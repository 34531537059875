import { Box, Stack, Typography } from '@mui/material';

import InvestmentDetailsMobile from '../InvestmentDetailsMobile';

export default function MobileInvestorProfile({
  commercialTransactionTitle,
  data,
}: {
  commercialTransactionTitle: String;
  data?: any;
}) {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'text.borderColorLight',
        borderRadius: '5px',
        padding: '10px',
        mb: 2,
        display: { xs: 'flex', sm: 'none' },
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Stack sx={{ rowGap: '10px' }}>
        <Typography
          variant='subtitle2'
          sx={{ color: 'text.labelColor' }}
        >
          {commercialTransactionTitle} Scheme
        </Typography>

        <Typography
          variant='subtitle1'
          sx={{ color: 'text.valueColor' }}
        >
          {data?.schemeDesc || 'NA'}
        </Typography>
      </Stack>

      <InvestmentDetailsMobile
        commercialTransactionTitle={commercialTransactionTitle}
        data={data}
      />
    </Box>
  );
}
