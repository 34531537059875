import { alpha, Box, InputBase, styled, Theme } from '@mui/material';

import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';

const SearchIconWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.inputTextColor,
  zIndex: 1,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  'fontSize': 14,
  'color': theme.palette.text.labelColor,
  'fontWeight': 500,
  '& .MuiInputBase-input': {
    padding: '11.64px 8px',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.text.borderColorLight,
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '7px 7px 0px 0px !important',
      backgroundColor: theme.palette.text.borderColorDark,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));

export function CustomSearch<T>({
  onChange,
  placeholder,
  value,
  sx,
}: {
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
  sx?: (theme: Theme) => any;
}) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange?.(e.target.value);
  };
  return (
    <Box
      sx={(theme: Theme) => ({
        position: 'relative',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        marginLeft: 0,
        width: '100%',
        ...sx?.(theme),
      })}
    >
      <SearchIconWrapper>
        <SearchIcon width={'18'} />
      </SearchIconWrapper>
      <StyledInputBase
        fullWidth
        value={value || ''}
        placeholder={placeholder || 'Search'}
        inputProps={{ 'aria-label': 'search' }}
        onChange={e => handleChange(e)}
      />
    </Box>
  );
}
