import {
  Box,
  Button,
  CardMedia,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AmountIcon from '../../../assets/amount-coin.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-red.svg';
import { ReactComponent as CloseIcon } from '../../../assets/filter-close-icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/search-icon.svg';
import { useDebounce } from '../../../hooks/useDebounce';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../common/BackToHomePage';
import { CustomizedCheckbox } from '../../common/checkbox';
import CustomLoader from '../../common/CustomLoader';
import InvestorProfile from '../../common/InvestorProfile';
import { CustomSearch } from '../../common/search';
import { useRootContext } from '../../data/root.context';
import { ResponsiveInvOnBoardingFilters } from '../mfs/newInvestorOnboarding/schemeSelection/ResponsiveInvOnboardingFilters';
import {
  useLazyAllSchemeDetailsQuery,
  useSchemeByfundMutation,
} from '../mfs/newInvestorOnboarding/slice';
import { setFundName, setInvestmentDetails } from './context/MfsNewPurchaseAction';
import { useMfsNewPurchaseContext } from './context/MfsNewPurchaseContext';
import { RadioCollapsable } from './RadioCollapsible';

const data = ['AMC', 'Category'];

interface Scheme {
  fund: string;
  fundName: string;
}

export interface SchemeByfund {
  category: string;
  fund: string;
  option: string;
  plan: string;
  plan_mode: string;
  schemeDesc: string;
  scheme: string;
}

function NewPurchase() {
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useRootContext();
  const [query, setQuery] = useState('');
  const [schemeSearchQuery, setSchemeSearchQuery] = useState('');
  const [allFundData, setAllFundData] = useState<Scheme[]>([]);
  const [tempFundData, setTempFundData] = useState<Scheme[]>([]);
  const [selectedScheme, setSelectedScheme] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [schemeName, setSchemeName] = useState(data[0] || '');
  const [schemesByFund, setSchemesByFund] = useState<SchemeByfund[]>([]);
  const [tempSchemeData, setTempSchemeData] = useState<SchemeByfund[]>([]);
  const [schemeCategoryData, setSchemeCategoryData] = useState<string[]>([]);
  const [tempAllFundData, setTempAllFundData] = useState<any>([]);
  const [chips, setChips] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>('');
  const [closeFilterOption, setCloseFilterOption] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [displaySchemeLoader, setDisplaySchemeLoader] = useState(false);
  const [selectedChip, setSelectedChip] = useState<string>('');

  const [getSchemes, getAllFundResponse] = useLazyAllSchemeDetailsQuery();
  const [getSchemesByFund] = useSchemeByfundMutation();
  const { dispatch: contextDispatch } = useMfsNewPurchaseContext();

  const cartItems = location?.state?.cartItem ?? null;
  const fundInCart = (cartItems && cartItems?.length && cartItems[0]?.fund) ?? null;
  const isFormCart = location?.state?.isFormCart;

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleCheckboxChange = async (index: number, e: any) => {
    const isChecked = e.target.checked;
    let prev = selectedScheme;
    let itemIndex = prev.indexOf(allFundData[index]?.fund);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(allFundData[index]?.fund);
    }
    setSelectedScheme([...prev]);
    const fundNames = allFundData.map((item: any) => item.fund);
    setDisplaySchemeLoader(true);
    try {
      const schemeByFundBody = {
        funds: selectedScheme.length === 0 ? fundNames : selectedScheme,
        category: selectedCategory,
        investorPan: '',
        start: 0,
        end: 1000,
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }))
        ?.filter((item: any) => item.newPurAllow);

      setSchemesByFund(resFundWithOpenKey);
      setPage(0);
    } catch (error: any) {
      showToast(error?.data?.message || 'unknown error', 'error');
    } finally {
      setDisplaySchemeLoader(false);
    }
    if (!isChecked) {
      setChips((prev: string[]) =>
        prev.filter((chip: string) => chip !== allFundData[index]?.fundName),
      );
    } else {
      setChips((prev: string[]) => [...new Set([...prev, allFundData[index]?.fundName])]);
    }
  };

  useEffect(() => {
    if (chips.length === 0) setSchemesByFund(tempSchemeData);
  }, [chips]);

  const handleCategoryChange = async (index: number, e: any) => {
    const isChecked = e.target.checked;
    let prev = selectedCategory;
    let itemIndex = prev.indexOf(schemeCategoryData[index]);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(schemeCategoryData[index]);
    }
    setSelectedCategory([...prev]);
    try {
      const schemeByFundBody = {
        funds:
          selectedScheme.length !== 0
            ? selectedScheme
            : allFundData.map((item: any) => item.fund),
        category: selectedCategory,
        investorPan: '',
        start: 0,
        end: 1000,
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }))
        ?.filter((item: any) => item.newPurAllow);

      setSchemesByFund(resFundWithOpenKey);
      setPage(0);
      if (!isChecked) {
        setChips((prev: string[]) =>
          prev.filter((chip: string) => chip !== schemeCategoryData[index]),
        );
      } else {
        setChips((prev: string[]) => [...new Set([...prev, schemeCategoryData[index]])]);
      }
    } catch (error: any) {
      showToast(error?.data?.message || 'unknown error', 'error');
    }
  };

  const handleClearClick = async () => {
    let updatedScheme = selectedScheme;
    let updatedCategory = selectedCategory;

    if (schemeName === 'AMC') {
      setSelectedScheme([]);
      setChips([]);
      updatedScheme = [];
    } else {
      setSelectedCategory([]);
      updatedCategory = [];
    }

    try {
      const schemeByFundBody = {
        funds:
          updatedScheme.length === 0
            ? allFundData.map((item: any) => item.fund)
            : updatedScheme,
        category: schemeName === 'AMC' ? updatedCategory : [],
        investorPan: '',
        start: 0,
        end: 1000,
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }))
        ?.filter((item: any) => item.newPurAllow);
      const tempCategoryData = resFund.map((item: any) => item.category);
      const uniQueCategory = [...new Set(tempCategoryData)] as string[];
      setSchemesByFund(resFundWithOpenKey);
      setSchemeCategoryData(uniQueCategory);
      setTempSchemeData(resFundWithOpenKey);
      setPage(0);
      if (updatedScheme.length === 0 && updatedCategory.length === 0) {
        setTempAllFundData(resFundWithOpenKey);
      }
    } catch (error: any) {
      showToast(error?.data?.message || 'unknown error', 'error');
    }
  };

  function sortByAction(action: string) {
    if (action === 'lowToHigh') {
      return schemesByFund.sort((a: any, b: any) => {
        const riskOrder = ['Low Risk', 'Moderate Risk', 'Very High Risk'];
        return riskOrder.indexOf(a.risk) - riskOrder.indexOf(b.risk);
      });
    } else if (action === 'highToLow') {
      return schemesByFund.sort((a: any, b: any) => {
        const riskOrder = ['Low Risk', 'Moderate Risk', 'Very High Risk'];
        return riskOrder.indexOf(b.risk) - riskOrder.indexOf(a.risk);
      });
    } else {
      return schemesByFund;
    }
  }

  const handleSortByChange = (event: any) => {
    setSortBy(event.target.value as string);
    switch (event.target.value) {
      case 'lowToHigh':
        setSchemesByFund(sortByAction('lowToHigh'));
        break;
      case 'highToLow':
        setSchemesByFund(sortByAction('highToLow'));
        break;
      default:
        return schemesByFund;
    }
  };

  const handleDelete = async (filteredScheme: string) => {
    const tempSelectedScheme = allFundData
      .filter(item => selectedScheme.indexOf(item.fund) !== -1)
      .filter((ele: any) => ele.fundName !== filteredScheme)
      .map(data => data.fund);
    setSelectedScheme(tempSelectedScheme);
    const tempCategry = selectedCategory.filter(
      (item: any) => !filteredScheme.includes(item),
    );
    setSelectedCategory(tempCategry);
    try {
      const schemeByFundBody = {
        funds:
          tempSelectedScheme.length === 0
            ? allFundData.map((item: any) => item.fund)
            : tempSelectedScheme,
        category: tempCategry,
        investorPan: '',
        start: 0,
        end: 1000,
      };
      const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

      const resFundWithOpenKey = resFund
        .map((item: SchemeByfund) => ({
          ...item,
          open: false,
          key: nanoid(),
        }))
        ?.filter((item: any) => item.newPurAllow);
      const tempCategoryData = resFund.map((item: any) => item.category);
      const uniQueCategory = [...new Set(tempCategoryData)];
      setSchemesByFund(resFundWithOpenKey);
      setTempSchemeData(resFundWithOpenKey);
      setPage(0);
    } catch (error: any) {
      showToast(error?.data?.message || 'unknown error', 'error');
    }
    setChips((prev: any[]) => prev.filter((chip: string) => chip !== filteredScheme));
  };

  useEffect(() => {
    setDisplayLoader(true);
    if (
      getAllFundResponse &&
      getAllFundResponse.isSuccess &&
      !getAllFundResponse.isFetching
    ) {
      const getAllSchemes = async () => {
        const allFunds = getAllFundResponse.data;
        setAllFundData(allFunds);
        setTempFundData(allFunds);
        setDisplayLoader(false);
        const fundNames = allFunds.map((item: any) => item.fund);
        try {
          setDisplaySchemeLoader(true);
          const schemeByFundBody = {
            funds: isFormCart
              ? [fundInCart]
              : selectedScheme.length !== 0
              ? selectedScheme
              : fundNames,
            category: selectedCategory.length !== 0 ? selectedCategory : [],
            investorPan: '',
            start: 0,
            end: 2000,
            searchQuery: schemeSearchQuery,
          };
          const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

          const resFundWithOpenKey = resFund
            .map((item: SchemeByfund) => ({
              ...item,
              open: false,
              key: nanoid(),
            }))
            ?.filter((item: any) => item.newPurAllow);

          const tempCategoryData = resFund
            .map((item: any) => item.category)
            .filter((category: string) => category !== null && category !== '');
          const uniQueCategory = [...new Set(tempCategoryData)] as string[];
          setSchemesByFund(resFundWithOpenKey);
          setSchemeCategoryData(uniQueCategory);
          setTempSchemeData(resFundWithOpenKey);
          isFormCart && setSelectedChip(uniQueCategory[0]);
          setPage(0);
          if (selectedScheme.length === 0 && selectedCategory.length === 0) {
            setTempAllFundData(resFundWithOpenKey);
          }
        } catch (error: any) {
          showToast(error?.data?.message || 'unknown error', 'error');
        } finally {
          setDisplaySchemeLoader(false);
        }
      };
      getAllSchemes();
    }
  }, [getAllFundResponse]);

  useEffect(() => {
    try {
      getSchemes('');
      contextDispatch(setInvestmentDetails(null));
      contextDispatch(setFundName(''));
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    }
  }, []);

  useEffect(() => {
    if (isFormCart) {
      const getAllSchemes = async () => {
        try {
          const schemeByFundBody = {
            funds: isFormCart && [fundInCart],
            category: [selectedChip],
            investorPan: '',
            start: 0,
            end: 500,
            searchQuery: '',
          };
          const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

          const resFundWithOpenKey = resFund
            .map((item: SchemeByfund) => ({
              ...item,
              open: false,
              key: nanoid(),
            }))
            ?.filter((item: any) => item.newPurAllow);

          setSchemesByFund(resFundWithOpenKey);
          setPage(0);
        } catch (error: any) {
          showToast(error?.data?.message || 'unknown error', 'error');
        }
      };
      getAllSchemes();
    }
  }, [selectedChip]);

  const handleClick = (scheme: string) => {
    setSchemeName(scheme);
  };

  useEffect(() => {
    const filteredData = allFundData?.filter((item: any) =>
      item.fundName.toLowerCase().includes(query.toLowerCase()),
    );

    if (query.length > 0) {
      setAllFundData(filteredData);
    } else {
      setAllFundData(tempFundData);
    }
  }, [query]);

  const handleSearch = useDebounce(async (searchText: string) => {
    const allFunds = getAllFundResponse.data;
    const fundNames = allFunds.map((item: any) => item.fund);
    if (searchText.length > -1) {
      try {
        const schemeByFundBody = {
          funds: isFormCart
            ? [fundInCart]
            : selectedScheme.length !== 0
            ? selectedScheme
            : fundNames,
          category: isFormCart
            ? [selectedChip]
            : selectedCategory.length !== 0
            ? selectedCategory
            : [],
          investorPan: '',
          start: 0,
          end: 2000,
          searchQuery: searchText,
        };
        const resFund = await getSchemesByFund(schemeByFundBody).unwrap();

        const resFundWithOpenKey = resFund
          .map((item: SchemeByfund) => ({
            ...item,
            open: false,
            key: nanoid(),
          }))
          ?.filter((item: any) => item.newPurAllow);

        const tempCategoryData = resFund
          .map((item: any) => item.category)
          .filter((category: string) => category !== null && category !== '');
        const uniQueCategory = [...new Set(tempCategoryData)] as string[];
        setSchemesByFund(resFundWithOpenKey);
        !isFormCart && setSchemeCategoryData(uniQueCategory);
        setPage(0);
      } catch (error: any) {
        showToast(error?.data?.message || 'unknown error', 'error');
      }
    }
  }, 500);

  const handleSearchTextChanged = (text: string) => {
    setSchemeSearchQuery(text);
    setPage(0);
    handleSearch(text);
  };

  const handleChipSelection = async (scheme: string) => {
    setSelectedChip(scheme);
  };

  const RightBottomComp = () => {
    return (
      <Box
        sx={{
          padding: '30px 20px',
          borderTop: '1px solid',
          borderColor: 'text.borderColorDark',
        }}
      >
        <Stack
          direction='column'
          gap='16px'
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography
              variant='subtitle1'
              sx={{ color: 'primary.main' }}
            >
              Purchase Scheme
            </Typography>
            <CardMedia
              sx={{
                '&.MuiCardMedia-root': {
                  width: '12%',
                },
              }}
              component='img'
              image={AmountIcon}
              alt='amount'
            />
          </Stack>
        </Stack>
        <Typography>--</Typography>
      </Box>
    );
  };

  return (
    <>
      <PageNavigator
        title='New Purchase'
        backHandler={() =>
          isFormCart
            ? navigate('/transaction-dashboard', { replace: true })
            : navigate('/transaction-dashboard/transact', { replace: true })
        }
      />
      <Typography
        variant='subtitle1'
        sx={{ color: 'primary.main', my: 2 }}
      >
        Please select the scheme you want to purchase
      </Typography>
      {/* Mobile UI Search Filter */}
      <ResponsiveInvOnBoardingFilters
        allFundData={allFundData}
        schemeCategoryData={schemeCategoryData}
        query={query}
        setQuery={setQuery}
        selectedScheme={selectedScheme}
        setSelectedScheme={setSelectedScheme}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        chips={chips}
        setChips={setChips}
        setSchemesByFund={setSchemesByFund}
        schemeSearchQuery={schemeSearchQuery}
        setSchemeSearchQuery={setSchemeSearchQuery}
        setSchemeCategoryData={setSchemeCategoryData}
        setTempSchemeData={setTempSchemeData}
        setPage={setPage}
        setTempAllFundData={setTempAllFundData}
      />
      {/* Mobile UI Search Filter */}
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={9}
        >
          {!isFormCart && (
            <Investorcard sx={{ mb: 4, display: { xs: 'none', sm: 'block' } }}>
              <Box
                sx={{
                  padding: '15px 20px',
                  borderBottom: '1px solid',
                  borderColor: 'text.borderColorLight',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    {data.map((scheme, index) => {
                      return (
                        <Chip
                          onClick={() => handleClick(scheme)}
                          key={index}
                          sx={{
                            'background':
                              schemeName === scheme
                                ? 'linear-gradient(180deg, #1D6CB0 0%, #10C1D0 100%)'
                                : 'white',
                            'borderRadius': '7px',
                            'padding': '6px 15px',

                            'border': schemeName === scheme ? 'unset' : '0.5px solid',
                            'borderColor':
                              schemeName === scheme ? 'unset' : 'text.borderColorLight',
                            'mr': 2,
                            '& .MuiChip-label': {
                              fontSize: '14px',
                              fontWeight: '500',
                              color:
                                schemeName === scheme
                                  ? 'common.white'
                                  : 'text.labelColor',
                            },
                          }}
                          label={scheme}
                        />
                      );
                    })}
                  </Box>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      rowGap: '20px',
                    }}
                  >
                    <Button
                      sx={{ color: 'text.clearText', fontSize: '14px' }}
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        setSelectedCategory([]);
                        setSelectedScheme([]);
                        setChips([]);
                        setSchemesByFund(tempAllFundData);
                        setSchemeName('AMC');
                      }}
                    >
                      Clear All
                    </Button>
                    <Button
                      sx={{ color: 'text.primary', fontSize: '14px' }}
                      startIcon={<CloseIcon />}
                      onClick={() => {
                        setCloseFilterOption(prev => !prev);
                      }}
                    >
                      Filters
                    </Button>
                  </Stack>
                </Box>
              </Box>
              {!closeFilterOption && (
                <Box sx={{ padding: '0px 20px 25px 20px', position: 'relative' }}>
                  <Grid
                    container
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      my: '20px',
                    }}
                  >
                    <Grid
                      item
                      sm={9}
                    >
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        columnGap={'10px'}
                      >
                        <Typography
                          variant='subtitle2'
                          sx={{ my: 2, color: 'primary.main' }}
                        >
                          {schemeName}
                        </Typography>

                        <Button
                          sx={{
                            fontSize: '13px',
                            color: 'text.clearText',
                            fontWeight: 500,
                          }}
                          onClick={handleClearClick}
                        >
                          Clear
                        </Button>
                      </Stack>
                    </Grid>
                    {schemeName === 'AMC' && (
                      <Grid
                        item
                        sm={3}
                      >
                        <Box sx={{ position: 'relative' }}>
                          <Box
                            sx={{
                              position: 'absolute',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              zIndex: 2,
                              pointerEvents: 'none',
                              top: '6px',
                            }}
                          >
                            <SearchIcon />
                          </Box>

                          <TextField
                            sx={{
                              '& .MuiInputBase-root': {
                                'fontSize': '13px',
                                'color': 'text.labelColor',
                                'fontWeight': 500,
                                '& .MuiInputBase-input': {
                                  border: 0,
                                  padding: '6px 5px 6px 22px',
                                },
                              },
                            }}
                            value={query}
                            onChange={(e: any) => setQuery(e.target.value)}
                            placeholder='Search'
                            type='search'
                            variant='standard'
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  {allFundData?.length !== 0 ? (
                    <Box
                      sx={{
                        'maxHeight': '130px',
                        'overflow': 'auto',
                        'pl': '10px',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                          height: '85px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          height: '6px',
                          border: '2px solid rgba(0, 0, 0, 0)',
                          backgroundClip: 'padding-box',
                          backgroundColor: 'rgba(133, 129, 129, 0.5)',
                          background: 'linear-gradient(180deg, #1D6CB0 0%, #10C1D0 100%)',
                          borderRadius: '84px',
                          boxShadow:
                            'inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05)',
                        },
                        '&::-webkit-scrollbar-button': {
                          display: 'none',
                          width: 0,
                          height: 0,
                        },
                        '&::-webkit-scrollbar-corner': {
                          backgroundColor: 'transparent',
                        },
                        '&::-webkit-scrollbar-track': {
                          borderRadius: '10px',
                          background: 'rgba(255, 255, 255, 0.8)',
                        },
                      }}
                    >
                      <FormGroup row>
                        <Grid container>
                          {schemeName === 'AMC'
                            ? allFundData?.map((item, index) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      sm={4}
                                      md={4}
                                      key={item.fund}
                                    >
                                      <FormControlLabel
                                        control={
                                          <CustomizedCheckbox
                                            checked={selectedScheme.includes(item?.fund)}
                                            onChange={async e =>
                                              handleCheckboxChange(index, e)
                                            }
                                          />
                                        }
                                        label={item.fundName}
                                        sx={{
                                          '& .MuiFormControlLabel-label': {
                                            fontSize: '13px',
                                            color: selectedScheme.includes(item.fund)
                                              ? 'primary.main'
                                              : 'text.labelColor',
                                            fontWeight: 400,
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </>
                                );
                              })
                            : schemeCategoryData?.map((item: any, index: any) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      sm={3}
                                      key={index}
                                    >
                                      <FormControlLabel
                                        control={
                                          <CustomizedCheckbox
                                            checked={selectedCategory.includes(item)}
                                            onChange={async e =>
                                              handleCategoryChange(index, e)
                                            }
                                          />
                                        }
                                        label={item}
                                      />
                                    </Grid>
                                  </>
                                );
                              })}
                        </Grid>
                      </FormGroup>
                    </Box>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: displayLoader ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      No data Found
                    </Grid>
                  )}
                  {displayLoader && <CustomLoader display={displayLoader} />}
                </Box>
              )}
            </Investorcard>
          )}
          <Investorcard
            sx={(theme: Theme) => ({
              mb: 4,
              [theme.breakpoints.down('sm')]: {
                boxShadow: 'unset',
                border: 'unset',
                p: 0,
              },
            })}
          >
            {isFormCart ? (
              <Box
                sx={{
                  padding: { xs: '0px', sm: '20px 25px 10px' },
                }}
              >
                {schemeCategoryData?.map((scheme: any, index: number) => (
                  <Chip
                    key={index}
                    onClick={async () => handleChipSelection(scheme)}
                    sx={(theme: Theme) => ({
                      'background':
                        selectedChip === scheme
                          ? 'linear-gradient(180deg, #1D6CB0 0%, #10C1D0 100%)'
                          : theme.palette.common.white,
                      'borderRadius': '7px',
                      'padding': '6px 15px',
                      'border': selectedChip === scheme ? 'unset' : '0.5px solid',
                      'borderColor':
                        selectedChip === scheme ? 'unset' : 'text.borderColorLight',
                      'mr': 1, // Changed to mr: 1 from mr: 2
                      'mb': 1, // Added mb: 1 for margin bottom
                      '& .MuiChip-label': {
                        fontSize: '15px',
                        fontWeight: '500',
                        color:
                          selectedChip === scheme ? 'common.white' : 'text.labelColor',
                      },
                    })}
                    label={scheme}
                  />
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  padding: { xs: '0px', sm: '35px 25px 10px' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{ color: 'text.valueColor', mb: { xs: '0px', sm: '20px' } }}
                  >
                    Search Results:
                    <Typography
                      component={'span'}
                      sx={{ fontWeight: 500, ml: 0.5 }}
                    >
                      {schemesByFund?.length} Mutual Funds
                    </Typography>
                  </Typography>
                  <FormControl
                    sx={{
                      'display': { xs: 'none', sm: 'block' },
                      '& .MuiInputBase-root ': {
                        '& .MuiTypography-root': {
                          fontSize: '12px',
                          fontWeight: 400,
                          color: 'text.inputLabelColor',
                        },

                        '&:hover': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'text.borderColorLight',
                          },
                        },
                        '& .MuiSvgIcon-root': {
                          color: 'primary.main',
                        },
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'text.borderColorLight',
                        borderRadius: '5px',
                      },
                    }}
                  >
                    <Select
                      sx={{
                        '& .MuiSelect-select': {
                          border: '0px',
                          padding: '8.5px 14px',
                          pl: '0px',
                          fontSize: '13px',
                          fontWeight: 500,
                          backgroundClip: 'text',
                          webkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          backgroundImage:
                            'linear-gradient(180deg, #215EAB 0%, #0FD0D6 100%)',
                        },
                      }}
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={sortBy !== '' ? sortBy : 'lowToHigh'}
                      onChange={e => handleSortByChange(e)}
                      startAdornment={
                        <InputAdornment position='start'>Sort By:</InputAdornment>
                      }
                    >
                      <MenuItem value='lowToHigh'>Risk - Low to High</MenuItem>
                      <MenuItem value='highToLow'>Risk - High to Low</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    sx={{
                      color: 'text.clearText',
                      fontSize: '14px',
                      p: 0,
                      display: { xs: 'inline-flex', sm: 'none' },
                    }}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setChips([]);
                      setSelectedCategory([]);
                      setSelectedScheme([]);
                      setSchemesByFund(tempAllFundData);
                    }}
                  >
                    Clear All
                  </Button>
                </Box>

                {chips.length !== 0 && (
                  <Box
                    sx={{
                      borderRadius: '10px',
                      border: '1px dashed',
                      borderColor: 'text.borderColorLight',
                      padding: '15px',
                      my: 1,
                      mb: { xs: 4, sm: '0' },
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '15px 10px',
                    }}
                  >
                    {chips?.map((level: any, index: number) => (
                      <Chip
                        key={index}
                        sx={{
                          '&.MuiButtonBase-root': {
                            'borderRadius': '7px',
                            'backgroundColor': 'text.dodgerBlue',
                            'display': 'inline-flex',
                            'padding': '4px 6px',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'gap': '5px',
                            'minWidth': 'max-content',
                            'transition': 'all 300ms',
                            '& .MuiChip-label': {
                              color: 'common.white',
                              fontSize: '13px',
                              fontWeight: 400,
                            },
                            '& .MuiSvgIcon-root ': {
                              color: 'common.white',
                            },
                            '&:hover': {
                              backgroundColor: 'text.dodgerBlue',
                            },
                          },
                        }}
                        label={level}
                        variant='outlined'
                        onDelete={async () => handleDelete(level)}
                      />
                    ))}
                  </Box>
                )}
                {chips.length !== 0 && (
                  <Button
                    disableTouchRipple
                    sx={{
                      color: 'text.clearText',
                      fontSize: '14px',
                      display: { xs: 'none', sm: 'inline-flex' },
                      mt: 1,
                    }}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setChips([]);
                      setSelectedCategory([]);
                      setSelectedScheme([]);
                      setSchemesByFund(tempAllFundData);
                    }}
                  >
                    Clear All
                  </Button>
                )}
              </Box>
            )}
            <Box
              sx={{
                padding: '25px 15px',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: 'text.borderColorLight',
                display: { xs: 'none', sm: 'block' },
              }}
            >
              <CustomSearch
                placeholder='Search'
                value={schemeSearchQuery}
                onChange={handleSearchTextChanged}
              />
            </Box>
            <RadioCollapsable
              schemeByFund={schemesByFund}
              setSchemesByFund={setSchemesByFund}
              tempSchemeData={tempSchemeData}
              setTempSchemeData={setTempSchemeData}
              allFundData={allFundData}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              displaySchemeLoader={displaySchemeLoader}
              cartItem={cartItems && cartItems?.length && cartItems[0]}
              isFormCart={isFormCart}
            />
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component='div'
              count={schemesByFund?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Investorcard>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
        >
          {/* <InvestorProfile bottomComponent={<RightBottomComp />} /> */}
          <InvestorProfile>{<RightBottomComp />}</InvestorProfile>
        </Grid>
      </Grid>
    </>
  );
}

export default NewPurchase;
