import { Grid } from '@mui/material';

import { FormData } from '../switch/Confirmation';

const Confirmation = ({ data }: { data: any }) => (
  <>
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='SWP From'
          value={data.schemeName}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='SWP Type'
          value={data.withdrawalType}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='SWP Installment Amount'
          value={data.amount}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='Frequency'
          value={data.swpFrequency}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='No. of Installment'
          value={data.noOfWithdrawals}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='SWP Start Date'
          value={new Date(data.swpStartDate).toLocaleDateString('en-GB')}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='SWP End Date'
          value={new Date(data.swpEndDate).toLocaleDateString('en-GB')}
        />
      </Grid>
    </Grid>
  </>
);

export default Confirmation;
