import { Button, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useRootContext } from '../../../../data/root.context';
import { setAAADetails, setStepNumber } from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';

export const AadhaarContainer = () => {
  const {
    state: { ackNumber: AckNumber, aadhaarDetails, statesList, countriesList },
    dispatch: dispatchContext,
  } = useNewSubscriberContext();
  const navigate = useNavigate();
  const { showToast } = useRootContext();

  const aadhaarPhoto =
    aadhaarDetails.Certificate.CertificateData[0].KycRes[0].UidData[0].Pht[0];
  const aadhaarPhotoBase64 = `data:image/jpeg;base64,${aadhaarPhoto}`;
  const aadhaar_Address =
    aadhaarDetails.Certificate.CertificateData[0].KycRes[0].UidData[0].Poa[0];
  const aadhaar_Basic =
    aadhaarDetails.Certificate.CertificateData[0].KycRes[0].UidData[0].Poi[0];
  const aadhaarName = aadhaar_Basic.$.name;
  const aadhaarGender = aadhaar_Basic.$.gender;
  const aadhaarNumber =
    aadhaarDetails.Certificate.CertificateData[0].KycRes[0].UidData[0].$.uid;

  const aadhaarDob = aadhaar_Basic.$.dob;
  const aadhaarFatherName = aadhaar_Address.$.co;
  const aadhaarCountry = aadhaar_Address.$.country;
  const aadhaarState = aadhaar_Address.$.state;
  const aadhaarDistrict = aadhaar_Address.$.dist;
  const aadhaarPincode = aadhaar_Address.$.pc;
  const aadhaarAddress1 = aadhaar_Address.$.house;
  const aadhaarAddress2 = aadhaar_Address.$.vtc;
  const aadhaarAddress3 = `${aadhaar_Address.$.dist},${aadhaar_Address.$.state},${aadhaar_Address.$.pc}`;
  const handleSubmit = () => {
    try {
      const params = {
        AckNumber: '123',
        District: aadhaarDistrict,
        PinCode: aadhaarPincode,
        StateName:
          statesList.length > 0
            ? statesList.find(
                item =>
                  item.label.toLowerCase().trim() === aadhaarState.toLowerCase().trim(),
              ).value
            : [],
        Address1: aadhaarAddress1,
        Addreess2: aadhaarAddress2,
        CountryName:
          countriesList.length > 0
            ? countriesList.find(
                item =>
                  item.label.toLowerCase().trim() === aadhaarCountry.toLowerCase().trim(),
              ).value
            : [],
        IdentifierNum: aadhaarNumber,
        IdentifierType: 150,
        SubscriberName: aadhaarName,
        Gender: aadhaarGender,
        aadhaar_number: aadhaarNumber,
        DOB: aadhaarDob.replaceAll('-', '/'),
      };
      dispatchContext(setAAADetails(params));
      const { pathname } = location;
      const navigateTo = pathname.split('?')[0];
      navigate(navigateTo);
      dispatchContext(setStepNumber(1));
    } catch (error) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };
  return (
    <Paper className='p-3 w-50'>
      <Typography
        variant='h6'
        style={{ fontSize: '14px', color: '#002856' }}
      >
        e-Aadhaar
      </Typography>
      <Paper
        style={{
          border: '1px solid',
          width: '540px',
          padding: '12px',
        }}
        className='d-flex flex-row'
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={4}
          >
            <img
              style={{ width: '160px' }}
              src={aadhaarPhotoBase64}
              alt='Aadhaar Photograph'
            />
          </Grid>
          <Grid
            item
            xs={8}
          >
            <Typography style={{ marginBottom: '2px' }}>{aadhaarName}</Typography>
            <Typography style={{ marginBottom: '2px' }}>DOB: {aadhaarDob}</Typography>
            <Typography>Gender: {aadhaarGender}</Typography>
            <Typography
              variant='subtitle1'
              style={{ fontWeight: 'bold', marginTop: '8px' }}
            >
              Address:
            </Typography>
            <Typography style={{ marginBottom: '2px' }}>{aadhaarFatherName},</Typography>
            <Typography style={{ marginBottom: '2px' }}>{aadhaarAddress1},</Typography>
            <Typography style={{ marginBottom: '2px' }}>{aadhaarAddress2},</Typography>
            <Typography>{aadhaarAddress3}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Button
        variant='contained'
        color='primary'
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Paper>
  );
};
