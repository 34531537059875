import {
  Button,
  FormControlLabel,
  Grid,
  styled,
  TablePagination,
  Theme,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

import { ReactComponent as CollapsibleUpIcon } from '../../assets/collapse-downicon.svg';
import { ReactComponent as CollapsibleDownIcon } from '../../assets/collapse-upicon.svg';
import { ReactComponent as NoDataIcon } from '../../assets/no-data-table.svg';
import { ReactComponent as ArrowIcon } from '../../assets/right-direction.svg';
import {
  StepperNextButton,
  ToggleSwitch,
} from '../../components/auth/login/styles/styledComponents';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: '#F4F6F8',
    color: theme.palette.common.white,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  'backgroundColor': theme.palette.common.white,
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export type ID = string | number;

interface AdditionalData {
  active?: boolean;
}
interface RowProps<T extends { [key: string]: string | number | AdditionalData }> {
  row: T;
  renderDropdown?: (item: T) => React.ReactNode;
  selected: boolean;
  handleSelectClick: (id: ID) => void;
  disabled?: boolean;
  checkboxTooltip?: React.ElementType;
  hidecheckbox?: boolean;
  showtoggle?: boolean;
  toggleHandler?: (item: T) => void;
  showAction?: boolean;
  actionHandler?: (item: T) => void;
  children?: React.ReactNode; // Optional children prop
  colspan?: number;
}

export function Row<T extends { [key: string]: any }>(props: RowProps<T>) {
  const {
    row,
    renderDropdown,
    selected,
    handleSelectClick,
    disabled,
    hidecheckbox,
    checkboxTooltip: CheckBoxToolTip,
    showtoggle,
    toggleHandler,
    showAction,
    actionHandler,
    children,
    colspan,
  } = props;
  const [open, setOpen] = React.useState(false);
  const checkbox = (
    <Box>
      <Checkbox
        onClick={() => handleSelectClick(row.id)}
        disabled={!!disabled}
        checked={selected}
        sx={{
          '&.MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
            color: 'primary.main',
          },
        }}
      />
    </Box>
  );
  return (
    <React.Fragment>
      <StyledTableRow
        sx={(theme: Theme) => ({
          bgcolor: open ? 'text.lightBlue !important' : 'common.white',
          borderBottom: open
            ? 'unset'
            : `1px solid ${theme.palette.text.borderColorDark}`,
          borderLeft: open ? `5px solid ${theme.palette.primary.main}` : 'unset',
        })}
      >
        {!hidecheckbox && (
          <>
            <StyledTableCell>
              {CheckBoxToolTip ? <CheckBoxToolTip>{checkbox}</CheckBoxToolTip> : checkbox}
            </StyledTableCell>
          </>
        )}
        {children && <StyledTableCell colSpan={colspan}>{children}</StyledTableCell>}
        {Object.keys(row)
          .filter(key => key !== 'id' && key !== 'additionalData')
          .map(key => (
            <StyledTableCell
              key={key}
              align={typeof row[key] === 'number' ? 'right' : 'left'}
            >
              {row[key]}
              {renderDropdown && row.additionalData?.dropDownColumn === key && (
                <IconButton
                  aria-label='expand row'
                  size='small'
                  onClick={() => setOpen(!open)}
                >
                  {open ? <CollapsibleDownIcon /> : <CollapsibleUpIcon />}
                </IconButton>
              )}
            </StyledTableCell>
          ))}
        {showtoggle && (
          <StyledTableCell>
            <Button onClick={() => toggleHandler?.(row)}>
              <FormControlLabel
                control={
                  <>
                    <ToggleSwitch
                      sx={{
                        'm': 1,
                        'width': '22px',
                        'height': '11px',
                        '& .MuiButtonBase-root.MuiSwitch-switchBase': {
                          'top': '1px',
                          'left': '-1px',
                          '&.Mui-checked': {
                            transform: 'translateX(12px)',
                          },
                          '& .MuiSwitch-thumb': {
                            width: '6px',
                            height: '6px',
                          },
                        },
                      }}
                      checked={row.additionalData?.active}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: row.additionalData?.active
                          ? 'success.dark'
                          : 'text.crimson',
                      }}
                    >
                      {row.additionalData?.active ? 'Enabled' : 'Disabled'}
                    </Typography>
                  </>
                }
                label=''
              />{' '}
            </Button>
          </StyledTableCell>
        )}
        {showAction && (
          <StyledTableCell>
            <IconButton onClick={() => actionHandler?.(row)}>
              <ArrowIcon />
            </IconButton>
          </StyledTableCell>
        )}
      </StyledTableRow>
      {renderDropdown && (
        <TableRow
          sx={(theme: Theme) => ({
            borderBottom: open
              ? `1px solid ${theme.palette.text.borderColorDark}`
              : 'unset',
            borderTop: open ? `1px solid ${theme.palette.text.borderColorDark}` : 'unset',
          })}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={Object.keys(row).length + 1}
          >
            <Collapse
              in={open}
              timeout='auto'
              unmountOnExit
            >
              <Box sx={{ margin: '0 auto', width: '90%', padding: '20px 20px' }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    'alignItems': 'center',
                    'justifyContent': 'space-between',
                    '& .MuiTypography-root ': {
                      fontSize: '14px',
                      color: 'text.darkGray2',
                    },
                    '& .MuiTypography-body1': {
                      fontWeight: 400,
                    },
                    '& .MuiTypography-body2': {
                      fontWeight: 500,
                    },
                  }}
                >
                  {renderDropdown?.(row)}
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

interface DataTableProps<T extends { [key: string]: string | number }> {
  rows: T[];
  columns: string[];
  renderDropdown?: (item: T) => React.ReactNode;
  onSubmit: (ids: ID[]) => void;
  filter?: JSX.Element;
  collapsableFilter?: JSX.Element;
  isRowDisabled?: (row: any, selected?: ID[]) => boolean;
  disableSelectAll?: boolean;
  hideUpdateButton?: boolean;
  hidecheckbox?: boolean;
  checkboxTooltip?: (
    isSelected: boolean,
    isDisabled: boolean,
  ) => React.ElementType | undefined;
  showtoggle?: boolean;
  showAction?: boolean;
  toggleHandler?: (item: T) => void;
  actionHandler?: (item: T) => void;
  paginationActive?: boolean;
  paginationDisabled?: boolean;
  rowsPerPageOptions?: number[];
  count?: number;
  page?: number;
  onPageChange?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  rowsPerPage?: number;
  onRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showPaper?: boolean;
  noDataText?: string;
  hideNoData?: boolean;
}

export default function DataTable({
  rows,
  columns,
  renderDropdown,
  onSubmit,
  filter: Filter,
  collapsableFilter: CollapsableFilter,
  isRowDisabled,
  disableSelectAll,
  hideUpdateButton,
  checkboxTooltip,
  hidecheckbox,
  showtoggle,
  toggleHandler,
  showAction,
  actionHandler,
  paginationActive = false,
  paginationDisabled,
  rowsPerPageOptions = [5, 10, 25],
  count = 0,
  page = 0,
  onPageChange = () => {},
  rowsPerPage = rowsPerPageOptions[0],
  onRowsPerPageChange = () => {},
  showPaper = true,
  noDataText = 'No Data Found',
  hideNoData = false,
}: DataTableProps<any>) {
  const [selected, setSelected] = React.useState<ID[]>([]);
  const [rowDisabled, setRowDisabled] = React.useState<boolean[]>([]);

  React.useEffect(() => {
    setRowDisabled(
      Array(rows.length)
        .fill(false)
        .map((_, index) => isRowDisabled?.(rows[index], selected) || false),
    );
  }, [rows, selected]);

  React.useEffect(() => {
    setSelected([]);
  }, [rows, columns]);

  const handleSelectClick = (id: ID) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: ID[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: ID) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows
        .filter(row => !isRowDisabled?.(row, selected))
        .map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <Paper
      sx={{
        width: '100%',
        mb: 2,
        borderRadius: '15px',
        border: !showPaper ? 'unset' : '0.3px solid',
        borderColor: 'text.borderColorDark',
        bgcolor: 'common.white',
        boxShadow: !showPaper ? 'unset' : '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        display: { xs: 'none', md: 'block' },
      }}
    >
      <Box sx={{ padding: !showPaper ? 'unset' : '20px 20px' }}>
        {/* <Stack direction='row' alignItems='center' justifyContent={Filter ? 'space-between' : 'flex-end'}> */}
        <Grid
          container
          spacing={2}
          alignItems={'center'}
        >
          <Grid
            item
            sm={!hideUpdateButton ? 10 : 12}
          >
            {Filter}
          </Grid>
          {!hideUpdateButton && (
            <Grid
              item
              sm={2}
            >
              <StepperNextButton
                disabled={!selected?.length}
                onClick={() => onSubmit(selected)}
              >
                Update
              </StepperNextButton>
            </Grid>
          )}
        </Grid>

        {/* </Stack> */}
      </Box>

      {CollapsableFilter}

      {/* Table Definition */}
      <TableContainer sx={{ maxHeight: !showPaper ? 'calc(100vh - 380px)' : 'unset' }}>
        <Table
          aria-label='collapsible table'
          sx={{
            '& .MuiTableCell-root ': {
              padding: '16px 22px',
            },
          }}
        >
          <TableHead
            sx={(theme: Theme) => ({
              '& .MuiTableCell-root.MuiTableCell-head': {
                fontSize: '15px',
                color: 'text.labelColor',
                fontWeight: '500',
                textAlign: 'left',
                [theme.breakpoints.between(768, 1024)]: {
                  fontSize: '13px',
                },
              },
            })}
          >
            <StyledTableRow>
              {!hidecheckbox && (
                <StyledTableCell>
                  {!disableSelectAll && (
                    <Checkbox
                      sx={{
                        '&.MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                      checked={
                        rows.length > 0 &&
                        rowDisabled.filter(row => !row).length === selected.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  )}
                </StyledTableCell>
              )}

              {columns.map(column => (
                <StyledTableCell key={column}>{column}</StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody
            sx={{
              '&.MuiTableBody-root': {
                '& .MuiTableRow-root': {
                  'backgroundColor': 'common.white',
                  'cursor': 'pointer',
                  '& .MuiTableCell-root ': {
                    color: 'text.valueColor',
                    fontSize: '14px',
                    fontWeight: '400',
                    textAlign: 'left',
                  },
                },
              },
            }}
          >
            {rows.length > 0
              ? rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const isDisabled = rowDisabled[index];
                  const ToolTip = checkboxTooltip?.(isItemSelected, isDisabled);
                  return (
                    <Row
                      disabled={isDisabled}
                      key={row.id}
                      row={row}
                      renderDropdown={renderDropdown}
                      selected={isItemSelected}
                      handleSelectClick={handleSelectClick}
                      checkboxTooltip={ToolTip}
                      hidecheckbox={hidecheckbox}
                      showtoggle={showtoggle}
                      toggleHandler={toggleHandler}
                      showAction={showAction}
                      actionHandler={actionHandler}
                    />
                  );
                })
              : !hideNoData && (
                  <Row
                    row={{}}
                    selected={false}
                    handleSelectClick={() => {}}
                    colspan={8}
                    hidecheckbox={true}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: '400px',
                        margin: '5% auto',
                      }}
                    >
                      <NoDataIcon />
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 500,
                          color: 'text.labelColor',
                          my: 4,
                        }}
                      >
                        {noDataText}
                      </Typography>
                    </Box>
                  </Row>
                )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationActive && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={count}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          disabled={paginationDisabled}
        />
      )}
    </Paper>
  );
}
