import { Box, Grid } from '@mui/material';

import { FormData } from '../switch/Confirmation';

const Confirmation = ({ data }: { data: any }) => (
  <Box>
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='Redeem From'
          value={data.schemeName}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='Redemption Units/Amount'
          value={data.amount === '0' ? data.units : data.amount}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='Investor Bank'
          value={`${data.bankName}-  ${
            'X'.repeat(data.bankAccountNumber.length - 4) +
            data.bankAccountNumber.slice(-4)
          }`}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='Exit Load'
          value={data?.exitLoad || '-'}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label='STT Value'
          value={data?.sttValue || '-'}
        />
      </Grid>
    </Grid>
  </Box>
);

export default Confirmation;
