import apiSlice from '../../../../service/api';

const npsSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    verifyPanKycDetails: builder.mutation({
      query: payload => ({
        url: 'user/investor-onboarding/get-pan-kyc-status',
        method: 'POST',
        body: payload,
      }),
    }),
    sendKycMailtoInvestor: builder.mutation({
      query: payload => ({
        url: '/user/investor-onboarding/send-ekyc-mail',
        method: 'POST',
        body: payload,
      }),
    }),
    investorDetails: builder.mutation({
      query: payload => ({
        url: 'user/investor-onboarding/investor-details',
        method: 'POST',
        body: payload,
      }),
    }),
    schemeByfund: builder.mutation({
      query: payload => ({
        url: 'user/transactions/get-schemes-by-fund',
        method: 'POST',
        body: payload,
      }),
    }),
    newPurchaseDirect: builder.mutation({
      query: payload => ({
        url: 'user/transactions/new-purchase/direct',
        method: 'POST',
        body: payload,
      }),
    }),
    newPurchaseSip: builder.mutation({
      query: payload => ({
        url: 'user/transactions/new-purchase/sip',
        method: 'POST',
        body: payload,
      }),
    }),
    newPurchaseDirectSip: builder.mutation({
      query: payload => ({
        url: 'user/transactions/new-purchase/direct-sip',
        method: 'POST',
        body: payload,
      }),
    }),
    allSchemeDetails: builder.query({
      query: () => ({
        url: '/user/transactions/get-all-funds',
        method: 'GET',
      }),
    }),
    allPaymentModes: builder.mutation({
      query: payload => ({
        url: '/user/transactions/get-available-payment-modes',
        method: 'POST',
        body: payload,
      }),
    }),
    allMandates: builder.mutation({
      query: payload => ({
        url: '/user/investors/get-investor-mandates',
        method: 'POST',
        body: payload,
      }),
    }),
    checkoutCart: builder.mutation({
      query: payload => ({
        url: '/user/transactions/checkout-cart',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useVerifyPanKycDetailsMutation,
  useInvestorDetailsMutation,
  useLazyAllSchemeDetailsQuery,
  useSchemeByfundMutation,
  useNewPurchaseDirectMutation,
  useNewPurchaseSipMutation,
  useNewPurchaseDirectSipMutation,
  useAllPaymentModesMutation,
  useAllMandatesMutation,
  useCheckoutCartMutation,
  useSendKycMailtoInvestorMutation,
} = npsSlice;
