import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

// import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { ReactComponent as ArrowDropDown } from '../../assets/select-dropdown-icon.svg';

export function DropDownToggle() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [checkedIndices, setCheckedIndices] = React.useState([]);
  const [buttonText, setButtonText] = useState('Text');
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSelectChange = (event: { target: any }) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    setButtonText(newValue); // Update button text
  };
  // const handleChange = (index: number) => {
  //     const currentIndex = checkedIndices.indexOf(index);
  //     const newCheckedIndices = [...checkedIndices];

  //     if (currentIndex === -1) {
  //         newCheckedIndices.push(index);
  //     } else {
  //         newCheckedIndices.splice(currentIndex, 1);
  //     }

  //     setCheckedIndices(newCheckedIndices);
  // };
  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      <Button
        sx={{
          justifyContent: 'space-between',
          borderRadius: '5px',
          border: '1px solid',
          borderColor: 'text.borderColorLight',
          backgroundColor: 'common.white',
          fontSize: '14px',
          fontWeight: 500,
          color: 'text.labelColor',
          padding: '10px 20px',
          width: '100%',
        }}
        endIcon={<ArrowDropDown />}
        onClick={handleDrawerOpen}
      >
        {' '}
        {buttonText}
      </Button>

      {/* Drawer */}
      <Drawer
        anchor='bottom'
        sx={{
          '& .MuiPaper-root ': {
            borderRadius: '25px 25px 0px 0px',
            backgroundColor: 'common.white',
            maxHeight: '400px',
            overflowY: 'scroll',
          },
        }}
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Box role='presentation'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '10px 25px 0px',
            }}
          >
            <Typography
              sx={{ color: 'text.labelColor', fontSize: '14px', fontWeight: 500, mb: 3 }}
            >
              Text
            </Typography>

            {/* <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton> */}
          </Box>
          <TextField
            fullWidth
            type='search'
            placeholder='search'
            sx={{
              'p': '0px 20px',

              '& .MuiInputBase-root': {
                '&.Mui-focused,&:hover': {
                  '& .MuiOutlinedInput-notchedOutline ': {
                    border: '1px solid',
                    borderColor: 'text.borderColorLight',
                  },
                },
              },
              '& fieldset': {
                border: '1px solid',
                borderColor: 'text.borderColorLight',
              },
              '& .MuiInputBase-input': {
                padding: '10px 14px',
                border: 'none',
              },
            }}
          />
          <List sx={{ p: '20px 10px' }}>
            {[
              '360 One Mutual Fund',
              'Aditya Birla Sun Life Mutual Fund',
              'Axis Mutual Fund',
              'Axis Mutual Fund',
              '360 One Mutual Fund',
              'Aditya Birla Sun Life Mutual Fund',
            ].map((text, index) => (
              <ListItem
                button={true}
                key={text}
                sx={{
                  'pb': 1,
                  '&:not(:last-child)': {
                    borderBottom: '1px solid',
                    borderColor: 'text.borderColorDark',
                  },
                  'borderTop': '1px solid',
                  'borderColor': 'text.borderColorDark',
                  '& .MuiTypography-root ': {
                    // color: checkedIndices.includes(index) ? 'primary.main' : '#293139',
                    color: '#111927',
                    fontSize: '14px',
                    fontWeight: 500,
                  },
                  // '& .MuiButtonBase-root.MuiRadio-root': {
                  //     color: '#48535B',
                  //     '& .MuiSvgIcon-root': {
                  //         width: '16px',
                  //         height: '16px',
                  //     },
                  //     '&.Mui-checked': {
                  //         color: 'primary.main',
                  //     },
                  // },
                }}
                onClick={() => handleSelectChange({ target: { value: text } })}
              >
                {/* <FormControlLabel control={<CustomizedCheckbox checked={checkedIndices.includes(index)} onChange={() => handleChange(index)} />} label={text} /> */}

                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* <List>
                    <ListItem onClick={() => handleSelectChange({ target: { value: 'Option 1' } })}>
                        <ListItemText primary="Option 1" />
                    </ListItem>
                </List> */}
      </Drawer>
    </Box>
  );
}
