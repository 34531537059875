/* eslint-disable no-empty-pattern */
import {
  Box,
  Button,
  Chip,
  Drawer,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  styled,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-red.svg';
import { ReactComponent as Filter } from '../../assets/filter.svg';
import { ReactComponent as SearchIconMobile } from '../../assets/search-icon-mobile.svg';
import {
  FieldSubHeader3,
  StepperNextButton,
} from '../auth/login/styles/styledComponents';
import { CustomizedCheckbox } from './checkbox';
type Anchor = 'bottom';

const anchor = 'bottom';
const SearchBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'auto',
  margin: '10px 0px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
const FilterBox = styled(Box)(({ theme }) => ({
  borderLeft: '1px solid',
  borderColor: theme.palette.text.borderColorLight,
  position: 'absolute',
  top: '0px',
  right: '0px',
  height: '100%',
  zIndex: 1,
  // pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
}));
const SearchIconContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
export default function SearchFilterBox() {
  const handleDelete = () => {
    //TODO: add delete functionality
  };
  const [checkedIndices, setCheckedIndices] = React.useState<number[]>([]);

  const handleChange = (index: number) => {
    const currentIndex = checkedIndices.indexOf(index);
    const newCheckedIndices = [...checkedIndices];

    if (currentIndex === -1) {
      newCheckedIndices.push(index);
    } else {
      newCheckedIndices.splice(currentIndex, 1);
    }

    setCheckedIndices(newCheckedIndices);
  };
  const [state, setState] = React.useState({
    bottom: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };
  const list = (anchor: Anchor) => (
    <Box
      role='presentation'
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '20px 25px',
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Filters</Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List
        sx={(theme: Theme) => ({
          borderTop: `1px solid ${theme.palette.text.borderColorLight}`,
          borderBottom: `1px solid ${theme.palette.text.borderColorLight}`,
          p: '20px 30px',
        })}
      >
        <FieldSubHeader3 sx={{ mb: 2 }}>Fund House</FieldSubHeader3>

        {[
          '360 One Mutual Fund',
          'Aditya Birla Sun Life Mutual Fund',
          'Axis Mutual Fund',
          'Axis Mutual Fund',
        ].map((text, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={{
              'pb': 1,
              '& .MuiTypography-root ': {
                color: checkedIndices.includes(index)
                  ? 'primary.main'
                  : 'text.grayishBlue',
              },
            }}
          >
            <FormControlLabel
              control={
                <CustomizedCheckbox
                  checked={checkedIndices.includes(index)}
                  onChange={() => handleChange(index)}
                />
              }
              label={text}
            />

            {/* <ListItemButton>
                            <ListItemText primary={text} />
                            <Radio
                                checked={selectedValue === 'b'}
                                onChange={handleChange}
                                value="b"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'B' }}
                            />
                        </ListItemButton> */}
          </ListItem>
        ))}
      </List>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        m='20px 20px'
      >
        <StepperNextButton sx={{ minWidth: { xs: '160px' } }}>Apply</StepperNextButton>
      </Stack>
    </Box>
  );
  return (
    <>
      <SearchBox>
        <SearchIconContainer>
          <SearchIconMobile />
        </SearchIconContainer>
        <TextField
          id='filled-basic'
          variant='filled'
          placeholder='Search By AMC'
          fullWidth
          sx={(theme: Theme) => ({
            '& .MuiInputBase-root ': {
              'color': 'inherit',
              'backgroundColor': 'text.lightGray',
              'borderTopLeftRadius': '5px',
              'borderTopRightRadius': '5px',
              '& .MuiInputBase-input': {
                padding: '15px 12px 15px 40px',
                backgroundColor: 'text.lightGray',
                borderRadius: '8px 8px 0px 0px',
                fontWeight: '500',
                fontSize: '14px',
                color: 'text.labelColor',
                border: `1px solid ${theme.palette.text.borderColorLight}`,
                borderBottom: `2px solid ${theme.palette.primary.main}`,
              },
            },
          })}
        />
        <FilterBox>
          <Button
            startIcon={<Filter />}
            onClick={toggleDrawer(anchor, true)}
          >
            Filter
          </Button>
          <Drawer
            sx={{
              '& .MuiPaper-root ': {
                borderRadius: '30px 30px 0px 0px',
                backgroundColor: 'common.white',
              },
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </FilterBox>
      </SearchBox>
      <Box
        sx={(theme: Theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        })}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography sx={{ fontWeight: 600 }}>
            3 <Typography component={'span'}>Fund House selected</Typography>
          </Typography>

          <Stack
            direction='row'
            alignItems='center'
            gap='5px'
          >
            <Button
              sx={{ color: 'text.clearText', fontSize: '14px', fontWeight: 500 }}
              startIcon={<DeleteIcon />}
            >
              Clear All
            </Button>
          </Stack>
        </Stack>
        <Box
          sx={(theme: Theme) => ({
            borderTop: `1px dashed ${theme.palette.text.borderColorLight}`,
            borderBottom: `1px dashed ${theme.palette.text.borderColorLight}`,
            padding: '20px',
            my: 1,
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
          })}
        >
          {[1, 2, 3].map((chips, index) => {
            return (
              <Chip
                key={chips}
                sx={{
                  '&.MuiButtonBase-root': {
                    'borderRadius': '7px',
                    'backgroundColor': 'primary.main',
                    'display': 'inline-flex',
                    'padding': '8px 10px',
                    'justifyContent': 'center',
                    'aligntems': 'center',
                    'gap': '5px',
                    'minWidth': 'max-content',
                    'transition': 'all 300ms',
                    '& .MuiChip-label': {
                      color: 'common.white',
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                    '& .MuiSvgIcon-root ': {
                      color: 'common.white',
                      opacity: 0.75,
                    },
                  },
                }}
                label=' Fund'
                variant='outlined'
                // onClick={handleChipClick}
                onDelete={handleDelete}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
}
