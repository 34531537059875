import { Box, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useRootContext } from '../../data/root.context';
import StyledRadioButtonGroup from '../../forms/FormStyledRadioButton';
import { FieldSubHeader2 } from '../../onboarding/styles/styledComponents';
import { CommonTable } from '../../transactionDashboard/investorTable';
import { createConvertedOutputArray } from '../common';
import Layout from '../Layout';
import { useGetInvestorSystematicPlanDataMutation } from '../slice';
import { pauseCancelColumns } from '../types';
import { SelectedOption, SystematicPlanData } from './types';

function PauseCancel() {
  const { showToast } = useRootContext();
  const navigate = useNavigate();

  const [getSystematicPlanData] = useGetInvestorSystematicPlanDataMutation();
  const [systematicPlanData, setSystematicPlanData] = useState<{
    investorDetails: object;
    allData: SystematicPlanData;
  }>({ investorDetails: {}, allData: { sipData: [], stpData: [], swpData: [] } });
  const [selectedOption, setSelectedOption] = useState<SelectedOption>('sip');
  const pan: string = useSelector((state: any) => state.transactions.pan);

  useEffect(() => {
    async function getInvestorSystematicPlanSchemes() {
      const payload = {
        investorPan: pan.toUpperCase(),
      };
      try {
        let response = await getSystematicPlanData(payload).unwrap();
        setSystematicPlanData({
          investorDetails: response.investorDetails,
          allData: response.systematicPlanDetails,
        });
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    }
    getInvestorSystematicPlanSchemes();
  }, []);

  function getRows() {
    const data = systematicPlanData.allData[`${selectedOption}Data`];

    const outputArray = createConvertedOutputArray(
      data?.map((item: any) => ({
        fundName: item?.amc,
        schemeDesc: item?.schemeDesc,
        folioNo: item?.folio,
        mode: selectedOption.toUpperCase(),
        ...item,
      })),
    );

    return outputArray;
  }

  const continueButtonHandler = (row: any) => {
    const trtType = selectedOption === 'swp' ? 'SWPC' : 'STPC';
    if (selectedOption === 'sip') {
      navigate('investment-details', {
        state: {
          previousURL: 'pause-cancel',
          schemeDetails: row,
        },
      });
    } else {
      navigate('confirmation', {
        state: {
          payload: {
            schemeDetails: row,
          },
          previousURL: 'pause-cancel',
          trtType: trtType,
        },
      });
    }
  };

  function selectOption() {
    return (
      <>
        <FieldSubHeader2
          sx={(theme: Theme) => ({ mb: 1, color: theme.palette.primary.main })}
        >
          Select Option:
        </FieldSubHeader2>
        <Box mb={4}>
          <StyledRadioButtonGroup
            options={[
              { label: 'SIP', value: 'sip' },
              { label: 'SWP', value: 'swp' },
              { label: 'STP', value: 'stp' },
            ]}
            value={selectedOption}
            handleChange={e => setSelectedOption(e.target.value as 'sip' | 'swp' | 'stp')}
          />
        </Box>
      </>
    );
  }

  return (
    <Layout
      title={'Pause/Cancellation Module'}
      subtitle='Please select the scheme you want to pause/cancellation'
    >
      {selectOption()}
      <CommonTable
        rows={getRows()} //TODO : optimise this line
        columns={Object.values(pauseCancelColumns)}
        start={0} // Starting page indexx
        limit={10} // Limit given
        continueButtonHandler={continueButtonHandler}
        columnsToShow={Object.keys(pauseCancelColumns)}
        useColumns={true}
      />
    </Layout>
  );
}

export default PauseCancel;
