import { Box, Typography } from '@mui/material';
import { FieldValidator, useField } from 'formik';
import React, { useState } from 'react';

import { FormSelectInput } from '../../forms/FormSelect';
import StyledRadioButtonGroup from '../../forms/FormStyledRadioButton';

interface EuinsFieldsProps {
  name: string;
  allEUINs?: any;
  defaultValue?: string;
  initialValue?: string;
  disabled?: boolean;
}

const EuinsFields: React.FC<EuinsFieldsProps> = ({
  name,
  allEUINs = [],
  defaultValue = '',
  initialValue,
  disabled = false,
}) => {
  const [selectedEuinValue, setSelectedEuinValue] = useState<string>(
    initialValue || 'yes',
  );

  const validate: FieldValidator = (value: any) => {
    let errors: string | undefined;
    if (selectedEuinValue === 'yes' && !value) {
      errors = 'EUIN is required when "Yes" is selected';
    }
    return errors;
  };

  const [field, meta, helpers] = useField({ name, validate });

  const valueChangeHandler = async (e: any) => {
    if (e.target.value === 'no') {
      helpers.setValue('');
    }
    setSelectedEuinValue(e.target.value);
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{
            color: 'text.valueColor',
            mb: 2,
            fontWeight: 500,
            fontSize: { xs: '12px', lg: '14px', xl: '16px' },
          }}
        >
          EUIN
        </Typography>
        <StyledRadioButtonGroup
          options={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ]}
          disabled={disabled}
          value={selectedEuinValue}
          handleChange={valueChangeHandler}
        />
      </Box>

      {selectedEuinValue === 'yes' ? (
        <FormSelectInput
          name={name}
          searchable
          searchPlaceholder={'Search EUINs'}
          label='Select EUIN'
          options={allEUINs?.map((item: any) => ({
            label: `${item.euin} - ${item.name}`,
            value: item.euin,
          }))}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      ) : (
        <Box
          sx={{
            bgcolor: 'text.lightGray',
            padding: '20px',
            borderRadius: '5px',
            maxWidth: '600px',
          }}
        >
          <Typography sx={{ color: 'text.valueColor', fontSize: '12px' }}>
            I/We hereby confirm that the EUIN box has been intentionally left blank by
            me/us as this is an execution-only transaction without any interaction or
            advice by any personnel of the above distributor or notwithstanding the advice
            of in-appropriateness, if any provided by any personnel of the distributor and
            the distributor has not charged any advisory fees on this transaction
          </Typography>
        </Box>
      )}
    </>
  );
};

export default EuinsFields;
