import { RowData, Scheme } from '../types';

export const arnRegex = /.+-\d+$/;

export const rowCreationForSchemes = (data: Scheme[]): RowData[] => {
  return data?.map(item => {
    return {
      fundName: item.amcName,
      schemeDesc: item.schemeName,
      folioNo: item.folio,
      availableAmount: item.nav * item.units,
      fund: item.fund,
    };
  });
};

export const createConvertedOutputArray = (
  data: RowData[],
  filterByKey?: string,
): Record<string, string | number>[] => {
  if (filterByKey) {
    return data
      ?.filter(rowData => rowData?.[filterByKey])
      ?.map(rowData => ({
        ...rowData,
      }));
  }
  return data?.map(rowData => ({
    ...rowData,
  }));
};
