import { Box, Grid, Typography } from '@mui/material';

export interface CardItem {
  label: string;
  route: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface CardGroupsProps {
  data: CardItem[];
  handleRoutes: (route: string) => void;
}

function CardGroups({ data, handleRoutes }: CardGroupsProps) {
  return (
    <>
      <Grid
        container
        spacing={2}
        rowGap='20px'
      >
        {data.map((item: CardItem, index: number) => (
          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={3}
            key={index}
          >
            <Box
              sx={{
                'borderRadius': '15px',
                'backgroundColor': 'common.white',
                'border': '0.3px solid',
                'borderColor': 'text.borderColorDark',
                'padding': { xs: '20px 15px', sm: '15px 15px 15px 15px ' },
                'cursor': 'pointer',
                'transition': 'all 0.3s',
                'maxWidth': { xs: '100%', lg: '280px' },
                'height': '100%',
                'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
                '&:hover': {
                  boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.10)',
                },
              }}
              onClick={() => handleRoutes(item.route)}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    borderRadius: '10px',
                    backgroundColor: 'text.lightGrayishBlue2',
                    padding: '10px 12px',
                    boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <item.icon />
                </Box>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: 'text.inputLabelText',
                    textAlign: { xs: 'center', sm: 'unset' },
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default CardGroups;
