import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';

import InvestorProfileIcon from '../../../../../assets/investor-profile.png';
import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import { useNewMfsInvestorContext } from '../context/NewMfsInvestorContext';

export function ConfirmationProfile() {
  const {
    state: { investmentDetails, investorDetails },
  } = useNewMfsInvestorContext();

  const { arnCode, subArnCode, subBrokerCode } = investmentDetails || {};
  const { name, pan, mobile, email } = investorDetails || {};

  return (
    <Investorcard
      sx={{ bgcolor: 'text.rightProfileCard', display: { xs: 'none', md: 'block' } }}
    >
      <Box
        sx={{
          padding: '30px 20px',
          borderBottom: '0.5px solid',
          borderColor: 'text.borderColorLight',
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'primary.main' }}>
            Investor Profile
          </Typography>

          <CardMedia
            component='img'
            image={InvestorProfileIcon}
            alt='Mutual Fund Pooled Investment'
            sx={{ width: '44px', height: '40px' }}
          />
        </Stack>
        <Grid
          container
          spacing={2}
          sx={{
            'my': 1.5,
            '& .MuiTypography-root': {
              wordBreak: 'break-word',
            },
          }}
        >
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Name</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {name || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>PAN</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {pan || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Mobile Number</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {mobile || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Email</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {email || 'NA'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '30px 20px' }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'primary.main' }}>
            Distributor Profile
          </Typography>
        </Stack>
        <Grid
          container
          spacing={2}
          sx={{
            'my': 1.5,
            '& .MuiTypography-root': {
              wordBreak: 'break-word',
            },
          }}
        >
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>ARN Code</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {arnCode || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              {/* {TODO: dont have the fiels for ARN Name} */}
              <Typography sx={{ color: 'text.labelColor' }}>ARN Name</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Sub ARN Name</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {subArnCode || 'NA'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Investorcard>
  );
}
