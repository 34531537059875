import apiSlice from '../../service/api';
const employeeManagementSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEmployees: builder.query({
      query: params => ({
        url: '/admin/fetch-employees',
        method: 'GET',
        params,
      }),
    }),
    disabledistrubutor: builder.mutation({
      query: payload => ({
        url: '/admin/disable-distributor',
        method: 'POST',
        body: payload,
      }),
    }),
    enabledistrubutor: builder.mutation({
      query: payload => ({
        url: '/admin/enable-distributor',
        method: 'POST',
        body: payload,
      }),
    }),
    bulkUploaddistributors: builder.mutation({
      query: payload => ({
        url: '/admin/bulkUpload-distributors',
        method: 'POST',
        body: payload,
      }),
    }),
    downloadTemplateBulkUpload: builder.query({
      query: ({ uploadDoc, id }) => ({
        url: `/uploads/${uploadDoc}/${id}/BulkUploadSheet`,
        method: 'GET',
        cache: 'no-cache',
        responseHandler: async response => {
          const blob = await response.blob();
          const reader = new FileReader();
          return new Promise<string>((resolve, reject) => {
            reader.onload = () => {
              const base64String = reader.result as string;
              resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        },
      }),
    }),
    addDistributor: builder.mutation({
      query: payload => ({
        url: '/admin/add-distributor',
        method: 'POST',
        body: payload,
      }),
    }),
    fetchAllEmployees: builder.query({
      query: () => ({
        url: '/admin/fetch-employees',
        method: 'GET',
      }),
    }),
    updateDistributor: builder.mutation({
      query: payload => ({
        url: '/admin/update-distributor',
        method: 'POST',
        body: payload,
      }),
    }),
    addEmployeeAsAdmin: builder.mutation({
      query: payload => ({
        url: '/admin/add-admin',
        method: 'POST',
        body: payload,
      }),
    }),
    removeEmployeeAsAdmin: builder.mutation({
      query: payload => ({
        url: '/admin/remove-admin',
        method: 'POST',
        body: payload,
      }),
    }),
    adminSearchEmployees: builder.query({
      query: params => ({
        url: '/admin/search-employees',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetEmployeesQuery,
  useDisabledistrubutorMutation,
  useEnabledistrubutorMutation,
  useBulkUploaddistributorsMutation,
  useAddDistributorMutation,
  useFetchAllEmployeesQuery,
  useUpdateDistributorMutation,
  useAddEmployeeAsAdminMutation,
  useRemoveEmployeeAsAdminMutation,
  useLazyAdminSearchEmployeesQuery,
  useLazyDownloadTemplateBulkUploadQuery,
} = employeeManagementSlice;
