import { Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { useRootContext } from '../../../../data/root.context';
import { FormSelectInput } from '../../../../forms/FormSelect';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput from '../../../../forms/FormTextInput';
import { setFatcaDetails, setStepNumber } from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import {
  useGetDetailsFromPincodeMutation,
  useGetFatcaMutation,
  useSaveFatcaDetailsMutation,
} from '../slice';

interface SuccessResponse {
  data: {
    statusCode: number;
    data: any;
  };
}
interface StateAndDistrictDetails {
  Country: string;
  District: string;
  State: string;
}

interface Address {
  country: string;
  district: string;
  state: string;
}

const FatcaDetails: React.FC = () => {
  const {
    state: {
      ackNumber,
      kycDetails = {},
      EKYCDetails = {},
      kycBankDetails = {},
      fatcaDetails = { data: {} },
      District,
      statesList,
      countriesList,
    },
    dispatch: dispatchContext,
  } = useNewSubscriberContext();
  const { data: { ListFatcaDetails = [] } = {} } = fatcaDetails || {};
  const [_existingFatcaDetails] = ListFatcaDetails;

  const { data = {} } = kycDetails || {};
  const EKYCdata = EKYCDetails?.data?.result?.PERSONAL_DETAILS || {};

  const [address, setAddress] = useState<Address>({
    country: '',
    district: '',
    state: '',
  });
  // const [isAddressLoading, setIsAddressLoading] = useState(true);
  //const [residentState, setResidentState] = useState("Y");
  const { showToast } = useRootContext();
  const [getDetailsFromPincode] = useGetDetailsFromPincodeMutation();
  const [saveFatcaDetails] = useSaveFatcaDetailsMutation();
  const [getFatcaDetails] = useGetFatcaMutation();
  useEffect(() => {
    (async function () {
      try {
        const pincode = [
          EKYCdata?.PERM_PIN,
          kycBankDetails?.PinCode,
          data?.pin_code1,
          _existingFatcaDetails?.PinCode,
        ].find(Boolean);

        if (!pincode) return;

        const response = await getDetailsFromPincode(pincode);
        if ('data' in response) {
          const { Country, District, State } = response.data.data[0];
          setAddress({
            country: Country,
            district: District,
            state: State,
          });
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    })();
  }, []);

  const initialValues = _existingFatcaDetails
    ? {
        resident: _existingFatcaDetails.Country === 'IN' ? 'Y' : 'N',
        usResident: _existingFatcaDetails.UsPerson === 'N' ? 'N' : 'Y',
        line1: _existingFatcaDetails.Address,
        line2: '',
        pincode: _existingFatcaDetails.PinCode || '',
        state: _existingFatcaDetails.State || '',
        district: _existingFatcaDetails.City || District || '',
        country: _existingFatcaDetails.Country,
      }
    : {
        resident: 'Y',
        usResident: 'N',
        line1: EKYCdata?.PERM_LINE1 || kycBankDetails?.Address1 || data.txtAddress1 || '',
        line2: EKYCdata?.PERM_LINE2 || kycBankDetails?.Address2 || data.txtAddress2 || '',
        pincode: EKYCdata?.PERM_PIN || kycBankDetails?.PinCode || data.pin_code1 || '',
        district:
          address?.district ||
          EKYCdata?.PERM_DIST ||
          kycBankDetails?.District ||
          data.txtCity1 ||
          '',
        state: address?.state || kycBankDetails?.StateName || data.State1 || '',
        country: '',
      };
  const validationSchema = Yup.object().shape({
    resident: Yup.string().required('I am tax resident of India is required'),
    usResident: Yup.string().required('I am a US resident is required'),
    line1: Yup.string()
      .required('Address line 1 is required')
      .matches(/^(?!\s+$).+$/, 'Address cannot be empty'),
    pincode: Yup.string()
      .required('Pincode is required')
      .matches(/^[0-9]{6}$/, 'Invalid Pincode'),
    district: Yup.string().required('District is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
  });
  const handleSubmit = async (values: any) => {
    try {
      const params = {
        AckNumber: ackNumber,
        UsSelection: 'N',
        ResidenceforTaxPursposes: 'Y',
        BirthCountry: values.country,
        txtAddress1: values.line1,
        txtAddress2: values.line2,
        txtcity1: values.district,
        pin_code1: values.pincode,
        TinNumber1: 'ZGHPA9555K',
        State1: values.state,
      };
      await saveFatcaDetails(params).unwrap();

      const updatedFatcaDetails = await getFatcaDetails({
        AckNumber: ackNumber,
      }).unwrap();
      dispatchContext(setFatcaDetails(updatedFatcaDetails));
      dispatchContext(setStepNumber(3));
    } catch (error) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };
  function isSuccessResponse(response: any): response is SuccessResponse {
    return 'data' in response && 'statusCode' in response.data;
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, setFieldTouched, setValues }) => (
        <Form>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '16px' },
              fontWeight: 700,
              color: 'text.primary',
              mb: 2,
            }}
          >
            Address in the Jurisdiction of Tax Residence*
          </Typography>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name='line1'
                label='Address 1'
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name='line2'
                label='Address 2 (Optional)'
                required={false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name='pincode'
                label='PIN Code'
                onBlur={async () => {
                  setFieldTouched('pincode', true);
                  if (!errors['pincode']) {
                    const response = await getDetailsFromPincode({
                      pincode: values.pincode,
                    });
                    if (
                      isSuccessResponse(response) &&
                      +response.data.statusCode === 10000
                    ) {
                      const data = response.data.data;
                      const { Country, District, State } = data[0];
                      setValues({
                        ...values,
                        state: State,
                        district: District,
                        country: Country,
                      });
                    }
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name='district'
                label='City / District'
                disabled={
                  Boolean(EKYCdata?.PERM_DIST) ||
                  Boolean(values.pincode && values?.district)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormSelectInput
                name='state'
                label='State'
                defaultValue={''}
                options={statesList}
                disabled={Boolean(values.pincode && values?.state)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormSelectInput
                name='country'
                label='Place of Birth'
                defaultValue={''}
                options={countriesList}
              />
            </Grid>
          </Grid>

          <SubmitButton
            sx={{
              '&.MuiButtonBase-root': {
                borderRadius: '5px',
                minWidth: { xs: '100%', sm: '200px' },
                mt: 5,
              },
            }}
            label='Save & Proceed'
          >
            Save & Proceed
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default FatcaDetails;
