import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import InfoIcon from '../../../../../assets/info-icon.svg';
import { SubTitle } from '../../../../auth/login/styles/styledComponents';
import { useRootContext } from '../../../../data/root.context';
import { FormFileUpload } from '../../../../forms/FormFileUpload';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import { FieldSubHeader3 } from '../../../../onboarding/styles/styledComponents';
import { SUCCESS_RESPONSE_CODE } from '..';
import {
  resetOldAccountType,
  setDocumentDetails,
  setStepNumber,
} from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import {
  useGetEpranKitMutation,
  useSaveEpranKitMutation,
  useSavePhotoAndSignatureDetailsMutation,
} from '../slice';
import { base64SizeInBytes } from '../utils';
const validationSchema = Yup.object().shape({});

const documentsMap: { label: string; fileName: string }[] = [
  { label: 'Photograph', fileName: 'photograph' },
  { label: 'Signature', fileName: 'signature' },
  { label: 'Cancelled Cheque Tier 1', fileName: 'cancelledChequeTier1' },
  { label: 'Cancelled Cheque Tier 2', fileName: 'cancelledChequeTier2' },
  { label: 'PAN Card', fileName: 'pan' },
];

interface InitialValues {
  photograph?: File;
  signature?: File;
  cancelledChequeTier1?: File;
  pan?: File;
  cancelledChequeTier2?: File;
  pranKitType: string;
  pranLanguage: string;
}

const UploadDocument: React.FC = () => {
  const [savePhotoAndSignatureDetails] = useSavePhotoAndSignatureDetailsMutation();
  const { showToast } = useRootContext();
  const [saveEpranKit] = useSaveEpranKitMutation();
  const [getEpranKit] = useGetEpranKitMutation();
  const [initialValues, setInitialValues] = useState<InitialValues>({
    photograph: undefined,
    signature: undefined,
    cancelledChequeTier1: undefined,
    pan: undefined,
    cancelledChequeTier2: undefined,
    pranKitType: 'E',
    pranLanguage: '1',
  });
  const {
    state: {
      ackNumber: AckNumber,
      pennyDropResponse: tier1PennyDrop,
      schemeDetails: npsSchemeDetails,
      isBackToSummary,
      tier2PennyDrop,
      EKYCDetails = {},
      documentDetails,
    },
    dispatch,
  } = useNewSubscriberContext();
  const isTier1AccountValid = tier1PennyDrop?.data?.result?.bankTxnStatus;
  const isTier2AccountValid = tier2PennyDrop?.data?.result?.bankTxnStatus;

  const { statusCode, data } = npsSchemeDetails || {};

  const accountType =
    +statusCode === SUCCESS_RESPONSE_CODE && data.AccountType ? data.AccountType : '2';

  const hasTierTwo = +accountType === 2;

  const shouldShowDocument = (fileName: string) => {
    switch (fileName) {
      case 'cancelledChequeTier1': {
        return !isTier1AccountValid;
      }
      case 'cancelledChequeTier2': {
        return hasTierTwo && !isTier2AccountValid;
      }
      case 'pan': {
        return hasTierTwo;
      }
    }
    return true;
  };
  const validationSchema = Yup.object().shape({
    pranKitType: Yup.string().required('Select the format of PRAN kit'),
    pranLanguage: Yup.string().required('Select preferred language on PRAN Card'),
  });

  useEffect(() => {
    if (documentDetails?.isPersisted) {
      setInitialValues(documentDetails.documents);
      return;
    }

    const { IMAGE = [] } = EKYCDetails?.data?.result?.IMAGE_DETAILS || {};

    const photographDetails =
      IMAGE.find((details: any) => details.IMAGE_NAME === 'Photograph') || {};
    const signatureDetails =
      IMAGE.find((details: any) => details.IMAGE_NAME === 'Signature') || {};

    [photographDetails, signatureDetails]
      .filter(
        document =>
          !!document.IMAGE_DATA && base64SizeInBytes(document.IMAGE_DATA) < 2048,
      )
      .forEach(document => {
        const byteCharacters = atob(document.IMAGE_DATA);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: document.IMAGE_TYPE });
        setInitialValues(prev => ({
          ...prev,
          [document.IMAGE_NAME === 'Photograph' ? 'photograph' : 'signature']: new File(
            [blob],
            document.IMAGE_NAME,
          ),
        }));
      });
  }, []);

  const handleSubmit = async (values: InitialValues) => {
    try {
      const filesShown = documentsMap.filter(({ fileName }) =>
        shouldShowDocument(fileName),
      );
      type fileType = Omit<InitialValues, 'pranKitType' | 'pranLanguage'>;
      const fileContent: { [key in keyof fileType]: string } = {};
      const promises: Promise<string>[] = [];
      for (const file of filesShown) {
        promises.push(
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
              // get file content
              const base64 = e.target!.result as string;
              fileContent[file.fileName as keyof fileType] = base64.split(',')[1];
              resolve('');
            };

            reader.onerror = function (error) {
              reject(error);
            };

            reader.readAsDataURL(values[file.fileName as keyof fileType]!);
          }),
        );
      }
      await Promise.all(promises);
      const payload = {
        AckNumber,
        SigImage: fileContent['signature'] || '',
        SignatureDocumentName: values.signature?.name || '',
        SignatureDocumentSize: values.signature?.size || 0,
        ChqImage: fileContent['cancelledChequeTier1'] || '',
        ChqDocumentName: values.cancelledChequeTier1?.name || '',
        ChqDocumentSize: values.cancelledChequeTier1?.size || 0,
        Tier1ChequeDocumentName: values.cancelledChequeTier2?.name || '',
        Tier1ChequeDocumentSize: values.cancelledChequeTier2?.size || 0,
        Tier1ChequeImage: fileContent['cancelledChequeTier2'] || '',
        PhotoImage: fileContent['photograph'] || '',
        PhotoDocumentName: values.photograph?.name || '',
        PhotoDocumentSize: values.photograph?.size || 0,
        ...(hasTierTwo && {
          PanImage: fileContent['pan'] || '',
          PanDocumentName: values.pan?.name || '',
          PanDocumentSize: values.pan?.size || 0,
        }),
        NRIPassport1: JSON.stringify([]),
        NRIPassport2: JSON.stringify([]),
      };
      const response = await savePhotoAndSignatureDetails(payload).unwrap();
      if (Number(response.statusCode) !== SUCCESS_RESPONSE_CODE) {
        throw new Error(response.message || 'Error saving documents');
      }
      const pranDetails = {
        AckNumber,
        Physicaldispatchrequired: values.pranKitType,
        EprankitLanguage: values.pranLanguage,
      };
      await saveEpranKit(pranDetails).unwrap();
      setDocumentDetails(values)(dispatch);
      if (isBackToSummary) {
        resetOldAccountType(dispatch);
      }
      dispatch(setStepNumber(8));
    } catch (error) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  useEffect(() => {
    const fetchEpranDetails = async () => {
      try {
        const response = await getEpranKit({ AckNumber }).unwrap();
        if (Number(response.statusCode) !== SUCCESS_RESPONSE_CODE) return;
        const { data = {} } = response;
        const { EprankitLanguage, Physicaldispatchrequired } = data;
        setInitialValues(prev => ({
          ...prev,
          pranKitType: Physicaldispatchrequired || 'E',
          pranLanguage: EprankitLanguage || '1',
        }));
      } catch (error) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    };

    fetchEpranDetails();
  }, [AckNumber]);

  const handleValidation = (values: Record<string, any>) => {
    const errors: Record<string, any> = {};
    documentsMap.forEach(document => {
      if (shouldShowDocument(document.fileName) && !values[document.fileName]) {
        errors[document.fileName] = `Please upload ${document.label} file`;
      }
    });
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validate={values => handleValidation(values)}
      onSubmit={async values => handleSubmit(values)}
    >
      {() => (
        <Form>
          <Box
            sx={{
              borderRadius: '7px',
              backgroundColor: '#F5F5F5',
              display: 'flex',
              padding: '16px',
              gap: '10px',
              mb: 3,
            }}
          >
            <img
              src={InfoIcon}
              alt='info-icon'
            />
            <SubTitle sx={{ fontWeight: 500 }}>
              All files should be in JPG or PNG format & File size should be between 2KB
              and 2MB.
            </SubTitle>
          </Box>

          <Grid
            container
            spacing={2}
          >
            {documentsMap.map((file, index) => {
              if (shouldShowDocument(file.fileName))
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    key={index}
                  >
                    <Typography
                      sx={{
                        color: '#212B36',
                        fontSize: '16px',
                        fontWeight: '500',
                        my: 2,
                        textAlign: { xs: 'center', sm: 'left' },
                      }}
                    >
                      {file.label}
                    </Typography>
                    <FormFileUpload name={file.fileName} />
                  </Grid>
                );
              return null;
            })}
          </Grid>
          <Box
            sx={{
              backgroundColor: 'text.boxColor',
              borderRadius: '7px',
              padding: '15px 15px',
              my: 3,
            }}
          >
            <FieldSubHeader3 sx={{ fontWeight: 700 }}>PRAN Kit Selection</FieldSubHeader3>
          </Box>
          <Box
            sx={{
              borderRadius: '7px',
              backgroundColor: '#F5F5F5',
              display: 'flex',
              padding: '16px',
              gap: '10px',
              mb: 3,
            }}
          >
            <img
              src={InfoIcon}
              alt='info-icon'
            />
            <SubTitle sx={{ fontWeight: 500 }}>
              KCRA charges RS.4 if you opt for ePRAN Kit, It is much faster and safer than
              a written/postal communication.
            </SubTitle>
          </Box>

          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#111927', mb: 2 }}>
            Select the desired format of PRAN kit you wish to receive*
          </Typography>

          <FormStyledRadioButton
            options={[
              { label: 'Digital (ePRAN)', value: 'E' },
              { label: 'Physical', value: 'P' },
            ]}
            name='pranKitType'
          />

          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#111927', my: 2 }}>
            Preferred Language on PRAN Card*
          </Typography>

          <FormStyledRadioButton
            options={[
              { label: 'English', value: '1' },
              { label: 'Hindi', value: '2' },
            ]}
            name='pranLanguage'
          />

          <SubmitButton
            sx={{
              '&.MuiButtonBase-root': {
                borderRadius: '5px',
                minWidth: { xs: '100%', sm: '200px' },
                mt: 5,
              },
            }}
            label='Save & Proceed'
          >
            Save & Proceed
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default UploadDocument;
