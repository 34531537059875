import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import React, { ChangeEvent, useRef } from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UniqueVisuallyHiddenInput: React.FC<{
  id: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({ id, onChange }) => (
  <VisuallyHiddenInput
    type='file'
    id={id}
    onChange={onChange}
  />
);

interface FileUploadProps {
  index: number;
  files: File[];
  fileName: string;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

export const FileUpload: React.FC<FileUploadProps> = ({ index, files, setFiles }) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const dummyFile = useRef<File[]>([]);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = e.target.files && e.target.files[0];
    if (!fileUploaded) return;
    const newFiles = [...files];
    newFiles[index] = fileUploaded;
    setFiles(newFiles);
  };

  const handleClick = () => {
    hiddenFileInputRef.current?.click();
  };

  const removeFile = () => {
    const OriginalFiles: File[] = files.map(f => f);
    OriginalFiles[index] = dummyFile.current[0] as unknown as File;
    setFiles(OriginalFiles);
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.value = '';
    }
  };

  return (
    <Box
      key={index}
      sx={{
        borderRadius: '7px',
        border: '1px solid',
        borderColor: 'text.mainLight2',
        padding: '10px',
        // width: '260px',
        minHeight: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        // alignItems: 'stretch',
      }}
    >
      <Box
        sx={(theme: Theme) => ({
          borderRadius: '7px',
          border: files[index]
            ? `0.6px dashed ${theme.palette.primary.main}`
            : `0.6px dashed ${theme.palette.text.shadyGray}`,
          background: files[index]
            ? 'rgba(32, 87, 166, 0.12)'
            : 'rgba(200, 200, 200, 0.12)',
          height: '160px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '40px',
          textAlign: 'center',
          position: 'relative',
        })}
      >
        <>
          <Typography
            variant='subtitle2'
            sx={{
              color: 'text.labelColor',
              wordBreak: 'break-word',
            }}
          >
            {files[index] ? files[index].name : 'No file choosen'}
          </Typography>
          {files[index] && (
            <IconButton
              onClick={() => removeFile()}
              sx={{ position: 'absolute', top: '5px', right: '5px' }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant='contained'
          component='label'
          sx={{
            'borderRadius': '7px',
            'bgcolor': 'primary.main',
            'minWidth': { xs: '100%', lg: '200px' },
            'color': 'common.white',
            'fontSize': '14px',
            'fontWeight': '500',
            '&:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={handleClick}
        >
          Browse file
          <UniqueVisuallyHiddenInput
            id={`fileInput-${index}`}
            onChange={e => handleUpload(e)}
          />
        </Button>
      </Box>
    </Box>
  );
};
