import { Box, Grid, Theme, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import {
  Investorcard,
  StepperNextButton,
} from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { PageNavigator } from '../../common/BackToHomePage';
import { ConfirmationProfile } from '../../common/InvestorProfile';
import { useRootContext } from '../../data/root.context';
import { CheckoutPaymentFormValues } from '../additionalPurchase/types';
import TransactionFactory from '../common/TransactionsFactory';
import {
  useAddItemInSwpMutation,
  useAddItemToSwitchMutation,
  useMakeDirectAdditionalPurchaseMutation,
  useMakeSIPAdditionalPurchaseMutation,
  useMakeSipAndLumpsumAdditionalPurchaseMutation,
  useModifyItemInCartMutation,
  useRedeemptionMutation,
  useStpTransactionMutation,
} from '../slice';
import { FormData } from '../switch/Confirmation';
import { ModeTypeToTrtType, TransactionComponentsType, TransactionType } from '../types';

export default function Confirmation() {
  const location = useLocation();
  const [requestId, setRequestId] = useState('');
  const state: any = location.state;
  const { showToast } = useRootContext();

  const transactionType = state.payload.transactionType;
  const [reedemScheme] = useRedeemptionMutation();
  const [modifyItemInCart] = useModifyItemInCartMutation();
  const [makeSwpTransaction] = useAddItemInSwpMutation();
  const [addItemToSwitch] = useAddItemToSwitchMutation();
  const [stpPost] = useStpTransactionMutation();
  // All AlP APIs.
  const [makeDirectAdditionalPurchase] = useMakeDirectAdditionalPurchaseMutation();
  const [makeSIPAdditionalPurchase] = useMakeSIPAdditionalPurchaseMutation();
  const [makeSipAndLumpsumAdditionalPurchase] =
    useMakeSipAndLumpsumAdditionalPurchaseMutation();
  const navigate = useNavigate();
  // For Payment methods.
  const formikRef = useRef<FormikProps<CheckoutPaymentFormValues>>(null);
  const confirmButtonHandler = async () => {
    try {
      let response;
      if (state.isEditMode) {
        let modifyItemPayload = state.payload;
        await modifyItemInCart(modifyItemPayload).unwrap();
        // Getting details object no success response being received
        response = 'Details updated successfully!';
      } else {
        if (transactionType === 'APL') {
          // Call the API for APL
          const { modeType } = state.payload;
          const commonData = {
            ...state.payload,
            createMandate:
              formikRef?.current?.values?.selectedMandateObj?.value === 'createMandate',
            umrn:
              formikRef?.current?.values?.selectedMandateObj?.value === 'createMandate'
                ? ''
                : formikRef?.current?.values?.umrn,
            selectedMandates: formikRef?.current?.values.selectedMandateObj,
          };
          // for lumpsum
          if (ModeTypeToTrtType[modeType as keyof typeof ModeTypeToTrtType] === 'APL') {
            response = await makeDirectAdditionalPurchase({
              ...commonData,
              pioTransaction: false,
              utrno: '',
              paymentMode: formikRef?.current?.values.paymentMode,
            }).unwrap();
          } else if (
            ModeTypeToTrtType[modeType as keyof typeof ModeTypeToTrtType] === 'ISIP'
          ) {
            // for sip
            response = await makeSIPAdditionalPurchase({
              ...commonData,
              modeOfRegistration: formikRef?.current?.values.modeOfRegistration,
            }).unwrap();
          } else {
            response = await makeSipAndLumpsumAdditionalPurchase({
              ...commonData,
              modeOfRegistration: formikRef?.current?.values.modeOfRegistration,
              paymentMode: formikRef?.current?.values.paymentMode,
            }).unwrap();
          }
          // response = await alpApi(state.payload).unwrap();
        } else if (transactionType === 'RED') {
          // Call the API for RED
          response = await reedemScheme(state.payload).unwrap();
        } else if (transactionType === 'SWP') {
          // Call the API for SWP
          response = await makeSwpTransaction(state.payload).unwrap();
        } else if (transactionType === 'STP') {
          // Call the API for STP
          response = await stpPost(state.payload).unwrap();
        } else {
          // Call the API for other transaction types
          response = await addItemToSwitch(state.payload).unwrap();
        }
        const { message, ihno } = response;
        response = `${message} ${ihno}`;
      }

      if (response) {
        setRequestId(response);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };
  return (
    <>
      <PageNavigator
        title='Confirmation'
        subtitle=' Please check the following details'
        backHandler={() => navigate(-1)}
      />
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          sm={8}
        >
          <Investorcard
            sx={(theme: Theme) => ({
              [theme.breakpoints.down('sm')]: {
                border: 'unset',
                boxShadow: 'unset',
              },
            })}
          >
            {/* Distributor Details for  mobile UI*/}
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'text.borderColorLight',
                borderRadius: '5px',
                padding: '10px',
                mb: 2,
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Typography
                variant='subtitle1'
                sx={{ color: 'primary.main', mb: 2 }}
              >
                Distributor Details
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={6}
                >
                  <FormData
                    label='ARN Name'
                    value='Rakesh Chirra'
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormData
                    label='ARN Code'
                    value='ARN-0037'
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormData
                    label='Sub ARN Code'
                    value='ARN-956'
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormData
                    label='EUIN'
                    value='003215'
                  />
                </Grid>
              </Grid>
            </Box>
            {/* Distributor Details for  mobile UI*/}
            <Box
              sx={(theme: Theme) => ({
                borderTop: {
                  xs: `0.5px dashed ${theme.palette.text.borderColorLight}`,
                  sm: 'unset',
                },
                borderBottom: {
                  xs: 'unset',
                  sm: `0.5px dashed ${theme.palette.text.borderColorLight}`,
                },
                padding: { xs: '10px 0px 0px 0px', sm: '30px 30px 0px 30px' },
              })}
            >
              <Typography
                variant='subtitle1'
                sx={{ color: 'primary.main', mb: 2 }}
              >
                Scheme Details
              </Typography>
              <TransactionFactory
                type={TransactionType[transactionType as keyof typeof TransactionType]}
                component={TransactionComponentsType.Confirmation}
                data={state.payload}
                formikRef={formikRef}
              />
            </Box>
            <Box sx={{ padding: { xs: '0px', sm: '30px' } }}>
              <StepperNextButton
                sx={{ minWidth: { xs: '100%', md: '200px' } }}
                onClick={confirmButtonHandler}
              >
                Confirm
              </StepperNextButton>
            </Box>
          </Investorcard>
        </Grid>
        <Grid
          item
          sm={4}
        >
          <ConfirmationProfile data={state.payload} />
        </Grid>
      </Grid>
      {!!requestId && (
        <CustomDialog
          isVisible={true}
          firstButtonHandler={() => navigate('../')}
          firstButtonTitle='OK'
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                'fontSize': '16px',
                'color': 'text.grayishBlue',
                'my': 2,
                '& >*': {
                  fontWeight: '600',
                },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />

            <Typography variant='body1'>{requestId}</Typography>
          </Box>
        </CustomDialog>
      )}
    </>
  );
}
