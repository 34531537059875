import { Box, Grid, Stack } from '@mui/material';
import { format } from 'date-fns';

import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';
import { calculateValue } from '../redeem/Cart';

const Cart = ({
  data,
  children,
  cartItemslength,
}: {
  data?: any;
  children?: React.ReactNode;
  cartItemslength?: boolean;
}) => {
  return (
    <Box
      sx={{
        'pb': 2,
        'mb': 3,
        '&:nth-of-type(n+2)': {
          borderTop: cartItemslength ? '1px solid ' : 'unset',
          borderColor: cartItemslength ? 'text.borderColorLight' : 'unset',
          pt: cartItemslength ? 3 : 'unset',
        },
      }}
    >
      {children}

      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Grid
          item
          xs={6}
        >
          <Stack gap='10px'>
            <CartLabel>{data?.fundName}</CartLabel>
            <CartValue>{data?.schemeName}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <CartLabel>Amount:</CartLabel>
            <CartValue> ₹ {calculateValue(data, 'amount')}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2}
        >
          <Stack gap='10px'>
            <CartLabel>Units:</CartLabel>
            <CartValue>{calculateValue(data, 'units')}</CartValue>
          </Stack>
        </Grid>

        <Grid
          item
          xs={6}
        >
          <Stack gap='10px'>
            <CartLabel>SWP Day</CartLabel>
            <CartValue>{data?.swpDay}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <CartLabel>Start date</CartLabel>
            <CartValue>{format(new Date(data?.swpStartDate), 'dd/MM/yyyy')}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2}
        >
          <Stack gap='10px'>
            <CartLabel>End date</CartLabel>
            <CartValue>{format(new Date(data?.swpEndDate), 'dd/MM/yyyy')}</CartValue>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cart;
