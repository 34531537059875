import { Logout } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  CardMedia,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Theme,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as AIFIcon } from '../../../assets/aif-onboarding.svg';
import { ReactComponent as ProfileDropdown } from '../../../assets/arrow-down.svg';
import { ReactComponent as DashBoard_Active } from '../../../assets/dashboard-active.svg';
import { ReactComponent as DashBoard } from '../../../assets/dashboard-Icon.svg';
import DashBoardLogo from '../../../assets/dashboard-logo.svg';
import { ReactComponent as UserIcon } from '../../../assets/Distributor-details/user-icon.svg';
import { ReactComponent as Employee_Setup } from '../../../assets/employee-setup.svg';
import { ReactComponent as Employee_Setup_Active } from '../../../assets/employee-setup-active.svg';
import { ReactComponent as FAQActiveIcon } from '../../../assets/faqs-active-icon.svg';
import { ReactComponent as Investors } from '../../../assets/investors.svg';
import { ReactComponent as InvestorActive } from '../../../assets/investors-active.svg';
import { ReactComponent as MenuIcon } from '../../../assets/menu-icon.svg';
import { ReactComponent as NotifyIcon } from '../../../assets/notification-icon.svg';
import profile_icon from '../../../assets/profile-avatar.svg';
import Sidenav_Logo from '../../../assets/sidenav-logo.svg';
import { resetAllSlices } from '../../../store';
import {
  onBoardingStatusCompany,
  onBoardingStatusIndividual,
} from '../../common/constants';
import { useRootContext } from '../../data/root.context';
import { LoginResponse, updateRefreshFlag } from '../../features/login/loginSlice';
import { updateOnBoardingStatus } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import { useLazyGetOnboardingStatusQuery } from '../../onboarding/slices';
// import { TransactionHeader } from '../auth/login/styles/styledComponents';
// import CollapsibleTable from './investorTable';

const menuItems = [
  {
    name: 'Dashboard',
    navigateTo: '/transaction-dashboard/dashboard',
    activeIcon: <DashBoard_Active />,
    icon: <DashBoard />,
    adminOnly: false,
  },
  {
    name: 'My Investors',
    navigateTo: '/transaction-dashboard',
    activeIcon: <InvestorActive />,
    icon: <Investors />,
    adminOnly: false,
  },
  {
    name: 'Reports',
    navigateTo: '/transaction-dashboard/mis',
    activeIcon: <DashBoard_Active />,
    icon: <DashBoard />,
    adminOnly: false,
  },
  {
    name: 'Queries',
    navigateTo: '/transaction-dashboard/queries',
    activeIcon: <AIFIcon />, //TODO: change this icon
    icon: <AIFIcon />,
    adminOnly: false,
  },
  {
    name: 'Employee Setup',
    navigateTo: '/transaction-dashboard/employee-setup',
    activeIcon: <Employee_Setup_Active />,
    icon: <Employee_Setup />,
    adminOnly: true,
  },
  {
    name: 'FAQs',
    navigateTo: '/transaction-dashboard/faqs',
    activeIcon: <FAQActiveIcon />,
    icon: <Employee_Setup />,
    adminOnly: false,
  },
];

const drawerWidth = 240;
const hideLeftcontantpaths = ['distributor', 'investor-onboarding', 'investor-details'];
const breakpoint = {
  md: 768,
  md2: 1024,
  xl: 1600,
};

const drawerWidthByBreakpoint = {
  md: `calc( ${drawerWidth}px - 40px)`,
  xl: `calc( ${drawerWidth}px + 60px)`,
};
const mainWidthByBreakpoint = {
  md: `calc( 100% - ${drawerWidthByBreakpoint.md})`,
  xl: `calc( 100% - ${drawerWidthByBreakpoint.xl})`,
};
interface Props {
  window?: () => Window;
  children: any;
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const filterMenuItems = (isAdmin: boolean) => {
  if (isAdmin) {
    return menuItems;
  } else {
    // Return menu items visible to non-admin users
    return menuItems.filter(item => item.adminOnly !== true);
  }
};
export function TransactionDashboard(props: Props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [getOnboardingStatus, onboardingStatusData] = useLazyGetOnboardingStatusQuery();

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [selectedItem, setSelectedItem] = React.useState<string>(
    capitalizeFirstLetter(pathname?.split('/').pop() || 'My Investors'),
  );
  const { showToast } = useRootContext();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { pan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  const { admin, isIndividual, onBoardingStatus, refreshFlag } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );

  const filteredMenuItems = filterMenuItems(admin);

  const isRouteInMenuItem = (route: string) => {
    return filteredMenuItems.some(item => item.navigateTo.includes(route));
  };
  const showLeftContent = !hideLeftcontantpaths.some(path => pathname.includes(path));

  const navigate = useNavigate();
  useEffect(() => {
    if (
      refreshFlag &&
      pathname.startsWith('/transaction-dashboard/') &&
      pathname !== '/transaction-dashboard' &&
      pathname !== '/transaction-dashboard/'
    ) {
      dispatch(updateRefreshFlag(false));
      navigate('/transaction-dashboard/');
    }
  }, []);

  useEffect(() => {
    if (
      onboardingStatusData &&
      onboardingStatusData.isSuccess &&
      !onboardingStatusData.isFetching
    ) {
      const onBoardingStatusValue = isIndividual
        ? onBoardingStatusIndividual[
            onboardingStatusData.data
              .onBoardingStatus as keyof typeof onBoardingStatusIndividual
          ]
        : onBoardingStatusCompany[
            onboardingStatusData.data
              .onBoardingStatus as keyof typeof onBoardingStatusCompany
          ];

      if (onBoardingStatusValue) {
        dispatch(updateOnBoardingStatus(onBoardingStatusValue));
        if (onBoardingStatusValue?.toLowerCase() !== 'completed') {
          if (isIndividual) {
            navigate(`/onboarding/individual/${onBoardingStatusValue}`);
          } else {
            navigate(`/onboarding/company/${onBoardingStatusValue}`);
          }
        }
      }
    }
  }, [onboardingStatusData]);

  useEffect(() => {
    if (onBoardingStatus?.toLowerCase() !== 'completed') getOnboardingStatus('');
  }, []);

  const handleListItemClick = (menu: (typeof filteredMenuItems)[0]) => {
    setSelectedItem(menu.name);
    navigate(`${menu.navigateTo}`);
  };

  useEffect(() => {
    if (isRouteInMenuItem(pathname)) {
      const selectedItem = setItemBasedOnPath(pathname);
      setSelectedItem(selectedItem);
    }
  }, [pathname]);

  const setItemBasedOnPath = (path: string) => {
    let selectedItem = '';
    if (path.includes('mis')) {
      selectedItem = 'Reports';
    } else if (path.includes('employee-setup')) {
      selectedItem = 'Employee Setup';
    } else if (path.includes('transaction-dashboard/dashboard')) {
      selectedItem = 'Dashboard';
    } else if (path.includes('queries')) {
      selectedItem = 'Queries';
    } else if (path.includes('faqs')) {
      selectedItem = 'FAQs';
    } else {
      selectedItem = 'My Investors';
    }
    return selectedItem;
  };

  const drawer = (
    <Box>
      <List
        sx={(theme: Theme) => ({
          'padding': '24px 0px',
          '& .MuiButtonBase-root.MuiListItemButton-root.Mui-selected': {
            'borderRadius': '6px',
            'background': {
              xs: 'rgba(255, 255, 255, 0.20)',
              md: 'linear-gradient(90deg, rgba(27, 132, 185, 0.75), rgba(20, 178, 203, 0.75) )',
            },
            '& .MuiTypography-root': {
              color: 'common.white',
              fontWeight: '500',
            },
          },
          '& .MuiTypography-root': {
            color: 'text.labelColor2',
            fontSize: '15px',
            fontWeight: 400,
            [theme.breakpoints.between(768, 1024)]: {
              color: 'text.lightGray2',
              fontSize: '13px',
            },
            [theme.breakpoints.down('sm')]: {
              color: 'text.lightGray2',
            },
          },
          '& .MuiListItemButton-root': {
            justifyContent: 'flex-start',
            py: '8px',
          },
        })}
      >
        <Box sx={{ display: { xs: 'block', lg: 'none' }, padding: '0px 24px 24px' }}>
          <CardMedia
            sx={{
              '&.MuiCardMedia-root': {
                width: '65%',
              },
            }}
            component='img'
            image={Sidenav_Logo}
            alt='amount'
          />
        </Box>
        {filteredMenuItems.map((menu, index) => (
          <ListItem
            sx={(theme: Theme) => ({
              '& .MuiListItemIcon-root': {
                minWidth: '40px',
              },
              [theme.breakpoints.between(768, 1024)]: {
                px: '12px',
              },
            })}
            key={menu.name}
            onClick={() => handleListItemClick(menu)}
          >
            <ListItemButton selected={selectedItem === menu.name}>
              <ListItemIcon>
                {selectedItem === menu.name ? menu.activeIcon : menu.icon}
              </ListItemIcon>
              <ListItemText>{menu.name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  // Remove this const when copying and pasting into your project.
  // const container = window !== undefined ? () => window().document.body : undefined;

  const logoutHandler = () => {
    resetAllSlices(dispatch);
    navigate('/auth/login');
  };
  const options = [
    { name: 'Distributor Profile', Icon: <UserIcon /> },
    { name: 'Logout', Icon: <Logout /> },
  ];
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const { name } = useSelector((state: { login: LoginResponse }) => state.login);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    if (index === 0) {
      // distrub
      navigate('distributor-details');
    } else {
      //logout
      logoutHandler();
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'hidden',
        // height: '100%',
      }}
    >
      <CssBaseline />
      <AppBar
        elevation={0}
        position='fixed'
        sx={{
          px: 2,
          justifyContent: 'center',
          //   width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: { xs: '90px', md: '72px' },
          //   ml: { sm: `${drawerWidth}px` },
          background: {
            xs: 'linear-gradient(270deg, #10C1D0 0%, #1D6CB0 100%)',

            md: 'linear-gradient(270deg, rgba(16, 193, 208, 0.85) 0%, rgba(29, 108, 176, 0.85) 100%)',
          },
          backdropFilter: 'blur(5px)',
        }}
      >
        <Toolbar sx={{ padding: '0px' }}>
          <Box
            sx={{
              display: { xs: 'flex' },
              alignItems: 'center',
              justifyContent: { xs: 'flex-start', md: 'unset' },
            }}
          >
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ mr: { xs: 0, md: 2 }, display: { lg: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              disableRipple={true}
              sx={{
                'display': 'block',
                'textAlign': { xs: 'left', md: 'unset' },
                '& img': {
                  width: { xs: '60%', sm: '80%', lg: 'unset' },
                },
              }}
            >
              <img
                src={DashBoardLogo}
                alt='Dashboard-logo'
              />
            </IconButton>
          </Box>

          <Stack
            direction='row'
            alignItems='center'
          >
            <Badge
              badgeContent={2}
              color='primary'
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: 'common.white',
                  color: 'primary.main',
                },
              }}
            >
              <NotifyIcon />
            </Badge>
            <Box sx={{ ml: { md: '40px' } }}>
              <Button onClick={() => navigate('distributor-details')}>
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: 'common.white',
                    display: { xs: 'none', md: 'block' },
                  }}
                >
                  {name}
                </Typography>
                <Avatar
                  sx={{ ml: '20px' }}
                  alt='Distributor'
                  src={profile_icon}
                />
              </Button>
            </Box>
            <ButtonGroup
              ref={anchorRef}
              aria-label='split button'
            >
              <Button
                size='small'
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label='select merge strategy'
                aria-haspopup='menu'
                onClick={handleToggle}
                sx={{
                  'p': 0,
                  'minWidth': '0px',
                  '&,&:hover': {
                    background: 'unset',
                    border: 'unset',
                  },
                }}
              >
                <ProfileDropdown />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
                top: '26px !important',
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        id='split-button-menu'
                        autoFocusItem
                      >
                        {options.map((option, index) => (
                          <MenuItem
                            key={option.name}
                            onClick={event => handleMenuItemClick(event, index)}
                          >
                            <Stack
                              sx={{ '& svg': { width: 24, height: 24 } }}
                              direction={'row'}
                              columnGap={'10px'}
                            >
                              {option.Icon}
                              <Typography> {option.name}</Typography>
                            </Stack>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                    {/* <Button
                      sx={{
                        color: '#2057A6',
                        fontSize: '14px',
                        fontWeight: '500',
                        mr: '10px',
                      }}
                      endIcon={<Logout />}
                      onClick={() => logoutHandler()}
                    >
                      Log Out
                    </Button> */}
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>
        </Toolbar>
      </AppBar>
      {showLeftContent && (
        <Box
          component='nav'
          sx={(theme: Theme) => ({
            width: { lg: drawerWidth },
            flexShrink: { sm: 0 },
            [theme.breakpoints.up(breakpoint.xl)]: {
              width: drawerWidthByBreakpoint.xl,
            },
            // [theme.breakpoints.between(breakpoint.md, breakpoint.md2)]: {
            //   width: drawerWidthByBreakpoint.md,
            // },
          })}
          aria-label='mailbox folders'
        >
          <Drawer
            // container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              'display': { xs: 'block', lg: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: `calc( ${drawerWidth}px + 60px)`,
                backgroundColor: 'text.darkBlue',
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant='permanent'
            sx={(theme: Theme) => ({
              'display': { xs: 'none', lg: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                marginTop: '72px',
                borderRight: '1px dashed',
                borderColor: 'text.borderColorLight',
                [theme.breakpoints.up(breakpoint.xl)]: {
                  width: drawerWidthByBreakpoint.xl,
                },
                [theme.breakpoints.between(breakpoint.md, breakpoint.md2)]: {
                  width: drawerWidthByBreakpoint.md,
                },
              },
            })}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      )}
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          mt: { xs: 11.25, md: 9 },
          overflow: 'hidden',
          p: { xs: '20px 20px', md: '35px 40px 0px 40px' },
          height: '100%',
          minHeight: 'calc(100vh - 72px)',
          width: {
            xs: '100%',
            lg: `calc(100% - ${drawerWidth}px)`,
            xl: mainWidthByBreakpoint.xl,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
