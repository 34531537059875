import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useField } from 'formik';

interface Option {
  label: string;
  value: string;
}

interface FormSelectProps {
  name: string;
  fullWidth?: boolean;
  label?: string;
  options?: Option[];
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string[];
  onChange?: (value: string[]) => void;
}

export function FormSelectWithCheckBoxInput({
  name,
  fullWidth = true,
  label,
  options = [],
  required,
  disabled,
  defaultValue = [],
  onChange,
}: FormSelectProps): JSX.Element {
  const [field, meta, { setValue }] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const allValues = options.map(option => option.value);
    const newValue = event.target.value as string[];
    if (newValue[newValue.length - 1] === 'all') {
      const selectedValue = field.value?.length === options.length ? [] : allValues;
      setValue(selectedValue);
    } else {
      setValue(newValue);
    }
    if (onChange) {
      onChange(newValue);
    }
  };

  const isAllSelected = field.value?.length === options.length;

  return (
    <FormControl
      margin='dense'
      variant='filled'
      fullWidth={fullWidth}
      error={hasError}
      sx={{
        '& .MuiFormLabel-root.MuiInputLabel-root': {
          fontSize: '14px',
          fontWeight: 500,
          color: 'text.labelColor',
          top: '2px',
        },
        '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
          color: 'text.labelColor',
          fontSize: '14px',
        },
        '& .MuiInputBase-root': {
          'fontSize': '14px',
          'color': 'text.labelColor',
          'fontWeight': 500,
          '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled': {
            backgroundColor: 'text.borderColorDark',
            color: 'text.primary',
            WebkitTextFillColor: 'unset',
          },
        },
        '& .MuiInputBase-root, & .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root': {
          'border': 'none',
          'backgroundColor': 'unset !important',
          '&:before, &:after, &:hover': {
            borderBottom: 'none !important',
          },
          '&.Mui-focused': {
            backgroundColor: 'unset',
          },
        },
        '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root.Mui-focused': {
          '&:hover': {
            '&:before': {
              border: 'none',
            },
          },
        },
        '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:hover': {
          'backgroundColor': 'unset',
          '&:before': {
            borderBottom: 'none',
          },
        },
        '& .MuiSelect-select ,&:focus': {
          borderRadius: '7px',
          border: '1px solid',
          borderColor: 'text.borderColorLight',
          backgroundColor: 'common.white',
          paddingBottom: '4px',
        },
      }}
    >
      <InputLabel required={required}>{label}</InputLabel>
      <Select
        multiple
        disabled={disabled}
        value={field.value || defaultValue}
        onChange={handleChange}
        renderValue={(selected: string[]) =>
          selected.length < 3
            ? selected
                .map(value => options.find(option => option.value === value)?.label)
                .join(', ')
            : 'Multiple'
        }
        inputProps={{ 'aria-label': 'select multiple' }}
      >
        <MenuItem value='all'>
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                field.value?.length > 0 && field.value?.length < options.length
              }
            />
          </ListItemIcon>
          <Typography>Select All</Typography>
        </MenuItem>
        {options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            <ListItemIcon>
              <Checkbox checked={field.value?.indexOf(option.value) > -1} />
            </ListItemIcon>
            <Typography>{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {errorText && (
          <InfoOutlinedIcon
            sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 0.5 }}
            color='error'
          />
        )}
        {errorText}
      </FormHelperText>
    </FormControl>
  );
}
