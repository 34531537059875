import { Outlet } from 'react-router-dom';

import { AssetSelection } from '../assetSelection';
import { DashBoard } from '../dashboard';
import NFO from '../dashboard/nfo';
import NFOTransact from '../dashboard/nfo/NFOTransact';
import Reinitiate from '../dashboard/reinitiate';
import SipDashboard from '../dashboard/SipDashboard';
import TransactionSummary from '../dashboard/transactionSummary';
import { ViewMyEarnings } from '../dashboard/ViewMyEarnings';
import Faqs from '../faqs';
import Queries from '../queries';
import { NonIndividual } from '../teamManagement';
import ProfileSummary from '../teamManagement/ProfileSummary';
import { Transact } from '.';
import MFSAdditionalPurchase from './additionalPurchase';
import AdditionalPurchaseInvestmentDetails from './additionalPurchase/InvestmentDetails';
import Cart from './cart';
import Confirmation from './confirmation';
import DistributorProfile from './DistributorProfile';
import Enach from './eNach';
import InvestorPortfolio from './InvestorPortfolio';
import NewInvestorOnBoarding from './mfs/newInvestorOnboarding';
import { NewMfsInvestorContextLayout } from './mfs/newInvestorOnboarding/context/NewMfsInvestorContext';
import KycDetailsForm from './mfs/newInvestorOnboarding/KycDetailsForm';
import NewMfsInvestorSteps from './mfs/newInvestorOnboarding/newMfsInvestorSteps';
import { SchemeSelection } from './mfs/newInvestorOnboarding/schemeSelection';
import MfsInvestmentDetails from './mfs/newInvestorOnboarding/schemeSelection/MfsInvestmentDetail';
import NCT from './nct';
import AddOrDeleteBankMandate from './nct/AddOrDeleteBankMandate';
import ChangeOfBankAccountDetails from './nct/ChangeBankDetails';
import ChangeEmailOrPhone from './nct/ChangeEmailPhone';
import ChangeIDCWOption from './nct/ChangeIDCWOption';
import Fatca from './nct/Fatca';
import UpdateBankIFSC from './nct/UpdateBankIFSC';
import UpdateNomineeDetails from './nct/updateNomineeDetails';
import NewPurchase from './newPurchase';
import { MfsNewPurchaseContextLayout } from './newPurchase/context/MfsNewPurchaseContext';
import NewMfPurchaseSteps from './newPurchase/investorDetailsSteps';
import NewInvestmentDetails from './newPurchase/NewInvestmentDetails';
import AdditionalPurchase from './nps/AdditionalPurchase';
import InvestorOnBoarding from './nps/investorOnBoarding';
import { NewSubscriberContextLayout } from './nps/investorOnBoarding/context/NewSubscriberContext';
import NewSubscriberSteps from './nps/investorOnBoarding/newSubscriberSteps';
import PauseCancel from './pauseCancel';
import PauseCancelConfirmation from './pauseCancel/Confirmation';
import PauseInvestmentDetails from './pauseCancel/InvestmentDetails';
import Redeem from './redeem';
import RedInvestmentDetails from './redeem/InvestmentDetails';
import MFSReports from './reports/mfsReports';
import CanBasedAccountStatement from './reports/mfsReports/canBasedAccountStatement';
import CapitalGainsAccountStatement from './reports/mfsReports/capitalGainAccountStatement';
import ConsolidateAccountStatement from './reports/mfsReports/consolidateAccountStatement';
import ConsolidatedCapitalGainsStatement from './reports/mfsReports/consolidatedCapitalGainStatement';
import ElssAccountStatement from './reports/mfsReports/elssAccountStatement';
import GrandFatheringAccountStatement from './reports/mfsReports/grandFatheringAccountStatement';
import InvestorSelection from './reports/mfsReports/InvestorSelection';
import NetworthReport from './reports/mfsReports/networthReport';
import Soa from './reports/mfsReports/soa';
import MisReports from './reports/MisReports';
import NPSReports from './reports/NpsReports';
import Stp from './stp';
import StpInvestmentDetails from './stp/InvestmentDetails';
import Switch from './switch';
import SwitchInvestmentDetails from './switch/InvestmentDetails';
import SystematicWithdrawalPlan from './swp';
import SwpInvestmentDetails from './swp/InvestmentDetails';
import CollapsibleTable from './transactionDashboard/investorTable';
export const TransactionRoutes = () => {
  return [
    {
      children: [
        {
          index: true,
          element: <CollapsibleTable />,
        },
        {
          path: 'employee-setup',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <NonIndividual />,
            },
          ],
        },
        {
          path: 'queries',
          element: <Queries />,
        },

        {
          path: 'dashboard',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <DashBoard />,
                },
                {
                  path: 'transaction-summary',
                  element: <TransactionSummary />,
                },
                {
                  path: 'view-my-earnings',
                  element: <ViewMyEarnings />,
                },
                {
                  path: 'sip-dashboard',
                  element: <SipDashboard />,
                },
                {
                  path: 're-initiate',
                  element: <Reinitiate />,
                },
              ],
            },
            {
              path: 'transaction-summary',
              element: <TransactionSummary />,
            },
          ],
        },
        {
          path: 'nfo',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <NFO />,
            },
            {
              path: 'transact',
              element: <NFOTransact />,
            },
          ],
        },
        {
          path: 'faqs',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <Faqs />,
            },
          ],
        },
        {
          path: 'cart',
          element: <Cart />,
        },
        {
          path: 'asset-selection',
          element: <AssetSelection />,
        },
        {
          path: 'transact',
          element: <Transact />,
        },
        {
          path: 'switch',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <Switch />,
            },
            {
              path: 'investment-details',
              element: <SwitchInvestmentDetails />,
            },
          ],
        },
        {
          path: 'additional-purchase',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <MFSAdditionalPurchase />,
            },
            {
              path: 'investment-details',
              element: <AdditionalPurchaseInvestmentDetails />,
            },
          ],
        },
        {
          path: 'stp',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <Stp />,
            },
            {
              path: 'investment-details',
              element: <StpInvestmentDetails />,
            },
          ],
        },
        {
          path: 'swp',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <SystematicWithdrawalPlan />,
            },
            {
              path: 'investment-details',
              element: <SwpInvestmentDetails />,
            },
          ],
        },
        {
          path: 'redemption',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <Redeem />,
            },
            {
              path: 'investment-details',
              element: <RedInvestmentDetails />,
            },
          ],
        },

        {
          path: 'pause-cancel',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <PauseCancel />,
            },
            {
              path: 'investment-details',
              element: <PauseInvestmentDetails />,
            },
            {
              path: 'confirmation',
              element: <PauseCancelConfirmation />,
            },
          ],
        },
        {
          path: 'new-purchase/*',
          element: <Outlet />,
          children: [
            {
              element: <MfsNewPurchaseContextLayout />,
              children: [
                {
                  path: '',
                  element: <NewPurchase />,
                },
                {
                  path: 'investment-details',
                  element: <NewInvestmentDetails />,
                },
                {
                  path: 'investor-details',
                  element: <NewMfPurchaseSteps />,
                },
              ],
            },
          ],
        },
        {
          path: 'enach',
          element: <Enach />,
        },
        {
          path: 'confirmation',
          element: <Confirmation />,
        },
        {
          path: 'investor-portfolio',
          element: <InvestorPortfolio />,
        },
        {
          path: 'profile-summary',
          element: <ProfileSummary />,
        },
        {
          path: 'mis/*',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <AssetSelection />,
            },
            {
              path: 'nps',
              element: <NPSReports />,
            },
            {
              path: 'reports',
              element: <MisReports />,
            },
            {
              path: 'mfs-reports/*',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <MFSReports />,
                },
                {
                  path: 'investor-selection',
                  element: <InvestorSelection />,
                },
                {
                  path: 'soa',
                  element: <Soa />,
                },
                {
                  path: 'consolidate-account-statement',
                  element: <ConsolidateAccountStatement />,
                },
                {
                  path: 'elss-account-statement',
                  element: <ElssAccountStatement />,
                },
                {
                  path: 'can-based-account-statement',
                  element: <CanBasedAccountStatement />,
                },
                {
                  path: 'capital-gain-statement',
                  element: <CapitalGainsAccountStatement />,
                },
                {
                  path: 'grandfathering-account-statement',
                  element: <GrandFatheringAccountStatement />,
                },
                {
                  path: 'networth-report',
                  element: <NetworthReport />,
                },
                {
                  path: 'consolidated-capital-gains-statement',
                  element: <ConsolidatedCapitalGainsStatement />,
                },
              ],
            },
          ],
        },
        {
          path: 'non-commercial-transaction/*',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <NCT />,
            },
            {
              path: 'update-nominee-details',
              element: <UpdateNomineeDetails />,
            },
            {
              path: 'change-email',
              element: <ChangeEmailOrPhone />,
            },
            {
              path: 'change-mobile',
              element: <ChangeEmailOrPhone />,
            },
            {
              path: 'change-idcw-option',
              element: <ChangeIDCWOption />,
            },
            {
              path: 'fatca-crs-declaration',
              element: <Fatca />,
            },
            {
              path: 'change-bank-account-details',
              element: <ChangeOfBankAccountDetails />,
            },
            {
              path: 'add-delete-bank-mandate',
              element: <AddOrDeleteBankMandate />,
            },
            {
              path: 'update-bank-ifsc',
              element: <UpdateBankIFSC />,
            },
          ],
        },
        {
          path: 'distributor-details/*',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <DistributorProfile />,
            },
          ],
        },
        {
          path: '/nps',
          element: <Outlet />,
          children: [
            {
              path: 'additional-purchase',
              element: <AdditionalPurchase />,
            },
            {
              path: 'investor-onboarding',
              element: <Outlet />,
              children: [
                {
                  element: <NewSubscriberContextLayout />,
                  children: [
                    {
                      path: '',
                      element: <InvestorOnBoarding />,
                    },
                    {
                      path: 'new-subscriber',
                      element: <NewSubscriberSteps />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: '/mfs',
          element: <Outlet />,
          children: [
            {
              element: <NewMfsInvestorContextLayout />,
              children: [
                {
                  path: 'scheme-selection',
                  element: <SchemeSelection />,
                },
                {
                  path: 'investment-details',
                  element: <MfsInvestmentDetails />,
                },
                {
                  path: 'kyc-details',
                  element: <KycDetailsForm />,
                },
                {
                  path: 'new-investor-onboarding',
                  element: <Outlet />,
                  children: [
                    {
                      path: '',
                      element: <NewInvestorOnBoarding />,
                    },
                    {
                      path: 'new-investor-steps',
                      element: <NewMfsInvestorSteps />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
