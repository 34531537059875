import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { StepperAddButton } from '../../auth/login/styles/styledComponents';
import { FileUpload } from '../../common/FileUpload';
import { useRootContext } from '../../data/root.context';
import { base64ToBlob } from '../../onboarding/common';
import {
  useBulkUploaddistributorsMutation,
  useLazyDownloadTemplateBulkUploadQuery,
} from '../slice';

const documentsMap: { [key: number]: string } = {
  0: 'Employee/subBroker',
};
interface BulkUploadProps {
  isBigScreen: boolean;
}
export function BulkUpload({ isBigScreen }: BulkUploadProps) {
  const [files, setFiles] = useState<File[]>([]);
  const userId = '78b154f7-7473-4fd9-ae7b-914b1381cac2';
  const [uploaddocs] = useBulkUploaddistributorsMutation();
  const [downloadTemplate] = useLazyDownloadTemplateBulkUploadQuery();
  const { showToast } = useRootContext();
  const [templateLink, setTemplateLink] = useState<string | null>(null);

  const uploadFiles = async () => {
    try {
      const payload = new FormData();
      payload.append('type', documentsMap[0]);
      payload.append('file', files[0]);
      const response = await uploaddocs(payload as any);
      if ('data' in response) {
        setFiles([]);
        showToast((response.data as { message: string }).message, 'success');
      } else {
        showToast(`Error ocurred while uploading details: ${response.error}`, 'error');
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };
  useEffect(() => {
    if (files.length > 0) {
      uploadFiles();
    }
  }, [files]);
  useEffect(() => {
    if (templateLink) {
      const downloadLink = document.querySelector(
        '.download-link',
      ) as HTMLAnchorElement | null;
      if (downloadLink) {
        downloadLink.click();
      }
    }
  }, [templateLink]);

  const getTemplate = async () => {
    try {
      const { data: fileData } = await downloadTemplate(
        { uploadDoc: true, id: userId },
        false,
      );
      if (fileData) {
        const blob = base64ToBlob(
          fileData.split(',')[1],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        );
        const blobUrl = URL.createObjectURL(blob);
        setTemplateLink(blobUrl);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  return (
    <Box sx={{ padding: !isBigScreen ? '0px' : '0px 25px' }}>
      <Typography
        variant='subtitle1'
        sx={{ my: 3 }}
      >
        Upload Employee & Sub Broker File
      </Typography>
      <FileUpload
        fileName={documentsMap[0]}
        index={0}
        files={files}
        setFiles={setFiles}
      />
      <Box sx={{ my: 3 }}>
        <Typography
          sx={{
            color: 'common.black',
            fontSize: { xs: '16px', xl: '18px' },
            fontWeight: 500,
            mb: 2,
          }}
        >
          To Import Data
        </Typography>
        <Typography sx={{ color: 'text.labelColor', mb: 1 }}>
          1. Download the template
        </Typography>
        <Typography sx={{ color: 'text.labelColor', mb: 1 }}>
          2. Add Data in the correct format
        </Typography>
        <Typography sx={{ color: 'text.labelColor' }}>3. Upload File</Typography>
      </Box>

      <StepperAddButton
        sx={{ minWidth: '220px' }}
        onClick={getTemplate}
      >
        Download Template
      </StepperAddButton>
      <Link
        to={templateLink ?? ''}
        target='_blank'
        download={'template_data'}
        className={'download-link'}
        style={{ display: 'none' }}
      />
    </Box>
  );
}
