import { Box, Divider, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { FieldSubHeader3, MainHeader } from '../../onboarding/styles/styledComponents';
import SubmitCartButtons from '../common/SubmitCartButtons';
import { useSchemeByfundMutation } from '../mfs/newInvestorOnboarding/slice';
import {
  useAddItemToCartMutation,
  useGetEUINSQuery,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
} from '../slice';

interface InitialValues {
  type: string;
  mode: string;
  value: number;
  euin: 'Y' | 'N';
  euinValue: string;
  subArn: string;
  subBroker: string;
  amc: string;
  category: string;
  folioNumber: number;
  switchOutScheme: string;
  isExistingScheme: 'E' | 'N';
}

interface InvestmentDetailsFormProps {
  data: any;
  isEditMode: boolean;
  handleCancelModifyingCart?: () => void;
}

const InvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  data,
  isEditMode,
  handleCancelModifyingCart,
}) => {
  const [addSwitchItemToCart] = useAddItemToCartMutation();
  const { showToast } = useRootContext();
  const [schemeList, setSchemeList] = useState<any>([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const { data: allEUINs } = useGetEUINSQuery([]);
  const [listOfSchemes] = useListOfSchemesMutation();
  const [modifyItemInCart] = useModifyItemInCartMutation();

  const { arn, subArn, subbrokerCode, euin } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const {
    name: investorName,
    pan,
    email,
    mobile,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);

  const availableUnits = data?.balanceUnits || 0;

  const handleAddToCartClick = async () => {
    try {
      const payload = {
        amount:
          formikRef.current?.values.mode === 'A'
            ? Number(formikRef.current?.values.value)
            : 0,
        category: data.category,
        email,
        euinDeclaration: '',
        folio: data.folio,
        fund: data.fund,
        fundName: data.fundName,
        investorName,
        mobile,
        option: data.option,
        pan,
        partial: formikRef.current?.values.type === 'P',
        plan: data.plan,
        scheme: data.scheme,
        subBroker: formikRef.current?.values.subBroker || '',
        switchInSchemeName: data.switchInSchemeName,
        switchOutSchemeName: data.switchOutSchemeName,
        taxSaverFlag: data.taxSaverFlag,
        toOption: data.toOption,
        toPlan: data.toPlan,
        toScheme: data.toScheme,
        subArn: formikRef.current?.values.subArn || '',
        euin: formikRef.current?.values.euinValue || '',
        units:
          formikRef.current?.values.mode === 'U'
            ? Number(formikRef.current?.values.value)
            : 0,
        transactionType: 'SWITCH',
        ...(data.extraData ? data.extraData : {}),
      };
      await addSwitchItemToCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  const handleSwitchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSwitchType = event.target.value;
    formikRef.current?.setFieldValue('type', selectedSwitchType);
    if (selectedSwitchType === 'F') {
      formikRef.current?.setFieldValue('mode', 'U');
      formikRef.current?.setFieldValue('value', availableUnits);
    } else formikRef.current?.setFieldValue('value', '');
  };

  async function handleSubmit(values: InitialValues) {
    try {
      const payload: any = {
        amount: values.mode === 'A' ? Number(values.value) : 0,
        category: data.category,
        email,
        euinDeclaration: '',
        folio: `${data.folio}`,
        fund: data.fund,
        fundName: data.fundName,
        investorName,
        mobile,
        option: data.option,
        pan,
        partial: values.type === 'P',
        plan: data.plan,
        scheme: data.scheme,
        subBroker: values.subBroker || '',
        euin: values.euinValue || '',
        subArn: values.subArn || '',
        switchInSchemeName: data.switchInSchemeName,
        switchOutSchemeName: data.switchOutSchemeName,
        taxSaverFlag: `${data.taxSaverFlag}`,
        toOption: data.toOption,
        toPlan: data.toPlan,
        toScheme: data.toScheme,
        units: values.mode === 'U' ? Number(values.value) : 0,
        transactionType: 'SWITCH',
        ...(data.extraData ? data.extraData : {}),
      };
      if (isEditMode) {
        payload.cartId = data.cartId;
        payload.cartItemId = data.cartItemId;
        await modifyItemInCart(payload).unwrap();
        showToast(`${payload.cartItemId} Item updated successfully`, 'success');
        handleCancelModifyingCart?.();
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  }

  const validationSchema = Yup.object().shape({
    type: Yup.string().required('Switch type is required'),
    mode: Yup.string().required('Switch mode is required'),
    value: Yup.number()
      .required('Value is required')
      .positive('Switch Value should be a positive number'),
    euin: Yup.string().required().oneOf(['Y', 'N']),
  });

  const handleGoToCart = () => {
    navigate('../../cart');
  };

  const handleAddAnotherTransaction = () => {
    navigate('../');
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listOfSchemes({ pan: pan.toUpperCase() }).unwrap();
        const filteredData = response.filter(
          (scheme: any) => scheme.switchAllow && scheme.fund === data.fund,
        );
        setSchemeList(filteredData);
      } catch (error: any) {
        showToast((error as { message: string }).message, 'error');
      }
    }
    if (isEditMode) fetchData();
  }, []);

  const [selectedCategory, setSelectedCategory] = useState(data.selectedCategory);
  const [getSchemesByFund] = useSchemeByfundMutation();
  const [schemesByFund, setSchemesByFund] = useState<any>([]);

  useEffect(() => {
    async function fetchSchemesByFund() {
      try {
        const schemeByFundBody = {
          funds: [data.fund],
          category: [selectedCategory],
          investorPan: '',
          start: 0,
          end: 20000, //TODO: add valid pagination
        };
        const resFund = await getSchemesByFund(schemeByFundBody).unwrap();
        setSchemesByFund(resFund);
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    }
    if (isEditMode) fetchSchemesByFund();
  }, [selectedCategory]);

  const getSwitchInSchemes = (isExistingScheme: 'E' | 'N', folioNumber: number) => {
    if (isExistingScheme === 'E') {
      return schemeList.filter(
        (scheme: any) =>
          scheme.folio === folioNumber &&
          `${scheme.scheme}_${scheme.schemePlan}_${scheme.schemeOption}` !==
            `${formikRef.current?.values.switchOutScheme}`,
      );
    } else {
      return schemesByFund;
    }
  };

  return (
    <Formik
      initialValues={{
        type: 'P',
        mode: 'amount' in data && data.amount !== 0 ? 'A' : 'U',
        value: isEditMode ? data.amount || data.units : 0,
        euin: 'Y',
        euinValue: euin,
        subArn,
        subBroker: subbrokerCode,
        amc: data.fundName,
        category: data.selectedCategory,
        folioNumber: data.folio,
        switchOutScheme: `${data.scheme}_${data.plan}_${data.option}`,
        isExistingScheme: data.isExistingScheme,
        switchInScheme: `${data.toScheme}_${data.toPlan}_${data.toOption}`,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
    >
      {({ values, setValues }) => (
        <Form>
          <Grid
            container
            spacing={2}
            sx={{
              'paddingBottom': '25px',
              '& .MuiButtonBase-root': {
                '&.MuiRadio-root': {
                  '&.Mui-checked': {
                    color: 'primary.main',
                  },
                },
              },
            }}
          >
            {isEditMode && (
              <>
                <Grid
                  item
                  sm={6}
                >
                  <FormTextInput
                    name='amc'
                    label='AMC'
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='folioNumber'
                    label='folioNumber'
                    options={schemeList.map((scheme: any) => ({
                      label: scheme.folio,
                      value: scheme.folio,
                    }))}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='switchOutScheme'
                    label='Switch Out Scheme'
                    options={schemeList
                      .filter((scheme: any) => scheme.folio === values.folioNumber)
                      .map((scheme: any) => ({
                        label: `${scheme.schemeName}-${scheme.planDesc}`,
                        value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                      }))}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  mb={2}
                >
                  <Box sx={{ bgcolor: 'text.boxColor', padding: '20px 15px' }}>
                    <Grid
                      container
                      spacing={2}
                      alignItems={'center'}
                    >
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Balance Units:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {data?.units || 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Current Value:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {((data?.units || 0) * (data?.nav || 0)).toLocaleString(
                              'en-US',
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            NAV as on Date:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {data?.nav || 0}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={12}
                >
                  <FormStyledRadioButton
                    options={[
                      { label: 'Existing Scheme & Plan', value: 'E' },
                      { label: 'New Scheme & Plan', value: 'N' },
                    ]}
                    name='isExistingScheme'
                  />
                </Grid>
                {values.isExistingScheme === 'N' && (
                  <Grid
                    item
                    sm={6}
                  >
                    <FormSelectInput
                      name='category'
                      options={data.categories?.map((category: string) => ({
                        label: category,
                        value: category,
                      }))}
                      onChange={async (e: SelectChangeEvent<any>) => (
                        setValues(values => ({
                          ...values,
                          category: e.target.value,
                          switchInScheme: '',
                        })),
                        setSelectedCategory(e.target.value)
                      )}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='switchInScheme'
                    label='Switch In Scheme'
                    options={getSwitchInSchemes(
                      values.isExistingScheme,
                      values.folioNumber,
                    ).map((scheme: any) => ({
                      label: `${scheme.schemeName || scheme.schemeDesc}-${
                        scheme.planDesc
                      }`,
                      value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                    }))}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            mb={'24px'}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 2,
                  fontWeight: 500,
                  fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                }}
              >
                Switch Type
              </Typography>
              <FormStyledRadioButton
                options={[
                  { label: 'Partial', value: 'P' },
                  { label: 'Full', value: 'F' },
                ]}
                name='type'
                handleChange={handleSwitchTypeChange}
              />
            </Grid>
            <Grid
              item
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 2,
                  fontWeight: 500,
                  fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                }}
              >
                Switch Mode
              </Typography>
              <FormStyledRadioButton
                options={[
                  { label: 'Units', value: 'U' },
                  { label: 'Amount', value: 'A' },
                ]}
                name='mode'
                disabled={values.type === 'F'}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                variant='subtitle1'
                sx={{ color: 'text.valueColor', mb: 1.5 }}
              >
                Switch value (INR/UNITS)
              </Typography>
              <FormTextInput
                required={false}
                name='value'
                placeholder='Value'
                type='number'
                disabled={values.type === 'F'}
              />
            </Grid>
          </Grid>

          <Typography
            variant='subtitle1'
            sx={{
              color: { xs: 'text.valueColor', sm: 'primary.main' },
              mb: 2,
            }}
          >
            Distributor Details
          </Typography>
          <Grid
            container
            spacing={2}
            mb={'24px'}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormTextInput
                required={false}
                name='arn'
                label={'ARN Code'}
                defaultValue={arn}
                disabled
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormTextInput
                required={false}
                name='subArn'
                label={'Sub ARN Code'}
                defaultValue={subArn}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormTextInput
                required={false}
                name='subBroker'
                label={'Sub Broker Code'}
                defaultValue={subbrokerCode}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ mb: 3 }}
            >
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor', mb: 1 }}
              >
                EUIN
              </Typography>
              <FormStyledRadioButton
                options={[
                  { label: 'Yes', value: 'Y' },
                  { label: 'No', value: 'N' },
                ]}
                name='euin'
              />
            </Grid>
            {values.euin === 'Y' ? (
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormSelectInput
                  name='euinValue'
                  label='Select EUIN'
                  options={
                    euin
                      ? [{ label: euin, value: euin }]
                      : allEUINs?.map((item: any) => ({
                          label: `${item.euin} - ${item.name}`,
                          value: item.euin,
                        }))
                  }
                />
              </Grid>
            ) : (
              <Box
                sx={{
                  bgcolor: 'text.lightGray',
                  padding: '20px',
                  borderRadius: '5px',
                  maxWidth: '600px',
                }}
              >
                <Typography sx={{ color: 'text.valueColor', fontSize: '12px' }}>
                  I/We hereby confirm that the EUIN box has been intentionally left the
                  blank by me/us as this is an execution-only transaction without any
                  interaction or advice by any personnel of the above distributor or
                  notwithstanding the advice of in-appropriateness,if any provided by any
                  personnel of the distributor and the distributor has not charged any
                  advisory fees on this transaction
                </Typography>
              </Box>
            )}
          </Grid>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              my: 3,
            }}
          />
          <SubmitCartButtons
            onAddToCartClick={
              !isEditMode ? handleAddToCartClick : handleCancelModifyingCart
            }
            firstButtonTitle={!isEditMode ? 'Add to Cart' : 'Cancel'}
            secondButtonTitle={!isEditMode ? 'Save & Proceed' : 'Save'}
          />

          {openDialogBox && (
            <CustomDialog
              isVisible={true}
              firstButtonHandler={handleGoToCart}
              secondButtonHandler={handleAddAnotherTransaction}
              firstButtonTitle='Go to cart'
              secondButtonTitle='Add another Transaction'
              handleClose={() => {}}
              showSecondButton={true}
            >
              <Box sx={{ textAlign: 'center', pt: 2 }}>
                <img
                  src={VerifiedIcon}
                  alt='verified-icon'
                />
                <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
                <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                  Scheme is added to investor cart
                </FieldSubHeader3>
              </Box>
            </CustomDialog>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default InvestmentDetailsForm;
