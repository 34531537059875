import {
  Box,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AIF from '../../assets/asset-selection-icons/aif.svg';
import Insurance from '../../assets/asset-selection-icons/insurance.svg';
import Loans from '../../assets/asset-selection-icons/loans.svg';
import MutualFund from '../../assets/asset-selection-icons/mutual-fund.svg';
import NPS from '../../assets/asset-selection-icons/nps.svg';
import PMS from '../../assets/asset-selection-icons/pms.svg';
import { ReactComponent as BackIcon } from '../../assets/back-icon-large.svg';
import { LoginResponse } from '../features/login/loginSlice';
import { TransactionResponse } from '../features/transactions/transactionSlice';

const imageComponents = {
  AIF: AIF,
  INS: Insurance,
  LOANS: Loans,
  MFS: MutualFund,
  NPS: NPS,
  PMS: PMS,
};

function CaptalizeKeys(data: { [key: string]: boolean }): { [key: string]: boolean } {
  const capitalizedData: { [key: string]: boolean } = {};
  for (const key in data) {
    if (data.hasOwnProperty(key) && data[key]) {
      const capitalizedKey = key.toUpperCase();
      capitalizedData[capitalizedKey] = true;
    }
  }
  return capitalizedData;
}

export const AssetSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigatedFrom = location.state?.from;
  const { MFS, NPS } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  const { assets } = useSelector((state: { login: LoginResponse }) => state.login);

  const empaneledAssets = navigatedFrom
    ? CaptalizeKeys(assets || {})
    : { MFS: MFS, NPS: NPS };

  const AssetCard = styled(Box)(({ theme }) => ({
    borderRadius: '15px',
    padding: '25px',
    border: `0.3px solid  ${theme.palette.text.borderColorDark}`,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  }));
  const AssetCardMedia = styled(Box)(({ theme }) => ({
    width: '100px',
    height: '100px',
    borderRadius: '10px',
    backgroundColor: ` ${theme.palette.text.lightGrayishBlue2}`,
    boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  }));
  const routesHandler = (key: string) => {
    if (location.pathname.includes('/mis')) {
      if (key === 'MFS') {
        navigate('./mfs-reports');
      } else if (key === 'NPS') {
        navigate('./nps');
      }
    } else {
      if (navigatedFrom === 'newInvestor') {
        if (key === 'MFS') {
          navigate('../mfs/scheme-selection');
        } else if (key === 'NPS') {
          navigate('../nps/investor-onboarding');
        }
      } else if (key === 'MFS')
        navigate('../transact', {
          state: { assets },
        });
      else
        navigate('../nps/additional-purchase', {
          state: { assets },
        });
    }
  };

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        mb={'20px'}
      >
        {location.pathname.includes('/mis') ? (
          <Typography
            variant='h4'
            sx={{ mb: 2, my: { xs: 2, md: 0 } }}
          >
            Reports
          </Typography>
        ) : (
          <>
            <IconButton sx={{ mb: 2, my: { xs: 2, md: 0 } }}>
              <BackIcon onClick={() => navigate('../')} />
            </IconButton>
            <Typography
              variant='h4'
              sx={{ mb: 2, my: { xs: 2, md: 0 } }}
            >
              Asset Selection
            </Typography>
          </>
        )}
      </Stack>
      <Box>
        <Grid
          container
          spacing={2}
        >
          {Object.entries(empaneledAssets).map(([key, value], index) => (
            <Grid
              item
              xs={6}
              sm={4}
              key={index}
            >
              <AssetCard
                sx={{ cursor: 'pointer' }}
                onClick={() => routesHandler(key)}
              >
                <AssetCardMedia>
                  <CardMedia
                    sx={{
                      '&.MuiCardMedia-root': {
                        width: '46%',
                      },
                    }}
                    component='img'
                    image={(imageComponents as Record<string, string>)[key]}
                    alt='amount'
                  />
                </AssetCardMedia>
                <Typography
                  variant='h4'
                  sx={{ mb: 1.5, textAlign: { xs: 'center', md: 'unset' } }}
                >
                  {key}
                </Typography>
                <Typography
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'block',
                      color: 'text.labelColor',
                    },
                  }}
                >
                  Professional management for personalized investment portfolios.
                </Typography>
              </AssetCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
