import { Grid, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Investorcard,
  StepperNextButton,
} from '../../../auth/login/styles/styledComponents';
import { CheckBoxHtmlTooltip } from '../../../common/checkbox';
import { useRootContext } from '../../../data/root.context';
import { FormSelectInput } from '../../../forms/FormSelect'; // Ensure the correct import for FormSelectInput and OptionType
import FormTextInput from '../../../forms/FormTextInput';
import Layout from '../../Layout';
import {
  useCreateUpdateEmailRequestMutation,
  useCreateUpdateMobileRequestMutation,
} from '../../slice';
import DetailsDataTable from '../common/DetailsDataTable';
import { DetailType } from '../common/types';

export type DOB = string | Date;
export type PINCODE = string | number;
export type ADDRESS = {
  line1: string;
  line2?: string;
  line3?: string;
  country: string;
  state: string;
  city?: string;
  pincode: PINCODE;
};

export type GUARDIAN = {
  dob: DOB;
  name: string;
  pan: string;
  relation: string;
};

export type NOMINEE = {
  dob: DOB;
  gender: string;
  address: ADDRESS;
  id: string;
  name: string;
  pan: string;
  percent: number;
  relation: string;
  guardian?: GUARDIAN;
};

export interface RowInfoType {
  id: string | number;
  amcName: string;
  investorName?: string;
  email?: string;
  amc?: string;
  schemeNames?: string[];
  mobile?: string;
  assetType?: string[];
  isDisabled?: boolean;
  folio?: string;
  nominees?: NOMINEE[];
  taxStatusLabel?: string;
}
interface Payload {
  requestData: {
    email: string;
    mobile: string;
    pan: string;
    distributor_id: string;
    relation: string;
    image: {
      name: string;
      content: string;
    };
    data: any[];
    newMobile?: string; // Define newMobile as an optional property
    newEmail?: string; // Define newEmail as an optional property
  };
}

interface FormValues {
  inputValue: string;
  relationship: string;
}

export default function ChangeEmailPhone() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { pathname } = useLocation();
  const path = pathname.split('/').pop();
  const inputType: 'email' | 'mobile' = path?.includes('email') ? 'email' : 'mobile';
  const [updateMobileRequest] = useCreateUpdateMobileRequestMutation();
  const [updateEmailRequest] = useCreateUpdateEmailRequestMutation();
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const { pan, email, mobile } = useSelector((state: any) => state.transactions); // selected investor PAN
  const [valueState, setValueState] = useState<FormValues>();
  const { showToast } = useRootContext();

  const arr: string[] = [
    'Self',
    'Mother',
    'Father',
    'Spouse',
    'Son',
    'Daughter',
    'Sister',
    'Brother',
  ];

  const initialValues: FormValues = {
    inputValue: '',
    relationship: '',
  };

  const validationSchema = Yup.object().shape({
    inputValue: Yup.string().when([], {
      is: () => inputType === 'email',
      then: Yup.string().required('Email Id is required').email('Invalid email address'),
      otherwise: Yup.string()
        .required('Mobile number is required')
        .matches(/^\d{10}$/, 'Mobile number must be a valid 10-digit number'),
    }),
    relationship: Yup.string().required(
      `${inputType === 'email' ? 'Email' : 'Mobile'} Relationship is required`,
    ),
  });

  const handleSelectedRows = async (selectedRows: any) => {
    try {
      const transformedResult = selectedRows.map((obj: any) => ({
        folio: obj.folio,
        amc: obj.amcId,
        amc_name: obj.amcName,
      }));

      const payload: Payload = {
        // arn: 'ARN-0018',
        // user: 'alice',
        requestData: {
          email: email,
          mobile: mobile,
          pan: pan,
          distributor_id: 'ARN-0018',
          relation: 'SE',
          image: {
            name: 'email_update_file_name',
            content: 'email/mobile_update_file_content',
          },
          data: transformedResult,
        },
      };

      if (inputType === 'mobile') {
        payload.requestData.newMobile = valueState?.inputValue; // Assign newMobile value
        await updateMobileRequest(payload);
      } else if (inputType === 'email') {
        payload.requestData.newEmail = valueState?.inputValue; // Assign newEmail value
        await updateEmailRequest(payload);
      }

      showToast(
        `${
          inputType.charAt(0).toUpperCase() + inputType.slice(1)
        } update request received successfully!`,
        'success',
      );
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  function isRowDisabled(currentRow: any): boolean {
    return currentRow.modeOfHolding !== '1';
  }

  function getTooltip(isSelected: boolean, isDisabled: boolean) {
    if (isSelected || !isDisabled) return undefined;
    return ({ children }: { children: JSX.Element }) => {
      return (
        <CheckBoxHtmlTooltip
          title={
            <>
              <Typography color='inherit'>
                Account Number and Bank Name should be same
              </Typography>
            </>
          }
        >
          {children}
        </CheckBoxHtmlTooltip>
      );
    };
  }

  function handleSubmit(values: FormValues) {
    setValueState(values);
    setIsSubmitted(true);
  }
  return (
    <Layout title={`Change / Update ${inputType === 'email' ? 'Email' : 'Phone'}`}>
      {!isSubmitted ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            actions.resetForm();
            handleSubmit(values);
          }}
          innerRef={formikRef}
          validationSchema={validationSchema}
        >
          {({ values, handleChange }: { values: FormValues; handleChange: Function }) => (
            <Form>
              <Investorcard
                sx={theme => ({
                  padding: { xs: 0, sm: '30px' },
                  [theme.breakpoints.down('md')]: {
                    border: 'unset',
                    boxShadow: 'unset',
                  },
                })}
              >
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'primary.main' }}
                >
                  {' '}
                  Please enter the new{' '}
                  {inputType === 'email' ? 'email address' : 'phone number'} and choose
                  family relationship below
                </Typography>

                <Grid
                  container
                  sx={{ my: 1 }}
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='inputValue'
                      label={`Enter new ${inputType === 'email' ? 'Email' : 'Phone'}`}
                      placeholder={`Enter new ${
                        inputType === 'email' ? 'Email' : 'Phone'
                      }*`}
                      //onChange={handleChange} // Include the onChange handler
                      value={values.inputValue} // Assign the input value
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormSelectInput
                      name='relationship'
                      label='Select Relationship'
                      options={arr.map((item: string) => ({
                        label: item,
                        value: item,
                      }))}
                      //onChange={handleChange} // Include the onChange handler
                      value={values.relationship} // Assign the relationship value
                    />
                  </Grid>
                </Grid>
                <StepperNextButton
                  sx={{ my: { xs: 3, sm: 'unset' } }}
                  type='submit'
                >
                  Next
                </StepperNextButton>
              </Investorcard>
            </Form>
          )}
        </Formik>
      ) : (
        <DetailsDataTable
          columns={[
            'Folio Number',
            'Fund House',
            'Primary Holder',
            'Mobile Number',
            'Holding',
            'Email ID',
          ]}
          onSubmit={handleSelectedRows}
          isFromEmailOrPhone={inputType}
          detailType={DetailType.Investor}
          isRowDisabled={isRowDisabled}
          checkboxTooltip={getTooltip}
        />
      )}
    </Layout>
  );
}
