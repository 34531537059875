import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import * as ReactDOMServer from 'react-dom/server';

const tickIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9801 4.42385C12.2983 4.68903 12.3413 5.16195 12.0762 5.48016L7.07617 11.4802C6.94127 11.642 6.74449 11.7397 6.53399 11.7492C6.32348 11.7588 6.11867 11.6794 5.96967 11.5303L3.46967 9.03035C3.17678 8.73746 3.17678 8.26258 3.46967 7.96969C3.76256 7.6768 4.23744 7.6768 4.53033 7.96969L6.4496 9.88896L10.9238 4.51988C11.189 4.20167 11.6619 4.15868 11.9801 4.42385Z'
      fill='white'
    />
  </svg>
);

const svgString = encodeURIComponent(ReactDOMServer.renderToStaticMarkup(tickIcon));

const BpIcon = styled('span')(({ theme }) => ({
  'borderRadius': 3,
  'width': 16,
  'height': 16,
  'boxShadow':
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  'backgroundColor': theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  'backgroundImage':
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  'backgroundColor': '#2057A6',
  'backgroundImage': 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: `url("data:image/svg+xml,${svgString}")`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#2057A6',
  },
});

// Inspired by blueprintjs
export default function Checkbox(props: CheckboxProps) {
  return (
    <MuiCheckbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color='default'
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'checkbox input' }}
      {...props}
    />
  );
}

export const CheckBoxHtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const CheckBoxIcon = styled('span')(({ theme }) => ({
  'borderRadius': 3,
  'width': 16,
  'height': 16,
  'border': '1px solid #6a6a6a',
  'boxShadow':
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  'backgroundColor': 'theme.palette.common.white',
  'backgroundImage':
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  // 'input:hover ~ &': {
  //   backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  // },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(BpIcon)({
  // backgroundColor: '#137cbd',
  'backgroundImage':
    'linear-gradient(90deg, rgba(33, 94, 171, 0.85) 0%, rgba(15, 209, 214, 0.85) 100%)',
  '&:before': {
    border: 'unset',
    display: 'block',
    width: 16,
    height: 16,
    // backgroundImage: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15\" height=\"15\" viewBox=\"0 0 15 15\" fill=\"none\"><rect y=\"-6.10352e-05\" width=\"15\" height=\"15\" rx=\"2.5\" fill=\"url(#paint0_linear_8951_103397)\"/><path d=\"M4.21875 7.82806L6.84375 10.4531L10.7812 4.54681\" stroke=\"white\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><defs><linearGradient id=\"paint0_linear_8951_103397\" x1=\"7.5\" y1=\"-6.10352e-05\" x2=\"7.5\" y2=\"14.9999\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#215DAB\"/><stop offset=\"1\" stop-color=\"#0FD1D7\"/></linearGradient></defs></svg>')",

    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// Inspired by blueprintjs
function CustomCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color='default'
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

export function CustomizedCheckbox(props: CheckboxProps) {
  return (
    <>
      <CustomCheckbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color='default'
        checkedIcon={<CheckedIcon />}
        icon={<CheckBoxIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    </>
  );
}
