import { Box, List, ListItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Detailslabel, SubHeader } from '../../../../auth/login/styles/styledComponents';
import { useRootContext } from '../../../../data/root.context';
import StyledRadioButtonGroup from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import { setAccountTypes, setStepNumber } from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import { useGetAccountTypeMutation, useSaveAccountTypeMutation } from '../slice';
const benefits = [
  {
    tier: 'Tier I',
    type: 'Non-withdrawal permanent account',
    withdrawal:
      'Allow to withdraw up to 50% if her/his contribution after completing 25 year of her/his service.',
  },
  {
    tier: 'Tier I & II',
    type: 'NPS Tier II account allows you to have unlimited withdrawals',
    withdrawal: '',
  },
];

const InvestmentDetails: React.FC = () => {
  const {
    state: { ackNumber, accountType: AT },
    dispatch: dispatchContext,
  } = useNewSubscriberContext();
  const { data = {} } = AT || {};
  const { AccountType = '1' } = data;
  const [saveAccountType] = useSaveAccountTypeMutation();
  const [getAccountType] = useGetAccountTypeMutation();
  const initialValues = {
    accountType: AccountType,
  };
  const { showToast } = useRootContext();

  const validationSchema = Yup.object().shape({
    accountType: Yup.string().required('Account Type is required'),
  });
  const handleSubmit = async (values: { accountType: any }) => {
    try {
      const response = await saveAccountType({
        AckNumber: ackNumber,
        AccountType: values.accountType,
      });
      const accountDetails = await getAccountType({ AckNumber: ackNumber }).unwrap();
      dispatchContext(setAccountTypes(accountDetails));
      dispatchContext(setStepNumber(4));
    } catch (error) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Box sx={{ mb: 2.5 }}>
            <StyledRadioButtonGroup
              options={[
                { label: 'Tier1', value: '1' },
                { label: 'Tier I && II', value: '2' },
              ]}
              value={values.accountType}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                setFieldValue('accountType', value);
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '16px' },
              fontWeight: 500,
              color: '#6C737F',
              mb: 2,
            }}
          >
            Avail Tax Benefits under sction 80C 80CCD
          </Typography>
          {benefits.map((benefit, index) => (
            <Box
              key={index}
              sx={{ mb: 3, borderRadius: '7px', backgroundColor: '#F5F5F5', p: 3 }}
            >
              <List disablePadding>
                <SubHeader sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
                  {benefit.tier}
                </SubHeader>

                <ListItem>
                  <Detailslabel sx={{ fontWeight: 500 }}>{benefit.type}</Detailslabel>
                </ListItem>
                <ListItem>
                  <Detailslabel sx={{ fontWeight: 500 }}>
                    {benefit.withdrawal}
                  </Detailslabel>
                </ListItem>
              </List>
            </Box>
          ))}

          <SubmitButton
            sx={{
              '&.MuiButtonBase-root': {
                borderRadius: '5px',
                minWidth: { xs: '100%', sm: '200px' },
                mt: 4,
              },
            }}
            label='Save & Proceed'
          >
            Save & Proceed
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default InvestmentDetails;
