import { Box, Stack } from '@mui/system';
import { Form, Formik } from 'formik';
import React from 'react';

import FormOTPInput from '../../../forms/FormOtpInput/FormOTPInput';
import { LinkButton, SubTitle } from '../../login/styles/styledComponents';

interface OtpInputSectionProps {
  OTPHandler: (_value: string) => Promise<void>;
  isDisabledField: boolean;
  isResendDisabled: boolean;
  resendButtonHandler: () => void;
  formattedTime: string;
}

const OtpInputSection: React.FC<OtpInputSectionProps> = ({
  OTPHandler, // OtpHandelr
  isDisabledField, // emailOtp
  isResendDisabled,
  resendButtonHandler,
  formattedTime,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: { xs: '10px' },
        }}
      >
        <Formik
          initialValues={{ otp: ['', '', '', '', '', ''] }}
          onSubmit={values => {
            //TODO: implement this
          }}
        >
          <Form>
            <FormOTPInput
              onChange={OTPHandler}
              disabled={isDisabledField}
            />
          </Form>
        </Formik>
      </Box>

      {!isDisabledField && (
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Stack
            direction='row'
            gap='4px'
            alignItems='center'
          >
            <SubTitle>Didn’t receive OTP?</SubTitle>
            <LinkButton
              disabled={isResendDisabled}
              onClick={() => {
                resendButtonHandler();
              }}
              sx={{
                opacity: isResendDisabled ? '0.4' : '1',
                textDecoration: isResendDisabled ? 'none !important' : 'underline',
              }}
            >
              Resend OTP
            </LinkButton>
          </Stack>
          <Stack>
            <SubTitle
              sx={{
                textAlign: 'right',
                color: '#2057A6 !important',
              }}
            >
              {formattedTime}
            </SubTitle>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default OtpInputSection;
