import { Box, Grid, Typography } from '@mui/material';
import { Field, Form, Formik, getIn, useFormikContext } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { PieChartRadioGroup } from '../../../../common/PieChartContainer';
import InputSlider from '../../../../common/slider';
import { useRootContext } from '../../../../data/root.context';
import { FormSelectInput } from '../../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput from '../../../../forms/FormTextInput';
import { SUCCESS_RESPONSE_CODE } from '..';
import { setSchemeDetails, setStepNumber } from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import {
  useGetnpsSchemeDetailsMutation,
  useGetPfmNamesQuery,
  useGetRegdSchemesMutation,
  useSaveSchemeDetailsMutation,
} from '../slice';
import {
  INVESTMENT_OPTIONS,
  LABELS,
  LIFECYCLE_MAP,
  LIFECYCLE_PERCENTAGE_ALLOCATION,
  PENSION_FUND_OPTIONS,
} from './constants';
interface TierValues {
  ITType: string;
  contributionAmount: string;
  pensionFundManager: string;
  frequency: string;
  startDate: string;
  endDate: string;
  invOption: string;
  lifeCycleOption: string;
  schemePercentageDetails: never[];
}

interface InitialValues {
  tier1: TierValues;
  tier2?: TierValues;
}

interface SchemePercentageDetail {
  Percentage: number;
  AssetCategory: string;
  MinValue: number;
  MaxValue: number;
}

const SchemeSection = ({
  name,
  values,
}: {
  name: keyof InitialValues;
  values?: TierValues;
}) => {
  const {
    state: { ackNumber, schemeDetails: SD },
  } = useNewSubscriberContext();
  const { showToast } = useRootContext();

  const { setFieldValue } = useFormikContext();

  const schemePercentageDetails: SchemePercentageDetail[] =
    (values ?? {})['schemePercentageDetails'] || [];

  const [pfmOptions, setpfmOptions] = useState([]);
  const [getRegdSchemes] = useGetRegdSchemesMutation();
  const { refetch: getPfmNames } = useGetPfmNamesQuery('');

  useEffect(() => {
    async function fetchPfmNames() {
      const response = await getPfmNames();
      setpfmOptions(
        response.data?.data.map((option: { Id: any; Name: any }) => ({
          value: option.Id,
          label: option.Name,
        })),
      );
    }
    fetchPfmNames();
  }, []);

  useEffect(() => {
    async function fetchSchemePercentages(ioption: string) {
      try {
        const params = {
          ApplicantType: 'AL',
          ackNumber,
          ioption: values?.invOption,
          pfund: values?.pensionFundManager,
          accountType: name === 'tier1' ? '1' : '2',
        };

        const { data, statusCode, message } = await getRegdSchemes(params).unwrap();
        if (+statusCode !== SUCCESS_RESPONSE_CODE)
          throw new Error(message || 'Something went wrong');

        const percentages = data.map(
          (item: {
            SchemeCode: any;
            AssetCategory: any;
            MinValue: any;
            MaxValue: any;
          }) => ({
            SchemeCode: item.SchemeCode,
            AssetCategory: item.AssetCategory,
            MinValue: item.MinValue,
            MaxValue: item.MaxValue,
            Percentage: SD?.data
              ? SD?.data[`${item.AssetCategory}${name === 'tier1' ? '' : '2'}`] || 0
              : ioption !== '1'
              ? item.MaxValue
              : item.MinValue,
          }),
        );
        setFieldValue(`${name}.schemePercentageDetails`, percentages);
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    }
    if (values?.pensionFundManager) {
      if (values['invOption'] === '1') {
        fetchSchemePercentages('1');
      } else {
        fetchSchemePercentages(values['lifeCycleOption']);
      }
    }
  }, [values?.pensionFundManager, values?.invOption, values?.lifeCycleOption]);

  return (
    <Field name={name}>
      {({
        field: { name: fieldName },
        meta: { error },
      }: {
        field: { name: string };
        meta: { error?: string };
      }) => {
        const { ITType, invOption } = values || { ITType: '', invOption: '' };
        // const isOneTimeInvestment = +ITType === 1;
        // const isOneTimeInvestmentSIP = +ITType === 3;
        const isActiveInvOption = +invOption === 1;
        const isAutoInvOption = +invOption === 2;
        const total = schemePercentageDetails.reduce(
          (acc, item) => acc + +item.Percentage,
          0,
        );
        const schemePercentageErrors = getIn(error, `schemePercentageDetails`);
        const errorMessage =
          total > 0 && typeof schemePercentageErrors === 'string'
            ? schemePercentageErrors
            : null;

        return (
          <>
            <Box
              sx={{
                backgroundColor: 'text.boxColor',
                borderRadius: '5px',
                mb: 2.5,
                p: 1.5,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#111927' }}>
                {name === 'tier1' ? 'Tier I' : 'Tier II'}
              </Typography>
            </Box>

            <FormStyledRadioButton
              options={[{ label: 'One Time Investment', value: '1' }]}
              name={`${fieldName}.ITType`}
            />

            <Grid
              container
              spacing={2}
              sx={{ my: 2 }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <FormTextInput
                  label='Contribution Amount'
                  name={`${fieldName}.contributionAmount`}
                  type='number'
                  placeholder={`Minimum Amount ₹. ${name === 'tier1' ? 500 : 1000}`}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <FormSelectInput
                  label='Pension Funds'
                  name={`${fieldName}.pensionFundManager`}
                  options={pfmOptions || PENSION_FUND_OPTIONS}
                />
              </Grid>
            </Grid>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px' },
                fontWeight: 700,
                color: '#111927',
                mb: 2,
              }}
            >
              Investment Options*
            </Typography>
            <FormStyledRadioButton
              name={`${fieldName}.invOption`}
              options={INVESTMENT_OPTIONS}
            />

            {isAutoInvOption && (
              <PieChartRadioGroup
                // label="Type of LifeCycle Fund"
                name={`${fieldName}.lifeCycleOption`}
                lifecycleOptions={LIFECYCLE_PERCENTAGE_ALLOCATION}
              />
            )}

            {isActiveInvOption && (
              <>
                {/* <Typography
                    variant="body1"
                    style={{
                      fontSize: '14px',
                      color: '#002856',
                      fontFamily: "'Roboto', sans-serif",
                      marginLeft: '20px',
                    }}
                  >
                    Percentage Allocation
                  </Typography> */}

                {schemePercentageDetails.map((item, i) => {
                  const { AssetCategory, MinValue, MaxValue } = item;
                  return (
                    <InputSlider
                      key={i}
                      label={LABELS[AssetCategory as keyof typeof LABELS]}
                      name={`${fieldName}.schemePercentageDetails.${i}.Percentage`}
                      minValue={MinValue}
                      maxValue={MaxValue}
                      options={[]}
                      fullWidth
                    />
                  );
                })}
                {errorMessage && (
                  <Typography
                    sx={{
                      fontSize: '15px',
                      fontWeight: 500,
                      color: '#111927',
                      my: 2,
                    }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </>
            )}
          </>
        );
      }}
    </Field>
  );
};

export const SchemeDetails = () => {
  const {
    state: { ackNumber, schemeDetails: SD, accountType: AT },
    dispatch,
  } = useNewSubscriberContext();
  const [saveSchemeDetails] = useSaveSchemeDetailsMutation();
  const [getSchemeDetails] = useGetnpsSchemeDetailsMutation();
  const { showToast } = useRootContext();

  const accountType = AT?.data?.AccountType || '1';
  const hasTierTwo = accountType === '2';

  const {
    contribution_amount,
    contribution_amountT2,
    TierTwoInvestmentChoice,
    TierOneInvestmentChoice,
  } = SD?.data || {};
  const commonValidationSchema = Yup.object().shape({
    contributionAmount: Yup.number()
      .required('Contribution Amount is required')
      .min(500, 'Contribution amount should be greater than 500.'),
    pensionFundManager: Yup.string().required('Pension Fund Manager is required'),
    invOption: Yup.string()
      .oneOf(['1', '2'], 'Investment Options is required')
      .required('Investment Options is required'),
    lifeCycleOption: Yup.string().when('invOption', (invOption, schema) => {
      return +invOption === 2 ? schema.required('Life Cycle Option is required') : schema;
    }),
    schemePercentageDetails: Yup.array()
      .when('invOption', (invOption, schema) => {
        return +invOption === 1
          ? schema
              .of(
                Yup.object().shape({
                  Percentage: Yup.number()
                    .required('Percentage is required')
                    .min(0, 'Percentage should be greater than 0')
                    .max(
                      Yup.ref('MaxValue'),
                      ({ max }) => `Percentage should be less than ${max}`,
                    ),
                }),
              )
              .min(1, 'Atleast one scheme is required')
          : schema;
      })
      .test(
        'sum',
        'Sum of percentages should be 100',
        function (value, { parent: { invOption } }) {
          if (+invOption !== 1) {
            return true;
          }
          if (value) {
            const sum = value.reduce((acc, curr) => acc + curr.Percentage, 0);
            if (sum !== 100) {
              return this.createError({
                message: `Sum of percentages should be 100, Currently ${sum}`,
              });
            }
            return true;
          }
          return true;
        },
      ),
  });

  const validationSchema = Yup.object().shape({
    tier1: commonValidationSchema,
    ...(hasTierTwo
      ? {
          tier2: commonValidationSchema.clone().shape({
            contributionAmount: Yup.number()
              .required('Contribution amount is required')
              .min(1000, 'Contribution amount for Tier II should be greater than 1000'),
          }),
        }
      : {}),
  });
  const initialValues = {
    tier1: {
      ITType: '1',
      contributionAmount: contribution_amount || '',
      pensionFundManager: SD?.data?.TierOneFundCodeOpted || '',
      frequency: '',
      startDate: '',
      endDate: '',
      invOption: TierOneInvestmentChoice === 1 ? '1' : '2',
      lifeCycleOption:
        Object.keys(LIFECYCLE_MAP).find(
          key => LIFECYCLE_MAP[key] === SD?.data?.Lifecyclefundtierone,
        ) || '',
      schemePercentageDetails: [],
    },
    ...(hasTierTwo
      ? {
          tier2: {
            ITType: '1',
            contributionAmount: contribution_amountT2 ? contribution_amountT2 : '',
            pensionFundManager: SD?.data?.TierTwoFundCodeOpted || '',
            frequency: '',
            startDate: '',
            endDate: '',
            invOption: TierTwoInvestmentChoice === 1 ? '1' : '2',
            lifeCycleOption:
              Object.keys(LIFECYCLE_MAP).find(
                key => LIFECYCLE_MAP[key] === SD?.data?.Lifecyclefundtiertwo,
              ) || '',
            schemePercentageDetails: [],
          },
        }
      : {}),
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const params = {
        AccountType: accountType,
        AckNumber: ackNumber,
        TierOneFundCodeOpted: values.tier1.pensionFundManager,
        TierOneInvestmentChoice:
          values.tier1.invOption === '1' ? '1' : values.tier1.lifeCycleOption,
        contribution_amount: Number(values.tier1.contributionAmount),
        chkschemeSameasTier1: 'N',
        Scheme: JSON.stringify(values['tier1'].schemePercentageDetails || []).trim(),
        Scheme2: '[]',
        Lifecyclefundtierone:
          values.tier1.invOption === '1'
            ? ''
            : LIFECYCLE_MAP[values.tier1.lifeCycleOption],
        ...(hasTierTwo
          ? {
              TierTwoFundCodeOpted: values.tier2?.pensionFundManager,
              TierTwoInvestmentChoice:
                values.tier2?.invOption === '1' ? '1' : values.tier2?.lifeCycleOption,
              contribution_amountT2: Number(values.tier2?.contributionAmount),
              Lifecyclefundtiertwo:
                values.tier2?.invOption === '1'
                  ? ''
                  : LIFECYCLE_MAP[values.tier2?.lifeCycleOption || '2'],
              Scheme2: JSON.stringify(values.tier2?.schemePercentageDetails || []).trim(),
            }
          : {}),
      };

      const response = await saveSchemeDetails(params).unwrap();

      if (Number(response.statusCode) !== SUCCESS_RESPONSE_CODE) {
        throw new Error(response.message || 'Error saving scheme details');
      }

      const responseScheme = await getSchemeDetails({
        AckNumber: ackNumber,
      }).unwrap();
      dispatch(setSchemeDetails(responseScheme));
      dispatch(setStepNumber(5));
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Form>
              {Object.keys(values).map(key => (
                <SchemeSection
                  name={key as keyof InitialValues}
                  key={key}
                  values={values[key as keyof InitialValues] as TierValues}
                />
              ))}
              <SubmitButton
                sx={{ minWidth: { xs: '100%', sm: '200px' }, my: 2 }}
                label='Save & Proceed'
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};
