import { Box, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

import { useTimer } from '../../../../hooks/useTimer';
import {
  useResendEditOtpMutation,
  useUpdateChangeRequestMutation,
  useVerifyEditOtpMutation,
} from '../../../auth/signup/slices';
import { useRootContext } from '../../../data/root.context';
import FormOTPInput from '../../../forms/FormOtpInput/FormOTPInput';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { LinkButton, SubTitle } from '../../../onboarding/styles/styledComponents';
import { ApiResponse, ProfileData } from './PersonalDetails';

const maskEmail = (email: string): string => {
  const [localPart, domain] = email.split('@');
  if (localPart.length <= 2) {
    return email;
  }
  const visiblePart = localPart.slice(0, 2);
  const maskedPart = '*'.repeat(localPart.length - 2);
  return `${visiblePart}${maskedPart}@${domain}`;
};

interface NewMobileNumberVerificationProps {
  setNewOtpVerificationDialog: (open: boolean) => void;
  newMob: string;
  newCountryCode: string;
  setOpenSuccessPopup: (open: boolean) => void;
  type: string;
  response: ApiResponse | null;
  newEmail: string;
  setResponseMsg: (message: string) => void;
}

interface ChangeMobileNumberCompProps {
  setChangeMobileNoDialogBox: (open: boolean) => void;
  setNewOtpVerificationDialog: (open: boolean) => void;
  setNewMobileNumber: (number: string) => void;
  setNewCountryCode: (code: string) => void;
  type: string;
  setNewEmail: (email: string) => void;
  response: ApiResponse | null;
}

interface EditMobileCompProps {
  data: ProfileData;
  setOpenOtpVerificationDialogBox: (open: boolean) => void;
  setChangeMobileNoDialogBox: (open: boolean) => void;
  type: string;
  response: ApiResponse | null;
}

export function NewMobileNumberVerification({
  setNewOtpVerificationDialog,
  newMob,
  newCountryCode,
  setOpenSuccessPopup,
  type,
  response,
  newEmail,
  setResponseMsg,
}: NewMobileNumberVerificationProps) {
  const { showToast } = useRootContext();
  const [verifyEditOtp] = useVerifyEditOtpMutation();
  const [resendEditOtp, resendEditOtpResponse] = useResendEditOtpMutation();
  let minutes = 3;
  const { timer, resetTimer } = useTimer({
    initcounter: minutes * 60,
    initStart: true,
  });
  const formattedTime = new Date(timer * 1000).toISOString().substr(14, 5);
  const isResendDisabled = timer < 1;

  const initialValues = {
    otp: ['', '', '', '', '', ''],
  };

  async function handleResendButton() {
    resetTimer();
    try {
      await resendEditOtp({
        token: response?.token,
      }).unwrap();
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  }

  useEffect(() => {
    if (resendEditOtpResponse && resendEditOtpResponse.isSuccess) {
      showToast(resendEditOtpResponse.data.message as string, 'success');
    }
  }, [resendEditOtpResponse]);

  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        token: response?.token,
        otp: values.otp.join(''),
      };
      const res = await verifyEditOtp(payload).unwrap();
      setResponseMsg(res.message);
      setNewOtpVerificationDialog(false);
      setOpenSuccessPopup(true);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  return (
    <>
      <Typography
        sx={theme => ({
          my: '20px',
          textAlign: 'center',
          color: 'primary.main',
          fontWeight: 700,
          fontSize: '25px',
          mb: 5,
          [theme.breakpoints.up(1600)]: {
            fontSize: '30px',
          },
        })}
      >
        {`Change ${type === 'mobile' ? 'Mobile Number' : 'Email ID'}`}
      </Typography>
      <Box sx={{ width: { xs: '100%', sm: '80%' }, margin: '0 auto' }}>
        <Typography
          variant='subtitle2'
          sx={{ mb: 2.5 }}
        >{`Enter New ${type === 'mobile' ? 'Mobile Number' : 'Email ID'} `}</Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Form>
            {type === 'mobile' ? (
              <Stack
                flexDirection='row'
                mb={3}
              >
                <Box
                  sx={{
                    width: { xs: '20%' },
                  }}
                >
                  <FormTextInput
                    sx={{
                      '& .MuiInputBase-input ': {
                        borderRadius: '7px 0px 0px 7px',
                        borderRight: '0',
                      },
                    }}
                    name='countryCode'
                    disabled
                    required={false}
                    value={newCountryCode}
                  />
                </Box>
                <Box
                  width='75%'
                  sx={{
                    'width': { xs: '80%', sm: '75%' },
                    '& .MuiInputBase-input ': {
                      borderRadius: '0px 7px 7px 0px',
                    },
                  }}
                >
                  <FormTextInput
                    name='mobNumber'
                    placeholder='Mobile number'
                    label='Mobile number'
                    value={newMob?.replace(/.(?=.{4})/g, '*')}
                    disabled
                  />
                </Box>
              </Stack>
            ) : (
              <Box sx={{ mb: 2 }}>
                <FormTextInput
                  name='emailID'
                  placeholder='Email ID'
                  label='Email ID'
                  value={maskEmail(newEmail)}
                  disabled
                />
              </Box>
            )}

            <Typography
              variant='subtitle2'
              sx={{ mb: 2.5 }}
            >
              {`Enter OTP Code Sent to your New ${
                type === 'mobile' ? 'Mobile Number' : 'Email Id'
              }
              ${
                type === 'mobile'
                  ? newCountryCode + newMob?.replace(/.(?=.{4})/g, '*')
                  : maskEmail(newEmail)
              }`}
            </Typography>
            <FormOTPInput name='otp' />
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Stack
                direction='row'
                gap='4px'
                alignItems='center'
              >
                <SubTitle>Didn’t receive OTP?</SubTitle>
                <LinkButton
                  onClick={handleResendButton}
                  disabled={!isResendDisabled}
                >
                  Resend OTP
                </LinkButton>
              </Stack>
              <Stack>
                <SubTitle sx={{ textAlign: 'right', color: '#2057A6 !important' }}>
                  {formattedTime}
                </SubTitle>
              </Stack>
            </Stack>
            {response && response?.isUsername && (
              <Typography sx={{ color: 'primary.main' }}>
                {`Updating your ${
                  type === 'mobile' ? 'mobile number' : 'Email ID'
                } will update your Username as well.`}
              </Typography>
            )}
            {response && response?.isAdmin && (
              <Typography sx={{ color: 'primary.main' }}>
                The email/mobile will be changed of the individual account too.
              </Typography>
            )}
            <SubmitButton
              label='Verify & Continue'
              sx={{ my: 4, minWidth: '100%' }}
              fullWidth
            />
          </Form>
        </Formik>
      </Box>
    </>
  );
}

export function ChangeMobileNumberComp({
  setChangeMobileNoDialogBox,
  setNewOtpVerificationDialog,
  setNewMobileNumber,
  setNewCountryCode,
  type,
  setNewEmail,
  response,
}: ChangeMobileNumberCompProps) {
  const { showToast } = useRootContext();
  const [updateChangeRequest] = useUpdateChangeRequestMutation();

  const handleFormSubmit = async (values: any) => {
    try {
      const payload = {
        mobile: type === 'mobile' ? values?.mobNumber : '',
        countryCode: type === 'mobile' ? values?.countryCode : '',
        email: type === 'mobile' ? '' : values?.email,
        token: response?.token,
      };
      await updateChangeRequest(payload).unwrap();
      if (type === 'mobile') {
        setNewMobileNumber(values.mobNumber);
        setNewCountryCode(values.countryCode);
      } else {
        setNewEmail(values.email);
      }
      setChangeMobileNoDialogBox(false);
      setNewOtpVerificationDialog(true);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  return (
    <Formik
      initialValues={
        type === 'mobile'
          ? {
              countryCode: '+91',
              mobNumber: '',
            }
          : {
              email: '',
            }
      }
      onSubmit={handleFormSubmit}
      validationSchema={
        type === 'mobile'
          ? Yup.object().shape({
              mobNumber: Yup.string()
                .required('Mobile Number is required')
                .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
            })
          : Yup.object().shape({
              email: Yup.string()
                .required('Email is required')
                .matches(
                  /^(\w[-._+]{0,1})*\w+@((\w)+[-]{0,1})*(\w)+(\.\w+)+\w$/,
                  'email must be a valid email',
                ),
            })
      }
    >
      {({ values }) => {
        return (
          <Form>
            <Box
              sx={{
                marginTop: '20px',
                marginBottom: '20px',
                display: 'block',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={theme => ({
                  color: 'primary.main',
                  fontWeight: 700,
                  fontSize: '25px',
                  mb: 5,
                  [theme.breakpoints.up(1600)]: {
                    fontSize: '30px',
                  },
                })}
              >
                {`Change ${type === 'mobile' ? 'Mobile Number' : 'Email ID'}`}
              </Typography>
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '80%' }, margin: '0 auto' }}>
              <Typography
                variant='subtitle2'
                sx={{ mb: 2.5 }}
              >{`Enter New ${
                type === 'mobile' ? 'Mobile Number' : 'Email ID'
              }`}</Typography>
              {type === 'mobile' ? (
                <>
                  <Stack flexDirection='row'>
                    <Box
                      sx={{
                        width: { xs: '20%' },
                      }}
                    >
                      <FormTextInput
                        sx={{
                          '& .MuiInputBase-input ': {
                            borderRadius: '7px 0px 0px 7px',
                            borderRight: '0',
                          },
                        }}
                        name='countryCode'
                        disabled
                        required={false}
                      />
                    </Box>
                    <Box
                      width='75%'
                      sx={{
                        'width': { xs: '80%', sm: '75%' },
                        '& .MuiInputBase-input ': {
                          borderRadius: '0px 7px 7px 0px',
                        },
                      }}
                    >
                      <FormTextInput
                        name='mobNumber'
                        placeholder='Mobile number'
                        label='Mobile number'
                      />
                    </Box>
                  </Stack>
                </>
              ) : (
                <>
                  <FormTextInput
                    name='email'
                    placeholder='Email Id'
                    label='Email ID'
                  />
                </>
              )}
              <SubmitButton
                label='Verify & Continue'
                sx={{ my: 4, minWidth: '100%' }}
                fullWidth
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default function EditMobileComp({
  data,
  setOpenOtpVerificationDialogBox,
  setChangeMobileNoDialogBox,
  type,
  response,
}: EditMobileCompProps) {
  const { showToast } = useRootContext();
  const [verifyEditOtp] = useVerifyEditOtpMutation();
  const [resendEditOtp, resendEditOtpResponse] = useResendEditOtpMutation();

  const initialValues = {
    otp: ['', '', '', '', '', ''],
  };

  let minutes = 3;
  const { timer, resetTimer } = useTimer({
    initcounter: minutes * 60,
    initStart: true,
  });
  const formattedTime = new Date(timer * 1000).toISOString().substr(14, 5);
  const isResendDisabled = timer < 1;
  async function handleResendButton() {
    resetTimer();
    try {
      await resendEditOtp({
        token: response?.token,
      }).unwrap();
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  }

  useEffect(() => {
    if (resendEditOtpResponse && resendEditOtpResponse.isSuccess) {
      showToast(resendEditOtpResponse.data.message as string, 'success');
    }
  }, [resendEditOtpResponse]);

  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        token: response?.token,
        otp: values?.otp.join(''),
      };
      const res = await verifyEditOtp(payload).unwrap();
      if (res.message === 'OTP verified successfully') {
        setOpenOtpVerificationDialogBox(false);
        setChangeMobileNoDialogBox(true);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box
            sx={{
              marginTop: '20px',
              marginBottom: '20px',
              display: 'block',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={theme => ({
                color: 'primary.main',
                fontWeight: 700,
                fontSize: '25px',
                mb: 5,
                [theme.breakpoints.up(1600)]: {
                  fontSize: '30px',
                },
              })}
            >
              OTP Verification
            </Typography>
          </Box>
          <Box sx={{ width: { xs: '100%', sm: '80%' }, margin: '0 auto' }}>
            <Typography
              variant='subtitle2'
              sx={{ mb: 2.5 }}
            >
              {`Enter OTP Code Sent to your Old ${
                type === 'mobile' ? 'Mobile Number' : 'Email ID'
              }
              ${
                type === 'mobile'
                  ? data?.mobile?.replace(/.(?=.{4})/g, '*')
                  : maskEmail(data?.email)
              }`}
            </Typography>

            <FormOTPInput name='otp' />
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Stack
                direction='row'
                alignItems='center'
              >
                <Typography sx={{ fontSize: '14px', color: 'text.labelColor' }}>
                  Didn’t receive OTP?
                </Typography>
                <LinkButton
                  onClick={async () => handleResendButton()}
                  disabled={!isResendDisabled}
                >
                  Resend OTP
                </LinkButton>
              </Stack>
              <Stack>
                <SubTitle sx={{ textAlign: 'right', color: '#2057A6 !important' }}>
                  {formattedTime}
                </SubTitle>
              </Stack>
            </Stack>
            <SubmitButton
              label='Verify & Continue'
              sx={{ my: 4, minWidth: '100%' }}
              fullWidth
            />
          </Box>
        </Form>
      </Formik>
    </>
  );
}
