import { Box, Divider, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { DetailsValue } from '../../../auth/login/styles/styledComponents';
import { countryListOptions } from '../../../common/constants';
import { useRootContext } from '../../../data/root.context';
import { FormSelectInput } from '../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { FieldSubHeader3 } from '../../../onboarding/styles/styledComponents';
import {
  Gross_Annual_income_options,
  Occupations_options,
  sourceOfWealthOptions,
} from '../../mfs/constants';
import { useLazyGetMasterDataQuery } from '../../nps/investorOnBoarding/slice';
import { _getOptions } from '../../nps/investorOnBoarding/utils';
import {
  setFatcaDetails,
  setHolderDetails,
  setStepNumber,
} from '../context/MfsNewPurchaseAction';
import { useMfsNewPurchaseContext } from '../context/MfsNewPurchaseContext';

interface Option {
  value: any;
  label: any;
}

interface FatcaDetailValues {
  taxResidentOtherThanIndia: string;
  country: string;
  birthPlace: string;
  annualIncome: string;
  sourceOfWealth: string;
  occupation: string;
  pincode: string;
  taxResidentCountry: string;
  foreignIdNo: string;
  politicallyExposedPerson: string;
  politicallyExposedRelative: string;
  taxResidentOtherThanIndiaH1: string;
  countryH1: string;
  birthPlaceH1: string;
  annualIncomeH1: string;
  sourceOfWealthH1: string;
  occupationH1: string;
  pincodeH1: string;
  taxResidentCountryH1: string;
  foreignIdNoH1: string;
  politicallyExposedPersonH1: string;
  politicallyExposedRelativeH1: string;
  taxResidentOtherThanIndiaH2: string;
  countryH2: string;
  birthPlaceH2: string;
  annualIncomeH2: string;
  sourceOfWealthH2: string;
  occupationH2: string;
  pincodeH2: string;
  taxResidentCountryH2: string;
  foreignIdNoH2: string;
  politicallyExposedPersonH2: string;
  politicallyExposedRelativeH2: string;
}

const FatcaDetails: React.FC = () => {
  const { showToast } = useRootContext();

  const [occupationOptions, setOccupationOptions] = useState<Option[]>([]);
  const [incomeRangeOptions, setIncomeRangeOption] = useState<Option[]>([]);

  const [getMasterData, masterData] = useLazyGetMasterDataQuery();

  const {
    dispatch: contextDispatch,
    state: {
      countriesList,
      fatcaDetails,
      holderDetails,
      investorDetails,
      investorDetailsFormResponse,
    },
  } = useMfsNewPurchaseContext();

  const { personalDetails } = investorDetailsFormResponse || {};

  const countryOptions =
    countriesList?.length !== 0
      ? countriesList
      : countryListOptions?.map((country: any) => ({
          value: country.country_name,
          label: country.country_name,
        }));

  const politicallyExposedPersonRes =
    personalDetails?.politicallyExposedPerson === 'Y' ? 'Yes' : 'No';
  const politicallyExposedPersonRelativeRes =
    personalDetails?.politicallyExposedPersonRelative === 'Y' ? 'Yes' : 'No';

  const taxResidentOtherIndia = investorDetails?.category === '20' ? 'Yes' : 'No';

  const [holder1 = {}, holder2 = {}] = holderDetails || [];

  const holder1TaxResidentOtherIndia = holder1?.category === '20' ? 'Yes' : 'No';
  const holder2TaxResidentOtherIndia = holder2?.category === '20' ? 'Yes' : 'No';

  const indiaCountry = countriesList.find(country => country.label === 'India');

  useEffect(() => {
    getMasterData('');
  }, []);

  useEffect(() => {
    if (masterData && masterData.isSuccess && !masterData.isFetching) {
      const { lstIncomeRangeDetails, lstOccupationDetails } = masterData.data;
      const incomeRangeList = _getOptions(lstIncomeRangeDetails, 'PK_ID');
      const occupationList = _getOptions(lstOccupationDetails, 'PK_ID');

      setIncomeRangeOption(incomeRangeList);
      setOccupationOptions(occupationList);
    }
  }, [masterData]);

  const initialValues: FatcaDetailValues = {
    taxResidentOtherThanIndia:
      fatcaDetails?.taxResidentOtherThanIndia || taxResidentOtherIndia || 'No',
    country:
      fatcaDetails?.country ||
      personalDetails?.countryOfCitizenship ||
      indiaCountry?.value ||
      '',
    birthPlace:
      fatcaDetails?.birthPlace ||
      personalDetails?.countryOfBirth ||
      indiaCountry?.value ||
      '',
    annualIncome: fatcaDetails?.annualIncome || personalDetails?.grossAnnualIncome || '',
    sourceOfWealth: fatcaDetails?.sourceOfWealth || personalDetails?.sourceOfWealth || '',
    occupation: fatcaDetails?.occupation || personalDetails?.occupation || '',
    pincode: fatcaDetails?.pincode || personalDetails?.pinCode || '',
    taxResidentCountry: fatcaDetails?.taxResidentCountry || '',
    foreignIdNo: fatcaDetails?.foreignIdNo || '',
    politicallyExposedPerson:
      fatcaDetails?.politicallyExposedPerson || politicallyExposedPersonRes || 'No',
    politicallyExposedRelative:
      fatcaDetails?.politicallyExposedRelative ||
      politicallyExposedPersonRelativeRes ||
      'No',
    taxResidentOtherThanIndiaH1:
      holder1?.taxResidentOfOtherCountry || holder1TaxResidentOtherIndia || 'No',
    countryH1: holder1?.countryOfCitizenship || '',
    birthPlaceH1: holder1?.countryOfBirth || '',
    annualIncomeH1: holder1?.grossAnnualIncome || '',
    sourceOfWealthH1: holder1?.sourceOfWealth || '',
    occupationH1: holder1?.occupation || '',
    pincodeH1: holder1?.pincode || '',
    taxResidentCountryH1: holder1?.countryOfTaxResident || '',
    foreignIdNoH1: holder1?.taxPayerIdentificationNumber || '',
    politicallyExposedPersonH1: holder1?.politicallyExposedPerson || 'No',
    politicallyExposedRelativeH1: holder1?.politicallyExposedRelative || 'No',
    taxResidentOtherThanIndiaH2:
      holder2?.taxResidentOfOtherCountry || holder2TaxResidentOtherIndia || 'No',
    countryH2: holder2?.countryOfCitizenship || '',
    birthPlaceH2: holder2?.countryOfBirth || '',
    annualIncomeH2: holder2?.grossAnnualIncome || '',
    sourceOfWealthH2: holder2?.sourceOfWealth || '',
    occupationH2: holder2?.occupation || '',
    pincodeH2: holder2?.pincode || '',
    taxResidentCountryH2: holder2?.countryOfTaxResident || '',
    foreignIdNoH2: holder2?.taxPayerIdentificationNumber || '',
    politicallyExposedPersonH2: holder2?.politicallyExposedPerson || 'No',
    politicallyExposedRelativeH2: holder2?.politicallyExposedRelative || 'No',
  };
  const holder1ValidationSchema = {
    taxResidentOtherThanIndiaH1: Yup.string().required(
      'Tax Resident Other Than India is required',
    ),
    countryH1: Yup.string().required('Country of Birth is required'),
    birthPlaceH1: Yup.string().required('Place of Birth is required'),

    annualIncomeH1: Yup.string().required('Annual Income is required'),
    occupationH1: Yup.string().required('Occupation is required'),
    sourceOfWealthH1: Yup.string().required('Source of wealth is required'),
    pincodeH1: Yup.string()
      .required(`${holder1?.category === '20' ? 'ZIP code' : 'Pincode'} is required`)
      .matches(
        /^[0-9]+$/,
        `Please enter valid ${holder1?.category === '20' ? 'ZIP code' : 'Pincode'}`,
      )
      .min(6)
      .max(6),
    politicallyExposedPersonH1: Yup.string().required(
      'Politically exposed person is required',
    ),
    politicallyExposedRelativeH1: Yup.string().required(
      'Politically exposed relative is required',
    ),
    foreignIdNoH1: Yup.string().when('taxResidentOtherThanIndiaH1', {
      is: 'Yes',
      then: Yup.string().required('Foreign Id No is required'),
    }),
    taxResidentCountryH1: Yup.string().when('taxResidentOtherThanIndiaH1', {
      is: 'Yes',
      then: Yup.string().required('Tax resident country is required'),
    }),
  };

  const holder2ValidationSchema = {
    taxResidentOtherThanIndiaH2: Yup.string().required(
      'Tax Resident Other Than India is required',
    ),
    countryH2: Yup.string().required('Country of Birth is required'),
    birthPlaceH2: Yup.string().required('Place of Birth is required'),

    annualIncomeH2: Yup.string().required('Annual Income is required'),
    occupationH2: Yup.string().required('Occupation is required'),
    sourceOfWealthH2: Yup.string().required('Source of wealth is required'),
    pincodeH2: Yup.string()
      .required(`${holder2?.category === '20' ? 'ZIP code' : 'Pincode'} is required`)
      .matches(
        /^[0-9]+$/,
        `Please enter valid ${holder2?.category === '20' ? 'ZIP code' : 'Pincode'}`,
      )
      .min(6)
      .max(6),
    politicallyExposedPersonH2: Yup.string().required(
      'Politically exposed person is required',
    ),
    politicallyExposedRelativeH2: Yup.string().required(
      'Politically exposed relative is required',
    ),
    foreignIdNoH2: Yup.string().when('taxResidentOtherThanIndiaH2', {
      is: 'Yes',
      then: Yup.string().required('Foreign Id No is required'),
    }),
    taxResidentCountryH2: Yup.string().when('taxResidentOtherThanIndiaH2', {
      is: 'Yes',
      then: Yup.string().required('Tax resident country is required'),
    }),
  };

  const validationSchema = Yup.object().shape({
    taxResidentOtherThanIndia: Yup.string().required(
      'Tax Resident Other Than India is required',
    ),
    country: Yup.string().required('Country of Birth is required'),
    birthPlace: Yup.string().required('Place of Birth is required'),

    annualIncome: Yup.string().required('Annual Income is required'),
    occupation: Yup.string().required('Occupation is required'),
    sourceOfWealth: Yup.string().required('Source of wealth is required'),
    pincode: Yup.string()
      .required(
        `${investorDetails?.category === '20' ? 'ZIP code' : 'Pincode'} is required`,
      )
      .matches(
        /^[0-9]+$/,
        `Please enter valid ${
          investorDetails?.category === '20' ? 'ZIP code' : 'Pincode'
        }`,
      )
      .min(6)
      .max(6),
    politicallyExposedPerson: Yup.string().required(
      'Politically exposed person is required',
    ),
    politicallyExposedRelative: Yup.string().required(
      'Politically exposed relative is required',
    ),
    foreignIdNo: Yup.string().when('taxResidentOtherThanIndia', {
      is: 'Yes',
      then: Yup.string().required('Foreign Id No is required'),
    }),
    taxResidentCountry: Yup.string().when('taxResidentOtherThanIndia', {
      is: 'Yes',
      then: Yup.string().required('Tax resident country is required'),
    }),
    ...(holderDetails !== null && holder1.pan ? holder1ValidationSchema : {}),
    ...(holderDetails !== null && holder2.pan ? holder2ValidationSchema : {}),
  });

  const handleSubmit = (values: FatcaDetailValues) => {
    const {
      taxResidentOtherThanIndiaH1,
      countryH1,
      birthPlaceH1,
      annualIncomeH1,
      sourceOfWealthH1,
      occupationH1,
      pincodeH1,
      politicallyExposedPersonH1,
      politicallyExposedRelativeH1,
      taxResidentOtherThanIndiaH2,
      countryH2,
      birthPlaceH2,
      annualIncomeH2,
      sourceOfWealthH2,
      occupationH2,
      pincodeH2,
      politicallyExposedPersonH2,
      politicallyExposedRelativeH2,
      taxResidentCountryH1,
      foreignIdNoH1,
      taxResidentCountryH2,
      foreignIdNoH2,
      ...primaryFatcaValues
    } = values;

    if (holderDetails !== null) {
      const updatedHolder = [
        {
          ...holder1,
          taxResidentOfOtherCountry: taxResidentOtherThanIndiaH1,
          countryOfCitizenship: countryH1,
          countryOfBirth: birthPlaceH1,
          grossAnnualIncome: annualIncomeH1,
          sourceOfWealth: sourceOfWealthH1,
          occupation: occupationH1,
          pincode: pincodeH1,
          countryOfTaxResident: taxResidentCountryH1,
          taxPayerIdentificationNumber: foreignIdNoH1,
          politicallyExposedPerson: politicallyExposedPersonH1,
          politicallyExposedRelative: politicallyExposedRelativeH1,
        },
      ];
      if (holderDetails.length === 2) {
        updatedHolder.push({
          ...holder2,
          taxResidentOfOtherCountry: taxResidentOtherThanIndiaH2,
          countryOfCitizenship: countryH2,
          countryOfBirth: birthPlaceH2,
          grossAnnualIncome: annualIncomeH2,
          sourceOfWealth: sourceOfWealthH2,
          occupation: occupationH2,
          pincode: pincodeH2,
          countryOfTaxResident: taxResidentCountryH2,
          taxPayerIdentificationNumber: foreignIdNoH2,
          politicallyExposedPerson: politicallyExposedPersonH2,
          politicallyExposedRelative: politicallyExposedRelativeH2,
        });
      }
      contextDispatch(setHolderDetails(updatedHolder));
    }
    contextDispatch(setFatcaDetails(primaryFatcaValues));
    contextDispatch(setStepNumber(2));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Form>
              <Grid
                container
                spacing={2}
              >
                {holder1?.pan && holder1?.pan.length === 10 && (
                  <Grid
                    item
                    xs={12}
                    mb={2}
                  >
                    <Box
                      sx={{
                        borderRadius: '5px',
                        padding: '16px 20px',
                        mt: 2,
                        background:
                          'linear-gradient(90deg, #EEE 0%, rgba(255, 255, 255, 0.00) 100%)',
                      }}
                    >
                      <Typography variant='subtitle2'>Primary Holder Details</Typography>
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                >
                  <Box sx={{ mb: 2 }}>
                    <DetailsValue sx={{ mb: 2 }}>
                      Tax Resident of Country other than India
                    </DetailsValue>
                    <FormStyledRadioButton
                      options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                      ]}
                      name='taxResidentOtherThanIndia'
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormSelectInput
                    name='country'
                    label='Country Of Birth'
                    options={countryOptions}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormSelectInput
                    name='birthPlace'
                    label='Place Of Birth'
                    options={countryOptions}
                    required
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                >
                  <Divider
                    orientation='horizontal'
                    variant='fullWidth'
                    flexItem
                    sx={{
                      border: '1px dashed',
                      borderColor: 'text.borderColorDark',
                      my: 3,
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormSelectInput
                    name='annualIncome'
                    label='Annual Income'
                    options={Gross_Annual_income_options}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormSelectInput
                    name='occupation'
                    label='Occupation'
                    required
                    options={Occupations_options}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='pincode'
                    label={
                      investorDetails?.category === '20' ||
                      values.taxResidentOtherThanIndia === 'Yes'
                        ? 'ZIP code'
                        : 'Pincode'
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormSelectInput
                    name='sourceOfWealth'
                    label='Source Of Wealth'
                    options={sourceOfWealthOptions}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  mb={2}
                >
                  <DetailsValue sx={{ mb: 2 }}>Politically Exposed Person</DetailsValue>
                  <FormStyledRadioButton
                    options={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' },
                    ]}
                    name='politicallyExposedPerson'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  mb={2}
                >
                  <DetailsValue sx={{ mb: 2 }}>Politically Exposed Relative</DetailsValue>
                  <FormStyledRadioButton
                    options={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' },
                    ]}
                    name='politicallyExposedRelative'
                  />
                </Grid>
                {values.taxResidentOtherThanIndia === 'Yes' && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='taxResidentCountry'
                        label='Tax Resident'
                        required
                        options={
                          countriesList.length !== 0
                            ? countriesList.filter(item => item.label !== 'India')
                            : countryListOptions
                                .filter(item => item.country_name !== 'India')
                                .map((country: any) => ({
                                  value: country.country_name,
                                  label: country.country_name,
                                }))
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='foreignIdNo'
                        label='Foreign ID No'
                      />
                    </Grid>
                  </>
                )}
                {holder1?.pan && holder1?.pan.length === 10 && (
                  <>
                    <Grid
                      item
                      xs={12}
                      mb={2}
                    >
                      <Box
                        sx={{
                          borderRadius: '5px',
                          padding: '16px 20px',
                          mt: 2,
                          background:
                            'linear-gradient(90deg, #EEE 0%, rgba(255, 255, 255, 0.00) 100%)',
                        }}
                      >
                        <FieldSubHeader3>First Joint Holder Details</FieldSubHeader3>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Box sx={{ mb: 2 }}>
                        <DetailsValue sx={{ mb: 2 }}>
                          Tax Resident of Country other than India
                        </DetailsValue>
                        <FormStyledRadioButton
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ]}
                          name='taxResidentOtherThanIndiaH1'
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='countryH1'
                        label='Country Of Birth'
                        options={countryOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='birthPlaceH1'
                        label='Place Of Birth'
                        options={countryOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='annualIncomeH1'
                        label='Annual Income'
                        options={Gross_Annual_income_options}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='occupationH1'
                        label='Occupation'
                        required
                        options={Occupations_options}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='pincodeH1'
                        label={
                          holder1?.category === '20' ||
                          values.taxResidentOtherThanIndiaH1 === 'Yes'
                            ? 'ZIP code'
                            : 'Pincode'
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='sourceOfWealthH1'
                        label='Source Of Wealth'
                        options={sourceOfWealthOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      mb={2}
                    >
                      <DetailsValue sx={{ mb: 2 }}>
                        Politically Exposed Person
                      </DetailsValue>
                      <FormStyledRadioButton
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                        ]}
                        name='politicallyExposedPersonH1'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      mb={2}
                    >
                      <DetailsValue sx={{ mb: 2 }}>
                        Politically Exposed Relative
                      </DetailsValue>
                      <FormStyledRadioButton
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                        ]}
                        name='politicallyExposedRelativeH1'
                      />
                    </Grid>
                    {values.taxResidentOtherThanIndiaH1 === 'Yes' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                        >
                          <FormSelectInput
                            name='taxResidentCountryH1'
                            label='Tax Resident'
                            required
                            options={
                              countriesList.length !== 0
                                ? countriesList.filter(item => item.label !== 'India')
                                : countryListOptions
                                    .filter(item => item.country_name !== 'India')
                                    .map((country: any) => ({
                                      value: country.country_name,
                                      label: country.country_name,
                                    }))
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                        >
                          <FormTextInput
                            name='foreignIdNoH1'
                            label='Foreign ID No'
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {holder2?.pan && holder2?.pan.length === 10 && (
                  <>
                    <Grid
                      item
                      xs={12}
                      mb={2}
                    >
                      <Box
                        sx={{
                          borderRadius: '5px',
                          padding: '16px 20px',
                          mt: 2,
                          background:
                            'linear-gradient(90deg, #EEE 0%, rgba(255, 255, 255, 0.00) 100%)',
                        }}
                      >
                        <FieldSubHeader3>second Joint Holder Details</FieldSubHeader3>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Box sx={{ mb: 2 }}>
                        <DetailsValue sx={{ mb: 2 }}>
                          Tax Resident of Country other than India
                        </DetailsValue>
                        <FormStyledRadioButton
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ]}
                          name='taxResidentOtherThanIndiaH2'
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='countryH2'
                        label='Country Of Birth'
                        options={countryOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='birthPlaceH2'
                        label='Place Of Birth'
                        options={countryOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='annualIncomeH2'
                        label='Annual Income'
                        options={Gross_Annual_income_options}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='occupationH2'
                        label='Occupation'
                        required
                        options={Occupations_options}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='pincodeH2'
                        label={
                          holder2?.category === '20' ||
                          values.taxResidentOtherThanIndiaH2 === 'Yes'
                            ? 'ZIP code'
                            : 'Pincode'
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='sourceOfWealthH2'
                        label='Source Of Wealth'
                        options={sourceOfWealthOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      mb={2}
                    >
                      <DetailsValue sx={{ mb: 2 }}>
                        Politically Exposed Person
                      </DetailsValue>
                      <FormStyledRadioButton
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                        ]}
                        name='politicallyExposedPersonH2'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      mb={2}
                    >
                      <DetailsValue sx={{ mb: 2 }}>
                        Politically Exposed Relative
                      </DetailsValue>
                      <FormStyledRadioButton
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                        ]}
                        name='politicallyExposedRelativeH2'
                      />
                    </Grid>
                    {values.taxResidentOtherThanIndiaH2 === 'Yes' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                        >
                          <FormSelectInput
                            name='taxResidentCountryH2'
                            label='Tax Resident'
                            required
                            options={
                              countriesList.length !== 0
                                ? countriesList.filter(item => item.label !== 'India')
                                : countryListOptions
                                    .filter(item => item.country_name !== 'India')
                                    .map((country: any) => ({
                                      value: country.country_name,
                                      label: country.country_name,
                                    }))
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                        >
                          <FormTextInput
                            name='foreignIdNoH2'
                            label='Foreign ID No'
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
              <SubmitButton
                sx={{
                  '&.MuiButtonBase-root': {
                    borderRadius: '5px',
                    minWidth: { xs: '100%', sm: '200px' },
                    mt: 5,
                  },
                }}
                label='Proceed'
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default FatcaDetails;
