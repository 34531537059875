import { Avatar, Chip, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import Female from '../../../../../assets/female.svg';
import Male from '../../../../../assets/male.svg';
import Others from '../../../../../assets/others.svg';
import { useRootContext } from '../../../../data/root.context';
import FormDateInput from '../../../../forms/FormDateInput';
import { FormSelectInput } from '../../../../forms/FormSelect';
import StyledRadioButtonGroup from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput from '../../../../forms/FormTextInput';
import { SUCCESS_RESPONSE_CODE } from '..';
import {
  setAccountTypes,
  setBankDetails,
  setBasicDetails,
  setFatcaDetails,
  setKycDetails,
  setNomineeDetails,
  setPersonalDetails,
  setSchemeDetails,
  setStepNumber,
} from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import {
  useGetAccountTypeMutation,
  useGetBankDetailsMutation,
  useGetDetailsFromPincodeMutation,
  useGetEkycDetailsMutation,
  useGetFatcaMutation,
  useGetKycDetailsMutation,
  useGetNpsNomineeDetailsMutation,
  useGetNpsSchemeDetailsMutation,
  useGetPersonalDetailsMutation,
  useLazyGetMasterDataQuery,
  useSaveBasicDetailsMutation,
  useSaveKycDetailsMutation,
  useSavePersonalDetailsMutation,
  useValidatePanMutation,
} from '../slice';
import { _getOptions, dateToDDMMYYY, formatDate, replaceDashToSlash } from '../utils';

interface Option {
  value: any;
  label: any;
}

const gendersList = {
  Male: Male,
  Female: Female,
  Others: Others,
};

const InvestorDetails: React.FC = () => {
  const {
    state: {
      dob,
      refId,
      pan,
      ackNumber,
      mobileNumber,
      email,
      kycDetails,
      kycTrack,
      EKYCDetails,
      personalDetails,
      kycBankDetails,
      isBackToSummary,
      ResidenceType,
      AAAdetails,
      skipKyc,
    },
    dispatch: dispatchContext,
  } = useNewSubscriberContext();

  const { showToast } = useRootContext();

  const [getMasterData] = useLazyGetMasterDataQuery();
  const [getPersonalDetails] = useGetPersonalDetailsMutation();
  const [SaveBasicDetails] = useSaveBasicDetailsMutation();
  const [SavePersonalDetails] = useSavePersonalDetailsMutation();
  const [getDetailsFromPincode] = useGetDetailsFromPincodeMutation();
  const [saveKycDetails] = useSaveKycDetailsMutation();

  const [getKycDetails] = useGetKycDetailsMutation();
  const [getEkycDetails] = useGetEkycDetailsMutation();
  const [validatePan] = useValidatePanMutation();
  const [getNomineeDetails] = useGetNpsNomineeDetailsMutation();
  const [getSchemeDetails] = useGetNpsSchemeDetailsMutation();
  const [getAccountType] = useGetAccountTypeMutation();
  const [getFatcaDetails] = useGetFatcaMutation();
  const [getBankDetails] = useGetBankDetailsMutation();

  const { DtData: kycKtrackDetails } = kycTrack;
  const EKYCdata = EKYCDetails?.data?.result?.PERSONAL_DETAILS || {};
  const { data } = personalDetails?.data || {};
  const {
    Address1,
    Addreess2,
    CountryName,
    DOB,
    District,
    Gender,
    IdentifierType: aadhaarIdentifierType,
    IdentifierNum,
    PinCode,
    StateName,
    SubscriberName,
  } = AAAdetails || {};

  useEffect(() => {
    const setOptions = async () => {
      try {
        const pDetailsResponse = await getMasterData('').unwrap();
        if (+pDetailsResponse?.statusCode === SUCCESS_RESPONSE_CODE) {
          const {
            ResidentTypeList = [],
            GenderList = [],
            CountryList = [],
            PranTitlesList = [],
            MaritalStatusList = [],
            lstOccupationDetails = [],
          } = personalDetails?.data || pDetailsResponse?.data || {};
          setResidentTypeList(_getOptions(ResidentTypeList, 'ID'));
          setOccupationOptions(_getOptions(lstOccupationDetails, 'PK_ID'));
          setCountryList(_getOptions(CountryList, 'PK_ID'));
          setGenderOptions(
            _getOptions(GenderList, 'PK_ID').filter(
              (gender: { label: string }) => gender.label !== 'Transgender',
            ),
          );
          setMaritalStatusOptions(_getOptions(MaritalStatusList, 'ID'));
          setPranNameOption(_getOptions(PranTitlesList, 'ID'));
        }
      } catch (error) {}
    };
    setOptions();
  }, []);

  useEffect(() => {
    async function fetchPersonalDetails() {
      const response = await getPersonalDetails({ AckNumber: ackNumber });
      dispatchContext(setPersonalDetails(response));
    }

    if (ackNumber) {
      fetchPersonalDetails();
    }
  }, [ackNumber, dispatchContext]);

  // get Master Data and fill these values
  const [residentTypeList, setResidentTypeList] = useState<Option[]>([]);
  const [occupationOptions, setOccupationOptions] = useState<Option[]>([]);
  const [countryList, setCountryList] = useState<Option[]>([]);
  const [genderOptions, setGenderOptions] = useState<Option[]>([]); // TODO: based on figma do the changes
  const [maritalStatusOptions, setMaritalStatusOptions] = useState<Option[]>([]);
  const [pranNameOptions, setPranNameOption] = useState<Option[]>([]);

  let dobb = DOB
    ? DOB
    : EKYCdata?.DOB || dob || data?.DOB
    ? EKYCdata?.DOB || dob || data?.DOB
    : '';
  if (dobb instanceof Date) {
    dobb = dateToDDMMYYY(dobb);
  } else if (dobb.includes('-')) {
    dobb = replaceDashToSlash(dobb, '-', '/');
  }

  const _residenceStatus =
    data?.ResidenceType || ResidenceType || kycDetails?.CountryName === 'IN' ? '1' : '';
  const initialValues = {
    status: _residenceStatus,
    pan: pan || EKYCdata?.PAN,
    dob: formatDate(dobb),
    mobile:
      data?.Mobile ||
      EKYCdata?.MOB_NUM ||
      mobileNumber ||
      // kycKtrackDetails?.mobile ||
      '',
    email:
      data?.Email ||
      EKYCdata?.EMAIL ||
      email ||
      // kycKtrackDetails?.email ||
      '',
    name:
      data?.Name ||
      EKYCdata?.FULLNAME ||
      kycDetails?.SubscriberName ||
      SubscriberName ||
      '',
    occupation: data?.Occupation || '',
    place: data?.CityofBirth || '',
    nationality: data?.Nationality || '' || '',
    country:
      _residenceStatus === '1'
        ? 'IN'
        : EKYCdata?.PERM_COUNTRY || kycDetails?.CountryName || CountryName || '',
    gender: data?.Gender || EKYCdata?.GENDER || kycDetails?.Gender || Gender || '',
    maritalStatus: data?.MaritalStatus || '',
    fatherName: data?.FatherFirstName || EKYCdata?.FATHER_FULLNAME || '',
    motherName: data?.MotherFirstName || EKYCdata?.MOTHER_FULLNAME || '',
    pranName: String(data?.PranTitleID) || '',
    occupationDetails: '',
    spouseName: data?.SpouceFirstName || '',
  };

  const getPersonalDetailsRequestBody = (values: {
    status: any;
    gender: any;
    email: any;
    pan: any;
    country: any;
    place: any;
    name: any;
    nationality: any;
    maritalStatus: string | number;
    occupation: string | number;
    mobile: any;
    dob: string | number | Date;
    pranName: any;
    fatherName: any;
    motherName: any;
    spouseName: any;
    occupationDetails: any;
  }) => ({
    ResidenceType: values.status,
    Gender: values.gender,
    Email: values.email,
    PANNumber: values.pan,
    CountryofBirth: values.country,
    CityofBirth: values.place,
    FullName: values.name,
    Nationality: values.nationality,
    MaritalStatus: String(values.maritalStatus),
    OccupationDetails: values.occupation,
    Mobile: values.mobile,
    DOB:
      typeof values.dob !== 'string'
        ? format(values.dob, 'dd/MM/yyyy')
        : format(new Date(values.dob), 'dd/MM/yyyy'),
    PranTitleID: values.pranName,
    FatherFirstName: values.fatherName || '',
    MotherFirstName: values.motherName || '',
    ReffID: refId,
    ...(+values.maritalStatus === 1 && {
      SpouceFirstName: values.spouseName,
    }),
    ...(+values.occupation === 29 && {
      OtherOccupation: values.occupationDetails,
    }),
  });

  const validationSchema = Yup.object().shape({
    status: Yup.string().required('Status of Residence is required'),
    pan: Yup.string()
      .required('PAN is required')
      .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN'),
    dob: Yup.string().required('Date of Birth is required'),
    mobile: Yup.string()
      .required('Mobile Number is required')
      .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    email: Yup.string()
      .required('Email is required')
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Invalid Email'),
    name: Yup.string().required('Name is required'),
    occupation: Yup.string().required('Occupation is required'),
    place: Yup.string().required('Place of Birth is required'),
    nationality: Yup.string().required('Nationality is required'),
    country: Yup.string().required('Country of Birth is required'),
    gender: Yup.string().required('Gender is required'),
    maritalStatus: Yup.string().required('Marital status is required'),
    fatherName: Yup.string().when('pranName', (pranName, schema) => {
      return +pranName === 1
        ? schema
            .required("Father's Name is required")
            .matches(/^[a-zA-Z ]{4,50}$/, 'Enter a valid name')
        : schema;
    }),
    motherName: Yup.string().when('pranName', (pranName, schema) => {
      return +pranName === 2
        ? schema
            .required("Mother's Name is required")
            .matches(/^[a-zA-Z ]{4,50}$/, 'Enter a valid name')
        : schema;
    }),
    pranName: Yup.string().oneOf(
      pranNameOptions.map(o => o.value + ''),
      "Choose mother's or father's name",
    ),
    spouseName: Yup.string().when('maritalStatus', (maritalStatus, schema) => {
      return +maritalStatus === 1
        ? schema
            .required("Spouse's Name is required")
            .matches(/^[a-zA-Z ]{4,50}$/, 'Enter a valid name')
        : schema;
    }),
    occupationDetails: Yup.string().when('occupation', (occupation, schema) => {
      return +occupation === 29
        ? schema.required('Occupation Detail is required')
        : schema;
    }),
  });
  const getStateAndDistrict = async (pincode: any) => {
    if (!pincode) return;
    const response = await getDetailsFromPincode({ pincode }).unwrap();
    if (Number(response?.statusCode) === SUCCESS_RESPONSE_CODE) {
      return response;
    }
  };
  const submitKycDetails = async (values: {
    ackNumber: any;
    name: any;
    dob: string | number | Date;
    gender: any;
  }) => {
    try {
      const pinCode = EKYCdata?.PERM_PIN || kycBankDetails?.PinCode || PinCode;

      const res = (await getStateAndDistrict(pinCode)) || {};
      const ckycIdentifierType = EKYCDetails?.data?.ckyc_remarks === 'OK' ? 372 : null;

      const requestBody = {
        AckNumber: values.ackNumber,
        BankName: kycBankDetails?.BankName || 'NA',
        Address1: kycBankDetails?.Address1 || EKYCdata?.PERM_LINE1 || Address1 || '',
        Address2: kycBankDetails?.Address2 || Addreess2 || EKYCdata?.PERM_LINE2 || '',
        IdentifierNum:
          kycBankDetails?.IdentifierNum || IdentifierNum || EKYCdata?.CKYC_NO || '',
        SubscriberName: values.name,
        District:
          kycBankDetails?.District ||
          District ||
          // EKYCdata?.PERM_DIST ||
          res?.data[0]?.District ||
          '',
        StateName: kycBankDetails?.StateName || StateName || res?.data[0]?.State || '',
        PinCode: kycBankDetails?.PinCode || PinCode || pinCode || '',
        PANNumber: kycBankDetails?.PANNumber || pan || '',
        CountryName:
          kycBankDetails?.CountryName || CountryName || res?.data[0]?.Country || '',
        IdentifierType: aadhaarIdentifierType || ckycIdentifierType || 119,
        DOB:
          typeof values.dob !== 'string'
            ? format(values.dob, 'dd/MM/yyyy')
            : format(new Date(values.dob), 'dd/MM/yyyy'),
        Gender: values?.gender,
      };

      const response = await saveKycDetails(requestBody).unwrap();

      if (Number(response.statusCode) !== 10000) {
        showToast((response.data as { message: string }).message, 'error');
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  const submitBasicDetails = async (requestBody: {
    ApplicantType?: string;
    MobileNo: any;
    Email: any;
    FullName: any;
    AgentRefNumber?: string;
    MobileEmailRegstredFlag?: string;
  }) => {
    try {
      if (ackNumber) return ackNumber;

      const response = await SaveBasicDetails(requestBody).unwrap();
      if (Number(response.statusCode) !== SUCCESS_RESPONSE_CODE)
        showToast((response.data as { message: string }).message, 'error');

      const basicDetails = {
        ackNumber: response.data,
        email: requestBody.Email,
        mobileNumber: requestBody.MobileNo,
        mobileCountryCode: '+91',
        name: requestBody.FullName,
      };
      dispatchContext(setBasicDetails(basicDetails));
      if (response.data) {
        return response.data;
      } else {
        showToast((response.data as { message: string }).message, 'error');
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  const bulkDispatchContext = async (ackNumber: string) => {
    const promiseList = [
      getPersonalDetails({ AckNumber: ackNumber }).unwrap(),
      getNomineeDetails({ AckNumber: ackNumber }).unwrap(),
      getSchemeDetails({ AckNumber: ackNumber }).unwrap(),
      getKycDetails({ AckNumber: ackNumber }).unwrap(),
      getAccountType({ AckNumber: ackNumber }).unwrap(),
      getFatcaDetails({ AckNumber: ackNumber }).unwrap(),
      getBankDetails({ AckNumber: ackNumber }).unwrap(),
    ];

    const resolvedPromises = await Promise.all(promiseList);

    const [
      personalDetais,
      nomineeDetails,
      schemeDetails,
      kycDetails,
      accountType,
      fatcaDetails,
      bankDetails,
    ] = resolvedPromises;

    dispatchContext(setPersonalDetails(personalDetais));
    dispatchContext(setNomineeDetails(nomineeDetails));
    dispatchContext(setSchemeDetails(schemeDetails));
    dispatchContext(setKycDetails(kycDetails));
    dispatchContext(setAccountTypes(accountType));
    dispatchContext(setFatcaDetails(fatcaDetails));
    dispatchContext(setBankDetails(bankDetails));
  };

  const handleSubmit = async (values: any, formikHelpers: any) => {
    try {
      const { mobile, email, name } = values;
      const acknowledgmentNumber = await submitBasicDetails({
        ApplicantType: 'AL',
        MobileNo: mobile,
        Email: email,
        FullName: name,
        AgentRefNumber: 'ABTPM7705J00012138',
        MobileEmailRegstredFlag: 'M',
      });

      const requestBody = {
        AckNumber: acknowledgmentNumber,
        ...getPersonalDetailsRequestBody(values),
      };
      if (acknowledgmentNumber) {
        const response = await SavePersonalDetails(requestBody).unwrap();
        if (Number(response.statusCode) !== 10000) {
          showToast(response?.message || 'Error Saving Personal Details', 'error');
        }
        const isKycSubmitted = Boolean(kycDetails?.data?.IdentifierNum);
        dispatchContext(setStepNumber(2));
        if (!skipKyc && !isKycSubmitted) {
          await submitKycDetails({
            ...values,
            ackNumber: acknowledgmentNumber,
          });
        }
        await bulkDispatchContext(acknowledgmentNumber);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
      const { setSubmitting } = formikHelpers;
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '16px' },
              fontWeight: 700,
              color: 'text.primary',
              mb: 2,
            }}
          >
            State of Residence*
          </Typography>
          <StyledRadioButtonGroup
            options={residentTypeList}
            value={values.status}
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              setFieldValue('status', value);
              if (+value === 1) {
                setFieldValue('country', 'IN');
                setFieldValue('nationality', 'Indian');
              } else {
                setFieldValue('country', '');
                setFieldValue('nationality', '');
              }
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name='pan'
                label='PAN'
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormDateInput
                name='dob'
                label='Date of Birth'
                required={false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name='name'
                label='FullName'
                required={false}
                defaultValue={''}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name='email'
                label='Email'
                required={false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name='mobile'
                label='Mobile Number'
                required={false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormSelectInput
                name='occupation'
                label='occupation'
                defaultValue={''}
                options={occupationOptions}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                label='Place of Birth'
                name='place'
                defaultValue={''}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name='nationality'
                label='Nationality'
                defaultValue={''}
                // disabled={+values.status === 1}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormSelectInput
                name='country'
                label='Country of Birth'
                // disabled={+values.status === 1}
                options={countryList}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormSelectInput
                name='maritalStatus'
                label='Martial Status'
                defaultValue={''}
                options={maritalStatusOptions}
              />
            </Grid>
            {+values.maritalStatus === 1 && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <FormTextInput
                  label="Spouse's Name"
                  name='spouseName'
                  required
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ mb: 2 }}
            >
              <Typography sx={{ mb: 2 }}>Gender</Typography>
              <Stack
                direction='row'
                spacing={1}
                sx={{
                  'justifyContent': { xs: 'space-between', sm: 'flex-start' },
                  '& .MuiButtonBase-root.MuiChip-root': {
                    'padding': '10px 5px',
                    'borderRadius': '44px',
                    '& .MuiChip-label': {
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                  },
                }}
              >
                {Object.keys(gendersList).map(gender => (
                  <Chip
                    key={gender}
                    avatar={
                      <Avatar
                        alt={gender.toLowerCase()}
                        src={(gendersList as Record<string, string>)[gender]}
                      />
                    }
                    label={gender}
                    onClick={async () => setFieldValue('gender', gender)}
                    variant='outlined'
                    sx={{
                      'borderColor':
                        values.gender === gender
                          ? 'text.navyBlue'
                          : 'text.borderColorLight',
                      'border': '1px solid',
                      'backgroundColor':
                        values.gender === gender ? 'text.boxColor' : 'common.white',
                      '& .MuiChip-label': {
                        color: values.gender === gender ? 'primary.main' : 'text.primary',
                      },
                    }}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <FormTextInput
                name='fatherName'
                label='Father Name'
                required={+values.pranName === 1}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormTextInput
                name='motherName'
                label='Mother Name'
                required={+values.pranName === 2}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <StyledRadioButtonGroup
                options={pranNameOptions}
                value={values.pranName}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('pranName', event.target.value);
                }}
              />
            </Grid>
          </Grid>

          <SubmitButton
            sx={{
              '&.MuiButtonBase-root': {
                borderRadius: '5px',
                minWidth: { xs: '100%', sm: '200px' },
                mt: 5,
              },
            }}
            label='Save & Proceed'
          >
            Save & Proceed
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default InvestorDetails;
