import { alpha, InputBase, styled } from '@mui/material';
import React, { ChangeEvent } from 'react';

import { ReactComponent as SearchIcon } from '../../../assets/search-icon.svg';
const SearchBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

const SearchIconContainer = styled('div')({
  position: 'absolute',
  left: '12px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
});
const Search = styled('div')(({ theme }) => ({
  'position': 'relative',
  'borderRadius': theme.shape.borderRadius,
  'backgroundColor': alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  'marginLeft': 8,
  'width': '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  'color': 'inherit',
  '& .MuiInputBase-input': {
    padding: '10.5px 8px',
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    borderRadius: '5px',
    fontWeight: '500',
    fontSize: '16px',
    color: theme.palette.text.labelColor,
    border: `1px solid ${theme.palette.text.borderColorLight}`,
  },
}));
type SearchFilterBoxProps = {
  text: string;
  onChangeSearchHandler: (event: ChangeEvent<HTMLInputElement>) => void;
};
const SearchComponent = ({ text, onChangeSearchHandler }: SearchFilterBoxProps) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        value={text}
        onChange={onChangeSearchHandler}
        fullWidth
        placeholder='Search by Name'
        inputProps={{ 'aria-label': 'search' }}
      />
    </Search>
  );
};

export default SearchComponent;
