import { Box, Divider, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import useSip, {
  getEnumValueFromString,
  getPerpetualSipEndDate,
} from '../../../hooks/useSip';
import { freqType } from '../../../hooks/useSip';
import { calculateDifference } from '../../../utils';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import CustomLoader from '../../common/CustomLoader';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormCheckbox from '../../forms/FormCheckbox';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { FieldSubHeader3, MainHeader } from '../../onboarding/styles/styledComponents';
import { arnRegex } from '../common';
import SubmitCartButtons from '../common/SubmitCartButtons';
import EuinsFields from '../euinsComponent';
import { DEFAULT_MIN_TAT } from '../mfs/constants';
import {
  useAddItemToCartMutation,
  useGetEnachAotmDetailsMutation,
  useGetEUINSBySubArnMutation,
  useGetPaymentModesByFundMutation,
  useGetSchemaDetailsMutation,
} from '../slice';
import { allAPLOptions, initialRegistrationModesForSIP } from './common';
import {
  DropDownData,
  FormValues,
  FrequencyAndSipState,
  FrequencyData,
  PaymentOption,
} from './types';

interface AdditionalPurchaseComponentProps {
  data?: any;
  isEditMode: boolean;
  // handleCancelModifyingCart?: () => void;
  isNewScheme?: boolean;
}
const validationSchema = Yup.object().shape({
  arnCode: Yup.string().matches(arnRegex, 'Invalid ARN Code'),
  subArnCode: Yup.string().matches(arnRegex, 'Invalid Sub ARN Code'),
  modeType: Yup.string().required('Mode of Transaction is required'),
  investmentAmount: Yup.string().required('Investment Amount is required'),
  modeOfRegistration: Yup.string().when(['modeType', 'investmentAmount'], {
    is: (modeType: string, investmentAmount: string) =>
      modeType !== 'Lumpsum' && !!investmentAmount,
    then: Yup.string().required('Mode of Registration is required'),
  }),
  frequency: Yup.string().when(['modeType', 'investmentAmount'], {
    is: (modeType: string, investmentAmount: string) =>
      modeType !== 'Lumpsum' && !!investmentAmount,
    then: Yup.string().required('Frequency is required'),
  }),
  sipDay: Yup.string().when(['modeType', 'investmentAmount'], {
    is: (modeType: string, investmentAmount: string) =>
      modeType !== 'Lumpsum' && !!investmentAmount,
    then: Yup.string().required('SIP Day is required'),
  }),
  installments: Yup.string().when(['modeType', 'investmentAmount'], {
    is: (modeType: string, investmentAmount: string) =>
      modeType !== 'Lumpsum' && !!investmentAmount,
    then: Yup.string().required('No. of Installments is required'),
  }),
  lumpsumAmount: Yup.string().when('modeType', {
    is: (modeType: string) => modeType === 'LumpsumAndSIP',
    then: Yup.string().required('Lumpsum amount is required'),
  }),
});

const AdditionalPurchaseComponent: React.FC<AdditionalPurchaseComponentProps> = ({
  data,
  isEditMode,
  isNewScheme,
}) => {
  const [schemaData, setSchemaData] = useState<any>({});
  const [dropDownData, setDropDownData] = useState<DropDownData>({});
  const { showToast } = useRootContext();
  const navigate = useNavigate();
  const { arn, euin, subArn, subbrokerCode } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const { name, pan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  const [allPaymentOptions, setAllPaymentOptions] = useState<PaymentOption[]>([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const initialValues: FormValues = {
    arnCode: arn,
    subArnCode: subArn || '',
    subBrokerCode: '',
    EUIN: euin,
    modeType: 'Lumpsum',
    investmentAmount: '',
    modeOfRegistration: data?.modeOfRegistration || '',
    frequency: freqType[data?.sipFrequency as keyof typeof freqType],
    sipDay: data?.sipDay || '',
    sipStartDate: data?.sipStartDate || null, // You may set a default date if needed
    sipEndDate: data?.sipEndDate || null, // You may set a default date if needed
    installments: data?.noOfInstallments || '',
    lumpsumAmount: data?.amount || '',
    perpetual: false,
  };

  const [selectedFrequencyAndSip, setSelectedFrequencyAndSip] =
    useState<FrequencyAndSipState>({
      selectedFrequency: null,
      selectedSipDay: null,
      noOfInstallments: 0,
      isPerpetual: false,
      selectedMode: 'Lumpsum',
    });
  // const { data: allEUINs, isLoading } = useGetEUINSQuery([]);
  const [allEUINs, setAllEUINs] = useState([]);
  const [getAllEuinsData] = useGetEUINSBySubArnMutation();
  // For registration modes.
  const [getPaymentModesByFund] = useGetPaymentModesByFundMutation();
  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const [getAotmDetails] = useGetEnachAotmDetailsMutation();

  const [addALPItemToCart] = useAddItemToCartMutation();
  const formikRef = useRef<FormikProps<FormValues>>(null);

  const firstButtonTitle = !isEditMode ? 'Add to Cart' : 'Cancel';
  const secondButtonTitle = !isEditMode ? 'Save & Proceed' : 'Save';
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [startDate, endDate, , , , , errors] = useSip({
    frequencyType: selectedFrequencyAndSip?.selectedFrequency || 'Weekly',
    numInstallments: Number(selectedFrequencyAndSip?.noOfInstallments) || 1,
    sipDay: selectedFrequencyAndSip?.selectedSipDay
      ? String(selectedFrequencyAndSip?.selectedSipDay)
      : '1',
    sipTat:
      schemaData?.frequency?.find(
        (f: any) => f.mode === selectedFrequencyAndSip?.selectedFrequency,
      )?.coolingPeriod || DEFAULT_MIN_TAT,
  });
  const isFrequencySelected =
    selectedFrequencyAndSip?.selectedFrequency &&
    selectedFrequencyAndSip?.noOfInstallments > 0 &&
    selectedFrequencyAndSip?.selectedSipDay;
  const [isPanBasedAotm, setIsPanBasedAotm] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    const {
      EUIN,
      subBrokerCode,
      arnCode,
      subArnCode,
      modeType,
      investmentAmount,
      paymentMode,
      installments,
      modeOfRegistration,
      sipDay,
      frequency,
      lumpsumAmount,
    } = values;
    const {
      fundName,
      category,
      mobile,
      email,
      pan,
      folioNo,
      fund,
      scheme,
      plan,
      option,
      schemeDesc,
    } = data;
    let payload;
    const commonPayload = {
      euin: EUIN,
      euinDeclaration: EUIN !== '' ? 'Y' : 'N',
      fundName: fundName,
      category: category || '',
      subBroker: subBrokerCode || '',
      arnCode: arnCode,
      subArn: subArnCode || '',
      remarks: '0',
    };
    const makeAdditionalPurchasePayload = {
      ...commonPayload,
      mobile: mobile || '',
      email: email || '',
      investorName: name || '',
      pan: pan || '',
      folio: folioNo || '',
      fund: fund || '',
      scheme: scheme || '',
      plan: plan || '',
      option: option || '',
      schemeName: schemeDesc || '',
      transactionType: 'APL',
      isPanBasedAotm,
      isNriInvestor: data?.investorCategory !== 'I',
    };
    switch (modeType) {
      case 'Lumpsum':
        payload = {
          ...makeAdditionalPurchasePayload,
          modeType: modeType,
          amount: investmentAmount,
          purchaseType: isNewScheme ? 'NEW' : 'EXISTING',
          paymentMode: paymentMode,
          createMandate: false,
          pioTransaction: false,
          sipFrequency: getEnumValueFromString(frequency),
        };

        break;

      case 'SIP':
        payload = {
          ...makeAdditionalPurchasePayload,
          modeType,
          amount: 0,
          sipFrequency: getEnumValueFromString(frequency),
          sipStartDate: format(startDate, 'dd/MM/yyyy'),
          sipEndDate: format(endDate, 'dd/MM/yyyy'),
          noOfInstallments: installments,
          modeOfRegistration,
          installmentAmount: investmentAmount,
          sipDay,
        };
        break;

      case 'LumpsumAndSIP':
        payload = {
          ...makeAdditionalPurchasePayload,
          modeType,
          amount: lumpsumAmount,
          sipFrequency: getEnumValueFromString(frequency),
          sipStartDate: format(startDate, 'dd/MM/yyyy'),
          sipEndDate: format(endDate, 'dd/MM/yyyy'),
          noOfInstallments: installments,
          modeOfRegistration,
          installmentAmount: investmentAmount,
          paymentMode,
          sipDay,
        };
        break;

      default:
        break;
    }

    navigate('/transaction-dashboard/confirmation', {
      state: { payload, isEditMode: false },
    });
  };

  const handleGoToCart = () => {
    navigate('../../cart');
  };
  const handleAddAnotherTransaction = () => {
    navigate('../');
  };
  const handleAddToCartClick = async () => {
    try {
      let payload: any = {
        euin: formikRef.current?.values?.EUIN,
        euinDeclaration: formikRef.current?.values?.EUIN !== '' ? 'N' : 'Y',
        subArn: formikRef.current?.values?.subArnCode,
      };
      if (formikRef.current?.values?.modeType === 'Lumpsum') {
        payload = {
          ...payload,
          fundName: data?.fundName,
          pan: data?.pan,
          folio: data?.folioNo,
          fund: data?.fund,
          scheme: data?.scheme,
          plan: data?.plan,
          option: data?.option,
          mobile: data?.mobile,
          email: data?.email,
          amount: formikRef.current?.values?.investmentAmount,
          investorName: data?.name || name,
          transactionType: 'APL',
          schemeName: data?.schemeDesc,
          category: data?.category || '',
          purchaseType: isNewScheme ? 'NEW' : 'EXISTING', // For Lumpsum
        };
      } else if (formikRef.current?.values?.modeType === 'SIP') {
        payload = {
          ...payload,
          fundName: data?.fundName,
          pan: data?.pan,
          folio: data?.folioNo,
          fund: data?.fund,
          scheme: data?.scheme,
          plan: data?.plan,
          option: data?.option,
          mobile: data?.mobile,
          email: data?.email,
          investorName: data?.name || name,
          category: data?.category,
          amount: 0,
          remarks: '0',
          schemeName: data?.schemeDesc,
          sipFrequency: getEnumValueFromString(formikRef?.current?.values?.frequency),
          sipStartDate: format(startDate, 'dd/MM/yyyy'),
          sipEndDate: format(endDate, 'dd/MM/yyyy'),
          noOfInstallments: formikRef?.current?.values?.installments,
          modeOfRegistration: formikRef?.current?.values?.modeOfRegistration,
          installmentAmount: formikRef?.current?.values?.investmentAmount,
          transactionType: 'ISIP',
          paymentMode: '',
          sipDay: formikRef?.current?.values.sipDay,
        };
      } else {
        payload = {
          ...payload,
          fundName: data?.fundName,
          pan: data?.pan,
          folio: data?.folioNo,
          fund: data?.fund,
          scheme: data?.scheme,
          plan: data?.plan,
          option: data?.option,
          mobile: data?.mobile,
          email: data?.email,
          investorName: data?.name || name,
          schemeName: data?.schemeDesc,
          category: data?.category,
          amount: formikRef?.current?.values?.lumpsumAmount,
          remarks: '0',
          sipFrequency: getEnumValueFromString(
            formikRef?.current?.values?.frequency || '',
          ),
          sipStartDate: format(startDate, 'dd/MM/yyyy'),
          sipEndDate: format(endDate, 'dd/MM/yyyy'),
          noOfInstallments: formikRef?.current?.values?.installments,
          modeOfRegistration: formikRef?.current?.values?.modeOfRegistration,
          installmentAmount: formikRef?.current?.values?.investmentAmount,
          transactionType: 'SIN',
          sipDay: formikRef?.current?.values?.sipDay,
        };
      }
      await addALPItemToCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };
  const handleValidation = async (values: Record<string, any>) => {
    let errors: Record<string, any> = {};
    const minInstallments = dropDownData[values?.frequency]?.minInstallments;
    const minTransactionAmount =
      values.modeType === 'Lumpsum'
        ? schemaData?.minTransactionAmount
        : dropDownData[values?.frequency]?.minTransactionAmount;

    if (minInstallments > values.installments) {
      errors.installments = `Min installments allowed ${minInstallments}`;
    }
    if (Number(values.investmentAmount) < minTransactionAmount) {
      errors.investmentAmount = `Min installment amount allowed ${minTransactionAmount}`;
    }
    if (
      values.modeType === 'LumpsumAndSIP' &&
      Number(values.lumpsumAmount) < minTransactionAmount
    ) {
      errors.lumpsumAmount = `Min installment amount allowed ${minTransactionAmount}`;
    }
    return errors;
  };
  useEffect(() => {
    if (errors.showError && isFrequencySelected) {
      showToast(errors.installments || errors.frequencyType, 'error');
    }
  }, [errors]);
  const processFrequencyData = (frequencyData: FrequencyData[]) => {
    const uniqueModesSet = new Set();
    const frequencyDataObject: any = {};

    frequencyData.forEach((entry: FrequencyData) => {
      if (!uniqueModesSet.has(entry.mode)) {
        uniqueModesSet.add(entry.mode);
        const { mode, cycle, ...rest } = entry;
        const cycleArray = cycle
          .split(',')
          .map(item => parseInt(item.trim(), 10))
          .filter(num => !isNaN(num));
        const newCycle = cycleArray.length
          ? cycleArray
          : Array.from({ length: 30 }, (_, index) => index + 1);
        const newObj = {
          ...rest,
          cycle: newCycle,
        };
        frequencyDataObject[mode] = newObj;
      }
    });

    return frequencyDataObject;
  };

  useEffect(() => {
    const fetchSchemaDetails = async () => {
      const payload = {
        fund: data?.fund,
        scheme: data?.scheme,
        plan: data?.plan,
        option: data?.option,
        trType: selectedFrequencyAndSip?.selectedMode === 'Lumpsum' ? 'APL' : 'SIP',
      };

      const result: any = await getSchemaDetails(payload).unwrap();

      if (result.error) {
        throw new Error(result.error.data.message);
      }

      setSchemaData(result);
      if (result?.frequency) {
        const frequencyDataObject = processFrequencyData(result?.frequency);
        setDropDownData(frequencyDataObject);
      }
    };

    const fetchRegistrationModes = async () => {
      const paymentModesResponse = await getPaymentModesByFund({
        fund: data?.fund,
      }).unwrap();

      if (paymentModesResponse.error) {
        throw new Error(paymentModesResponse.error.data.message);
      }

      const registrationOptions = Object.entries(paymentModesResponse)
        .filter(([_, value]) => value === 'Y')
        .map(([key, _]) => ({ label: key.toUpperCase(), value: key }));

      let filteredModes = initialRegistrationModesForSIP(
        registrationOptions,
        isPanBasedAotm,
      );
      setAllPaymentOptions(filteredModes);
    };

    const fetchAllDetails = async () => {
      try {
        setIsLoading(true);
        await fetchSchemaDetails();

        if (selectedFrequencyAndSip?.selectedMode !== 'Lumpsum') {
          await fetchRegistrationModes();
        }
      } catch (error: any) {
        showToast(error.message || 'Unknown error', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    if (data && selectedFrequencyAndSip?.selectedMode) {
      fetchAllDetails();
    }
  }, [data, selectedFrequencyAndSip?.selectedMode]);
  useEffect(() => {
    const fetchEuinData = async () => {
      const payload = { subArn: subArn };
      const allEuinsData: any = await getAllEuinsData(payload).unwrap();
      if (allEuinsData.error) {
        throw new Error(allEuinsData.error);
      }
      setAllEUINs(allEuinsData);
    };

    const getAotmStatus = async () => {
      const response: any = await getAotmDetails({ fund: data?.fund }).unwrap();
      if (response.error) {
        throw new Error(response.error.data.message);
      }
      const { enach_aotm_basedon } = response;
      setIsPanBasedAotm(enach_aotm_basedon.toUpperCase() === 'PAN');
    };

    const fetchData = async () => {
      try {
        await Promise.all([fetchEuinData(), getAotmStatus()]);
      } catch (error: any) {
        const message = error?.data?.message || error.message || 'Unknown error';
        showToast(message, 'error');
      }
    };
    if (data?.fund) {
      fetchData();
    }
  }, [data?.fund]);

  useEffect(() => {
    if (startDate && endDate) {
      formikRef.current?.setFieldValue(
        'sipStartDate',
        format(new Date(startDate), 'eee do MMMM yyyy'),
      );
      formikRef.current?.setFieldValue(
        'sipEndDate',
        format(new Date(endDate), 'eee do MMMM yyyy'),
      );
    }
  }, [startDate, endDate]);

  const clearAllValues = (setFieldValue: (field: string, value: any) => void) => {
    setFieldValue('sipDay', '');
    setFieldValue('installments', '');
    setFieldValue('frequency', '');
    setFieldValue('sipStartDate', null);
    setFieldValue('sipEndDate', null);
  };
  const handlePerpetualChange = (
    checked: boolean,
    setFieldValue: (field: string, value: any) => void,
    values: FormValues,
  ): void => {
    if (checked) {
      const end_date = getPerpetualSipEndDate(values.modeOfRegistration as string);
      const selectedInstallments = calculateDifference(
        startDate,
        end_date,
        selectedFrequencyAndSip.selectedFrequency as string,
      );
      setFieldValue('sipEndDate', format(new Date(end_date), 'eee do MMMM yyyy'));
      setFieldValue('installments', selectedInstallments);
      setSelectedFrequencyAndSip({
        ...selectedFrequencyAndSip,
        noOfInstallments: selectedInstallments || 0,
      });
    } else {
      // clearing the values
      clearAllValues(setFieldValue);
      setFieldValue('modeOfRegistration', '');
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validate={async values => handleValidation(values)}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Typography
              sx={{
                fontSize: {
                  xs: '16px',
                  xl: '18px',
                },
                fontWeight: 500,
                color: { xs: 'text.valueColor', sm: 'primary.main' },
                mb: 2,
              }}
            >
              Distributor Details
            </Typography>
            {/* Distributor details Grid */}
            <Grid
              container
              spacing={2}
              sx={{ mb: 2 }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='arnCode'
                  label='ARN Code'
                  disabled={true}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='subArnCode'
                  label='Sub ARN Code'
                  required={false}
                  disabled={!!subArn}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='subBrokerCode'
                  label='Sub Broker Code'
                  required={false}
                  disabled={!!subbrokerCode}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <EuinsFields
                  name='EUIN'
                  allEUINs={allEUINs}
                  defaultValue={euin}
                />
              </Grid>
            </Grid>
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{
                border: '0.5px dashed',
                borderColor: 'text.borderColorDark',
                my: 2,
              }}
            />

            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                sm={12}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                  }}
                >
                  Mode of Transaction *
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <FormStyledRadioButton
                    options={allAPLOptions}
                    name='modeType'
                    handleChange={(e: any) => {
                      setSelectedFrequencyAndSip({
                        ...selectedFrequencyAndSip,
                        selectedMode: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            {!isLoading ? (
              <Grid
                sx={{ position: 'relative' }}
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Typography
                    sx={{
                      color: 'text.valueColor',
                      mb: 1,
                      fontWeight: 500,
                      fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                    }}
                  >
                    Investment Amount
                  </Typography>
                  <FormTextInput
                    name='investmentAmount'
                    label={
                      values.modeType === 'Lumpsum'
                        ? 'Lumpsum Amount'
                        : 'Investment Amount'
                    }
                  />
                </Grid>

                {values.modeType !== 'Lumpsum' && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ mt: { sm: '30px' } }}
                    >
                      <FormSelectInput
                        name='modeOfRegistration'
                        label='Mode of Registration'
                        defaultValue={''}
                        options={allPaymentOptions}
                        onChange={() => clearAllValues(setFieldValue)}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <FormSelectInput
                        name='frequency'
                        label='Frequency'
                        defaultValue={''}
                        options={Object.keys(dropDownData).map(entry => ({
                          value: entry,
                          label: entry,
                        }))}
                        onChange={(e: SelectChangeEvent<any>) => {
                          setSelectedFrequencyAndSip({
                            ...selectedFrequencyAndSip,
                            selectedFrequency: e.target.value,
                            noOfInstallments: 0,
                          });
                          setFieldValue('installments', '');
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <FormSelectInput
                        name='sipDay'
                        label='SIP Day'
                        defaultValue={''}
                        options={dropDownData[values?.frequency]?.cycle?.map(entry => ({
                          value: entry,
                          label: entry.toString(),
                        }))}
                        onChange={(e: SelectChangeEvent<any>) => {
                          setSelectedFrequencyAndSip({
                            ...selectedFrequencyAndSip,
                            selectedSipDay: e.target.value,
                          });
                        }}
                        disabled={!values.frequency}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      {values?.frequency && (
                        <Typography>
                          Minimum Number of installments:
                          {dropDownData[values?.frequency]?.minInstallments || 0}
                        </Typography>
                      )}
                      <FormTextInput
                        name='installments'
                        label='No. of Installments'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setSelectedFrequencyAndSip({
                            ...selectedFrequencyAndSip,
                            noOfInstallments: Number(event.target.value),
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <FormDateInput
                        name='sipStartDate'
                        placeholder='SIP Start Date'
                        label='SIP Start Date'
                        value={values.sipStartDate}
                        disabled={true}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <FormDateInput
                        name='sipEndDate'
                        placeholder='SIP End Date'
                        label='SIP End Date'
                        disabled={true}
                        value={values.sipEndDate}
                        // onChange={async (value: any) => {}}
                      />
                    </Grid>
                    {values?.frequency && values.sipStartDate ? (
                      <Grid
                        item
                        xs={12}
                      >
                        <FormCheckbox
                          name='perpetual'
                          label='Perpetual SIP'
                          margin='dense'
                          onChange={e =>
                            handlePerpetualChange(e.target.checked, setFieldValue, values)
                          }
                        />
                      </Grid>
                    ) : null}
                    {values.modeType === 'LumpsumAndSIP' && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <FormTextInput
                          name='lumpsumAmount'
                          label='Lumpsum Amount'
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            ) : (
              <Box sx={{ position: 'relative', padding: '30px' }}>
                <CustomLoader display={true} />
              </Box>
            )}
            <SubmitCartButtons
              // TODO: Edit mode to be added
              onAddToCartClick={handleAddToCartClick}
              firstButtonTitle={firstButtonTitle}
              secondButtonTitle={secondButtonTitle}
            />
            {openDialogBox && (
              <CustomDialog
                isVisible={true}
                firstButtonHandler={handleGoToCart}
                secondButtonHandler={handleAddAnotherTransaction}
                firstButtonTitle='Go to cart'
                secondButtonTitle='Add another Transaction'
                handleClose={() => {}}
                showSecondButton={true}
              >
                <Box sx={{ textAlign: 'center', pt: 2 }}>
                  <img
                    src={VerifiedIcon}
                    alt='verified-icon'
                  />
                  <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
                  <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                    Scheme is added to investor cart
                  </FieldSubHeader3>
                </Box>
              </CustomDialog>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default AdditionalPurchaseComponent;
