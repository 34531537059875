import { createSlice } from '@reduxjs/toolkit';

import { NomineeDetailsPayload } from '../../onboarding/types';

export interface NomineesList {
  nominees: NomineeDetailsPayload[];
}
const initialState: NomineesList = {
  nominees: [],
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialState,
  reducers: {
    nomineeList(state, action) {
      state.nominees = action.payload;
    },
    resetOnboardingState: () => initialState,
  },
});

export const { nomineeList, resetOnboardingState } = onboardingSlice.actions;
export default onboardingSlice.reducer;
