import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

interface RequireAuthProps {
  children?: React.ReactNode; // Define children prop as React.ReactNode
}

function RequireAuth({ children }: RequireAuthProps) {
  const { token } = useSelector((state: any) => state.login);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/auth', { replace: true });
    }
  }, [token, navigate]);

  return token ? (
    <>{children}</>
  ) : (
    <Navigate
      to='/auth'
      replace
    />
  );
}

export default RequireAuth;
