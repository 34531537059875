import { Box, Grid, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Bank_Details } from '../../../assets/Distributor-details/bank-details.svg';
import { ReactComponent as Bank_Details_Active } from '../../../assets/Distributor-details/bank-details-active.svg';
import { ReactComponent as Nominee_Details } from '../../../assets/Distributor-details/nominee-details.svg';
import { ReactComponent as Nominee_Details_Active } from '../../../assets/Distributor-details/nominee-details-active.svg';
import { ReactComponent as Personal_Details } from '../../../assets/Distributor-details/personal-details.svg';
import { ReactComponent as Personal_Details_Active } from '../../../assets/Distributor-details/personal-details-active.svg';
import UserIcon from '../../../assets/Distributor-details/user-icon.svg';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../common/BackToHomePage';
import VerticalTabs, { TabPanel } from '../../common/verticalTabs';
import { LoginResponse } from '../../features/login/loginSlice';
import { BankDetails } from '../../onboarding/BankDetails';
import { NomineeDetails } from '../../onboarding/NomineeDetails';
import PersonalDetails from '../DistributorProfile/individual/PersonalDetails';

const tabsForIndividual = [
  {
    label: 'Personal Details',
    icon: <Personal_Details />,
    activeIcon: <Personal_Details_Active />,
  },
  { label: 'Bank Details', icon: <Bank_Details />, activeIcon: <Bank_Details_Active /> },
  {
    label: 'Nominee Details',
    icon: <Nominee_Details />,
    activeIcon: <Nominee_Details_Active />,
  },
];

const tabsForNonIndividual = [
  {
    label: 'Primary Details',
    icon: <Personal_Details />,
    activeIcon: <Personal_Details_Active />,
  },
  { label: 'Bank Details', icon: <Bank_Details />, activeIcon: <Bank_Details_Active /> },
];
const individualComponents = [PersonalDetails, BankDetails, NomineeDetails];
const nonIndividualComponents = [PersonalDetails, BankDetails];

export default function DistributorProfile() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [individual, setIndividual] = useState(true);
  const { isIndividual } = useSelector((state: { login: LoginResponse }) => state.login);
  const tabs = individual ? tabsForIndividual : tabsForNonIndividual;
  const { name } = useSelector((state: { login: LoginResponse }) => state.login);

  useEffect(() => {
    setIndividual(isIndividual);
  }, []);

  const handleTabChange = (newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <>
      <Box sx={{ maxWidth: { xs: '100%', lg: '80%' }, margin: '0 auto' }}>
        <PageNavigator
          title='Distributor Profile'
          backHandler={() => navigate('../')}
        />
        <Grid
          container
          spacing={2}
          alignItems={'stretch'}
        >
          {/* <Grid item sm={12}>
           
          </Grid> */}
          <Grid
            item
            sm={3}
            xs={12}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                minHeight: { xs: 'unset', sm: 'calc(100vh - 175px)' },
                [theme.breakpoints.down('sm')]: {
                  boxShadow: 'unset',
                  border: 'unset',
                },
              })}
            >
              <Box sx={{ textAlign: 'center', mt: { xs: 0, sm: 5 }, mb: 3 }}>
                <Box
                  sx={{
                    bgcolor: 'text.lightgray',
                    width: '120px',
                    height: '120px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0px auto 10px auto',
                  }}
                >
                  <img
                    src={UserIcon}
                    alt='user_icon'
                  />
                </Box>
                <Typography
                  sx={{ color: 'text.inputLabelText', fontSize: '22px', fontWeight: 600 }}
                >
                  {name}
                </Typography>
              </Box>

              <VerticalTabs
                onTabChange={handleTabChange}
                tabs={tabs}
              />
            </Investorcard>
          </Grid>
          <Grid
            item
            sm={9}
            xs={12}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                height: '100%',
                p: { xs: '0', sm: 3 },
                [theme.breakpoints.down('sm')]: { boxShadow: 'unset', border: 'unset' },
              })}
            >
              {tabs.map((tab, index) => {
                const Component = individual
                  ? individualComponents[index]
                  : nonIndividualComponents[index];
                return (
                  <TabPanel
                    key={index}
                    value={selectedTab}
                    index={index}
                    aria-label='phone'
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{
                        mb: 2,
                        display: { xs: 'none', sm: 'block' },
                        color: 'primary.main',
                      }}
                    >
                      {tab.label}
                    </Typography>

                    <Component
                      hideStyles={true}
                      individual={isIndividual ? true : false}
                    />
                  </TabPanel>
                );
              })}
            </Investorcard>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
