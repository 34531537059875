import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints

const BASE_URL = `${import.meta.env.VITE_API_BASE_URL || ''}`;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as any).auth.token;
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    } else {
      const loginToken = (getState() as any).login.token;
      headers.set('Authorization', `Bearer ${loginToken}`);
    }
    return headers;
  },
});

// Custom base query function
const CustomBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = '/auth/login';
  }
  return result;
};

// Define a service using a base URL and expected endpoints
const api = createApi({
  baseQuery: CustomBaseQuery,
  endpoints: builder => ({
    getAllReports: builder.mutation({
      query: payload => ({
        url: `/nps`,
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json', // Set Content-Type header
        },
      }),
    }),
  }),
});

export default api;

export const { useGetAllReportsMutation } = api;
