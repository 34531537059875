import { ReactComponent as AxisMf } from '../mfcentral-logos/fav-logos/axis_MF Central.svg';
import { ReactComponent as BajajMf } from '../mfcentral-logos/fav-logos/bajaj_MF.svg';
import { ReactComponent as BarodaMf } from '../mfcentral-logos/fav-logos/baroda_MF.svg';
import { ReactComponent as BirlaMf } from '../mfcentral-logos/fav-logos/birla_MF.svg';
import { ReactComponent as BnpMf } from '../mfcentral-logos/fav-logos/bnp_MF.svg';
import { ReactComponent as BoiMf } from '../mfcentral-logos/fav-logos/boi_MF.svg';
import { ReactComponent as CanaraMF } from '../mfcentral-logos/fav-logos/canara_MF.svg';
import { ReactComponent as defautSvg } from '../mfcentral-logos/fav-logos/default.svg';
import { ReactComponent as EdelweissMf } from '../mfcentral-logos/fav-logos/edelweiss_MF.svg';
import { ReactComponent as FranklinMf } from '../mfcentral-logos/fav-logos/franklin_MF.svg';
import { ReactComponent as GrowwMf } from '../mfcentral-logos/fav-logos/groww_new.svg';
import { ReactComponent as HeliosMf } from '../mfcentral-logos/fav-logos/Helios.svg';
import { ReactComponent as IdbiMf } from '../mfcentral-logos/fav-logos/idbi_MF.svg';
import { ReactComponent as IndiaBullsMf } from '../mfcentral-logos/fav-logos/indiabulls_MF.svg';
import { ReactComponent as InvescoMf } from '../mfcentral-logos/fav-logos/invesco_MF.svg';
import { ReactComponent as ItiMf } from '../mfcentral-logos/fav-logos/iti_MF.svg';
import { ReactComponent as JmMF } from '../mfcentral-logos/fav-logos/jm_MF.svg';
import { ReactComponent as KotakMf } from '../mfcentral-logos/fav-logos/kotak_MF.svg';
import { ReactComponent as LicMF } from '../mfcentral-logos/fav-logos/lic_MF.svg';
import { ReactComponent as MiraiMf } from '../mfcentral-logos/fav-logos/mirae_MF.svg';
import { ReactComponent as MotilalMf } from '../mfcentral-logos/fav-logos/motilal_MF.svg';
import { ReactComponent as NaviMf } from '../mfcentral-logos/fav-logos/navi_mf_new.svg';
import { ReactComponent as NipponMf } from '../mfcentral-logos/fav-logos/nippon_MF Central.svg';
import { ReactComponent as NjMf } from '../mfcentral-logos/fav-logos/NJ_MF.svg';
import { ReactComponent as OldBridgeMf } from '../mfcentral-logos/fav-logos/old_bridge_mf.svg';
import { ReactComponent as PgimMf } from '../mfcentral-logos/fav-logos/PGIM_India_MF.svg';
import { ReactComponent as QuantMf } from '../mfcentral-logos/fav-logos/quant_MF.svg';
import { ReactComponent as QuantumMf } from '../mfcentral-logos/fav-logos/quantum_MF.svg';
import { ReactComponent as SamcoMf } from '../mfcentral-logos/fav-logos/samco_MF.svg';
import { ReactComponent as SbiMf } from '../mfcentral-logos/fav-logos/sbi_MF.svg';
import { ReactComponent as SundaramIcon } from '../mfcentral-logos/fav-logos/sundaram_MF.svg';
import { ReactComponent as TataMf } from '../mfcentral-logos/fav-logos/tata_MF.svg';
import { ReactComponent as TaurasMF } from '../mfcentral-logos/fav-logos/tauras_MF.svg';
import { ReactComponent as TrustMf } from '../mfcentral-logos/fav-logos/trust_MF.svg';
import { ReactComponent as UtiMf } from '../mfcentral-logos/fav-logos/uti_MF.svg';

export const amcWithLogos = [
  {
    amc_code: 'Y',
    amc_name: 'WhiteOak Capital Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'K',
    amc_name: 'Kotak Mahindra Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'B',
    amc_name: 'Aditya Birla Sun Life Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'D',
    amc_name: 'DSP Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'H',
    amc_name: 'HDFC Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'SH',
    amc_name: 'Shriram Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'P',
    amc_name: 'ICICI Prudential Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'L',
    amc_name: 'SBI Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
    icon: SbiMf,
  },
  {
    amc_code: 111,
    amc_name: 'Tata Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
    icon: TataMf,
  },
  {
    amc_code: 'O',
    amc_name: 'HSBC Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'G',
    amc_name: 'Bandhan Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'F',
    amc_name: 'L&T Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'PP',
    amc_name: 'PPFAS Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'UK',
    amc_name: 'Union Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'IF',
    amc_name: '360 ONE Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 'MM',
    amc_name: 'Mahindra Manulife Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-22 0:00:00',
  },
  {
    amc_code: 101,
    amc_name: 'Canara Robeco Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: CanaraMF,
  },
  {
    amc_code: 102,
    amc_name: 'LIC Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: LicMF,
  },
  {
    amc_code: 104,
    amc_name: 'Taurus Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: TaurasMF,
  },
  {
    amc_code: 105,
    amc_name: 'JM Financial Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: JmMF,
  },
  {
    amc_code: 107,
    amc_name: 'Baroda Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: BarodaMf,
  },
  {
    amc_code: 108,
    amc_name: 'UTI Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: UtiMf,
  },
  {
    amc_code: 113,
    amc_name: 'Sahara Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: SundaramIcon,
  },
  {
    amc_code: 116,
    amc_name: 'Bank of India Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: BoiMf,
  },
  {
    amc_code: 117,
    amc_name: 'Mirae Asset Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: MiraiMf,
  },
  {
    amc_code: 118,
    amc_name: 'Edelweiss Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: EdelweissMf,
  },
  {
    amc_code: 120,
    amc_name: 'Invesco Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: InvescoMf,
  },
  {
    amc_code: 123,
    amc_name: 'Quantum Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: QuantumMf,
  },
  {
    amc_code: 125,
    amc_name: 'Groww Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: GrowwMf,
  },
  {
    amc_code: 127,
    amc_name: 'Motilal Oswal Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: MotilalMf,
  },
  {
    amc_code: 128,
    amc_name: 'Axis Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: AxisMf,
  },
  {
    amc_code: 129,
    amc_name: 'PGIM India Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: PgimMf,
  },
  {
    amc_code: 'PLF',
    amc_name: 'Navi Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: NaviMf,
  },
  {
    amc_code: 135,
    amc_name: 'IDBI Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: IdbiMf,
  },
  {
    amc_code: 152,
    amc_name: 'ITI Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: ItiMf,
  },
  {
    amc_code: 166,
    amc_name: 'Quant Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: QuantMf,
  },
  {
    amc_code: 176,
    amc_name: 'Sundaram Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: SundaramIcon,
  },
  {
    amc_code: 178,
    amc_name: 'Baroda BNP Paribas Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: BnpMf,
  },
  {
    amc_code: 185,
    amc_name: 'TRUST Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: TrustMf,
  },
  {
    amc_code: 142,
    amc_name: 'Nippon India Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-06-24 0:00:00',
    icon: NipponMf,
  },
  {
    amc_code: 187,
    amc_name: 'NJ Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-12-23 0:00:00',
    icon: NjMf,
  },
  {
    amc_code: 188,
    amc_name: 'Samco Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2021-12-23 0:00:00',
    icon: SamcoMf,
  },
  {
    amc_code: 189,
    amc_name: 'Bajaj Finserv Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2023-07-27 0:00:00',
    icon: BajajMf,
  },
  {
    amc_code: 'HLS',
    amc_name: 'Helios Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2023-09-15 0:00:00',
    icon: HeliosMf,
  },
  {
    amc_code: 'Z',
    amc_name: 'Zerodha Mutual Fund',
    rta_code: 'CAMS',
    last_updated_date_time: '2023-09-15 0:00:00',
    icon: SundaramIcon,
  },
  {
    amc_code: 139,
    amc_name: 'Old Bridge Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: OldBridgeMf,
  },
  {
    amc_code: 114,
    amc_name: 'Franklin Templeton Mutual Fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: FranklinMf,
  },
  {
    amc_code: 119,
    amc_name: 'Daiwa mutual fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: FranklinMf,
  },
  {
    amc_code: 130,
    amc_name: 'Navi mutual fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: NaviMf,
  },
  {
    amc_code: 138,
    amc_name: 'Malabar mutual fund',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: NaviMf,
  },
  {
    amc_code: 140,
    amc_name: 'SREI INFRASTRUCTURE DEBT FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: NaviMf,
  },
  {
    amc_code: 141,
    amc_name: 'THIRD EYE CINEMA - 7 YR CLOSE-END CAT II',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: NaviMf, //not available
  },
  {
    amc_code: 143,
    amc_name: 'Sbi Aif',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: SbiMf,
  },
  {
    amc_code: 144,
    amc_name: 'Birla Capital',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: BirlaMf,
  },
  {
    amc_code: 146,
    amc_name: 'Indiabulls AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: IndiaBullsMf,
  },
  {
    amc_code: 147,
    amc_name: 'ARTHVEDA AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: IndiaBullsMf, //not available
  },
  {
    amc_code: 148,
    amc_name: 'Essel AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: IndiaBullsMf, //not available
  },
  {
    amc_code: 149,
    amc_name: 'ZODIUS TECHNOLOGY OPPORTUNITIES FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: IndiaBullsMf, //not available
  },
  {
    amc_code: 150,
    amc_name: 'Motilal AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: MotilalMf,
  },
  {
    amc_code: 151,
    amc_name: 'Kotak AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: KotakMf,
  },
  {
    amc_code: 153,
    amc_name: 'Baring AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: defautSvg, //not available
  },
  {
    amc_code: 155,
    amc_name: 'ASK WEALTH AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: defautSvg, //not available
  },
  {
    amc_code: 156,
    amc_name: 'UTI AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: UtiMf,
  },
  {
    amc_code: 157,
    amc_name: 'NIFF AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: defautSvg, //not available
  },
  {
    amc_code: 158,
    amc_name: 'VC Bank',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: defautSvg, //not available
  },
  {
    amc_code: 160,
    amc_name: 'Landmark AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: TataMf,
  },
  {
    amc_code: 161,
    amc_name: 'Axis AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: AxisMf,
  },
  {
    amc_code: 162,
    amc_name: 'EDELWEISS AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: EdelweissMf,
  },
  {
    amc_code: 163,
    amc_name: 'FORTUNE AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: ItiMf,
  },
  {
    amc_code: 164,
    amc_name: 'NAPEAN AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: defautSvg, //not available
  },
  {
    amc_code: 165,
    amc_name: 'LIC AIF',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: LicMF,
  },
  {
    amc_code: 181,
    amc_name: 'Mind Capital MUTUAL FUND',
    rta_code: 'KFIN',
    last_updated_date_time: '2024-01-05 0:00:00',
    icon: defautSvg, //not available
  },
];
