import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FieldArray, useField } from 'formik';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { ReactComponent as AddIcon } from '../../../../assets/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/edit-icon.svg';
import {
  DetailsHeader,
  Detailslabel,
  DetailsValue,
  StepperAddButton,
  StepperNextButton,
} from '../../../auth/login/styles/styledComponents';
import FormCheckbox from '../../../forms/FormCheckbox';
import { FormSelectInput } from '../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import FormTextInput from '../../../forms/FormTextInput';
import { FatcaInitialValues } from '.';

const taxId = {
  country: '',
  taxId: '',
  idType: '',
  tinReason: '',
  tinRemarks: '',
  tinNotAvailable: false,
  tinIdType: 'tinNumber',
  reasonIndex: '0',
};

const tinNotAvailableReasons = [
  'The country where the account holder is liable to pay tax does not issue TIN to its residents',
  'TIN not required (Applicable only if the authorities of the respective country of tax residency do not require TIN to be collected)',
  'Others (Please specify reason)',
];

interface TaxDetailsProps {
  countryOptions: {
    label: string;
    value: string;
  }[];
  initialValues: typeof FatcaInitialValues;
  setValues: (values: typeof FatcaInitialValues) => void;
}

export interface TaxDetailsRef {
  checkAllSaved: () => boolean;
}

const TaxDetails = forwardRef<TaxDetailsRef, TaxDetailsProps>(
  ({ countryOptions, initialValues: values, setValues }, ref) => {
    const [savedTaxIds, setSavedTaxIds] = useState([false]);

    function checkAllSaved() {
      return !savedTaxIds.some(val => val === false);
    }

    useImperativeHandle(ref, () => ({
      checkAllSaved,
    }));

    const [{ value }, { error }, { setValue }] = useField<Array<any>>('taxResidency');

    const isValid = !error;

    return (
      <>
        <FieldArray name='taxResidency'>
          {({ remove, push }) => (
            <Box sx={{ borderTop: '0.5px dashed #cdcdcd', width: '100%', my: 2 }}>
              {value.map((_, index) => (
                <React.Fragment key={index}>
                  {savedTaxIds[index] ? (
                    <>
                      <Box
                        sx={{
                          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.20)',
                          borderRadius: '7px',
                          padding: '15px 15px 20px 15px',
                          mt: 3,
                        }}
                      >
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <DetailsHeader sx={{ fontWeight: 700 }}>
                            Tax Identification ID - {index + 1}
                          </DetailsHeader>
                          <Box>
                            <IconButton
                              onClick={() =>
                                setSavedTaxIds((prev: boolean[]) =>
                                  prev.map((taxIdSavedOrNot, i) =>
                                    i === index ? false : taxIdSavedOrNot,
                                  ),
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            {index !== 0 && (
                              <IconButton
                                onClick={() => {
                                  setSavedTaxIds(prev =>
                                    prev.filter((_, i) => i !== index),
                                  );
                                  remove(index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Stack>
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Detailslabel sx={{ mb: 2 }}>
                              Country Of Tax Residency:
                            </Detailslabel>
                            <DetailsValue>{value[index].country}</DetailsValue>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Detailslabel sx={{ mb: 2 }}>Tax ID:</Detailslabel>
                            <DetailsValue>{value[index].taxId}</DetailsValue>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Detailslabel sx={{ mb: 2 }}>Type:</Detailslabel>
                            <DetailsValue>{value[index].idType}</DetailsValue>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          backgroundColor: 'text.sliderColor',
                          borderRadius: '7px',
                          padding: '10px 15px',
                          mt: 3,
                        }}
                      >
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Typography
                            variant='subtitle1'
                            sx={{ fontWeight: 700 }}
                          >
                            Tax identification ID - {index + 1}
                          </Typography>
                          {index !== 0 && (
                            <IconButton
                              onClick={() => {
                                setSavedTaxIds(prev =>
                                  prev.filter((_, i) => i !== index),
                                );
                                remove(index);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Box>

                      <Grid
                        container
                        spacing={2}
                        my={2}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                        >
                          <FormSelectInput
                            name={`taxResidency.${index}.country`}
                            defaultValue={''}
                            label='Country of tax residency'
                            required
                            options={countryOptions}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                        >
                          <FormTextInput
                            disabled={(value[index] as typeof taxId).tinNotAvailable}
                            name={`taxResidency.${index}.taxId`}
                            label='Tax Identification Number/Equivalent'
                          />
                        </Grid>
                      </Grid>

                      <FormCheckbox
                        name={`taxResidency.${index}.tinNotAvailable`}
                        label='Tax Identification Number not available'
                        margin='dense'
                      />

                      {!(value[index] as typeof taxId).tinNotAvailable ? (
                        <>
                          <Typography
                            variant='subtitle1'
                            sx={{ my: 2, color: 'primary.main' }}
                          >
                            Identification Type
                          </Typography>
                          <FormStyledRadioButton
                            name={`taxResidency.${index}.tinIdType`}
                            options={[
                              { label: 'Tax Identification Number', value: 'tinNumber' },
                              { label: 'Others(please specify)', value: 'others' },
                            ]}
                          />
                          {(value[index] as typeof taxId).tinIdType === 'others' && (
                            <Grid
                              container
                              spacing={2}
                              my={'15px'}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={6}
                              >
                                <FormTextInput
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      padding: '8px 16px',
                                    },
                                  }}
                                  placeholder='Type here'
                                  required={false}
                                  name={`taxResidency.${index}.tinRemarks`}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            variant='subtitle1'
                            sx={{ my: 3 }}
                          >
                            Please select the reason:
                          </Typography>
                          <FormStyledRadioButton
                            options={tinNotAvailableReasons.map((reason, index) => ({
                              label: reason,
                              value: `${index}`,
                            }))}
                            name={`taxResidency.${index}.reasonIndex`}
                            handleChange={(e: any) => {
                              setValue(
                                value.map((datum, i) => ({
                                  ...datum,
                                  ...(i === index
                                    ? { tinReason: '', reasonIndex: e.target.value }
                                    : {}),
                                })),
                              );
                            }}
                          />
                          {(value[index] as typeof taxId).reasonIndex === '2' && (
                            <Grid
                              container
                              spacing={2}
                              my={'20px'}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={10}
                              >
                                <FormTextInput
                                  multiline
                                  rows={4}
                                  placeholder='Specify here'
                                  required={false}
                                  name={`taxResidency.${index}.tinReason`}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Stack
                    direction='row'
                    columnGap='20px'
                    my='30px'
                  >
                    {index === value.length - 1 && index < 2 && (
                      <StepperAddButton
                        startIcon={<AddIcon />}
                        onClick={() => {
                          setSavedTaxIds(prev => [...prev, false]);
                          push(taxId);
                        }}
                        sx={{
                          flex: { xs: '1', sm: 'unset' },
                          minWidth: { xs: 'unset', sm: '220px' },
                        }}
                      >
                        Add another id
                      </StepperAddButton>
                    )}
                    {!savedTaxIds[index] && (
                      <StepperNextButton
                        sx={{
                          flex: { xs: '1', sm: 'unset' },
                          minWidth: { xs: 'unset', sm: '220px' },
                        }}
                        // disabled={!isValid}
                        onClick={() => {
                          setSavedTaxIds((prev: boolean[]) =>
                            prev.map((taxIdSavedOrNot, i) =>
                              i === index ? true : taxIdSavedOrNot,
                            ),
                          );
                          setValues({
                            ...values,
                            taxResidency: value.map((tR: any, i: number) => {
                              if (i === index)
                                return {
                                  ...tR,
                                  idType: `TAXID${index + 1}`,
                                  ...(!tR.tinNotAvailable
                                    ? {
                                        ...(tR.tinIdType !== 'others'
                                          ? { tinRemarks: '' }
                                          : {}),
                                      }
                                    : {
                                        tinRemarks: '',
                                        ...(tR.reasonIndex !== '2'
                                          ? {
                                              tinReason:
                                                tinNotAvailableReasons[
                                                  parseInt(tR.reasonIndex ?? '0')
                                                ],
                                            }
                                          : {}),
                                      }),
                                };
                              return tR;
                            }),
                          });
                        }}
                      >
                        Save
                      </StepperNextButton>
                    )}
                  </Stack>
                </React.Fragment>
              ))}
            </Box>
          )}
        </FieldArray>
      </>
    );
  },
);

export default TaxDetails;
