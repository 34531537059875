import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Tab, Theme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as AddIcon } from '../../assets/add-icon-rounded.svg';
import { ReactComponent as Earnings } from '../../assets/OnboardingDashboard/earnings.svg';
import DashboardBG from '../../assets/OnboardingDashboard/onboarding-dashboard.png';
import { useGetAssetsQuery } from '../auth/signup/slices';
import { useRootContext } from '../data/root.context';
import { LoginResponse, updateAssets } from '../features/login/loginSlice';
import { StepperNextButton } from '../onboarding/styles/styledComponents';
import { MF } from './MF';
import { NPS } from './NPS';
import { useLazyGetSnapshotsQuery } from './slice';
export interface SnapshotData {
  [key: string]: number | string | null | undefined;
}

export function DashBoard() {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState('1');
  const { name } = useSelector((state: { login: LoginResponse }) => state.login);
  const [snapshotsData, setSnapshotData] = useState<SnapshotData | null>(null);

  const [callGetSnapshots, getSnapshotsResponse] = useLazyGetSnapshotsQuery();

  const { data, error } = useGetAssetsQuery('');
  const { showToast } = useRootContext();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (data) {
      dispatch(updateAssets(data));
    } else if (error) {
      const errorMessage =
        (error as { message: string }).message || 'Unknown error occurred.';
      showToast(errorMessage, 'error');
    }
  }, [data, dispatch, error]);

  useEffect(() => {
    if (
      getSnapshotsResponse &&
      getSnapshotsResponse.isSuccess &&
      !getSnapshotsResponse.isFetching
    ) {
      setSnapshotData(getSnapshotsResponse.data);
    }
  }, [getSnapshotsResponse]);

  useEffect(() => {
    callGetSnapshots('');
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Typography
        variant='h4'
        sx={{ mb: 2 }}
      >
        Dashboard
      </Typography>
      <Box
        sx={(theme: Theme) => ({
          background: `url(${DashboardBG}), ${theme.palette.background.primaryLinearGradient}`,
          borderRadius: '15px',
          backgroundRepeat: 'no-repeat',
          padding: '35px 35px',
          backgroundSize: { xs: 'cover', md: 'cover', lg: 'cover', xl: 'cover' },
          mb: 4,
        })}
      >
        <Typography
          sx={{
            color: 'common.white',
            fontSize: { xs: '20px', lg: '24px', xl: '26px' },
            fontWeight: 700,
            mb: 1.5,
          }}
        >
          Hi, {name} {'\u{1F44B}'}
        </Typography>
        <Typography
          variant='body2'
          sx={{
            color: 'common.white',
            fontWeight: 300,
            maxWidth: '600px',
            mb: { xs: 2, md: 4 },
            lineHeight: '32px',
          }}
        >
          Discover your financial narrative with &apos;Earnings&apos;, where numbers
          become the strokes of accomplishment.
        </Typography>
        <Button
          sx={{
            'borderRadius': '49px',
            // maxWidth: 'fit-content',
            'padding': '12px 20px',
            'minWidth': '175px',
            'justifyContent': 'flex-start',
            'fontSize': { xs: '14px', md: '14px' },
            'fontWeight': 600,
            'color': 'primary.main',
            '&,&:hover': {
              bgcolor: 'common.white',
            },
          }}
          endIcon={<Earnings />}
          onClick={() => navigate('./view-my-earnings')}
        >
          View My Earnings
        </Button>
      </Box>
      <TabContext value={tabValue}>
        <Box
          sx={{
            'borderBottom': '1px solid',
            'borderColor': 'text.borderColorLight',
            '& .MuiTabs-root': {
              '& .MuiTabs-flexContainer': {
                'pl': '0px',
                'columnGap': '25px',
                '& .MuiButtonBase-root.MuiTab-root': {
                  // 'maxWidth': '60px',
                  // 'minWidth': '60px',
                  'color': 'text.labelColor',
                  'fontSize': '16px',
                  'fontWeight': 500,
                  '&.Mui-selected': {
                    color: 'primary.main',
                  },
                },
              },
              '& .MuiTabs-indicator': {},
            },
            '& .MuiTabPanel-root': {
              padding: '50px 50px',
            },
          }}
        >
          <TabList
            onChange={handleTabChange}
            aria-label='lab API tabs example'
          >
            <Tab
              label='Mutual Funds'
              value='1'
            />
            <Tab
              label='NPS'
              value='2'
            />
          </TabList>
        </Box>
        <TabPanel
          sx={{ p: 0, mb: 5 }}
          value='1'
        >
          <MF snapshotsData={snapshotsData} />
        </TabPanel>
        <TabPanel
          sx={{ p: 0 }}
          value='2'
        >
          <NPS />
        </TabPanel>
      </TabContext>
      <StepperNextButton
        sx={{
          borderRadius: '50px',
          position: 'fixed',
          bottom: '30px',
          right: '30px',
          zIndex: '10',
        }}
        startIcon={<AddIcon />}
        onClick={() => navigate('../nfo')}
      >
        Create NFO
      </StepperNextButton>
    </>
  );
}
