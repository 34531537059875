import {
  addDays,
  addMonths,
  differenceInCalendarMonths,
  differenceInWeeks,
  format,
  parse,
} from 'date-fns';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
type sipHookProps = {
  stpFlag?: boolean;
  frequencyType: string;
  numInstallments: number;
  sipDay: string;
  sipTat: number;
};
interface ErrorState {
  showError: boolean;
  frequencyType: string;
  installments: string;
}

export enum freqType {
  W = 'Weekly',
  M = 'Monthly',
  D = 'Daily',
  DZ = 'DailyZip',
  Q = 'Quarterly',
  H = 'Halfyearly',
  A = 'Annual',
  F = 'Fortnightly',
}
export function getEnumValueFromString(str: string): string {
  for (const key in freqType) {
    if (freqType[key as keyof typeof freqType] === str) {
      return key as freqType;
    }
  }
  return '';
}
export function monthDiff(dateFrom: Date, dateTo: Date) {
  const parsedDateFrom = parse(format(dateFrom, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());
  const parsedDateTo = parse(format(dateTo, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());
  return differenceInCalendarMonths(parsedDateTo, parsedDateFrom);
}

export function weekDiff(dateFrom: Date, dateTo: Date) {
  const parsedDateFrom = parse(format(dateFrom, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());
  const parsedDateTo = parse(format(dateTo, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());
  return differenceInWeeks(parsedDateTo, parsedDateFrom);
}
export const getPerpetualSipEndDate = (mode?: string) => {
  if (mode === 'ISIP') {
    const endDate = new Date(2099, 11, 31);
    return endDate;
  }

  const date = new Date();

  date.setDate(date.getDate() - 1);
  date.setFullYear(date.getFullYear() + 40);
  return date;
};
enum Day {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

function getDaysToAdd(currentDay: Day): number {
  if (currentDay === Day.Sunday) return 1;
  if (currentDay === Day.Saturday) return 2;
  return 0;
}

export function isWeekendDay(currentDay: Day) {
  return currentDay === Day.Saturday || currentDay === Day.Sunday;
}

const useSip = ({
  frequencyType,
  numInstallments,
  sipDay,
  sipTat,
  stpFlag = false,
}: sipHookProps): [
  Date,
  Date,
  Dispatch<SetStateAction<Date>>,
  Dispatch<SetStateAction<Date>>,
  () => void,
  number,
  any,
] => {
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [errors, setErrors] = React.useState<ErrorState>({
    showError: false,
    frequencyType: '',
    installments: '',
  });

  const resetEndDate = () => {
    if (!stpFlag && [freqType.D].includes(frequencyType as freqType)) {
      const newEndDate = addDays(
        parse(
          format(startDate, 'yyyy-MM-dd'),
          // startDate.toISOString().slice(0, 10),
          'yyyy-MM-dd',
          new Date(),
        ),
        (numInstallments - 1) * dayMultiplier,
      );
      const endDateDayofWeek = newEndDate.getDay();
      setEndDate(
        addDays(
          parse(format(startDate, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date()),
          getDaysToAdd(endDateDayofWeek),
        ),
      );
      return;
    }

    if (
      frequencyType === freqType.W ||
      frequencyType === freqType.D ||
      frequencyType === freqType.DZ ||
      frequencyType === freqType.F
    ) {
      setEndDate(
        addDays(
          parse(format(startDate, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date()),
          (numInstallments - 1) * dayMultiplier,
        ),
      );
      return;
    }

    setEndDate(
      addMonths(
        parse(format(startDate, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date()),
        (numInstallments - 1) * monthMultiplier,
      ),
    );
  };

  let installment_diff = 0,
    monthMultiplier = 1,
    dayMultiplier = 1,
    frequencyMatched = true;
  switch (frequencyType.replace(/[^a-zA-Z]/g, '')) {
    case freqType.M:
      installment_diff = monthDiff(startDate, endDate);
      break;
    case freqType.H:
      installment_diff = monthDiff(startDate, endDate) / 6.0;
      monthMultiplier = 6;
      break;
    case freqType.Q:
      installment_diff = monthDiff(startDate, endDate) / 3.0;
      monthMultiplier = 3;
      break;
    case freqType.W:
      installment_diff = weekDiff(startDate, endDate);
      dayMultiplier = 7;
      break;
    case freqType.F:
      installment_diff = weekDiff(startDate, endDate) / 2.0;
      dayMultiplier = 14;
      break;
    case freqType.A:
      installment_diff = monthDiff(startDate, endDate) / 12.0;
      monthMultiplier = 12;
      break;
    case freqType.D:
    case freqType.DZ:
      installment_diff = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
      break;
    default:
      frequencyMatched = false;
  }
  useEffect(() => {
    if (!!sipDay && !!sipTat) {
      let todayDate = new Date();
      // date after which the sip must happen
      let sipTatDate = new Date(todayDate.setDate(todayDate.getDate() + sipTat));
      // currently selected date
      let selectedSipDate = new Date(todayDate.setDate(parseInt(sipDay)));
      let newStartDate;
      if (selectedSipDate >= sipTatDate) {
        // if sip date is after the tat date
        newStartDate = selectedSipDate;
      } else {
        // if sip date is before the tat date
        selectedSipDate.setMonth(sipTatDate.getMonth() + 1);
        newStartDate = selectedSipDate;
      }
      if (!stpFlag && [freqType.D, freqType.DZ].includes(frequencyType as freqType)) {
        const startDateDayOfWeek = newStartDate.getDay();
        setStartDate(
          addDays(
            parse(format(startDate, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date()),
            getDaysToAdd(startDateDayOfWeek),
          ),
        );
        return;
      }
      setStartDate(newStartDate);
    }
  }, [sipDay, sipTat, frequencyType]); // For setting the startDate.

  useEffect(() => {
    resetEndDate();
  }, [startDate, frequencyType, sipDay, numInstallments]);

  useEffect(() => {
    if (parseInt(installment_diff.toString()) < numInstallments - 1) {
      setErrors({
        ...errors,
        showError: true,
        installments: `The number of installments you've planned does not match the expected duration of your investment.`,
      });
    } else {
      setErrors({
        ...errors,
        showError: false,
        installments: '',
        frequencyType: '',
      });
    }
  }, [endDate]);
  return [
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetEndDate,
    Math.ceil(installment_diff),
    {
      ...errors,
      ...(!frequencyMatched
        ? { showError: true, frequencyType: `${frequencyType} is not supported` }
        : {}),
    },
  ];
};
export default useSip;
