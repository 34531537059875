/* eslint-disable no-unused-vars */
// import styled from '@emotion/styled';
import { Button, InputBase, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const StyledTabs = styled(Tabs)(({ theme }) => {
  return {
    '&.MuiTabs-root': {
      'borderBottom': 'none',
      '& .MuiTabs-scroller': {
        '& .MuiTabs-flexContainer': {
          padding: '4px',
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'unset',
        justifyContent: 'space-evenly',
      },
    },
    'width': '400px',
    'backgroundColor': '#ECECEC',
    'borderRadius': '15px',
    'height': '46px',
    [theme.breakpoints.down('lg')]: {
      width: '550px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
      width: '450px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      margin: '0 auto',
    },
  };
});

export const StyledTab = styled(Tab<typeof Link>)(({ theme }) => ({
  'width': '50%',
  'height': '38px',
  'fontSize': '16px',
  'fontWeight': 500,
  'minHeight': '38px',

  '&.Mui-selected': {
    color: 'white',
    background: 'linear-gradient(90deg, #215EAB 0%, #0FD1D6 100%)',
    borderRadius: '11px',
    width: '50%',
    height: '38px',
    minHeight: '38px',
  },
}));
export const OtpField = styled(InputBase)(({ theme }) => ({
  'width': '60px',
  'height': '58px',
  '&.Mui-focused': {
    '&.MuiInputBase-root': {
      '& .MuiInputBase-input': {
        border: '2px solid #337FC9',
        background: 'rgba(173, 204, 233, 0.10)',
      },
    },
  },
  '&.Mui-disabled': {
    '&.MuiInputBase-root': {
      '& .MuiInputBase-input': {
        border: '2px solid #337fc9',
      },
    },
  },
  '&.MuiInputBase-root': {
    '& .MuiInputBase-input': {
      'textAlign': 'center',
      'padding': '10px 16px',
      'color': '#337FC9',
      'fontSize': '24px',
      'fontWeight': '700',
      'borderRadius': '5px',
      'border': '0',
      'background': 'rgba(173, 204, 233, 0.35)',
      '&.Mui-disabled': {
        WebkitTextFillColor: 'rgba(51, 127, 201, 0.50)',
        border: '2px solid rgba(51, 127, 201, 0.35);',
      },
    },
  },
}));
export const StepperNextButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  borderRadius: '5px',
  backgroundImage: 'linear-gradient(90deg, #215EAB 0%, #0FD1D6 100%)',
  minWidth: '160px',
  padding: '10px 20px',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}));
export const StepperAddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.darkBlue,
  backgroundImage: 'linear-gradient(90deg, #215EAB 0%, #0FD1D6 100%)',
  backgroundClip: 'text',
  webkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  borderRadius: '5px',
  backgroundColor: theme.palette.common.white,
  minWidth: '160px',
  padding: '10px 20px',
  border: '1.5px solid #215EAB',
}));
export const MainHeader = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}));
export const SubTitle = styled(Typography)(({ theme }) => ({
  color: '#818181',
  fontSize: '14px',
  fontWeight: '400',
}));
export const LinkText = styled(Link)(({ theme }) => ({
  color: '#2057A6',
  fontSize: '14px',
  fontWeight: '500',
  textDecoration: 'underline',
  textDecorationColor: '#337FC9',
}));
export const FieldSubHeader1 = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
}));
export const FieldSubHeader2 = styled(Typography)(({ theme }) => ({
  color: '#000',
  fontSize: '14px',
  fontWeight: '500',
}));
export const FieldSubHeader3 = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '500',
}));
export const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  fontWeight: '500',
  textDecoration: 'underline',
  textDecorationColor: '#337FC9',
}));
export const LeftContentText = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.common.white,
    fontSize: '80px',
    fontWeight: '700',
    lineHeight: '120px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '50px',
      lineHeight: '72px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '45px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: 'normal',
    },
  };
});
export const LeftContentTitle = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.common.white,
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },

    fontWeight: '500',
  };
});
export const ErrorText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  textAlign: 'left',
  mt: '3px',
  mr: '14px',
  mb: 0,
  ml: '14px',
  color: '#f44336',
}));
