import {
  Avatar,
  Box,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikErrors } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Female from '../../../../assets/female.svg';
import Male from '../../../../assets/male.svg';
import Others from '../../../../assets/others.svg';
import { DetailsValue, ErrorText } from '../../../auth/login/styles/styledComponents';
import { CustomizedCheckbox } from '../../../common/checkbox';
import { countryListOptions } from '../../../common/constants';
import CustomLoader from '../../../common/CustomLoader';
import { useRootContext } from '../../../data/root.context';
import FormDateInput from '../../../forms/FormDateInput';
import { FormSelectInput } from '../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { familyDescriptionOptions, guardianRelationWithM } from '../../mfs/constants';
import {
  useInvestorDetailsMutation,
  useVerifyPanKycDetailsMutation,
} from '../../mfs/newInvestorOnboarding/slice';
import { dateToDDMMYYYY } from '../../mfs/newInvestorOnboarding/utils';
import { useAadharPanSeedingMutation } from '../../slice';
import {
  setCartState,
  setHolder1DetailsFromResponse,
  setHolderDetails,
  setInvestorDetails,
  setIsNomination,
  setNomineeDetails,
  setStepNumber,
} from '../context/MfsNewPurchaseAction';
import { useMfsNewPurchaseContext } from '../context/MfsNewPurchaseContext';

const gendersList = {
  Male: Male,
  Female: Female,
  Others: Others,
};

interface InvestorDetailValues {
  modeOfHolding: string;
  invType: string;
  category: string;
  pan: string;
  mobile: string;
  email: string;
  name: string;
  emailFamilyDescription: string;
  mobileFamilyDescription: string;
  dob: string | null;
  gender: string;
  address1: string;
  residentOfUsOrCanada: boolean;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  countryCode: string;
  guardianPan: string;
  guardianDob: string | null;
  guardianName: string;
  guardianEmail: string;
  guardianCategory: string;
  guardianMonNo: string;
  guardianEmailFamilyDescription: string;
  guardianMobileFamilyDescription: string;
  guardianRelationWithMinor: string;
  panOfJointHolder1: string;
  categoryOfJointHolder1: string;
  nameOfJointHolder1: string;
  emailIdOfJointHolder1: string;
  mobileNoOfJointHolder1: string;
  dobOfJointHolder1: string | null;
  genderOfJointHolder1: string;
  jointHolder1EmailFamilyDescription: string;
  jointHolder1MobileFamilyDescription: string;
  h1Address1: string;
  h1Address2: string;
  h1City: string;
  h1State: string;
  h1Country: string;
  h1ZipCode: string;
  panOfJointHolder2: string;
  categoryOfJointHolder2: string;
  nameOfJointHolder2: string;
  emailIdOfJointHolder2: string;
  mobileNoOfJointHolder2: string;
  dobOfJointHolder2: string | null;
  genderOfJointHolder2: string;
  jointHolder2EmailFamilyDescription: string;
  jointHolder2MobileFamilyDescription: string;
  h2Address1: string;
  h2Address2: string;
  h2City: string;
  h2State: string;
  h2Country: string;
  h2ZipCode: string;
}

export const InvestorDetails: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [jointHolderPanErr, setJointHolderPanErr] = useState('');
  const [jointHolder2PanErr, setJointHolder2PanErr] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const {
    dispatch: contextDispatch,
    state: {
      investorDetailsFormResponse,
      investorDetails,
      holderDetails,
      investmentDetails,
      countriesList,
    },
  } = useMfsNewPurchaseContext();

  const countryList =
    countriesList?.length !== 0
      ? countriesList.filter(item => item.label !== 'India')
      : countryListOptions?.map((country: any) => ({
          value: country.country_name,
          label: country.country_name,
        }));

  const [getData] = useInvestorDetailsMutation();
  const [verifyPanFraud] = useVerifyPanKycDetailsMutation();
  const [aadharPanSeeding] = useAadharPanSeedingMutation();

  const personalDetails =
    investorDetailsFormResponse?.personalDetails ||
    state?.invResponse?.personalDetails ||
    {};
  const {
    dob: resDob,
    email: resEmail,
    mobile: resMob,
    name: resName,
    modeOfHolding: modeOfHoldingRes,
    investorType,
    pan,
  } = personalDetails || {};

  const [holder1 = {}, holder2 = {}] = holderDetails || [];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDate = new Date().getDate();
  const modeOfHoldingFill = modeOfHoldingRes === '1' ? 'single' : 'anyone';

  const invTypeFromRes = investorType === '01' || investorType === 'I' ? 'I' : 'M';
  const validJson =
    state?.previousUrl === 'cart' && state?.cartItems && state?.cartItems?.[0]?.payload;
  const payloadObj = JSON.parse(validJson);

  const initialValues: InvestorDetailValues = {
    invType: investorDetails?.invType || invTypeFromRes || 'I',
    modeOfHolding: investorDetails?.modeOfHolding || modeOfHoldingFill || 'single',
    category: investorDetails?.category
      ? investorDetails.category
      : state?.previousUrl === 'cart' && state?.cartItems?.length > 0
      ? state.cartItems[0].invCategory
      : investmentDetails?.invCategory || '11',
    pan: investorType === '01' || investorType === 'I' ? pan : '',
    mobile: investorDetails?.mobile
      ? investorDetails.mobile
      : state?.previousUrl === 'cart' && state?.cartItems?.length > 0
      ? state.cartItems[0].mobile
      : investmentDetails?.phoneNo || '',
    email: investorDetails?.email
      ? investorDetails.email
      : state?.previousUrl === 'cart' && state?.cartItems?.length > 0
      ? state.cartItems[0].email
      : investmentDetails?.emailId || '',
    name: investorDetails?.name
      ? investorDetails.name
      : investorType === '01' || investorType === 'I'
      ? resName
      : '',
    emailFamilyDescription: investorDetails?.emailFamilyDescription
      ? investorDetails.emailFamilyDescription
      : state?.previousUrl === 'cart' && payloadObj?.familyEmailFlag
      ? payloadObj.familyEmailFlag
      : investmentDetails?.emailFamilyFlag || 'SE',

    mobileFamilyDescription: investorDetails?.mobileFamilyDescription
      ? investorDetails.mobileFamilyDescription
      : state?.previousUrl === 'cart' && payloadObj?.familyMobileFlag
      ? payloadObj.familyMobileFlag
      : investmentDetails?.mobileFamilyFlag || 'SE',
    dob:
      investorDetails?.dob || investorType === '01' || investorType === 'I'
        ? resDob
        : null,
    gender: investorDetails?.gender || '',
    address1: investorDetails?.address1 || '',
    residentOfUsOrCanada: true,
    address2: investorDetails?.address2 || '',
    city: investorDetails?.city || '',
    state: investorDetails?.state || '',
    country: investorDetails?.country || '',
    zipCode: investorDetails?.zipCode || '',
    countryCode: '+91',
    guardianPan: investorDetails?.guardianPan || '',
    guardianDob: investorDetails?.guardianDob || investorType === 'M' ? resDob : null,
    guardianName: investorDetails?.guardianName || '',
    guardianEmail: investorDetails?.guardianEmail || '',
    guardianCategory: investorDetails?.guardianCategory || '',
    guardianMonNo: investorDetails?.guardianMonNo || '',
    guardianEmailFamilyDescription:
      investorDetails?.guardianEmailFamilyDescription || 'SE',
    guardianMobileFamilyDescription:
      investorDetails?.guardianMobileFamilyDescription || 'SE',
    guardianRelationWithMinor: investorDetails?.guardianRelationWithMinor || '',
    panOfJointHolder1: holder1?.pan || '',
    categoryOfJointHolder1: holder1?.category || '11',
    nameOfJointHolder1: holder1?.name || '',
    emailIdOfJointHolder1: holder1?.email || '',
    mobileNoOfJointHolder1: holder1?.mobile || '',
    dobOfJointHolder1: holder1?.dob || '',
    genderOfJointHolder1: holder1?.gender || '',
    jointHolder1EmailFamilyDescription: holder1?.emailFamilyDescription || 'SE',
    jointHolder1MobileFamilyDescription: holder1?.mobileFamilyDescription || 'SE',
    h1Address1: holder1?.nriAddress1 || '',
    h1Address2: holder1?.nriAddress2 || '',
    h1City: holder1?.nriCity || '',
    h1State: holder1?.nriState || '',
    h1Country: '',
    h1ZipCode: '',
    panOfJointHolder2: holder2?.pan || '',
    categoryOfJointHolder2: holder2?.category || '11',
    nameOfJointHolder2: holder2?.name || '',
    emailIdOfJointHolder2: holder2?.email || '',
    mobileNoOfJointHolder2: holder2?.mobile || '',
    dobOfJointHolder2: holder2?.dob || '',
    genderOfJointHolder2: holder2?.gender || '',
    jointHolder2EmailFamilyDescription: holder2?.emailFamilyDescription || 'SE',
    jointHolder2MobileFamilyDescription: holder2?.mobileFamilyDescription || 'SE',
    h2Address1: holder2?.nriAddress1 || '',
    h2Address2: holder2?.nriAddress2 || '',
    h2City: holder2?.nriCity || '',
    h2State: holder2?.nriState || '',
    h2Country: '',
    h2ZipCode: '',
  };

  const isJointHolder2NriAddressRequired = (
    mode: string,
    panOfJointHolder2: string,
    categoryOfJointHolder2: string,
  ) =>
    mode === 'anyone' &&
    categoryOfJointHolder2 === '20' &&
    panOfJointHolder2 &&
    panOfJointHolder2.length === 10;

  const isJointHolderToFieldsRequired = (mode: string, panOfJointHolder2: string) =>
    mode === 'anyone' && panOfJointHolder2 && panOfJointHolder2.length === 10;

  const validationSchema = Yup.object().shape({
    invType: Yup.string().required('Investor type is required'),
    modeOfHolding: Yup.string().required('Mode of Holding is required'),
    category: Yup.string().required('Category is required'),
    pan: Yup.string()
      .when('invType', {
        is: 'M',
        then: Yup.string().required('Minor PAN is required'),
        otherwise: Yup.string().required('PAN Of Primary Holder is required'),
      })
      .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN'),
    name: Yup.string().when('invType', {
      is: 'M',
      then: Yup.string().required('Minor Name is required'),
      otherwise: Yup.string().required('Name is required'),
    }),
    email: Yup.string()
      .required('Email is required')
      .matches(
        /^(\w[-._+]{0,1})*\w+@((\w)+[-]{0,1})*(\w)+(\.\w+)+\w$/,
        'email must be a valid email',
      ),
    mobile: Yup.string()
      .required('Mobile Number is required')
      .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    emailFamilyDescription: Yup.string().when('invType', {
      is: 'I',
      then: Yup.string().required('Email family description is required'),
    }),
    mobileFamilyDescription: Yup.string().when('invType', {
      is: 'I',
      then: Yup.string().required('Mobile family desc is required'),
    }),
    dob: Yup.date()
      .required('Date of birth required')
      .when('invType', {
        is: 'I',
        then: Yup.date().max(
          new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
          'Individual Date of Birth should be at least 18 years old',
        ),
        otherwise: Yup.date().when('invType', {
          is: 'M',
          then: Yup.date().min(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            'Date should not be before minimal date',
          ),
          otherwise: Yup.date().nullable(),
        }),
      }),
    gender: Yup.string().required('Gender is required'),
    address1: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('Address 1 is required'),
    }),
    address2: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('Address 2 is required'),
    }),
    city: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('City is required'),
    }),
    state: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('State is required'),
    }),
    country: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('Country is required'),
    }),
    zipCode: Yup.string().when('category', {
      is: '20',
      then: Yup.string()
        .required('ZipCode is required')
        .matches(/^[0-9]+$/, 'Please enter valid ZipCode'),
    }),
    guardianPan: Yup.string().when('invType', {
      is: 'M',
      then: Yup.string().required('Guardian PAN is required'),
    }),
    guardianName: Yup.string().when('invType', {
      is: 'M',
      then: Yup.string().required('Guardian name is required'),
    }),
    guardianCategory: Yup.string().when('invType', {
      is: 'M',
      then: Yup.string().required('Guardian category is required'),
    }),
    guardianDob: Yup.date()
      .nullable()
      .when('invType', {
        is: 'M',
        then: Yup.date()
          .required('Guardian Date of birth required')
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            'Guardian Date of Birth should be 18 years old',
          ),
        otherwise: Yup.date().nullable(),
      })
      .test('is-valid-date', 'Invalid Date', value => {
        return value === null || value === undefined || !isNaN(new Date(value).getTime());
      }),
    guardianEmailFamilyDescription: Yup.string().when('invType', {
      is: 'M',
      then: Yup.string().required('Guardian email family description is required'),
    }),
    guardianMobileFamilyDescription: Yup.string().when('invType', {
      is: 'M',
      then: Yup.string().required('Guardian mobile family description is required'),
    }),
    guardianEmail: Yup.string().when('invType', {
      is: 'M',
      then: Yup.string()
        .required('Guardian email is required')
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Invalid Email'),
    }),
    guardianMonNo: Yup.string().when('invType', {
      is: 'M',
      then: Yup.string()
        .required('Guardian mobile number is required')
        .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    }),
    guardianRelationWithMinor: Yup.string().when('invType', {
      is: 'M',
      then: Yup.string().required('Guardian relationship with minor is required'),
    }),
    panOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string()
        .required('PAN of joint holder 1 is required')
        .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN'),
    }),
    categoryOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().required('Category of joint holder 1 is required'),
    }),
    nameOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().required('Name of joint holder is required'),
    }),
    emailIdOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string()
        .required('Email id of joint holder is required')
        .matches(
          /^(\w[-._+]{0,1})*\w+@((\w)+[-]{0,1})*(\w)+(\.\w+)+\w$/,
          'email must be a valid email',
        ),
    }),
    mobileNoOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string()
        .required('Mobile No of joint holder is required')
        .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    }),
    dobOfJointHolder1: Yup.date().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.date()
        .required('DOB of joint holder is required')
        .max(
          new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
          'Holder should be at least 18 years old',
        ),
    }),
    genderOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().required('Gender of joint holder is required'),
    }),

    jointHolder1EmailFamilyDescription: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().required('Email family description of joint holder is required'),
    }),

    jointHolder1MobileFamilyDescription: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().required(
        'Mobile family description of joint holder is required',
      ),
    }),
    h1Address1: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('Address 1 is required'),
    }),
    h1Address2: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('Address 2 is required'),
    }),
    h1City: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('City is required'),
    }),
    h1State: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('State is required'),
    }),
    h1Country: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('Country is required'),
    }),
    h1ZipCode: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string()
        .required('ZipCode is required')
        .matches(/^[0-9]+$/, 'Please enter valid ZipCode'),
    }),
    panOfJointHolder2: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN'),
    }),
    categoryOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isJointHolderToFieldsRequired,
      then: Yup.string().required('Category of joint holder 2 is required'),
    }),
    nameOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isJointHolderToFieldsRequired,
      then: Yup.string().required('Name of joint holder is required'),
    }),
    emailIdOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isJointHolderToFieldsRequired,
      then: Yup.string()
        .required('Email id of joint holder is required')
        .matches(
          /^(\w[-._+]{0,1})*\w+@((\w)+[-]{0,1})*(\w)+(\.\w+)+\w$/,
          'email must be a valid email',
        ),
    }),
    mobileNoOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isJointHolderToFieldsRequired,
      then: Yup.string()
        .required('Mobile No of joint holder is required')
        .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    }),
    jointHolder2EmailFamilyDescription: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2'],
      {
        is: isJointHolderToFieldsRequired,
        then: Yup.string().required(
          'Email family description of joint holder is required',
        ),
      },
    ),
    jointHolder2MobileFamilyDescription: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2'],
      {
        is: isJointHolderToFieldsRequired,
        then: Yup.string().required(
          'Mobile family description of joint holder is required',
        ),
      },
    ),
    dobOfJointHolder2: Yup.date()
      .nullable()
      .when(['modeOfHolding', 'panOfJointHolder2'], {
        is: isJointHolderToFieldsRequired,
        then: Yup.date()
          .required('DOB of joint holder is required')
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            'Holder should be at least 18 years old',
          ),
        otherwise: Yup.date().nullable(),
      }),
    genderOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isJointHolderToFieldsRequired,
      then: Yup.string().required('Gender of joint holder is required'),
    }),
    h2Address1: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isJointHolder2NriAddressRequired,
        then: Yup.string().required('Address 1 is required'),
      },
    ),
    h2Address2: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isJointHolder2NriAddressRequired,
        then: Yup.string().required('Address 2 is required'),
      },
    ),
    h2City: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isJointHolder2NriAddressRequired,
        then: Yup.string().required('City is required'),
      },
    ),
    h2State: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isJointHolder2NriAddressRequired,
        then: Yup.string().required('State is required'),
      },
    ),
    h2Country: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isJointHolder2NriAddressRequired,
        then: Yup.string().required('Country is required'),
      },
    ),
    h2ZipCode: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isJointHolder2NriAddressRequired,
        then: Yup.string()
          .required('ZipCode is required')
          .matches(/^[0-9]+$/, 'Please enter valid ZipCode'),
      },
    ),
  });

  const handleCheckboxChange = (e: any, setFieldValue: any) => {
    setFieldValue('residentOfUsOrCanada', e.target.checked);
  };

  const handleMinorPanChanged = async (
    e: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    values: InvestorDetailValues,
    setValues: (values: InvestorDetailValues) => void,
    setErrors: (errors: FormikErrors<InvestorDetailValues>) => void,
  ) => {
    const panValue = e.target.value.toUpperCase();
    setFieldValue('pan', panValue);
    if (panValue.length === 10 && panValue !== values.guardianPan) {
      const panFraudBody = {
        pan: panValue,
      };
      try {
        setDisplayLoader(true);
        const response = await verifyPanFraud(panFraudBody).unwrap();
        showToast((response as { message: string }).message, 'success');

        const cKycRequestBody = {
          pan: panValue,
          mobile: '',
        };
        const minorResponse = await getData(cKycRequestBody).unwrap();
        const { name, dob, email, mobile } = minorResponse.personalDetails || {};

        setValues({
          ...values,
          pan: panValue,
          name: name,
          email: email,
          mobile: mobile,
          dob: dob,
        });
      } catch (error: any) {
        showToast(error && error.data?.message, 'error');
      } finally {
        setDisplayLoader(false);
      }
    } else {
      setErrors({
        name: '',
        email: '',
        mobile: '',
        dob: '',
        emailFamilyDescription: '',
        mobileFamilyDescription: '',
        gender: '',
      });
      setValues({
        ...values,
        name: '',
        pan: panValue,
        email: '',
        mobile: '',
        dob: null,
        emailFamilyDescription: '',
        mobileFamilyDescription: '',
        gender: '',
      });
    }
  };

  const handlePrimaryHolderPanChanged = async (
    e: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    values: InvestorDetailValues,
    setValues: (values: InvestorDetailValues) => void,
    setErrors: (errors: FormikErrors<InvestorDetailValues>) => void,
  ) => {
    const panValue = e.target.value.toUpperCase();
    setFieldValue('panOfJointHolder1', panValue);

    if (panValue.length === 10 && panValue !== values.pan) {
      const panFraudBody = {
        pan: panValue,
      };
      try {
        setDisplayLoader(true);
        const response = await verifyPanFraud(panFraudBody).unwrap();
        showToast((response as { message: string }).message, 'success');

        const cKycRequestBody = {
          pan: panValue,
          mobile: '',
        };
        const jointHolder1Response = await getData(cKycRequestBody).unwrap();
        contextDispatch(setHolder1DetailsFromResponse(jointHolder1Response));
        const { name, dob, email, mobile } = jointHolder1Response.personalDetails || {};
        setValues({
          ...values,
          panOfJointHolder1: panValue,
          nameOfJointHolder1: name,
          emailIdOfJointHolder1: email,
          mobileNoOfJointHolder1: mobile,
          dobOfJointHolder1: dob,
        });
        setJointHolderPanErr('');
      } catch (error: any) {
        showToast(error && error.data?.message, 'error');
        setJointHolderPanErr(error && error.data.message);
      } finally {
        setDisplayLoader(false);
      }
    } else {
      setErrors({
        nameOfJointHolder1: '',
        emailIdOfJointHolder1: '',
        mobileNoOfJointHolder1: '',
        dobOfJointHolder1: '',
        jointHolder1EmailFamilyDescription: '',
        jointHolder1MobileFamilyDescription: '',
        genderOfJointHolder1: '',
      });
      setValues({
        ...values,
        nameOfJointHolder1: '',
        panOfJointHolder1: panValue,
        emailIdOfJointHolder1: '',
        mobileNoOfJointHolder1: '',
        dobOfJointHolder1: null,
        jointHolder1EmailFamilyDescription: '',
        jointHolder1MobileFamilyDescription: '',
        genderOfJointHolder1: '',
      });
    }
  };

  const handleSecondHolderPanChanged = async (
    e: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    values: InvestorDetailValues,
    setValues: (values: InvestorDetailValues) => void,
    setErrors: (errors: FormikErrors<InvestorDetailValues>) => void,
  ) => {
    const panValue = e.target.value.toUpperCase();
    setFieldValue('panOfJointHolder2', panValue);
    if (
      panValue.length === 10 &&
      panValue !== values.pan &&
      panValue !== values.panOfJointHolder1
    ) {
      const panFraudBody = {
        pan: panValue,
      };
      try {
        setDisplayLoader(true);
        const response = await verifyPanFraud(panFraudBody).unwrap();
        showToast((response as { message: string }).message, 'success');

        const cKycRequestBody = {
          pan: panValue,
          mobile: '',
        };
        const jointHolder2Response = await getData(cKycRequestBody).unwrap();
        const { name, dob, email, mobile } = jointHolder2Response.personalDetails || {};
        setValues({
          ...values,
          panOfJointHolder2: panValue,
          nameOfJointHolder2: name,
          emailIdOfJointHolder2: email,
          mobileNoOfJointHolder2: mobile,
          dobOfJointHolder2: dob,
        });
        setJointHolder2PanErr('');
      } catch (error: any) {
        showToast(error && error.data?.message, 'error');
        setJointHolder2PanErr(error && error.data?.message);
      } finally {
        setDisplayLoader(false);
      }
    } else {
      setErrors({
        nameOfJointHolder2: '',
        emailIdOfJointHolder2: '',
        mobileNoOfJointHolder2: '',
        dobOfJointHolder2: '',
        jointHolder2EmailFamilyDescription: '',
        jointHolder2MobileFamilyDescription: '',
        genderOfJointHolder2: '',
      });
      setValues({
        ...values,
        nameOfJointHolder2: '',
        panOfJointHolder2: panValue,
        emailIdOfJointHolder2: '',
        mobileNoOfJointHolder2: '',
        dobOfJointHolder2: null,
        jointHolder2EmailFamilyDescription: '',
        jointHolder2MobileFamilyDescription: '',
        genderOfJointHolder2: '',
      });
    }
  };

  const getStatus = (category: string) => (category === '20' ? 'NRI' : 'RI');
  const getInvStatus = (category: string) => (category ? getStatus(category) : '');

  const handleSubmit = async (values: InvestorDetailValues) => {
    try {
      setDisplayLoader(true);
      const payload = {
        pan: values.pan,
        PanName: values.name,
        PanDOB: dateToDDMMYYYY(values.dob),
        folio: '',
        trType: 'New',
        fund:
          investmentDetails?.fund !== ''
            ? investmentDetails?.fund
            : state?.cartItems?.[0]?.fund,
        scheme: '',
        plan: '',
        guardianPan: values.guardianPan,
        guardianPanDOB: dateToDDMMYYYY(values.guardianDob),
        guardianPanName: values.guardianName,
        jointHolder1Pan: values.panOfJointHolder1,
        jointHolder1PanName: values.nameOfJointHolder1,
        jointHolder1PanDOB: dateToDDMMYYYY(values.dobOfJointHolder1),
        jointHolder2Pan: values.panOfJointHolder2,
        jointHolder2PanName: values.nameOfJointHolder2,
        jointHolder2PanDOB: dateToDDMMYYYY(values.dobOfJointHolder2),
        invStatus: getInvStatus(values.category),
        JH1_invStatus: getInvStatus(values.categoryOfJointHolder1),
        JH2_invStatus: getInvStatus(values.categoryOfJointHolder2),
        G_invStatus: getInvStatus(values.guardianCategory),
      };
      const panCheckResponse = await aadharPanSeeding(payload);
      const errorMessage = validateResponse(panCheckResponse);
      if (errorMessage) {
        showToast(errorMessage, 'error');
        return;
      }
      const {
        panOfJointHolder1,
        nameOfJointHolder1,
        emailIdOfJointHolder1,
        mobileNoOfJointHolder1,
        dobOfJointHolder1,
        genderOfJointHolder1,
        jointHolder1EmailFamilyDescription,
        jointHolder1MobileFamilyDescription,
        categoryOfJointHolder1,
        h1Address1,
        h1Address2,
        h1City,
        h1State,
        h1Country,
        h1ZipCode,
        panOfJointHolder2,
        categoryOfJointHolder2,
        nameOfJointHolder2,
        emailIdOfJointHolder2,
        mobileNoOfJointHolder2,
        dobOfJointHolder2,
        genderOfJointHolder2,
        jointHolder2EmailFamilyDescription,
        jointHolder2MobileFamilyDescription,
        h2Address1,
        h2Address2,
        h2City,
        h2Country,
        h2State,
        h2ZipCode,
        ...primaryHolderDetails
      } = values;
      const investorDetailsData = { ...primaryHolderDetails };
      if (values.modeOfHolding === 'anyone') {
        const holderDetails = [
          {
            pan: panOfJointHolder1,
            name: nameOfJointHolder1,
            email: emailIdOfJointHolder1,
            mobile: mobileNoOfJointHolder1,
            dob: dobOfJointHolder1,
            gender: genderOfJointHolder1,
            emailFamilyRelation: jointHolder1EmailFamilyDescription,
            mobileFamilyRelation: jointHolder1MobileFamilyDescription,
            category: categoryOfJointHolder1,
            nriAddress1: h1Address1,
            nriAddress2: h1Address2,
            nriCity: h1City,
            nriState: h1State,
            jointHolderType: 'I',
            status: 'I',
          },
        ];

        if (panOfJointHolder2 && panOfJointHolder2.length === 10) {
          holderDetails.push({
            pan: panOfJointHolder2,
            name: nameOfJointHolder2,
            email: emailIdOfJointHolder2,
            mobile: mobileNoOfJointHolder2,
            dob: dobOfJointHolder2,
            gender: genderOfJointHolder2,
            emailFamilyRelation: jointHolder2EmailFamilyDescription,
            mobileFamilyRelation: jointHolder2MobileFamilyDescription,
            category: categoryOfJointHolder2,
            nriAddress1: h2Address1,
            nriAddress2: h2Address2,
            nriCity: h2City,
            nriState: h2State,
            jointHolderType: 'I',
            status: 'I',
          });
        }
        contextDispatch(setHolderDetails(holderDetails));
      }
      setDisplayLoader(false);
      contextDispatch(setInvestorDetails(investorDetailsData));
      contextDispatch(setStepNumber(1));
    } catch (error: any) {
      showToast(error && error.data?.message, 'error');
    } finally {
      setDisplayLoader(false);
    }
  };

  function validateResponse(response: any) {
    if (response.Transaction_allow === 'Y') return;
    let errorMessage = '';
    if (response.PASFlag === 'N') {
      errorMessage = response.PASRemarks;
    } else if (response.PANFlag === 'N') {
      errorMessage = response.PANRemarks;
    } else if (response.PANNameMatch === 'N') {
      errorMessage = 'Entered Primary name does not match with PAN record';
    } else if (response.JH1_panFlag === 'N') {
      errorMessage = response.JH1_panRemarks;
    } else if (response.JH2_panFlag === 'N') {
      errorMessage = response.JH2_panRemarks;
    } else if (response.G_panFlag === 'N') {
      errorMessage = response.G_panRemarks;
    } else if (response.JH1_PANNameMatch === 'N') {
      errorMessage = 'Entered Joint Holder 1 name does not match with PAN record';
    } else if (response.JH2_PANNameMatch === 'N') {
      errorMessage = 'Entered Joint Holder 2 name does not match with PAN record';
    } else if (response.G_PANNameMatch === 'N') {
      errorMessage = 'Entered Guardian name does not match with PAN record';
    } else if (response.PanDOBMatch === 'N') {
      errorMessage = 'Entered Primary DOB does not match with PAN record';
    } else if (response.JH1_PanDOBMatch === 'N') {
      errorMessage = 'Entered Joint Holder 1 DOB does not match with PAN record';
    } else if (response.JH2_PanDOBMatch === 'N') {
      errorMessage = 'Entered Joint Holder 2 DOB does not match with PAN record';
    } else if (response.G_PanDOBMatch === 'N') {
      errorMessage = 'Entered Guardian DOB does not match with PAN record';
    }
    return errorMessage;
  }

  const validateHolderPan = (values: InvestorDetailValues) => {
    const errors: Record<string, any> = {};

    if (values.guardianPan && values.guardianPan === values.pan) {
      errors.pan = 'PAN must be unique';
    }

    if (values.panOfJointHolder1 && values.panOfJointHolder1 === values.pan) {
      errors.panOfJointHolder1 = 'PAN must be unique';
    }

    if (values.panOfJointHolder2 && values.panOfJointHolder2 === values.pan) {
      errors.panOfJointHolder2 = 'PAN must be unique';
    }

    if (
      values.panOfJointHolder2 &&
      values.panOfJointHolder2 === values.panOfJointHolder1
    ) {
      errors.panOfJointHolder2 = 'PAN must be unique';
    }
    return errors;
  };

  const handleInvTypeChange = (
    e: any,
    setValues: (values: InvestorDetailValues) => void,
    values: InvestorDetailValues,
  ) => {
    if (e.target.value === 'M') {
      setValues({
        ...values,
        invType: e.target.value,
        guardianPan: pan,
        guardianDob: resDob,
        guardianName: resName,
        guardianEmail: investmentDetails?.emailId || resEmail,
        guardianMonNo: investmentDetails?.phoneNo || resMob,
        guardianEmailFamilyDescription:
          investmentDetails?.emailFamilyFlag || payloadObj.familyEmailFlag,
        guardianMobileFamilyDescription:
          investmentDetails?.mobileFamilyFlag || payloadObj.familyMobileFlag,
        pan: '',
        mobile: '',
        email: '',
        name: '',
        dob: null,
        emailFamilyDescription: '',
        mobileFamilyDescription: '',
      });
      contextDispatch(setNomineeDetails(null));
      contextDispatch(setIsNomination(false));
    } else {
      setValues({
        ...values,
        guardianPan: '',
        guardianName: '',
        guardianEmail: '',
        guardianMonNo: '',
        guardianEmailFamilyDescription: '',
        guardianMobileFamilyDescription: '',
        guardianDob: null,
        invType: e.target.value,
        pan: pan,
        mobile: resMob,
        email: resEmail,
        name: resName,
        dob: resDob,
        emailFamilyDescription:
          investmentDetails?.emailFamilyFlag || payloadObj.familyEmailFlag,
        mobileFamilyDescription:
          investmentDetails?.mobileFamilyFlag || payloadObj.familyMobileFlag,
      });
    }
  };

  useEffect(() => {
    if (state !== null) {
      contextDispatch(setCartState(state));
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validate={validateHolderPan}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          setFieldError,
          setValues,
          setErrors,
        }) => {
          const { gender, genderOfJointHolder1, genderOfJointHolder2 } = errors;
          const isMinor = values.invType === 'M';

          return (
            <Form>
              {investmentDetails?.invCategory !== '20' && (
                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant='subtitle2'
                    sx={{ mb: 2, color: 'text.valueColor' }}
                  >
                    Type of Investor
                  </Typography>
                  <FormStyledRadioButton
                    options={[
                      { label: 'Individual', value: 'I' },
                      { label: 'Minor', value: 'M' },
                    ]}
                    name='invType'
                    handleChange={(e: any) => {
                      handleInvTypeChange(e, setValues, values);
                    }}
                  />
                </Box>
              )}
              {values.invType !== 'M' ? (
                investmentDetails?.invCategory !== '20' && (
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant='subtitle2'
                      sx={{ mb: 2, color: 'text.valueColor' }}
                    >
                      Mode of Holding
                    </Typography>
                    <FormStyledRadioButton
                      options={[
                        { label: 'Single', value: 'single' },
                        {
                          label: 'Anyone or Serviour',
                          value: 'anyone',
                        },
                      ]}
                      name='modeOfHolding'
                      handleChange={() => {
                        contextDispatch(setHolderDetails(null));
                      }}
                    />
                  </Box>
                )
              ) : (
                <Box
                  sx={{
                    borderRadius: '5px',
                    bgcolor: 'text.boxColorLight',
                    padding: '10px 20px',
                    my: 3,
                  }}
                >
                  <Typography
                    variant='subtitle2'
                    sx={{ color: 'text.valueColor' }}
                  >
                    Minor Details
                  </Typography>
                </Box>
              )}
              <Box sx={{ mb: 3 }}>
                <Typography
                  variant='subtitle2'
                  sx={{ mb: 2, color: 'text.valueColor' }}
                >
                  Category
                </Typography>
                <FormStyledRadioButton
                  options={[
                    { label: 'Resident of India', value: '11' },
                    { label: 'NRI', value: '20' },
                  ]}
                  name='category'
                  handleChange={() => {
                    setFieldValue('residentOfUsOrCanada', true);
                  }}
                  disabled={investmentDetails?.invCategory !== ''}
                />
              </Box>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <FormTextInput
                    name='pan'
                    label={values.invType === 'M' ? 'Minor PAN' : 'PAN of primary Holder'}
                    disabled={values.invType === 'I'}
                    onChange={e => {
                      handleMinorPanChanged(
                        e,
                        setFieldValue,
                        values,
                        setValues,
                        setErrors,
                      );
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <FormTextInput
                    name='name'
                    label={values.invType === 'M' ? 'Minor Name' : 'Name'}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <FormTextInput
                    name='email'
                    label='Email ID'
                    disabled={!isMinor && investmentDetails?.emailId !== ''}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <Stack flexDirection='row'>
                    <Box
                      sx={(theme: Theme) => ({
                        width: '20%',
                        [theme.breakpoints.up(1600)]: {
                          width: '15%',
                        },
                        [theme.breakpoints.up(1800)]: {
                          width: '10%',
                        },
                      })}
                    >
                      <FormTextInput
                        sx={{
                          '& .MuiInputBase-input ': {
                            borderRadius: '7px 0px 0px 7px',
                            borderRight: '0',
                          },
                        }}
                        name='countryCode'
                        disabled
                        required={false}
                      />
                    </Box>
                    <Box
                      width='75%'
                      sx={{
                        'width': { xs: '90%' },
                        '& .MuiInputBase-input ': {
                          borderRadius: '0px 7px 7px 0px',
                        },
                      }}
                    >
                      <FormTextInput
                        name='mobile'
                        placeholder='Enter mobile number'
                        label='Enter mobile number'
                        disabled={!isMinor && investmentDetails?.phoneNo !== ''}
                      />
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormSelectInput
                    name='emailFamilyDescription'
                    label='Email Family Description'
                    options={familyDescriptionOptions}
                    required={values.invType === 'I' ? true : false}
                    disabled={
                      !isMinor
                        ? state?.previousUrl === 'cart'
                          ? payloadObj.familyEmailFlag !== ''
                          : investmentDetails?.emailFamilyFlag !== ''
                        : false
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormSelectInput
                    name='mobileFamilyDescription'
                    label='Mobile Family Description'
                    options={familyDescriptionOptions}
                    required={values.invType === 'I' ? true : false}
                    disabled={
                      !isMinor
                        ? state?.previousUrl === 'cart'
                          ? payloadObj.familyMobileFlag !== ''
                          : investmentDetails?.mobileFamilyFlag !== ''
                        : false
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormDateInput
                    name='dob'
                    label='Date of Birth'
                    disableFuture
                    minDate={
                      values?.invType === 'I'
                        ? new Date(1900, 0, 1)
                        : new Date(currentYear - 18, currentMonth, currentDate)
                    }
                    maxDate={
                      values?.invType === 'I'
                        ? new Date(currentYear - 18, currentMonth, currentDate)
                        : new Date()
                    }
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ mb: 2 }}
                >
                  <DetailsValue sx={{ mb: 2 }}>Gender</DetailsValue>
                  <Stack
                    direction='row'
                    spacing={2}
                    sx={{
                      '& .MuiButtonBase-root.MuiChip-root': {
                        'padding': '10px 5px',
                        'borderRadius': '44px',
                        '& .MuiChip-label': {
                          fontSize: '14px',
                          fontWeight: 500,
                        },
                      },
                    }}
                  >
                    {Object.keys(gendersList)?.map(gender => (
                      <Chip
                        key={gender}
                        avatar={
                          <Avatar
                            alt={gender.toLowerCase()}
                            src={(gendersList as Record<string, string>)[gender]}
                          />
                        }
                        label={gender}
                        onClick={async () => setFieldValue('gender', gender)}
                        variant='outlined'
                        sx={{
                          'border': '1px solid',
                          'borderColor':
                            values.gender === gender
                              ? 'text.navyBlue'
                              : 'text.borderColorLight',
                          'backgroundColor':
                            values.gender === gender
                              ? 'text.boxColorLight'
                              : 'common.white',
                          '& .MuiChip-label': {
                            color:
                              values.gender === gender ? 'primar.main' : 'text.primary',
                          },
                        }}
                      />
                    ))}
                  </Stack>
                  {typeof gender === 'string' && touched.gender && (
                    <ErrorText>{gender}</ErrorText>
                  )}
                </Grid>
                {values.category === '20' && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='address1'
                        label='Address 1'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='address2'
                        label='Address 2'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='city'
                        label='City'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='state'
                        label='State'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='country'
                        label='Country'
                        options={countryList}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='zipCode'
                        label='ZipCode'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <FormControlLabel
                        control={
                          <CustomizedCheckbox
                            checked={values.residentOfUsOrCanada}
                            onChange={e => handleCheckboxChange(e, setFieldValue)}
                          />
                        }
                        label='I am not a resident of US/Canada'
                      />
                    </Grid>
                  </>
                )}
                {values.invType === 'M' && (
                  <>
                    <Grid
                      item
                      xs={12}
                    >
                      <Box
                        sx={{
                          borderRadius: '5px',
                          bgcolor: 'text.boxColorLight',
                          padding: '10px 20px',
                          my: 3,
                        }}
                      >
                        <DetailsValue sx={{ fontSize: '16px' }}>
                          Guardian Details
                        </DetailsValue>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='guardianName'
                        label='Guardian Name'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='guardianPan'
                        label='Guardian PAN'
                        disabled={pan?.length}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormDateInput
                        name='guardianDob'
                        label='Guardian Date of Birth'
                        disableFuture
                        minDate={
                          values?.invType === 'M'
                            ? new Date(1900, 0, 1)
                            : new Date(currentYear - 18, currentMonth, currentDate)
                        }
                        maxDate={
                          values?.invType === 'M'
                            ? new Date(currentYear - 18, currentMonth, currentDate)
                            : new Date()
                        }
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='guardianCategory'
                        label='Guardian Category'
                        options={[
                          { label: 'Resident of India', value: '11' },
                          { label: 'NRI', value: '20' },
                        ]}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='guardianEmail'
                        label='Guardian Email ID'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='guardianEmailFamilyDescription'
                        label='Guardian Email Family Description'
                        options={familyDescriptionOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Stack flexDirection='row'>
                        <Box
                          sx={(theme: Theme) => ({
                            width: '20%',
                            [theme.breakpoints.up(1600)]: {
                              width: '15%',
                            },
                            [theme.breakpoints.up(1800)]: {
                              width: '10%',
                            },
                          })}
                        >
                          <FormTextInput
                            sx={{
                              '& .MuiInputBase-input ': {
                                borderRadius: '7px 0px 0px 7px',
                                borderRight: '0',
                              },
                            }}
                            name='countryCode'
                            disabled
                            required={false}
                          />
                        </Box>
                        <Box
                          width='75%'
                          sx={{
                            'width': { xs: '90%' },
                            '& .MuiInputBase-input ': {
                              borderRadius: '0px 7px 7px 0px',
                            },
                          }}
                        >
                          <FormTextInput
                            name='guardianMonNo'
                            placeholder='Guardian mobile number'
                            label='Guardian mobile number'
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='guardianMobileFamilyDescription'
                        label='Guardian Mobile Family Description'
                        options={familyDescriptionOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='guardianRelationWithMinor'
                        label='Guardian Relationship with Minor'
                        options={guardianRelationWithM}
                        required
                      />
                    </Grid>
                  </>
                )}
                {values.modeOfHolding === 'anyone' && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='panOfJointHolder1'
                        label='PAN of joint holder1'
                        onChange={e => {
                          handlePrimaryHolderPanChanged(
                            e,
                            setFieldValue,
                            values,
                            setValues,
                            setErrors,
                          );
                        }}
                      />
                    </Grid>
                    {values?.panOfJointHolder1?.length === 10 &&
                      values?.panOfJointHolder1 !== values.pan &&
                      jointHolderPanErr === '' && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FormTextInput
                              name='nameOfJointHolder1'
                              label='Name'
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FormTextInput
                              name='emailIdOfJointHolder1'
                              label='Email ID'
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <Stack flexDirection='row'>
                              <Box
                                sx={(theme: Theme) => ({
                                  width: '20%',
                                  [theme.breakpoints.up(1600)]: {
                                    width: '15%',
                                  },
                                  [theme.breakpoints.up(1800)]: {
                                    width: '10%',
                                  },
                                })}
                              >
                                <FormTextInput
                                  sx={{
                                    '& .MuiInputBase-input ': {
                                      borderRadius: '7px 0px 0px 7px',
                                      borderRight: '0',
                                    },
                                  }}
                                  name='countryCode'
                                  disabled
                                  required={false}
                                />
                              </Box>
                              <Box
                                width='75%'
                                sx={{
                                  'width': { xs: '90%' },
                                  '& .MuiInputBase-input ': {
                                    borderRadius: '0px 7px 7px 0px',
                                  },
                                }}
                              >
                                <FormTextInput
                                  name='mobileNoOfJointHolder1'
                                  label='Enter mobile number'
                                />
                              </Box>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FormSelectInput
                              name='jointHolder1EmailFamilyDescription'
                              label='Email Family Description'
                              options={familyDescriptionOptions}
                              required
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FormSelectInput
                              name='jointHolder1MobileFamilyDescription'
                              label='Mobile Family Description'
                              options={familyDescriptionOptions}
                              required
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FormDateInput
                              name='dobOfJointHolder1'
                              label='Date of Birth'
                              disableFuture
                              // minDate={minDate}
                              maxDate={
                                new Date(currentYear - 18, currentMonth, currentDate)
                              }
                              required
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{ mb: 2 }}
                          >
                            <DetailsValue sx={{ mb: 2 }}>Category</DetailsValue>
                            <FormStyledRadioButton
                              options={[
                                { label: 'Resident of India', value: '11' },
                                { label: 'NRI', value: '20' },
                              ]}
                              name='categoryOfJointHolder1'
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{ mb: 2 }}
                          >
                            <DetailsValue sx={{ mb: 2 }}>Gender</DetailsValue>
                            <Stack
                              direction='row'
                              spacing={2}
                              sx={{
                                '& .MuiButtonBase-root.MuiChip-root': {
                                  'padding': '10px 5px',
                                  'borderRadius': '44px',
                                  '& .MuiChip-label': {
                                    fontSize: '14px',
                                    fontWeight: 500,
                                  },
                                },
                              }}
                            >
                              {Object.keys(gendersList)?.map(gender => (
                                <Chip
                                  key={gender}
                                  avatar={
                                    <Avatar
                                      alt={gender.toLowerCase()}
                                      src={
                                        (gendersList as Record<string, string>)[gender]
                                      }
                                    />
                                  }
                                  label={gender}
                                  onClick={async () =>
                                    setFieldValue('genderOfJointHolder1', gender)
                                  }
                                  variant='outlined'
                                  sx={{
                                    'border': '1px solid',
                                    'borderColor':
                                      values.genderOfJointHolder1 === gender
                                        ? 'text.navyBlue'
                                        : 'text.borderColorLight',
                                    'backgroundColor':
                                      values.genderOfJointHolder1 === gender
                                        ? 'text.boxColorLight'
                                        : 'common.white',
                                    '& .MuiChip-label': {
                                      color:
                                        values.genderOfJointHolder1 === gender
                                          ? 'primary.main'
                                          : 'text.primary',
                                    },
                                  }}
                                />
                              ))}
                            </Stack>
                            {typeof genderOfJointHolder1 === 'string' &&
                              touched.genderOfJointHolder1 && (
                                <ErrorText>{genderOfJointHolder1}</ErrorText>
                              )}
                          </Grid>
                          {values.categoryOfJointHolder1 === '20' && (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                              >
                                <FormTextInput
                                  name='h1Address1'
                                  label='Address 1'
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                              >
                                <FormTextInput
                                  name='h1Address2'
                                  label='Address 2'
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                              >
                                <FormTextInput
                                  name='h1City'
                                  label='City'
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                              >
                                <FormTextInput
                                  name='h1State'
                                  label='State'
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                              >
                                <FormSelectInput
                                  name='h1Country'
                                  label='Country'
                                  options={countryList}
                                  required
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                              >
                                <FormTextInput
                                  name='h1ZipCode'
                                  label='ZipCode'
                                />
                              </Grid>
                            </>
                          )}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FormTextInput
                              name='panOfJointHolder2'
                              label='PAN of joint holder2'
                              onChange={e => {
                                handleSecondHolderPanChanged(
                                  e,
                                  setFieldValue,
                                  values,
                                  setValues,
                                  setErrors,
                                );
                              }}
                              required={false}
                            />
                          </Grid>
                          {values?.panOfJointHolder2?.length === 10 &&
                            jointHolder2PanErr === '' &&
                            values?.panOfJointHolder2 !== values.pan &&
                            values?.panOfJointHolder2 !== values.panOfJointHolder1 && (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name='nameOfJointHolder2'
                                    label='Name'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name='emailIdOfJointHolder2'
                                    label='Email ID'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <Stack flexDirection='row'>
                                    <Box
                                      sx={(theme: Theme) => ({
                                        width: '20%',
                                        [theme.breakpoints.up(1600)]: {
                                          width: '15%',
                                        },
                                        [theme.breakpoints.up(1800)]: {
                                          width: '10%',
                                        },
                                      })}
                                    >
                                      <FormTextInput
                                        sx={{
                                          '& .MuiInputBase-input ': {
                                            borderRadius: '7px 0px 0px 7px',
                                            borderRight: '0',
                                          },
                                        }}
                                        name='countryCode'
                                        disabled
                                        required={false}
                                      />
                                    </Box>
                                    <Box
                                      width='75%'
                                      sx={{
                                        'width': { xs: '90%' },
                                        '& .MuiInputBase-input ': {
                                          borderRadius: '0px 7px 7px 0px',
                                        },
                                      }}
                                    >
                                      <FormTextInput
                                        name='mobileNoOfJointHolder2'
                                        label='Enter mobile number'
                                      />
                                    </Box>
                                  </Stack>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormSelectInput
                                    name='jointHolder2EmailFamilyDescription'
                                    label='Email Family Description'
                                    options={familyDescriptionOptions}
                                    required
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormSelectInput
                                    name='jointHolder2MobileFamilyDescription'
                                    label='Mobile Family Description'
                                    options={familyDescriptionOptions}
                                    required
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormDateInput
                                    name='dobOfJointHolder2'
                                    label='Date of Birth'
                                    disableFuture
                                    maxDate={
                                      new Date(
                                        currentYear - 18,
                                        currentMonth,
                                        currentDate,
                                      )
                                    }
                                    required
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ mb: 2 }}
                                >
                                  <DetailsValue sx={{ mb: 2 }}>Category</DetailsValue>
                                  <FormStyledRadioButton
                                    options={[
                                      { label: 'Resident of India', value: '11' },
                                      { label: 'NRI', value: '20' },
                                    ]}
                                    name='categoryOfJointHolder2'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ mb: 2 }}
                                >
                                  <DetailsValue sx={{ mb: 2 }}>Gender</DetailsValue>
                                  <Stack
                                    direction='row'
                                    spacing={2}
                                    sx={{
                                      '& .MuiButtonBase-root.MuiChip-root': {
                                        'padding': '10px 5px',
                                        'borderRadius': '44px',
                                        '& .MuiChip-label': {
                                          fontSize: '14px',
                                          fontWeight: 500,
                                        },
                                      },
                                    }}
                                  >
                                    {Object.keys(gendersList)?.map(gender => (
                                      <Chip
                                        key={gender}
                                        avatar={
                                          <Avatar
                                            alt={gender.toLowerCase()}
                                            src={
                                              (gendersList as Record<string, string>)[
                                                gender
                                              ]
                                            }
                                          />
                                        }
                                        label={gender}
                                        onClick={async () =>
                                          setFieldValue('genderOfJointHolder2', gender)
                                        }
                                        variant='outlined'
                                        sx={{
                                          'border': '1px solid',
                                          'borderColor':
                                            values.genderOfJointHolder2 === gender
                                              ? 'text.navyBlue'
                                              : 'text.borderColorLight',
                                          'backgroundColor':
                                            values.genderOfJointHolder2 === gender
                                              ? 'text.boxColorLight'
                                              : 'common.white',
                                          '& .MuiChip-label': {
                                            color:
                                              values.genderOfJointHolder2 === gender
                                                ? 'primary.main'
                                                : 'text.primary',
                                          },
                                        }}
                                      />
                                    ))}
                                  </Stack>
                                  {typeof genderOfJointHolder2 === 'string' &&
                                    touched.genderOfJointHolder2 && (
                                      <ErrorText>{genderOfJointHolder2}</ErrorText>
                                    )}
                                </Grid>
                                {values.categoryOfJointHolder2 === '20' && (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <FormTextInput
                                        name='h2Address1'
                                        label='Address 1'
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <FormTextInput
                                        name='h2Address2'
                                        label='Address 2'
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <FormTextInput
                                        name='h2City'
                                        label='City'
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <FormTextInput
                                        name='h2State'
                                        label='State'
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <FormSelectInput
                                        name='h2Country'
                                        label='Country'
                                        options={countryList}
                                        required
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <FormTextInput
                                        name='h2ZipCode'
                                        label='ZipCode'
                                      />
                                    </Grid>
                                  </>
                                )}
                              </>
                            )}
                        </>
                      )}
                  </>
                )}
              </Grid>
              {displayLoader && <CustomLoader display={displayLoader} />}
              <SubmitButton
                sx={{
                  '&.MuiButtonBase-root': {
                    borderRadius: '5px',
                    minWidth: { xs: '100%', sm: '200px' },
                    mt: 5,
                  },
                }}
                label='Proceed'
                disabled={!values.residentOfUsOrCanada}
              >
                Proceed
              </SubmitButton>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
