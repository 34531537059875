import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../data/root.context';
import Layout from '../../Layout';
import { useCreateUpdateIdcwOptionRequestMutation } from '../../slice';
import DetailsDataTable from '../common/DetailsDataTable';
import { DetailType } from '../common/types';

const ChangeIDCWOption = () => {
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [requestId, setRequestId] = useState('');
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [createUpdateIdcwOptionRequest] = useCreateUpdateIdcwOptionRequestMutation();
  const { pan, email, mobile } = useSelector((state: any) => state.transactions);

  async function handleSubmit(data: any) {
    try {
      const payload = {
        requestData: {
          pan,
          mobile,
          email,
          data: data.map((scheme: any) => ({
            amc: scheme.amc,
            folio: `${scheme.folio}`,
            schemeCode: scheme.schemeCode,
            schemeName: scheme.schemeName,
            currentIdcwOption: scheme.schemeOption === 'R' ? 'R' : 'D',
            newIdcwOption: scheme.schemeOption,
          })),
        },
      };
      const response: any = await createUpdateIdcwOptionRequest(payload).unwrap();
      setRequestId(response.req_id);
      setOpenDialogBox(true);
    } catch (error: any) {
      showToast(error.message || error?.data?.message, 'error');
    }
  }

  return (
    <Layout title='Change of IDCW Option'>
      <DetailsDataTable
        columns={['Folio Number', 'Fund House', 'Primary Holder', 'Mobile Number']}
        onSubmit={handleSubmit}
        detailType={DetailType.Scheme}
        showEmailPhoneCombination
      />
      <CustomDialog
        isVisible={openDialogBox}
        firstButtonHandler={() => navigate(-1)}
        firstButtonTitle='OK'
        handleClose={() => {}}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />
          <Typography>
            Your request for{' '}
            <Typography component={'span'}>Payout to Reinvest</Typography> is created
            successfully with request id{' '}
            <Typography component={'span'}>{requestId}</Typography>
          </Typography>
          <Typography>
            A Link has been sent to the Investor Email ID. After investor authorises the
            transaction, details will be updated after T+1 day.
          </Typography>
        </Box>
      </CustomDialog>
    </Layout>
  );
};

export default ChangeIDCWOption;
