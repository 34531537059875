import { IconButton, Stack } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { ReactComponent as BackIcon_Mobile } from '../../../assets/back-icon.svg';
import { ReactComponent as BackIcon } from '../../../assets/back-icon-large.svg';
import { useGetAllReportsMutation } from '../../../service/api';
import { TransactionHeader } from '../../auth/login/styles/styledComponents';
// import { PageNavigator } from '../../common/BackToHomePage';
import DataTable from '../../common/DataTable';
import { useRootContext } from '../../data/root.context';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import ReportsFilter from './ReportsFilter';
const REPORTS_ENDPOINTS = {
  PENDING: '/NPSMFDAgentPendingSubscribers',
  COMPLETED: '/NPSMFDAgentCompletedSubscribers',
  CONTRIBUTIONS: '/NPSMFDAgentContributedSubscribers',
};
type RowType = {
  'Subscriber Name': string;
  'Subscriber PAN': string;
  'Mobile Number': string;
  'EMail ID': string;
  'POP Name': string;
  'Workflownumber': string;
  'Agent ReferenceNumber': string;
  'Pending At': string;
};
interface TableDataState {
  rows: RowType[]; // Assuming RowType is defined as in your previous code
  cols: string[];
}
const MisReports = () => {
  const navigate = useNavigate();

  const {
    state: { type, heading },
  } = useLocation();

  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
  );
  const [getAllReportsData] = useGetAllReportsMutation();
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [tableData, setTableData] = useState<TableDataState>({ rows: [], cols: [] });
  const initialTableData = useRef([]);
  const { showToast } = useRootContext();
  const [searchText, setSearchText] = useState('');
  const { ackNumber, uniqueAgentIdentifier } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  const [currentLink, setCurrentLink] = useState('');
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const getData = async () => {
    // TODO: Remove Hardcoded values.
    const payload = JSON.stringify({
      body: JSON.stringify({
        AgentReferenceNumber: 'AABCK5190K00000018',
        FromDate: format(startDate, 'dd/MM/yyyy'),
        toDate: format(endDate, 'dd/MM/yyyy'),
      }),
      endPoint: REPORTS_ENDPOINTS[type as keyof typeof REPORTS_ENDPOINTS],
    });

    try {
      const response = await getAllReportsData(payload);
      if ('data' in response) {
        const data = JSON.parse(response?.data?.body);
        const newRows =
          type === 'PENDING'
            ? data.PendingSubscribers
            : type === 'COMPLETED'
            ? data.CompletedSubscribers
            : data.ContributedSubscribers;
        if (newRows && newRows.length > 0) {
          const newCols: string[] = newRows ? Object.keys(newRows[0]) : [];
          const csv = [
            newCols.join(','), // header row first
            ...newRows.map((row: RowType) =>
              Object.values(row)
                .map(value => JSON.stringify(value))
                .join(','),
            ),
          ].join('\r\n');

          const blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8' });
          const url = window.URL.createObjectURL(blob);
          setCurrentLink(url);
          setTableData({ rows: newRows, cols: newCols });
          initialTableData.current = newRows;
        } else {
          setTableData({ rows: [], cols: [] });
          initialTableData.current = [];
        }
      }
    } catch (error: any) {
      showToast((error.data || (error as { message: string })).message, 'error');
    }
  };

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const dateChangeHandler = (newDate: Date, type: string) => {
    type === 'startDate' ? setStartDate(newDate) : setEndDate(newDate);
  };
  const handleSearchTextChanged = (text: string) => {
    setSearchText(text);
    const filteredData = initialTableData.current.filter(
      (rowData: any) =>
        rowData['Subscriber Name'].toLowerCase().includes(text.toLowerCase()) ||
        rowData['Subscriber PAN'].toLowerCase().includes(text.toLowerCase()),
    );
    setTableData({ ...tableData, rows: filteredData });
  };
  const handleDownloadCSV = () => {
    if (downloadLinkRef.current) {
      downloadLinkRef.current.click();
    }
  };
  return (
    <React.Fragment>
      <Stack
        direction='row'
        alignItems='center'
      >
        <IconButton
          sx={{ mb: '10px', mt: { xs: 2, md: 0 }, display: { xs: 'none', sm: 'unset' } }}
        >
          <BackIcon onClick={() => navigate('../nps')} />
        </IconButton>
        <IconButton
          sx={{ mb: 2, mt: { xs: 2, md: 0 }, display: { xs: 'unset', sm: 'none' } }}
        >
          <BackIcon_Mobile onClick={() => navigate('../nps')} />
        </IconButton>
        <TransactionHeader sx={{ mb: 2, mt: { xs: 2, md: 0 } }}>
          {heading} Reports
        </TransactionHeader>
      </Stack>
      {/* <PageNavigator title={` ${heading} Reports`} /> */}
      <DataTable
        columns={tableData.cols ?? []}
        rows={tableData.rows ?? []}
        onSubmit={() => {}}
        disableSelectAll={true}
        hideUpdateButton={true}
        hidecheckbox={true}
        filter={
          <ReportsFilter
            startDate={startDate}
            endDate={endDate}
            searchText={searchText}
            onSearchTextChange={handleSearchTextChanged}
            handleDateChange={dateChangeHandler}
            currentLink={currentLink}
            downloadLinkRef={downloadLinkRef}
            handleDownloadCSV={handleDownloadCSV}
            fileName={`${type}_SUBSCRIBERS`}
            isButtonDisabled={!(tableData?.rows?.length > 0)}
          />
        }
      />
    </React.Fragment>
  );
};
export default MisReports;
