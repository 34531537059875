import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Theme,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React from 'react';

type StyledRadioButtonGroupProps = {
  options: { label: string; value: string }[];
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errorText?: string;
};

export const StyledRadioButtonGroup: React.FC<StyledRadioButtonGroupProps> = ({
  options,
  value,
  handleChange,
  disabled = false,
  errorText,
}) => {
  return (
    <Box
      sx={{
        '& .MuiFormGroup-root': {
          gap: { xs: '10px', sm: '20px' },
        },
      }}
    >
      <FormControl>
        <RadioGroup
          row
          aria-labelledby='demo-row-radio-buttons-group-label'
          name='radio-btn-group'
          value={value}
          onChange={handleChange}
        >
          {options.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.value}
              control={<Radio />}
              label={item.label}
              sx={{
                'borderRadius': '5px',
                'mx': 0,
                'minWidth': 'max-content',
                'justifyContent': 'center',

                'border': value === item.value ? '1px solid' : '1px solid ',
                'borderColor':
                  value === item.value ? 'text.navyBlue' : 'text.borderColorLight',
                'bgcolor':
                  value === item.value ? 'rgba(0, 87, 155, 0.06)' : 'common.white',
                'padding': { xs: '0px 20px 0px 10px', sm: '0px 30px 0px 10px' },
                '& .MuiTypography-root': {
                  color: 'text.valueColor',
                  fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                  fontWeight: '500',
                },
                '& .MuiButtonBase-root': {
                  '&.Mui-checked': {
                    color: `text.navyBlue`,
                  },
                  '& .MuiSvgIcon-root': {
                    width: '16px',
                    height: '16px',
                  },
                },
                '.MuiButtonBase-root.Mui-checked + .MuiTypography-root': {
                  color: `text.navyBlue`,
                  opacity: 1,
                },
                ...(disabled
                  ? {
                      opacity: 0.5,
                      pointerEvents: 'none',
                    }
                  : {}),
              }}
            />
          ))}
        </RadioGroup>
        <FormHelperText sx={{ color: 'error.main', fontSize: '11px', fontWeight: '400' }}>
          {errorText && (
            <InfoOutlinedIcon
              sx={(theme: Theme) => ({
                fontSize: '14px',
                verticalAlign: 'middle',
                mr: 0.5,
                [theme.breakpoints.between(768, 1200)]: {
                  fontSize: '13px',
                },
              })}
              color='error'
            />
          )}
          {errorText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export function FormStyledRadioButton({
  options,
  name,
  handleChange,
  disabled,
}: {
  options: { label: string; value: string }[];
  name: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const { value } = field;
  const { error, touched } = meta;

  const errorText = error && touched ? error : '';

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFieldValue(name, e.target.value);
    handleChange?.(e);
  }

  return (
    <StyledRadioButtonGroup
      options={options}
      value={value}
      handleChange={onChange}
      disabled={disabled}
      errorText={errorText}
    />
  );
}

export default StyledRadioButtonGroup;
