import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useField, useFormikContext } from 'formik';
import * as React from 'react';

const Input = styled(MuiInput)`
  width: 65px;
`;
const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '100',
  },
];
function valuetext(value: number) {
  return `${value}°C`;
}

interface InputSliderProps {
  required?: boolean;
  options: any;
  name: string;
  label: any;
  fullWidth: boolean;
  maxValue: any;
  minValue: any;
  commonProps?: Record<string, any>;
}
export default function InputSlider({
  required = false,
  options,
  name,
  label,
  fullWidth,
  maxValue,
  minValue,
  commonProps = {},
  ...rest
}: InputSliderProps) {
  //const [value, setValue] = React.useState(30);
  const [field, meta, { setValue, setTouched }] = useField(name);
  const { value, onBlur } = field;
  const formikContext = useFormikContext();

  const { isSubmitting: disabled } = formikContext;
  const errorMessage = meta.error && meta.touched ? meta.error : '';

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setTouched(true);
    setValue(newValue as number);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    setValue(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '70%' },
        display: 'flex',
        justifyContent: 'space-between',
        my: 3,
      }}
    >
      {/* <Typography id="input-slider" gutterBottom>
                Volume
            </Typography> */}

      <Grid
        container
        alignItems='center'
        spacing={3}
      >
        <Grid
          item
          xs={3}
        >
          <Typography
            variant='subtitle2'
            id='input-slider'
            sx={{ color: 'text.labelColor' }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid
          item
          xs
        >
          <Slider
            sx={{
              'mb': 0,
              '& .MuiSlider-rail': {
                backgroundColor: 'text.sliderColor',
                opacity: '1',
              },
              '& .MuiSlider-track': {
                border: 'none',
                borderRadius: '2px',
                background: 'linear-gradient(249deg, #337FC9 36%, #3FDDE0 100%)',
              },
              '& .MuiSlider-mark': {
                display: 'none',
              },
              '& .MuiSlider-thumb': {
                'background': 'linear-gradient(249deg, #337FC9 36%, #3FDDE0 100%)',
                'boxShadow': 'none',
                'filter': 'drop - shadow(0px 1px 3px rgba(0, 0, 0, 0.30))',
                '&:hover,:before,:after,.Mui-focusVisible,.Mui-active': {
                  boxShadow: 'none',
                },
              },
              '& .MuiSlider-markLabel': {
                fontSize: '12px',
                fontWeight: 400,
                color: 'text.labelColor',
              },
            }}
            value={value || 0}
            onChange={handleSliderChange}
            aria-labelledby='input-slider'
            // valueLabelDisplay="auto"
            aria-label='Custom marks'
            getAriaValueText={valuetext}
            step={10}
            marks={marks}
            min={minValue}
            max={maxValue}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <Input
            sx={{
              'backgroundColor': 'common.white',
              '& .MuiInputBase-input': {
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 500,
                color: 'text.labelColor',
                opacity: '0.7',
                padding: '10px 0px',
                border: '1.5px solid',
                borderColor: 'text.borderColorDark',
                borderRadius: '7px',
              },
              ':before,:after': {
                borderBottom: 'none',
                content: 'none',
              },
              ':hover:not(.Mui-dusabled, .Mui-error):before': {
                borderBottom: 'none',
              },
            }}
            value={value}
            size='small'
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              'step': 10,
              'min': 0,
              'max': 100,
              'type': 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
