import {
  Box,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import AUM from '../../assets/OnboardingDashboard/aum.svg';
import { ReactComponent as DirectionIcon } from '../../assets/OnboardingDashboard/direction-icon.svg';
import { ReactComponent as EditFilled } from '../../assets/OnboardingDashboard/edit-filled.svg';
import { ReactComponent as ExpiredFilled } from '../../assets/OnboardingDashboard/expired-filled.svg';
import SIPDashboard from '../../assets/OnboardingDashboard/mfs-sip-bg.svg';
import { ReactComponent as ProcessingFilled } from '../../assets/OnboardingDashboard/processing-filled.svg';
import { ReactComponent as SIPCoin } from '../../assets/OnboardingDashboard/sip-coin.svg';
import Investors from '../../assets/OnboardingDashboard/total-investors.svg';
import Schemes from '../../assets/OnboardingDashboard/total-schemes.svg';
import { ReactComponent as WhatsappFilled } from '../../assets/OnboardingDashboard/whatsapp-filled.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
import {
  Investorcard,
  MainHeader,
  StepperNextButton,
} from '../auth/login/styles/styledComponents';

interface Props {
  snapshotsData: SnapshotData | null; // Define the type of snapshotsData prop
}
import { TabContext, TabList } from '@mui/lab';

import { SnapshotData } from '.';
import AumSummary from './AumSummary';

export const MF: React.FC<Props> = ({ snapshotsData }) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState('1');

  const items = [
    { name: 'AUM(INR)', rupees: '₹ 65,500', icon: AUM },
    { name: 'Total Schemes', rupees: '10', icon: Schemes },
    { name: 'Total Investors', rupees: 'Total Investors', icon: Investors },
  ];
  const pfmData = [
    {
      pfm: 'Birla Sunlife Pension Management Limited',
      percentage: '25%',
      inr: '₹2,22,22,561',
    },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
  ];
  const reports = [
    'Transaction Report',
    'Net AUM Report',
    'Client-Wise AUM Report',
    'Brokerage Report',
    'Investor Master Information',
    'NAV Report',
    'Rejection Report',
    'Transaction Wise Investor Master',
    'SIP Report',
  ];

  const handleSnapshot = (type: string) => {
    const state = { authorizationType: type };
    navigate('transaction-summary', { state });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Grid
        container
        my={'20px'}
        spacing={2}
      >
        {items.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'position': 'relative',
                'borderRadius': '5px 10px 10px 5px',
                'border': '0.1px solid',
                'borderColor': 'text.borderColorLight',
                'bgcolor': 'common.white',
                'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                'padding': '25px',
                'display': 'flex',
                'alignItems': 'center',
                '&:before': {
                  content: `' '`,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '5px',
                  height: '100%',
                  background: theme.palette.background.primaryLinearGradient,
                  borderRadius: '15px 0px 0px 15px',
                },
              })}
            >
              <CardMedia
                component='img'
                image={item.icon}
                alt='image'
                sx={{ width: '70px', height: '70px', mr: 4 }}
              />
              <Stack>
                <Typography sx={{ color: 'text.labelColor', mb: 1 }}>
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 700,
                    fontSize: { xs: '14px', lg: '18px', xl: '22px' },
                  }}
                >
                  {item.rupees}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant='subtitle2'
        sx={{ my: 2, color: 'text.labelColor' }}
      >
        As on: 26th Oct 2023
      </Typography>
      <Grid
        container
        spacing={3}
        my={'10px'}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Investorcard sx={{ padding: { xs: '16px', md: '30px' } }}>
            <Typography
              variant='subtitle1'
              sx={{ color: 'primary.main', fontWeight: 700, mb: 2 }}
            >
              Asset Class-Wise Investment Breakup
            </Typography>

            <Typography
              variant='subtitle2'
              sx={{ color: 'text.labelColor' }}
            >
              As on: 26th Oct 2023
            </Typography>

            {/* pieChart */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={1} />
                    <TableCell>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor', fontWeight: 600 }}
                      >
                        Percentage(%)
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor', fontWeight: 600 }}
                      >
                        INR
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    '& .MuiTableRow-root:nth-of-type(odd)': {
                      bgcolor: 'common.white',
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        columnGap={'20px'}
                      >
                        <Box
                          sx={{
                            bgcolor: 'text.paleAqua',
                            width: '11px',
                            height: '11px',
                            borderRadius: '50px',
                          }}
                        />
                        <Typography sx={{ color: 'text.valueColor' }}>Equity</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>25%</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>₹2,22,561</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        columnGap={'20px'}
                      >
                        <Box
                          sx={{
                            bgcolor: 'text.lavender',
                            width: '11px',
                            height: '11px',
                            borderRadius: '50px',
                          }}
                        />
                        <Typography sx={{ color: 'text.valueColor' }}>Liquid</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>25%</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>₹2,22,561</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        columnGap={'20px'}
                      >
                        <Box
                          sx={{
                            bgcolor: 'text.pastelYellow',
                            width: '11px',
                            height: '11px',
                            borderRadius: '50px',
                          }}
                        />
                        <Typography sx={{ color: 'text.valueColor' }}>Debt</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>9.53%</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>₹2,22,561</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        columnGap={'20px'}
                      >
                        <Box
                          sx={{
                            bgcolor: 'text.lightSkyBlue',
                            width: '11px',
                            height: '11px',
                            borderRadius: '50px',
                          }}
                        />
                        <Typography sx={{ color: 'text.valueColor' }}>Others</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>25%</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>₹2,22,561</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Investorcard>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Investorcard>
            <Typography
              variant='subtitle1'
              sx={{
                color: 'primary.main',
                fontWeight: 700,
                margin: { xs: '15px 25px 15px', md: '35px 35px 16px' },
              }}
            >
              AMC Wise Breakup
            </Typography>
            <Grid
              container
              sx={{
                bgcolor: 'text.tableBgColor',
                padding: { xs: '8px 20px', md: '16px 35px' },
                position: 'sticky',
                justifyContent: 'space-between',
              }}
            >
              <Grid
                item
                xs={4}
                md={5.5}
              >
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  AMC
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                md={3}
              >
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  Percentage(%)
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                md={3.5}
              >
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  INR
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                'maxHeight': '350px',
                'overflowY': 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '214px',
                },
                '&::-webkit-scrollbar-thumb': {
                  height: '6px',
                  border: '2px solid rgba(0, 0, 0, 0)',
                  backgroundClip: 'padding-box',
                  backgroundColor: 'text.stepperLine',
                  borderRadius: '33px',
                  boxShadow:
                    'inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05)',
                },
                '&::-webkit-scrollbar-button': {
                  display: 'none',
                  width: 0,
                  height: 0,
                },
                '&::-webkit-scrollbar-corner': {
                  backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-track': {
                  borderRadius: '33px',
                  background: 'rgba(255, 255, 255, 0.8)',
                },
              }}
            >
              <Box>
                {pfmData.map((data, index) => (
                  <Grid
                    container
                    sx={{
                      'padding': { xs: '8px 20px', md: '30px 35px' },
                      'justifyContent': 'space-between',
                      '&:not(:last-child)': {
                        borderBottom: '1px solid',
                        borderColor: 'text.borderColorDark',
                      },
                    }}
                    key={index}
                  >
                    <Grid
                      item
                      xs={4}
                      md={5.5}
                    >
                      <Typography sx={{ color: 'text.valueColor' }}>
                        {data.pfm}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={3}
                    >
                      <Typography sx={{ color: 'text.valueColor' }}>
                        {data.percentage}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={3.5}
                    >
                      <Typography sx={{ color: 'text.valueColor' }}>
                        {data.inr}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Box>
          </Investorcard>
        </Grid>
      </Grid>
      <Investorcard sx={{ padding: '35px 35px' }}>
        <MainHeader sx={{ fontSize: '16px', mb: 2 }}>Transaction Snapshots</MainHeader>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={{
                bgcolor: 'text.lightShadeGreen',
                padding: '10px 15px 15px 35px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <Stack>
                <Typography
                  sx={{
                    mb: 2,
                    color: 'text.greenShade',
                    fontSize: '22px',
                    fontWeight: 900,
                  }}
                >
                  {snapshotsData?.approved ?? 'NA'}
                </Typography>
                <Typography
                  sx={{ color: 'text.labelColor', fontSize: '16px', fontWeight: 500 }}
                >
                  Approved
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon onClick={() => handleSnapshot('Approved')} />
                </IconButton>

                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': '50px',
                    'height': '50px',
                    'bgcolor': 'text.greenShade',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,
                    'transition': 'background-color 0.5s', // Add transition for smooth effect
                    '&:hover': {
                      '&:before': {
                        left: 0,
                        bottom: 0,
                        top: 0,
                      },
                      '& svg path': {
                        fill: theme.palette.text.greenShade, // Change fill color of paths within SVG on hoverZ
                        transition: 'fill 0.5s',
                      },
                    },
                    '& svg': {
                      zIndex: '2',
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <WhatsappFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={{
                bgcolor: 'text.lightShadeAmber',
                padding: '10px 15px 15px 35px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <Stack>
                <Typography
                  variant='h3'
                  sx={{ mb: 2, color: 'text.amber', fontWeight: 900 }}
                >
                  {snapshotsData?.pending ?? 'NA'}
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.labelColor' }}
                >
                  Pending
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon onClick={() => handleSnapshot('Pending')} />
                </IconButton>
                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': '50px',
                    'height': '50px',
                    'bgcolor': 'text.amber',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,

                    'transition': 'background-color 0.5s', // Add transition for smooth effect
                    '&:hover': {
                      '&:before': {
                        left: 0,
                        bottom: 0,
                        top: 0,
                      },
                      '& svg path': {
                        fill: theme.palette.text.amber, // Change fill color of paths within SVG on hoverZ
                        transition: 'fill 0.5s',
                      },
                    },
                    '& svg': {
                      zIndex: '2',
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <ProcessingFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={{
                bgcolor: 'text.coral',
                padding: '10px 15px 15px 35px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <Stack>
                <Typography
                  variant='h3'
                  sx={{ mb: 2, color: 'text.clearText', fontWeight: 900 }}
                >
                  {snapshotsData?.expired ?? 'NA'}
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.labelColor' }}
                >
                  Expired
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon onClick={() => handleSnapshot('Expired')} />
                </IconButton>
                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': '50px',
                    'height': '50px',
                    'bgcolor': 'text.clearText',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,
                    'transition': 'background-color 0.5s', // Add transition for smooth effect
                    '&:hover': {
                      '&:before': {
                        left: 0,
                        bottom: 0,
                        top: 0,
                      },
                      '& svg path': {
                        fill: theme.palette.text.clearText, // Change fill color of paths within SVG on hoverZ
                        transition: 'fill 0.5s',
                      },
                    },
                    '& svg': {
                      zIndex: '2',
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <ExpiredFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={{
                bgcolor: 'text.lightBlue',
                padding: '10px 15px 15px 35px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <Stack>
                <Typography
                  variant='h3'
                  sx={{ mb: 2, color: 'text.lightShadeBlue', fontWeight: 900 }}
                >
                  {snapshotsData?.PhysicalTransactions ?? 'NA'}
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.labelColor' }}
                >
                  Phygital Transactions
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon />
                </IconButton>

                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': '50px',
                    'height': '50px',
                    'bgcolor': 'text.denimBlue',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,

                    'transition': 'background-color 0.5s', // Add transition for smooth effect
                    '&:hover': {
                      '&:before': {
                        left: 0,
                        bottom: 0,
                        top: 0,
                      },
                      '& svg path': {
                        fill: theme.palette.text.turquoiseBlue, // Change fill color of paths within SVG on hoverZ
                        transition: 'fill 0.5s',
                      },
                    },
                    '& svg': {
                      zIndex: '2',
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <EditFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Investorcard>
      <Box
        sx={(theme: Theme) => ({
          my: 3,
          backgroundSize: { xs: 'contain' },
          borderRadius: '15px',
          backgroundRepeat: 'no-repeat',
          background: `${theme.palette.text.lightBlue2} url(${SIPDashboard}) no-repeat right 10% center`,

          padding: '35px 35px',
          [theme.breakpoints.down('sm')]: {
            background: `${theme.palette.text.lightBlue2}`,
          },
        })}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          columnGap={'16px'}
        >
          <Typography
            variant='h3'
            fontWeight={'700'}
            sx={{ color: 'primary.main' }}
          >
            SIP Dashboard
          </Typography>
          <SIPCoin />
        </Stack>
        <Typography
          variant='subtitle1'
          sx={{ color: 'text.labelColor', maxWidth: '600px', fontWeight: 400, my: 3 }}
        >
          Monitor active, paused, and cancelled SIPs in real time for efficient tracking
          and management of communication sessions.ss
        </Typography>
        <StepperNextButton
          onClick={() => navigate('./sip-dashboard')}
          sx={{ minWidth: '140px', padding: '8px 20px' }}
        >
          Explore SIPs
        </StepperNextButton>
      </Box>
      <Investorcard
        sx={(theme: Theme) => ({
          position: 'relative',
          padding: '30px 20px',
          zIndex: '1',
          transition: 'width 0.2ms',
          cursor: 'pointer',
          height: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          // justifyContent: 'space-between',
          flexDirection: 'column',
        })}
      >
        <TabContext value={tabValue}>
          <Box
            sx={{
              'width': '100%',
              'borderBottom': '1px solid',
              'borderColor': 'text.borderColorLight',
              'mb': 4,
              '& .MuiTabs-root': {
                '& .MuiTabs-flexContainer': {
                  'pl': '0px',
                  'columnGap': '25px',
                  '& .MuiButtonBase-root.MuiTab-root': {
                    'pb': '20px',
                    'fontSize': { xl: '18px', lg: '16px', xs: '14px' },
                    'fontWeight': 500,
                    '&.Mui-disabled': {
                      color: 'rgba(108, 115, 127, 0.50)',
                    },
                  },
                },
                '& .MuiTabs-indicator': {
                  height: '5px',
                  borderRadius: '100px 100px 0px 0px',
                },
              },
              '& .MuiTabPanel-root': {
                padding: '50px 50px',
              },
            }}
          >
            <TabList
              onChange={handleTabChange}
              aria-label='lab API tabs example'
            >
              <Tab
                label='AUM Summary'
                value='1'
              />
              <Tab
                label='Frequent Reports '
                value='2'
              />
            </TabList>
          </Box>
        </TabContext>
        {tabValue === '1' ? (
          <AumSummary />
        ) : (
          <Grid
            container
            spacing={2}
          >
            {reports.map((report, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  alignItems={'stretch'}
                >
                  <Investorcard
                    sx={(theme: Theme) => ({
                      'position': 'relative',
                      'padding': '30px 20px',
                      'zIndex': '1',
                      'transition': 'width 0.2ms',
                      'cursor': 'pointer',
                      'height': '100%',
                      'display': 'flex',
                      'alignItems': 'center',
                      'justifyContent': 'space-between',
                      '&:before': {
                        content: `' '`,
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        width: '0%',
                        height: '100%',
                        backgroundColor: 'rgba(111, 229, 232, 0.251)',
                        transition: '.4s',
                        zIndex: '1',
                        borderRadius: '48px 15px 15px 48px',
                      },
                      '&:hover': {
                        '& .MuiTypography-root': {
                          color: 'primary.main',
                        },
                        '&:before': {
                          width: '33%',
                          [theme.breakpoints.up(1920)]: {
                            width: '25%',
                          },
                        },
                        '& .MuiButtonBase-root': {
                          'bgcolor': 'unset',
                          '&:before': {
                            left: '0px',
                            bottom: 0,
                            top: 0,
                            right: 0,
                            width: '100%',
                          },
                          '& path': {
                            stroke: 'white',
                            zIndex: 3,
                          },
                        },
                      },
                    })}
                  >
                    <Typography variant='subtitle2'>{report}</Typography>
                    <IconButton
                      sx={(theme: Theme) => ({
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'borderRadius': '38px',
                        'border': '1px solid',
                        'borderColor': 'text.borderColorLight',
                        'padding': '11.5px 26px',
                        'zIndex': '1',
                        'transition': 'width 0.2ms', // Add transition for smooth effect
                        '& svg': {
                          position: 'absolute',
                          zIndex: 3,
                        },
                        '&:before': {
                          content: `' '`,
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          right: '0px',
                          bottom: '0px',
                          width: '0%',
                          height: '100%',
                          background: theme.palette.background.primaryLinearGradient2,
                          transition: '.4s',
                          zIndex: '1',
                          borderRadius: '38px',
                        },
                      })}
                    >
                      <RightArrowIcon />
                    </IconButton>
                  </Investorcard>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Investorcard>
    </>
  );
};
