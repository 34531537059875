import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { PageNavigator } from '../../common/BackToHomePage';
import CollapsibleContainer from '../../common/CollapsibleContainer';
import InvestorProfile from '../../common/InvestorProfile';
import SearchInputBox from '../../common/SearchBox';
import { useRootContext } from '../../data/root.context';
import { CommonTable } from '../../transactionDashboard/investorTable';
import { createConvertedOutputArray, rowCreationForSchemes } from '../common';
import { useListOfSchemesMutation } from '../slice';
import {
  investorSchemeDetailsColumns,
  RowData,
  Scheme,
  TransactionAllow,
} from '../types';
const SystematicWithdrawalPlan = () => {
  const navigate = useNavigate();
  const pan: string = useSelector((state: any) => state.transactions.pan);

  const [investorSchemes, setInvestorSchemes] = useState<Scheme[]>([]);
  const { showToast } = useRootContext();
  const [investorDetailsList] = useListOfSchemesMutation();
  const continueButtonHandler = (row: any) => {
    navigate('./investment-details', {
      state: {
        previousURL: 'swp',
        schemeDetails: row,
      },
    });
  };
  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      const payload = {
        pan: pan, // From redux.
      };
      try {
        const response = await investorDetailsList(payload);

        if ('data' in response) {
          setInvestorSchemes(response.data);
        } else {
          showToast(`Error fetching investor details: ${response.error}`, 'error');
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    }

    getInvestorDetailsSchemes();
  }, []);
  const outputArray: RowData[] = rowCreationForSchemes(investorSchemes);

  return (
    <>
      <PageNavigator
        title='SWP'
        subtitle='Please select the scheme you want to make SWP'
      />
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={9}
        >
          <CommonTable
            rows={createConvertedOutputArray(
              investorSchemes?.map((item: any) => ({
                fundName: item.amcName,
                schemeDesc: item.schemeName,
                folioNo: item.folio,
                availableAmount: (item.nav * item.units).toFixed(2),
                balanceUnits: item.units,
                exitLoad: '1', //TODO: replace with exit load
                taxSaverFlag: item.taxsaverFlag,
                ...item,
              })),
              TransactionAllow['SWP'],
            )} //TODO : optimise this line
            columns={Object.values(investorSchemeDetailsColumns) ?? []}
            start={0} // Starting page indexx
            limit={10} // Limit given
            searchPlaceholder={'Search by Name'}
            continueButtonHandler={continueButtonHandler}
            columnsToShow={Object.keys(investorSchemeDetailsColumns)}
            keyToCheck={TransactionAllow['SWP']}
          />
          <SearchInputBox />
          {/* //TODO for NCT */}
          <CollapsibleContainer
            rows={[]}
            columnsToShow={[]}
            continueButtonHandler={continueButtonHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{ display: { xs: 'none', md: 'unset' } }}
        >
          <InvestorProfile />
        </Grid>
      </Grid>
    </>
  );
};

// TODO: make use of the below UI
{
  /* <Drawer
anchor='bottom'
open={drawerOpen}
onClose={handleDrawerClose}
sx={{
  '& .MuiPaper-root ': {
    borderRadius: '25px 25px 0px 0px',
    backgroundColor: 'common.white',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
}}
>
<Box role='presentation'>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '10px 25px ',
    }}
  >
    <Typography sx={{ color: '#111927', fontSize: '14px', fontWeight: 500 }}>
      SWP Scheme
    </Typography>
    <IconButton
      onClick={handleDrawerClose}
      sx={{ p: '0px' }}
    >
      <CloseIcon />
    </IconButton>
  </Box>
  <List sx={{ borderTop: '1px solid #EEE' }}>
    <ListItem
      sx={{
        p: '10px 25px',
        // '&:not(:last-child)': {
        //   borderBottom: '1px solid',
        borderColor: 'text.borderColorDark',
        // },
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Stack
        rowGap={'8px'}
        mb={'16px'}
      >
        <DetailsHeader>Scheme</DetailsHeader>
        <DetailsValue>Axis Growth Opportunities Fund Direct Growth</DetailsValue>
      </Stack>
      <Stack
        rowGap={'8px'}
        mb={'16px'}
      >
        <Detailslabel>Folio Number</Detailslabel>
        <DetailsValue>12345678</DetailsValue>
      </Stack>
      <Stack
        rowGap={'8px'}
        mb={'16px'}
      >
        <Detailslabel>Total Balance Units</Detailslabel>
        <DetailsValue>87.563</DetailsValue>
      </Stack>
      <Stack
        rowGap={'8px'}
        mb={'16px'}
      >
        <Detailslabel>87.563</Detailslabel>
        <DetailsValue>₹ 90,000</DetailsValue>
      </Stack>
      <Stack
        rowGap={'8px'}
        mb={'16px'}
      >
        <Stack
          flexDirection={'row'}
          columnGap={'5px'}
          alignItems={'center'}
        >
          <Detailslabel>Exit load by fund house</Detailslabel>
          <img
            src={InfoIcon}
            alt='info-icon'
            width={'12px'}
          />
        </Stack>

        <DetailsValue>Nil</DetailsValue>
      </Stack>
    </ListItem>
  </List>
</Box>
</Drawer> */
}

export default SystematicWithdrawalPlan;
