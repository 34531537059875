import {
  Box,
  Button,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
} from '@mui/material';
import React, { FunctionComponent } from 'react';

import { ReactComponent as DeleteIcon } from '../../../../assets/delete-red.svg';
import { DetailsHeader } from '../../../auth/login/styles/styledComponents';
import { CustomizedCheckbox } from '../../../common/checkbox';

interface TableCollapsableFilterProps {
  open: boolean;
}

const TableCollapsableFilter: FunctionComponent<TableCollapsableFilterProps> = ({
  open,
}) => {
  const [backgroundColor, setBackgroundColor] = React.useState('common.white');

  const handleChipClick = () => {
    setBackgroundColor('#2057A6');
  };

  const handleDelete = () => {
    //TODO: add delete functionality
  };

  return (
    <Box sx={{ padding: open ? '10px 20px' : '0px' }}>
      <Collapse
        in={open}
        timeout='auto'
        unmountOnExit
        sx={{
          borderTop: '0.3px solid #cdcdcd',
          px: 2.5,
          py: 1.5,
          transitionDuration: '200s',
        }}
      >
        <Box>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <DetailsHeader
              sx={{
                '&.MuiTypography-root': {
                  fontSize: '16px',
                },
              }}
            >
              Fund House
            </DetailsHeader>
            <Stack
              direction='row'
              alignItems='center'
              gap='5px'
            >
              <Button
                sx={{ color: '#FF5630', fontSize: '14px', fontWeight: 500 }}
                startIcon={<DeleteIcon />}
              >
                Clear All
              </Button>
            </Stack>
          </Stack>

          <FormGroup row>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                sm={4}
              >
                <FormControlLabel
                  control={<CustomizedCheckbox />}
                  label='360 ONE Mutal Fund'
                />
              </Grid>
              <Grid
                item
                sm={4}
              >
                <FormControlLabel
                  control={<CustomizedCheckbox />}
                  label='Aditya Birla Sun Life Mutual Fund'
                />
              </Grid>
              <Grid
                item
                sm={4}
              >
                <FormControlLabel
                  control={<CustomizedCheckbox />}
                  label='Axis Mutual Fund'
                />
              </Grid>
            </Grid>
          </FormGroup>
          <Box
            sx={{
              borderRadius: '10px',
              border: '1px dashed #cdcdcd',
              padding: '20px',
              my: 1,
            }}
          >
            {[1, 2, 3].map((chips, index) => {
              return (
                <Chip
                  key={chips}
                  sx={{
                    '&.MuiButtonBase-root': {
                      'borderRadius': '7px',
                      'backgroundColor': backgroundColor,
                      'display': 'inline-flex',
                      'padding': '8px 10px',
                      'marginRight': '10px',
                      'justifyContent': 'center',
                      'aligntems': 'center',
                      'gap': '5px',
                      'minWidth': 'max-content',
                      'transition': 'all 300ms',
                      '& .MuiChip-label': {
                        color: 'text.inputLabelText',
                        fontSize: '15px',
                        fontWeight: 500,
                      },

                      '&:hover': {
                        'backgroundColor': 'primary.main',
                        '& .MuiSvgIcon-root ': {
                          color: ' common.whiteBF',
                        },
                        '& .MuiChip-label': {
                          color: 'common.white',
                          fontSize: '15px',
                          fontWeight: 500,
                        },
                      },
                    },
                  }}
                  label='Axis Mutual Fund'
                  variant='outlined'
                  onClick={handleChipClick}
                  onDelete={handleDelete}
                />
              );
            })}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default TableCollapsableFilter;
