import { TabContext, TabList } from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  Stack,
  Tab,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import VerifiedIcon from '../../../assets/Portfolio/checked-icon.svg';
import EmailIcon from '../../../assets/Portfolio/email-icon.svg';
import Gain_Loss from '../../../assets/Portfolio/gain-loss-rounded.svg';
import Invested_Value from '../../../assets/Portfolio/invested-value-rounded.svg';
import Market_value from '../../../assets/Portfolio/market-value-rounded.svg';
import PhoneIcon from '../../../assets/Portfolio/phone-icon.svg';
import UserIcon from '../../../assets/Portfolio/portfolio-user-icon.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/Portfolio/reports-icon.svg';
import { ReactComponent as TransactIcon } from '../../../assets/Portfolio/transact-icon.svg';
import { ReactComponent as SearchIconMobile } from '../../../assets/search-icon-mobile.svg';

interface RowData {
  SchemeName: any;
  ContributionAmount: any;
  CurrentNAV: any;
  BalanceUnits: any;
  CurrentValuation: string;
}

import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import {
  Detailslabel,
  // Investorcard,
  SubHeader,
  SubTitle,
} from '../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../common/BackToHomePage';
import DataTable from '../../common/DataTable';
import { useRootContext } from '../../data/root.context';
// import SearchInputBox from '../../common/SearchBox';
import { SearchBox, SearchIconContainer } from '../transactionDashboard/investorTable';
import {
  useInvestmentDetailsMutation,
  useServiceRequestDetailsMutation,
  useTransactionDetailsMutation,
} from '../transactionDashboard/slice';

const MFSTabList = ['Investments', 'Portfolio', 'Transactions', 'ServiceRequest'];
const NPSTabList = [
  'Tier 1 Investments',
  'Tier 2 Investments',
  'Transactions',
  'ServiceRequest',
];

function getKeysOfFirstObject(arr: any[]): string[] {
  if (arr.length > 0) {
    const firstObject = arr[0];
    let keys = Object.keys(firstObject);

    return keys;
  }
  return [];
}

export default function InvestorPortfolio() {
  const [tabValue, setTabValue] = React.useState('1');
  const location = useLocation();
  const { investor, investorPortfolio, portfolioSummary, type, percentageGainLoss } =
    location.state;
  const [rows, setRows] = React.useState<RowData[]>([]);
  const [filteredRows, setFilteredRows] = React.useState<any[]>([]);
  const tabList = type === 'MFS' ? MFSTabList : NPSTabList;
  const [getinvestmentDetails] = useInvestmentDetailsMutation();
  const [getTransactionDetails] = useTransactionDetailsMutation();
  const [getServiceRequestDetails] = useServiceRequestDetailsMutation();
  const [tableData, setTableData] = React.useState([{}]);
  const { showToast } = useRootContext();

  useEffect(() => {
    function CreateRowData() {
      const tier1Schemes = investorPortfolio['Tier-1-Schemes'] || [];
      const tier2Schemes = investorPortfolio['Tier-2-Schemes'] || [];

      const allSchemes = [...tier1Schemes, ...tier2Schemes];
      investorPortfolio['All-Schemes'] = allSchemes;

      const rowData = allSchemes.map(scheme => ({
        SchemeName: scheme.SchemeName,
        ContributionAmount: scheme.CurrentAmount,
        CurrentNAV: scheme.CurrentNav,
        BalanceUnits: scheme.BalanceUnits,
        CurrentValuation: '234455', //todo
      }));

      setRows(rowData);

      const filteredRows = rowData.filter(
        scheme =>
          scheme.SchemeName.includes('Tier I') && !scheme.SchemeName.includes('Tier II'),
      );
      setFilteredRows(filteredRows);
    }

    async function fetchInvestmentDetails() {
      try {
        const res = await getinvestmentDetails({
          pan: investor.pan,
        }).unwrap();
        setTableData(res);
      } catch (error: any) {
        const errorMessage =
          error.data && error.data.message ? error.data.message : 'Unknown error';
        showToast(errorMessage, 'error');
      }
    }
    if (investorPortfolio && Object.keys(investorPortfolio).length && type === 'NPS') {
      CreateRowData();
    } else if (portfolioSummary && type === 'MFS') {
      fetchInvestmentDetails();
    }
  }, [investorPortfolio]);

  const handleTabChange = async (event: React.SyntheticEvent, newValue: string) => {
    try {
      const numericValue = Number(newValue);

      switch (type) {
        case 'NPS':
          switch (numericValue) {
            case 1: {
              // Filter Tier-1 schemes
              setFilteredRows(
                rows.filter(
                  (scheme: { SchemeName: string }) =>
                    scheme.SchemeName.includes('Tier I') &&
                    !scheme.SchemeName.includes('Tier II'),
                ),
              );
              break;
            }

            case 2: {
              // Filter Tier-2 schemes
              setFilteredRows(
                rows.filter((scheme: { SchemeName: string }) =>
                  scheme.SchemeName.includes('Tier II'),
                ),
              );
              break;
            }

            case 3: {
              // Return a combination of Tier-1 and Tier-2 schemes
              setFilteredRows(rows);
              break;
            }

            default: {
              setFilteredRows([]); // Set to empty array if no matching case
              break;
            }
          }
          break;

        default:
          switch (numericValue) {
            case 1: {
              const investmentDetails = await getinvestmentDetails({
                pan: investor.pan,
              }).unwrap();
              setTableData(investmentDetails);
              break;
            }

            case 3: {
              const transactionDetails = await getTransactionDetails({
                pan: investor.pan,
              }).unwrap();
              setTableData(transactionDetails);
              break;
            }

            case 4: {
              const serviceRequestDetails = await getServiceRequestDetails({
                pan: investor.pan,
              }).unwrap();
              setTableData(serviceRequestDetails);
              break;
            }

            default: {
              // Handle unexpected case, if necessary
              break;
            }
          }
          break;
      }

      setTabValue(newValue);
    } catch (error: any) {
      const errorMessage =
        error.data && error.data.message ? error.data.message : 'Unknown error';
      showToast(errorMessage, 'error');
    }
  };

  const filterTabs = (
    <>
      <TabContext value={tabValue}>
        <Box
          sx={{
            'borderBottom': '1px solid',
            'borderColor': 'text.borderColorLight',
            'mb': 3,
            '& .MuiTabs-root': {
              '& .MuiTabs-flexContainer': {
                'pl': '0px',
                'columnGap': '25px',
                '& .MuiButtonBase-root.MuiTab-root': {
                  // maxWidth: '60px',
                  // minWidth: '60px',
                  '&.Mui-disabled': {
                    color: 'rgba(108, 115, 127, 0.50)',
                  },
                },
              },
              '& .MuiTabs-indicator': {},
            },
            '& .MuiTabPanel-root': {
              padding: '50px 50px',
            },
          }}
        >
          <TabList
            onChange={handleTabChange}
            aria-label='lab API tabs example'
          >
            {tabList.map((tab, index) => (
              <Tab
                key={tab}
                label={tab}
                value={Number(index + 1)}
              />
            ))}
          </TabList>
        </Box>
      </TabContext>
      <SearchBox>
        <SearchIconContainer>
          <SearchIconMobile />
        </SearchIconContainer>
        <TextField
          id='filled-basic'
          variant='filled'
          placeholder='Search'
          fullWidth
          sx={{
            '& .MuiInputBase-root ': {
              'color': 'inherit',
              'backgroundColor': 'common.white',
              'borderTopLeftRadius': '8px',
              'borderTopRightRadius': '8px',
              '&:hover,&::after,&::before': {
                borderBottom: 'unset',
              },
              '& .MuiInputBase-input': {
                padding: '15px 12px 15px 40px',
                backgroundColor: 'common.white',
                borderRadius: '5px',
                fontWeight: '500',
                fontSize: '14px',
                color: '#6C737F',
                border: '1px solid #CDCDCD',
              },
            },
          }}
        />
      </SearchBox>
    </>
  );

  const data =
    type === 'MFS'
      ? [
          { name: 'equity', value: 25 },
          { name: 'debt', value: 9.53 },
          { name: 'liquid', value: 37.5 },
          { name: 'Others', value: 28 },
        ]
      : [
          { name: 'equity', value: 25 },
          { name: 'debt', value: 9.53 },
          { name: 'liquid', value: 37.5 },
          { name: 'Others', value: 28 },
        ]; // TODO - remove static data

  const COLORS = ['#ADD8E6', '#D8BFD8', '#FFD700', '#9370DB'];
  //TODO - remove static data
  return (
    <>
      <PageNavigator title='Investor Portfolio' />
      <Box
        sx={{
          backgroundColor: '#EAF8FF',
          padding: { xs: '20px 10px', md: '30px 0px 30px 30px' },
          pr: '0',
          borderRadius: '12px',
          mb: 3,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                columnGap: '25px',
                mb: { xs: 3, md: 5 },
              }}
            >
              <img
                src={UserIcon}
                alt='user-icon'
              />
              <Box>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      my: 1,
                      fontWeight: 500,
                      color: 'primary.main',
                    }}
                  >
                    {investor.name}
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 500, color: 'text.valueColor', mb: 1.75 }}
                  >
                    <Typography
                      component={'span'}
                      sx={{ color: 'text.labelColor' }}
                    >
                      PAN:
                    </Typography>
                    {investor.pan}
                  </Typography>
                </Stack>
                <Stack direction={'row'}>
                  {['KYC', 'FATCA'].map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        'display': 'flex',
                        'backgroundColor': '#22C55E26',
                        'borderRadius': '46px',
                        'padding': '5px 8px',
                        'columnGap': '5px',
                        '&:first-child': {
                          marginRight: '12px',
                        },
                      }}
                    >
                      <img
                        src={VerifiedIcon}
                        alt='verified'
                      />
                      <Typography
                        sx={{ fontWeight: 500, fontSize: { xs: '11px', md: '12px' } }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                columnGap: '12px',
                mb: 2.5,
              }}
            >
              <img
                src={EmailIcon}
                alt='email'
              />
              <Typography sx={{ fontWeight: '500', color: 'text.labelColor' }}>
                {investor?.email || 'NA'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '12px' }}>
              <img
                src={PhoneIcon}
                alt='phone'
              />
              <Typography sx={{ fontWeight: '500', color: 'text.labelColor' }}>
                {investor?.mobile || 'NA'}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
          >
            <Box
              sx={{
                borderLeft: { md: '1px solid' },
                borderTop: { xs: '3px dashed', md: 'unset' },
                borderColor: { xs: 'text.borderColorLight', md: 'text.borderColorLight' },
                padding: { xs: '30px 0px 0px 0px', md: '26px 0px 26px 30px' },
                mt: { xs: '30px', md: 'unset' },
              }}
            >
              <Grid container>
                <Grid
                  item
                  md={9}
                  xs={12}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          columnGap: '12px',
                          alignItems: 'center',
                          mb: 4,
                        }}
                      >
                        <img
                          src={Invested_Value}
                          alt='invested-value'
                        />
                        <Stack>
                          <SubHeader sx={{ display: 'flex' }}>
                            <Detailslabel sx={{ fontSize: '20px' }}>₹</Detailslabel>
                            {type === 'MFS'
                              ? portfolioSummary?.marketValue
                              : investorPortfolio?.totalCurrentValuation || 0}{' '}
                          </SubHeader>
                          <SubTitle sx={{ fontWeight: 500 }}>Invested Value</SubTitle>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          columnGap: '12px',
                          alignItems: 'center',
                          justifyContent: { xs: 'flex-end', md: 'flex-start' },
                        }}
                      >
                        <img
                          src={Gain_Loss}
                          alt='gain-loss'
                        />
                        <Stack>
                          <SubHeader sx={{ color: '#22C55E' }}>
                            {percentageGainLoss}
                          </SubHeader>
                          <SubTitle sx={{ fontWeight: 500 }}>Gain/Loss</SubTitle>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          columnGap: '12px',
                          alignItems: 'center',
                          mb: 4,
                        }}
                      >
                        <img
                          src={Market_value}
                          alt='market-value'
                        />
                        <Stack>
                          <SubHeader sx={{ display: 'flex' }}>
                            <Detailslabel sx={{ fontSize: '20px' }}>₹</Detailslabel>
                            {type === 'MFS'
                              ? portfolioSummary?.marketValue
                              : investorPortfolio?.totalCurrentValuation || 0}{' '}
                          </SubHeader>
                          <SubTitle sx={{ fontWeight: 500 }}>Market Value</SubTitle>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2.5}
                      sx={{ textAlign: { xs: 'center', md: 'unset' } }}
                    >
                      <Box
                        sx={(theme: Theme) => ({
                          borderRadius: '53px',
                          fontSize: { xs: '10px', md: '14px' },
                          fontWeight: 400,
                          minHeight: { md: '34px' },
                          padding: { xs: '5px 5px', md: '4px 12px' },
                          background: theme.palette.background.primaryLinearGradient2,
                          color: 'common.white',
                          minWidth: { xs: '100px', md: '120px' },
                          mb: { xs: 1, md: 2 },
                          textAlign: 'center',
                          display: { xs: 'inline-flex', md: 'flex' },
                          justifyContent: 'center',
                          alignItems: 'center',
                        })}
                      >
                        Absolute
                      </Box>
                      <Typography sx={{ textAlign: 'center' }}>
                        {((Number(investorPortfolio?.totalCurrentValuation) -
                          Number(investorPortfolio?.totalContributionAmount)) /
                          (Number(investorPortfolio?.totalContributionAmount)
                            ? Number(investorPortfolio?.totalContributionAmount)
                            : 1)) *
                          100}
                        {'%'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <Stack
                    sx={{
                      alignItems: 'end',
                      height: '100%',
                      justifyContent: { xs: 'space-between', md: 'center' },
                      gap: { xs: '20px', md: '20px' },
                      flexDirection: { xs: 'row-reverse', md: 'column' },
                    }}
                  >
                    <Button
                      sx={(theme: Theme) => ({
                        background: theme.palette.background.primaryLinearGradient2,
                        borderRadius: { xs: '5px', md: '100px 0px 0px  100px' },
                        padding: '12px 20px',
                        minWidth: { md: '175px' },
                        justifyContent: { xs: 'center', md: 'flex-start' },
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: 600,
                        color: 'common.white',
                        flex: { xs: 1, md: 'unset' },
                      })}
                      startIcon={<TransactIcon />}
                    >
                      Transact
                    </Button>
                    <Button
                      sx={{
                        borderRadius: { xs: '5px', md: '100px 0px 0px  100px' },
                        minWidth: { md: '175px' },
                        padding: '12px 20px',
                        justifyContent: { xs: 'center', md: 'flex-start' },
                        border: '1px solid',
                        borderColor: 'primary.main',
                        bgcolor: 'text.boxColor',
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: 600,
                        color: 'primary.main',
                        borderRight: { md: '0px' },
                        flex: { xs: 1, md: 'unset' },
                      }}
                      startIcon={<ReportsIcon />}
                    >
                      Send Reports
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {type === 'NPS' ? (
        <DataTable
          rows={filteredRows}
          columns={[
            'Scheme Name',
            'Contribution Amount',
            'Current NAV',
            'Balance Units',
            'Current Valuation',
          ]}
          onSubmit={() => {}}
          filter={filterTabs}
          hideUpdateButton={true}
          hidecheckbox={true}
        />
      ) : (
        <>
          <DataTable
            rows={Number(tabValue) !== 2 ? tableData : []}
            columns={Number(tabValue) !== 2 ? getKeysOfFirstObject(tableData) : []}
            onSubmit={() => {}}
            filter={filterTabs}
            hideUpdateButton={true}
            hidecheckbox={true}
            hideNoData={true}
          />
          {Number(tabValue) === 2 && (
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '45px' }}>
              <Box>
                <PieChart
                  style={{ width: '200px', height: '200px', margin: '0 auto' }}
                  width={200}
                  height={200}
                >
                  <Pie
                    dataKey='value'
                    data={data}
                    cx='50%'
                    cy='50%'
                    startAngle={90}
                    endAngle={-270}
                    innerRadius={60} // Adjust inner radius to create space in the middle
                    outerRadius={80}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value, name) => [`${name} - ${value} %`, '']} />
                </PieChart>
              </Box>
              <Box>
                {data.map((entry, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '10px',
                      my: 2,
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: COLORS[index as number] || 'red',
                        height: '10px',
                        width: '10px',
                        borderRadius: '50%',
                      }}
                    />
                    <Typography component={'span'}>{entry?.name}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
      {/* Footer */}
    </>
  );
}
