import {
  Avatar,
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import Female from '../../../../assets/female.svg';
// import InfoIcon from '../../../../assets/info-fill.svg';
import InfoIcon from '../../../../assets/info-filled-icon.svg';
import Male from '../../../../assets/male.svg';
import Others from '../../../../assets/others.svg';
import { ReactComponent as CircleAddIcon } from '../../../../assets/rounded-add-icon.svg';
import { DetailsHeader, DetailsValue } from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import Checkbox from '../../../common/checkbox';
import { countryListOptions } from '../../../common/constants';
import { useRootContext } from '../../../data/root.context';
import FormCheckbox from '../../../forms/FormCheckbox';
import FormDateInput from '../../../forms/FormDateInput';
import { FormSelectInput } from '../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import {
  ErrorText,
  StepperAddButton,
  StepperNextButton,
} from '../../../onboarding/styles/styledComponents';
import { relationshipsOptions } from '../../mfs/constants';
import { handleSameAsApplicantAddressChange } from '../../utils';
import {
  setIsNomination,
  setNomineeDetails,
  setStepNumber,
} from '../context/MfsNewPurchaseAction';
import { useMfsNewPurchaseContext } from '../context/MfsNewPurchaseContext';

export interface Nominee {
  isMinor: string;
  name: string;
  pan: string;
  relation: string;
  dob: string;
  email: string;
  sharePercentage: number;
  gender: string;
  address: string;
  city: string;
  state: string;
  pinCode: string;
  country: string;
  guardianName: string;
  guardianPan: string;
  guardianRelation: string;
  guardianGender: string;
  isSameAsApplicant: boolean;
}

interface NomineeFormValues {
  nominees: Nominee[];
}
const gendersList = {
  Male: Male,
  Female: Female,
  Others: Others,
};

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const currentDate = new Date().getDate();

const NomineeForm: React.FC = () => {
  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const {
    dispatch: contextDispatch,
    state: {
      isNomination,
      countriesList,
      nomineeDetails,
      basicDetails,
      holderDetails,
      investorDetailsFormResponse,
      investorDetails,
    },
  } = useMfsNewPurchaseContext();

  const { nominees: contextNominees } = nomineeDetails || {};

  const { personalDetails, nominees: resNominees } = investorDetailsFormResponse || {};
  const { pan: invPan } = personalDetails || {};
  const [holder1 = {}, holder2 = {}] = holderDetails || [];

  const nomineesResponse = resNominees.map((item: any) => ({
    isMinor: 'No',
    name: item.name || '',
    pan: item.pan || '',
    relation: item.relation || '',
    dob: item.dob || '',
    sharePercentage: item.sharePercentage || 0,
    gender: item.gender || '',
    address: item.address1 || '',
    city: item.city || '',
    state: item.state || '',
    pinCode: item.pinCode || '',
    country: item.country || '',
    guardianName: '',
    guardianPan: '',
    guardianRelation: '',
    guardianGender: '',
    isSameAsApplicant: false,
  }));

  const NOMINEE_DETAILS = {
    isMinor: 'No',
    name: '',
    pan: '',
    relation: '',
    dob: '',
    email: '',
    sharePercentage: 0,
    gender: '',
    address: '',
    city: '',
    state: '',
    pinCode: '',
    country: '',
    guardianName: '',
    guardianPan: '',
    guardianRelation: '',
    guardianGender: '',
    isSameAsApplicant: false,
  };

  const nominees =
    contextNominees && contextNominees.length > 0
      ? contextNominees
      : nomineesResponse.length > 0
      ? nomineesResponse
      : [NOMINEE_DETAILS];

  const initialValues: NomineeFormValues = {
    nominees: nominees,
  };

  const validationSchema = Yup.object().shape({
    nominees: Yup.array().of(
      Yup.object().shape({
        isMinor: Yup.string().required('Selecct Yes or No'),
        name: Yup.string().required('Nominee name is required'),
        relation: Yup.string().required('Relationship is required'),
        dob: Yup.date().when('isMinor', {
          is: 'No',
          then: Yup.date()
            .required('Date of birth required')
            .max(
              new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
              'Nominee should be at least 18 years old',
            ),
        }),
        email: Yup.string().matches(
          /^(\w[-._+]{0,1})*\w+@((\w)+[-]{0,1})*(\w)+(\.\w+)+\w$/,
          'email must be a valid email',
        ),
        gender: Yup.string().required('Gender is required'),
        pinCode: Yup.string()
          .matches(/^\d+$/, 'Pincode should be a number')
          .min(6, 'Pincode should be 6 digits')
          .max(6, 'Pincode should be 6 digits')
          .optional(),
        sharePercentage: Yup.number()
          .required('Nominee Percentage required')
          .positive('Nominee Percentage should be greater than 0')
          .max(100, 'Nominee Percentage should not be greater than 100'),
        guardianName: Yup.string().when('isMinor', {
          is: 'Yes',
          then: Yup.string().required('Guardian name is required'),
        }),
        guardianPan: Yup.string().when('isMinor', {
          is: 'Yes',
          then: Yup.string()
            .required('Guardian PAN is required')
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'Invalid PAN'),
        }),
        guardianRelation: Yup.string().when('isMinor', {
          is: 'Yes',
          then: Yup.string().required('Guardian relation with Minor required'),
        }),
        guardianGender: Yup.string().when('isMinor', {
          is: 'Yes',
          then: Yup.string().required('Guardian gender is required'),
        }),
      }),
    ),
  });

  const handleNominationChange = (event: any) => {
    const target = event.target as HTMLInputElement;
    const checked = target.checked;
    contextDispatch(setIsNomination(checked));
    contextDispatch(setNomineeDetails(null));
  };

  const handleUniquePanValidation = (
    panOfNominee: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    index: number,
    values: any,
  ) => {
    setFieldValue(`nominees.${index}.pan`, panOfNominee.toUpperCase());

    const isDuplicate = values.nominees.some((nominee: { pan: string }, i: number) => {
      return i !== index && nominee.pan.toUpperCase() === panOfNominee.toUpperCase();
    });

    const isDuplicateGuardianPan = values.nominees.some((nominee: any) => {
      return (
        nominee.isMinor === 'Yes' &&
        nominee.guardianPan.toUpperCase() === panOfNominee.toUpperCase()
      );
    });

    if (
      (panOfNominee.length === 10 && panOfNominee.toUpperCase() === invPan) ||
      panOfNominee.toUpperCase() === holder1?.pan ||
      panOfNominee.toUpperCase() === holder2?.pan ||
      isDuplicate ||
      isDuplicateGuardianPan
    ) {
      setOpenDialogBox(true);
      setFieldValue(`nominees.${index}.pan`, '');
    }
  };
  const handleUniqueGuardianPanValidation = (
    guardianPan: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    index: number,
    values: any,
  ) => {
    setFieldValue(`nominees.${index}.guardianPan`, guardianPan.toUpperCase());
    const isDuplicateGuardianPan = values.nominees.some((nominee: any, i: number) => {
      return (
        i !== index &&
        nominee.isMinor === 'Yes' &&
        nominee.guardianPan.toUpperCase() === guardianPan.toUpperCase()
      );
    });

    const isDuplicateNomineePan = values.nominees.some((nominee: any, i: number) => {
      return i !== index && nominee.pan.toUpperCase() === guardianPan.toUpperCase();
    });

    if (
      (guardianPan.length === 10 &&
        guardianPan.toUpperCase() === values.nominees[index].pan) ||
      isDuplicateGuardianPan ||
      isDuplicateNomineePan
    ) {
      setOpenDialogBox(true);
      setFieldValue(`nominees.${index}.guardianPan`, '');
    }
  };

  const handleSubmit = (values: NomineeFormValues, formikHelpers: any) => {
    const totalPercentage = values.nominees.reduce(
      (acc, nominee) => acc + Number(nominee.sharePercentage),
      0,
    );

    if (totalPercentage !== 100) {
      showToast(
        'Nominee Share Percentage should not be greater or less than 100',
        'error',
      );
      const { setSubmitting } = formikHelpers;
      setSubmitting(false);
      return;
    }
    contextDispatch(setNomineeDetails(values));
    contextDispatch(setStepNumber(3));
  };

  return (
    <>
      {investorDetails.invType === 'I' ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              borderRadius: '5px',
              bgcolor: 'text.borderColorDark',
              padding: '10px 20px',
              height: '52px',
              mb: 3,
            }}
          >
            <img
              src={InfoIcon}
              alt='info-icon'
            />
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, color: 'text.labelColor' }}
            >
              Investor(s) have the options to choose upto three nominees
            </Typography>
          </Box>

          <FormControlLabel
            sx={{
              'mb': 3,
              '& .MuiTypography-root ': {
                color: 'text.primary',
                fontWeight: '500',
                fontSize: '14px',
              },
            }}
            control={
              <Checkbox
                sx={{
                  'cursor': 'pointer',
                  '&.Mui-checked': {
                    color: 'primary.main',
                  },
                }}
                disableRipple
                checked={isNomination}
                onChange={handleNominationChange}
              />
            }
            label='I wish not to nominate'
          />

          {!isNomination && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, handleChange, setFieldValue, setValues }) => {
                return (
                  <Form>
                    <FieldArray name='nominees'>
                      {({ insert, remove, push }) => (
                        <>
                          {values?.nominees?.map((nominee, index) => {
                            const genderError =
                              errors.nominees && errors.nominees?.[index];
                            const isTouched =
                              touched.nominees && touched.nominees?.[index];
                            return (
                              <Grid
                                container
                                spacing={2}
                                mb='10px'
                                key={index}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  mb={2}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      borderRadius: '5px',
                                      bgcolor: 'text.boxColorLight',
                                      padding: '10px 20px',
                                      height: '52px',
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle1'
                                      sx={{ fontWeight: 600 }}
                                    >
                                      Nominee {index + 1}
                                    </Typography>
                                    {values.nominees.length > 1 && (
                                      <IconButton
                                        sx={{ float: 'right' }}
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <DetailsHeader sx={{ fontSize: '16px' }}>
                                    Enter details of Nominee {index + 1}
                                  </DetailsHeader>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  mt={1}
                                  mb={2}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontWeight: 500,
                                      color: 'text.primary',
                                      mb: 2,
                                    }}
                                  >
                                    Is Nominee a Minor
                                  </Typography>
                                  <FormStyledRadioButton
                                    options={[
                                      { label: 'Yes', value: 'Yes' },
                                      { label: 'No', value: 'No' },
                                    ]}
                                    name={`nominees.${index}.isMinor`}
                                  />
                                </Grid>
                                {values.nominees[index].isMinor === 'Yes' && (
                                  <Grid
                                    item
                                    xs={12}
                                    mb={2}
                                  >
                                    <Box
                                      sx={{
                                        borderRadius: '5px',
                                        padding: '16px 20px',
                                        mt: 2,
                                        background:
                                          'linear-gradient(90deg, #EEE 0%, rgba(255, 255, 255, 0.00) 100%)',
                                      }}
                                    >
                                      <Typography
                                        variant='subtitle1'
                                        sx={{ fontWeight: 600 }}
                                      >
                                        Minor Details
                                      </Typography>
                                    </Box>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name={`nominees.${index}.name`}
                                    label='Nominee Name'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name={`nominees.${index}.pan`}
                                    label='Nominee PAN'
                                    onChange={e => {
                                      handleUniquePanValidation(
                                        e.target.value,
                                        setFieldValue,
                                        index,
                                        values,
                                      );
                                    }}
                                    required={false}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormSelectInput
                                    name={`nominees.${index}.relation`}
                                    label='Relationship'
                                    required
                                    options={relationshipsOptions}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormDateInput
                                    name={`nominees.${index}.dob`}
                                    label='Date of Birth'
                                    disableFuture
                                    required
                                    minDate={
                                      values?.nominees?.[index]?.isMinor === 'No'
                                        ? new Date(1900, 0, 1)
                                        : new Date(
                                            currentYear - 18,
                                            currentMonth,
                                            currentDate,
                                          )
                                    }
                                    maxDate={
                                      values?.nominees?.[index]?.isMinor === 'No'
                                        ? new Date(
                                            currentYear - 18,
                                            currentMonth,
                                            currentDate,
                                          )
                                        : new Date()
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    required={false}
                                    name={`nominees.${index}.email`}
                                    label='Email ID'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name={`nominees.${index}.sharePercentage`}
                                    label='Nominee %'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ mb: 2 }}
                                >
                                  <Typography sx={{ mb: 2, mt: 1 }}>Gender</Typography>
                                  <Stack
                                    direction='row'
                                    spacing={1}
                                    sx={{
                                      '& .MuiButtonBase-root.MuiChip-root': {
                                        'padding': '10px 5px',
                                        'borderRadius': '44px',
                                        '& .MuiChip-label': {
                                          fontSize: '14px',
                                          fontWeight: 500,
                                        },
                                      },
                                    }}
                                  >
                                    {Object.keys(gendersList)?.map(gender => (
                                      <Chip
                                        key={gender}
                                        avatar={
                                          <Avatar
                                            alt={gender.toLowerCase()}
                                            src={
                                              (gendersList as Record<string, string>)[
                                                gender
                                              ]
                                            }
                                          />
                                        }
                                        label={gender}
                                        onClick={() => {
                                          setFieldValue(
                                            `nominees.${index}.gender`,
                                            gender,
                                          );
                                        }}
                                        variant='outlined'
                                        sx={{
                                          'border': '1px solid',
                                          'borderColor':
                                            values.nominees[index].gender === gender
                                              ? 'text.navyBlue'
                                              : 'text.borderColorLight',
                                          'backgroundColor':
                                            values.nominees[index].gender === gender
                                              ? 'text.boxColorLight'
                                              : 'common.white',
                                          '& .MuiChip-label': {
                                            color:
                                              values.nominees[index].gender === gender
                                                ? 'primary.main'
                                                : 'text.primary',
                                          },
                                        }}
                                      />
                                    ))}
                                  </Stack>
                                  <ErrorText>
                                    {isTouched &&
                                      isTouched['gender' as keyof typeof isTouched] &&
                                      genderError &&
                                      genderError['gender' as keyof typeof genderError]}
                                  </ErrorText>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <DetailsValue sx={{ fontWeight: 600 }}>
                                    Address
                                  </DetailsValue>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormCheckbox
                                    name={`nominees.${index}.isSameAsApplicant`}
                                    label='Same as Applicant'
                                    margin='dense'
                                    checked={values?.nominees[index]?.isSameAsApplicant}
                                    onChange={e => {
                                      handleSameAsApplicantAddressChange(
                                        e,
                                        setFieldValue,
                                        index,
                                        personalDetails,
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    required={false}
                                    name={`nominees.${index}.address`}
                                    label='Address'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    required={false}
                                    name={`nominees.${index}.city`}
                                    label='City'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    required={false}
                                    name={`nominees.${index}.state`}
                                    label='State'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    required={false}
                                    name={`nominees.${index}.pinCode`}
                                    label='Pincode'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormSelectInput
                                    required={false}
                                    name={`nominees.${index}.country`}
                                    label='Country'
                                    defaultValue={values?.nominees[index]?.country}
                                    options={
                                      countriesList?.length !== 0
                                        ? countriesList
                                        : countryListOptions?.map((country: any) => ({
                                            value: country.country_name,
                                            label: country.country_name,
                                          }))
                                    }
                                  />
                                </Grid>
                                {values.nominees[index].isMinor === 'Yes' && (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      mb={2}
                                    >
                                      <Box
                                        sx={{
                                          borderRadius: '5px',
                                          padding: '16px 20px',
                                          mt: 2,
                                          background:
                                            'linear-gradient(90deg, #EEE 0%, rgba(255, 255, 255, 0.00) 100%)',
                                        }}
                                      >
                                        <Typography
                                          variant='subtitle1'
                                          sx={{ fontWeight: 600 }}
                                        >
                                          Guardian Details
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <FormTextInput
                                        name={`nominees.${index}.guardianName`}
                                        label='Guardian Name'
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <FormTextInput
                                        name={`nominees.${index}.guardianPan`}
                                        label='Guardian PAN'
                                        onChange={e => {
                                          handleUniqueGuardianPanValidation(
                                            e.target.value,
                                            setFieldValue,
                                            index,
                                            values,
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <FormSelectInput
                                        name={`nominees.${index}.guardianRelation`}
                                        label='Relationship with Minor'
                                        required
                                        options={[
                                          {
                                            label: 'Father',
                                            value: 'Father',
                                          },
                                          {
                                            label: 'Mother',
                                            value: 'Mother',
                                          },
                                          {
                                            label: 'Brother',
                                            value: 'Brother',
                                          },
                                          {
                                            label: 'Sister',
                                            value: 'Sister',
                                          },
                                        ]}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{ mb: 2 }}
                                    >
                                      <Typography sx={{ mb: 2, mt: 1 }}>
                                        Gender
                                      </Typography>
                                      <Stack
                                        direction='row'
                                        spacing={1}
                                        sx={{
                                          '& .MuiButtonBase-root.MuiChip-root': {
                                            'padding': '10px 5px',
                                            '& .MuiChip-label': {
                                              fontSize: '14px',
                                              fontWeight: 500,
                                              color: 'text.primary',
                                            },
                                          },
                                        }}
                                      >
                                        {Object.keys(gendersList)?.map(item => (
                                          <Chip
                                            key={item}
                                            avatar={
                                              <Avatar
                                                alt={item.toLowerCase()}
                                                src={
                                                  (gendersList as Record<string, string>)[
                                                    item
                                                  ]
                                                }
                                              />
                                            }
                                            label={item}
                                            onClick={() => {
                                              setFieldValue(
                                                `nominees.${index}.guardianGender`,
                                                item,
                                              );
                                            }}
                                            variant='outlined'
                                            sx={{
                                              'border': '1px solid',
                                              'borderColor':
                                                values.nominees[index].guardianGender ===
                                                item
                                                  ? 'text.navyBlue'
                                                  : 'text.borderColorLight',
                                              'backgroundColor':
                                                values.nominees[index].guardianGender ===
                                                item
                                                  ? 'text.boxColorLight'
                                                  : 'common.white',
                                              '& .MuiChip-label': {
                                                color:
                                                  values.nominees[index]
                                                    .guardianGender === item
                                                    ? 'primary.main'
                                                    : 'text.primary',
                                              },
                                            }}
                                          />
                                        ))}
                                      </Stack>
                                      <ErrorText>
                                        {isTouched &&
                                          isTouched[
                                            'guardianGender' as keyof typeof isTouched
                                          ] &&
                                          genderError &&
                                          genderError[
                                            'guardianGender' as keyof typeof genderError
                                          ]}
                                      </ErrorText>
                                    </Grid>
                                  </>
                                )}
                                {values?.nominees?.length === 1 ? (
                                  <Grid
                                    item
                                    xs={12}
                                    display='flex'
                                    justifyContent='center'
                                    mb={2}
                                    mt={3}
                                  >
                                    {values?.nominees?.length < 3 && (
                                      <StepperAddButton
                                        startIcon={<CircleAddIcon />}
                                        onClick={() => {
                                          const percentage =
                                            100 -
                                            values?.nominees?.reduce(
                                              (acc: number, nominee: any) =>
                                                acc + Number(nominee?.sharePercentage),
                                              0,
                                            );
                                          push({
                                            ...NOMINEE_DETAILS,
                                            sharePercentage:
                                              percentage > 0 ? percentage : 0,
                                          });
                                        }}
                                      >
                                        Add Another Nominee
                                      </StepperAddButton>
                                    )}
                                  </Grid>
                                ) : (
                                  values?.nominees?.length > 1 &&
                                  values?.nominees?.length === index + 1 && (
                                    <Grid
                                      item
                                      xs={12}
                                      display='flex'
                                      justifyContent='center'
                                      mb={2}
                                      mt={3}
                                    >
                                      {values?.nominees?.length < 3 && (
                                        <StepperAddButton
                                          startIcon={<CircleAddIcon />}
                                          onClick={() => {
                                            const percentage =
                                              100 -
                                              values.nominees.reduce(
                                                (acc: number, nominee: any) =>
                                                  acc + Number(nominee.sharePercentage),
                                                0,
                                              );
                                            push({
                                              ...NOMINEE_DETAILS,
                                              sharePercentage:
                                                percentage > 0 ? percentage : 0,
                                            });
                                          }}
                                        >
                                          Add Another Nominee
                                        </StepperAddButton>
                                      )}
                                    </Grid>
                                  )
                                )}
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <Divider
                                    orientation='horizontal'
                                    variant='fullWidth'
                                    flexItem
                                    sx={{
                                      border: '1px dashed',
                                      borderColor: 'text.borderColorLight',
                                      mt: 1,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                        </>
                      )}
                    </FieldArray>
                    <SubmitButton
                      sx={{
                        '&.MuiButtonBase-root': {
                          borderRadius: '5px',
                          minWidth: { xs: '100%', sm: '200px' },
                          mt: 5,
                        },
                      }}
                      label='Proceed'
                    >
                      Proceed
                    </SubmitButton>
                  </Form>
                );
              }}
            </Formik>
          )}
        </>
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          mt={4}
          mb={3}
        >
          Nominee section is disabled for the Minors
        </Box>
      )}
      {(isNomination || investorDetails.invType === 'M') && (
        <Grid
          item
          xs={12}
          mb={2}
        >
          <StepperNextButton
            sx={{ minWidth: '200px', borderRadius: '5px' }}
            onClick={() => contextDispatch(setStepNumber(3))}
          >
            Proceed
          </StepperNextButton>
        </Grid>
      )}
      <CustomDialog
        isVisible={openDialogBox}
        secondButtonHandler={() => {
          setOpenDialogBox(false);
        }}
        firstButtonHandler={() => {}}
        firstButtonTitle=''
        secondButtonTitle='Okay'
        handleClose={() => {}}
        showFirstButton={false}
        showSecondButton={true}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'fontSize': '16px',
              'color': 'text.valueColor',
              'fontWeight': '400',
              'opacity': '0.85',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <Typography variant='body1'>
            Nominee pan, Holder pan and Guardian Pan cannot be same
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
};

export default NomineeForm;
