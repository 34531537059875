import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import countriesData from '../../../common/countries-list.json';
import FormCheckbox from '../../../forms/FormCheckbox';
import { FormSelectInput } from '../../../forms/FormSelect';
import StyledRadioButtonGroup from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import Layout from '../../Layout';
import {
  useCreateUpdateFatcaRequestMutation,
  useGetFatcaDetailsMutation,
} from '../../slice';
import KycFields from './KycFields';
import TaxDetails, { TaxDetailsRef } from './TaxDetails';

type COUNTRY_DETAILS = {
  code: string;
  dial_code: string;
  name: string;
};

type COUNTRIES = {
  countries: COUNTRY_DETAILS[];
};

export const FatcaInitialValues = {
  taxStatus: '',
  taxResidencyOtherthanIndia: 'N',
  addressType: '',
  placeOfBirth: '',
  countryOfBirth: '',
  nationality: '',
  annualIncomeSlab: '',
  occupation: '',
  pepFlag: '',
  netWorth: '',
  netWorthDate: '',
  sourceOfWealth: '',
  sourceOfFunds: '',
  fatherName: '',
  spouseName: '',
  corporateServices: '',
  fatcaCheckFlag: '',
  declaration: false,
  lastUpdatedDate: '',
  taxResidency: [
    {
      country: '',
      taxId: '',
      idType: '',
      tinReason: '',
      tinRemarks: '',
    },
  ],
};

const taxResidencySchema = Yup.object().shape({
  country: Yup.string().required('Country is required'),
  taxId: Yup.string().when('tinNotAvailable', {
    is: (tinNotAvailable: boolean) => !tinNotAvailable,
    then: Yup.string().required('Tax ID is required'),
    otherwise: Yup.string(),
  }),
  tinRemarks: Yup.string().when(['tinNotAvailable', 'tinIdType'], {
    is: (tinNotAvailable: boolean, tinIdType: string) =>
      !tinNotAvailable && tinIdType === 'others',
    then: Yup.string().required('Type details is required'),
    otherwise: Yup.string(),
  }),
  tinReason: Yup.string().when(['tinNotAvailable', 'reasonIndex'], {
    is: (tinNotAvailable: boolean, reasonIndex: string) =>
      tinNotAvailable && reasonIndex === '2',
    then: Yup.string().required('Reason is required'),
    otherwise: Yup.string(),
  }),
});

const validationSchema = Yup.object().shape({
  taxResidencyOtherthanIndia: Yup.string().required('Select Yes/No'),
  taxResidency: Yup.array().when('taxResidencyOtherthanIndia', {
    is: (taxResidencyOtherthanIndia: string) => taxResidencyOtherthanIndia === 'Y',
    then: Yup.array().of(taxResidencySchema),
    otherwise: Yup.array().of(Yup.object()),
  }),
  addressType: Yup.string().required('Address type is required'),
  placeOfBirth: Yup.string()
    .required('Place of birth is required')
    .matches(/^[a-zA-Z\s]*$/, 'Place of birth should contain alphabets only'),
  countryOfBirth: Yup.string().when('taxResidencyOtherthanIndia', {
    is: (taxResidencyOtherthanIndia: string) => taxResidencyOtherthanIndia === 'Y',
    then: Yup.string().required('Country is required'),
    otherwise: Yup.string(),
  }),
  nationality: Yup.string().when('taxResidencyOtherthanIndia', {
    is: (taxResidencyOtherthanIndia: string) => taxResidencyOtherthanIndia === 'Y',
    then: Yup.string().required('Nationality is required'),
    otherwise: Yup.string(),
  }),
  annualIncomeSlab: Yup.string().required('Annual income slab is required'),
  occupation: Yup.string().required('Occupation is required'),
  pepFlag: Yup.string().required('Pep status is required'),
  netWorth: Yup.string()
    .required('Net worth is required')
    .matches(/^[0-9]*\.?[0-9]+$/, 'Net worth should be a number'),
  netWorthDate: Yup.date().required('Net worth date is required'),
  sourceOfWealth: Yup.string().required('Source of wealth is required'),
  fatherName: Yup.string()
    .required('Father name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Fathers name should contain alphabets only'),
  spouseName: Yup.string()
    .required('Spouse name is required')
    .matches(/^[a-zA-Z\s]*$/, 'Spouse name should contain alphabets only'),
  declaration: Yup.boolean().oneOf([true], 'Declaration is required'),
});

export default function Fatca() {
  const [getFatcaDetails] = useGetFatcaDetailsMutation();
  const [createUpdateFatcaRequest] = useCreateUpdateFatcaRequestMutation();
  const [formData, setFormData] = useState(FatcaInitialValues);
  const navigate = useNavigate();
  const [requestId, setRequestId] = useState('');

  const countryOptions = countriesData.countries.map(country => ({
    label: country.name,
    value: country.name,
  }));
  const taxDetailsRef = useRef<TaxDetailsRef>(null);

  useEffect(() => {
    let ignore = false;
    async function getDetails() {
      const response: any = await getFatcaDetails({
        requestData: {
          pan: 'AXWPR5906P',
        },
      });
      const fatcaDetails = { ...(response?.data?.response?.data?.[0] ?? {}) };
      if (!ignore && fatcaDetails) {
        delete fatcaDetails['taxResidency'];
        setFormData(prev => ({
          ...prev,
          ...fatcaDetails,
          declaration: false,
          taxResidencyOtherthanIndia:
            fatcaDetails.taxResidencyOtherthanIndia === 'Y' ? 'Y' : 'N',
        }));
      }
    }
    getDetails();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <>
      <Layout title='FATCA/CRS Declaration'>
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            if (
              values.taxResidencyOtherthanIndia === 'Y' &&
              taxDetailsRef.current &&
              !taxDetailsRef.current.checkAllSaved()
            ) {
              //Todo: show all tax ids not saved
              setSubmitting(false);
            }
            const requestData = {
              ...values,
              taxResidency: values.taxResidency.map(
                ({ tinReason, taxId, tinRemarks, country, idType }) => ({
                  tinReason,
                  taxId,
                  tinRemarks,
                  country,
                  idType,
                }),
              ),
            };
            createUpdateFatcaRequest({
              requestData: {
                pan: 'AXWPR5906P',
                data: [
                  {
                    ...requestData,
                    declaration: 'Y',
                    ...(values.taxResidencyOtherthanIndia === 'N'
                      ? { countryOfBirth: 'India' }
                      : {}),
                  },
                ],
              },
            }).then((data: any) => {
              setRequestId(data?.data?.req_id || '');
            });
          }}
        >
          {({ values, setFieldValue, setValues }) => (
            <Form>
              <Investorcard
                sx={theme => ({
                  padding: { xs: '0px', sm: '15px 30px' },
                  [theme.breakpoints.down('md')]: {
                    border: 'unset',
                    boxShadow: 'unset',
                  },
                })}
              >
                <Grid
                  container
                  sx={{ my: 1 }}
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ mb: 3 }}
                  >
                    <FormTextInput
                      name='PAN Number'
                      label='PAN/PEKRN'
                      value='BYWPG3701P'
                      disabled={true}
                    />
                  </Grid>
                </Grid>
                <Typography
                  variant='subtitle2'
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: '500',
                    mb: 2,
                  }}
                >
                  Are you a tax resident of any country other than India?*
                </Typography>
                <StyledRadioButtonGroup
                  options={[
                    { label: 'Yes', value: 'Y' },
                    { label: 'No', value: 'N' },
                  ]}
                  value={values.taxResidencyOtherthanIndia}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const selectedValue = event.target.value;
                    setFieldValue('taxResidencyOtherthanIndia', selectedValue);
                    setFieldValue('taxResidency', [
                      {
                        country: '',
                        taxId: '',
                        idType: '',
                        tinReason: '',
                        tinRemarks: '',
                        tinNotAvailable: false,
                        tinIdType: 'tinNumber',
                        reasonIndex: '0',
                      },
                    ]);
                  }}
                />
                {values.taxResidencyOtherthanIndia === 'Y' ? (
                  <>
                    <Grid
                      container
                      spacing={2}
                      my={2}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <FormSelectInput
                          required={false}
                          name='countryOfBirth'
                          defaultValue={''}
                          label='Country'
                          options={countryOptions}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <FormSelectInput
                          required={false}
                          name='nationality'
                          defaultValue={''}
                          label='Nationality'
                          options={countryOptions}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                      >
                        <FormTextInput
                          name='placeOfBirth'
                          label='Place of Birth'
                        />
                      </Grid>
                    </Grid>

                    <TaxDetails
                      ref={taxDetailsRef}
                      countryOptions={countryOptions}
                      initialValues={values}
                      setValues={setValues}
                    />
                  </>
                ) : (
                  <Grid
                    container
                    spacing={2}
                    my={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                    >
                      <FormSelectInput
                        name='country'
                        label='Country'
                        options={[
                          {
                            label: 'India',
                            value: 'India',
                          },
                        ]}
                        defaultValue='India'
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                    >
                      <FormTextInput
                        name='placeOfBirth'
                        label='Place of Birth'
                      />
                    </Grid>
                  </Grid>
                )}
                <KycFields />
                <Grid
                  item
                  sm={12}
                >
                  <FormCheckbox
                    name='declaration'
                    label='I hereby declare that the information entered by me is true to the best of my knowledge and belief.'
                    margin='dense'
                  />
                </Grid>
                <SubmitButton label='Submit' />
                {/* <StepperNextButton>Submit</StepperNextButton> */}
              </Investorcard>
            </Form>
          )}
        </Formik>
        {!!requestId && (
          <CustomDialog
            isVisible={true}
            firstButtonHandler={() => navigate('../')}
            firstButtonTitle='OK'
            handleClose={() => {}}
          >
            <Box
              sx={{
                'textAlign': 'center',
                'pt': 2,
                '& .MuiTypography-root': {
                  'my': 2,
                  '& >*': {
                    fontWeight: '600',
                  },
                },
              }}
            >
              <img
                src={VerifiedIcon}
                alt='verified-icon'
              />
              <Typography>
                Your request for{' '}
                <Typography component={'span'}>FATCA/CRS Declaration </Typography>is
                created successfully with request id {requestId}
              </Typography>
              <Typography>
                A Link has been sent to the Investor Email ID. After investor authorises
                the transaction, details will be updated after T+1 day.
              </Typography>
            </Box>
          </CustomDialog>
        )}
      </Layout>
    </>
  );
}
