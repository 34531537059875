import { Dispatch } from 'react';

interface Action {
  type: string;
  payload: any;
}

export const setFundName = (body: any): Action => ({
  type: 'SET_FUNDNAME',
  payload: body,
});
export const setInvestmentDetails = (body: any): Action => ({
  type: 'SET_INVESTMENT_DETAILS',
  payload: body,
});

export const setBasicDetails = (body: any): Action => ({
  type: 'SET_BASIC_DETAILS',
  payload: body,
});

export const setKycTrackDetails = (body: any): Action => ({
  type: 'SET_KYC_TRACK_DETAILS',
  payload: body,
});

export const setInvestorDetails = (body: any): Action => ({
  type: 'SET_INVESTOR_DETAILS',
  payload: body,
});

export const setFatcaDetails = (body: any): Action => ({
  type: 'SET_FATCA_DETAILS',
  payload: body,
});

export const setNomineeDetails = (body: any): Action => ({
  type: 'SET_NOMINEE_DETAILS',
  payload: body,
});

export const setInvestorBankDetails = (body: any): Action => ({
  type: 'SET_INVESTOR_BANK_DETAILS',
  payload: body,
});

export const setCountriesListStateListDetails = (body: any): Action => ({
  type: 'SET_COUNTRIES_DETAILS',
  payload: body,
});

export const setInvestorDetailsFromResponse = (body: any): Action => ({
  type: 'SET_INVESTOR_DETAILS_FORM_RESPONSE',
  payload: body,
});

export const setHolder1DetailsFromResponse = (body: any): Action => ({
  type: 'SET_HOLDER1_DETAILS_FORM_RESPONSE',
  payload: body,
});

export const setSipDates = (body: any): Action => ({
  type: 'SET_SIP_DATES',
  payload: body,
});

export const setStatusBackToSummary = (body: any): Action => ({
  type: 'SET_STATUS_BACK_TO_SUMMARY',
  payload: body,
});

export const setDistrict = (body: any): Action => ({
  type: 'SET_DISTRICT',
  payload: body,
});

export const setStepNumber = (body: any): Action => ({
  type: 'SET_STEP',
  payload: body,
});

export const setIsNomination = (body: any): Action => ({
  type: 'SET_IS_NOMINATION',
  payload: body,
});

export const setSchemeDetails = (body: any): Action => ({
  type: 'SET_SCHEME_DETAILS',
  payload: body,
});

export const setHolderDetails = (body: any): Action => ({
  type: 'SET_HOLDER_DETAILS',
  payload: body,
});
export const setCartState = (body: any): Action => ({
  type: 'SET_CART_STATE',
  payload: body,
});

export const setStatesAndCountries =
  ({ statesList, countriesList }: { statesList: any[]; countriesList: any[] }) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: 'SET_BASIC',
      payload: {
        statesList,
        countriesList,
      },
    });
  };
