import { useNavigate } from 'react-router-dom';

import { SchemeFromAndTo } from '../common/SchemeFromAndTo';

const Switch = () => {
  const navigate = useNavigate();

  function handleContinueButton(data: { fromData: any; toData: any; extraData?: any }) {
    const { fromData, toData, extraData } = data;
    navigate('investment-details', {
      state: { switchOutData: fromData, switchInData: toData, extraData },
    });
  }
  return (
    <SchemeFromAndTo
      type='SWITCH'
      onSubmit={handleContinueButton}
    />
  );
};

export default Switch;
