import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { FileUpload } from '../../common/FileUpload';
interface UploadChequeCopyProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  values: any;
  bankNameList: string[];
  hasAccountValidationFailed: boolean;
  index: number;
}
const UploadChequeCopy: React.FC<UploadChequeCopyProps> = ({
  files,
  setFiles,
  // values,
  // bankNameList,
  hasAccountValidationFailed,
  index,
}) => {
  // const selectedBank = bankNameList.find((item) => item === values.selectedValue);
  const { setFieldError } = useFormikContext();
  useEffect(() => {
    // Clear validation errors when files are selected
    if (hasAccountValidationFailed && files.length > 0) {
      setFieldError(`bankList.${index}.accountNumber`, ''); // Replace 'accountNumber' with the actual field names
      setFieldError(`bankList.${index}.verifiedAccountNumber`, ''); // Replace 'verifiedAccountNumber' with the actual field names
      // setFieldError('ifsc', ''); // Replace 'ifsc' with the actual field names
    }
  }, [files]);
  return (
    <>
      <Typography sx={{ color: '#212b36', fontSize: '16px', fontWeight: 500, mb: 2 }}>
        Please Provide the cancelled cheque copy
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <FileUpload
            key={0}
            index={0}
            files={files}
            setFiles={setFiles}
            fileName={''}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UploadChequeCopy;
