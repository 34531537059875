import apiSlice from '../../../service/api';

const swpSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSWPwithdrawalType: builder.mutation({
      query: payload => ({
        url: '/user/transactions/swp/get-swp-withdrawal-type',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useGetSWPwithdrawalTypeMutation } = swpSlice;
