export const dateToMMDDYYYY = (dateString: any): string => {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString();

  return `${month}/${day}/${year}`;
};

export const dateToDDMMYYYY = (dateString: any): string => {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString();

  return `${day}/${month}/${year}`;
};

export function mapFrequencyToLetter(frequency: string) {
  switch (frequency) {
    case 'Daily':
      return 'D';
    case 'Fortnightly':
      return 'F';
    case 'Monthly':
      return 'M';
    case 'Quarterly':
      return 'Q';
    case 'Weekly':
      return 'W';
    case 'DailyZip':
      return 'DZ';
    case 'Halfyearly':
      return 'H';
    case 'Yearly':
      return 'Y';
    default:
      return '';
  }
}
export function mapFrequency(frequency: string) {
  switch (frequency) {
    case 'D':
      return 'Daily';

    case 'F':
      return 'Fortnightly';
    case 'M':
      return 'Monthly';
    case 'Q':
      return 'Quarterly';
    case 'W':
      return 'Weekly';
    case 'Y':
      return 'Yearly';
    case 'DZ':
      return 'DailyZip';
    case 'H':
      return 'Halfyearly';
    default:
      return '';
  }
}
