import { Box, Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as BankIcon } from '../../../../assets/bank-icon.svg';
import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import CustomLoader from '../../../common/CustomLoader';
import InvestorProfile from '../../../common/InvestorProfile';
import { useRootContext } from '../../../data/root.context';
import { FormSelectInput } from '../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import { MandateData } from '../../mfs/newInvestorOnboarding/newMfsInvestorSteps/Confirmation';
import {
  useAllMandatesMutation,
  useAllPaymentModesMutation,
  useCheckoutCartMutation,
  useNewPurchaseDirectMutation,
  useNewPurchaseDirectSipMutation,
  useNewPurchaseSipMutation,
} from '../../mfs/newInvestorOnboarding/slice';
import {
  dateToDDMMYYYY,
  dateToMMDDYYYY,
  mapFrequencyToLetter,
} from '../../mfs/newInvestorOnboarding/utils';
import { getEarliestAndLatestSipDates } from '../../utils';
import { useMfsNewPurchaseContext } from '../context/MfsNewPurchaseContext';
import { Nominee } from './NomineeDetails';

interface HolderDetails {
  pan: string;
  name: string;
  email: string;
  mobile: string;
  dob: string;
  gender: string;
  emailFamilyRelation: string;
  mobileFamilyRelation: string;
  category: string;
  nriAddress1?: string;
  nriAddress2?: string;
  nriCity?: string;
  nriState?: string;
  taxResidentOfOtherCountry: string;
  countryOfCitizenship: string;
  countryOfBirth: string;
  grossAnnualIncome: string;
  sourceOfWealth: string;
  occupation: string;
  countryOfTaxResident?: string;
  taxPayerIdentificationNumber?: string;
  politicallyExposedPerson: string;
  politicallyExposedRelative: string;
  jointHolderType: string;
  status: string;
}

export function Payment() {
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [transactionNumber, setTransactionNumber] = useState('');
  const [paymentModeOptions, setPaymnetModeOptions] = useState<any[]>([]);
  const [mandateData, setMandatedata] = useState<MandateData>({ kotmData: [] });
  const [umrnOptions, setUmrnOptions] = useState<any[]>([]);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [selectedMandate, setSelectedMandate] = useState({
    label: '',
    value: '',
    mandateLimit: 0,
    startDate: '',
    endDate: '',
    bankAccountNumber: '',
    bankName: '',
  } as any);

  const {
    dispatch: contextDispatch,
    state: {
      isNomination,
      investmentDetails,
      investorBankDetails,
      investorDetails,
      fundName,
      schemeDetails,
      fatcaDetails,
      holderDetails,
      nomineeDetails,
      investorDetailsFormResponse,
      cartSate,
      sipDates,
    },
  } = useMfsNewPurchaseContext();

  const { personalDetails } = investorDetailsFormResponse;

  const { subBrokerCode } = investmentDetails || {};

  const modeOfTransaction =
    cartSate?.previousUrl === 'cart'
      ? cartSate?.cartItems[0]?.transactionType === 'NPL'
        ? 'Lumpsum'
        : cartSate?.cartItems[0]?.transactionType === 'SINI' &&
          cartSate?.cartItems[0]?.investmentAmount === 0
        ? 'SIP'
        : 'Lumpsum + SIP'
      : investmentDetails?.modeOfTransaction;

  const [newPurchaseDirect] = useNewPurchaseDirectMutation();
  const [newPurchaseSip] = useNewPurchaseSipMutation();
  const [newPurchaseDirectSip] = useNewPurchaseDirectSipMutation();
  const [getAllPaymentModes] = useAllPaymentModesMutation();
  const [checkoutCart] = useCheckoutCartMutation();
  const [getAllMandates] = useAllMandatesMutation();

  const payloadOb =
    cartSate?.cartItems[0].payload && JSON?.parse(cartSate?.cartItems[0].payload);

  const refactoredHolderDetails = holderDetails?.map((holder: any) => {
    const cleanedHolder: Partial<HolderDetails> = {};
    for (const key in holder) {
      if (holder[key as keyof HolderDetails] !== '') {
        cleanedHolder[key as keyof HolderDetails] = holder[key as keyof HolderDetails];
      }
    }
    cleanedHolder.category = 'I';
    return cleanedHolder;
  });

  const nominees = nomineeDetails?.nominees?.map((nominee: Nominee) => {
    const {
      isMinor,
      isSameAsApplicant,
      guardianName,
      guardianPan,
      guardianRelation,
      guardianGender,
      dob,
      sharePercentage,
      ...rest
    } = nominee;

    const formattedDob = dob ? format(dob as any, 'MM/dd/yyyy') : '';
    const sharePercentageToString = sharePercentage ? sharePercentage.toString() : '';
    const isNomineeMinor = isMinor === 'No' ? false : true;

    if (isMinor === 'No') {
      return {
        ...rest,
        dob: formattedDob,
        sharePercentage: sharePercentageToString,
        isMinor: isNomineeMinor,
      };
    } else {
      return {
        ...rest,
        guardianName,
        guardianPan,
        guardianRelation,
        guardianGender,
        dob: formattedDob,
        sharePercentage: sharePercentageToString,
        isMinor: isNomineeMinor,
      };
    }
  });

  const sipValidationSchama = {
    modeOfRegistration: Yup.string().required('Mode of Registration is required'),
    mandate: Yup.string().when('modeOfRegistration', {
      is: 'kotm',
      then: Yup.string().required('Mandate is required'),
    }),
  };
  const lumpsumValidationSchama = {
    paymentMode: Yup.string().required('Payment mode is required'),
  };

  const validationSchema = Yup.object().shape({
    mandate: Yup.string().when('paymentMode', {
      is: 'kotm',
      then: Yup.string().required('Mandate is required'),
    }),
    ...(modeOfTransaction !== 'Lumpsum' ? sipValidationSchama : {}),
    ...(modeOfTransaction !== 'SIP' ? lumpsumValidationSchama : {}),
  });

  const handleMandateChange = (e: any) => {
    const value = e.target.value;
    const selectedValue = mandateData?.kotmData?.find(
      (mandate: any) => mandate.umrn === value,
    );
    if (value !== 'createMandate') {
      setSelectedMandate({
        label: value,
        value: value,
        mandateLimit: selectedValue?.amount,
        startDate: selectedValue?.fromDate,
        endDate: selectedValue?.toDate,
        bankAccountNumber: selectedValue?.bnkacno,
        bankName: selectedValue?.bank,
      });
    } else {
      setSelectedMandate({
        label: '+ Create New Mandate',
        value: 'createMandate',
        startDate: '',
        endDate: '',
        mandateLimit: 0,
        bankAccountNumber: '',
        bankName: '',
      });
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      const cartPayload = {
        cartId: cartSate?.cartItems?.[0]?.cartId,
        pan: investorDetails?.pan,
        selectedCartItems: cartSate?.cartItemIds,
        personalDetails: {
          modeOfHolding:
            investorDetails?.invType === 'M'
              ? '1'
              : investorDetails?.modeOfHolding === 'single'
              ? '1'
              : '4',
          name: investorDetails?.name,
          dob: investorDetails?.dob,
          pan: investorDetails?.pan,
          email: investorDetails?.email,
          mobile: investorDetails?.mobile,
          ...(fatcaDetails?.taxResidentOtherThanIndia === 'Yes' && {
            taxPayerIdentificationNumber:
              fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
                ? fatcaDetails?.foreignIdNo
                : investorDetails?.pan,
            countryOfTaxResident:
              fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
                ? fatcaDetails?.taxResidentCountry
                : null,
          }),
          investorType: investorDetails?.invType,
          familyMobileFlag: investorDetails?.mobileFamilyDescription,
          familyEmailFlag: investorDetails?.emailFamilyDescription,
          category: investorDetails?.category,
          gender: investorDetails?.gender,
          status: personalDetails?.investorStatus,
          seafarerFlag: investorDetails?.seaFarerFlag === 'Yes' ? true : false,
          ...(investorDetails.category === '20' &&
            investorDetails?.seaFarerFlag === 'No' && {
              nriAddress1: investorDetails?.address1,
              nriAddress2: investorDetails?.address2,
              nriCity: investorDetails?.city,
              nriState: investorDetails?.state,
              nriCountry: investorDetails?.country,
              nriZipCode: investorDetails?.zipCode,
            }),
          ...(investorDetails?.invType === 'M' && {
            guardianName: investorDetails?.guardianName,
            guardianPan: investorDetails?.guardianPan,
            guardianDob: investorDetails?.guardianDob,
            guardianRelation: investorDetails?.guardianRelationWithMinor,
            guardianEmailDescription: investorDetails?.guardianEmailFamilyDescription,
            guardianMobileDescription: investorDetails?.guardianMobileFamilyDescription,
            guardianEmail: investorDetails?.guardianEmail,
            guardianMobile: investorDetails?.guardianMonNo,
          }),
          countryOfBirth: fatcaDetails?.country,
          countryOfCitizenship: fatcaDetails?.birthPlace,
          countryOfCitizenShip: investorDetails.category === '20' ? 'yes' : 'no',
          grossAnnualIncome: fatcaDetails?.annualIncome,
          taxResidentOfOtherCountry: fatcaDetails?.taxResidentOtherThanIndia,
          occupation: fatcaDetails?.occupation,
          sourceOfWealth: fatcaDetails?.sourceOfWealth,
          politicallyExposedPerson: fatcaDetails?.politicallyExposedPerson,
          politicallyExposedRelative: fatcaDetails?.politicallyExposedRelative,
        },

        jointHolders:
          investorDetails?.invType === 'M' ||
          holderDetails === null ||
          holderDetails === undefined
            ? []
            : refactoredHolderDetails,
        nominees:
          investorDetails?.invType === 'M' || isNomination || !nomineeDetails?.nominees
            ? []
            : nominees || [],
        noNomineeDeclaration:
          investorDetails?.invType === 'M' || isNomination || !nomineeDetails?.nominees
            ? true
            : false,
        bankDetails: {
          ifsc: investorBankDetails?.ifscCode,
          bank: investorBankDetails?.bankName,
          accType: investorBankDetails?.accountType,
          existing: '',
          acceptTerms: 'Y',
          accNo: investorBankDetails?.accountNumber,
          address1: investorBankDetails?.branchAddress,
          address2: '',
          address3: '',
          city: investorBankDetails?.bankBranch,
          pinCode: investorBankDetails?.branchPincode,
        },
        ...(modeOfTransaction !== 'Lumpsum' && {
          modeOfRegistration: values.modeOfRegistration,
        }),
        ...(modeOfTransaction !== 'SIP' && {
          paymentDetails: {
            paymentMode: values.paymentMode,
          },
          paymentMode: values.paymentMode,
        }),
        createMandate: selectedMandate?.value === 'createMandate',
        umrn: selectedMandate?.value === 'createMandate' ? '' : selectedMandate.value,
        selectedMandates:
          values.paymentMode === 'kotm' || values.modeOfRegistration === 'kotm'
            ? selectedMandate
            : {},
      };

      const payloadData = {
        email: investorDetails?.email,
        mobile: investorDetails?.mobile,
        scheme: schemeDetails?.scheme,
        plan: schemeDetails?.plan,
        option: schemeDetails?.option,
        fund: schemeDetails?.fund,
        schemeName: schemeDetails?.schemeDesc,
        pan: personalDetails?.pan,
        combo: false,
        invCategory: investorDetails?.category,
        investorName: investorDetails?.name,
        euin: investmentDetails?.euinValue,
        euinDeclaration: investmentDetails?.euin,
        familyMobileFlag: investorDetails?.mobileFamilyDescription,
        familyEmailFlag: investorDetails?.emailFamilyDescription,
        amount:
          modeOfTransaction === 'Lumpsum'
            ? Number(investmentDetails?.investmentAmount)
            : Number(investmentDetails?.lumpsumAmount),
        subBroker: subBrokerCode,
        category: schemeDetails?.category,
        fundName: fundName,
        personalDetails: {
          modeOfHolding:
            investorDetails?.invType === 'M'
              ? '1'
              : investorDetails?.modeOfHolding === 'single'
              ? '1'
              : '4',
          name: investorDetails?.name,
          dob: dateToMMDDYYYY(investorDetails?.dob),
          pan: investorDetails?.pan,
          email: investorDetails?.email,
          mobile: investorDetails?.mobile,
          ...(fatcaDetails?.taxResidentOtherThanIndia === 'Yes' && {
            countryOfTaxResident:
              fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
                ? fatcaDetails?.taxResidentCountry
                : null,
          }),
          taxPayerIdentificationNumber:
            fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
              ? fatcaDetails?.foreignIdNo
              : investorDetails?.pan,
          seafarerFlag: false,
          investorType: investorDetails?.invType,
          familyMobileFlag: investorDetails?.mobileFamilyDescription,
          familyEmailFlag: investorDetails?.emailFamilyDescription,
          category: investorDetails?.category,
          gender: investorDetails?.gender,
          status: personalDetails?.investorStatus,
          ...(investorDetails.category === '20' && {
            nriAddress1: investorDetails?.address1,
            nriAddress2: investorDetails?.address2,
            nriCity: investorDetails?.city,
            nriState: investorDetails?.state,
            nriCountry: investorDetails?.country,
            nriZipCode: investorDetails?.zipCode,
          }),
          ...(investorDetails?.invType === 'M' && {
            guardianName: investorDetails?.guardianName,
            guardianPan: investorDetails?.guardianPan,
            guardianDob: dateToMMDDYYYY(investorDetails?.guardianDob),
            guardianRelation: investorDetails?.guardianRelationWithMinor,
            guardianEmailDescription: investorDetails?.guardianEmailFamilyDescription,
            guardianMobileDescription: investorDetails?.guardianMobileFamilyDescription,
            guardianEmail: investorDetails?.guardianEmail,
            guardianMobile: investorDetails?.guardianMonNo,
            guardianCategory: investorDetails?.guardianCategory,
          }),
          countryOfBirth: fatcaDetails?.country,
          countryOfCitizenship: fatcaDetails?.birthPlace,
          countryOfCitizenShip: investorDetails.category === '20' ? 'yes' : 'no',
          grossAnnualIncome: fatcaDetails?.annualIncome,
          taxResidentOfOtherCountry: fatcaDetails?.taxResidentOtherThanIndia,
          occupation: fatcaDetails?.occupation,
          sourceOfWealth: fatcaDetails?.sourceOfWealth,
          politicallyExposedPerson: fatcaDetails?.politicallyExposedPerson,
          politicallyExposedRelative: fatcaDetails?.politicallyExposedRelative,
        },
        jointHolders:
          investorDetails?.invType === 'M' ||
          holderDetails === null ||
          holderDetails === undefined
            ? []
            : refactoredHolderDetails,
        nominees:
          investorDetails?.invType === 'M' || isNomination || !nomineeDetails?.nominees
            ? []
            : nominees || [],
        noNomineeDeclaration:
          investorDetails?.invType === 'M' || isNomination || !nomineeDetails?.nominees
            ? true
            : false,
        bankDetails: {
          ifsc: investorBankDetails?.ifscCode,
          bank: investorBankDetails?.bankName,
          accType: investorBankDetails?.accountType,
          existing: '',
          acceptTerms: 'Y',
          accNo: investorBankDetails?.accountNumber,
          address1: investorBankDetails?.branchAddress,
          address2: '',
          address3: '',
          city: investorBankDetails?.bankBranch,
          pinCode: investorBankDetails?.branchPincode,
        },
        ...(modeOfTransaction !== 'Lumpsum' && {
          sipFrequency: mapFrequencyToLetter(investmentDetails?.frequency),
          sipStartDate: sipDates?.startDate && format(sipDates?.startDate, 'MM/dd/yyyy'),
          sipEndDate: investmentDetails?.perpetual
            ? dateToMMDDYYYY(investmentDetails?.endDate)
            : sipDates?.endDate && format(sipDates?.endDate, 'MM/dd/yyyy'),
          sipDay: investmentDetails?.sipDay,
          noOfInstallments: Number(investmentDetails?.numberOfInstalments),
          modeOfRegistration: values?.modeOfRegistration,
          installmentAmount: Number(investmentDetails?.investmentAmount),
          perpetual: investmentDetails?.perpetual,
          remarks: '0',
        }),
        ...(modeOfTransaction !== 'SIP' && {
          paymentDetails: {
            paymentMode: values.paymentMode,
          },
          paymentMode: values.paymentMode,
        }),
        createMandate: selectedMandate?.value === 'createMandate' ?? false,
        umrn:
          values.paymentMode === 'kotm' || values.modeOfRegistration === 'kotm'
            ? selectedMandate?.value === 'createMandate'
              ? ''
              : selectedMandate.value
            : '',
        selectedMandates:
          values.paymentMode === 'kotm' || values.modeOfRegistration === 'kotm'
            ? selectedMandate
            : {},
      };

      let response;
      if (cartSate && cartSate?.previousUrl === 'cart') {
        response = await checkoutCart(cartPayload).unwrap();
      } else {
        if (modeOfTransaction === 'Lumpsum') {
          response = await newPurchaseDirect(payloadData).unwrap();
        } else if (modeOfTransaction === 'SIP') {
          response = await newPurchaseSip(payloadData).unwrap();
        } else {
          response = await newPurchaseDirectSip(payloadData).unwrap();
        }
      }

      if (response) {
        setTransactionNumber(response?.ihno ? response.ihno : response.batchId);
        setOpenDialogBox(true);
      }
    } catch (error) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  useEffect(() => {
    const getPaymentOptions = async () => {
      setDisplayLoader(true);
      const payload = {
        fund:
          cartSate?.previousUrl === 'cart'
            ? cartSate?.cartItems[0]?.fund
            : schemeDetails?.fund,
      };
      const response = await getAllPaymentModes(payload).unwrap();
      const options = Object.entries(response)
        .map(([key, value]) => {
          if (value === 'Y') {
            return { label: key.toUpperCase(), value: key };
          }
          return null;
        })
        .filter(Boolean);
      setPaymnetModeOptions(options);
      setDisplayLoader(false);
    };
    getPaymentOptions();
  }, []);

  useEffect(() => {
    const getPaymentOptions = async () => {
      const payload = {
        fund:
          cartSate?.previousUrl === 'cart'
            ? cartSate?.cartItems[0]?.fund
            : schemeDetails?.fund,
        pan: investorDetails?.pan,
        trtype: 'NPL',
        folio: '',
      };
      const res: MandateData = await getAllMandates(payload).unwrap();
      setMandatedata(res);
      let filteredMandates: any[] = [];
      const currentTime = new Date().getTime();
      const cartHighestAmount = Math.max(
        ...(cartSate?.cartItems || []).map((cartItem: any) => {
          const payloadRes = JSON.parse(cartItem.payload);
          return modeOfTransaction !== 'Lumpsum'
            ? Number(payloadRes.installmentAmount) < Number(payloadRes.amount)
              ? Number(payloadRes.amount)
              : Number(payloadRes.installmentAmount)
            : Number(payloadRes.amount);
        }),
      );
      const sipDatesFormCart = getEarliestAndLatestSipDates(cartSate?.cartItems);
      const sipEarliestDateCart = new Date(sipDatesFormCart?.earliestDate).getTime();
      const sipLatestDateCart = new Date(sipDatesFormCart?.latestDate).getTime();
      const investmentSipLatestDate = investmentDetails?.perpetual
        ? new Date(investmentDetails?.endDate).getTime()
        : new Date(sipDates?.endDate).getTime();
      const investmentSipEarliestDate = new Date(sipDates?.startDate).getTime();

      const sipEarliestDate =
        cartSate?.previousUrl === 'cart'
          ? sipEarliestDateCart
          : investmentSipEarliestDate;
      const sipLatestDate =
        cartSate?.previousUrl === 'cart' ? sipLatestDateCart : investmentSipLatestDate;
      const highestAmount =
        cartSate?.previousUrl === 'cart'
          ? cartHighestAmount
          : Math.max(
              Number(investmentDetails?.investmentAmount || 0),
              Number(investmentDetails?.lumpsumAmount || 0),
            );
      if (modeOfTransaction === 'Lumpsum') {
        //for lumpsum checking highest investment amount should be less than or equal to mandate amount limit
        //mandate end date should be greater than current date
        //mandate start date should be less than or equal to current date
        filteredMandates =
          res?.kotmData?.filter(item => {
            const startDate = new Date(item.fromDate).getTime();
            const endDate = new Date(item.toDate).getTime();
            return (
              item.amount >= highestAmount &&
              startDate <= currentTime &&
              endDate > currentTime
            );
          }) || [];
      } else {
        //checking for sip => mandate start date should be less than or equal to the sip start date
        //and mandate end date should be greater than longest sip end date
        filteredMandates =
          res?.kotmData?.filter(item => {
            const startDate = new Date(item.fromDate).getTime();
            const endDate = new Date(item.toDate).getTime();
            return (
              item.amount >= highestAmount &&
              startDate <= sipEarliestDate &&
              endDate > sipLatestDate
            );
          }) || [];
      }
      const options = filteredMandates.map((item: any) => {
        return { label: item.umrn, value: item.umrn };
      });
      //For NRI i.e '20' dont allow to create Mandate
      if (modeOfTransaction !== 'Lumpsum' && investorDetails?.category !== '20') {
        options.unshift({ label: '+ Create New Mandate', value: 'createMandate' });
      }
      setUmrnOptions(options);
    };
    getPaymentOptions();
  }, []);

  return (
    <>
      <Typography
        variant='subtitle2'
        sx={{ mb: 2, color: 'primary.main' }}
      >
        Please check the following details
      </Typography>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={7.5}
        >
          <Investorcard
            sx={(theme: Theme) => ({
              padding: '35px 35px',
              [theme.breakpoints.down('sm')]: {
                boxShadow: 'unset',
                p: 2,
                borderRadius: '4px',
              },
            })}
          >
            {cartSate?.previousUrl !== 'cart' ? (
              <>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    sm={1.2}
                    xs={2}
                  >
                    <Box
                      sx={{
                        borderRadius: '5px',
                        border: '0.5px solid',
                        borderColor: 'text.borderColorLight',
                        boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                        padding: '4px 4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <BankIcon />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={10.8}
                    xs={10}
                    mt={1}
                  >
                    <Typography
                      variant='h5'
                      sx={{
                        color: 'primary.main',
                      }}
                    >
                      {fundName}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  sx={{
                    my: 2,
                    border: '1px dashed',
                    borderColor: 'text.borderColorLight',
                  }}
                />

                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={7}
                  >
                    <Stack
                      rowGap={'10px'}
                      mb={'25px'}
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>Scheme</Typography>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor' }}
                      >
                        {`${schemeDetails?.schemeDesc} - ${schemeDetails?.planDesc}` ||
                          'NA'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                  >
                    <Stack
                      rowGap={'10px'}
                      mb={'25px'}
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>
                        {modeOfTransaction !== 'Lumpsum'
                          ? 'SIP Amount'
                          : 'Investment Amount'}
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor' }}
                      >
                        ₹{investmentDetails?.investmentAmount || 'NA'}
                      </Typography>
                    </Stack>
                  </Grid>
                  {modeOfTransaction !== 'Lumpsum' && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <Stack
                          rowGap={'10px'}
                          mb={'25px'}
                        >
                          <Typography sx={{ color: 'text.labelColor' }}>
                            SIP Day
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            sx={{ color: 'text.valueColor' }}
                          >
                            {investmentDetails?.sipDay || 'NA'}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <Stack
                          rowGap={'10px'}
                          mb={'25px'}
                        >
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Number Of Installments
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            sx={{ color: 'text.valueColor' }}
                          >
                            {investmentDetails?.numberOfInstalments || 'NA'}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <Stack
                          rowGap={'10px'}
                          mb={'25px'}
                        >
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Start Date
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            sx={{ color: 'text.valueColor' }}
                          >
                            {format(sipDates?.startDate, 'dd/MM/yyyy') || 'NA'}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <Stack
                          rowGap={'10px'}
                          mb={'25px'}
                        >
                          <Typography sx={{ color: 'text.labelColor' }}>
                            End Date
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            sx={{ color: 'text.valueColor' }}
                          >
                            {investmentDetails.perpetual
                              ? dateToDDMMYYYY(investmentDetails?.endDate)
                              : format(sipDates?.endDate, 'dd/MM/yyyy') || 'NA'}
                          </Typography>
                        </Stack>
                      </Grid>
                    </>
                  )}
                  {modeOfTransaction === 'Lumpsum + SIP' && (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                    >
                      <Stack
                        rowGap={'10px'}
                        mb={'25px'}
                      >
                        <Typography sx={{ color: 'text.labelColor' }}>
                          Lumpsum Amount
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          sx={{ color: 'text.valueColor' }}
                        >
                          ₹{investmentDetails?.lumpsumAmount || 'NA'}
                        </Typography>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  spacing={2}
                  mb={2}
                >
                  <Grid
                    item
                    sm={1.2}
                  >
                    <Box
                      sx={{
                        borderRadius: '5px',
                        border: '0.5px solid',
                        borderColor: 'text.borderColorLight',
                        boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                        padding: '4px 4px',
                        display: 'flex',
                      }}
                    >
                      <BankIcon />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={10.8}
                    mt={1}
                  >
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 500,
                        color: 'primary.main',
                      }}
                    >
                      {payloadOb.fundName}
                    </Typography>
                  </Grid>
                </Grid>
                {cartSate?.cartItems?.map((item: any, index: number) => {
                  const payloadObj = JSON.parse(item.payload);
                  const investmentDetails = {
                    investmentAmount: payloadObj.amount || 'NA',
                    installmentAmount: payloadObj.installmentAmount || 'NA',
                    schemeName: payloadObj.schemeName || 'NA',
                    planDesc: payloadObj.planDesc || 'NA',
                    sipDay: payloadObj.sipDay || 'NA',
                    numberOfInstalments: payloadObj.noOfInstallments || 'NA',
                    startDate: payloadObj.sipStartDate || 'NA',
                    endDate: payloadObj.sipEndDate || 'NA',
                    lumpsumAmount: payloadObj.amount || 'NA',
                  };
                  return (
                    <Grid
                      container
                      spacing={2}
                      key={index}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <Divider
                          orientation='horizontal'
                          variant='fullWidth'
                          flexItem
                          sx={{
                            border: '1px dashed',
                            borderColor: 'text.borderColorLight',
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <Stack
                          rowGap={'10px'}
                          mb={'25px'}
                        >
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Scheme
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            sx={{ color: 'text.valueColor' }}
                          >
                            {`${investmentDetails?.schemeName} - ${investmentDetails?.planDesc}` ||
                              'NA'}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <Stack
                          rowGap={'10px'}
                          mb={'25px'}
                        >
                          <Typography sx={{ color: 'text.labelColor' }}>
                            {modeOfTransaction !== 'Lumpsum'
                              ? 'SIP Amount'
                              : 'Investment Amount'}
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            sx={{ color: 'text.valueColor' }}
                          >
                            ₹
                            {modeOfTransaction === 'Lumpsum'
                              ? investmentDetails?.investmentAmount
                              : investmentDetails?.installmentAmount || 'NA'}
                          </Typography>
                        </Stack>
                      </Grid>
                      {modeOfTransaction !== 'Lumpsum' && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Stack
                              rowGap={'10px'}
                              mb={'25px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                SIP Day
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {investmentDetails?.sipDay || 'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Stack
                              rowGap={'10px'}
                              mb={'25px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Number Of Installments
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {investmentDetails?.numberOfInstalments || 'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Stack
                              rowGap={'10px'}
                              mb={'25px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Start Date
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {investmentDetails?.startDate || 'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Stack
                              rowGap={'10px'}
                              mb={'25px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                End Date
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {investmentDetails?.endDate || 'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                        </>
                      )}
                      {modeOfTransaction === 'Lumpsum + SIP' && (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                        >
                          <Stack
                            rowGap={'10px'}
                            mb={'25px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              Lumpsum Amount
                            </Typography>
                            <Typography
                              variant='subtitle2'
                              sx={{ color: 'text.valueColor' }}
                            >
                              ₹{investmentDetails?.lumpsumAmount || 'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </>
            )}
            <Formik
              initialValues={{
                paymentMode: '',
                modeOfRegistration:
                  investmentDetails?.modeOfRegistration ||
                  payloadOb?.modeOfRegistration ||
                  '',
                mandate: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors }) => {
                return (
                  <Form>
                    {modeOfTransaction !== 'SIP' && (
                      <Box sx={{ mb: 3 }}>
                        <Typography
                          variant='subtitle2'
                          sx={{ mb: 2 }}
                        >
                          Payment Mode
                        </Typography>
                        {/* {for NewPurchase hide ISIP Payment mode} */}
                        <FormStyledRadioButton
                          options={
                            selectedMandate.value === 'createMandate' ||
                            values.modeOfRegistration === 'ISIP'
                              ? paymentModeOptions?.filter(
                                  option =>
                                    option.label !== 'KOTM' && option.label !== 'ISIP',
                                )
                              : paymentModeOptions?.filter(
                                  option => option.label !== 'ISIP',
                                )
                          }
                          name='paymentMode'
                        />
                      </Box>
                    )}
                    <Grid
                      container
                      spacing={2}
                    >
                      {modeOfTransaction !== 'Lumpsum' && (
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormSelectInput
                            name='modeOfRegistration'
                            label='Mode of Registration (SIP)'
                            required
                            options={
                              investorDetails?.category === '11' &&
                              (investmentDetails?.modeOfRegistration === 'kotm' ||
                                payloadOb?.modeOfRegistration === 'kotm')
                                ? paymentModeOptions.filter(
                                    option => option.label === 'KOTM',
                                  )
                                : paymentModeOptions.filter(
                                    option =>
                                      option.label === 'KOTM' || option.label === 'ISIP',
                                  )
                            }
                          />
                        </Grid>
                      )}
                      {(values.paymentMode === 'kotm' ||
                        modeOfTransaction !== 'Lumpsum') &&
                        values?.modeOfRegistration !== 'ISIP' && (
                          <Grid
                            item
                            sm={6}
                            xs={12}
                          >
                            <FormSelectInput
                              name='mandate'
                              label='Select Mandate'
                              required
                              options={umrnOptions}
                              onChange={e => handleMandateChange(e)}
                              onClick={() => {
                                if (umrnOptions.length === 0) {
                                  showToast(
                                    'There are no valid mandates available, please select a different payment mode',
                                    'error',
                                  );
                                }
                              }}
                            />
                          </Grid>
                        )}
                    </Grid>
                    {displayLoader && <CustomLoader display={displayLoader} />}
                    <SubmitButton
                      sx={{
                        '&.MuiButtonBase-root': {
                          borderRadius: '5px',
                          minWidth: { xs: '100%', sm: '200px' },
                          mt: 5,
                        },
                      }}
                      label='Submit'
                    />
                  </Form>
                );
              }}
            </Formik>
          </Investorcard>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4.5}
        >
          <InvestorProfile />
        </Grid>
      </Grid>
      <CustomDialog
        isVisible={openDialogBox}
        secondButtonHandler={() => {
          setOpenDialogBox(false);
          navigate('/transaction-dashboard');
        }}
        secondButtonTitle='Ok'
        firstButtonTitle=''
        showFirstButton={false}
        showSecondButton
        handleClose={() => {}}
        firstButtonHandler={() => {}}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'fontSize': '16px',
              'color': 'text.grayishBlue',
              'fontWeight': '400',
              'opacity': '0.85',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />

          <Typography variant='body1'>{`Transaction Submitted Successfully with batch number ${transactionNumber}`}</Typography>
        </Box>
      </CustomDialog>
    </>
  );
}
