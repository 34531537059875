import { Box, Grid, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import FormDateInput from '../../../forms/FormDateInput';
import { FormSelectInput } from '../../../forms/FormSelect';
import SubmitButton from '../../../forms/FormSubmitButton';
import { FormPasswordInput } from '../../../forms/FormTextInput';
import { reportsOptions, reportTypesOptions } from '../constant';
import { Option } from './soa';

export interface ReportForProps {
  amcOptions: Option[];
}

function ReportFor({ amcOptions }: ReportForProps) {
  const navigate = useNavigate();
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const initialValues = {
    amc: '',
    selectReports: '',
    reportType: '',
    passwordToOpen: '',
    startDate: '',
    endDate: '',
  };

  const validationSchema = Yup.object().shape({
    amc: Yup.string().required('AMC is required'),
    selectReports: Yup.string().required('Select Reports is required'),
    reportType: Yup.string().required('ReportType is required'),
    passwordToOpen: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]+$/,
        'Password should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)',
      ),
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string().required('End Date is required'),
  });

  const handleSubmit = async (values: any) => {
    setOpenDialogBox(true);
    const Payload = {
      amc: values.amc,
      selectReports: values.selectReports,
      reportType: values.reportType,
      passwordToOpen: values.passwordToOpen,
      startDate: values.startDate,
      endDate: values.endDate,
    };
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Form>
              <Typography
                variant='subtitle1'
                sx={{ color: 'primary.main', my: 2 }}
              >
                Mailback Self Reports
              </Typography>
              <Investorcard
                sx={(theme: Theme) => ({
                  padding: '35px 35px',
                  [theme.breakpoints.down('sm')]: {
                    boxShadow: 'unset',
                    border: 'unset',
                    padding: '0px',
                  },
                })}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormSelectInput
                      name='amc'
                      label='AMC'
                      defaultValue={''}
                      options={amcOptions}
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormSelectInput
                      name='selectReports'
                      label='Select Reports'
                      defaultValue={''}
                      options={reportsOptions}
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormSelectInput
                      name='reportType'
                      label='Report Type'
                      defaultValue={''}
                      options={reportTypesOptions}
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormPasswordInput
                      name='passwordToOpen'
                      label='Password to Open'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormDateInput
                      name='startDate'
                      label='Start Date'
                      required={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormDateInput
                      name='endDate'
                      label='End Date'
                      required={true}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', mt: 5 }}>
                  <SubmitButton
                    sx={(theme: Theme) => ({
                      '&.MuiButtonBase-root': {
                        borderRadius: '5px',
                        minWidth: '200px',

                        [theme.breakpoints.down('sm')]: {
                          minWidth: '100%',
                        },
                      },
                    })}
                    label='Submit'
                  />
                </Box>
              </Investorcard>
            </Form>
          );
        }}
      </Formik>
      <CustomDialog
        isVisible={openDialogBox}
        showFirstButton={false}
        firstButtonHandler={() => {}}
        firstButtonTitle=''
        showSecondButton
        secondButtonTitle='Continue'
        secondButtonHandler={() => navigate('../')}
        handleClose={() => {}}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& img': {
              maxWidth: { xs: '17%', md: '70%' },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />
          <Typography
            variant='subtitle1'
            mt={2}
          >
            Your request is successfully registered with referance no{' '}
            <Typography
              variant='subtitle1'
              component={'span'}
              sx={{ fontWeight: 700 }}
            >
              859988568
              {/* {TODO Api Integration not in scope} */}
            </Typography>
            . Distributor will receive an Email for approval.
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
}

export default ReportFor;
