import { useEffect, useRef } from 'react';

type DebounceCallback<T extends any[]> = (...args: T) => void;

export const useDebounce = <T extends any[]>(
  callback: DebounceCallback<T>,
  delay: number,
  stopCall: boolean = true,
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const debouncedCallback: DebounceCallback<T> = (...args) => {
    if (!stopCall) return;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedCallback;
};
