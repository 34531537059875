import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { Investorcard } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { PageNavigator } from '../../common/BackToHomePage';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { FormSelectInput } from '../../forms/FormSelect';
import StyledRadioButtonGroup from '../../forms/FormStyledRadioButton';
import SubmitButton from '../../forms/FormSubmitButton';
import FormTextInput from '../../forms/FormTextInput';
import { useGetEUINSQuery } from '../../transactions/slice';
import { useGetNFOLinkMutation } from '../slice';
import { NFOItem } from '.';

const NFOTransact = () => {
  const { state } = useLocation();
  const { data: allEUINs, isLoading } = useGetEUINSQuery([]);
  const { arn, subArn, subbrokerCode, euin } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const [link, setLink] = useState('');
  const { showToast } = useRootContext();

  const [generateNFOLink] = useGetNFOLinkMutation();
  const initialValues = {
    arn: arn || '',
    subArn: subArn || 'ARN-',
    scheme: '',
    subBrokerCode: '',
    euin: 'Y',
    euinValue: euin || '',
  };

  const validationSchema = Yup.object().shape({
    arn: Yup.string(),
    scheme: Yup.string().required('Scheme is required'),
    euin: Yup.string().oneOf(['Y', 'N']).required(),
    euinValue: Yup.string().when('euin', {
      is: 'Y',
      then: Yup.string().required('EUIN value is required when EUIN is Y'),
      otherwise: Yup.string().nullable(),
    }),
  });

  function copyLink() {
    try {
      navigator.clipboard.writeText(link as string);
      showToast('Link copied!!', 'success');
    } catch (error: any) {
      showToast(error, 'error');
    }
  }

  async function handleSubmit(values: any) {
    try {
      const selectedScheme = state?.schemeList[values.scheme];
      const payload = {
        scheme: selectedScheme.scheme,
        pln: selectedScheme.plan,
        opt: selectedScheme.option,
        fund: state.amcCode,
        schemeDesc: selectedScheme.schemeDesc,
        planDesc: selectedScheme.planDesc,
        optionDesc: selectedScheme.optionDesc,
        fundName: selectedScheme.fundName,
        euin: values.euin === 'Y' ? values.euinValue : '',
      };
      const response = await generateNFOLink(payload).unwrap();
      setLink(response.url);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  }
  return (
    <>
      <PageNavigator title='NFO Transact' />

      <Investorcard
        sx={(theme: Theme) => ({
          padding: { sm: '30px' },
          [theme.breakpoints.down('sm')]: {
            boxShadow: 'unset',
            border: 'unset',
          },
        })}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, setValues, errors }) => (
            <Form>
              <Box>
                <Grid
                  container
                  spacing={2}
                  sx={{ columnGap: '40px' }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                  >
                    <FormTextInput
                      name='arn'
                      label='ARN'
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                  >
                    <FormTextInput
                      name='subArn'
                      label='SUB ARN'
                      required={false}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={5}
                  >
                    <FormSelectInput
                      name='scheme'
                      label='Select Scheme'
                      options={state?.schemeList?.map((item: NFOItem, index: number) => ({
                        label: `${item.schemeDesc}  ${item.planDesc} ${item.optionDesc}`,
                        value: index,
                      }))}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                  >
                    <FormTextInput
                      name='subBrokerCode'
                      label='SUB Broker code'
                      required={false}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ mb: 1.5 }}
                  >
                    <Typography
                      variant='subtitle2'
                      sx={{ color: 'text.valueColor', mb: 1 }}
                    >
                      EUIN
                    </Typography>
                    <StyledRadioButtonGroup
                      options={[
                        { label: 'Yes', value: 'Y' },
                        { label: 'No', value: 'N' },
                      ]}
                      value={values.euin}
                      handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const selectedValue = event.target.value;
                        setFieldValue('euin', selectedValue);
                      }}
                    />
                  </Grid>
                  {values.euin === 'Y' ? (
                    <Grid
                      item
                      xs={12}
                      sm={5}
                    >
                      <FormSelectInput
                        name='euinValue'
                        label='Select EUIN'
                        options={
                          euin
                            ? [{ label: euin, value: euin }]
                            : allEUINs?.map((item: any) => ({
                                label: `${item.euin} - ${item.name}`,
                                value: item.euin,
                              }))
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Box
                        sx={{
                          bgcolor: 'text.lightGray',
                          padding: '20px',
                          borderRadius: '5px',
                          maxWidth: '600px',
                        }}
                      >
                        <Typography sx={{ color: 'text.valueColor', fontSize: '12px' }}>
                          I/We hereby confirm that the EUIN box has been intentionally
                          left the blank by me/us as this is an execution-only transaction
                          without any interaction or advice by any personnel of the above
                          distributor or notwithstanding the advice of
                          in-appropriateness,if any provided by any personnel of the
                          distributor and the distributor has not charged any advisory
                          fees on this transaction
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>

              <Divider
                orientation='horizontal'
                variant='fullWidth'
                flexItem
                sx={{ border: '1px dashed', borderColor: 'text.borderColorDark', my: 3 }}
              />
              <SubmitButton
                label='Submit'
                sx={{ minWidth: { xs: '100%', sm: '200px' } }}
              />
            </Form>
          )}
        </Formik>
        <CustomDialog
          isVisible={!!link}
          firstButtonHandler={copyLink}
          secondButtonHandler={() => setLink('')}
          firstButtonTitle='Copy Link'
          secondButtonTitle='Close'
          handleClose={() => setLink('')}
          showSecondButton={true}
        >
          <Box sx={{ textAlign: 'center', pt: 2 }}>
            <Typography>
              {state?.fundName} NFO has been created successfully.
              <br />
              <Typography
                component='span'
                sx={{ fontWeight: 'bold' }}
              >
                Link: {link}
              </Typography>
            </Typography>
          </Box>
        </CustomDialog>
      </Investorcard>
    </>
  );
};

export default NFOTransact;
