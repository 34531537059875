import apiSlice from '../../../service/api';

const SummarySlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    portfolioSummary: builder.mutation({
      query: payload => ({
        url: '/user/investors/portfolio-summary',
        method: 'POST',
        body: payload,
      }),
    }),
    investmentDetails: builder.mutation({
      query: payload => ({
        url: '/user/investors/investment-details',
        method: 'POST',
        body: payload,
      }),
    }),
    transactionDetails: builder.mutation({
      query: payload => ({
        url: '/user/investors/transaction-details',
        method: 'POST',
        body: payload,
      }),
    }),
    serviceRequestDetails: builder.mutation({
      query: payload => ({
        url: '/user/investors/service-request-details',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  usePortfolioSummaryMutation,
  useInvestmentDetailsMutation,
  useTransactionDetailsMutation,
  useServiceRequestDetailsMutation,
} = SummarySlice;
