import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { CANCELLATION_REASONS_LIST } from '../../common/constants';

interface CancellationReasonDialogProps {
  showDialog: boolean;
  backButtonHandler: () => void;
  proceedButtonHandler: () => void;
  selectedReason: string;
  setSelectedReason: (reason: string) => void;
  header?: string;
}

const CancellationReasonDialog = ({
  showDialog,
  backButtonHandler,
  proceedButtonHandler,
  selectedReason,
  setSelectedReason,
  header = 'Cancellation',
}: CancellationReasonDialogProps) => {
  const [checkBoxLabel, setCheckBoxLabel] = useState<string>(selectedReason || '');
  const [error, setError] = useState<string>('');
  const remarksRef = useRef<HTMLDivElement>(null);

  const handleCheckboxChange = (reason: string) => {
    setCheckBoxLabel(reason);
    setSelectedReason(reason === 'Others-' ? '' : reason);
    setError('');
  };

  const handleProceed = () => {
    if (checkBoxLabel === 'Others-' && !selectedReason) {
      setError('Please provide more details.');
      return;
    }
    proceedButtonHandler();
  };

  useEffect(() => {
    if (checkBoxLabel === 'Others-') {
      remarksRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [checkBoxLabel]);

  return (
    <CustomDialog
      isVisible={showDialog}
      firstButtonHandler={backButtonHandler}
      secondButtonHandler={handleProceed}
      firstButtonTitle='Back'
      secondButtonTitle='Proceed'
      handleClose={() => {}}
      showSecondButton={true}
    >
      <Box sx={{ textAlign: 'center', pt: 2 }}>
        <Typography sx={{ fontSize: { xs: '16px', xl: '20px' }, fontWeight: '600' }}>
          {header} Reasons
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {CANCELLATION_REASONS_LIST.map((reason, index) => (
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {
                  fontSize: { xs: '14px', xl: '16px' },
                },
              }}
              key={index}
              control={
                <Checkbox
                  checked={checkBoxLabel === reason}
                  onChange={() => handleCheckboxChange(reason)}
                />
              }
              label={reason}
            />
          ))}
          {checkBoxLabel === 'Others-' && (
            <Box
              sx={{
                mt: 2,
              }}
              ref={remarksRef}
            >
              <Typography
                sx={{ color: 'primary.main', fontSize: '16px', mb: 1.5, fontWeight: 500 }}
              >
                Remarks
              </Typography>
              <TextField
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '10px',
                  },
                }}
                fullWidth
                multiline
                rows={4}
                variant='outlined'
                value={selectedReason}
                onChange={e => {
                  setSelectedReason(e.target.value);
                  if (e.target.value) {
                    setError('');
                  }
                }}
                placeholder='Provide more details...'
                error={!!error}
                helperText={error}
              />
            </Box>
          )}
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default CancellationReasonDialog;
