import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { PageNavigator } from '../../common/BackToHomePage';
import InvestorProfile from '../../common/InvestorProfile';
import PanDetailsBox from '../../common/PanDetails';
import SearchInputBox from '../../common/SearchBox';
import { useRootContext } from '../../data/root.context';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import { CommonTable } from '../../transactionDashboard/investorTable';
import { createConvertedOutputArray, rowCreationForSchemes } from '../common';
import { useListOfSchemesMutation } from '../slice';
import {
  investorSchemeDetailsColumns,
  RowData,
  Scheme,
  TransactionAllow,
} from '../types';

export default function Redeem() {
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [investorSchemes, setInvestorSchemes] = useState<Scheme[]>([]);
  const [investorDetailsList] = useListOfSchemesMutation();

  const { pan, name } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      const payload = {
        pan: pan.toUpperCase(),
      };
      try {
        const response = await investorDetailsList(payload);

        if ('data' in response) {
          setInvestorSchemes(response.data);
        } else {
          showToast(`Error fetching investor details: ${response.error}`, 'error');
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    }

    getInvestorDetailsSchemes();
  }, []);

  const outputArray: RowData[] = rowCreationForSchemes(investorSchemes);

  const continueButtonHandler = (row: any) => {
    navigate('investment-details', {
      state: {
        previousURL: 'reedem',
        schemeDetails: row,
      },
    });
  };
  return (
    <>
      <PanDetailsBox
        name={name}
        value={pan}
      />
      <PageNavigator title='Redeem' />
      <Typography
        variant='subtitle2'
        sx={{ color: 'primary.main', mb: 2 }}
      >
        Please select the scheme you want to make redeem
      </Typography>

      <SearchInputBox />

      <Grid
        container
        spacing={2}
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        <Grid
          item
          sm={9}
        >
          <CommonTable
            rows={createConvertedOutputArray(
              investorSchemes.map((item: any) => ({
                fundName: item.amcName,
                schemeDesc: item.schemeName,
                folioNo: item.folio,
                availableAmount: (item.nav * item.units).toFixed(2),
                balanceUnits: item.units,
                exitLoad: '1', //TODO: replace with exit load
                taxSaverFlag: item.taxsaverFlag,
                ...item,
              })),
              TransactionAllow['RED'],
            )} //TODO : optimise this line
            columns={Object.values(investorSchemeDetailsColumns) ?? []}
            start={0} // Starting page indexx
            limit={10} // Limit given
            searchPlaceholder={'Search by Name'}
            continueButtonHandler={continueButtonHandler}
            columnsToShow={Object.keys(investorSchemeDetailsColumns)}
            keyToCheck={TransactionAllow['RED']}
          />
        </Grid>
        <Grid
          item
          sm={3}
        >
          <InvestorProfile />
        </Grid>
      </Grid>
    </>
  );
}
