import apiSlice from '../../service/api';

const faqSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    allFaqs: builder.query({
      query: () => ({
        url: '/user/faqs',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyAllFaqsQuery } = faqSlice;
