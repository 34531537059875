import { IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackIcon_Mobile } from '../../../assets/back-icon.svg';
import { ReactComponent as BackIcon } from '../../../assets/back-icon-large.svg';
import { ReactComponent as AddDeleteBank } from '../../../assets/NCT/add-delete-bank.svg';
import { ReactComponent as ChangeEmail } from '../../../assets/NCT/change-email.svg';
import { ReactComponent as ChangeBankAccount } from '../../../assets/NCT/change-of-bank-account.svg';
import { ReactComponent as ChangeIDCW } from '../../../assets/NCT/change-of-idcw.svg';
import { ReactComponent as ChangeNumber } from '../../../assets/NCT/change-of-number.svg';
import { ReactComponent as FATCA } from '../../../assets/NCT/fatca.svg';
import { ReactComponent as UpdateBank } from '../../../assets/NCT/update-bank.svg';
import { ReactComponent as UserIcon } from '../../../assets/NCT/user-edit.svg';
import { TransactionHeader } from '../../auth/login/styles/styledComponents';
import CardGroups, { CardItem } from '../../common/CardGroups';

export default function NCT() {
  const navigate = useNavigate();

  const nctArr: CardItem[] = [
    {
      label: 'Update Nominee Details',
      route: 'update-nominee-details',
      icon: UserIcon,
    },
    {
      label: 'Change/Update Email Address',
      route: 'change-email',
      icon: ChangeEmail,
    },
    {
      label: 'Change Registered Mobile Number',
      route: 'change-mobile',
      icon: ChangeNumber,
    },
    {
      label: 'Change of Bank Account Details',
      route: 'change-bank-account-details',
      icon: ChangeBankAccount,
    },
    { label: 'Change of IDCW Option', route: 'change-idcw-option', icon: ChangeIDCW },
    {
      label: 'Add or Delete Multiple Bank Mandate',
      route: 'add-delete-bank-mandate',
      icon: AddDeleteBank,
    },
    { label: 'Update Bank IFSC', route: 'update-bank-ifsc', icon: UpdateBank },
    { label: 'FATCA/CRS Declaration', route: 'fatca-crs-declaration', icon: FATCA },
  ];

  const handleRoutes = (route: string) => {
    navigate(`/transaction-dashboard/non-commercial-transaction/${route}`);
  };

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
      >
        <IconButton
          sx={{ mb: '10px', mt: { xs: 2, md: 0 }, display: { xs: 'none', sm: 'unset' } }}
        >
          <BackIcon onClick={() => navigate(-1)} />
        </IconButton>
        <IconButton
          sx={{ mb: 2, mt: { xs: 2, md: 0 }, display: { xs: 'unset', sm: 'none' } }}
        >
          <BackIcon_Mobile onClick={() => navigate(-1)} />
        </IconButton>
        <TransactionHeader sx={{ mb: 2, mt: { xs: 2, md: 0 } }}>
          Non Commercial Transaction
        </TransactionHeader>
      </Stack>
      <CardGroups
        data={nctArr}
        handleRoutes={handleRoutes}
      />
    </>
  );
}
