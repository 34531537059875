import { Box, Grid, IconButton, Stack, Theme, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as BackIcon_Mobile } from '../../../../assets/back-icon.svg';
import { ReactComponent as BackIcon } from '../../../../assets/back-icon-large.svg';
import { ReactComponent as Clip_Icon } from '../../../../assets/clipper.svg';
import InfoIcon from '../../../../assets/info-fill.svg';

export const SUCCESS_RESPONSE_CODE = 10000;
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useTimer } from '../../../../hooks/useTimer';
import {
  FieldSubHeader2,
  LinkButton,
  StepperNextButton,
  TransactionHeader,
} from '../../../auth/login/styles/styledComponents';
import OtpInputSection from '../../../auth/signup/components/OtpInputSection';
import { useRootContext } from '../../../data/root.context';
import FormCheckbox from '../../../forms/FormCheckbox';
import FormDateInput from '../../../forms/FormDateInput';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import {
  setAccountTypes,
  setACKNumber,
  setBankDetails,
  setBasicDetails,
  setEKYCDetails,
  setFatcaDetails,
  setKycDetails,
  setNomineeDetails,
  setPersonalDetails,
  setSchemeDetails,
  setSkipKyc,
  setStatesAndCountries,
  setStepNumber,
} from './context/NewSubscriberAction';
import { useNewSubscriberContext } from './context/NewSubscriberContext';
import {
  useGetAccountTypeMutation,
  useGetBankDetailsMutation,
  useGetEkycDetailsMutation,
  useGetFatcaMutation,
  useGetKycDetailsMutation,
  useGetNpsNomineeDetailsMutation,
  useGetNpsSchemeDetailsMutation,
  useGetPersonalDetailsMutation,
  useLazyGetCountriesAndStateQuery,
  useSendRegistrationOtpMutation,
  useValidatePanMutation,
  useValidateRegistrationOtpMutation,
} from './slice';
import { _getOptions, dateToDDMMYYYByDash } from './utils';

interface InvestorOnBoardingFormValues {
  pan: string;
  dateOfBirth: Date | '';
  isTaxResident: boolean;
}
const initialValues: InvestorOnBoardingFormValues = {
  pan: '',
  dateOfBirth: '',
  isTaxResident: true,
};
interface OtpErrorState {
  showOtpErr: boolean;
  otpErr: string;
}

const validationSchema = Yup.object().shape({
  pan: Yup.string()
    .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN Number')
    .required('PAN Number is required'),
  dateOfBirth: Yup.date()
    .required('Date of birth is required')
    .test(
      'is-over-18',
      'Customers must be at least 18 years old.',
      (value: Date | string | null | undefined) => {
        if (!value) return false;
        const currentDate = new Date();
        const selectedDate = new Date(value);
        const age = Math.floor(
          (currentDate.getTime() - selectedDate.getTime()) /
            (364.25 * 24 * 60 * 60 * 1000),
        );
        return age >= 18 && age < 70;
      },
    )
    .typeError('Date of Birth is required'),
});
export default function InvestorOnBoarding() {
  const [callGetCountriesAndState, getCountriesAndStateResponse] =
    useLazyGetCountriesAndStateQuery();

  const {
    state: { EKYCDetails, mobileNumber, email },
    dispatch,
  } = useNewSubscriberContext();

  const emailDescription = email;
  const mobileDescription = mobileNumber;
  const [getKycDetails] = useGetKycDetailsMutation();
  const [getEkycDetails] = useGetEkycDetailsMutation();
  const [validatePan] = useValidatePanMutation();
  const [getPersonalDetails] = useGetPersonalDetailsMutation();
  const [getNomineeDetails] = useGetNpsNomineeDetailsMutation();
  const [getSchemeDetails] = useGetNpsSchemeDetailsMutation();
  const [getAccountType] = useGetAccountTypeMutation();
  const [getFatcaDetails] = useGetFatcaMutation();
  const [getBankDetails] = useGetBankDetailsMutation();
  const navigate = useNavigate();
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const { showToast } = useRootContext();
  const [reqGuid, setReqGuid] = useState('');
  const formikRef = useRef<FormikProps<InvestorOnBoardingFormValues>>(null);
  const [sendOTP] = useSendRegistrationOtpMutation();
  const [validateEnteredOtp] = useValidateRegistrationOtpMutation();
  const [otpInput, setOtpInput] = useState<string>('');

  const [otpError, setOtpError] = useState<OtpErrorState>({
    showOtpErr: false,
    otpErr: '',
  });
  const minutes = 3;
  const { timer, resetTimer } = useTimer({
    initcounter: minutes * 60,
    initStart: true,
  });
  const formattedTime = new Date(timer * 1000).toISOString().substr(14, 5);
  const maxDate = new Date().setFullYear(new Date().getFullYear() - 18);
  // Disabled button initially
  const [isVerifyButtonEnabled, setIsVerifyButtonEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (
      getCountriesAndStateResponse &&
      getCountriesAndStateResponse.isSuccess &&
      !getCountriesAndStateResponse.isFetching
    ) {
      // eslint-disable-next-line no-inner-declarations
      async function getStatesAndCountries() {
        const { data, statusCode: statesListStatusCode } =
          getCountriesAndStateResponse.data;

        if (Number(statesListStatusCode) !== SUCCESS_RESPONSE_CODE) return;

        const { ListCountries, StatesList } = data;
        const statesList = _getOptions(StatesList, 'Id');
        const countriesList = _getOptions(ListCountries, 'PK_ID');

        setStatesAndCountries({
          countriesList,
          statesList,
        })(dispatch);
      }
      getStatesAndCountries();
    }
  }, [getCountriesAndStateResponse]);

  const handleCkycDetails = async (values: InvestorOnBoardingFormValues) => {
    const { pan, dateOfBirth, isTaxResident } = values;
    const formattedDate = dateOfBirth ? dateToDDMMYYYByDash(dateOfBirth) : '';

    const cKycRequestBody = {
      Pan: pan,
      Dob: formattedDate,
    };
    const response = await getEkycDetails(cKycRequestBody).unwrap();
    if (Number(response.statusCode) !== SUCCESS_RESPONSE_CODE) return;

    if (response.data.ckyc_remarks !== 'OK') return;

    const {
      MOB_NUM,
      EMAIL,
      FULLNAME,
      MOB_CODE,
      FNAME,
      CKYC_NO,
      GENDER,
      DOB,
      PAN,
      PERM_LINE1,
      PERM_DIST,
      PERM_STATE,
      PERM_COUNTRY,
      PERM_PIN,
    } = response?.data?.result?.PERSONAL_DETAILS || {};

    const { IMAGE = [] } = response.data?.result?.IMAGE_DETAILS || {};

    const photograph = IMAGE.find(
      (details: any) => details.IMAGE_NAME === 'Photograph',
    )?.IMAGE_DATA;

    const isValidCkyc = [
      MOB_NUM,
      EMAIL,
      FULLNAME,
      FNAME,
      CKYC_NO,
      GENDER,
      DOB,
      PAN,
      PERM_LINE1,
      PERM_DIST,
      PERM_STATE,
      PERM_COUNTRY,
      PERM_PIN,
      photograph,
    ].every(Boolean);

    if (!isValidCkyc) return;

    dispatch(setEKYCDetails(response));

    dispatch(
      setBasicDetails({
        ResidenceType: isTaxResident ? '1' : null,
        Nationality: 'IN',
        mobileNumber: MOB_NUM ?? '',
        mobileCountryCode: MOB_CODE ?? '+91',
        email: EMAIL ?? '',
        name: FULLNAME ?? '',
        cKYC: response?.data?.ckyc_remarks,
      }),
    );

    return response;
  };

  const validateAndResumeOnboarding = async (pan: string) => {
    const { data = {} } = await validatePan({ PAN: pan }).unwrap();
    const { pranGenerated = false } = data;
    if (pranGenerated) throw new Error('PRAN with same PAN exists in KFintech CRA');

    const { WF_workFlowNumber } = data;

    if (!WF_workFlowNumber) return;
    dispatch(setACKNumber(WF_workFlowNumber));

    const response = await getPersonalDetails({ AckNumber: WF_workFlowNumber }).unwrap();

    const { data: personalDetails = {} } = response;

    const { DOB = '' } = personalDetails;

    if (!DOB) return;

    return WF_workFlowNumber;
  };

  const bulkDispatchContext = async (ackNumber: string) => {
    const promiseList = [
      getPersonalDetails({ AckNumber: ackNumber }).unwrap(),
      getNomineeDetails({ AckNumber: ackNumber }).unwrap(),
      getSchemeDetails({ AckNumber: ackNumber }).unwrap(),
      getKycDetails({ AckNumber: ackNumber }).unwrap(),
      getAccountType({ AckNumber: ackNumber }).unwrap(),
      getFatcaDetails({ AckNumber: ackNumber }).unwrap(),
      getBankDetails({ AckNumber: ackNumber }).unwrap(),
    ];

    const resolvedPromises = await Promise.all(promiseList);

    const [
      personalDetais,
      nomineeDetails,
      schemeDetails,
      kycDetails,
      accountType,
      fatcaDetails,
      bankDetails,
    ] = resolvedPromises;

    dispatch(setPersonalDetails(personalDetais));
    dispatch(setNomineeDetails(nomineeDetails));
    dispatch(setSchemeDetails(schemeDetails));
    dispatch(setKycDetails(kycDetails));
    dispatch(setAccountTypes(accountType));
    dispatch(setFatcaDetails(fatcaDetails));
    dispatch(setBankDetails(bankDetails));

    const { statusCode } = kycDetails;
    if (Number(statusCode) !== SUCCESS_RESPONSE_CODE) return;
    const { data } = kycDetails;

    //IdentiferNum = PAN, Aadhaar or CKYC Number
    //IdentiferType = Type of KYC the user is opted for
    //If Both are falsy then we set skip KYC to true

    const { IdentifierNum, IdentifierType } = data;
    if (Number(IdentifierType) && IdentifierNum) return;
    dispatch(setSkipKyc(true));
  };

  const handleSubmit = async (values: InvestorOnBoardingFormValues) => {
    try {
      const { pan, dateOfBirth } = values;

      callGetCountriesAndState('');

      dispatch(setBasicDetails({ pan, dateOfBirth, refId: '' }));
      const ckycDetails = await handleCkycDetails(values);
      const ackNumber = await validateAndResumeOnboarding(pan);
      if (ackNumber) {
        await bulkDispatchContext(ackNumber);
        navigate('new-subscriber');
        dispatch(setStepNumber(0));
        return;
      }

      if (ckycDetails) {
        const res = await sendOTP({
          MobileNo: ckycDetails.data.result?.PERSONAL_DETAILS.MOB_NUM,
          EmailID: ckycDetails.data.result?.PERSONAL_DETAILS.EMAIL,
          PAN: values.pan,
        }).unwrap();
        setReqGuid(res.data.ReqGUID);
        showToast((res as { message: string }).message, 'success');
        setShowOTP(true);
        return;
      }
      navigate('new-subscriber');
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  const otpHandler = async (value: string): Promise<void> => {
    setOtpInput(value);
    if (otpError.showOtpErr) {
      setOtpError({ showOtpErr: false, otpErr: '' });
    }
  };
  const isResendDisabled = timer < 1;

  const submitOtp = async () => {
    try {
      if (otpInput.length < 6) {
        throw new Error('OTP must be 6 characters long.');
      }
      const { error }: { error?: string } = await validateEnteredOtp({
        OTPCode: otpInput,
        ReqGUID: reqGuid,
      }).unwrap();
      if (error) {
        throw new Error(error);
      } else {
        dispatch(setStepNumber(1));
        navigate('./new-subscriber'); // Navigate to the new subscriber page
      }
    } catch (error: any) {
      setOtpError({ showOtpErr: true, otpErr: error.message });
    }
  };

  const resendButtonHandler = async () => {
    resetTimer();
    const responseOrError = await sendOTP({
      MobileNo: EKYCDetails?.data?.result?.PERSONAL_DETAILS.MOB_NUM,
      EmailID: EKYCDetails?.data?.result?.PERSONAL_DETAILS.EMAIL,
      PAN: formikRef?.current?.values.pan,
    }).unwrap();
    setReqGuid(responseOrError.data.ReqGUID);
    if ('error' in responseOrError) {
      showToast(responseOrError.error, 'error'); // Show error message
    } else {
      showToast(responseOrError.message, 'success'); // Navigate to the new subscriber page
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack
        sx={(theme: Theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '10px',
          mb: '12px',
          [theme.breakpoints.up(1920)]: {
            ml: '40px',
            mb: '40px',
          },
        })}
      >
        <IconButton
          sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
          onClick={() => {
            navigate('../../asset-selection', { state: { from: 'newInvestor' } });
          }}
        >
          <BackIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate('../../asset-selection', { state: { from: 'newInvestor' } });
          }}
          sx={{ display: { xs: 'inline-flex', sm: 'none' }, p: 0 }}
        >
          <BackIcon_Mobile />
        </IconButton>
        <Typography
          variant='h4'
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Back
        </Typography>

        <Typography
          variant='h4'
          sx={{
            fontWeight: 700,
            color: 'primary.main',
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {' '}
          NPS Investor Onboarding
        </Typography>
      </Stack>
      <Grid
        container
        justifyContent={'space-between'}
        height={'100%'}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Box
            sx={(theme: Theme) => ({
              pr: { xs: 'unset', sm: '30px', md: '45px' },
              [theme.breakpoints.up(1920)]: {
                pr: 0,
                maxWidth: '800px',
                margin: '0 auto',
              },
            })}
          >
            <Box sx={{ width: { xs: '100%', xl: '80%' } }}>
              <Typography
                sx={(theme: Theme) => ({
                  color: 'primary.main',
                  fontSize: { lg: '50px', xl: '60px' },
                  fontWeight: 700,
                  mb: 3,
                  display: { xs: 'none', sm: 'block' },
                  mt: 5,
                  lineHeight: { sm: '50px', md: 'initial' },
                  [theme.breakpoints.up(1920)]: {
                    mb: 6,
                  },
                })}
              >
                Efficiency meets trust in onboarding.
              </Typography>

              <Typography
                sx={(theme: Theme) => ({
                  maxWidth: '550px',
                  fontSize: { xs: '14px', sm: '16px', lg: '20px', xl: '22px' },
                  color: 'text.labelColor',
                  mb: { sm: 6.5, xl: 8 },
                  textAlign: { xs: 'center', md: 'unset' },
                  [theme.breakpoints.up(1920)]: {
                    maxWidth: '600px',
                    mb: 10,
                  },
                })}
              >
                Seamless onboarding: trust, efficiency define the investor journey for
                growth
              </Typography>
            </Box>

            <Box
              sx={(theme: Theme) => ({
                borderRadius: { xs: '10px', sm: '25px' },
                bgcolor: 'text.boxColor',
                padding: { xs: '20px 30px', sm: '50px 50px', xl: '20px 60px 20px' },
                position: 'relative',
                mt: { xs: 5, sm: 0 },
                [theme.breakpoints.up(1920)]: {
                  py: 6,
                  px: 8,
                },
              })}
            >
              {/*Mobile UI */}
              <Box
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  position: 'absolute',
                  top: '-20px',
                  transform: 'translateX(-50%)',
                  left: '50%',
                }}
              >
                <Clip_Icon />
              </Box>
              {/*Mobile UI */}
              <Grid
                container
                sx={{
                  'justifyContent': { xs: 'space-between', sm: 'unset' },
                  'position': 'relative',
                  '&:before': {
                    position: 'absolute',
                    bgcolor: 'text.stepperLine',
                    height: '1px',
                    content: '""',
                    top: '10px',
                    left: { xs: '25px', sm: 'unset' },
                    width: { xs: 'calc(100% - 80px)', sm: '100%' },
                  },
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ pr: { sm: 2 } }}
                >
                  <Box
                    sx={{
                      'display': 'flex',
                      'justifyContent': 'center',
                      'alignItems': 'center',
                      'ml': { xs: '25px', sm: 'unset' },
                      'width': '22px',
                      'height': '22px',
                      'transform': 'rotate(-45deg)',
                      'borderRadius': '5px',
                      'bgcolor': 'text.primary',
                      '& .MuiTypography-root': {
                        transform: 'rotate(45deg)',
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 500,
                        color: 'common.white',
                      },
                    }}
                  >
                    <Typography>1</Typography>
                  </Box>
                  <Box sx={{ my: { xs: 'unset', sm: 4 } }}>
                    <Typography
                      sx={(theme: Theme) => ({
                        color: 'text.primary',
                        fontSize: { xs: '12px', sm: '16px', md: '20px' },
                        mb: { xs: 0, sm: 2 },
                        mt: { xs: 2, sm: 'unset' },
                        fontWeight: 500,
                        [theme.breakpoints.down('sm')]: {
                          color: 'text.primary',
                        },
                      })}
                    >
                      Verify CKYC
                    </Typography>
                    <Typography
                      sx={{
                        display: { xs: 'none', sm: 'block' },
                        color: 'text.labelColor',
                      }}
                    >
                      Verify the CKYC to continue the NPS Investor account Setup.
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  sm={6}
                  sx={{ pr: { sm: 2 } }}
                >
                  <Box
                    sx={{
                      'ml': { xs: 'auto', sm: 'unset' },
                      'mr': { xs: '55px', sm: 'unset' },
                      'width': '22px',
                      'height': '22px',
                      'transform': 'rotate(-45deg)',
                      'borderRadius': '5px',
                      'bgcolor': 'text.labelColor',
                      '& .MuiTypography-root': {
                        transform: 'rotate(45deg)',
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 500,
                        color: 'common.white',
                      },
                    }}
                  >
                    <Typography>2</Typography>
                  </Box>
                  <Box sx={{ my: { xs: 'unset', sm: 4 } }}>
                    <TransactionHeader
                      sx={{
                        fontSize: { xs: '12px', sm: '16px', md: '20px' },
                        mb: { xs: 0, sm: 2 },
                        mt: { xs: 2, sm: 'unset' },
                        fontWeight: 500,
                        color: 'text.labelColor',
                      }}
                    >
                      NPS Account Setup
                    </TransactionHeader>
                    <Typography
                      sx={{
                        display: { xs: 'none', sm: 'block' },
                        color: 'text.labelColor',
                      }}
                    >
                      Complete NPS Account setup onboarding: Fill Investor Details , FATCA
                      Details, Investment Details, Scheme Details, Nominee Details, Bank
                      Details and Upload the important document for seamless account
                      setup.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Box sx={{ position: 'absolute', top: 0, bottom: 0 }}>
            <Box
              component={'span'}
              sx={{
                borderLeft: {
                  xs: 'unset',
                  sm: '1px dashed',
                  display: 'inline-block',
                  height: '100vh',
                },
                borderColor: {
                  xs: 'unset',
                  sm: 'text.borderColorLight',
                },
              }}
            ></Box>
          </Box>
          <Box
            sx={(theme: Theme) => ({
              mt: { xs: 'unset', sm: 7 },

              pl: { xs: '0px', sm: '30px', md: '45px', xl: '0px' },
              maxWidth: '600px',
              [theme.breakpoints.up(1700)]: {
                mx: 'auto',
                maxWidth: '700px',
              },
            })}
          >
            {!showOTP ? (
              <>
                <Typography
                  variant='h3'
                  sx={(theme: Theme) => ({
                    display: { xs: 'none', sm: 'block' },
                    [theme.breakpoints.up(1920)]: {
                      mb: 4,
                    },
                  })}
                >
                  NPS Investor Onboarding
                </Typography>

                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  innerRef={formikRef}
                  validationSchema={validationSchema}
                >
                  <Box>
                    <Form>
                      <Grid
                        container
                        sx={{ my: 1 }}
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                          mb={2}
                        >
                          <FormTextInput
                            name='pan'
                            label='Enter PAN'
                            placeholder='Enter PAN'
                            required={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <FormDateInput
                          name='dateOfBirth'
                          placeholder='Date of Birth'
                          label='Date of Birth *'
                          maxDate={new Date(maxDate)}
                          disableFuture={true}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <FormCheckbox
                          name='isTaxResident'
                          label='I am a tax resident of India'
                          margin='dense'
                          disabled={true}
                        />
                      </Grid>
                      <SubmitButton
                        label='Verify'
                        sx={{ my: 3, minWidth: '100%' }}
                        fullWidth
                      />
                    </Form>
                  </Box>
                </Formik>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '10px',
                    borderRadius: '7px',
                    bgcolor: 'text.borderColorDark',
                    padding: '20px 40px 20px 20px',
                    mt: 2,
                  }}
                >
                  <img
                    src={InfoIcon}
                    alt='info-icon'
                  />
                  <Typography
                    variant='subtitle2'
                    sx={{ lineHeight: '22px', color: 'text.labelColor', fontWeight: 400 }}
                  >
                    Add the applicant(s) PAN and if the PAN is CKYC verified, the contact
                    details will be populated automatically. PAN will be checked against
                    World-Check database for AML compliance.
                  </Typography>
                </Box>
              </>
            ) : (
              <React.Fragment>
                <TransactionHeader>OTP Verification</TransactionHeader>
                <FieldSubHeader2>
                  Enter OTP Code Sent to {mobileDescription} and {emailDescription}
                </FieldSubHeader2>

                <OtpInputSection
                  OTPHandler={otpHandler}
                  isDisabledField={false}
                  isResendDisabled={!isResendDisabled}
                  resendButtonHandler={resendButtonHandler}
                  formattedTime={formattedTime}
                />
                {/* Implement verify functionality */}
                <StepperNextButton
                  onClick={() => {
                    submitOtp();
                  }}
                  fullWidth
                  // disabled={isVerifyButtonEnabled}
                >
                  Verify
                </StepperNextButton>

                {otpError.showOtpErr && (
                  <Typography sx={{ color: 'red', mt: 1 }}>{otpError.otpErr}</Typography>
                )}

                {/* Implement Skip functionality */}
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  mt={'25px'}
                >
                  <LinkButton
                    onClick={() => {
                      dispatch(setStepNumber(0));
                      navigate('./new-subscriber');
                    }}
                    disabled={false}
                  >
                    Skip OTP Verification
                  </LinkButton>
                </Stack>
              </React.Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
