import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { Outlet } from 'react-router-dom';

interface State {
  pan: string;
  dob: string;
  email: string;
  fundName: string;
  kycTrack: { DtData: any[] };
  investmentDetails: any | null;
  basicDetails: any | null;
  investorDetails: any | null;
  fatcaDetails: any | null;
  nomineeDetails: any | null;
  investorBankDetails: any | null;
  countriesDetails: any | null;
  investorDetailsFormResponse: any | null;
  schemeDetails: any | null;
  holderDetails: any | null;
  sipDates: any | null;
  District: string;
  statesList: any[];
  countriesList: any[];
  currentStep: number;
  ResidenceType: string;
  isNomination: boolean;
  paymentModeConfirmation: boolean;
}

interface Action {
  type: string;
  payload: any;
}

type DispatchType = Dispatch<Action>;

interface ContextProps {
  state: State;
  dispatch: DispatchType;
}

export const NewMfsInvestorContext = createContext<ContextProps | undefined>(undefined);

const initialState: State = {
  pan: '',
  dob: '',
  email: '',
  fundName: '',
  kycTrack: { DtData: [] },
  investmentDetails: null,
  basicDetails: null,
  investorDetails: null,
  fatcaDetails: null,
  nomineeDetails: null,
  investorBankDetails: null,
  countriesDetails: null,
  investorDetailsFormResponse: null,
  schemeDetails: null,
  holderDetails: null,
  sipDates: null,
  District: '',
  statesList: [],
  countriesList: [],
  currentStep: 0,
  ResidenceType: '',
  isNomination: false,
  paymentModeConfirmation: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_BASIC':
      return { ...state, ...action.payload };
    case 'SET_BASIC_DETAILS':
      return { ...state, basicDetails: action.payload };
    case 'SET_INVESTMENT_DETAILS':
      return { ...state, investmentDetails: action.payload };
    case 'SET_FUNDNAME':
      return { ...state, fundName: action.payload };
    case 'SET_INVESTOR_DETAILS':
      return { ...state, investorDetails: action.payload };
    case 'SET_FATCA_DETAILS':
      return { ...state, fatcaDetails: action.payload };
    case 'SET_NOMINEE_DETAILS':
      return { ...state, nomineeDetails: action.payload };
    case 'SET_INVESTOR_BANK_DETAILS':
      return { ...state, investorBankDetails: action.payload };
    case 'SET_SIP_DATES':
      return { ...state, sipDates: action.payload };
    case 'SET_KYC_TRACK_DETAILS':
      return { ...state, kycTrack: action.payload };
    case 'SET_COUNTRIES_DETAILS':
      return { ...state, countriesDetails: action.payload };
    case 'SET_INVESTOR_DETAILS_FORM_RESPONSE':
      return { ...state, investorDetailsFormResponse: action.payload };
    case 'SET_DISTRICT':
      return { ...state, District: action.payload };
    case 'SET_STEP':
      return { ...state, currentStep: action.payload };
    case 'SET_IS_NOMINATION':
      return { ...state, isNomination: action.payload };
    case 'SET_SCHEME_DETAILS':
      return { ...state, schemeDetails: action.payload };
    case 'SET_HOLDER_DETAILS':
      return { ...state, holderDetails: action.payload };
    case 'SET_PAYMENT_CONFIRMATION':
      return { ...state, paymentModeConfirmation: action.payload };
    default:
      return state;
  }
};

const NewMfsInvestorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <NewMfsInvestorContext.Provider value={{ state, dispatch }}>
      {children}
    </NewMfsInvestorContext.Provider>
  );
};

export const NewMfsInvestorContextLayout = () => {
  return (
    <NewMfsInvestorProvider>
      <Outlet />
    </NewMfsInvestorProvider>
  );
};

export const useNewMfsInvestorContext = (): ContextProps => {
  const context = useContext(NewMfsInvestorContext);
  if (!context) {
    throw new Error(
      'useNewMfsInvestorContext must be used within a NewMfsInvestorProvider',
    );
  }
  return context;
};
