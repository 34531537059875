import { CardMedia, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackIcon_Mobile } from '../../assets/back-icon.svg';
import { ReactComponent as BackIcon } from '../../assets/back-icon-large.svg';
import AdditionalPurchase from '../../assets/transactions-assets/additional-purchase.svg';
import eNach from '../../assets/transactions-assets/e-nach.svg';
import NewPurchase from '../../assets/transactions-assets/new-purchase.svg';
import NCT from '../../assets/transactions-assets/non-commercial-transaction.svg';
import pause_Cancellation from '../../assets/transactions-assets/pause-cancellation.svg';
import Redemption from '../../assets/transactions-assets/redemption.svg';
import STP from '../../assets/transactions-assets/stp.svg';
import Switch from '../../assets/transactions-assets/switch.svg';
import SWP from '../../assets/transactions-assets/swp.svg';
import { Investorcard } from '../auth/login/styles/styledComponents';
import PanDetailsBox from '../common/PanDetails';
import { TransactionResponse } from '../features/transactions/transactionSlice';
import { useLazyGetInvestorTransactionsQuery } from './slice';

interface InvestorDetails {
  newPurchase: boolean;
  additionalPurchase: boolean;
  switch: boolean;
  swp: boolean;
  stp: boolean;
  redemption: boolean;
  eNACH: boolean;
  pause_cancellation: boolean;
  non_commercial_transaction: boolean;
  [key: string]: boolean;
}

interface CardData {
  name: string;
  image: string;
  routerPath: string;
  url?: string;
}
const cardNameMappings: Record<string, string> = {
  SWP: 'SWP (Systematic Withdrawal Plan)',
  STP: 'STP (Systematic Transfer Plan)',
};
export const Transact = () => {
  const [investorTransactions, setInvestorTransactions] =
    useState<InvestorDetails | null>(null);
  const [getInvestorTransactions, investorTransactionsList] =
    useLazyGetInvestorTransactionsQuery();
  const { MFS, NPS, pan, name } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  const navigate = useNavigate();
  useEffect(() => {
    getInvestorTransactions('');
  }, []);

  useEffect(() => {
    if (
      investorTransactionsList &&
      investorTransactionsList.isSuccess &&
      !investorTransactionsList.isFetching
    ) {
      setInvestorTransactions(investorTransactionsList.data);
    }
  }, [investorTransactionsList]);
  const getMappedName = (originalName: string): string => {
    return cardNameMappings[originalName] || originalName;
  };
  const cardData: CardData[] = [
    {
      name: 'New Purchase',
      image: NewPurchase,
      routerPath: 'new-purchase',
    },
    {
      name: 'Additional Purchase',
      image: AdditionalPurchase,
      routerPath: 'additional-purchase',
    },
    {
      name: 'Switch',
      image: Switch,
      routerPath: 'switch',
    },
    {
      name: 'SWP',
      image: SWP,
      routerPath: 'swp',
    },
    {
      name: 'STP',
      image: STP,
      routerPath: 'stp',
    },
    {
      name: 'Redemption',
      image: Redemption,
      routerPath: 'redemption',
    },
    {
      name: 'eNach',
      image: eNach,
      routerPath: 'enach',
    },
    {
      name: 'Pause/Cancellation',
      image: pause_Cancellation,
      routerPath: 'pause-cancel',
    },
    {
      name: 'Non-Commercial Transaction',
      image: NCT,
      routerPath: 'non-commercial-transaction',
    },
  ];
  const cleanName = (name: string): string => name.toLowerCase().replace(/[^a-z]/g, '');

  const filteredCardData = cardData.filter(card => {
    if (investorTransactions) {
      const cleanCardName = cleanName(card.name);
      const cleanInvestorDetailsNames = Object.keys(investorTransactions)
        .filter(key => investorTransactions[key])
        .map(key => cleanName(key));

      return cleanInvestorDetailsNames.includes(cleanCardName);
    }
    return false;
  });

  // eslint-disable-next-line no-empty-pattern

  const handleBackButton = () => {
    if (MFS && NPS) {
      navigate('../asset-selection');
    } else {
      navigate('../');
    }
  };

  return (
    <>
      <PanDetailsBox
        name={name}
        value={pan}
      />
      <Stack
        direction='row'
        alignItems='center'
      >
        <IconButton
          sx={{ mb: '10px', mt: { xs: 2, md: 0 }, display: { xs: 'none', sm: 'unset' } }}
        >
          <BackIcon onClick={handleBackButton} />
        </IconButton>
        <IconButton
          sx={{ mb: 2, mt: { xs: 2, md: 0 }, display: { xs: 'unset', sm: 'none' } }}
        >
          <BackIcon_Mobile onClick={handleBackButton} />
        </IconButton>
        <Typography
          variant='h4'
          sx={{ mb: 2, mt: { xs: 2, md: 0 } }}
        >
          Transact
        </Typography>
      </Stack>
      <Grid
        container
        spacing={3}
      >
        {filteredCardData.map((card, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
          >
            <Investorcard
              sx={theme => ({
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                columnGap: '20px',
                padding: '18px 20px',
                maxWidth: '280px',
                [theme.breakpoints.up(1920)]: {
                  minWidth: '-webkit-fill-available',
                },
                [theme.breakpoints.down('md')]: {
                  padding: '15px 20px',
                  width: '100%',
                  maxWidth: 'unset',
                },
              })}
              onClick={() => navigate(`../${card.routerPath}`)}
            >
              <CardMedia
                sx={{
                  '&.MuiCardMedia-root': {
                    width: { xs: '40px', sm: '70px' },
                    height: { xs: '40px', sm: '70px' },
                  },
                }}
                component='img'
                image={card.image}
                alt={card.name}
              />

              <Typography sx={{ fontSize: { xs: '14px', xl: '16px' }, fontWeight: 500 }}>
                {getMappedName(card.name)}
              </Typography>
            </Investorcard>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
