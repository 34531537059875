import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import useSip, {
  getEnumValueFromString,
  getPerpetualSipEndDate,
} from '../../../hooks/useSip';
import { calculateDifference } from '../../../utils';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import CustomLoader from '../../common/CustomLoader';
import { useRootContext } from '../../data/root.context';
import FormCheckbox from '../../forms/FormCheckbox';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { arnRegex } from '../common';
import SubmitCartButtons from '../common/SubmitCartButtons';
import EuinsFields from '../euinsComponent';
import { DEFAULT_MIN_TAT } from '../mfs/constants';
import { useSchemeByfundMutation } from '../mfs/newInvestorOnboarding/slice';
import {
  useGetEUINSQuery,
  useGetSchemaDetailsMutation,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
} from '../slice';
import { AlpModeType, Investment, TransactionType } from '../types';
import { SchemaData, SchemesByFund, SelectedScheme } from './types';

// Define the type for your form values
interface FormValues {
  selectedAmc: string;
  modeType: string;
  folioNumber: string;
  schemeType: string;
  schemeName: string;
  modeOfRegistration: string;
  frequency: string;
  installments: number;
  sipDay: string;
  startDate: string;
  endDate: string;
  installmentAmount: string; // SIp amount
  amount: string; //Lumpsum amount
  category: string;
  perpetual: boolean;
  euin: string;
}
const validationSchema = Yup.object().shape({
  arnCode: Yup.string().matches(arnRegex, 'Invalid ARN Code'),
  subArnCode: Yup.string().matches(arnRegex, 'Invalid Sub ARN Code'),
  frequency: Yup.string().when(['modeType', 'installmentAmount'], {
    is: (modeType: string, installmentAmount: string) =>
      modeType !== 'Lumpsum' && installmentAmount,
    then: Yup.string().required('Frequency is required'),
  }),
  sipDay: Yup.string().when(['modeType', 'installmentAmount'], {
    is: (modeType: string, installmentAmount: string) =>
      modeType !== 'Lumpsum' && installmentAmount,
    then: Yup.string().required('SIP Day is required'),
  }),
  installments: Yup.string().when(['modeType', 'installmentAmount'], {
    is: (modeType: string, installmentAmount: string) =>
      modeType !== 'Lumpsum' && installmentAmount,
    then: Yup.string().required('No. of Installments is required'),
  }),
  folioNumber: Yup.string().required('Folio Number is required'),
  schemeType: Yup.string().required('Scheme Type is required'),
  schemeName: Yup.string().required('Scheme Name is required'),
  category: Yup.string().when('schemeType', {
    is: 'new',
    then: Yup.string().required('Category is required'),
  }),
  amount: Yup.string().when('modeType', {
    is: (modeType: string) => modeType === 'Lumpsum' || modeType === 'LumpsumAndSIP',
    then: Yup.string()
      .required('Lumpsum amount is required')
      .matches(/^\d+(\.\d{1,2})?$/, 'Invalid amount'),
  }),
});
interface InvestmentDetailsFormProps {
  data: any;
  handleCancelModifyingCart?: () => void;
}

const EditTransactionAPL: React.FC<InvestmentDetailsFormProps> = ({
  data,
  handleCancelModifyingCart,
}) => {
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const [investorSchemes, setInvestorSchemes] = useState<Investment[]>([]);
  const [investorDetailsList] = useListOfSchemesMutation();
  const [selectedScheme, setSelectedScheme] = useState<SelectedScheme | {}>({});
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const navigate = useNavigate();
  const [schemesByFund, setSchemesByFund] = useState<SchemesByFund>({
    allSchemes: [],
    categories: [],
    selectedCategory: '',
  });
  const [schemaData, setSchemaData] = useState<SchemaData | {}>({});
  const [frequencyType, setFrequencyType] = useState('');
  const [getSchemesByFund] = useSchemeByfundMutation();
  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const { showToast } = useRootContext();
  const isSchemeChanged = useRef<boolean | null>(null);
  const [sipDay, setSipDay] = useState('1');
  const firstRender = useRef<boolean>(false);
  const [installments, setInstallments] = useState(1);
  const [modifyItemInCart] = useModifyItemInCartMutation(); // Modify items in cart
  const [isLoading, setIsLoading] = useState<boolean>();
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const initialInvestorSchemes = useRef<Investment[]>([]);
  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      const payload = {
        pan: pan,
      };
      try {
        const response = await investorDetailsList(payload).unwrap();
        const filteredSchemes = response?.filter(
          (item: { additionalPurAllow: boolean }) => item.additionalPurAllow,
        );
        setInvestorSchemes(filteredSchemes);
        initialInvestorSchemes.current = response;
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    }

    getInvestorDetailsSchemes();
  }, []);

  useEffect(() => {
    const fetchSchemesByFund = async () => {
      const resFund = await getSchemesByFund({
        funds: [data?.fund],
        investorPan: '',
        start: 0,
        end: 20000,
      }).unwrap();
      const filteredData = resFund.filter((row: any) => row.additionalPurAllow === true);
      const uniqueCategories = [
        ...new Set(filteredData.map((item: any) => item.category)),
      ];
      setSchemesByFund({
        ...schemesByFund,
        allSchemes: filteredData,
        categories: uniqueCategories as string[],
      });
      firstRender.current = true;
    };
    if (data?.fund && !firstRender.current) {
      fetchSchemesByFund();
    }
  }, [data]);
  useEffect(() => {
    const fetchSchemaDetails = async () => {
      const payload = {
        fund: data?.fund,
        scheme: (selectedScheme as SelectedScheme)?.scheme,
        plan: (selectedScheme as SelectedScheme)?.plan,
        option: (selectedScheme as SelectedScheme)?.option,
        trType: data?.transactionType !== 'APL' ? 'SIP' : 'APL',
      };

      const result = await getSchemaDetails(payload).unwrap();

      if (result.error) {
        throw new Error(result.error.data.message);
      }

      setSchemaData(result);
      isSchemeChanged.current = true;
    };
    if (isSchemeChanged.current === false) {
      fetchSchemaDetails();
    }
  }, [selectedScheme]);

  function getInvestment(fund?: string, folio?: number): any[] {
    if (fund && folio) {
      return investorSchemes?.filter(inv => inv.fund === fund && inv.folio === folio);
    } else if (fund) {
      const data =
        formikRef?.current?.values?.schemeType == 'new'
          ? initialInvestorSchemes?.current?.filter(inv => inv.fund === fund)
          : investorSchemes?.filter(inv => inv.fund === fund);
      return data;
    } else {
      return investorSchemes;
    }
  }

  const frequencyModes: string[] = useMemo(() => {
    const modes = (schemaData as SchemaData)?.frequency?.map((f: any) => f.mode) || [];
    setFrequencyType(modes?.[0]);
    return Array.from(new Set(modes));
  }, [(schemaData as SchemaData)?.frequency]);

  const [aplDates, minInstallments, minAmountAllowed, tatTime]: [
    string[],
    number,
    number,
    number,
  ] = useMemo(() => {
    const frequency = (schemaData as SchemaData)?.frequency?.find(
      (f: any) => f.mode === frequencyType,
    );
    const cycle = frequency?.cycle;
    const dates =
      !cycle || !/\d/.test(cycle)
        ? Array.from({ length: 28 }, (_, index) => `${index + 1}`)
        : cycle.split(',');
    const tatTime = (schemaData as SchemaData)?.frequency?.find(
      (f: any) => f.mode === frequencyType,
    )?.coolingPeriod;
    return [dates, frequency?.minInstallments, frequency?.minTransactionAmount, tatTime];
  }, [(schemaData as SchemaData)?.frequency, frequencyType]);
  const { data: allEUINs } = useGetEUINSQuery([]);

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);

      const {
        fundName,
        mobile,
        email,
        pan,
        fund,
        investorName,
        TRACEID,
        folioBanks,
        cartId,
        cartItemId,
        modeOfRegistration,
        invCategory,
      } = data;
      const { folioNumber, amount, installments, installmentAmount, sipDay } = values;

      const scheme = (selectedScheme as SelectedScheme)?.scheme;
      const option = (selectedScheme as SelectedScheme)?.option;
      const plan = (selectedScheme as SelectedScheme)?.plan;
      const schemeName = (selectedScheme as SelectedScheme)?.schemeName;
      const category = (selectedScheme as SelectedScheme)?.category;

      let payload;
      const commonPayload = {
        investorName,
        pan,
        fund,
        fundName,
        scheme,
        plan,
        option,
        modeOfRegistration,
        schemeName,
        category,
        sipDay,
        mobile,
        email,
        TRACEID,
        folioBanks,
        cartId,
        cartItemId,
        invCategory,
      };
      switch (values.modeType) {
        case 'Lumpsum':
          payload = {
            ...commonPayload,
            folio: folioNumber,
            amount,
            transactionType: TransactionType.APL,
            purchaseType: values?.schemeType === 'existing' ? 'EXISTING' : 'NEW',
            type: values?.schemeType === 'existing' ? 'EXISTING' : 'NEW',
          };
          break;

        case 'SIP':
          payload = {
            ...commonPayload,
            folio: folioNumber,
            sipFrequency: getEnumValueFromString(values?.frequency),
            sipStartDate: format(startDate, 'dd/MM/yyyy'),
            sipEndDate: format(endDate, 'dd/MM/yyyy'),
            noOfInstallments: installments,
            remarks: '0',
            transactionType: TransactionType.ISIP,
            installmentAmount,
          };
          break;
        case 'LumpsumAndSIP':
          payload = {
            ...commonPayload,
            remarks: '0',
            installmentAmount,
            amount,
            folio: folioNumber,
            sipFrequency: getEnumValueFromString(values?.frequency),
            sipStartDate: format(startDate, 'dd/MM/yyyy'),
            sipEndDate: format(endDate, 'dd/MM/yyyy'),
            noOfInstallments: installments,
            transactionType: TransactionType.SIN,
          };
          break;
        default:
          break;
      }
      await modifyItemInCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      setIsLoading(false);
      if (handleCancelModifyingCart) {
        handleCancelModifyingCart();
      }
    }
  };

  const clearCategoryAndScheme = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFieldValue('category', '');
    setFieldValue('schemeName', '');
  };
  const [startDate, endDate, , , , , errors] = useSip({
    frequencyType: frequencyType || 'Weekly',
    numInstallments: Number(installments) || 1,
    sipDay: sipDay ? String(sipDay) : '1',
    sipTat: tatTime || DEFAULT_MIN_TAT,
  });

  const handlePerpetualChange = (
    checked: boolean,
    setFieldValue: (field: string, value: any) => void,
    values: FormValues,
  ) => {
    if (checked) {
      const end_date = getPerpetualSipEndDate(values?.modeOfRegistration as string);
      const selectedInstallments = calculateDifference(
        startDate,
        end_date,
        frequencyType as string,
      );
      setFieldValue('endDate', format(new Date(end_date), 'eee do MMMM yyyy'));
      setFieldValue('installments', selectedInstallments);
      setInstallments(selectedInstallments as number);
    } else {
      // clearing the values
      setFieldValue('sipDay', '');
      setFieldValue('installments', '');
      setFieldValue('modeOfRegistration', '');
      setFieldValue('startDate', null);
      setFieldValue('endDate', null);
    }
  };
  const handleValidation = async (values: Record<string, any>) => {
    let errors: Record<string, any> = {};
    const minTransactionAmount =
      (data?.transactionType === 'APL'
        ? (schemaData as SchemaData)?.minTransactionAmount
        : minAmountAllowed) || 0;

    if (Number(values.amount) < minTransactionAmount) {
      // Lumpsum amount
      errors.amount = `Min Lumpsum amount allowed ${minTransactionAmount}`;
    }
    if (minInstallments > values.installments) {
      errors.installments = `Min installments allowed ${minInstallments}`;
    }
    if (
      Number(values.installmentAmount) < minTransactionAmount &&
      values?.modeType !== 'Lumpsum'
    ) {
      errors.installmentAmount = `Min installment amount allowed ${minTransactionAmount}`;
    }
    return errors;
  };

  useEffect(() => {
    if (startDate && endDate) {
      formikRef.current?.setFieldValue(
        'startDate',
        format(new Date(startDate), 'eee do MMMM yyyy'),
      );
      formikRef.current?.setFieldValue(
        'endDate',
        format(new Date(endDate), 'eee do MMMM yyyy'),
      );
    }
  }, [startDate, endDate]);

  return (
    <Formik<FormValues>
      initialValues={{
        selectedAmc: data?.fundName || '',
        schemeName: '',
        folioNumber: data?.folioNo || '',
        modeType: AlpModeType[data?.transactionType as keyof typeof AlpModeType],
        schemeType: '',
        modeOfRegistration: data?.modeOfRegistration || '',
        frequency: '',
        installments: 0,
        sipDay: data?.sipDay || '',
        startDate: data?.sipStartDate || null,
        endDate: data?.sipEndDate || null,
        installmentAmount: '',
        amount: '', // Lumpsum amount
        category: '',
        perpetual: false,
        euin: data?.euin || '',
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
      enableReinitialize
      validate={async values => handleValidation(values)}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Grid
              container
              spacing={2}
              sx={{
                'paddingBottom': '25px',
                '& .MuiButtonBase-root': {
                  '&.MuiRadio-root': {
                    '&.Mui-checked': {
                      color: 'primary.main',
                    },
                  },
                },
              }}
            >
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{ mb: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormTextInput
                      name='arnCode'
                      label='ARN Code'
                      disabled
                      value={data?.arnCode}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormTextInput
                      name='subArnCode'
                      label='Sub ARN Code'
                      required={false}
                      disabled
                      value={data?.subArn}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormTextInput
                      name='subBrokerCode'
                      label='Sub Broker Code'
                      required={false}
                      disabled
                      value={data?.subArnCode}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <EuinsFields
                      name='euin'
                      allEUINs={allEUINs}
                      initialValue={data?.euin !== '' ? 'yes' : 'no'}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{ color: 'text.inputLabelText', mb: 1.5 }}
                  >
                    Selected AMC
                  </Typography>
                  <FormTextInput
                    name='selectedAmc'
                    label='selectedAmc'
                    disabled
                  />
                </Grid>
                {/* <Grid
                  item
                  sm={6}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{ color: 'text.inputLabelText', mb: 1.5 }}
                  >
                    Transaction Type
                  </Typography>
                  <FormTextInput
                    name='modeType'
                    disabled
                  />
                </Grid> */}

                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{ color: 'text.valueColor', mb: 3 }}
                  >
                    Scheme Type
                  </Typography>
                  <FormStyledRadioButton
                    name='schemeType'
                    options={[
                      { label: 'Existing', value: 'existing' },
                      { label: 'New', value: 'new' },
                    ]}
                    handleChange={e =>
                      clearCategoryAndScheme(e.target.value, setFieldValue)
                    }
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{ color: 'text.inputLabelText', mb: 1.5 }}
                  >
                    Folio
                  </Typography>

                  <FormSelectInput
                    name='folioNumber'
                    label='folioNumber'
                    options={getInvestment(data?.fund).map(data => ({
                      value: data.folio,
                      label: data.folio,
                    }))}
                  />
                </Grid>
                {values.schemeType === 'new' && (
                  <Grid
                    item
                    sm={6}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{ color: 'text.inputLabelText', mb: 1.5 }}
                    >
                      Category
                    </Typography>

                    <FormSelectInput
                      name='category'
                      label='Category'
                      options={schemesByFund.categories.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      onChange={(e: any) =>
                        setSchemesByFund({
                          ...schemesByFund,
                          selectedCategory: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )}
                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{ color: 'text.inputLabelText', mb: 1.5 }}
                  >
                    Scheme
                  </Typography>

                  <FormSelectInput
                    name='schemeName'
                    label='Select Scheme'
                    options={
                      values?.category && values?.schemeType === 'new'
                        ? schemesByFund.allSchemes
                            .filter((item: any) => item.category === values?.category)
                            .map(data => ({
                              label: `${data.schemeDesc} - ${data.planDesc}`,
                              value: `${data.schemeDesc} - ${data.planDesc}`,
                            }))
                        : getInvestment(data?.fund, (values as any)?.folioNumber).map(
                            data => ({
                              value: data.scheme.toString(),
                              label: data.schemeName.toString(),
                            }),
                          )
                    }
                    disabled={!(values?.schemeType !== '')}
                    onChange={(e: any) => {
                      let selectedScheme: any = {};

                      if (values?.category && values?.schemeType === 'new') {
                        selectedScheme = schemesByFund.allSchemes.find(
                          (item: any) =>
                            `${item.schemeDesc} - ${item.planDesc}` === e.target.value,
                        );
                      } else {
                        selectedScheme = getInvestment(
                          data?.fund,
                          (values as any)?.folioNumber,
                        ).find(item => item.scheme === e.target.value);
                      }
                      isSchemeChanged.current = false;
                      setSelectedScheme(selectedScheme);
                    }}
                  />
                </Grid>

                <Box sx={{ padding: '20px 15px' }}>
                  <Grid
                    container
                    spacing={2}
                    alignItems={'center'}
                  >
                    {data?.transactionType !== 'APL' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          sx={{ mt: { sm: '40px' } }}
                        >
                          <FormTextInput
                            name='modeOfRegistration'
                            label={'Mode of Registration'}
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <FormSelectInput
                            name='frequency'
                            label='Frequency'
                            defaultValue={''}
                            options={
                              frequencyModes?.map((mode: string) => ({
                                label: mode,
                                value: mode,
                              })) || []
                            }
                            onChange={(e: any) => {
                              setFrequencyType(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <FormSelectInput
                            name='sipDay'
                            label='SIP Day'
                            options={aplDates.map(date => ({ label: date, value: date }))}
                            onChange={e => setSipDay(e.target.value as string)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                        >
                          {values?.frequency && (
                            <Typography>
                              Minimum Number of installments:
                              {0}
                            </Typography>
                          )}
                          <FormTextInput
                            name='installments'
                            label='No. of Installments'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setInstallments(Number(event.target.value));
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <FormDateInput
                            name='sipStartDate'
                            placeholder='SIP Start Date'
                            label='SIP Start Date'
                            value={values.startDate}
                            disabled={true}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <FormDateInput
                            name='endDate'
                            placeholder='SIP End Date'
                            label='SIP End Date'
                            disabled={true}
                            value={values.endDate}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <FormTextInput
                            name='installmentAmount'
                            placeholder='Installment Amount'
                            label='Enter Installment Amount'
                            value={values.installmentAmount}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <FormTextInput
                        name='amount'
                        placeholder='Lumpsum Amount'
                        label='Lumpsum Amount'
                        value={values.amount}
                      />
                    </Grid>
                    {values?.frequency && values.startDate ? (
                      <Grid
                        item
                        xs={12}
                      >
                        <FormCheckbox
                          name='perpetual'
                          label='Perpetual SIP'
                          margin='dense'
                          onChange={e =>
                            handlePerpetualChange(e.target.checked, setFieldValue, values)
                          }
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              </>
            </Grid>
            {isLoading && <CustomLoader display={isLoading} />}

            <SubmitCartButtons
              onAddToCartClick={handleCancelModifyingCart}
              firstButtonTitle={'Got to cart'}
              secondButtonTitle={'Update'}
              disableCondition={!data}
            />
            {openDialogBox && (
              <CustomDialog
                isVisible={true}
                firstButtonHandler={() => navigate('../')}
                firstButtonTitle='OK'
                handleClose={() => {}}
              >
                <Box
                  sx={{
                    'textAlign': 'center',
                    'pt': 2,
                    '& .MuiTypography-root': {
                      'fontSize': '16px',
                      'color': 'text.grayishBlue',
                      'my': 2,
                      '& >*': {
                        fontWeight: '600',
                      },
                    },
                  }}
                >
                  <img
                    src={VerifiedIcon}
                    alt='verified-icon'
                  />

                  <Typography variant='body1'>
                    {'Details Updated Successfully!'}
                  </Typography>
                </Box>
              </CustomDialog>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default EditTransactionAPL;
