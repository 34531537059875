import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  //   IconButton,
  Typography,
} from '@mui/material';

export const PopupDialogBox = ({
  open,
  onClose,
  title,
  description,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
}) => {
  return (
    <Dialog
      open={open}
      // onClose={close}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      sx={{
        'pb': 5,
        '& .MuiDialog-paper': {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent
        sx={{
          minWidth: 573,
          minHeight: 190,
        }}
      >
        <DialogContentText
          id='dialog-description'
          sx={{ pt: 2, textAlign: 'center' }}
        >
          <Grid
            container
            xs={11}
            ml={3}
          >
            <Grid
              item
              xs={12}
            >
              <Typography
                variant='h4'
                sx={{ color: 'common.black', textAlign: 'center' }}
              >
                {description}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ pt: 6 }}>
            <Button
              onClick={onClose}
              sx={theme => ({
                width: 165,
                height: 41,
                border: `2px solid ${theme.palette.text.buttonBGColor}`,
                py: 1,
              })}
            >
              OK
            </Button>
          </Box>
        </DialogContentText>

        {/* {text_img && (
            <DialogContentText id="dialog-description">
              
            </DialogContentText>
          )} */}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};
