import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { FieldSubHeader3, MainHeader } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../data/root.context';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { ReedemCartItem } from '../../transactions/cart/types';
import {
  useAddItemToCartMutation,
  useGetIndividualSchemeDetailsMutation,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
} from '../../transactions/slice';
import RenderSubmitCartButtons from '../common/SubmitCartButtons';
import { Investment, Reedem, SchemeDetails } from '../types';
interface InvestmentDetailsFormProps {
  data: any;
  isEditMode: boolean;
  handleCancelModifyingCart?: () => void;
}
const InvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  data,
  isEditMode,
  handleCancelModifyingCart,
}) => {
  const pan: string = useSelector((state: any) => state.transactions.pan);
  // Add to cart reedemed item here.
  const formikRef = useRef<FormikProps<Reedem | ReedemCartItem>>(null);
  const navigate = useNavigate();
  const { showToast } = useRootContext();

  const [investorSchemes, setInvestorSchemes] = useState<Investment[]>([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [investorDetails, setInvestorDetails] = useState<SchemeDetails | null>(data);
  const [selectedOption, setSelectedOption] = useState<any>(data);

  const [addReedemedItemInCart] = useAddItemToCartMutation();
  const [investorDetailsList] = useListOfSchemesMutation();
  const [modifyItemInCart] = useModifyItemInCartMutation();
  const [individualSchemeDetails] = useGetIndividualSchemeDetailsMutation();

  const availableUnitsRef = useRef(data ? data?.units : 0);
  const availableAmountRef = useRef(
    data ? availableUnitsRef.current * (data?.nav || 0) : 0,
  );
  const formattedAmountRef = useRef(
    availableAmountRef.current.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  );
  const minAmtRef = useRef(data?.minTranAmount ?? 0);
  const navRef = useRef(data?.nav ?? 0);

  function setData(data: any) {
    availableUnitsRef.current = data ? data?.units : 0;
    availableAmountRef.current = data ? availableUnitsRef.current * (data?.nav || 0) : 0;
    formattedAmountRef.current = availableAmountRef.current.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    minAmtRef.current = data?.minTranAmount ?? 0;
    navRef.current = data?.nav ?? 0;
  }

  useEffect(() => {
    setData(data);
  }, [data]);

  const firstButtonTitle = !isEditMode ? 'Add to Cart' : 'Cancel';
  const secondButtonTitle = !isEditMode ? 'Save & Proceed' : 'Update';

  const handleSubmit = async (values: Reedem | ReedemCartItem) => {
    const {
      defaultBank,
      cartId,
      cartItemId,
      fund,
      folio,
      name,
      email,
      mobile,
      option,
      pan,
      scheme,
      schemeName,
      schemeDesc,
      taxSaver,
      folioNo,
      fundName,
      plan,
    } = data || {};
    const { bankAccNo, bankName, ifscCode, bankAcType } = defaultBank || {};
    const payload = isEditMode // Modify cart
      ? {
          cartId,
          cartItemId,
          ifscCode: ifscCode || '',
          bankName: bankName || '',
          bankAccountNumber: bankAccNo || '',
          bankAccountType: bankAcType?.toLowerCase() || 'SAVING',
          email,
          folio: isEditMode && selectedOption ? selectedOption?.folio : String(folio),
          fund:
            isEditMode && selectedOption && selectedOption.fund
              ? selectedOption.fund.trim()
              : fund
              ? fund.trim()
              : '',
          investorName: name,
          mobile,
          option: isEditMode && selectedOption ? selectedOption?.option : String(option),
          pan,
          partial: values.redeemType === 'Partial',
          plan: isEditMode && selectedOption ? selectedOption?.plan : String(plan),
          scheme: isEditMode && selectedOption ? selectedOption?.scheme : String(scheme),
          schemeName:
            isEditMode && selectedOption && selectedOption.schemeName !== ''
              ? selectedOption.schemeName
              : String(schemeName || ''),
          taxSaverFlag: taxSaver,
          amount:
            values?.redeemMode === 'Amounts' && values?.redeemType === 'Partial'
              ? String(values.reedemptionValue)
              : 0,

          units:
            (values?.redeemMode === 'Units' && values?.redeemType === 'Partial') ||
            values?.redeemType === 'Full'
              ? String(values.reedemptionValue)
              : '0',
          transactionType: 'RED',
          fundName,
        }
      : {
          pan,
          email: email || '',
          mobile: mobile || '',
          investorName: name || '',
          folio: String(folioNo) || '',
          fund: fund.trim(),
          scheme,
          plan: data?.plan,
          option,
          amount:
            values?.redeemMode === 'Amounts' && values?.redeemType === 'Partial'
              ? String(values?.reedemptionValue || '')
              : '0',
          units:
            (values?.redeemMode === 'Units' && values?.redeemType === 'Partial') ||
            values.redeemType === 'Full'
              ? String(values?.reedemptionValue || '')
              : '0',
          partial: values?.redeemType === 'Partial',
          ifscCode: ifscCode || '',
          bankName: bankName || '',
          bankAccountNumber: bankAccNo || '',
          bankAccountType: bankAcType?.toLowerCase() || 'SAVING',
          schemeName: schemeDesc || '',
          taxSaverFlag: taxSaver || false,
          transactionType: 'RED',
          fundName,
        };
    //if edit mode hit update cart item and redirect user to cart page
    if (isEditMode) {
      try {
        await modifyItemInCart(payload).unwrap();
        showToast(`CartItem updated successfully`, 'success');
        if (handleCancelModifyingCart) handleCancelModifyingCart();
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    } else {
      navigate('/transaction-dashboard/confirmation', {
        state: { payload: payload, isEditMode: isEditMode },
      });
    }
  };

  const handleReedemTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedReedemType = event.target.value;
    formikRef.current?.setFieldValue('redeemType', selectedReedemType);
    if (selectedReedemType === 'Full') {
      formikRef.current?.setFieldValue('redeemMode', 'Units');
      formikRef.current?.setFieldValue('reedemptionValue', availableUnitsRef.current);
    } else formikRef.current?.setFieldValue('reedemptionValue', ''); // Clear the value if not "Full"
  };

  const validationSchema = Yup.object().shape({
    reedemptionValue: Yup.string().when('redeemMode', {
      is: 'Units',
      then: Yup.string()
        .required('Units are required')
        .matches(
          /^\d+(\.\d{1,3})?$/,
          'Units should be a number with up to three decimal places',
        ),
      otherwise: Yup.string()
        .required('Enter investment amount')
        .matches(/^[0-9]*\.?[0-9]+$/, 'Investment amount should be a number'),
    }),
    bankName: Yup.string().required('Please select Bank to proceed with the Transaction'),
  });
  const handleAddToCartClick = async () => {
    const { bankAccNo, bankName, ifscCode, bankAcType } = data?.defaultBank || {};

    const payload = {
      pan: pan,
      mobile: data?.mobile || '',
      email: data?.email || '',
      fund: data?.fund,
      scheme: data?.scheme,
      plan: data?.plan,
      option: data?.option,
      investorName: data?.name || '',
      folio: data?.folioNo || '',
      fundName: data?.fundName,
      amount:
        ((formikRef.current?.values as Reedem).redeemMode === 'Amounts' &&
          (formikRef.current?.values as Reedem).redeemType === 'Partial') ||
        (formikRef.current?.values as Reedem).redeemType === 'Full'
          ? String((formikRef.current?.values as Reedem).reedemptionValue)
          : '0',
      units:
        (formikRef.current?.values as Reedem).redeemMode === 'Units' &&
        (formikRef.current?.values as Reedem).redeemType === 'Partial'
          ? String((formikRef.current?.values as Reedem).reedemptionValue)
          : '0',
      partial:
        (formikRef.current?.values as Reedem).redeemType === 'Partial' ? true : false, // full or partial

      ifscCode: ifscCode || '',
      bankName: bankName || '',
      bankAccountNumber: bankAccNo || '',
      bankAccountType: bankAcType?.toLowerCase() || 'SAVING',
      transactionType: 'RED',
      schemeName: data?.schemeDesc || '', //optional
      taxSaverFlag: data?.taxSaver, // required always
    };
    try {
      await addReedemedItemInCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  const handleAddingItemInCart = () => {
    navigate('../../cart');
  };

  const handleAddAnotherTransactionBtn = () => {
    // Second button click logic
    setOpenDialogBox(false); // Close dialog after handling logic
    navigate('../');
  };

  const handleValidation = (investorDetails: any, values: Record<string, any>) => {
    const errors: Record<string, any> = {};
    const { redeemMode, redeemType, reedemptionValue } = values;

    if (isEditMode) {
      availableUnitsRef.current = investorDetails?.units;
      availableAmountRef.current =
        availableUnitsRef.current * (investorDetails?.nav || 0);
      minAmtRef.current = investorDetails?.minTranAmount;
    }

    const isPartial = redeemType === 'Partial';
    if (isPartial) {
      if (redeemMode === 'Units' && reedemptionValue > availableUnitsRef.current) {
        errors.reedemptionValue = 'Not enough units to redeem.';
      }

      if (redeemMode === 'Amounts') {
        if (availableAmountRef.current * 1.005 < minAmtRef.current) {
          errors.reedemptionValue = `You do not have enough amount to redeem`;
        } else if (
          reedemptionValue < minAmtRef.current &&
          minAmtRef.current < availableAmountRef.current
        ) {
          errors.reedemptionValue = `Minimum Amount required is ${minAmtRef.current}`;
        } else if (reedemptionValue < minAmtRef.current)
          errors.reedemptionValue = `Minimum Amount required is ${minAmtRef.current}`;
      }
    }

    return errors;
  };

  function getInvestment(fund?: string, folio?: number): any[] {
    if (fund && folio) {
      return investorSchemes?.filter(inv => inv.fund == fund && inv.folio == folio);
    } else if (fund) {
      return investorSchemes?.filter(inv => inv.fund == fund);
    } else {
      return investorSchemes;
    }
  }

  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      const payload = {
        pan: pan,
      };
      try {
        const response = await investorDetailsList(payload).unwrap();
        const filteredSchemes = response?.filter(
          (item: { redemptionAllow: any }) => item.redemptionAllow,
        );
        setInvestorSchemes(filteredSchemes);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    }
    if (isEditMode) {
      getInvestorDetailsSchemes();
    }
  }, []);

  async function getSelectedInvestorDetailsSchemes(
    selectedObject: Investment | undefined,
    folio: string,
  ) {
    const payload = {
      pan: pan,
      folio: folio,
      fund: selectedObject?.fund,
      trtype: 'RED',
      scheme: selectedObject?.scheme,
      option: selectedObject?.schemeOption,
      plan: selectedObject?.schemePlan,
    };

    try {
      const response = await individualSchemeDetails(payload).unwrap();
      setData(response);
      setInvestorDetails(response);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  }

  const handleSelectChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    values: any,
  ) => {
    const selectObject = Array.from(
      new Set(
        investorSchemes
          ?.filter((item: any) => {
            // Create a unique key from `schemeName` and `planDesc`
            const key = `${item.scheme} - ${item.plan} - ${item.option}`;
            return key === event.target.value;
          })
          .map((item: any) => JSON.stringify(item)),
      ),
    ).map(item => JSON.parse(item));
    await getSelectedInvestorDetailsSchemes(selectObject[0], values?.folioNumber);
    setSelectedOption(selectObject[0]);
  };
  return (
    <Formik
      initialValues={{
        redeemType:
          selectedOption?.folioNo !== data?.folioNo || data?.partial ? 'Partial' : 'Full',
        redeemMode:
          selectedOption?.folioNo !== data?.folioNo
            ? 'Amounts'
            : data?.cartAmount > 0
            ? 'Amounts'
            : 'Units',
        reedemptionValue:
          selectedOption?.folioNo !== data?.folioNo
            ? ''
            : data?.cartAmount > 0
            ? data?.cartAmount
            : data?.cartUnits || '',
        bankName: selectedOption?.folioNo !== data?.folioNo ? '' : data?.bankName || '',
        selectedAmc: data?.fundName || '',
        schemeName: `${data?.scheme} - ${data?.plan} - ${data?.option}`,
        folioNumber: data?.folioNo || '',
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
      key={data?.folioNo}
      validate={values => handleValidation(investorDetails, values)}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <Grid
            container
            spacing={2}
            sx={{
              'paddingBottom': '25px',
              '& .MuiButtonBase-root': {
                '&.MuiRadio-root': {
                  '&.Mui-checked': {
                    color: 'primary.main',
                  },
                },
              },
            }}
          >
            {isEditMode && (
              <>
                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{ color: 'text.inputLabelText', mb: 1.5 }}
                  >
                    Selected AMC
                  </Typography>
                  <FormTextInput
                    name='selectedAmc'
                    label='selectedAmc'
                    disabled
                  />
                </Grid>

                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{ color: 'text.inputLabelText', mb: 1.5 }}
                  >
                    Folio
                  </Typography>

                  <FormSelectInput
                    name='folioNumber'
                    label='folioNumber'
                    options={getInvestment(data?.fund).map(data => ({
                      value: data.folio,
                      label: data.folio,
                    }))}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{ color: 'text.inputLabelText', mb: 1.5 }}
                  >
                    Scheme
                  </Typography>

                  <FormSelectInput
                    name='schemeName'
                    label='schemeName'
                    options={getInvestment(data?.fund, (values as any)?.folioNumber).map(
                      data => ({
                        label: `${data.schemeName} - ${data.planDesc}`,
                        value: `${data.scheme} - ${data.plan} - ${data.option}`,
                      }),
                    )}
                    onChange={async (e: any) => handleSelectChange(e, values)}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                >
                  <Box sx={{ bgcolor: 'text.boxColor', padding: '20px 15px' }}>
                    <Grid
                      container
                      spacing={2}
                      alignItems={'center'}
                    >
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Balance Units:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {availableUnitsRef.current}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Current Value:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {availableAmountRef.current.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            NAV as on Date:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {navRef.current}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Minimum Amount:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {minAmtRef.current}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 2,
                  fontWeight: 500,
                  fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                }}
              >
                Redeem Type
              </Typography>
              <FormStyledRadioButton
                options={[
                  { label: 'Partial', value: 'Partial' },
                  { label: 'Full', value: 'Full' },
                ]}
                name='redeemType'
                handleChange={handleReedemTypeChange}
              />
            </Grid>

            {!(values.redeemType === 'Full') && (
              <Grid
                item
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 2,
                    fontWeight: 500,
                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                  }}
                >
                  Redeem Mode
                </Typography>
                <FormStyledRadioButton
                  options={[
                    { label: 'Units', value: 'Units' },
                    { label: 'Amounts', value: 'Amounts' },
                  ]}
                  name='redeemMode'
                  disabled={values.redeemType === 'Full'}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
            >
              <Typography
                sx={{
                  color: 'text.labelColor',
                  my: 2,
                }}
              >
                Available {values.redeemMode === 'Units' ? 'units' : 'amount'}:
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: 'text.valueColor',
                    ml: 1,
                  }}
                  component='span'
                >
                  {values.redeemMode === 'Units'
                    ? availableUnitsRef.current
                    : formattedAmountRef.current}
                </Typography>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 2,
                  fontWeight: 500,
                  fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                }}
              >
                Redemption Value (INR/UNITS)
              </Typography>

              <FormTextInput
                name='reedemptionValue'
                label={values.redeemMode === 'Units' ? 'Units' : 'Amount'}
                placeholder='Enter Amount'
                disabled={values.redeemType === 'Full' ? true : false}
              />
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 2,
                  fontWeight: 500,
                  fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                }}
              >
                Select Bank
              </Typography>

              <FormSelectInput
                name='bankName'
                label='Select Bank'
                options={
                  data?.defaultBank
                    ? [
                        {
                          label: data?.defaultBank?.bankName,
                          value: data?.defaultBank?.bankName,
                        },
                      ]
                    : []
                }
              />
            </Grid>
          </Grid>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              mt: 3,
              mb: 2,
            }}
          />
          <RenderSubmitCartButtons
            onAddToCartClick={
              !isEditMode ? handleAddToCartClick : handleCancelModifyingCart
            }
            firstButtonTitle={firstButtonTitle}
            secondButtonTitle={secondButtonTitle}
            disableCondition={!data}
          />
          <CustomDialog
            isVisible={openDialogBox}
            firstButtonHandler={handleAddingItemInCart}
            secondButtonHandler={handleAddAnotherTransactionBtn}
            firstButtonTitle='Go to cart'
            secondButtonTitle='Add another Transaction'
            handleClose={() => {
              setOpenDialogBox(false);
            }}
            showSecondButton={true}
          >
            <Box sx={{ textAlign: 'center', pt: 2 }}>
              <img
                src={VerifiedIcon}
                alt='verified-icon'
              />
              <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
              <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                Scheme is added to investor cart
              </FieldSubHeader3>
            </Box>
          </CustomDialog>
        </Form>
      )}
    </Formik>
  );
};

export default InvestmentDetailsForm;
