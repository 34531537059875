export const INVESTMENT_OPTIONS = [
  { label: 'Active', value: '1' },
  { label: 'Auto', value: '2' },
];

export const FUND_OPTIONS = {
  E: 'Equity',
  C: 'Corporate Bonds',
  G: 'Government Securites',
  A: 'Alternate Investment Funds',
};

export const PENSION_FUND_OPTIONS = [
  {
    value: 110,
    label: 'Aditya Birla Sun Life Pension Management Limited',
  },
  {
    value: 108,
    label: 'HDFC Pension Management Company Limited',
  },
  {
    value: 107,
    label: 'ICICI Prudential Pension Funds Management Company Limited',
  },
  {
    value: 105,
    label: 'Kotak Mahindra Pension Fund Limited',
  },
  {
    value: 103,
    label: 'LIC Pension Fund Limited',
  },
  {
    value: 101,
    label: 'SBI Pension Funds Private Limited',
  },
  {
    value: 102,
    label: 'UTI Retirement Solutions Limited',
  },
];

export const FREQUENCY = [
  {
    value: 1,
    label: 'MONTHLY',
  },
  {
    value: 2,
    label: 'YEARLY',
  },
  {
    value: 3,
    label: 'WEEKLY',
  },
];
export const LIFECYCLE_PERCENTAGE_ALLOCATION = {
  2: [
    {
      SchemeCode: '',
      AssetCategory: 'E',
      MinValue: 0,
      MaxValue: 50,
    },
    {
      SchemeCode: '',
      AssetCategory: 'C',
      MinValue: 0,
      MaxValue: 30,
    },
    {
      SchemeCode: '',
      AssetCategory: 'G',
      MinValue: 0,
      MaxValue: 20,
    },
  ],
  3: [
    {
      AssetCategory: 'E',
      MinValue: 0,
      MaxValue: 25,
    },
    {
      AssetCategory: 'C',
      MinValue: 0,
      MaxValue: 45,
    },
    {
      AssetCategory: 'G',
      MinValue: 0,
      MaxValue: 30,
    },
  ],
  4: [
    {
      SchemeCode: '',
      AssetCategory: 'E',
      MinValue: 0,
      MaxValue: 75,
    },
    {
      SchemeCode: '',
      AssetCategory: 'C',
      MinValue: 0,
      MaxValue: 10,
    },
    {
      SchemeCode: '',
      AssetCategory: 'G',
      MinValue: 0,
      MaxValue: 15,
    },
  ],
};

export const LIFECYCLE_MAP: { [key: string]: string } = {
  '2': 'LC50',
  '3': 'LC25',
  '4': 'LC75',
};

export const LABELS = {
  E: 'E-Equity',
  C: 'C-Corp Bonds',
  G: 'G-Govt Sector',
  A: 'A-Alt Invt Funds',
};
