/* eslint-disable no-unused-vars */
import { LoadingButton } from '@mui/lab';
import { ButtonProps, CircularProgress, Theme } from '@mui/material';
import { useFormikContext } from 'formik';

export default function SubmitButton({
  label = 'Submit',
  sx,
  fullWidth,
  ...props
}: ButtonProps & { label: string }): any {
  const formikContext = useFormikContext();
  const { isSubmitting } = formikContext;

  return (
    <LoadingButton
      sx={(theme: Theme) => ({
        'minWidth': { xs: 'unset', sm: '260px' },
        'my': 3,
        'width': 'unset',
        'padding': '10px 20px',
        'color': theme.palette.primary.light,
        'borderRadius': '5px',
        [theme.breakpoints.down('md')]: {
          fontSize: 14,
        },
        [theme.breakpoints.down('sm')]: {
          flex: 1,
          width: '100%',
        },
        '& .MuiLoadingButton-loadingIndicator': {
          top: '30%',
        },
        'background': theme.palette.background.primaryLinearGradient2,
        '&.Mui-disabled': {
          opacity: '0.3',
          color: 'common.white',
        },

        ...(sx as object),
      })}
      type='submit'
      fullWidth={fullWidth}
      // variant={}
      {...props}
      loading={isSubmitting}
    >
      {isSubmitting && (
        <CircularProgress
          color='inherit'
          size={16}
          sx={{
            position: 'absolute',
            // left: '50%',
            top: '50%',
            right: '20%',
            marginLeft: '-8px',
            marginTop: '-8px',
          }}
          role='progressbar'
          aria-label='Loading'
        />
      )}
      {label}
    </LoadingButton>
  );
}
