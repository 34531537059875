import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { useDebounce } from '../../../hooks/useDebounce';
import { useRootContext } from '../../data/root.context';
import { useLazyAdminSearchEmployeesQuery } from '../slice';

interface EmployeeSearchAutoCompleteProps {
  textPlaceHolder: string;
  selectedOptionHandler: (value: string) => void;
  disabled?: boolean;
}

const EmployeeSearchAutoComplete: React.FC<EmployeeSearchAutoCompleteProps> = ({
  textPlaceHolder,
  selectedOptionHandler,
  disabled = false,
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const [searchValues, setSearchValues] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const timeout = 500;
  const [adminSearchEmployees, adminSearchEmployeesValues] =
    useLazyAdminSearchEmployeesQuery();
  const [isSelectedOption, setIsSelectedOption] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    // Removing previous setted values
    if (Object.keys(isSelectedOption || {}).length > 0) {
      setIsSelectedOption({});
      selectedOptionHandler('');
    }
  };
  const { showToast } = useRootContext();
  // Handle Select
  const handleSelect = (value: any) => {
    setInputValue(value.label);
    setIsSelectedOption(value);
    selectedOptionHandler(value.value);
    setOpen(false);
  };

  useEffect(() => {
    if (
      adminSearchEmployeesValues &&
      adminSearchEmployeesValues.isSuccess &&
      !adminSearchEmployeesValues.isFetching
    ) {
      const formattedData = adminSearchEmployeesValues?.data?.map(
        (item: { empId: any; name: any }, index: any) => ({
          value: item.empId,
          label: `${item.empId} - ${item.name}`,
        }),
      );
      setSearchValues(formattedData);
      setLoading(false);
    }
  }, [adminSearchEmployeesValues]);

  const handleSearch = useDebounce(async (query: string) => {
    try {
      setLoading(true);
      const queryParams = {
        query: query,
      };
      adminSearchEmployees(queryParams);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  }, timeout);

  useEffect(() => {
    // for api call
    const findMatches = async () => {
      if (!inputValue) {
        setSearchValues([]);
        return;
      }
      try {
        if (Object.keys(isSelectedOption).length === 0 && inputValue.length >= 3) {
          // Only make API call if an option has not been selected
          handleSearch(inputValue);
        } else {
          setLoading(false);
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    };
    findMatches();
  }, [inputValue]);

  const handleKeyUp = ({ keyCode }: React.KeyboardEvent) => {
    setOpen(true);
    switch (keyCode) {
      case 40: // Down arrow
        if (count < searchValues.length - 1) {
          setCount(prev => prev + 1);
          listRef.current && (listRef.current.firstChild as HTMLElement)?.focus();
        }
        break;
      case 38: // Up arrow
        if (count > 0) {
          setCount(prev => prev - 1);
          listRef.current && (listRef.current.firstChild as HTMLElement)?.focus();
        }
        break;
      case 27: // Escape key
        setOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TextField
        sx={{
          'mt': '8px',
          'mb': '4px',
          '& .MuiInputBase-root': {
            'borderRadius': '7px',
            '&.Mui-focused,&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid',
                borderColor: 'text.borderColorLight',
              },
            },
            '&.Mui-disabled': {
              'color': 'text.primary',
              'bgcolor': 'text.borderColorDark',
              '-webkitTextFillColor': 'unset',
              '& fieldset': {
                borderColor: 'text.borderColorLight',
              },
            },
            '& .MuiInputBase-input': {
              'fontSize': 14,
              'fontWeight': '500',
              'color': 'text.primary',
              'border': 'unset',
              'padding': '14.95px 14.95px',
              '&.Mui-disabled': {
                bgcolor: 'text.borderColorDark',
              },
            },
          },
        }}
        fullWidth
        autoComplete='off'
        placeholder={textPlaceHolder}
        value={inputValue}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        type='search'
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setOpen(!isOpen)}>
              <ArrowDropDownIcon />
            </IconButton>
          ),
        }}
      />

      {!!inputValue && isOpen && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <MenuList
            role='listbox'
            ref={listRef}
          >
            {loading ? (
              <MenuItem>
                <CircularProgress size={24} />
              </MenuItem>
            ) : searchValues.length > 0 ? (
              searchValues.map((match, index) => (
                <MenuItem
                  role='option'
                  key={index}
                  onClick={() => {
                    handleSelect(match);
                  }}
                >
                  {match.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No result found.</MenuItem>
            )}
          </MenuList>
        </ClickAwayListener>
      )}
    </>
  );
};

export default EmployeeSearchAutoComplete;
