export const reportsOptions = [
  {
    label: 'Transaction Report',
    value: 'Transaction Report',
  },
  {
    label: 'New AUM Report',
    value: 'New AUM Report',
  },
  {
    label: 'Client',
    value: 'Client',
  },
  {
    label: 'Brokerage Report',
    value: 'Brokerage Report',
  },
  {
    label: 'Investor Master Information',
    value: 'Investor Master Information',
  },
  {
    label: 'NAV Report',
    value: 'NAV Report',
  },
  {
    label: 'Transaction Wise Investor Master',
    value: 'Transaction Wise Investor Master',
  },
  {
    label: 'SIP/STP Report',
    value: 'SIP/STP Report',
  },
];

export const reportTypesOptions = [
  {
    label: 'Excel',
    value: 'Excel',
  },
  {
    label: 'DBF',
    value: 'DBF',
  },
];

export const periodOptions = [
  { label: 'Current Fiscal Year', value: 0 },
  { label: 'Previous Fiscal Year', value: -1 },
  { label: '2nd Last Fiscal Year', value: -2 },
  { label: '3rd Last Fiscal Year', value: -3 },
];
