import { Box } from '@mui/material';

export default function AppLayout({ children }: any) {
  return (
    <Box>
      {/* <AppBar
        position="fixed"
        sx={{
          background: 'common.white',
          display: { xs: 'none', md: 'flex' },
          boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
          // backdrop-filter: blur(25px);

          height: '102px',
          justifyContent: 'center',
          top: '0',
          // bgcolor: 'common.white',
        }}
      >
        <Toolbar>
          <IconButton disableRipple={true}>
            <img
              src={Logo}
              alt="logo_icon"
              style={{
                marginLeft: '30px',
              }}
            />
          </IconButton>
        </Toolbar>
      </AppBar> */}
      <Box
        sx={{
          // background: {
          //   xs: `url(${MobileHomeBg}),linear-gradient(249deg, rgba(51, 127, 201, 0.80) 36%, rgba(63, 221, 224, 0.80) 100%)`,
          //   md: `url(${Homebg}),linear-gradient(249deg, rgba(51, 127, 201, 0.80) 36%, rgba(63, 221, 224, 0.80) 100%)`,
          // },
          // width: '100%',
          // overflowY: 'scroll',
          height: { sm: '100vh' },
          // display: { xs: 'flex', sm: 'unset' },
          // overflowX: 'hidden',
          // backgroundBlendMode: 'darken',
          // marginTop: { xs: '0px', md: '102px' },
          // padding: { xs: '0px', md: '50px', lg: '50px 0px' },
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: { xs: 'cover', md: 'cover', lg: 'cover', xl: 'cover' },
        }}
      >
        {/* <AppBar
          position="static"
          sx={{
            background: 'unset',
            display: { xs: 'flex', md: 'none' },
            boxShadow: 'none',
            // backdrop-filter: blur(25px);

            // height: '102px',
            // justifyContent: 'center',
            // top: '0',
            // bgcolor: 'common.white',
          }}
        >
          <Toolbar sx={{ padding: '0px' }}>
            <IconButton disableRipple={true}>
              <img
                src={LogoMobile}
                alt="logo_icon"
                style={{
                  marginLeft: '10px',
                }}
              />
            </IconButton>
            <IconButton disableRipple={true}>
              <img
                src={MenuIcon}
                alt="menu_icon"
                // style={{
                //   marginLeft: '10px',
                // }}
              />
            </IconButton>
          </Toolbar>
        </AppBar> */}
        {children}
      </Box>
    </Box>
  );
}
