import { Box, CardMedia, Divider, Grid, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as Bank_Icon } from '../../../assets/bank-account-icon.svg';
import InfoIcon from '../../../assets/info-filled-icon.svg';
import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import ButtonScroller from '../../common/ButtonScroller';
import { useRootContext } from '../../data/root.context';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormCheckbox from '../../forms/FormCheckbox';
import FormDateInput from '../../forms/FormDateInput';
import SubmitButton from '../../forms/FormSubmitButton';
import FormTextInput from '../../forms/FormTextInput';
import Layout from '../Layout';
import { useCreateEnachMandateMutation, useGetInvestorDetailsMutation } from '../slice';
import { EnachValues } from '../types';

const Enach = () => {
  const { name, pan, email, mobile } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  const navigate = useNavigate();
  const [requestId, setRequestId] = useState('');
  const { showToast } = useRootContext();
  const [bankData, setBankData] = useState<any>(null);
  const [getInvestorDetails] = useGetInvestorDetailsMutation();
  const [createEnachMandate] = useCreateEnachMandateMutation();
  const formikRef = useRef<FormikProps<EnachValues>>(null);

  useEffect(() => {
    async function getDetails() {
      try {
        const response: any = await getInvestorDetails({
          invPan: pan,
        }).unwrap();
        setBankData(response.bankData);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    }
    getDetails();
  }, []);

  async function handleSubmit(values: EnachValues) {
    try {
      const {
        amount,
        fromDT,
        toDT,
        investorName,
        investorMobile,
        investorEmail,
        investorPan,
      } = values;
      const response = await createEnachMandate({
        bankName: values.newBank ? '' : values.bankName,
        ifsc: values.newBank ? '' : values.ifsc,
        accountNo: values.newBank ? '' : values.accountNo,
        accountType: values.newBank ? '' : values.accountType,
        amount,
        fromDT,
        toDT,
        investorName,
        investorMobile,
        investorEmail,
        investorPan,
      }).unwrap();
      setRequestId(response.ihno);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  }

  function handleChangeBank(index: number) {
    formikRef.current?.setValues({
      ...formikRef.current.values,
      bankName: bankData?.[index].BankName || '',
      ifsc: bankData?.[index].ifsccode || '',
      accountNo: bankData?.[index].BankAcno || '',
      accountType: bankData?.[index].bankAcType || '',
    });
  }

  const validationSchema = () => {
    return Yup.object().shape({
      amount: Yup.number().required('Amount is required'),
      fromDT: Yup.date().required('Time Period from is required'),
      toDT: Yup.date().required('Time Period to is required'),
      investorMobile: Yup.string()
        .required('Mobile Number is required')
        .matches(
          /^(?!0000000000)([1-9][0-9]{9}|[6-9][0-9]{9})$/,
          'Invalid Mobile Number',
        ),
    });
  };
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  return (
    <Layout
      title='eNach'
      subtitle='Please enter the investment details'
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          bankName: bankData?.[0].BankName,
          ifsc: bankData?.[0].ifsccode,
          accountNo: bankData?.[0].BankAcno,
          accountType: bankData?.[0].bankAcType,
          amount: '',
          fromDT: '',
          toDT: '',
          investorName: name,
          investorMobile: mobile,
          investorEmail: email,
          investorPan: pan,
          newBank: false,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <Investorcard
              sx={theme => ({
                padding: { xs: '0px', sm: '15px 30px' },
                [theme.breakpoints.down('md')]: {
                  border: 'unset',
                  boxShadow: 'unset',
                },
              })}
            >
              <Typography
                variant='subtitle1'
                sx={{ color: { xs: 'text.valueColor', md: 'primary.main' } }}
              >
                Personal Details
              </Typography>
              <Grid
                container
                sx={{ my: 1 }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='investorPan'
                    label='PAN'
                    required={false}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='investorName'
                    label='Investor Name'
                    required={false}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='investorEmail'
                    label='Email ID'
                    required={false}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Stack flexDirection='row'>
                    <Box
                      sx={{
                        'width': { xs: '25%', lg: '25%' },
                        '& .MuiInputBase-input ': {
                          borderRadius: '7px 0px 0px 7px',
                          borderRight: '0',
                        },
                      }}
                    >
                      <FormTextInput
                        label='Country code'
                        name='countryCode'
                        value={'+91'}
                        disabled
                        required={false}
                      />
                    </Box>
                    <Box
                      width='75%'
                      sx={{
                        '& .MuiInputBase-input ': {
                          borderRadius: '0px 7px 7px 0px',
                        },
                      }}
                    >
                      <FormTextInput
                        name='investorMobile'
                        label='Mobile number'
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
              <Divider
                sx={{ border: '1px dashed', borderColor: 'text.borderColorDark', my: 3 }}
              />
              <Typography
                variant='subtitle1'
                sx={{ color: 'primary.main', my: 3 }}
              >
                Bank Details
              </Typography>

              <ButtonScroller
                data={
                  bankData?.map((bank: any) => ({
                    header: bank.BankName,
                    subHeader: bank.BankAcno,
                    icon: <Bank_Icon />,
                  })) || []
                }
                onClick={handleChangeBank}
                defaultClicked={values.newBank ? -1 : 0}
                disabled={values.newBank}
              />
              <Grid
                container
                sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
              >
                <FormCheckbox
                  name='newBank'
                  label={'Adding New Bank'}
                />
              </Grid>

              {bankData && !values.newBank && (
                <Grid
                  container
                  sx={{ my: 1 }}
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='bankName'
                      label='Bank Name'
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='ifsc'
                      label='IFSC Code'
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='accountNo'
                      label='Account Number'
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='accountHolderName'
                      label='Account Holder Name'
                      required={false}
                      value={name}
                      disabled
                    />
                  </Grid>
                </Grid>
              )}
              <Divider
                sx={{ border: '1px dashed', borderColor: 'text.borderColorDark', my: 3 }}
              />
              <Grid
                container
                sx={{ my: 1 }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='amount'
                    label='Amount'
                    required={false}
                    type='number'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormDateInput
                    name='fromDT'
                    label='Time Period from'
                    maxDate={values.toDT ? new Date(values.toDT) : undefined}
                    minDate={tomorrow}
                    required={false}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormDateInput
                    name='toDT'
                    label='Time Period to'
                    minDate={values.fromDT ? new Date(values.fromDT) : undefined}
                    required={false}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '12.5px 20px',
                      alignItems: 'center',
                      bgcolor: 'text.boxColorLight',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography sx={{ color: 'text.navyBlue', fontWeight: 500, mr: 2 }}>
                      eMandate participating AMCs
                    </Typography>
                    <img
                      src={InfoIcon}
                      alt='info-icon'
                    />
                  </Box>
                </Grid>
              </Grid>
              <SubmitButton label='Submit' />
            </Investorcard>
            {!!requestId && (
              <CustomDialog
                isVisible={true}
                firstButtonHandler={() => navigate('../')}
                firstButtonTitle='OK'
                handleClose={() => {}}
              >
                <Box sx={{ textAlign: 'center', pt: 2 }}>
                  <CardMedia
                    component={'img'}
                    src={VerifiedIcon}
                    sx={{ maxWidth: { xs: '50px', md: '90px', margin: '10px auto' } }}
                  />

                  <Typography variant='subtitle1'>
                    Your have initiated the eMandate successfully with Reference Number:{' '}
                    {requestId}
                  </Typography>
                </Box>
              </CustomDialog>
            )}
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default Enach;
