import { Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Investorcard } from '../auth/login/styles/styledComponents';
import { PageNavigator } from '../common/BackToHomePage';
import { useRootContext } from '../data/root.context';
import { LoginResponse } from '../features/login/loginSlice';
import { FormSelectInput } from '../forms/FormSelect';
import SubmitButton from '../forms/FormSubmitButton';
import FormTextInput from '../forms/FormTextInput';
import { useCustomerSupportMutation } from './slice';

const queries = [
  'Non receipt of Brokerage',
  'Clarification Required',
  'Information Required',
  'Literature Request',
  'Others',
];

const validationSchema = Yup.object({
  queryOption: Yup.string().required('Please select a query option'),
  query: Yup.string()
    .max(120, 'Query must be at most 120 characters')
    .required('Query is required'),
});

function Queries() {
  const navigate = useNavigate();
  const { arn } = useSelector((state: { login: LoginResponse }) => state.login);
  const [customerSupportRequest] = useCustomerSupportMutation();
  const { showToast } = useRootContext();

  const handleSubmit = async (values: any) => {
    const payload = {
      queryOption: values.queryOption,
      query: values.query,
    };
    try {
      const res = await customerSupportRequest(payload).unwrap();
      showToast(res?.Message, 'success');
    } catch (error: any) {
      showToast((error?.data as { message: string }).message, 'error');
    }
  };
  return (
    <>
      <PageNavigator
        title='Queries'
        backHandler={() => navigate('../')}
      />
      <Investorcard
        sx={theme => ({
          padding: '30px',
          [theme.breakpoints.down('md')]: {
            border: 'unset',
            boxShadow: 'unset',
            padding: '0px',
          },
        })}
      >
        <Typography
          variant='subtitle1'
          sx={{ color: 'primary.main' }}
        >
          Distributor Details
        </Typography>
        <Formik
          initialValues={{
            ARN: arn,
            queryOption: '',
            query: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {() => {
            return (
              <Form>
                <Grid
                  container
                  sx={{ my: 1 }}
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='ARN'
                      label='ARN Code'
                      placeholder='Enter your ARN Code'
                      required={false}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormSelectInput
                      name='queryOption'
                      label='Select query option'
                      options={queries.map((query: string) => ({
                        label: query,
                        value: query,
                      }))}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      multiline
                      rows={4}
                      name='query'
                      placeholder='Query Input'
                    />
                  </Grid>
                </Grid>
                <SubmitButton
                  label='Submit'
                  sx={{ my: 3, minWidth: '200px' }}
                  fullWidth
                />
              </Form>
            );
          }}
        </Formik>
      </Investorcard>
    </>
  );
}

export default Queries;
