import {
  alpha,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  styled,
  TablePagination,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { PageNavigator } from '../../../../common/BackToHomePage';
import CustomLoader from '../../../../common/CustomLoader';
import { CustomSearch } from '../../../../common/search';
import { useRootContext } from '../../../../data/root.context';
import { useLazyGetInvestorsQuery } from '../../../slice';
import TableContainer from './TableContainer';
const Search = styled('div')(({ theme }) => ({
  'position': 'relative',
  'borderRadius': theme.shape.borderRadius,
  'backgroundColor': alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  'marginLeft': 0,
  'width': '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  'padding': theme.spacing(0, 2),
  'height': '100%',
  'position': 'absolute',
  'zIndex': 1,
  'pointerEvents': 'none',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  '& svg': {
    [theme.breakpoints.between(768, 1024)]: {
      width: '14px',
    },
  },
}));
export const SearchBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'auto',
}));
const InvestorBox = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  border: `0.3px solid ${theme.palette.text.borderColorDark}`,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.05)',
}));
export const SearchIconContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
export default function InvestorSelection() {
  const { showToast } = useRootContext();
  const [searchCriteria, setSearchCriteria] = useState('Name');
  const [searchText, setSearchText] = useState('');
  const [investors, setInvestors] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayLoader, setDisplayLoader] = useState(false);

  const [getInvestorDetails, InvestorDetailsList] = useLazyGetInvestorsQuery();

  useEffect(() => {
    try {
      getInvestorDetails('');
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    }
  }, []);

  useEffect(() => {
    setDisplayLoader(true);
    if (InvestorDetailsList.data && InvestorDetailsList.data.invester) {
      const result = InvestorDetailsList.data.invester;
      setInvestors(result);
    }
    setDisplayLoader(false);
  }, [InvestorDetailsList.data]);

  const handleSearchTextChanged = (text: string) => {
    setSearchText(text);
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const filteredInvestors = investors.filter(investor => {
    switch (searchCriteria) {
      case 'Name':
        return investor.name.toLowerCase().includes(searchText.toLowerCase());
      case 'PAN':
        return investor.pan.toLowerCase().includes(searchText.toLowerCase());
      case 'Mobile Number':
        return investor.mobile.includes(searchText);
      case 'Email':
        return investor.email.toLowerCase().includes(searchText.toLowerCase());
      default:
        return true;
    }
  });

  return (
    <>
      <PageNavigator title='Investor Selection' />
      <Paper
        sx={(theme: Theme) => ({
          'display': { xs: 'none', sm: 'block' },
          'width': '100%',
          'mb': 2,
          'borderRadius': '15px',
          'border': '0.3px solid',
          'borderColor': 'text.borderColorDark',
          'background': theme.palette.common.white,
          'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
          '& .MuiButtonBase-root.MuiRadio-root': {
            'color': 'text.darkGrayBlue',
            'padding': '15px 8px',
            '& .MuiSvgIcon-root': {
              width: '30px',
              height: '25px',
            },
            '&.Mui-checked': {
              'color': 'primary.main',
              '& + .MuiTypography-root': {
                color: 'primary.main',
              },
            },
          },
        })}
      >
        <Box sx={{ padding: '16px' }}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
              sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
            >
              <Typography sx={{ color: 'text.labelColor', fontSize: '16px' }}>
                Search By
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  value={searchCriteria}
                  onChange={e => {
                    setSearchText('');
                    setSearchCriteria(e.target.value);
                  }}
                >
                  {['Name', 'PAN', 'Mobile Number', 'Email'].map((item, index) => (
                    <FormControlLabel
                      sx={{
                        '& .MuiTypography-root': {
                          fontSize: '16px',
                          color: 'text.labelColor',
                          fontWeight: 500,
                        },
                      }}
                      key={index}
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <CustomSearch
                value={searchText}
                placeholder={`Search by ${searchCriteria}`}
                onChange={handleSearchTextChanged}
              />
            </Grid>
          </Grid>
        </Box>
        {filteredInvestors.length !== 0 ? (
          <TableContainer
            data={filteredInvestors}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        ) : (
          <Box
            sx={{
              display: displayLoader ? 'none' : 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              my: 3,
            }}
          >
            No Data found
          </Box>
        )}
        {displayLoader && <CustomLoader display={displayLoader} />}
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component='div'
          count={investors?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
