import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import { format, parse } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import {
  calculateDifference,
  calculateNextInstallmentDate,
  calculateTatDate,
  disableDatesOnEndDate,
  disableDatesOnStartDate,
} from '../../../utils';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { getDayFromDate } from '../../common/custom-functions';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { FieldSubHeader3, MainHeader } from '../../onboarding/styles/styledComponents';
import SubmitCartButtons from '../common/SubmitCartButtons';
import {
  useAddItemToCartMutation,
  useGetEUINSQuery,
  useGetSchemaDetailsMutation,
} from '../slice';

interface InitialValues {
  stpOption: 'Fixed' | 'Capital Appreciation';
  frequency: string;
  amount: string;
  // noOfTransfers: string;
  stpDay: string;
  stpStartDate: Date | null;
  stpEndDate: Date | null;
  euin: 'Y' | 'N';
  euinValue: string;
  subArn: string;
  subBroker: string;
}

interface InvestmentDetailsFormProps {
  data: any;
  isEditMode: boolean;
  handleCancelModifyingCart?: () => void;
}

const DEFAULT_MIN_TAT = 7;

const InvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  data,
  isEditMode,
  handleCancelModifyingCart,
}) => {
  const [addStpItemToCart] = useAddItemToCartMutation();
  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [schemeDetails, setSchemeDetails] = useState<any>({});
  const [sipDay, setSipDay] = useState('1');
  const [frequencyType, setFrequencyType] = useState('');
  const firstButtonTitle = !isEditMode ? 'Add to Cart' : 'Cancel';
  const secondButtonTitle = !isEditMode ? 'Save & Proceed' : 'Save';
  const [calculatedInstallments, setCalculatedInstallments] = useState<number>(0);
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const { data: allEUINs } = useGetEUINSQuery([]);
  const { arn, subArn, subbrokerCode, euin } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const [firstEnabledDate, setFirstEnabledDate] = useState<Date>(new Date());

  const {
    name: investorName,
    pan,
    email,
    mobile,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);

  useEffect(() => {
    async function fetchData() {
      try {
        const payload = await getSchemaDetails({
          fund: data.fund,
          scheme: data.scheme,
          plan: data.plan,
          option: data.option,
          trType: 'STP',
          toScheme: data.scheme,
        }).unwrap();
        setSchemeDetails(payload);
      } catch (error: any) {
        showToast((error as { message: string }).message, 'error');
      }
    }
    fetchData();
  }, [data]);

  const handleAddToCartClick = async () => {
    try {
      const payload = {
        pan,
        mobile,
        email,
        folio: data.folio,
        fund: data.fund,
        fundName: data.fundName,
        scheme: data.scheme,
        plan: data.plan,
        option: data.option,
        toScheme: data.toScheme,
        toPlan: data.toPlan,
        toOption: data.toOption,
        amount: formikRef.current?.values.amount,
        subBroker: formikRef.current?.values.subBroker || '',
        subArn: formikRef.current?.values.subArn || '',
        euin: formikRef.current?.values.euinValue || '',
        transactionType: 'STP',
        category: data.category,
        stpOption: formikRef.current?.values.stpOption,
        frequency: formikRef.current?.values.frequency[0],
        stpDay: formikRef.current?.values.stpDay,
        stpStartDate: formikRef.current?.values.stpStartDate, // Selected Values from form
        stpEndDate: formikRef.current?.values.stpEndDate,
        noOfTransfers: calculatedInstallments || 0,
        investorName,
        switchOutSchemeName:
          'Quant Liquid Fund  Regular Plan Monthly IDCW' || data.schemeDesc,
        switchInSchemeName: 'Quant Dynamic Bond  Regular Plan IDCW' || data.schemeDesc,
        taxSaverFlag: data.taxSaverFlag,
      };
      await addStpItemToCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  async function handleSubmit(values: InitialValues) {
    try {
      const payload = {
        pan,
        mobile,
        email,
        folio: data.folio,
        fund: data.fund,
        fundName: data.fundName,
        scheme: data.scheme,
        plan: data.plan,
        option: data.option,
        toScheme: data.toScheme,
        toPlan: data.toPlan,
        toOption: data.toOption,
        amount: values.amount,
        subBroker: values.subBroker || '',
        euin: values.euinValue || '',
        subArn: values.subArn || '',
        transactionType: 'STP',
        category: data.category,
        stpOption: values.stpOption,
        frequency: values.frequency[0],
        stpDay: values.stpDay,
        stpStartDate: values.stpStartDate,
        stpEndDate: values.stpEndDate,
        noOfTransfers: calculatedInstallments || 0,
        investorName,
        switchOutSchemeName:
          'Quant Liquid Fund  Regular Plan Monthly IDCW' || data.schemeDesc,
        switchInSchemeName: 'Quant Dynamic Bond  Regular Plan IDCW' || data.schemeDesc,
        taxSaverFlag: `${data.taxSaverFlag}`,
      };
      navigate('/transaction-dashboard/confirmation', {
        state: { payload, isEditMode: isEditMode },
      });
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  }

  const handleGoToCart = () => {
    navigate('../../cart');
  };

  const handleAddAnotherTransaction = () => {
    navigate('../');
  };

  const [
    stpDates,
    minInstallments,
    minTransactionAmount,
    coolingPeriod,
    calculatedTatDate,
  ]: [string[], number, number, number, any] = useMemo(() => {
    const frequency = schemeDetails?.frequency?.find(
      (f: any) => f.mode === frequencyType,
    );
    const cycle = frequency?.cycle;
    const dates =
      !cycle || !/\d/.test(cycle)
        ? Array.from({ length: 28 }, (_, index) => `${index + 1}`)
        : cycle.split(',');

    const tatDate = calculateTatDate(frequency?.coolingPeriod);

    return [
      dates,
      frequency?.minInstallments,
      frequency?.minTransactionAmount,
      frequency?.coolingPeriod,
      tatDate,
    ];
  }, [schemeDetails?.frequency, frequencyType]);

  const stpModes: string[] = useMemo(() => {
    const modes = schemeDetails?.frequency?.map((f: any) => f.mode) || [];
    setFrequencyType(modes?.[0]);
    return Array.from(new Set(modes));
  }, [schemeDetails?.frequency]);

  const validationSchema = Yup.object().shape({
    stpOption: Yup.string()
      .oneOf(['Fixed', 'Capital Appreciation'], 'Select one option')
      .required('STP Option is required'),
    frequency: Yup.string()
      .oneOf(stpModes ?? [], 'Select one option')
      .required('STP Frequency is required'),
    amount: Yup.string()
      .test(
        'greaterThanMinTransactionAmount',
        `Investment Amount must be greater than ${
          minTransactionAmount ? minTransactionAmount : 0
        }`,
        function (value: any) {
          return parseFloat(value) > minTransactionAmount;
        },
      )
      .required('Amount is required'),
    // noOfTransfers: Yup.string().required('Number of transfers is required'),
    stpDay: Yup.string().required('STP date is required'),
    stpStartDate: Yup.string().required('STP start date is required'),
    stpEndDate: Yup.string().required('STP end date is required'),
    euin: Yup.string().required().oneOf(['Y', 'N']),
    euinValue: Yup.string().when('euin', {
      is: 'Y',
      then: Yup.string().required('EUIN value is required'),
    }),
  });

  const initialValues: InitialValues = {
    stpOption: 'Fixed',
    frequency: frequencyType || stpModes[0],
    amount: '',
    stpDay: stpDates[0],
    stpStartDate: null,
    stpEndDate: null,
    euin: 'Y',
    euinValue: euin,
    subArn,
    subBroker: subbrokerCode,
  };

  const shouldDisableDate = (date: Date) => {
    return disableDatesOnStartDate(date, stpDates, calculatedTatDate);
  };

  const shouldDisableDateForEndDate = (date: Date) => {
    return disableDatesOnEndDate(
      date,
      formikRef?.current?.values?.frequency.replace(/[^a-zA-Z]/g, '') as string,
      formikRef?.current?.values?.stpStartDate as Date,
      minInstallments,
    );
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Investorcard
            sx={(theme: Theme) => ({
              padding: '30px',
              [theme.breakpoints.down('sm')]: {
                padding: 0,
                boxShadow: 'unset',
                border: 'unset',
              },
            })}
          >
            <Grid
              container
              spacing={2}
              mb={'24px'}
            >
              <Grid
                item
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 2,
                    fontWeight: 500,
                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                  }}
                >
                  STP Type
                </Typography>
                <FormStyledRadioButton
                  options={[
                    { label: 'Fixed', value: 'Fixed' },
                    { label: 'Capital Appreciation', value: 'Capital Appreciation' },
                  ]}
                  name='stpOption'
                />
              </Grid>

              <Grid
                item
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 2,
                    fontWeight: 500,
                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                  }}
                >
                  STP Mode
                </Typography>
                <FormStyledRadioButton
                  options={
                    stpModes?.map((mode: string) => ({
                      label: mode,
                      value: mode,
                    })) || []
                  }
                  name='frequency'
                  handleChange={e => {
                    setFrequencyType(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mb={'24px'}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 2,
                    fontWeight: 500,
                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                  }}
                >
                  STP Installment Amount:
                </Typography>
                <FormTextInput
                  required={false}
                  name='amount'
                  placeholder='Enter the Investment Amount'
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mb={'24px'}
            >
              <Grid
                item
                sm={6}
                xs={12}
              >
                <FormSelectInput
                  name='stpDay'
                  label='STP Date'
                  options={stpDates.map(date => ({ label: date, value: date }))}
                  onChange={e => setSipDay(e.target.value as string)}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <FormDateInput
                  name='stpStartDate'
                  label='Time Period from'
                  placeholder='Time Period from'
                  shouldDisableDate={shouldDisableDate}
                  onChange={(date: any) => {
                    const day = getDayFromDate(date);
                    setFieldValue('stpDay', String(day));
                    const newDate = calculateNextInstallmentDate(
                      date,
                      frequencyType,
                      minInstallments,
                    );
                    setFirstEnabledDate(newDate);
                  }}
                />
                {minInstallments !== undefined && (
                  <Typography sx={{ mb: 2, my: { xs: 2, md: 0 } }}>
                    Minimum No. of Installments: {minInstallments}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <FormDateInput
                  name='stpEndDate'
                  placeholder='Time Period to'
                  label='Time Period to'
                  shouldDisableDate={shouldDisableDateForEndDate}
                  onChange={(date: any) => {
                    const endDate = parse(
                      format(date, 'yyyy-MM-dd'),
                      'yyyy-MM-dd',
                      new Date(),
                    );
                    const startDate = parse(
                      format(values?.stpStartDate as Date, 'yyyy-MM-dd'),
                      'yyyy-MM-dd',
                      new Date(),
                    );
                    const selectedInstallments = calculateDifference(
                      startDate,
                      endDate,
                      values?.frequency,
                    );
                    setCalculatedInstallments(selectedInstallments as number);
                  }}
                  defaultCalendarMonth={firstEnabledDate}
                  // disabled
                />
                {calculatedInstallments > 0 && (
                  <Typography sx={{ mb: 2, my: { xs: 2, md: 0 } }}>
                    Selected No. of Installments: {calculatedInstallments}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Typography
              variant='subtitle1'
              sx={{
                fontSize: {
                  xs: '16px',
                  xl: '18px',
                },
                fontWeight: 500,
                color: { xs: 'text.valueColor', sm: 'primary.main' },
                mb: 2,
              }}
            >
              Distributor Details
            </Typography>
            <Grid
              container
              spacing={2}
              mb={'24px'}
            >
              <Grid
                item
                sm={6}
                xs={12}
              >
                <FormTextInput
                  required={false}
                  name='arn'
                  label={'ARN Code'}
                  defaultValue={arn}
                  disabled
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <FormTextInput
                  required={false}
                  name='subArn'
                  label={'Sub ARN Code'}
                  defaultValue={subArn}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <FormTextInput
                  required={false}
                  name='subBroker'
                  label={'Sub Broker Code'}
                  defaultValue={subbrokerCode}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ mb: 1 }}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                  }}
                >
                  EUIN
                </Typography>
                <FormStyledRadioButton
                  options={[
                    { label: 'Yes', value: 'Y' },
                    { label: 'No', value: 'N' },
                  ]}
                  name='euin'
                />
              </Grid>
              {values.euin === 'Y' ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormSelectInput
                    name='euinValue'
                    label='Select EUIN'
                    options={
                      euin
                        ? [{ label: euin, value: euin }]
                        : allEUINs?.map((item: any) => ({
                            label: `${item.euin} - ${item.name}`,
                            value: item.euin,
                          }))
                    }
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    sx={{
                      bgcolor: 'text.lightGray',
                      padding: '20px',
                      borderRadius: '5px',
                      maxWidth: '600px',
                    }}
                  >
                    <Typography sx={{ color: 'text.valueColor', fontSize: '12px' }}>
                      I/We hereby confirm that the EUIN box has been intentionally left
                      the blank by me/us as this is an execution-only transaction without
                      any interaction or advice by any personnel of the above distributor
                      or notwithstanding the advice of in-appropriateness,if any provided
                      by any personnel of the distributor and the distributor has not
                      charged any advisory fees on this transaction
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{
                border: '1px dashed',
                borderColor: 'text.borderColorDark',
                my: 3,
              }}
            />
            <SubmitCartButtons
              onAddToCartClick={
                !isEditMode ? handleAddToCartClick : handleCancelModifyingCart
              }
              firstButtonTitle={firstButtonTitle}
              secondButtonTitle={secondButtonTitle}
            />
          </Investorcard>
          {openDialogBox && (
            <CustomDialog
              isVisible={true}
              firstButtonHandler={handleGoToCart}
              secondButtonHandler={handleAddAnotherTransaction}
              firstButtonTitle='Go to cart'
              secondButtonTitle='Add another Transaction'
              handleClose={() => {}}
              showSecondButton={true}
            >
              <Box sx={{ textAlign: 'center', pt: 2 }}>
                <img
                  src={VerifiedIcon}
                  alt='verified-icon'
                />
                <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
                <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                  Scheme is added to investor cart
                </FieldSubHeader3>
              </Box>
            </CustomDialog>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default InvestmentDetailsForm;
