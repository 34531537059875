import apiSlice from '../../service/api';

const onBoardingSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getpersonalDetails: builder.query({
      query: () => ({
        url: '/user/personal/get-personal-details',
        method: 'GET',
      }),
    }),
    personalDetails: builder.mutation({
      query: payload => ({
        url: '/user/personal/update-personal-details',
        method: 'POST',
        body: payload,
      }),
    }),
    getprimaryDetails: builder.query({
      query: () => ({
        url: '/user/primary/primary-details',
        method: 'GET',
      }),
    }),
    primaryDetails: builder.mutation({
      query: payload => ({
        url: '/user/primary/primary-details',
        method: 'POST',
        body: payload,
      }),
    }),
    getNomineeDetails: builder.query({
      query: () => ({
        url: '/user/nominee/get-nominees',
        method: 'GET',
      }),
    }),
    addNomineeDetails: builder.mutation({
      query: payload => ({
        url: '/user/nominee/add-nominees',
        method: 'POST',
        body: payload,
      }),
    }),
    // verify-bankdetails
    verifyBankDetails: builder.mutation({
      query: payload => ({
        url: '/user/bank-details/verify-account',
        method: 'POST',
        body: payload,
      }),
    }),
    getAuthSignDetails: builder.query({
      query: () => ({
        url: '/user/auth-sign',
        method: 'GET',
      }),
    }),
    addAuthSignsDetails: builder.mutation({
      query: payload => ({
        url: '/user/auth-sign',
        method: 'POST',
        body: payload,
      }),
    }),
    // upload-cheque
    uploadBlankChequeIndividual: builder.mutation({
      query: payload => ({
        url: '/user/bank-details/cheque-upload',
        method: 'POST',
        body: payload,
      }),
    }),
    // Check IFSC code
    checkIfsc: builder.mutation({
      query: payload => ({
        url: '/user/bank-details/check-ifsc',
        method: 'POST',
        body: payload,
      }),
    }),
    uploadDocuments: builder.mutation({
      query: payload => ({
        url: 'user/document/upload',
        method: 'POST',
        body: payload,
      }),
    }),
    //  Get all Accounts.
    getAllBankAccounts: builder.query({
      query: () => ({
        url: '/user/bank-details/get-bank-details',
        method: 'GET',
      }),
    }),
    // Delete an account
    deleteBankAccount: builder.mutation({
      query: payload => ({
        url: '/user/bank-details/delete-bank-details',
        method: 'POST',
        body: payload,
      }),
    }),
    // save-bank-detail
    saveBankDetail: builder.mutation({
      query: payload => ({
        url: '/user/bank-details/save-bank-detail',
        method: 'POST',
        body: payload,
      }),
    }),
    updateKydBankDetail: builder.mutation({
      query: payload => ({
        url: '/user/bank-details/update-kyd-bank-detail',
        method: 'POST',
        body: payload,
      }),
    }),
    getOnboardingStatus: builder.query({
      query: () => ({
        url: '/user/get-onboarding-status',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetpersonalDetailsQuery,
  usePersonalDetailsMutation,
  useLazyGetprimaryDetailsQuery,
  usePrimaryDetailsMutation,
  useLazyGetNomineeDetailsQuery,
  useAddNomineeDetailsMutation,
  useVerifyBankDetailsMutation,
  useUploadBlankChequeIndividualMutation,
  useCheckIfscMutation,
  useLazyGetAuthSignDetailsQuery,
  useAddAuthSignsDetailsMutation,
  useUploadDocumentsMutation,
  useLazyGetAllBankAccountsQuery,
  useDeleteBankAccountMutation,
  useSaveBankDetailMutation,
  useUpdateKydBankDetailMutation,
  useLazyGetOnboardingStatusQuery,
} = onBoardingSlice;
