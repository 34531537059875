import apiSlice from '../../service/api';

const queriesSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    customerSupport: builder.mutation({
      query: payload => ({
        url: '/user/create-customer-support-request',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useCustomerSupportMutation } = queriesSlice;
