import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { ReactComponent as CAN } from '../../../../assets/Reports/MFS/can-based-account-statement.svg';
import { ReactComponent as CAS } from '../../../../assets/Reports/MFS/consolidate-account-statement.svg';
import { ReactComponent as ELSS } from '../../../../assets/Reports/MFS/elss-account-statement.svg';
import { ReactComponent as CapitalGains } from '../../../../assets/Reports/MFS/gains-statment.svg';
import { ReactComponent as GFAS } from '../../../../assets/Reports/MFS/grandfathering-account-statement.svg';
import { ReactComponent as NetworthReport } from '../../../../assets/Reports/MFS/networth-report.svg';
import { ReactComponent as SOA } from '../../../../assets/Reports/MFS/soa.svg';
import { PageNavigator } from '../../../common/BackToHomePage';
import CardGroups, { CardItem } from '../../../common/CardGroups';
import { useRootContext } from '../../../data/root.context';
import StyledRadioButtonGroup from '../../../forms/FormStyledRadioButton';
import { useLazyAllSchemeDetailsQuery } from '../../mfs/newInvestorOnboarding/slice';
import ReportFor from './ReportFor';

export interface Option {
  value: string;
  label: string;
}

interface Scheme {
  fund: string;
  fundName: string;
}

export default function MFSReports() {
  const navigate = useNavigate();
  const [reportTo, setReportTo] = useState('Investor');
  const { showToast } = useRootContext();
  const [getSchemes, getAllFundResponse] = useLazyAllSchemeDetailsQuery();
  const [amcData, setAmcData] = useState<any[]>([]);

  const reportsArr: CardItem[] = [
    {
      label: 'SOA',
      route: 'soa',
      icon: SOA,
    },
    {
      label: 'Consolidate Account Statement',
      route: 'consolidate-account-statement',
      icon: CAS,
    },
    {
      label: 'ELSS Account Statement',
      route: 'elss-account-statement',
      icon: ELSS,
    },
    {
      label: 'CAN Based Account Statement',
      route: 'can-based-account-statement',
      icon: CAN,
    },
    {
      label: 'Capital Gains Statement',
      route: 'capital-gain-statement',
      icon: CapitalGains,
    },
    {
      label: 'Grandfathering Account Statement',
      route: 'grandfathering-account-statement',
      icon: GFAS,
    },
    { label: 'Networth Report', route: 'networth-report', icon: NetworthReport },
    {
      label: 'Consolidated Capital Gains Statement',
      route: 'consolidated-capital-gains-statement',
      icon: CapitalGains,
    },
  ];

  useEffect(() => {
    try {
      getSchemes('');
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    }
  }, []);

  useEffect(() => {
    if (
      getAllFundResponse &&
      getAllFundResponse.isSuccess &&
      !getAllFundResponse.isFetching
    ) {
      const getAllSchemes = async () => {
        const allAmcs: Option[] = getAllFundResponse?.data?.map((fund: Scheme) => ({
          label: fund.fundName,
          value: fund.fund,
        }));
        setAmcData(allAmcs);
      };
      getAllSchemes();
    }
  }, [getAllFundResponse]);

  const handleRoutes = (route: string) => {
    navigate(`./investor-selection`, {
      state: {
        route: route,
      },
    });
  };

  return (
    <>
      <PageNavigator title='Reports' />

      <StyledRadioButtonGroup
        options={[
          { label: 'Myself', value: 'Myself' },
          { label: 'Investor', value: 'Investor' },
        ]}
        handleChange={e => setReportTo(e.target.value)}
        value={reportTo}
      />

      {reportTo !== 'Investor' ? (
        <ReportFor amcOptions={amcData} />
      ) : (
        <Box sx={{ mt: 3 }}>
          <CardGroups
            data={reportsArr}
            handleRoutes={handleRoutes}
          />
        </Box>
      )}
    </>
  );
}
