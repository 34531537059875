import apiSlice from '../../../service/api';

const cartSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    checkoutCart: builder.mutation({
      query: payload => ({
        url: 'user/transactions/checkout-cart',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useCheckoutCartMutation } = cartSlice;
