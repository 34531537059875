import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import InvestorProfileIcon from '../../assets/investor-profile.png';
import { Investorcard } from '../auth/login/styles/styledComponents';
import { TransactionResponse } from '../features/transactions/transactionSlice';
export function ConfirmationProfile({ data }: { data?: any }) {
  const { arn, name, subArn } = useSelector((state: { login: any }) => state.login);
  return (
    <Investorcard
      sx={{ bgcolor: 'text.rightProfileCard', display: { xs: 'none', md: 'block' } }}
    >
      <Box
        sx={{
          padding: '30px 20px',
          borderBottom: '0.5px solid ',
          borderColor: 'text.borderColorLight',
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            variant='subtitle1'
            sx={{ color: 'primary.main' }}
          >
            Investor Profile
          </Typography>
          <CardMedia
            component='img'
            image={InvestorProfileIcon}
            alt='Mutual Fund Pooled Investment'
            sx={{ width: '44px', height: '40px' }}
          />
        </Stack>
        <Grid
          container
          spacing={2}
          my='10px'
        >
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Name</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {data?.investorName || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>PAN</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {data?.pan || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Mobile Number</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {data?.mobile || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Email</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {data?.email || 'NA'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '30px 20px' }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            variant='subtitle1'
            sx={{ color: 'primary.main' }}
          >
            Distrubutor Profile
          </Typography>
          <CardMedia
            component='img'
            image={InvestorProfileIcon}
            alt='Mutual Fund Pooled Investment'
            sx={{ width: '44px', height: '40px' }}
          />
        </Stack>
        <Grid
          container
          spacing={2}
          my='10px'
        >
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>ARN Code</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {arn || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>ARN Name</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {name || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Sub ARN Code</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {subArn || 'NA'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Investorcard>
  );
}

export default function InvestorProfile({
  children,
  bottomComponent,
}: {
  children?: React.ReactNode;
  bottomComponent?: React.ReactNode;
}) {
  const { name, pan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  return (
    <>
      <Investorcard
        sx={{
          bgcolor: 'text.rightProfileCard',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Box
          sx={{
            padding: '30px 20px',
          }}
        >
          <Stack
            direction='column'
            gap='16px'
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography
                variant='subtitle1'
                sx={{ color: 'primary.main' }}
              >
                Investor Profile
              </Typography>
              <CardMedia
                component='img'
                image={InvestorProfileIcon}
                alt='Mutual Fund Pooled Investment'
                sx={{ width: '44px', height: '40px' }}
              />
            </Stack>
            {Boolean(name) && (
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>Name</Typography>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  {name}
                </Typography>
              </Stack>
            )}
            {Boolean(pan) && (
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>PAN</Typography>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  {pan}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
        {children}
      </Investorcard>
      {bottomComponent}
    </>
  );
}
