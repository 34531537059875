import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import BreadCrumb_Icon from '../../../assets/breadcrumb-icon.svg';
import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { ConfirmationProfile } from '../../common/InvestorProfile';
import { useRootContext } from '../../data/root.context';
import { StepperNextButton } from '../../onboarding/styles/styledComponents';
import AdditionalPurchaseConfirmation from '../../transactions/additionalPurchase/Confirmation';
import {
  useNewPurchaseDirectMutation,
  useNewPurchaseDirectSipMutation,
  useNewPurchaseSipMutation,
} from '../../transactions/mfs/newInvestorOnboarding/slice';
import RedeemConfirmation from '../../transactions/redeem/Confirmation';
import {
  useAddItemInSwpMutation,
  useAddItemToSwitchMutation,
  useMakeDirectAdditionalPurchaseMutation,
  useMakeSIPAdditionalPurchaseMutation,
  useMakeSipAndLumpsumAdditionalPurchaseMutation,
  useModifyItemInCartMutation,
  useRedeemptionMutation,
  useStpTransactionMutation,
} from '../../transactions/slice';
import StpConfirmation from '../../transactions/stp/Confirmation';
import SwitchConfirmation from '../../transactions/switch/Confirmation';
import SwpConfirmation from '../../transactions/swp/Confirmation';
import { useOrderReinitiateMutation } from '../slice';
import NewPurchaseConfirmation from './NewPurchaseConfirmation';

interface ResponseObject {
  [key: string]: any;
}

interface OrderResponse {
  response?: ResponseObject;
}

interface ConfirmationRendererProps {
  transactionType: string;
  responseData: any;
}

const ConfirmationRenderer: React.FC<ConfirmationRendererProps> = ({
  transactionType,
  responseData,
}) => {
  switch (transactionType) {
    case 'STP':
      return <StpConfirmation data={responseData} />;
    case 'SWITCH':
      return <SwitchConfirmation data={responseData} />;
    case 'RED':
      return <RedeemConfirmation data={responseData} />;
    case 'SWP':
      return <SwpConfirmation data={responseData} />;
    case 'ISIP':
    case 'APL':
      return <AdditionalPurchaseConfirmation data={responseData} />;
    case 'NPL':
    case 'SINI':
    case 'SIN':
      return <NewPurchaseConfirmation data={responseData} />;
    default:
      return null;
  }
};

function Reinitiate() {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state;
  const { showToast } = useRootContext();
  const [orderResponse, setOrderResponse] = useState<OrderResponse>({});
  const [requestId, setRequestId] = useState('');
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [orderReinitiate] = useOrderReinitiateMutation();
  const [reedemScheme] = useRedeemptionMutation();
  const [modifyItemInCart] = useModifyItemInCartMutation();
  const [makeSwpTransaction] = useAddItemInSwpMutation();
  const [addItemToSwitch] = useAddItemToSwitchMutation();
  // All AlP APIs.
  const [makeDirectAdditionalPurchase] = useMakeDirectAdditionalPurchaseMutation();
  const [makeSIPAdditionalPurchase] = useMakeSIPAdditionalPurchaseMutation();
  const [makeSipAndLumpsumAdditionalPurchase] =
    useMakeSipAndLumpsumAdditionalPurchaseMutation();
  const [newPurchaseDirect] = useNewPurchaseDirectMutation();
  const [newPurchaseSip] = useNewPurchaseSipMutation();
  const [newPurchaseDirectSip] = useNewPurchaseDirectSipMutation();
  const [stpPost] = useStpTransactionMutation();

  const keysToRemove = [
    'user_agent',
    'userId',
    'dcr',
    'arnCode',
    'distributorID',
    'userAgent',
    'arn',
    'subArn',
    'euin',
    'euinDeclaration',
    'parentIhno',
  ];

  const filterKeys = (obj: ResponseObject) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => !keysToRemove.includes(key)),
    );

  const confirmButtonHandler = async () => {
    try {
      let response;

      switch (orderResponse?.response?.transactionType) {
        case 'RED':
          response = await reedemScheme(filterKeys(orderResponse?.response)).unwrap();
          break;
        case 'STP':
          response = await stpPost(filterKeys(orderResponse?.response)).unwrap();
          break;
        case 'SWP':
          response = await makeSwpTransaction(
            filterKeys(orderResponse?.response),
          ).unwrap();
          break;
        case 'SWITCH':
          response = await addItemToSwitch(filterKeys(orderResponse?.response)).unwrap();
          break;
        case 'NPL':
          response = await newPurchaseDirect(
            filterKeys(orderResponse?.response),
          ).unwrap();
          break;
        case 'SINI':
          if (
            orderResponse?.response?.installmentAmount !== 0 &&
            orderResponse?.response?.amount === 0
          ) {
            response = await newPurchaseSip(filterKeys(orderResponse?.response)).unwrap();
          } else if (
            orderResponse?.response?.installmentAmount !== 0 &&
            orderResponse?.response?.amount !== 0
          ) {
            response = await newPurchaseDirectSip(
              filterKeys(orderResponse?.response),
            ).unwrap();
          } else {
            response = await makeDirectAdditionalPurchase(
              filterKeys(orderResponse?.response),
            ).unwrap();
          }
          break;
        case 'ISIP':
          response = await makeSIPAdditionalPurchase(
            filterKeys(orderResponse?.response),
          ).unwrap();
          break;
        case 'SIN':
          response = await makeSipAndLumpsumAdditionalPurchase(
            filterKeys(orderResponse?.response),
          ).unwrap();
          break;
        default:
          // Handle other transaction type
          break;
      }

      if (response) {
        setRequestId(response?.ihno);
      }
      setOpenDialogBox(true);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  useEffect(() => {
    const getOrders = async () => {
      try {
        const payload = {
          ihno: item?.orders[0]?.dco_ihno,
        };
        const result = await orderReinitiate(payload).unwrap();
        setOrderResponse(result);
      } catch (error: any) {
        showToast(
          (error as { message: string })?.message || error?.data?.message,
          'error',
        );
      }
    };
    getOrders();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          sm={8}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Button
              sx={{
                color: 'text.labelColor',
                p: 0,
              }}
              disableFocusRipple={true}
            >
              Home
            </Button>
            <img
              src={BreadCrumb_Icon}
              alt='breadcrumb-icon'
            />
            <Button>
              <Typography sx={{ color: 'primary.main', fontWeight: 500 }}>
                {orderResponse?.response?.transactionType}
              </Typography>
            </Button>
          </Box>
          <Investorcard sx={{ padding: '35px 35px' }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                fontWeight: 500,
                color: 'primary.main',
                mb: 2,
              }}
            >{`Confirm re-initiation of ${orderResponse?.response?.transactionType} transaction`}</Typography>

            <ConfirmationRenderer
              transactionType={orderResponse?.response?.transactionType}
              responseData={orderResponse?.response}
            />
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{ border: '1px dashed', borderColor: 'text.borderColorLight', my: 3 }}
            />
            <Box>
              <StepperNextButton
                sx={{ minWidth: { xs: '100%', md: '220px' }, padding: '8px 20px' }}
                onClick={confirmButtonHandler}
              >
                Confirm
              </StepperNextButton>
            </Box>
          </Investorcard>
        </Grid>
        <Grid
          item
          sm={4}
        >
          <ConfirmationProfile data={orderResponse?.response} />
        </Grid>
      </Grid>
      <CustomDialog
        isVisible={openDialogBox}
        secondButtonHandler={() => {
          setOpenDialogBox(false);
          navigate('/transaction-dashboard/dashboard');
        }}
        secondButtonTitle='Ok'
        firstButtonTitle=''
        showFirstButton={false}
        showSecondButton
        handleClose={() => {}}
        firstButtonHandler={() => {}}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'opacity': '0.85',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />

          <Typography sx={{ color: 'text.darkGreen', fontSize: '16px', fontWeight: 500 }}>
            Successfully Re-Initiated Transaction
          </Typography>
          <Typography variant='body1'>
            {`Transaction for ${orderResponse?.response?.transactionType} has been initiated Successfully`}
          </Typography>
          <Typography variant='body1'>{`Your investor will receive the details via an mail and sms with reference number ${requestId}`}</Typography>
        </Box>
      </CustomDialog>
    </>
  );
}

export default Reinitiate;
