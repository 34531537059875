import { Box, Grid, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../../data/root.context';
import FormDateInput from '../../../../forms/FormDateInput';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput, { FormPasswordInput } from '../../../../forms/FormTextInput';
import Layout from '../../../Layout';
import { dateToDDMMYYYY } from '../../../mfs/newInvestorOnboarding/utils';
import { useElssReportMutation } from '../../slice';
import {
  getDatesForCurrentFinancialYr,
  handleDetailsOptionChange,
  validateDates,
  YYMMDDtoMMDDYYYY,
} from '../../utils';
import { AccountStatementType } from '../consolidateAccountStatement';

export default function ElssAccountStatement() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [responseMsg, setResponseMsg] = useState<string>('');

  const [getElssStatement] = useElssReportMutation();

  const initialValues: AccountStatementType = {
    statementType: 'Summary',
    asOnDate: new Date().toISOString().split('T')[0] || '',
    detailedOption: 'Current Financial Year',
    startDate: `${getDatesForCurrentFinancialYr().startDate}` || '',
    endDate: `${getDatesForCurrentFinancialYr().endDate}` || '',
    emailId: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    statementType: Yup.string().required('Statement type is required'),
    asOnDate: Yup.string().when('statementType', {
      is: 'Summary',
      then: Yup.string().required('As on date is required'),
    }),
    detailedOption: Yup.string().when('statementType', {
      is: 'Detailed',
      then: Yup.string().required('Detailed option is required'),
    }),
    startDate: Yup.string().when('statementType', {
      is: 'Detailed',
      then: Yup.string().required('Start date is required').nullable(),
    }),
    endDate: Yup.string().when('statementType', {
      is: 'Detailed',
      then: Yup.string().required('End date is required').nullable(),
    }),
    emailId: Yup.string()
      .required('Email ID is required')
      .matches(
        /^(\w[-._+]{0,1})*\w+@((\w)+[-]{0,1})*(\w)+(\.\w+)+\w$/,
        'email must be a valid email',
      ),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]+$/,
        'Password should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)',
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .test(
        'passwordMatch',
        'The Password and confirm Password do not match',
        function (value) {
          return value === this.parent.password;
        },
      ),
  });

  const handleSubmit = async (values: AccountStatementType) => {
    const params = {
      fund: '0',
      invEmail: values.emailId,
      folio: '0',
      fromDate:
        values.statementType === 'Summary'
          ? '01/01/1970'
          : dateToDDMMYYYY(values.startDate),
      toDate:
        values.statementType === 'Summary'
          ? YYMMDDtoMMDDYYYY(values.asOnDate)
          : dateToDDMMYYYY(values.endDate),
      invPan: pan,
      soaPwd: values.password,
      broker: '',
      brokerEmail: values.emailId,
      stmtFlag: values.statementType,
    };
    try {
      const response: any = await getElssStatement(params).unwrap();
      if (response?.message) {
        setResponseMsg(response?.message);
        setOpenDialogBox(true);
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  return (
    <>
      <Layout title='ELSS Account Statement'>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            sm={12}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                padding: '35px 35px',
                [theme.breakpoints.down('sm')]: {
                  boxShadow: 'unset',
                  border: 'unset',
                  padding: '0px',
                },
              })}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validate={values => validateDates(values)}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form>
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Typography
                            variant='subtitle2'
                            sx={{ mb: 2 }}
                          >
                            Statement Type
                          </Typography>
                          <FormStyledRadioButton
                            options={[
                              { label: 'Summary', value: 'Summary' },
                              { label: 'Detailed', value: 'Detailed' },
                            ]}
                            name='statementType'
                          />
                        </Grid>
                        {values.statementType === 'Detailed' ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              mt={2}
                            >
                              <Typography
                                variant='subtitle2'
                                sx={{ mb: 2 }}
                              >
                                Detailed Option
                              </Typography>
                              <FormStyledRadioButton
                                options={[
                                  {
                                    label: 'Current Financial Year',
                                    value: 'Current Financial Year',
                                  },
                                  {
                                    label: 'Previous Financial Year',
                                    value: 'Previous Financial Year',
                                  },
                                  {
                                    label: 'Specified Period',
                                    value: 'Specified Period',
                                  },
                                ]}
                                name='detailedOption'
                                handleChange={e =>
                                  handleDetailsOptionChange(e, setFieldValue)
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              sm={6}
                              xs={12}
                            >
                              <FormDateInput
                                name='startDate'
                                label='Start Date'
                                required={true}
                                disabled={values.detailedOption !== 'Specified Period'}
                                disableFuture
                              />
                            </Grid>
                            <Grid
                              item
                              sm={6}
                              xs={12}
                            >
                              <FormDateInput
                                name='endDate'
                                label='End Date'
                                required={true}
                                disabled={
                                  values.detailedOption !== 'Specified Period' ||
                                  values.startDate === '' ||
                                  values.startDate === null
                                }
                                disableFuture
                              />
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            item
                            sm={6}
                            xs={12}
                          >
                            <FormDateInput
                              name='asOnDate'
                              label='As Date On'
                              required={true}
                              disabled
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormTextInput
                            name='emailId'
                            label='Email ID'
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        mt={1}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                        >
                          <FormPasswordInput
                            name='password'
                            label='Self Extraction Password'
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                        >
                          <FormPasswordInput
                            name='confirmPassword'
                            label='Confirm Self Extraction Password'
                          />
                        </Grid>
                      </Grid>
                      <Box sx={{ display: 'flex', mt: 5 }}>
                        <SubmitButton
                          sx={(theme: Theme) => ({
                            '&.MuiButtonBase-root': {
                              borderRadius: '5px',
                              minWidth: '200px',

                              [theme.breakpoints.down('sm')]: {
                                minWidth: '100%',
                              },
                            },
                          })}
                          label='Submit'
                        />
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Investorcard>
          </Grid>
        </Grid>
        <CustomDialog
          isVisible={openDialogBox}
          showFirstButton={false}
          firstButtonHandler={() => {}}
          firstButtonTitle=''
          showSecondButton
          secondButtonTitle='Continue'
          secondButtonHandler={() => navigate('/transaction-dashboard/dashboard')}
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& img': {
                maxWidth: { xs: '17%', md: '70%' },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography
              variant='h3'
              sx={{ color: 'primary.main', my: 2 }}
            >
              Successfully submitted your request
            </Typography>
            <Typography variant='subtitle1'>{responseMsg}</Typography>
          </Box>
        </CustomDialog>
      </Layout>
    </>
  );
}
