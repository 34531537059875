import { FormikProps } from 'formik';
import React from 'react';

import AdditionalPurchaseCart from '../additionalPurchase/Cart';
import AdditionalPurchaseConfirmation from '../additionalPurchase/Confirmation';
import EditTransactionAPL from '../additionalPurchase/EditTransaction';
import { CheckoutPaymentFormValues } from '../additionalPurchase/types';
import NewPurchaseCart from '../newPurchase/Cart';
import UpdateInvestmentDetailsForm from '../newPurchase/UpdateInvetmentDetails';
import RedCart from '../redeem/Cart';
import RedConfirmation from '../redeem/Confirmation';
import RedInvestmentDetailsForm from '../redeem/InvestmentDetailsForm';
import StpCart from '../stp/Cart';
import StpConfirmation from '../stp/Confirmation';
import StpInvestmentDetailsForm from '../stp/InvestmentDetailsForm';
import SwitchCart from '../switch/Cart';
import SwitchConfirmation from '../switch/Confirmation';
import SwitchInvestmentDetailsForm from '../switch/InvestmentDetailsForm';
import SwpCart from '../swp/Cart';
import SwpConfirmation from '../swp/Confirmation';
import SwpInvestmentDetailsForm from '../swp/InvestmentDetailsForm';
import { TransactionComponentsType, TransactionType } from '../types';

// interface for transaction components
interface TransactionComponents {
  [key: string]: {
    Cart: React.FC<{
      data?: any;
      children?: React.ReactNode;
    }>;
    Confirmation?: React.FC<{
      data: any;
      formikRef?: any;
    }>;
    InvestmentDetailsForm: React.FC<{
      data: any;
      isEditMode: boolean;
      handleCancelModifyingCart?: () => void;
      cartItemslength?: boolean;
    }>;
  };
}

// components for different types of transactions
const transactionComponents: TransactionComponents = {
  SWITCH: {
    Cart: props => <SwitchCart {...props} />,
    Confirmation: props => <SwitchConfirmation {...props} />,
    InvestmentDetailsForm: props => <SwitchInvestmentDetailsForm {...props} />,
  },
  SWP: {
    Cart: props => <SwpCart {...props} />,
    Confirmation: props => <SwpConfirmation {...props} />,
    InvestmentDetailsForm: props => <SwpInvestmentDetailsForm {...props} />,
  },
  RED: {
    Cart: props => <RedCart {...props} />,
    Confirmation: props => <RedConfirmation {...props} />,
    InvestmentDetailsForm: props => <RedInvestmentDetailsForm {...props} />,
  },
  STP: {
    Cart: props => <StpCart {...props} />,
    Confirmation: props => <StpConfirmation {...props} />,
    InvestmentDetailsForm: props => <StpInvestmentDetailsForm {...props} />,
  },
  NPL: {
    Cart: props => <NewPurchaseCart {...props} />,
    InvestmentDetailsForm: props => <UpdateInvestmentDetailsForm {...props} />,
  },
  SINI: {
    Cart: props => <NewPurchaseCart {...props} />,
    InvestmentDetailsForm: props => <UpdateInvestmentDetailsForm {...props} />,
  },
  APL: {
    Cart: props => <AdditionalPurchaseCart {...props} />,
    Confirmation: props => <AdditionalPurchaseConfirmation {...props} />,
    InvestmentDetailsForm: props => <EditTransactionAPL {...props} />,
  },
};

// abstract factory function
const TransactionFactory: React.FC<{
  type: TransactionType;
  component: TransactionComponentsType;
  children?: React.ReactNode;
  data?: any;
  isEditMode?: boolean;
  cartItemslength?: boolean;
  handleCancelModifyingCart?: () => void;
  formikRef?: React.RefObject<FormikProps<CheckoutPaymentFormValues>> | null;
}> = ({
  type,
  component,
  children,
  data,
  isEditMode,
  handleCancelModifyingCart,
  cartItemslength,
  formikRef = null,
}) => {
  const Component = transactionComponents[type]?.[component];
  return Component ? (
    <Component
      data={data}
      isEditMode={!!isEditMode}
      handleCancelModifyingCart={handleCancelModifyingCart}
      cartItemslength={cartItemslength}
      formikRef={formikRef}
    >
      {children}
    </Component>
  ) : null;
};

export default TransactionFactory;
