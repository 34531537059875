import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';

import { ReactComponent as Filter } from '../../assets/filter.svg';
import { CustomSearch } from './search';

type Anchor = 'bottom';

interface SearchInputBoxProps {
  showSearch?: boolean;
  data?: Record<string, string>;
  filterTitle?: string;
  placeholder?: string;
  query?: string;
  dropDownHandler?: (selectedOption: string) => void;
  queryChangeHandler?: (value: string) => void;
  selectedDropdownValue?: string;
}

const anchor: Anchor = 'bottom';

const SearchBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'auto',
  marginBottom: '16px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const FilterBox = styled(Box)(({ theme }) => ({
  borderLeft: '1px solid',
  borderColor: theme.palette.text.borderColorLight,
  position: 'absolute',
  top: '0px',
  right: '0px',
  height: '100%',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
}));

const SearchIconContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchInputBox: React.FC<SearchInputBoxProps> = ({
  data,
  filterTitle = 'AMC',
  placeholder = '',
  query = '',
  dropDownHandler = () => {},
  queryChangeHandler = () => {},
  selectedDropdownValue = '',
  showSearch = true,
}) => {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <Typography sx={{ color: 'text.labelColor' }}>{filterTitle}</Typography>
        </ListItem>

        {Object.entries(data || {}).map(([key, value]) => (
          <ListItem
            key={key}
            disablePadding
            sx={{
              '&:not(last-child)': {
                borderBottom: '1px solid',
                borderColor: 'text.borderColorLight',
              },
              '& .MuiTypography-root ': {
                fontWeight: 500,
              },
              '& .MuiButtonBase-root.MuiRadio-root': {
                'color': 'text.darkGrayBlue',
                '& .MuiSvgIcon-root': {
                  width: '16px',
                  height: '16px',
                },
                '&.Mui-checked': {
                  color: 'primary.main',
                },
              },
            }}
          >
            <ListItemButton>
              <ListItemText primary={key} />
              <Radio
                checked={data && selectedDropdownValue === data[value]}
                onChange={() => dropDownHandler(value)}
                value={key}
                name='radio-buttons'
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <SearchBox>
      {showSearch && (
        <CustomSearch
          value={query}
          placeholder={placeholder}
          onChange={queryChangeHandler}
        />
      )}

      {Object.entries(data || {}).length > 0 && (
        <FilterBox>
          <Button
            startIcon={<Filter />}
            onClick={toggleDrawer(anchor, true)}
          >
            {filterTitle}
          </Button>
          <Drawer
            sx={{
              '& .MuiPaper-root ': {
                borderRadius: '30px 30px 0px 0px',
                backgroundColor: 'common.white',
              },
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </FilterBox>
      )}
    </SearchBox>
  );
};

export default SearchInputBox;
