import { Box, Grid, IconButton, Stack, Theme, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from '../../../../../assets/edit-icon.svg';
import VerifiedIcon from '../../../../../assets/verified-cart-icon.png';
import {
  DetailsHeader,
  FieldSubHeader3,
  StepperNextButton,
} from '../../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../../data/root.context';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import { useSendEmailMutation } from '../slice';
import { generateUrl } from '../utils';

const Summary: React.FC = () => {
  const {
    state: {
      ackNumber: AckNumber,
      personalDetails,
      kycDetails,
      accountType,
      nomineeDetails,
      bankDetails,
      schemeDetails,
      documentDetails,
    },
  } = useNewSubscriberContext();
  const personalDetailsData = personalDetails.data;
  const [url, setUrl] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const { showToast } = useRootContext();
  const navigate = useNavigate();

  const hasTierTwo = accountType?.data?.AccountType === '2';
  const [sendEmail] = useSendEmailMutation();
  const handleSubmit = async () => {
    try {
      const link = generateUrl({
        AckNumber,
        agentId: '0018',
      });
      setUrl(link);
      const params = {
        name: personalDetailsData.Name,
        link,
        emailAddress: personalDetailsData.Email,
      };
      await sendEmail(params).unwrap();
      setDialogOpen(true);
    } catch (error) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  return (
    <>
      <InvestorDetails investorDetailsData={personalDetails?.data} />
      <KycDetails kycDetailsData={kycDetails?.data} />
      <NomineeDetails
        nomineeDetailsData={nomineeDetails?.data}
        hasTierTwo={hasTierTwo}
      />
      <BankDetails
        bankDetailsData={bankDetails?.data}
        hasTierTwo={hasTierTwo}
      />
      <UploadedDocuments
        documents={documentDetails?.documents}
        hasTierTwo={hasTierTwo}
      />
      <SchemeDetails
        schemeDetailsData={schemeDetails?.data}
        hasTierTwo={hasTierTwo}
      />
      <AmountPayable
        schemeDetailsData={schemeDetails?.data}
        hasTierTwo={hasTierTwo}
      />
      <StepperNextButton
        onClick={handleSubmit}
        sx={{
          '&.MuiButtonBase-root': {
            minWidth: { xs: '100%', sm: '220px' },
          },
        }}
      >
        Save & Proceed
      </StepperNextButton>
      {dialogOpen && (
        <CustomDialog
          isVisible={true}
          firstButtonHandler={async () => (
            setCopyDialogOpen(true),
            setDialogOpen(false),
            await navigator.clipboard.writeText(url)
          )}
          firstButtonTitle='Copy'
          showSecondButton
          secondButtonTitle='Close'
          secondButtonHandler={() => navigate('../../../')}
          handleClose={() => setDialogOpen(false)}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                'my': 2,
                '&:last-of-type': {
                  fontWeight: '600',
                },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography>
              Your Investor will receive the confirmation details and verification link
              via an email and sms.
            </Typography>
            <Typography>Would you like to copy the confirmation link?</Typography>
          </Box>
        </CustomDialog>
      )}

      {copyDialogOpen && (
        <CustomDialog
          isVisible={true}
          firstButtonHandler={() => navigate('../../../')}
          firstButtonTitle='OK'
          handleClose={() => setCopyDialogOpen(false)}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                fontSize: '14px',
                color: 'text.primary',
                fontWeight: '600',
                my: 2,
              },
              '& img': {
                width: { xs: '50px', sm: 'inherit' },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography variant='body1'>URL copied to clipboard</Typography>
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

const InvestorDetails = ({ investorDetailsData }: any) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        my: 2,
        // padding: '20px',
        border: '0.3px solid',
        borderColor: 'text.borderColorDark',
      }}
    >
      <Box
        sx={{
          borderRadius: '15px 15px 0px 0px',
          background: 'text.boxColor',
          padding: '5px 20px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <FieldSubHeader3>Investor Details</FieldSubHeader3>
          <Box>
            <IconButton
              onClick={() => {
                //TODO: implement edit
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ padding: '5px 20px 20px' }}>
        <Grid
          container
          my='5px'
          spacing={2}
          sx={(theme: Theme) => ({
            '&>.MuiGrid-item ': {
              pb: 2,
            },
            '& .MuiTypography-root': {
              fontSize: '14px',
            },
            '& .MuiTypography-h5 ': {
              fontWeight: '400',
              color: theme.palette.text.labelColor,
            },
            '& .MuiTypography-body1 ': {
              fontWeight: '500',
              color: '#212B36',
            },
          })}
        >
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Applicant Name:</Typography>

              <Typography component='span'>
                {investorDetailsData?.Name ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Email Id:</Typography>

              <Typography component='span'>
                {investorDetailsData?.Email ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Mobile No.:</Typography>

              <Typography component='span'>
                {investorDetailsData?.Mobile ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Father&apos;s Name: </Typography>

              <Typography component='span'>
                {investorDetailsData?.FatherFirstName ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Mother&apos;s Name:</Typography>

              <Typography component='span'>
                {investorDetailsData?.MotherFirstName ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Gender:</Typography>

              <Typography component='span'>
                {investorDetailsData?.Gender ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Date of Birth:</Typography>

              <Typography component='span'>
                {investorDetailsData?.DOB ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Occupation:</Typography>

              <Typography component='span'>
                {investorDetailsData?.OccupationDes ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Maritial Status:</Typography>

              <Typography component='span'>
                {investorDetailsData?.MaritalStatusDes ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>City:</Typography>

              <Typography component='span'>
                {investorDetailsData?.CityofBirth ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>State:</Typography>

              <Typography component='span'>
                {investorDetailsData?.ResidenceTypeDes ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Country:</Typography>

              <Typography component='span'>
                {investorDetailsData?.CountryofBirthDes ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Nationality:</Typography>

              <Typography component='span'>
                {investorDetailsData?.Nationality ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const KycDetails = ({ kycDetailsData }: any) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        my: 2,
        // padding: '20px',
        border: '0.3px solid',
        borderColor: 'text.borderColorDark',
      }}
    >
      <Box
        sx={{
          borderRadius: '15px 15px 0px 0px',
          background: 'text.boxColor',
          padding: '5px 20px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <FieldSubHeader3>KYC Details</FieldSubHeader3>
          <Box>
            <IconButton
              onClick={() => {
                //TODO: implement edit
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ padding: '5px 20px 20px' }}>
        <Grid
          container
          my='5px'
          spacing={2}
          sx={{
            '&>.MuiGrid-item ': {
              pb: 2,
            },
            '& .MuiTypography-root': {
              fontSize: '14px',
            },
            '& .MuiTypography-h5 ': {
              fontWeight: '400',
              color: '#6C737F',
            },
            '& .MuiTypography-body1 ': {
              fontWeight: '500',
              color: '#212B36',
            },
          }}
        >
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Registration Mode:</Typography>

              <Typography component='span'>
                {+kycDetailsData?.IdentifierType === 150 ? 'eAadhar' : 'PAN'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>PAN Number:</Typography>

              <Typography component='span'>
                {kycDetailsData?.PANNumber || kycDetailsData?.IdentifierNum || 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>PIN Code.:</Typography>

              <Typography component='span'>{kycDetailsData?.PinCode ?? 'N/A'}</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Address: </Typography>

              <Typography component='span'>
                {`${kycDetailsData?.Address1}, ${kycDetailsData?.District}, ${kycDetailsData?.PinCode}`}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>District:</Typography>

              <Typography component='span'>
                {kycDetailsData?.District ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>State:</Typography>

              <Typography component='span'>
                {kycDetailsData?.StateName ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack gap='10px'>
              <Typography variant='h5'>Country:</Typography>

              <Typography component='span'>
                {kycDetailsData?.CountryName ?? 'N/A'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const NomineeDetails = ({
  nomineeDetailsData,
  hasTierTwo,
}: {
  nomineeDetailsData: any;
  hasTierTwo: boolean;
}) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        my: 2,
        // padding: '20px',
        border: '0.3px solid',
        borderColor: 'text.borderColorDark',
      }}
    >
      <Box
        sx={{
          borderRadius: '15px 15px 0px 0px',
          background: 'text.boxColor',
          padding: '5px 20px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <FieldSubHeader3>Nominee Details</FieldSubHeader3>
          <Box>
            <IconButton
              onClick={() => {
                //TODO: implement edit
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ padding: '5px 20px 20px' }}>
        <Grid
          container
          my='5px'
          spacing={2}
          sx={{
            '&>.MuiGrid-item ': {
              pb: 2,
            },
            '& .MuiTypography-root': {
              fontSize: '14px',
            },
            '& .MuiTypography-h5 ': {
              fontWeight: '400',
              color: '#6C737F',
            },
            '& .MuiTypography-body1 ': {
              fontWeight: '500',
              color: '#212B36',
            },
          }}
        >
          {[1, ...(hasTierTwo ? [2] : [])].map(tier => (
            <>
              {(
                (tier === 1
                  ? nomineeDetailsData.NominationList
                  : nomineeDetailsData.NominationsList2) || []
              ).map((nominee: any) => (
                <Fragment key={tier}>
                  <Grid
                    item
                    xs={12}
                  >
                    <DetailsHeader>Tier {tier}</DetailsHeader>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                  >
                    <Stack gap='10px'>
                      <Typography variant='h5'>Name:</Typography>

                      <Typography component='span'>
                        {nominee?.FirstName ?? 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                  >
                    <Stack gap='10px'>
                      <Typography variant='h5'>Relationship:</Typography>

                      <Typography component='span'>
                        {(nominee.RelationType &&
                          (nomineeDetailsData?.lstRelationships || []).find(
                            (el: any) => el.Id === +nominee.RelationType,
                          )?.['Name']) ??
                          'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                  >
                    <Stack gap='10px'>
                      <Typography variant='h5'>Share:</Typography>

                      <Typography component='span'>
                        {nominee?.Nompercentage ?? 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                  >
                    <Stack gap='10px'>
                      <Typography variant='h5'>Nominee DOB: </Typography>

                      <Typography component='span'>
                        {nominee?.DateOfBirth ?? 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                </Fragment>
              ))}
            </>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const BankDetails = ({
  bankDetailsData,
  hasTierTwo,
}: {
  bankDetailsData: any;
  hasTierTwo: boolean;
}) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        my: 2,
        // padding: '20px',
        border: '0.3px solid',
        borderColor: 'text.borderColorDark',
      }}
    >
      <Box
        sx={{
          borderRadius: '15px 15px 0px 0px',
          background: 'text.boxColor',
          padding: '5px 20px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <FieldSubHeader3>Bank Details</FieldSubHeader3>
          <Box>
            <IconButton
              onClick={() => {
                //TODO: implement edit
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ padding: '5px 20px 20px' }}>
        <Grid
          container
          my='5px'
          spacing={2}
          sx={{
            '&>.MuiGrid-item ': {
              pb: 2,
            },
            '& .MuiTypography-root': {
              fontSize: { xs: '12px', sm: '14px' },
            },
            '& .MuiTypography-h5 ': {
              fontWeight: '400',
              color: '#6C737F',
            },
            '& .MuiTypography-body1 ': {
              fontWeight: '500',
              color: '#212B36',
            },
          }}
        >
          {[1, ...(hasTierTwo ? [2] : [])].map(tier => (
            <Fragment key={tier}>
              <Grid
                item
                xs={12}
              >
                <DetailsHeader variant='h6'>Tier {tier}</DetailsHeader>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
              >
                <Stack gap='10px'>
                  <Typography variant='h5'>Account Number:</Typography>

                  <Typography component='span'>
                    {bankDetailsData?.[`${tier === 2 ? 'T2' : ''}BankAccountNumber`] ??
                      'N/A'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
              >
                <Stack gap='10px'>
                  <Typography variant='h5'>Bank Name:</Typography>

                  <Typography component='span'>
                    {bankDetailsData?.[`${tier === 2 ? 'T2' : ''}BankName`] ?? 'N/A'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
              >
                <Stack gap='10px'>
                  <Typography variant='h5'>IFSC Code:</Typography>

                  <Typography component='span'>
                    {bankDetailsData?.[`${tier === 2 ? 'T2' : ''}BankIFCS`] ?? 'N/A'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
              >
                <Stack gap='10px'>
                  <Typography variant='h5'>Account Type: </Typography>

                  <Typography component='span'>
                    {bankDetailsData?.[`${tier === 2 ? 'T2' : ''}BankAccountTypeDes`] ??
                      'N/A'}
                  </Typography>
                </Stack>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SchemeDetails = ({
  schemeDetailsData,
  hasTierTwo,
}: {
  schemeDetailsData: any;
  hasTierTwo: boolean;
}) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        my: 2,
        // padding: '20px',
        border: '0.3px solid',
        borderColor: 'text.borderColorDark',
      }}
    >
      <Box
        sx={{
          borderRadius: '15px 15px 0px 0px',
          background: 'text.boxColor',
          padding: '5px 20px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <FieldSubHeader3>Scheme Details</FieldSubHeader3>
          <Box>
            <IconButton
              onClick={() => {
                //TODO: implement edit
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ padding: '5px 20px 20px' }}>
        <Grid
          container
          my='5px'
          spacing={2}
          sx={{
            '&>.MuiGrid-item ': {
              pb: 2,
            },
            '& .MuiTypography-root': {
              fontSize: '14px',
            },
            '& .MuiTypography-h5 ': {
              fontWeight: '400',
              color: '#6C737F',
            },
            '& .MuiTypography-body1 ': {
              fontWeight: '500',
              color: '#212B36',
            },
          }}
        >
          {[1, ...(hasTierTwo ? [2] : [])].map(tier => (
            <Fragment key={tier}>
              <Grid
                item
                xs={12}
              >
                <DetailsHeader>Tier {tier}</DetailsHeader>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
              >
                <Stack gap='10px'>
                  <Typography variant='h5'>Pension Funds:</Typography>

                  <Typography component='span'>
                    {schemeDetailsData?.[
                      `Tier${tier === 1 ? 'One' : 'Two'}FundCodeOptedDes`
                    ] ?? 'N/A'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
              >
                <Stack gap='10px'>
                  <Typography variant='h5'>Investment Option:</Typography>

                  <Typography component='span'>
                    {schemeDetailsData?.[
                      `Tier${tier === 1 ? 'One' : 'Two'}InvestmentChoice`
                    ] ?? 'N/A'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
              >
                <Stack gap='10px'>
                  <Typography variant='h5'>Contribution Amount:</Typography>

                  <Typography component='span'>
                    {schemeDetailsData?.[
                      `contribution_amount${tier === 2 ? 'T2' : ''}`
                    ] ?? 'N/A'}
                  </Typography>
                </Stack>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const AmountPayable = ({
  schemeDetailsData,
  hasTierTwo,
}: {
  schemeDetailsData: any;
  hasTierTwo: boolean;
}) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        my: 2,
        // padding: '20px',
        border: '0.3px solid',
        borderColor: 'text.borderColorDark',
      }}
    >
      <Box
        sx={{
          borderRadius: '15px 15px 0px 0px',
          background: 'text.boxColor',
          padding: '5px 20px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <FieldSubHeader3>Amount Payable</FieldSubHeader3>
          <Box>
            <IconButton
              onClick={() => {
                //TODO: implement edit
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ padding: '5px 20px 20px' }}>
        <Grid
          container
          my='5px'
          spacing={2}
          sx={{
            '&>.MuiGrid-item ': {
              pb: 2,
            },
            '& .MuiTypography-root': {
              fontSize: '14px',
            },
            '& .MuiTypography-h5 ': {
              fontWeight: '400',
              color: '#6C737F',
            },
            '& .MuiTypography-body1 ': {
              fontWeight: '500',
              color: '#212B36',
            },
          }}
        >
          <Grid
            item
            xs={12}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Typography variant='h5'>Contribution Amount:</Typography>

              <Typography component='span'>
                {schemeDetailsData?.contribution_amount || 'N/A'}
              </Typography>
            </Stack>
          </Grid>
          {hasTierTwo && (
            <Grid
              item
              xs={12}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant='h5'>Contribution Amount Tier 2:</Typography>

                <Typography component='span'>
                  {schemeDetailsData?.contribution_amountT2 || 'N/A'}
                </Typography>
              </Stack>
            </Grid>
          )}
          <Grid
            item
            xs={12}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Typography variant='h5'>POP Charges:</Typography>

              <Typography component='span'>₹ 20.00</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Typography variant='h5'>Payment Gateway Charges*:</Typography>

              <Typography component='span'>₹ 2.00</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Typography variant='h5'>GST: </Typography>

              <Typography component='span'>₹ 3.60</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ borderTop: '1px dashed #cdcdcd' }}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Typography component='span'>Total Payable Amount:</Typography>

              <Typography component='span'>₹ 5025.60</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const FilePreview = ({ file }: { file: File }) => {
  const [preview, setPreview] = useState<any>(null);

  const reader = new FileReader();

  reader.onloadend = () => {
    if (!preview) setPreview(reader.result);
  };

  reader.readAsDataURL(file);

  return (
    <img
      src={preview}
      alt='File Preview'
      style={{ maxWidth: '100%' }}
    />
  );
};

const UploadedDocuments = ({ documents }: any) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '15px',
        my: 2,
        // padding: '20px',
        border: '0.3px solid',
        borderColor: 'text.borderColorDark',
      }}
    >
      <Box
        sx={{
          borderRadius: '15px 15px 0px 0px',
          background: 'text.boxColor',
          padding: '5px 20px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <FieldSubHeader3>Uploaded Documents</FieldSubHeader3>
          <Box>
            <IconButton
              onClick={() => {
                //TODO: implement edit
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ padding: '5px 20px 20px' }}>
        <Grid
          container
          my='5px'
          spacing={2}
          sx={{
            '&>.MuiGrid-item ': {
              pb: 2,
            },
            '& .MuiTypography-root': {
              fontSize: '14px',
            },
            '& .MuiTypography-h5 ': {
              fontWeight: '400',
              color: '#6C737F',
            },
            '& .MuiTypography-body1 ': {
              fontWeight: '500',
              color: '#212B36',
              textAlign: { xs: 'center', sm: 'unset' },
            },
          }}
        >
          {Object.entries(documents).map(([key, value]) => {
            if (value instanceof File && !value.name.includes('pdf')) {
              return (
                <>
                  <Grid
                    item
                    sm={4}
                    xs={12}
                  >
                    <Stack gap='10px'>
                      <Typography variant='body1'>{key}</Typography>

                      <FilePreview file={value} />
                    </Stack>
                  </Grid>
                </>
              );
            }
            return null;
          })}
        </Grid>
      </Box>
    </Grid>
  );
};
export default Summary;
