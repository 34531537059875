import {
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as SearchIconMobile } from '../../../assets/search-icon-mobile.svg';
import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import {
  FieldSubHeader3,
  MainHeader,
  StepperNextButton,
} from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { PageNavigator } from '../../common/BackToHomePage';
import { useSnapshotDetailsMutation } from '../slice';
import { Filters } from './Filters';
import TableContainer from './TableContainer';
export interface Order {
  [key: string]: string;
}

export interface TransactionHistory {
  [key: string]: any;
  orders: Order[];
}
const Search = styled('div')(({ theme }) => ({
  'position': 'relative',
  'borderRadius': theme.shape.borderRadius,
  'backgroundColor': alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  'marginLeft': 0,
  'width': '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  'padding': theme.spacing(0, 2),
  'height': '100%',
  'position': 'absolute',
  'zIndex': 1,
  'pointerEvents': 'none',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  '& svg': {
    [theme.breakpoints.between(768, 1024)]: {
      width: '14px',
    },
  },
}));
export const SearchBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'auto',
}));
const InvestorBox = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  border: `0.3px solid ${theme.palette.text.borderColorDark}`,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.05)',
}));
export const SearchIconContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const authorizationTypes = {
  Approved: 'A',
  Pending: 'P',
  Expired: 'E',
};

function TransactionSummary() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  const [getSnapshotDetails] = useSnapshotDetailsMutation();
  const [snapshotDetails, setSnapshotDetails] = useState<TransactionHistory[]>([]);
  const [tabValue, setTabValue] = useState(
    authorizationTypes[state?.authorizationType as keyof typeof authorizationTypes] ||
      'A',
  );
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.width);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    async function snapshotDetails() {
      const response: TransactionHistory[] = await getSnapshotDetails({
        transactionFlag: tabValue ? tabValue : 'A',
      }).unwrap();
      setSnapshotDetails(response);
    }
    snapshotDetails();
  }, [tabValue]);
  const handleGoToCart = () => {
    navigate('../../cart');
  };

  const handleAddAnotherTransaction = () => {
    navigate('../');
  };
  const maxWidth = 600;
  const filterTabs = (
    <Filters
      tabValue={tabValue}
      tabValueHandler={newValue => {
        setTabValue(newValue);
      }}
      tabContent={[]}
      tabsArr={[
        {
          label: 'Approved',
          value: 'A',
        },
        {
          label: 'Pending',
          value: 'P',
        },
        {
          label: 'Expired',
          value: 'E',
        },
      ]}
    />
  );
  return (
    <>
      <PageNavigator title='Transaction Snapshots' />

      {/* <DataTable
        rows={snapshotDetails ? snapshotDetails : []}
        columns={['Name', 'Contact', 'Type', 'Date', 'Scheme', 'Ref No.', 'Amount/Units']}
        onSubmit={() => {}}
        filter={
          
        }
        hideUpdateButton
        hidecheckbox
      /> */}
      {/* Mobile UI Starts*/}
      {screenWidth <= maxWidth ? filterTabs : ''}

      <Box
        sx={{
          backgroundColor: 'text.boxColor',
          padding: '20px 16px',
          mx: '-20px',
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          // justifyContent="space-between"
          mb='15px'
        >
          <Typography
            variant='subtitle1'
            sx={{ color: 'primary.main' }}
          >
            Search By
          </Typography>
          {/* TODO - onClick Responsive Design */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {['Investor', 'Ref No', 'Mobile', 'Date'].map((label, index) => {
              return (
                <Chip
                  sx={(theme: Theme) => ({
                    'border': `1px solid ${theme.palette.text.borderColorLight}`,
                    'mx': 0.5,
                    // background: 'linear-gradient(90deg, #215EAB 0%, #0FD1D7 100%)',
                    'background': 'transparent',
                    '& .MuiChip-label': {
                      color: theme.palette.text.primary,
                      fontSize: '12px',
                      fontWeight: '500',
                      padding: '8px 15px',
                      textAlign: 'center',
                      // color:'common.white',
                    },
                  })}
                  key={index}
                  label={label}
                  // onClick={handleClick}
                />
              );
            })}
          </Box>
        </Stack>
        <SearchBox>
          <SearchIconContainer>
            <SearchIconMobile />
          </SearchIconContainer>
          <TextField
            id='filled-basic'
            variant='filled'
            placeholder='Search By Mobile'
            fullWidth
            sx={(theme: Theme) => ({
              '& .MuiInputBase-root ': {
                'color': 'inherit',
                'backgroundColor': theme.palette.common.white,
                'borderTopLeftRadius': '8px',
                'borderTopRightRadius': '8px',
                '& .MuiInputBase-input': {
                  padding: '15px 12px 15px 40px',
                  backgroundColor: theme.palette.common.white,
                  borderRadius: '8px 8px 0px 0px',
                  fontWeight: '500',
                  fontSize: '14px',
                  color: theme.palette.text.labelColor,
                  border: `1px solid ${theme.palette.text.borderColorLight}`,
                },
              },
            })}
          />
        </SearchBox>
      </Box>

      <InvestorBox
        sx={{
          'display': { md: 'none' },
          'my': '20px',
          '&>.MuiBox-root': {
            '&:not(:last-child)': {
              borderBottom: '0.5px solid',
              borderColor: 'text.borderColorLight',
            },
          },
        }}
      >
        <Box sx={{ padding: '20px' }}>
          <Typography
            variant='subtitle1'
            sx={{ color: 'text.valueColor', mb: 3 }}
          >
            Bhuvnesh Kumar
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              '& .MuiTypography-root': {
                color: 'text.labelColor',
              },
            }}
          >
            <Grid
              item
              xs={8}
            >
              <Typography variant='subtitle1'>
                Axis Balanced Advantage Fund Regular Plan Growth
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography variant='subtitle1'>New Purchase</Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography
                variant='subtitle1'
                sx={{ textAlign: 'right' }}
              >
                ₹ 2,00,00,000
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography variant='subtitle1'>Ref No:113439940</Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography
                variant='subtitle1'
                sx={{ textAlign: 'right' }}
              >
                4 Dec 2023
              </Typography>
            </Grid>
          </Grid>

          <StepperNextButton
            sx={{ my: 2 }}
            fullWidth
          >
            Re-Initiate
          </StepperNextButton>
        </Box>
        <Box sx={{ padding: '20px' }}>
          <Typography
            variant='subtitle1'
            sx={{ color: 'text.valueColor', mb: 3 }}
          >
            Bhuvnesh Kumar
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              '& .MuiTypography-root': {
                color: 'text.labelColor',
              },
            }}
          >
            <Grid
              item
              xs={8}
            >
              <Typography variant='subtitle1'>
                Axis Balanced Advantage Fund Regular Plan Growth
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography variant='subtitle1'>New Purchase</Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography
                variant='subtitle1'
                sx={{ textAlign: 'right' }}
              >
                ₹ 2,00,00,000
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography variant='subtitle1'>Ref No:113439940</Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography
                variant='subtitle1'
                sx={{ textAlign: 'right' }}
              >
                4 Dec 2023
              </Typography>
            </Grid>
          </Grid>

          <StepperNextButton
            sx={{ my: 2 }}
            fullWidth
          >
            Re-Initiate
          </StepperNextButton>
        </Box>
      </InvestorBox>
      {/* Mobile UI  Ends*/}
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          borderRadius: '15px',
          border: '0.3px solid',
          borderColor: 'text.borderColorDark',
          bgcolor: 'common.white',
          boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
          display: { xs: 'none', md: 'block' },
        }}
      >
        {filterTabs}
        <TableContainer
          active={tabValue}
          snapshotDetails={snapshotDetails}
          setOpenDialogBox={setOpenDialogBox}
        />
        <CustomDialog
          isVisible={openDialogBox}
          firstButtonHandler={handleGoToCart}
          secondButtonHandler={handleAddAnotherTransaction}
          firstButtonTitle='Go to cart'
          secondButtonTitle='Add another Transaction'
          handleClose={() => {}}
          showSecondButton={true}
        >
          <Box sx={{ textAlign: 'center', pt: 2 }}>
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
            <FieldSubHeader3 sx={{ fontSize: '16px' }}>
              Scheme is added to investor cart
            </FieldSubHeader3>
          </Box>
        </CustomDialog>
      </Paper>
    </>
  );
}

export default TransactionSummary;
