import {
  Box,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';

import AUM from '../../assets/OnboardingDashboard/aum.svg';
import { ReactComponent as DirectionIcon } from '../../assets/OnboardingDashboard/direction-icon.svg';
import { ReactComponent as ExpiredFilled } from '../../assets/OnboardingDashboard/expired-filled.svg';
import PFM from '../../assets/OnboardingDashboard/pfm.svg';
import { ReactComponent as ProcessingFilled } from '../../assets/OnboardingDashboard/processing-filled.svg';
import Investors from '../../assets/OnboardingDashboard/total-investors.svg';
import { ReactComponent as WhatsappFilled } from '../../assets/OnboardingDashboard/whatsapp-filled.svg';
import { Investorcard } from '../auth/login/styles/styledComponents';

export const NPS = () => {
  const items = [
    { name: 'AUM(INR)', rupees: '₹ 65,500', icon: AUM },
    { name: "Total PFM's", rupees: '10', icon: PFM },
    { name: 'Total Investors', rupees: 'Total Investors', icon: Investors },
  ];
  const pfmData = [
    {
      pfm: 'Birla Sunlife Pension Management Limited',
      percentage: '25%',
      inr: '₹2,22,22,561',
    },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
    { pfm: 'LIC Pension Fund Limited', percentage: '15%', inr: '₹2,22,22,561' },
  ];

  return (
    <>
      <Grid
        container
        my={'20px'}
        spacing={2}
      >
        {items.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'position': 'relative',
                'borderRadius': '5px 10px 10px 5px',
                'border': '0.1px solid',
                'borderColor': 'text.borderColorLight',
                'bgcolor': 'common.white',
                'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                'padding': '25px',
                'display': 'flex',
                'alignItems': 'center',
                '&:before': {
                  content: `' '`,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '5px',
                  height: '100%',
                  background: theme.palette.background.primaryLinearGradient2,
                  borderRadius: '15px 0px 0px 15px',
                },
              })}
            >
              <CardMedia
                component='img'
                image={item.icon}
                alt='image'
                sx={{ width: '70px', height: '70px', mr: 4 }}
              />
              <Stack>
                <Typography sx={{ color: 'text.labelColor', mb: 1 }}>
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    fontWeight: 700,
                    fontSize: { xs: '14px', lg: '18px', xl: '22px' },
                  }}
                >
                  {item.rupees}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant='subtitle2'
        sx={{ my: 2, color: 'text.labelColor' }}
      >
        As on: 26th Oct 2023
      </Typography>
      <Grid
        container
        spacing={3}
        my={'20px'}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Investorcard sx={{ padding: { xs: '16px', md: '30px' } }}>
            <Typography
              variant='subtitle1'
              sx={{ color: 'primary.main', fontWeight: 700, mb: 2 }}
            >
              Tier-Wise Investment Breakup
            </Typography>

            <Typography
              variant='subtitle2'
              sx={{ color: 'text.labelColor' }}
            >
              As on: 26th Oct 2023
            </Typography>
            {/* pieChart */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={1} />
                    <TableCell>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor', fontWeight: 600 }}
                      >
                        Percentage(%)
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor', fontWeight: 600 }}
                      >
                        INR
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    '& .MuiTableRow-root:nth-of-type(odd)': {
                      bgcolor: 'common.white',
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        columnGap={'20px'}
                      >
                        <Box
                          sx={{
                            bgcolor: 'text.paleAqua',
                            width: '11px',
                            height: '11px',
                            borderRadius: '50px',
                          }}
                        />
                        <Typography sx={{ color: 'text.valueColor' }}>Tier-I</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>25%</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>₹2,22,561</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        columnGap={'20px'}
                      >
                        <Box
                          sx={{
                            bgcolor: 'text.skyBlue',
                            width: '11px',
                            height: '11px',
                            borderRadius: '50px',
                          }}
                        />
                        <Typography sx={{ color: 'text.valueColor' }}>Tier-II</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>25%</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: 'text.valueColor' }}>₹2,22,561</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Investorcard>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Investorcard>
            <Typography
              variant='subtitle1'
              sx={{
                color: 'primary.main',
                fontWeight: 700,
                margin: { xs: '15px 25px 15px', md: '35px 35px 16px' },
              }}
            >
              PFM Wise Breakup
            </Typography>
            <Grid
              container
              sx={{
                bgcolor: 'text.tableBgColor',
                position: 'sticky',
                padding: { xs: '8px 20px', md: '16px 35px' },
                justifyContent: 'space-between',
              }}
            >
              <Grid
                item
                xs={4}
                md={5.5}
              >
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  PFM
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                md={3}
              >
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  Percentage(%)
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                md={3.5}
              >
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  INR
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                'maxHeight': '350px',
                'overflowY': 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '214px',
                },
                '&::-webkit-scrollbar-thumb': {
                  height: '6px',
                  border: '2px solid rgba(0, 0, 0, 0)',
                  backgroundClip: 'padding-box',
                  backgroundColor: 'text.stepperLine',
                  // background: 'linear-gradient(180deg, #1D6CB0 0%, #10C1D0 100%)',
                  borderRadius: '33px',
                  boxShadow:
                    'inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05)',
                },
                '&::-webkit-scrollbar-button': {
                  display: 'none',
                  width: 0,
                  height: 0,
                },
                '&::-webkit-scrollbar-corner': {
                  backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-track': {
                  borderRadius: '33px',
                  background: 'rgba(255, 255, 255, 0.8)',
                },
              }}
            >
              {pfmData.map((data, index) => (
                <Grid
                  container
                  sx={{
                    'padding': { xs: '8px 20px', md: '30px 35px' },
                    '&:not(:last-child)': {
                      borderBottom: '1px solid',
                      borderColor: 'text.borderColorDark',
                    },
                  }}
                  key={index}
                >
                  <Grid
                    item
                    xs={4}
                    md={5.5}
                  >
                    <Typography sx={{ color: 'text.valueColor' }}>{data.pfm}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={3}
                  >
                    <Typography sx={{ color: 'text.valueColor' }}>
                      {data.percentage}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={3.5}
                  >
                    <Typography sx={{ color: 'text.valueColor' }}>{data.inr}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Investorcard>
        </Grid>
      </Grid>
      <Investorcard sx={{ padding: '35px 35px' }}>
        <Typography
          variant='subtitle1'
          sx={{ fontWeight: 700, mb: 2, color: 'primary.main' }}
        >
          Transaction Snapshots
        </Typography>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={{
                bgcolor: 'text.lightShadeGreen',
                padding: '10px 15px 15px 35px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <Stack>
                <Typography
                  variant='h3'
                  sx={{ mb: 2, color: 'text.greenShade', fontWeight: 900 }}
                >
                  512
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.labelColor' }}
                >
                  Approved
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon />
                </IconButton>

                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': '50px',
                    'height': '50px',
                    'bgcolor': 'text.greenShade',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,

                    'transition': 'background-color 0.5s', // Add transition for smooth effect
                    '&:hover': {
                      '&:before': {
                        left: 0,
                        bottom: 0,
                        top: 0,
                      },
                      '& svg path': {
                        fill: theme.palette.text.greenShade, // Change fill color of paths within SVG on hoverZ
                        transition: 'fill 0.5s',
                      },
                    },
                    '& svg': {
                      zIndex: '2',
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <WhatsappFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={{
                bgcolor: 'text.lightShadeAmber',
                padding: '10px 15px 15px 35px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <Stack>
                <Typography
                  variant='h3'
                  sx={{ mb: 2, color: 'text.amber', fontWeight: 900 }}
                >
                  160
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.labelColor' }}
                >
                  Pending
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon />
                </IconButton>
                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': '50px',
                    'height': '50px',
                    'bgcolor': 'text.amber',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,

                    'transition': 'background-color 0.5s', // Add transition for smooth effect
                    '&:hover': {
                      '&:before': {
                        left: 0,
                        bottom: 0,
                        top: 0,
                      },
                      '& svg path': {
                        fill: theme.palette.text.amber, // Change fill color of paths within SVG on hoverZ
                        transition: 'fill 0.5s',
                      },
                    },
                    '& svg': {
                      zIndex: '2',
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <ProcessingFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={{
                bgcolor: 'text.coral',
                padding: '10px 15px 15px 35px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <Stack>
                <Typography
                  variant='h3'
                  sx={{ mb: 2, color: 'text.clearText', fontWeight: 900 }}
                >
                  53
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.labelColor', fontWeight: 500 }}
                >
                  Expired
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon />
                </IconButton>
                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': '50px',
                    'height': '50px',
                    'bgcolor': 'text.clearText',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,
                    'transition': 'background-color 0.5s', // Add transition for smooth effect
                    '&:hover': {
                      '&:before': {
                        left: 0,
                        bottom: 0,
                        top: 0,
                      },
                      '& svg path': {
                        fill: theme.palette.text.clearText, // Change fill color of paths within SVG on hoverZ
                        transition: 'fill 0.5s',
                      },
                    },
                    '& svg': {
                      zIndex: '2',
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <ExpiredFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Investorcard>
    </>
  );
};
