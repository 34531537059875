import {
  Box,
  Collapse,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TablePagination,
  Theme,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { amcWithLogos } from '../../assets/mfcentral-logos/amcLogos';
import { ReactComponent as SelectSchemesMobile } from '../../assets/scheme-table-mobile.svg';
import { ReactComponent as SelectSchemes } from '../../assets/schemeTable.svg';
import { StepperNextButton } from '../onboarding/styles/styledComponents';

interface RowProps {
  [key: string]: any;
}

interface ExpandableRadioTableProps {
  rows: RowProps[];
  keysToShow: string[];
  colorMappings?: { [key: string]: string };
  rowHeader?: string;
  onContinue: (selectedRow: RowProps) => void;
  filter?: React.ReactNode;
  showIcon?: boolean;
  showSelectAmcImage?: boolean;
}
const ExpandableRadioTable: React.FC<ExpandableRadioTableProps> = ({
  rows,
  keysToShow,
  colorMappings,
  rowHeader,
  onContinue,
  filter,
  showIcon,
  showSelectAmcImage = false,
}) => {
  const start = 0;
  const limit = 10;
  const [selectedScheme, setSelectedScheme] = useState<number | null>(null);
  const [page, setPage] = useState(start);
  const [rowsPerPage, setRowsPerPage] = useState(limit);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt((event.target as HTMLInputElement).value);
    setSelectedScheme(value === selectedScheme ? null : value);
  };
  const handleContinue = () => {
    if (selectedScheme !== null) {
      const selectedRow = rows[selectedScheme];
      onContinue(selectedRow);
    }
  };
  // Handlers to change the states
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <Box>
        {/* <CustomSearch
              placeholder='Search'
              value={query}
              onChange={handleSearchTextChanged}
            /> */}
        {filter && <Box>{filter}</Box>}{' '}
      </Box>
      {rows.length !== 0 ? (
        <RadioGroup
          value={selectedScheme}
          onChange={handleChange}
        >
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item: RowProps, index: number) => {
              const logo = amcWithLogos.find(logo => logo.amc_code === Number(item.fund));
              return (
                <Box
                  key={index}
                  sx={(theme: Theme) => ({
                    'position': 'relative',
                    'p': { xs: '10px', sm: '30px 30px' },
                    'paddingLeft': selectedScheme === index ? '0px' : '5px',
                    'borderLeft':
                      selectedScheme === index
                        ? `5px solid ${theme.palette.primary.main}`
                        : 'unset',
                    'backgroundColor':
                      selectedScheme === index
                        ? `${theme.palette.text.boxColor}`
                        : 'common.white',
                    '&:not(:last-child)': {
                      borderTop: `0.5px solid ${theme.palette.text.borderColorLight}`,
                    },
                  })}
                >
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: '20px',
                      }}
                    >
                      <Radio
                        sx={{
                          'position': 'absolute',
                          'width': { sm: '60%', md: '70%', lg: '75%', xl: '80%' },
                          'justifyContent': 'flex-start',
                          'left': '20px',
                          'top': '42px',
                          'right': '0px',
                          'bottom': '0px',
                          'alignItems': 'baseline',
                          'padding': '0px',
                          'display': 'block',
                          '& .MuiSvgIcon-root': {
                            width: '20px',
                            height: '20px',
                          },
                        }}
                        value={index}
                        name='radio-buttons'
                        inputProps={{ 'aria-label': 'A' }}
                        disableFocusRipple
                        disableRipple
                      />
                      <Box
                        sx={{
                          'borderRadius': '10px',
                          'border': '0.5px solid',
                          'borderColor': 'text.borderColorLight',
                          'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                          'ml': 4,
                          'p': '6px 6px',
                          'display': 'flex',
                          'justifyContent': 'center',
                          'alignItems': 'center',
                          '& svg': {
                            width: '30px',
                            height: '30px',
                          },
                        }}
                      >
                        {logo?.icon && <logo.icon />}
                      </Box>
                      <Typography
                        variant='subtitle2'
                        sx={(theme: Theme) => ({
                          ...(selectedScheme === index
                            ? {
                                backgroundClip: 'text',
                                webkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundImage: `linear-gradient(180deg,  ${theme.palette.text.darkBlue} 0%,  ${theme.palette.text.gradientSkyBlue} 100%)`,
                              }
                            : { color: 'text.valueColor' }),
                          ml: showIcon ? 0 : 7,
                        })}
                      >
                        {rowHeader
                          ? `${item[rowHeader]} - ${item.planDesc}`
                          : 'Row Header'}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      columnGap={'20px'}
                    >
                      {keysToShow.map(key => {
                        if (key !== rowHeader) {
                          return (
                            <Typography
                              key={key}
                              sx={{ color: colorMappings?.[key] }}
                              component={'span'}
                            >
                              {item[key]}
                            </Typography>
                          );
                        }
                      })}
                    </Stack>
                  </Box>
                  <Grid
                    container
                    alignItems={'center'}
                    sx={{ display: { sm: 'none' } }}
                  >
                    <Grid item>
                      <Radio
                        sx={{
                          position: 'absolute',
                          width: '100%',
                          justifyContent: 'flex-start',
                          left: '10px',
                          top: showIcon ? '10px' : '15px',
                          right: '0px',
                          bottom: '0px',
                          alignItems: 'baseline',
                          padding: '0px',
                          display: 'block',
                          height: '65%',
                        }}
                        value={index}
                        name='radio-buttons'
                        inputProps={{ 'aria-label': 'A' }}
                        disableFocusRipple
                        disableRipple
                      />
                    </Grid>
                    <Grid
                      item
                      xs={11}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            borderRadius: '5px',
                            border: '0.5px solid',
                            borderColor: 'text.borderColorLight',
                            boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                            ml: 3,
                            p: '0px 6px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: { xs: '32px' },
                            height: { xs: '32px' },
                          }}
                        >
                          {logo?.icon && <logo.icon />}
                        </Box>
                        <Typography
                          variant='subtitle1'
                          sx={{ color: 'text.valueColor', ml: showIcon ? 2 : 7 }}
                        >
                          {rowHeader ? item[rowHeader] : 'Row Header'}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        {keysToShow.map(key => {
                          if (key !== rowHeader) {
                            return (
                              <Typography
                                variant='subtitle2'
                                key={key}
                                sx={{
                                  color: colorMappings?.[key],
                                  ml: 3,
                                  mt: 2,
                                  // color: 'text.labelColor3',
                                  fontWeight: 400,
                                }}
                                component={'span'}
                              >
                                {item[key]}
                              </Typography>
                            );
                          }
                        })}
                      </Box>
                    </Grid>
                  </Grid>

                  <Collapse
                    in={selectedScheme === index}
                    timeout='auto'
                    unmountOnExit
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <StepperNextButton
                        sx={{
                          minWidth: { xs: '125px', sm: '160px', lg: '200px' },
                          flex: { xs: 'unset' },
                          mt: 3,
                        }}
                        onClick={handleContinue}
                      >
                        Continue
                      </StepperNextButton>
                    </Box>
                  </Collapse>
                </Box>
              );
            })}
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component='div'
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </RadioGroup>
      ) : showSelectAmcImage ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '50px 0px 60px 0px',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <SelectSchemes />
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <SelectSchemesMobile />
          </Box>

          <Box sx={{ my: 3 }}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: { xs: '16px', sm: '26px' },
                fontWeight: 500,
                mb: 2,
              }}
            >
              Scheme Viewing :{' '}
              <Typography
                component={'span'}
                sx={{
                  fontSize: { xs: '16px', sm: '26px' },
                  color: 'primary.main',
                  fontWeight: 700,
                }}
              >
                AMC
              </Typography>{' '}
              &{' '}
              <Typography
                sx={{
                  fontSize: { xs: '16px', sm: '26px' },
                  color: 'primary.main',
                  fontWeight: 700,
                }}
                component={'span'}
              >
                Folio{' '}
              </Typography>
              Selection
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ textAlign: 'center', color: 'text.labelColor' }}
            >
              Please select the AMC and folio to access the schemes!
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          mt={4}
        >
          No data Found
        </Box>
      )}
    </>
  );
};

export default ExpandableRadioTable;
