import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { useRootContext } from '../../data/root.context';
import { FormSelectInput } from '../../forms/FormSelect';
import StyledRadioButtonGroup from '../../forms/FormStyledRadioButton';
import FormTextInput, { FormPasswordInput } from '../../forms/FormTextInput';
import { useCheckIfscMutation, useVerifyBankDetailsMutation } from '../slices';

interface AddBankAccountProps {
  values: any;
  setFieldValue: any;
  bankNameList: any;
  setFieldError: (field: string, errorMessage: string) => void;
  setBankNameList: (bankNameList: string[]) => void;
  index: number;
  isFromKyd?: boolean;
  setCurrentBankAccountToken: (token: string) => void;
  setHasValidationFailed: (hasFailed: boolean) => void;
  filesLength: number;
}

const AddBankAccount: React.FC<AddBankAccountProps> = ({
  values,
  setFieldValue,
  bankNameList,
  setBankNameList,
  index,
  isFromKyd,
  setFieldError,
  setHasValidationFailed,
  setCurrentBankAccountToken,
  filesLength,
}) => {
  const { showToast } = useRootContext();
  const [checkIfscCode, { isSuccess: checkIfscSuccess }] = useCheckIfscMutation();
  const [verifyBankDetails, { isSuccess: verificationFailed, isLoading }] =
    useVerifyBankDetailsMutation();
  useEffect(() => {
    const fetchData = async () => {
      if (values.bankList[index].ifsc.length === 11) {
        try {
          const response = await checkIfscCode({ ifsc: values.bankList[index].ifsc });
          if ('data' in response) {
            // Checking if data exists in response
            setBankNameList(
              response.data.bankname !== 'No Record Found'
                ? [response.data.bankname]
                : response.data.banknamelist,
            );
            setFieldValue('ifscCodeEntered', true);
          }
        } catch (error: any) {
          showToast((error.data as { message: string }).message, 'error');
        }
      }
    };

    fetchData();
  }, [values.bankList[index].ifsc]);

  const validateBankAccount = async (
    bank: {
      accountNumber: string;
      verifiedAccountNumber: string;
      accountType: string;
      ifsc: string;
      bankName: string;
      isDefault: boolean;
    },
    index: number,
  ) => {
    // Check the account number and verified account number conditions
    if (
      bank.accountNumber.trim() !== '' &&
      bank.accountNumber.length >= 10 &&
      bank.accountNumber.length <= 16 &&
      bank.accountNumber === bank.verifiedAccountNumber &&
      filesLength === 0
    ) {
      setHasValidationFailed(false);
      const response = await verifyBankDetails({
        ifsc: bank.ifsc,
        accountType: bank.accountType === 'Savings' ? 'saving' : 'current',
        accountNumber: bank.accountNumber,
        isDefault: bank.isDefault ? 'true' : 'false',
        bankname: bank.bankName,
      });

      // Check if verification failed
      if (!verificationFailed) {
        // Handle failure case
        setHasValidationFailed(true);
        setFieldError(
          `bankList.${index}.accountNumber`,
          'Bank Account validation failed! Try again with updating the bank account number or upload canceled cheque.',
        );
      } else {
        if ('data' in response) {
          setCurrentBankAccountToken(response.data.banktoken);
        }
      }
    }
  };

  useEffect(() => {
    const bank = values.bankList[index];
    validateBankAccount(bank, index);
  }, [values.bankList[index].verifiedAccountNumber]);

  return (
    <Box
      sx={{
        my: 2.5,
      }}
    >
      <Typography
        variant='subtitle1'
        sx={{ color: 'text.valueColor', mb: 2 }}
      >
        Account Type*
      </Typography>
      <StyledRadioButtonGroup
        options={[
          { label: 'Savings', value: 'Savings' },
          { label: 'Current', value: 'Current' },
        ]}
        value={values.bankList[index].accountType}
        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`bankList.${index}.accountType`, event.target.value);
        }}
      />
      <Grid
        container
        sx={{ my: 1 }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormTextInput
            name={`bankList.${index}.ifsc`}
            label='IFSC Code'
            disabled={isFromKyd}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormSelectInput
            name={`bankList.${index}.bankName`}
            label='Select Bank'
            options={
              !isFromKyd
                ? bankNameList.map((bank: any) => ({ label: bank, value: bank })) ?? []
                : [
                    {
                      label: values.bankList[index].bankName,
                      value: values.bankList[index].bankName,
                    },
                  ]
            }
            defaultValue={isFromKyd ? values.bankList[index].bankName : ''}
            disabled={!checkIfscSuccess || isFromKyd}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormPasswordInput
            name={`bankList.${index}.accountNumber`}
            label='Account Number'
            autoComplete='off'
            disabled={isFromKyd || isLoading}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormTextInput
            name={`bankList.${index}.verifiedAccountNumber`}
            label='Confirm Account Number'
            disabled={isFromKyd || isLoading}
          />
          {isLoading && (
            <Typography
              variant='body2'
              sx={{ color: 'text.valueColor' }}
            >
              Validating Account Number...
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddBankAccount;
