import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/close-icon-mobile.svg';
import DatePickerIcon from '../../assets/date-picker.svg';
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';

type Anchor = 'bottom';
const anchor = 'bottom';
export default function InvestmentDetailsMobile({
  commercialTransactionTitle,
  data,
}: {
  commercialTransactionTitle: String;
  data?: any;
}) {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '10px 20px',
          borderBottom: '1px solid',
          borderColor: 'text.borderColorDark',
        }}
      >
        <Typography
          variant='subtitle1'
          sx={{ color: 'text.valueCVolor' }}
        >
          {commercialTransactionTitle}
        </Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ padding: '10px 20px' }}>
        <List disablePadding>
          <ListItem>
            {data?.schemeDesc && (
              <ListItemText>
                <Typography
                  variant='h5'
                  sx={{ color: 'primary.main', mb: 1 }}
                >
                  Scheme
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.valueColor' }}
                >
                  {data?.schemeDesc || 'NA'}
                </Typography>
              </ListItemText>
            )}
          </ListItem>

          <ListItem>
            {data?.folioNo && (
              <ListItemText>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.labelColor', fontWeight: 400, mb: 1 }}
                >
                  Folio Number
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.valueColor' }}
                >
                  {data?.folioNo || 'NA'}
                </Typography>
              </ListItemText>
            )}
          </ListItem>
          {data?.units && (
            <ListItem>
              <ListItemText>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.labelColor', fontWeight: 400, mb: 1 }}
                >
                  Total Balance Units
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.valueColor' }}
                >
                  {data?.units?.toFixed(2) || '0'}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
          {data?.availableamount && (
            <ListItem>
              <ListItemText>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.labelColor', fontWeight: 400, mb: 1 }}
                >
                  Total Available Amount
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.valueColor' }}
                >
                  ₹ {data?.availableamount}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
          {data?.exitload && (
            <ListItem>
              <ListItemText>
                <Stack
                  direction={'row'}
                  columnGap={'10px'}
                >
                  <Typography
                    variant='subtitle2'
                    sx={{ color: 'text.labelColor', fontWeight: 400, mb: 1 }}
                  >
                    Exit load by fund house
                  </Typography>
                  <InfoIcon />
                </Stack>

                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.valueColor' }}
                >
                  Nil
                </Typography>
              </ListItemText>
            </ListItem>
          )}
          {data?.nav && (
            <ListItem>
              <ListItemText>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.labelColor', fontWeight: 400, mb: 1 }}
                >
                  Current NAV
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'text.valueColor' }}
                >
                  {data?.nav || 'NA'}
                </Typography>
                <Stack
                  gap='5px'
                  direction='row'
                  alignItems='center'
                  mt={1}
                >
                  <img
                    src={DatePickerIcon}
                    alt='date-picker'
                  />
                  <Typography
                    variant='subtitle2'
                    sx={{ color: 'text.labelColor', fontWeight: 400 }}
                  >
                    As on Date
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      <Button
        onClick={toggleDrawer(anchor, true)}
        sx={{
          fontSize: '12px',
          fontWeight: 500,
          color: 'primary.main',
          mb: 1.5,
          p: 0,
          m: 0,
        }}
      >
        View Details
      </Button>
      <Drawer
        sx={{
          '& .MuiPaper-root ': {
            borderRadius: '30px 30px 0px 0px',
            backgroundColor: 'common.white',
          },
        }}
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
    </Box>
  );
}
