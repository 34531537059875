import { Box, Stack, styled, Typography } from '@mui/material';

import SwitchFromIcon from '../../../assets/switch-from.svg';
import SwitchToIcon from '../../../assets/switch-to.svg';
import { TransactionType } from '../types';

export const FromAndToHeader = ({
  data,
  type,
}: {
  data: any;
  type: TransactionType.SWITCH | TransactionType.STP;
}) => (
  <Box
    sx={{
      'borderRadius': '5px',
      'bgcolor': { xs: 'unset', sm: 'text.boxColor' },
      'display': 'flex',
      'flexWrap': 'wrap',
      '& .MuiTypography-root': {
        mb: 1,
      },
    }}
  >
    <Box
      sx={{ flex: { xs: 'unset', sm: 1 }, padding: { xs: '10px 0px', sm: '10px 20px' } }}
    >
      <Stack
        direction='row'
        gap='10px'
      >
        <img
          src={SwitchFromIcon}
          alt='scheme-from'
          width='24px'
          height='24px'
        />
        <Typography
          variant='subtitle2'
          sx={{ fontWeight: 400, color: 'text.labelColor' }}
        >
          {type === TransactionType.SWITCH ? 'Switch' : 'Transfer'} From
        </Typography>
      </Stack>

      <Typography
        variant='subtitle2'
        sx={{ color: 'text.valueColor' }}
      >
        {data?.switchOutSchemeName}
      </Typography>
    </Box>
    <Box
      sx={theme => ({
        display: { xs: 'none', sm: 'block' },
        border: `1px dashed ${theme.palette.text.borderColorLight}`,
      })}
    />
    <Box
      sx={{ flex: { xs: 'unset', sm: 1 }, padding: { xs: '10px 0px', sm: '10px 20px' } }}
    >
      <Stack
        direction='row'
        gap='10px'
      >
        <img
          src={SwitchToIcon}
          alt='scheme-to'
          width='24px'
          height='24px'
        />
        <Typography
          variant='subtitle2'
          sx={{ fontWeight: 400, color: 'text.labelColor' }}
        >
          {type === TransactionType.SWITCH ? 'Switch' : 'Transfer'} To
        </Typography>
      </Stack>
      <Typography
        variant='subtitle2'
        sx={{ color: 'text.valueColor' }}
      >
        {data?.switchInSchemeName}
      </Typography>
    </Box>
  </Box>
);

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.labelColor,
  mb: 1,
}));
