export const getDatesForCurrentFinancialYr = () => {
  const reformtStDt = new Date(
    new Date().setFullYear(new Date().getFullYear(), 3, 1),
  ).toLocaleDateString('en-US', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  });

  const changeStDt = new Date().toLocaleDateString('en-US', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  });
  const d2 = new Date(reformtStDt).setHours(12, 0, 0, 0);
  const d3 = new Date(changeStDt).setHours(12, 0, 0, 0);
  const startDate =
    d2 > d3
      ? new Date(new Date().setFullYear(new Date().getFullYear() - 1, 3, 1))
      : new Date(new Date().setFullYear(new Date().getFullYear(), 3, 1));
  const endDate = new Date();

  return {
    startDate,
    endDate,
  };
};

export const getDatesForPreviousFinancialYr = () => {
  const reformtStDt = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1, 3, 1),
  ).toLocaleDateString('en-US', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  });

  const changeStDt = new Date().toLocaleDateString('en-US', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  });
  const d2 = new Date(reformtStDt).setHours(12, 0, 0, 0);
  const d3 = new Date(changeStDt).setHours(12, 0, 0, 0);

  const reformtEndDt = new Date(
    new Date().setFullYear(new Date().getFullYear(), 2, 31),
  ).toLocaleDateString('en-US', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  });

  const changeEndDt = new Date().toLocaleDateString('en-US', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  });
  const d4 = new Date(reformtEndDt).setHours(12, 0, 0, 0);
  const d5 = new Date(changeEndDt).setHours(12, 0, 0, 0);

  const startDate =
    d2 < d3
      ? new Date(new Date().setFullYear(new Date().getFullYear() - 1, 3, 1))
      : new Date(new Date().setFullYear(new Date().getFullYear() - 2, 3, 1));
  const endDate =
    d4 > d5
      ? new Date(new Date().setFullYear(new Date().getFullYear() - 1, 2, 31))
      : new Date(new Date().setFullYear(new Date().getFullYear(), 2, 31));

  return {
    startDate,
    endDate,
  };
};

export const getFiscalYear = (year: number) => {
  const currentYear = new Date().toLocaleDateString('en-US', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  });

  const currentFiscalYear = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1, 3, 1),
  ).toLocaleDateString('en-US', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  });
  if (currentYear < currentFiscalYear) {
    const diff = 1;
    const fiscalYearStart = new Date(
      new Date().setFullYear(new Date().getFullYear() - diff + year, 3, 1),
    ).toLocaleDateString('en-US', {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit',
    });
    const fiscalYearEnd = new Date(
      new Date().setFullYear(new Date().getFullYear() - diff + year + 1, 2, 31),
    ).toLocaleDateString('en-US', {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit',
    });
    return [fiscalYearStart, fiscalYearEnd];
  } else {
    const diff = 0;
    const fiscalYearStart = new Date(
      new Date().setFullYear(new Date().getFullYear() + year - diff, 3, 1),
    ).toLocaleDateString('en-US', {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit',
    });
    const fiscalYearEnd = new Date(
      new Date().setFullYear(new Date().getFullYear() + year - diff + 1, 2, 31),
    ).toLocaleDateString('en-US', {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit',
    });
    return [fiscalYearStart, fiscalYearEnd];
  }
};

export function YYMMDDtoMMDDYYYY(dateString: any) {
  const [year, month, day] = dateString.split('-');
  const date = new Date(year, month - 1, day);
  const monthString = (date.getMonth() + 1).toString().padStart(2, '0');
  const dayString = date.getDate().toString().padStart(2, '0');
  const yearString = date.getFullYear().toString();
  return `${dayString}/${monthString}/${yearString}`;
}

export function MMddToDDMMYYYY(dateString: any) {
  const parts = dateString.split('/');
  const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
  return formattedDate;
}

export function DDMMToMMDD(dateString: string) {
  const parts = dateString.split('/');
  const newDateString = parts[1] + '/' + parts[0] + '/' + parts[2];
  return newDateString;
}
export const validateDates = (values: any) => {
  const errors: Record<string, any> = {};
  const startDate = new Date(values.startDate).setHours(12, 0, 0, 0);
  const endDate = new Date(values.endDate).setHours(12, 0, 0, 0);
  if (values.detailedOption === 'Specified Period') {
    if (startDate >= endDate) {
      errors.endDate = 'Start Date should be earlier than End Date';
    }
  }
  return errors;
};

export const handleDetailsOptionChange = (
  e: any,
  setFieldValue: (field: string, value: any) => void,
) => {
  if (e.target.value === 'Current Financial Year') {
    const { startDate, endDate } = getDatesForCurrentFinancialYr();
    setFieldValue('startDate', startDate);
    setFieldValue('endDate', endDate);
  } else if (e.target.value === 'Previous Financial Year') {
    const { startDate, endDate } = getDatesForPreviousFinancialYr();
    setFieldValue('startDate', startDate);
    setFieldValue('endDate', endDate);
  } else {
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
  }
};
