import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';

import {
  // StepperAddButton,
  StepperCancelButton,
  StepperNextButton,
} from '../../auth/login/styles/styledComponents';
type MaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

interface CustomDialogProps {
  children: React.ReactNode;
  isVisible: boolean;
  showSecondButton?: boolean;
  handleClose: () => void;
  firstButtonHandler?: () => void;
  secondButtonHandler?: () => void;
  firstButtonTitle?: string;
  secondButtonTitle?: string;
  showFirstButton?: boolean;
  maxWidth?: MaxWidth;
}
export default function CustomDialog({
  children,
  isVisible,
  showSecondButton = false,
  firstButtonHandler,
  secondButtonHandler = () => {},
  handleClose,
  firstButtonTitle,
  secondButtonTitle,
  showFirstButton = true,
  maxWidth = 'sm',
}: CustomDialogProps) {
  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '15px',
        },
      }}
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{
          'justifyContent': 'center',
          'columnGap': '10px',
          'p': '20px 24px',
          'flexDirection': { xs: 'column', sm: 'row' },
          'alignItems': { xs: 'normal' },
          '&:not(style) ~ :not(style)': {
            marginLeft: 0,
          },
        }}
      >
        {showFirstButton && (
          <StepperCancelButton
            sx={{ minWidth: { xs: 'unset', sm: '200px' }, flex: { xs: 1, sm: 'unset' } }}
            onClick={firstButtonHandler}
          >
            {firstButtonTitle}
          </StepperCancelButton>
        )}
        {showSecondButton && (
          <StepperNextButton
            sx={{
              ml: 0,
              minWidth: { xs: 'unset', sm: '200px' },
              flex: { xs: 1, sm: 'unset' },
              fontSize: '14px',
              mt: { xs: 2, sm: 0 },
            }}
            onClick={secondButtonHandler}
          >
            {secondButtonTitle || 'OK'}
          </StepperNextButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
