import { Box, Button, Grid, Stack, Theme, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as CancelIcon } from '../../../assets/cancel-icon.svg';
import { ReactComponent as PauseIcon } from '../../../assets/pause-icon.svg';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import Layout from '../Layout';
import CancellationReasonDialog from './CancellationReasonDialog';

function InvestmentDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [showCancellationDialogBox, setShowCancellationDialogBox] =
    useState<boolean>(false);
  const [trtType, setTrtType] = useState<string>('');
  const [titleCancelDialogBox, setTitleCancelDialogBox] = useState<string>('');
  const [selectedReason, setSelectedReason] = useState('');
  const { arn, euin } = useSelector((state: { login: LoginResponse }) => state.login);
  const {
    pan: invPan,
    email,
    mobile,
    name,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);
  return (
    <Layout
      title='Pause/Cancellation Module'
      subtitle='Please proceed with a cancellation or pause for the scheme'
    >
      <Investorcard
        sx={theme => ({
          padding: '30px',
          [theme.breakpoints.down('md')]: {
            border: 'unset',
            boxShadow: 'unset',
            padding: '0px',
          },
        })}
      >
        <Typography
          sx={{
            color: 'primary.main',
            mb: 3,
            fontSize: { xs: '16px', xl: '18px' },
            fontWeight: 500,
          }}
        >
          Scheme Details
        </Typography>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Stack
              gap='10px'
              mb='36px'
            >
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.labelColor',
                }}
              >
                Scheme Name
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.valueColor',
                }}
              >
                {state?.schemeDetails?.amc}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack
              gap='10px'
              mb='36px'
            >
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.labelColor',
                }}
              >
                Folio
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.valueColor',
                }}
              >
                {state?.schemeDetails?.folio}
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack
              gap='10px'
              mb='36px'
            >
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.labelColor',
                }}
              >
                Installment Amount
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.valueColor',
                }}
              >
                {state?.schemeDetails?.installmentAmount}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack
              gap='10px'
              mb='36px'
            >
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.labelColor',
                }}
              >
                Frequency
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.valueColor',
                }}
              >
                {state?.schemeDetails?.frequency}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack
              gap='10px'
              mb='36px'
            >
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.labelColor',
                }}
              >
                Registration Date
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.valueColor',
                }}
              >
                {format(new Date(state?.schemeDetails?.registerDate), 'dd/MM/yyyy')}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
          >
            <Stack
              gap='10px'
              mb='36px'
            >
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.labelColor',
                }}
              >
                From Date
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.valueColor',
                }}
              >
                {format(new Date(state?.schemeDetails?.startDate), 'dd/MM/yyyy')}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={4}
            xs={6}
          >
            <Stack
              gap='10px'
              mb='36px'
            >
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.labelColor',
                }}
              >
                To Date
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                  color: 'text.valueColor',
                }}
              >
                {format(new Date(state?.schemeDetails?.endDate), 'dd/MM/yyyy')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            borderTop: '1px dashed',
            borderColor: 'text.borderColorLight',
            pt: 4,
            columnGap: '20px',
          }}
        >
          <Button
            sx={(theme: Theme) => ({
              borderRadius: '5px',
              border: `1px solid  ${theme.palette.primary.main}`,
              padding: '8px 26px',
              fontSize: '14px',
              fontWeight: 600,
              minWidth: '260px',
              [theme.breakpoints.down(600)]: {
                fontSize: '13px',
                flex: 1,
                minWidth: 'unset',
              },
              [theme.breakpoints.between(768, 1200)]: {
                fontSize: '13px',
                py: '6px',
              },
              [theme.breakpoints.up(1920)]: {
                fontSize: '16px',
              },
            })}
            startIcon={<CancelIcon />}
            onClick={() => {
              setOpenDialogBox(true);
              setTitleCancelDialogBox('Cancellation');
              setTrtType('SIPC');
            }}
          >
            Cancel SIP
          </Button>
          <Button
            sx={(theme: Theme) => ({
              borderRadius: '5px',
              border: `1px solid  ${theme.palette.primary.main}`,
              padding: '8px 26px',
              fontSize: '14px',
              minWidth: '260px',
              fontWeight: 600,
              [theme.breakpoints.down(600)]: {
                fontSize: '13px',
                flex: 1,
                minWidth: 'unset',
              },
              [theme.breakpoints.between(768, 1200)]: {
                fontSize: '13px',
                py: '6px',
              },
              [theme.breakpoints.up(1600)]: {
                fontSize: '16px',
              },
            })}
            startIcon={<PauseIcon />}
            onClick={() => {
              setShowCancellationDialogBox(true);
              setTitleCancelDialogBox('Pause');
              setTrtType('PAUS');
            }}
          >
            Pause SIP
          </Button>
        </Box>
      </Investorcard>
      <CustomDialog
        maxWidth={'sm'}
        isVisible={openDialogBox}
        firstButtonHandler={() => setOpenDialogBox(false)}
        secondButtonHandler={() => {
          setOpenDialogBox(false);
          setShowCancellationDialogBox(true);
        }}
        firstButtonTitle='No'
        secondButtonTitle='Yes'
        handleClose={() => {
          setOpenDialogBox(false);
        }}
        showSecondButton={true}
      >
        <Typography
          sx={{
            fontWeight: 500,
            color: 'text.primary',
            mb: 2,
            fontSize: '20px',
          }}
        >
          {state?.schemeDetails?.Mode} Cancellation
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{
            color: 'text.labelColor',
            mb: 2,
          }}
        >
          Do you want to cancel {state?.schemeDetails?.Mode}?
        </Typography>
      </CustomDialog>
      <CancellationReasonDialog
        showDialog={showCancellationDialogBox}
        backButtonHandler={() => {
          setShowCancellationDialogBox(false);
        }}
        proceedButtonHandler={() => {
          navigate('../confirmation', {
            state: {
              payload: {
                ...state,
                invPan,
                email,
                mobile,
                name,
                arn,
                euin,
              },
              trtType,
              remarks: selectedReason,
            }, // SIPC trtType
          });
        }}
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
        header={titleCancelDialogBox}
      />
    </Layout>
  );
}

export default InvestmentDetails;
