import { Box, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

import SwitchFromIcon from '../../../assets/switch-from.svg';
import SwitchLine from '../../../assets/switch-line.svg';
import SwitchToIcon from '../../../assets/switch-to.svg';
import SwitchLineMobile from '../../../assets/switch-vertical-line-mobile.svg';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import InvestmentDetailsMobile from '../../common/InvestmentDetailsMobile';
import Layout from '../Layout';
import InvestmentDetailsForm from './InvestmentDetailsForm';

const InvestmentDetails = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // Define image sources based on screen size
  const imageUrl = isSmallScreen ? SwitchLineMobile : SwitchLine;
  const { state } = useLocation();
  const { switchInData, switchOutData, extraData } = state;
  const data = {
    folio: switchOutData.folioNo,
    fund: switchOutData.fund,
    fundName: switchOutData.fundName,
    switchInSchemeName: switchInData.schemeDesc,
    switchOutSchemeName: switchOutData.schemeDesc,
    taxSaverFlag: switchOutData.taxSaverFlag,
    option: switchOutData.schemeOption,
    plan: switchOutData.schemePlan,
    scheme: switchOutData.scheme,
    toOption: switchInData.schemeOption || switchInData.option,
    toPlan: switchInData.schemePlan || switchInData.plan,
    toScheme: switchInData.scheme,
    availableAmount: Number(switchOutData.availableAmount),
    balanceUnits: switchOutData.balanceUnits,
    category: switchOutData.category,
    extraData,
  };
  return (
    <Layout
      title='Investment Details'
      rightBottomComponent={
        <Box
          sx={{
            padding: '30px 20px',
            borderTop: '0.5px solid ',
            borderColor: 'text.borderColorLight',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
              borderBottom: '1px dashed',
              borderColor: 'text.borderColorLight',
              pb: 2,
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography
                variant='subtitle2'
                sx={{ color: 'primary.main' }}
              >
                Switch From
              </Typography>
              <img
                src={SwitchFromIcon}
                alt='switch-from-icon'
              />
            </Stack>
            {Boolean(data.switchOutSchemeName) && (
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {data.switchOutSchemeName}
              </Typography>
            )}
            {Boolean(data.folio) && (
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>Folio Number</Typography>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  {data.folio}
                </Typography>
              </Stack>
            )}
            {Boolean(switchOutData.balanceUnits) && (
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>
                  Total Balance Units
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  {switchOutData.balanceUnits}
                </Typography>
              </Stack>
            )}
            {Boolean(switchOutData.availableAmount) && (
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>
                  Total Available Amount
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  {switchOutData.availableAmount}
                </Typography>
              </Stack>
            )}
            {Boolean(switchOutData.exitLoad) && (
              <Stack gap='5px'>
                <Typography sx={{ color: 'text.labelColor' }}>
                  Exit load by fund house
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  {switchOutData.exitLoad}
                </Typography>
              </Stack>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px', pt: 2 }}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography
                variant='subtitle2'
                sx={{ color: 'primary.main' }}
              >
                Switch To
              </Typography>
              <img
                src={SwitchToIcon}
                alt='switch-to-icon'
              />
            </Stack>
            {Boolean(data.switchInSchemeName) && (
              <Stack gap='5px'>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.valueColor' }}
                >
                  {data.switchInSchemeName}
                </Typography>
              </Stack>
            )}
          </Box>
        </Box>
      }
    >
      <Box
        sx={{
          borderRadius: '15px',
          bgcolor: 'text.boxColorLight',
          padding: { xs: '20px 10px ', sm: '30px' },
          mb: 3,
        }}
      >
        <Grid
          container
          alignItems='center'
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant='subtitle1'
                sx={{ color: 'text.labelColor', mb: { xs: 1, sm: 1.5 } }}
              >
                From
              </Typography>
              {/* Mobile-UI Bottom-Drawer */}
              <InvestmentDetailsMobile
                commercialTransactionTitle='Switch Scheme'
                data={switchOutData}
              />
              {/* Mobile-UI Bottom-Drawer */}
            </Box>

            <Typography
              variant='subtitle2'
              sx={{ color: 'text.valueColor', mb: { xs: 1, sm: 0 } }}
            >
              {switchOutData.schemeDesc}
            </Typography>
          </Grid>
          <Grid
            item
            sm={4}
            xs={12}
            sx={{
              textAlign: { xs: 'left', sm: 'center' },
              my: { xs: '4px', sm: 'unset' },
            }}
          >
            <img
              src={imageUrl}
              alt='switch-line'
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            alignSelf='flex-start'
          >
            <Typography
              variant='subtitle1'
              sx={{ color: 'text.labelColor', mb: { xs: 1, sm: 1.5 } }}
            >
              To
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.valueColor', mb: { xs: 1, sm: 0 } }}
            >
              {switchInData.schemeDesc}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography
        variant='subtitle1'
        sx={{ color: 'primary.main', mb: 2 }}
      >
        Please enter the investment details
      </Typography>
      <Investorcard
        sx={theme => ({
          padding: '30px',
          [theme.breakpoints.down('md')]: {
            border: 'unset',
            boxShadow: 'unset',
            padding: '0px',
          },
        })}
      >
        <InvestmentDetailsForm
          data={data}
          isEditMode={false}
          handleCancelModifyingCart={() => {}}
        />
      </Investorcard>
    </Layout>
  );
};

export default InvestmentDetails;
