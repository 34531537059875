import {
  Box,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as AlertIcon } from '../../../../assets/alert-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/close-icon.svg';
import { ReactComponent as BankDetailsIcon } from '../../../../assets/NPS-CKYC/bank-details.svg';
import { ReactComponent as BankActiveIcon } from '../../../../assets/NPS-CKYC/bank-details-active.svg';
import { ReactComponent as CompletedIcon } from '../../../../assets/NPS-CKYC/completed-icon.svg';
import { ReactComponent as ConfirmationActiveIcon } from '../../../../assets/NPS-CKYC/confirmation-active.svg';
import { ReactComponent as ConfirmationIcon } from '../../../../assets/NPS-CKYC/confirmation-icon.svg';
import { ReactComponent as FatcaIcon } from '../../../../assets/NPS-CKYC/fatca-details.svg';
import { ReactComponent as FatcaActiveIcon } from '../../../../assets/NPS-CKYC/fatca-details-active.svg';
import { ReactComponent as InvestorActiveIcon } from '../../../../assets/NPS-CKYC/investor-details-active.svg';
import { ReactComponent as NomineeDetailsIcon } from '../../../../assets/NPS-CKYC/nominee-details.svg';
import { ReactComponent as NomineeActiveIcon } from '../../../../assets/NPS-CKYC/nominee-details-active.svg';
import { DetailsHeader } from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { PageNavigator } from '../../../common/BackToHomePage';
import { Breadcrumb } from '../../../common/breadcrumbs';
import { onboardingStepsCount } from '../../../common/constants';
import { useRootContext } from '../../../data/root.context';
import { TransactionResponse } from '../../../features/transactions/transactionSlice';
import DropdownIcon from '../../../forms/FormMultiSelect/icons/dropdown-icon';
import { SUCCESS_RESPONSE_CODE } from '../../mfs/newInvestorOnboarding';
import { useInvestorDetailsMutation } from '../../mfs/newInvestorOnboarding/slice';
import { useLazyGetCountriesAndStateQuery } from '../../nps/investorOnBoarding/slice';
import { _getOptions } from '../../nps/investorOnBoarding/utils';
import {
  setInvestorDetailsFromResponse,
  setStatesAndCountries,
  setStepNumber,
} from '../context/MfsNewPurchaseAction';
import { useMfsNewPurchaseContext } from '../context/MfsNewPurchaseContext';
import BankDetails from './BankDetails';
import FatcaDetails from './FatcaDetails';
import { InvestorDetails } from './InvestorDetails';
import NomineeForm from './NomineeDetails';
import { Payment } from './Payment';

const steps = [
  {
    inactiveIcon: <InvestorActiveIcon />,
    activeIcon: <InvestorActiveIcon />,
    label: 'Step 1',
    description: 'Investor Details',
    component: <InvestorDetails />,
  },
  {
    inactiveIcon: <FatcaIcon />,
    activeIcon: <FatcaActiveIcon />,
    label: 'Step 2',
    description: 'FATCA',
    component: <FatcaDetails />,
  },
  {
    inactiveIcon: <NomineeDetailsIcon />,
    activeIcon: <NomineeActiveIcon />,
    label: 'Step 3',
    description: 'Nominee Details',
    component: <NomineeForm />,
  },
  {
    inactiveIcon: <BankDetailsIcon />,
    activeIcon: <BankActiveIcon />,
    label: 'Step 4',
    description: 'Investor Bank Details',
    component: <BankDetails />,
  },
  {
    inactiveIcon: <ConfirmationIcon />,
    activeIcon: <ConfirmationActiveIcon />,
    label: 'Step 5',
    description: 'Confirmation',
    component: <Payment />,
  },
];

function ProgressMobileStepper() {
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const {
    dispatch: contextDispatch,
    state: { currentStep },
  } = useMfsNewPurchaseContext();

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '90px',
          left: 0,
          width: '100%',
          zIndex: 2,
          mb: 4,
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Box
          sx={{
            bgcolor: 'text.babyBlue',
            padding: '20px 10px 20px 20px',
            borderBottom: '1px solid',
            borderColor: 'text.borderColorLight',
          }}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack
              direction={'row'}
              columnGap={'15px'}
            >
              {steps[currentStep].activeIcon}
              <Stack>
                <DetailsHeader sx={{ fontSize: '14px', mb: 1 }}>
                  {steps[currentStep].label} of {steps.length}
                </DetailsHeader>
                <Typography
                  sx={{ fontSize: '16px', color: 'primary.main', fontWeight: 600 }}
                >
                  {steps[currentStep].description}
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={handleDrawerOpen}>
              <DropdownIcon />
            </IconButton>
          </Stack>
        </Box>
        <LinearProgress
          sx={{
            'bgcolor': 'transparent',
            'height': '3px',
            'width': '100%',
            'position': 'absolute',
            'bottom': '1px',
            '& .MuiLinearProgress-bar': {
              borderRadius: '0px 23px 31px 0px',
            },
          }}
          variant={'determinate'}
          value={(currentStep + 1) * (100 / onboardingStepsCount.mfs)}
        />
      </Box>
      <Drawer
        anchor='bottom'
        sx={{
          '& .MuiPaper-root ': {
            borderRadius: '25px 25px 0px 0px',
            backgroundColor: 'common.white',
          },
        }}
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Box role='presentation'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '0px 25px',
              borderBottom: '0.5px solid',
              borderColor: 'text.borderColorLight',
              marginBottom: '20px',
            }}
          >
            <Typography
              variant='subtitle1'
              sx={{
                color: 'primary.main',
                fontWeight: 700,
              }}
            >
              New Purchase
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ maxWidth: 400, p: '15px 20px' }}>
            <Stepper
              activeStep={currentStep}
              orientation='vertical'
              sx={{
                '& .MuiStepLabel-root ': {
                  padding: '0px',
                },
                '& .MuiStepConnector-root ': {
                  'marginLeft': '20px',
                  '& .MuiStepConnector-line': {
                    minHeight: '45px',
                  },
                },
              }}
            >
              {steps.map((step, index) => {
                const isCompleted = index < currentStep;
                return (
                  <Step key={step.label}>
                    <StepLabel
                      icon={
                        isCompleted ? (
                          <CompletedIcon />
                        ) : index === currentStep ? (
                          step.activeIcon
                        ) : (
                          step.inactiveIcon
                        )
                      }
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{
                          color: 'text.labelColor',
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          color: 'text.labelColor',
                          fontWeight: 700,
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

const NewMfPurchaseSteps = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [callGetCountriesAndState, getCountriesAndStateResponse] =
    useLazyGetCountriesAndStateQuery();
  const [getData] = useInvestorDetailsMutation();

  const { pan: invPan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  const {
    dispatch: contextDispatch,
    state: {
      currentStep,
      investorDetails,
      fatcaDetails,
      isNomination,
      nomineeDetails,
      investorBankDetails,
    },
  } = useMfsNewPurchaseContext();

  const onGoAnywayButtonClick = async () => {
    setOpenDialogBox(false);
    navigate('..');
  };

  const onCancelClick = () => {
    setOpenDialogBox(false);
  };

  const handleBackClicked = (steps: any) => {
    switch (steps[currentStep].description) {
      case 'FATCA':
        return contextDispatch(setStepNumber(0));
      case 'Nominee Details':
        return contextDispatch(setStepNumber(1));
      case 'Investor Bank Details':
        return contextDispatch(setStepNumber(2));
      case 'Confirmation':
        return contextDispatch(setStepNumber(3));
      default:
        return null;
    }
  };

  const handleStepperClicked = (index: number) => {
    switch (index) {
      case 1:
        if (!investorDetails) {
          return;
        }
        break;
      case 2:
        if (!fatcaDetails) {
          return;
        }
        break;
      case 3:
        if (!isNomination && !nomineeDetails) {
          return;
        }
        break;
      case 4:
        if (!investorBankDetails) {
          return;
        }
        break;
      default:
        break;
    }
    contextDispatch(setStepNumber(index));
  };

  useEffect(() => {
    if (
      getCountriesAndStateResponse &&
      getCountriesAndStateResponse.isSuccess &&
      !getCountriesAndStateResponse.isFetching
    ) {
      const getStatesAndCountries = async () => {
        const { data, statusCode: statesListStatusCode } =
          getCountriesAndStateResponse.data;

        if (Number(statesListStatusCode) !== SUCCESS_RESPONSE_CODE) return;

        const { ListCountries, StatesList } = data;
        const statesList = _getOptions(StatesList, 'Id');
        const countriesList = _getOptions(ListCountries, 'PK_ID');

        setStatesAndCountries({
          countriesList,
          statesList,
        })(contextDispatch);
      };
      getStatesAndCountries();
    }
  }, [getCountriesAndStateResponse]);

  useEffect(() => {
    callGetCountriesAndState('');
    contextDispatch(setStepNumber(0));
  }, []);

  useEffect(() => {
    const getInvData = async () => {
      const invResponse = await getData({ pan: invPan }).unwrap();
      contextDispatch(setInvestorDetailsFromResponse(invResponse));
    };
    getInvData();
  }, [invPan]);

  return (
    <Box>
      <ProgressMobileStepper />
      <Grid
        container
        spacing={3}
        sx={{ mt: { xs: '85px', sm: 0 }, minHeight: 'calc(100vh - 110px)' }}
      >
        <Grid
          item
          sm={2.5}
          sx={{
            bgcolor: 'text.babyBlue',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <Box mb={2}>
            <Stepper
              activeStep={currentStep}
              orientation='vertical'
              sx={{
                '& .MuiStepLabel-root ': {
                  padding: '0px',
                },
                '& .MuiStepConnector-root ': {
                  'marginLeft': '20px',
                  '& .MuiStepConnector-line': {
                    minHeight: '65px',
                  },
                },
              }}
            >
              {steps.map((step, index) => {
                const isCompleted = index < currentStep;
                return (
                  <Step key={step.label}>
                    <StepLabel
                      icon={
                        isCompleted ? (
                          <CompletedIcon />
                        ) : index === currentStep ? (
                          step.activeIcon
                        ) : (
                          step.inactiveIcon
                        )
                      }
                      onClick={() => handleStepperClicked(index)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Typography
                        sx={{
                          color: 'text.labelColor',
                          fontSize: '12px',
                          fontWeight: 500,
                          cursor: 'pointer',
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.labelColor',
                          fontSize: '18px',
                          fontWeight: 700,
                          cursor: 'pointer',
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Grid>
        {/* Right side (70%) */}
        <Grid
          item
          sm={currentStep === 4 ? 9.5 : 8}
          xs={12}
          sx={{ position: 'relative' }}
        >
          <Breadcrumb
            title='Home'
            subtitle='New Purchase'
            otherHeading='New Folio Creation'
            onClick={() => setOpenDialogBox(true)}
          />
          <PageNavigator
            backHandler={() => {
              if (currentStep === 0) {
                setOpenDialogBox(true);
              } else {
                contextDispatch(setStepNumber(currentStep - 1));
              }
            }}
            title={steps[currentStep].description}
          />

          {steps[currentStep].component}
        </Grid>
      </Grid>
      <CustomDialog
        isVisible={openDialogBox}
        firstButtonHandler={onCancelClick}
        secondButtonHandler={onGoAnywayButtonClick}
        firstButtonTitle='Cancel'
        secondButtonTitle='Go Back Anyway'
        handleClose={() => {
          setOpenDialogBox(false);
        }}
        showSecondButton={true}
      >
        <Stack
          display='flex'
          direction='row'
          alignItems='center'
          gap='10px'
        >
          <AlertIcon />
          <Typography variant='h5'>Data Not Saved, Will be Lost</Typography>
        </Stack>
        <Typography
          variant='subtitle1'
          sx={{ fontWeight: 400, color: 'text.labelColor', my: 3 }}
        >
          Going Back will result in the loss of your entered data. Are you sure you want
          to proceed?
        </Typography>
      </CustomDialog>
    </Box>
  );
};

export default NewMfPurchaseSteps;
