/* eslint-disable no-empty-pattern */
// import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  alpha,
  Badge,
  Button,
  Chip,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Tab,
  TablePagination,
  TextField,
  Theme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';

import { ReactComponent as CartIcon } from '../../../assets/cart-icon.svg';
import { ReactComponent as KeyBoardArrowUpIcon } from '../../../assets/keyboard-arrow-up.svg';
// import { ReactComponent as Filter } from '../../../assets/filter.svg';
import { ReactComponent as KeyBoardRightArrowIcon } from '../../../assets/keyboard-right-arrow.svg';
import { ReactComponent as AddIcon } from '../../../assets/rounded-add-icon.svg';
import { ReactComponent as SearchIconMobile } from '../../../assets/search-icon-mobile.svg';
import {
  DetailsHeader,
  Detailslabel,
  DetailsValue,
  StepperNextButton,
} from '../../auth/login/styles/styledComponents';
import { useGetAssetsQuery } from '../../auth/signup/slices';
import CustomLoader from '../../common/CustomLoader';
import { CustomSearch } from '../../common/search';
// import SearchInputBox from '../../common/SearchBox';
import { useRootContext } from '../../data/root.context';
import { updateAssets, updateRefreshFlag } from '../../features/login/loginSlice';
import { investorDetails } from '../../features/transactions/transactionSlice';
import {
  useGetAgentDetailsMutation,
  useLazyGetInvestorsQuery,
  usePortfolioMutation,
} from '../slice';
import EmpanelmentTab from './EmpanelmentTab';
import { usePortfolioSummaryMutation } from './slice';

interface AgentDetails {
  data: {
    POPCode: string;
    POP_SPCode: string;
  };
}

interface CollapseDataProps {
  open: boolean;
  value: string;
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
  empaneledItems: {
    MFS: boolean;
    NPS: boolean;
  };
  investor: Investor;
  investorPortfolio?: InvestorPortfolio;
  portfolioSummary?: any;
}

// Typing the functions

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: theme.palette.text.tableBgColor,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  'backgroundColor': theme.palette.common.white,
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const Search = styled('div')(({ theme }) => ({
  'position': 'relative',
  'borderRadius': theme.shape.borderRadius,
  'backgroundColor': alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  'marginLeft': 0,
  'width': '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  'padding': theme.spacing(0, 2),
  'height': '100%',
  'position': 'absolute',
  'zIndex': 1,
  'pointerEvents': 'none',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  '& svg': {
    [theme.breakpoints.between(768, 1024)]: {
      width: '14px',
    },
  },
}));
export const SearchBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'auto',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));
// const FilterBox = styled(Box)(({}) => ({
//   borderLeft: '1px solid #cdcdcd',
//   position: 'absolute',
//   top: '0px',
//   right: '0px',
//   height: '100%',
//   zIndex: 1,
//   // pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   padding: '10px',
// }));
const InvestorBox = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  border: `0.3px solid ${theme.palette.text.borderColorDark}`,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.05)',
}));
export const SearchIconContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  'color': 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create('width'),
    width: '350px',
    borderRadius: '5px',
    fontWeight: '500',
    fontSize: '16px',
    color: theme.palette.text.labelColor,
    border: `1px solid ${theme.palette.text.borderColorLight}`,
    [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
    },
  },
}));
export interface Investor {
  name: string;
  pan: string;
  email: string;
  mobile: string;
  cart: number;
  mfStatus: boolean;
  pran: string;
  AccountType: string;
  npsEmpanelmentStatus: boolean;
}

export interface InvestorPortfolio {
  totalContributionAmount: string;
  totalCurrentValuation: string;
  mfStatus: boolean | string;
  npsEmpanelmentStatus: boolean | string;
}

interface InvestorPortfolioResponse {
  data: InvestorPortfolio;
}

function getEmpanlementStatus(empaneledItems: {}) {
  const empaneledStatus = (
    Object.keys(empaneledItems) as Array<keyof typeof empaneledItems>
  )
    .filter(key => empaneledItems[key])
    .join(',');
  return empaneledStatus;
}

function getEmpanlementItems(investor: any) {
  return {
    MFS:
      typeof investor.mfStatus === 'boolean'
        ? investor.mfStatus
        : investor.mfStatus === 'true',
    NPS:
      typeof investor.npsEmpanelmentStatus === 'boolean'
        ? investor.npsEmpanelmentStatus
        : investor.npsEmpanelmentStatus === 'true',
  };
}

const transactHandler = (
  investor: any,
  dispatch: React.Dispatch<AnyAction>,
  navigate: NavigateFunction,
  empaneledStatus: string,
  empaneledItems: { MFS: boolean; NPS: boolean },
) => {
  dispatch(investorDetails(investor));
  dispatch(updateRefreshFlag(true));

  const empaneledStatusList = empaneledStatus.split(',');
  if (empaneledStatusList.length > 1) {
    navigate('asset-selection', {
      state: {
        empaneledItems,
      },
    });
  } else {
    if (empaneledStatusList[0] === 'MFS') {
      navigate('transact');
    } else {
      navigate('nps/additional-purchase');
    }
  }
};

async function fetchAgentDetails(
  getAgentDetails: any,
  getInvestorPortfolio: any,
  investor: Investor,
): Promise<InvestorPortfolioResponse> {
  const agentDetailsResponse = await getAgentDetails({
    agentRefNumber: '',
  }).unwrap();
  const { POPCode, POP_SPCode } = agentDetailsResponse.data;
  const payload = {
    PRAN: investor.pran,
    POP_Code: POPCode,
    POP_SP_Code: POP_SPCode,
    AccountType: investor.AccountType || '2',
  };
  return getInvestorPortfolio(payload).unwrap();
}

function CollapseData({
  open,
  value,
  handleChange,
  empaneledItems,
  investor,
  investorPortfolio,
  portfolioSummary,
}: CollapseDataProps) {
  return (
    <Collapse
      in={open}
      timeout='auto'
      unmountOnExit
    >
      <Box
        sx={{
          width: '100%',
          minHeight: { xs: 'calc(100vh - 90px)', sm: 'unset' },
          padding: { xs: '0px 10px', sm: 'unset' },
        }}
      >
        <TabContext value={value}>
          <Box
            sx={(theme: Theme) => ({
              'borderBottom': `1px solid ${theme.palette.text.borderColorLight}`,
              '& .MuiTabs-root': {
                '& .MuiTabs-flexContainer': {
                  'pl': { xs: '10px', sm: '60px' },
                  'columnGap': '25px',
                  '& .MuiButtonBase-root.MuiTab-root': {
                    maxWidth: '60px',
                    minWidth: '60px',
                  },
                },

                '& .MuiTabs-indicator': {},
              },
              '& .MuiTabPanel-root': {
                padding: '50px 50px',
              },
            })}
          >
            <TabList
              onChange={handleChange}
              aria-label='lab API tabs example'
            >
              {empaneledItems &&
                typeof empaneledItems === 'object' &&
                Object.entries(empaneledItems)
                  .filter(([, isEnabled]) => isEnabled)
                  .map(([key, value], index) => {
                    if (value) {
                      return (
                        <Tab
                          key={key}
                          label={key}
                          value={(index + 1).toString()}
                        />
                      );
                    }
                    return null;
                  })}
            </TabList>
          </Box>
          {empaneledItems &&
            typeof empaneledItems === 'object' &&
            Object.entries(empaneledItems)
              .filter(([, isEnabled]) => isEnabled)
              .map(([key], index) => (
                <TabPanel
                  key={key}
                  value={String(index + 1)}
                >
                  <EmpanelmentTab
                    investorPortfolio={investorPortfolio}
                    value={String(index + 1)}
                    investor={investor}
                    type={key}
                    transactHandler={transactHandler}
                    empaneledStatus={getEmpanlementStatus(empaneledItems)}
                    empaneledItems={empaneledItems}
                    portfolioSummary={portfolioSummary}
                  />
                </TabPanel>
              ))}
        </TabContext>
      </Box>
    </Collapse>
  );
}

function Row(props: { investor: Investor }) {
  const { investor } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('1');
  const [getAgentDetails] = useGetAgentDetailsMutation();
  const [getInvestorPortfolio] = usePortfolioMutation();
  const [investorPortfolio, setInvestorPortfolio] = React.useState<InvestorPortfolio>();
  const { showToast } = useRootContext();
  const [getPortfolioSummary] = usePortfolioSummaryMutation();
  const [portfolioSummary, setPortfolioSummary] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (open && investor.pran) {
          const res = await fetchAgentDetails(
            getAgentDetails,
            getInvestorPortfolio,
            investor,
          );
          setInvestorPortfolio(res?.data);
        }

        if (open && !portfolioSummary) {
          const res = await getPortfolioSummary({ pan: investor.pan }).unwrap();
          setPortfolioSummary(res);
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    };

    fetchData();
  }, [open, investor.pran]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const empaneledItems = getEmpanlementItems(investor);
  const empaneledStatus = getEmpanlementStatus(empaneledItems);

  return (
    <>
      <StyledTableRow
        sx={(theme: Theme) => ({
          borderBottom: open
            ? 'unset'
            : `1px solid ${theme.palette.text.borderColorLight}`,
          bgcolor: 'common.white',
          borderLeft: open ? `5px solid ${theme.palette.primary.main}` : 'unset',
        })}
      >
        <StyledTableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyBoardArrowUpIcon /> : <KeyBoardRightArrowIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle1'>{investor.name}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle1'>{investor.pan}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle1'>{investor.mobile}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle1'>{investor.email}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography
            sx={(theme: Theme) => ({
              color: `${theme.palette.primary.main} !important`,
              fontWeight: '500 !important',
            })}
          >
            {empaneledStatus}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <IconButton
            onClick={() => {
              dispatch(investorDetails(investor));
              navigate('cart');
            }}
          >
            <Badge
              badgeContent={investor.cart == 0 ? '0' : investor.cart}
              color='primary'
              sx={(theme: Theme) => ({
                '& .MuiBadge-badge': {
                  backgroundColor: 'text.darkBlue',
                  color: theme.palette.common.white,
                },
              })}
            >
              <CartIcon />
            </Badge>
          </IconButton>
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: 'end' }}>
          <StepperNextButton
            sx={{ minWidth: '115px', padding: '8px 5px' }}
            onClick={() => {
              transactHandler(
                investor,
                dispatch,
                navigate,
                empaneledStatus,
                empaneledItems,
              );
            }}
          >
            Transact
          </StepperNextButton>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow
        sx={(theme: Theme) => ({
          borderBottom: !open
            ? 'unset'
            : `1px solid  ${theme.palette.text.borderColorLight}`,
          bgcolor: theme.palette.common.white,
          borderLeft: !open ? 'unset' : `5px solid  ${theme.palette.primary.main}`,
        })}
      >
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={8}
        >
          <CollapseData
            open={open}
            value={value}
            investor={investor}
            investorPortfolio={investorPortfolio}
            handleChange={handleChange}
            empaneledItems={empaneledItems}
            portfolioSummary={portfolioSummary}
          />
        </StyledTableCell>
      </TableRow>
    </>
  );
}

function ResponsiveRow(props: { investor: Investor }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { investor } = props;
  const empaneledItems = getEmpanlementItems(investor);
  const empaneledStatus = getEmpanlementStatus(empaneledItems);
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState('1');
  const [getAgentDetails] = useGetAgentDetailsMutation();
  const [getInvestorPortfolio] = usePortfolioMutation();
  const [investorPortfolio, setInvestorPortfolio] = React.useState<InvestorPortfolio>();
  const { showToast } = useRootContext();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpen(open);
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (open && investor.pran) {
          const res = await fetchAgentDetails(
            getAgentDetails,
            getInvestorPortfolio,
            investor,
          );
          setInvestorPortfolio(res?.data);
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    };

    fetchData();
  }, [open, investor.pran]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.stopPropagation();
    setValue(newValue);
  };

  return (
    <>
      <Box>
        <Drawer
          sx={{
            '& .MuiBackdrop-root': {
              top: '90px',
            },
            '& .MuiPaper-root': {
              top: '90px',
            },
          }}
          anchor='left'
          open={open}
          onClose={toggleDrawer(false)}
        >
          <Box
            role='presentation'
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px',
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                {investor?.name}
              </Typography>
              <IconButton
                edge='end'
                color='inherit'
                onClick={toggleDrawer(false)}
                aria-label='close'
                sx={{ paddingRight: '20px' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <CollapseData
              open={open}
              value={value}
              investor={investor}
              investorPortfolio={investorPortfolio}
              handleChange={handleChange}
              empaneledItems={empaneledItems}
            />
          </Box>
        </Drawer>
        <Grid
          container
          alignItems={'center'}
        >
          <Grid
            item
            xs={1}
          >
            <IconButton sx={{ p: 0 }}>
              <KeyBoardRightArrowIcon onClick={toggleDrawer(!open)} />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={9}
          >
            <DetailsValue sx={{ ml: '5px' }}>{investor.name}</DetailsValue>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign={'center'}
          >
            <IconButton>
              <Badge
                badgeContent={investor.cart == 0 ? '0' : investor.cart}
                color='primary'
                sx={{
                  '& .MuiBadge-badge': {
                    bgcolor: 'text.darkBlue',
                    color: 'common.white',
                  },
                }}
              >
                <CartIcon />
              </Badge>
            </IconButton>
          </Grid>
        </Grid>

        <Box sx={{ padding: '10px 10px 0px 30px' }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            mb='16px'
          >
            <Detailslabel sx={{ fontWeight: 500 }}>{investor.pan}</Detailslabel>

            <Detailslabel sx={{ fontWeight: 500 }}>{investor.mobile}</Detailslabel>
          </Stack>
          <Detailslabel sx={{ fontWeight: 500, mb: 2 }}>{investor.email}</Detailslabel>

          <DetailsHeader sx={{ fontSize: '14px', mb: 2 }}>
            {empaneledStatus}
          </DetailsHeader>
        </Box>
        <StepperNextButton
          sx={{ my: 2 }}
          fullWidth
          onClick={() => {
            transactHandler(
              investor,
              dispatch,
              navigate,
              empaneledStatus,
              empaneledItems,
            );
          }}
        >
          Transact
        </StepperNextButton>
      </Box>
    </>
  );
}

export default function CollapsibleTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [getInvestorDetails, investorsList] = useLazyGetInvestorsQuery();
  const { data, error, refetch } = useGetAssetsQuery('');

  const allDataList = React.useRef<Investor[]>([]);
  const [filteredInvestors, setFilteredInvestors] = React.useState<Investor[]>([]);
  const onFirstRender = React.useRef<boolean>(false);
  const [searchCriteria, setSearchCriteria] = React.useState('Name');
  const [searchText, setSearchText] = React.useState('');
  const [displayLoader, setDisplayLoader] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchTextChanged = (text: string) => {
    setSearchText(text);
    setPage(0);
  };

  React.useEffect(() => {
    if (data) {
      dispatch(updateAssets(data));
    } else if (error) {
      showToast((error as { message: string }).message, 'error');
    }
  }, [data, dispatch]);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  React.useEffect(() => {
    setDisplayLoader(true);
    try {
      if (!onFirstRender.current) {
        getInvestorDetails('');
        onFirstRender.current = true;
      }
      if (investorsList.data && investorsList.data.invester) {
        const uniqueInvestors = getUniqueInvestors(investorsList.data.invester);
        allDataList.current = uniqueInvestors;
        setFilteredInvestors(uniqueInvestors);
        setDisplayLoader(false);
      }
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    }
  }, [investorsList.data]);

  const getUniqueInvestors = (investors: Investor[]) => {
    const uniqueInvestors = investors.reduce((acc: Investor[], current: Investor) => {
      const x = acc.find(item => item.pan === current.pan);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, [] as Investor[]);
    return uniqueInvestors;
  };

  const filteredInvestorsBySearchCriteria = React.useMemo(
    () =>
      filteredInvestors.filter(investor => {
        switch (searchCriteria) {
          case 'Name':
            return investor.name?.toLowerCase().includes(searchText.toLowerCase());
          case 'PAN':
            return investor.pan.toLowerCase().includes(searchText.toLowerCase());
          case 'Mobile Number':
            return investor.mobile.includes(searchText);
          case 'Email':
            return investor.email.toLowerCase().includes(searchText.toLowerCase());
          default:
            return true;
        }
      }),
    [searchText, filteredInvestors],
  );

  return (
    <>
      <Box
        display={{ xs: 'flex', md: 'unset' }}
        justifyContent={{ xs: 'space-between', md: 'unset' }}
        alignItems={{ xs: 'center', md: 'unset' }}
        marginBottom={{ xs: '20px' }}
      >
        <Typography
          variant='h3'
          sx={{ mb: { xs: '0px', md: 5 } }}
        >
          My Investors
        </Typography>

        <Button
          sx={(theme: Theme) => ({
            ml: 2,
            borderRadius: '5px',
            border: `1px solid   ${theme.palette.primary.main}`,
            padding: '8px 26px',
            display: { md: 'none' },
            fontSize: '14px',
          })}
          onClick={() => {
            dispatch(updateRefreshFlag(false));
            navigate('asset-selection', { state: { from: 'newInvestor' } });
          }}
          startIcon={<AddIcon />}
        >
          New Investor
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: 'text.boxColor',
          padding: '20px 16px',
          mx: '-20px',
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          // justifyContent="space-between"
          mb='15px'
        >
          <DetailsHeader sx={{ fontSize: '14px' }}>Search by</DetailsHeader>
          <Box sx={{ display: 'flex', ml: '10px' }}>
            {['Name', 'PAN', 'Mobile', 'Email'].map((label, index) => {
              return (
                <Chip
                  sx={(theme: Theme) => ({
                    'border': `1px solid ${theme.palette.text.borderColorLight}`,
                    'mx': 0.5,
                    // background: 'linear-gradient(90deg, #215EAB 0%, #0FD1D7 100%)',
                    'background': 'transparent',
                    '& .MuiChip-label': {
                      color: theme.palette.text.primary,
                      fontSize: '12px',
                      fontWeight: '500',
                      padding: '8px 15px',
                      textAlign: 'center',
                      // color:'common.white',
                    },
                  })}
                  key={index}
                  label={label}
                  // onClick={handleClick}
                />
              );
            })}
          </Box>
        </Stack>
        <SearchBox>
          <SearchIconContainer>
            <SearchIconMobile />
          </SearchIconContainer>
          <TextField
            id='filled-basic'
            variant='filled'
            placeholder='Search By Mobile'
            fullWidth
            sx={(theme: Theme) => ({
              '& .MuiInputBase-root ': {
                'color': 'inherit',
                'backgroundColor': theme.palette.common.white,
                'borderTopLeftRadius': '8px',
                'borderTopRightRadius': '8px',
                '& .MuiInputBase-input': {
                  padding: '15px 12px 15px 40px',
                  backgroundColor: theme.palette.common.white,
                  borderRadius: '8px 8px 0px 0px',
                  fontWeight: '500',
                  fontSize: '14px',
                  color: theme.palette.text.labelColor,
                  border: `1px solid ${theme.palette.text.borderColorLight}`,
                },
              },
            })}
          />
        </SearchBox>
        {/* TODO MobileUI} */}
        {/* <SearchInputBox /> */}
        {/* {TODO MobileUI} */}
      </Box>

      <InvestorBox
        sx={{
          'display': { md: 'none' },
          'my': '20px',
          '&>.MuiBox-root': {
            'padding': '20px 20px',
            '&:not(:last-child)': {
              borderBottom: '0.5px solid',
              borderColor: 'text.borderColorLight',
            },
          },
        }}
      >
        {filteredInvestorsBySearchCriteria.length > 0 &&
          filteredInvestorsBySearchCriteria.map(investor => (
            <ResponsiveRow
              key={investor.pan}
              investor={investor}
            />
          ))}
      </InvestorBox>

      <Box sx={{ width: '100%' }}>
        <Paper
          sx={(theme: Theme) => ({
            'width': '100%',
            'display': { xs: 'none', sm: 'block' },
            'mb': 2,
            'borderRadius': '15px',
            'border': '0.3px solid',
            'borderColor': 'text.borderColorDark',
            'background': theme.palette.common.white,
            'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
            '& .MuiButtonBase-root.MuiRadio-root': {
              'color': 'text.darkGrayBlue',
              'padding': '15px 8px',
              '& .MuiSvgIcon-root': {
                width: '20px',
                height: '20px',
                [theme.breakpoints.down('sm')]: {
                  width: '10px',
                  height: '20px',
                },
              },
              '&.Mui-checked': {
                'color': 'primary.main',
                '& + .MuiTypography-root': {
                  color: 'primary.main',
                },
              },
            },
          })}
        >
          <Box
            sx={{
              padding: { sm: '16px 16px', lg: '16px 25px' },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: { xs: '0px', sm: '10px' },
              }}
            >
              <Typography
                sx={(theme: Theme) => ({
                  color: 'text.labelColor',
                  fontSize: '16px',
                  [theme.breakpoints.down('xl')]: {
                    fontSize: '14px',
                  },
                })}
              >
                Search By
              </Typography>
              <FormControl>
                <RadioGroup
                  sx={{ columnGap: '10px' }}
                  row
                  value={searchCriteria}
                  onChange={e => {
                    setSearchText('');
                    setSearchCriteria(e.target.value);
                  }}
                >
                  {['Name', 'PAN', 'Mobile Number', 'Email'].map((item, index) => (
                    <FormControlLabel
                      sx={(theme: Theme) => ({
                        '& .MuiTypography-root': {
                          fontSize: '16px',
                          color: 'text.labelColor',
                          fontWeight: 500,
                          [theme.breakpoints.down('xl')]: {
                            fontSize: '14px',
                          },
                          [theme.breakpoints.down('lg')]: {
                            fontSize: '12px',
                          },
                        },
                      })}
                      key={index}
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
                columnGap: '30px',
              }}
            >
              <CustomSearch
                sx={(theme: Theme) => ({
                  [theme.breakpoints.up(1800)]: {
                    maxWidth: '600px',
                  },
                  [theme.breakpoints.up(1900)]: {
                    maxWidth: '750px',
                  },

                  [theme.breakpoints.between(1500, 1800)]: {
                    maxWidth: '415px',
                  },
                  [theme.breakpoints.between(1400, 1500)]: {
                    maxWidth: '380px',
                  },
                  [theme.breakpoints.between(1300, 1400)]: {
                    maxWidth: '340px',
                  },
                  [theme.breakpoints.between(1200, 1300)]: {
                    maxWidth: '200px',
                  },
                  [theme.breakpoints.between(1100, 1200)]: {
                    maxWidth: '350px',
                  },
                  [theme.breakpoints.between(1000, 1100)]: {
                    maxWidth: '320px',
                  },
                  [theme.breakpoints.between(900, 1000)]: {
                    maxWidth: '200px',
                  },
                })}
                value={searchText}
                placeholder={`Search by ${searchCriteria}`}
                onChange={handleSearchTextChanged}
              />

              <Button
                sx={(theme: Theme) => ({
                  borderRadius: '5px',
                  border: `1px solid  ${theme.palette.primary.main}`,
                  padding: '8px 26px',
                  fontSize: '14px',
                  fontWeight: '600 ',
                  [theme.breakpoints.between(768, 1200)]: {
                    fontSize: '13px',
                    py: '6px',
                  },
                  [theme.breakpoints.down(1200)]: {
                    fontSize: '13px',
                    py: '9.5px',
                  },
                })}
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(updateRefreshFlag(false));
                  navigate('asset-selection', { state: { from: 'newInvestor' } });
                }}
              >
                New Investor
              </Button>
            </Box>
          </Box>

          {displayLoader && (
            <Box
              sx={(theme: Theme) => ({
                position: 'relative',
                height: 'calc(100vh - 340px)',
              })}
            >
              <CustomLoader display={displayLoader} />
            </Box>
          )}

          {filteredInvestorsBySearchCriteria.length !== 0 ? (
            <TableContainer>
              <Table
                aria-label='collapsible table'
                sx={(theme: Theme) => ({
                  '& .MuiTableCell-root ': {
                    padding: '16px 10px',
                  },
                })}
              >
                <TableHead
                  sx={(theme: Theme) => ({
                    '& .MuiTableCell-root.MuiTableCell-head': {
                      fontSize: '14px',
                      padding: '16px 10px',
                      color: theme.palette.text.labelColor,
                      fontWeight: '500',
                      [theme.breakpoints.up('xl')]: {
                        fontSize: '15px',
                      },
                      [theme.breakpoints.between(768, 1024)]: {
                        fontSize: '13px',
                      },
                    },
                  })}
                >
                  <StyledTableRow>
                    <StyledTableCell />
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>PAN</StyledTableCell>
                    <StyledTableCell>Mobile Number</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Assets Empaneled</StyledTableCell>
                    <StyledTableCell colSpan={2} />
                    {/* <StyledTableCell /> */}
                  </StyledTableRow>
                </TableHead>
                <TableBody
                  sx={(theme: Theme) => ({
                    '&.MuiTableBody-root': {
                      '& .MuiTableRow-root': {
                        'backgroundColor': theme.palette.common.white,
                        '& .MuiTableCell-root ': {
                          '& .MuiTypography-root.MuiTypography-subtitle1': {
                            fontSize: { xs: '14px', xl: '16px' },
                            fontWeight: '400',
                          },
                        },
                      },
                    },
                  })}
                >
                  {filteredInvestorsBySearchCriteria
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((investor: any, index: any) => (
                      <Row
                        key={investor.pan}
                        investor={investor}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                display: displayLoader ? 'none' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                my: 3,
              }}
            >
              No Data found
            </Box>
          )}

          {!displayLoader && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={investorsList?.data?.invester?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Box>
    </>
  );
}
