import { Box, Grid, IconButton, Stack, styled, Theme, Typography } from '@mui/material';
import { Form, Formik, FormikErrors } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import {
  DetailsHeader,
  Investorcard,
  TransactionHeader,
} from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import FormCheckbox from '../../../forms/FormCheckbox';
import FormSelect from '../../../forms/FormSelect';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { useGetIfscDetailsMutation } from '../../slice';
import { BankFormValues } from './types';

const StyledGrid = styled(Grid)(({ theme }: { theme: Theme }) => ({
  '& .MuiGrid-item': {
    '&:last-child': {
      '& .MuiFormControl-root': {
        borderBottom: 'unset',
      },
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: '500',
      color: '#23263B',
      marginBottom: '16px',
    },
    '& .MuiFormControl-root': {
      'paddingBottom': '16px',
      'borderBottom': '1px solid',
      'borderColor': theme.palette.text.borderColorDark,
      'marginBottom': '16px',
      '& .MuiInputBase-root': {
        '& .MuiInputBase-input': {
          'padding': '5px 16px',
          '&.Mui-disabled': {
            'backgroundColor': theme.palette.text.borderColorDark,
            'border': 'unset',
            'color': theme.palette.text.primary,
            '-webkitTextFillColor': 'unset',
          },
        },
        '& .MuiSelect-select': {
          padding: '12px 16px',
        },
      },
    },
  },
}));

export default function BankForm({
  initialValues,
  onSubmit,
  header,
  subHeader,
  isUpdateIfsc,
  folios,
  setFolios,
}: {
  initialValues: BankFormValues;
  onSubmit: (data: BankFormValues) => void;
  header: string;
  subHeader: string;
  isUpdateIfsc?: boolean;
  folios: any;
  setFolios: React.Dispatch<any>;
}) {
  const [getIfscDetails] = useGetIfscDetailsMutation();
  const [ifscDetailsPopup, setIfscDetailsPopup] = React.useState<any>(undefined);

  const handleIfscChanged = async (
    ifsc: string,
    setFieldError: (field: string, message: string | undefined) => void,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<BankFormValues>>,
  ) => {
    if (ifsc.length === 11) {
      try {
        const ifscDetailsResponse: any = await getIfscDetails({
          requestData: {
            ifscCode: ifsc,
          },
        }).unwrap();
        if (ifscDetailsResponse?.data?.[0]) {
          const ifscData = ifscDetailsResponse.data[0];
          setIfscDetailsPopup(ifscData);
          setFieldValue(
            'branch',
            ifscData.BankAdd1 || ifscData.BankAdd2 || ifscData.BankAdd3,
          );
          setFieldValue('city', ifscData.BankCity);
        } else {
          setFieldError('ifsc', 'Incorrect IFSC entered.');
          setFieldValue('branch', '');
          setFieldValue('city', '');
        }
      } catch (e) {
        setFieldError('ifsc', 'Could not validate IFSC code.');
        setFieldValue('branch', '');
        setFieldValue('city', '');
      }
    }
  };

  const validationSchema = Yup.object().shape({
    accountNo: Yup.string().required('Account Number is required'),
    ifsc: Yup.string().required('IFSC code is required').length(11),
    name: Yup.string().required('Account name is required'),
    accountType: Yup.string().required('Account type is required'),
    branch: Yup.string().required('Branch name is required'),
    city: Yup.string().required('City name is required'),
    ...(!isUpdateIfsc
      ? {
          confirmAccountNo: Yup.string()
            .required('Account Number is required')
            .oneOf(
              [Yup.ref('accountNo')],
              'Confirm account number should match account number',
            ),
        }
      : {}),
  });

  return (
    <Investorcard
      sx={(theme: Theme) => ({
        padding: { xs: '0px', sm: '30px 30px' },
        [theme.breakpoints.down('sm')]: {
          boxShadow: 'unset',
          border: 0,
        },
      })}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Typography
            variant='subtitle2'
            sx={{ mb: 2, color: 'primary.main' }}
          >
            Selected Folios
          </Typography>
          {folios.map((folio: any) => {
            const defaultBank = (folio.banks || []).find(
              (bank: any) => bank.default === 'Y',
            );
            return (
              <Box
                key={`${folio.folio}_${folio.amcId}`}
                sx={{
                  padding: '20px 12px',
                  borderTop: '0.3px solid',
                  borderBottom: '0.3px solid',
                  borderColor: 'text.borderColorDark',
                }}
              >
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography
                    variant='subtitle2'
                    sx={{
                      color: 'primary.main',
                      fontSize: '15px',
                      opacity: '0.85',
                    }}
                  >
                    {folio.amcName}
                  </Typography>
                  <IconButton
                    onClick={() =>
                      setFolios((prev: any) =>
                        prev.filter((prevFolio: any) => prevFolio.folio !== folio.folio),
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                  >
                    <Stack
                      rowGap='10px'
                      my='10px'
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>
                        Primary Holder:
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor' }}
                      >
                        {folio.investorName || 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Stack
                      rowGap='10px'
                      my='10px'
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>
                        IFSC Code:
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor' }}
                      >
                        {defaultBank?.ifsc || 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Stack
                      rowGap='10px'
                      my='10px'
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>
                        Account Number:
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor' }}
                      >
                        {defaultBank?.accountNo || 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Stack
                      rowGap='10px'
                      my='10px'
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>
                        Folio number:
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        sx={{ color: 'text.valueColor' }}
                      >
                        {folio.folio || 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
        >
          <Box
            sx={(theme: Theme) => ({
              borderLeft: {
                xs: 'unset',
                sm: `1px solid ${theme.palette.text.borderColorDark}`,
              },
              py: { xs: 2.5, sm: 'unset' },
              paddingLeft: { xs: '0px', sm: '26px' },
              marginLeft: { xs: '0px', sm: '26px' },
            })}
          >
            <DetailsHeader
              sx={{
                '&.MuiTypography-root': {
                  fontSize: { xs: '16px', sm: '18px' },
                  opacity: '0.85',
                  mb: 1,
                },
              }}
            >
              {header}
            </DetailsHeader>
            <Typography sx={{ color: 'text.labelColor', mb: 2 }}>{subHeader}</Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ setFieldValue, setFieldError }) => (
                <Form>
                  <StyledGrid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography>Account Number</Typography>
                      <FormTextInput
                        disabled={isUpdateIfsc}
                        required={false}
                        name='accountNo'
                        placeholder='Account Number'
                      />
                    </Grid>
                    {!isUpdateIfsc && (
                      <Grid
                        item
                        xs={12}
                      >
                        <Typography>Confirm Account Number</Typography>
                        <FormTextInput
                          required={false}
                          name='confirmAccountNo'
                          placeholder='Confirm Account Number'
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography>IFSC Code</Typography>
                      <FormTextInput
                        onChange={async e =>
                          handleIfscChanged(e.target.value, setFieldError, setFieldValue)
                        }
                        required={false}
                        name='ifsc'
                        placeholder='IFSC Code'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography>Bank Name</Typography>
                      <FormTextInput
                        disabled
                        required={false}
                        name='name'
                        placeholder='Bank Name'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography>Account Type</Typography>
                      <FormSelect
                        options={[
                          { label: 'NRE', value: 'NRE' },
                          { label: 'NRO', value: 'NRO' },
                          { label: 'Savings', value: 'SAVING' },
                        ]}
                        disabled={isUpdateIfsc}
                        required={false}
                        name='accountType'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography>Branch</Typography>
                      <FormTextInput
                        disabled
                        required={false}
                        name='branch'
                        placeholder='Branch'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography>City</Typography>
                      <FormTextInput
                        disabled
                        required={false}
                        name='city'
                        placeholder='City'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <FormCheckbox
                        name='defaultBankAccount'
                        label='Set as default bank Account'
                        margin='dense'
                      />
                    </Grid>
                  </StyledGrid>
                  {!!ifscDetailsPopup && (
                    <CustomDialog
                      isVisible={true}
                      handleClose={() => {}}
                      firstButtonTitle='Ok'
                      firstButtonHandler={() => setIfscDetailsPopup(undefined)}
                      secondButtonTitle='Cancel'
                      secondButtonHandler={() => (
                        setFieldValue('ifsc', ''),
                        setFieldValue('branch', ''),
                        setFieldValue('city', ''),
                        setIfscDetailsPopup(undefined)
                      )}
                      showSecondButton={true}
                    >
                      <Box>
                        <TransactionHeader>
                          Please confirm Bank Details{' '}
                        </TransactionHeader>
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={6}
                            sm={4}
                          >
                            <Stack rowGap={'8px'}>
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Bank Name:
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {ifscDetailsPopup.BankName}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                          >
                            <Stack rowGap={'8px'}>
                              <Typography sx={{ color: 'text.labelColor' }}>
                                IFSC Code:
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {ifscDetailsPopup.IFSCCode}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                          >
                            <Stack rowGap={'8px'}>
                              <Typography sx={{ color: 'text.labelColor' }}>
                                City:
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {ifscDetailsPopup.BankCity}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                          >
                            <Stack rowGap={'8px'}>
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Branch:
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {ifscDetailsPopup.BankAdd1 ||
                                  ifscDetailsPopup.BankAdd2 ||
                                  ifscDetailsPopup.BankAdd3}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </CustomDialog>
                  )}
                  <SubmitButton label='Submit' />
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Investorcard>
  );
}
