import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import * as Yup from 'yup';

import { DetailsValue } from '../../../auth/login/styles/styledComponents';
import CustomLoader from '../../../common/CustomLoader';
import { useRootContext } from '../../../data/root.context';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { NreActypeOption, ResidentIndiaAccTypeOption } from '../../mfs/constants';
import { useGetIfscDetailsMutation } from '../../slice';
import { setInvestorBankDetails, setStepNumber } from '../context/MfsNewPurchaseAction';
import { useMfsNewPurchaseContext } from '../context/MfsNewPurchaseContext';

interface InitialBankDetails {
  accountType: string;
  ifscCode: string;
  accountNumber: string;
  confirmAccountNumber: string;
  bankName: string;
  bankBranch: string;
  branchAddress: string;
  branchPincode: string;
}

const BankDetails: React.FC = () => {
  const [getIfscDetails] = useGetIfscDetailsMutation();
  const { showToast } = useRootContext();
  const [displayLoader, setDisplayLoader] = useState(false);

  const {
    dispatch: contextDispatch,
    state: { investorBankDetails, investorDetailsFormResponse, investorDetails },
  } = useMfsNewPurchaseContext();

  const { bankDetails } = investorDetailsFormResponse || {};

  const initialValues: InitialBankDetails = {
    accountType:
      investorBankDetails?.accountType || investorDetails?.category === '11'
        ? 'Saving'
        : 'nre',
    ifscCode: investorBankDetails?.ifscCode || bankDetails.ifsc || '',
    accountNumber: investorBankDetails?.accountNumber || bankDetails.accNo || '',
    confirmAccountNumber: investorBankDetails?.confirmAccountNumber || '',
    bankName: investorBankDetails?.bankName || bankDetails.bank || '',
    bankBranch: investorBankDetails?.bankBranch || bankDetails.city || '',
    branchAddress: investorBankDetails?.branchAddress || bankDetails.address1 || '',
    branchPincode: investorBankDetails?.branchPincode || bankDetails.pinCode || '',
  };

  const validationSchema = Yup.object().shape({
    accountType: Yup.string().required('Account Type is required'),
    ifscCode: Yup.string()
      .required('IFSC code is required')
      .length(11, 'IFSC code must be 11 characters'),
    branchPincode: Yup.string()
      .required('Branch pincide is required')
      .matches(/^[0-9]{6}$/, 'Branch pincode must contain exactly 6 numbers'),
    accountNumber: Yup.string()
      .required('Account number is required')
      .matches(/^[0-9]+$/, 'Account number must contain only numbers')
      .min(9, 'Account number should be at least 9 digits')
      .max(18, 'Account number should not be greater than 18 digits'),
    confirmAccountNumber: Yup.string()
      .required('Please verify Account number')
      .matches(/^[0-9]+$/, 'Account number must contain only numbers')
      .test('accountNumberMatch', 'Account numbers do not match', function (value) {
        return value === this.parent.accountNumber;
      }),
  });

  const handleSubmit = (values: InitialBankDetails) => {
    contextDispatch(setInvestorBankDetails(values));
    contextDispatch(setStepNumber(4));
  };

  const handleIfscChanged = async (
    ifsc: string,
    setFieldError: (field: string, message: string | undefined) => void,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    if (ifsc.length === 11) {
      try {
        setDisplayLoader(true);
        const ifscDetailsResponse: any = await getIfscDetails({
          requestData: {
            ifscCode: ifsc,
          },
        }).unwrap();
        if (ifscDetailsResponse?.data?.[0]) {
          setFieldValue(`bankName`, ifscDetailsResponse?.data[0].BankName);
          setFieldValue(`branchPincode`, ifscDetailsResponse?.data[0].BankPin);
          setFieldValue(`bankBranch`, ifscDetailsResponse?.data[0].BankCity);
          const branchAddress = [
            ifscDetailsResponse?.data[0]?.BankAdd1,
            ifscDetailsResponse?.data[0]?.BankAdd2,
            ifscDetailsResponse?.data[0]?.BankAdd3,
          ]
            .filter(Boolean)
            .join(', ');
          setFieldValue(`branchAddress`, branchAddress);
        } else {
          showToast(ifscDetailsResponse?.errors[0]?.message, 'error');
        }
      } catch (error: any) {
        setFieldError(`ifscCode`, 'Could not validate IFSC code.');
      } finally {
        setDisplayLoader(false);
      }
    } else {
      setFieldValue(`bankName`, '');
      setFieldValue(`branchPincode`, '');
      setFieldValue(`bankBranch`, '');
      setFieldValue(`branchAddress`, '');
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, setFieldError, setFieldTouched, setValues }) => (
          <Form>
            <Box sx={{ mb: 3 }}>
              <DetailsValue sx={{ mb: 2 }}>Account Type</DetailsValue>
              <FormStyledRadioButton
                options={
                  investorDetails?.category === '11'
                    ? ResidentIndiaAccTypeOption
                    : NreActypeOption
                }
                name='accountType'
              />
            </Box>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormTextInput
                  name='ifscCode'
                  label='IFSC Code'
                  onChange={e => {
                    setFieldValue('ifscCode', e.target.value.toUpperCase());
                    handleIfscChanged(e.target.value, setFieldError, setFieldValue);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormTextInput
                  name='accountNumber'
                  label='Account Number'
                  onChange={() => {
                    setFieldValue(`confirmAccountNumber`, '');
                  }}
                  type='password'
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormTextInput
                  name='confirmAccountNumber'
                  label='Confirm Account Number'
                  type='password'
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormTextInput
                  name='bankName'
                  label='Bank Name'
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormTextInput
                  name='bankBranch'
                  label='Bank Branch'
                  required={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormTextInput
                  name='branchAddress'
                  label='Branch Address'
                  required={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormTextInput
                  name='branchPincode'
                  label='Branch Pincode'
                />
              </Grid>
            </Grid>
            {displayLoader && <CustomLoader display={displayLoader} />}
            <SubmitButton
              sx={{
                '&.MuiButtonBase-root': {
                  borderRadius: '5px',
                  minWidth: { xs: '100%', sm: '200px' },
                  mt: 5,
                },
              }}
              label='Proceed'
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BankDetails;
