import { Box, Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as BankIcon } from '../../../../../assets/bank-icon.svg';
import useSip from '../../../../../hooks/useSip';
import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../../../common/BackToHomePage';
import CustomLoader from '../../../../common/CustomLoader';
import { useRootContext } from '../../../../data/root.context';
import { LoginResponse } from '../../../../features/login/loginSlice';
import FormCheckbox from '../../../../forms/FormCheckbox';
import FormDateInput from '../../../../forms/FormDateInput';
import { FormSelectInput } from '../../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput from '../../../../forms/FormTextInput';
import EuinsFields from '../../../euinsComponent';
import { dateToDDMMYYY } from '../../../nps/investorOnBoarding/utils';
import { useGetEUINSQuery, useGetSchemaDetailsMutation } from '../../../slice';
import {
  getPerpetualSipEndDate,
  handlePerpetualSipBoxChnage,
  maxInstallmentBasedOnFrequency,
} from '../../../utils';
import { setInvestmentDetails, setSipDates } from '../context/NewMfsInvestorAction';
import { useNewMfsInvestorContext } from '../context/NewMfsInvestorContext';
import { useAllPaymentModesMutation } from '../slice';
import { dateToDDMMYYYY } from '../utils';

interface Option {
  value: any;
  label: any;
}

interface InvestmentDetails {
  arnCode: string;
  subArnCode: string;
  subBrokerCode: string;
  euin: string;
  modeOfTransaction: string;
  investmentAmount: string;
  frequency: string;
  sipDay: string;
  numberOfInstalments: string;
  startDate: string | null;
  endDate: string | null;
  lumpsumAmount: string;
  perpetual: boolean;
  modeOfRegistration: string;
}

const MfsInvestmentDetails: React.FC = () => {
  const navigate = useNavigate();
  const [schemaData, setSchemaData] = useState<any>({});
  const { showToast } = useRootContext();
  const [sipFrequencyOption, setSipFrequencyOptions] = useState<Option[]>([]);
  const [sipDays, setSipDays] = useState<Option[]>([]);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [paymentModeOptions, setPaymnetModeOptions] = useState<any[]>([]);
  const [selectedFrequencyAndSip, setSelectedFrequencyAndSip] = useState<{
    selectedFrequency: any;
    selectedSipDay: any;
    noOfInstallments: number;
  }>({
    selectedFrequency: null,
    selectedSipDay: null,
    noOfInstallments: 0,
  });
  const { arn, euin, subArn } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );

  const {
    dispatch: contextDispatch,
    state: { schemeDetails, fundName, investmentDetails },
  } = useNewMfsInvestorContext();

  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const [getAllPaymentModes] = useAllPaymentModesMutation();
  const { data: allEUINs } = useGetEUINSQuery([]);

  const { schemeDesc, planDesc } = schemeDetails || {};

  const [startDate, endDate] = useSip({
    frequencyType: selectedFrequencyAndSip?.selectedFrequency || 'Monthly',
    numInstallments: Number(selectedFrequencyAndSip?.noOfInstallments) || 1,
    sipDay: selectedFrequencyAndSip?.selectedSipDay
      ? String(selectedFrequencyAndSip?.selectedSipDay)
      : '1',
    sipTat:
      schemaData?.frequency?.find(
        (f: any) => f.mode === selectedFrequencyAndSip?.selectedFrequency,
      )?.coolingPeriod || 0,
  });

  React.useEffect(() => {
    const getPaymentOptions = async () => {
      const payload = {
        fund: schemeDetails?.fund,
      };
      const response = await getAllPaymentModes(payload).unwrap();
      const options = Object.entries(response)
        .map(([key, value]) => {
          if (value === 'Y') {
            return { label: key.toUpperCase(), value: key };
          }
          return null;
        })
        .filter(Boolean);
      setPaymnetModeOptions(options);
    };
    getPaymentOptions();
  }, []);

  const initialValues: InvestmentDetails = {
    arnCode: investmentDetails?.arnCode || arn || 'NA',
    subArnCode: investmentDetails?.subArnCode || subArn || '',
    subBrokerCode: investmentDetails?.subBrokerCode || '',
    euin: investmentDetails?.euin || euin || '',
    modeOfTransaction: investmentDetails?.modeOfTransaction || '',
    investmentAmount: investmentDetails?.investmentAmount || '',
    frequency: investmentDetails?.frequency || '',
    sipDay: investmentDetails?.sipDay || '',
    numberOfInstalments: investmentDetails?.numberOfInstalments || '',
    startDate: investmentDetails?.startDate || '',
    endDate: investmentDetails?.endDate || '',
    lumpsumAmount: investmentDetails?.lumpsumAmount || '',
    perpetual: investmentDetails?.perpetual || '',
    modeOfRegistration: investmentDetails?.lumpsumAmount || '',
  };

  const validationSchema = Yup.object().shape({
    modeOfTransaction: Yup.string().required('Mode of Transaction is required'),
    arnCode: Yup.string().required('ARN code is required'),
    subArnCode: Yup.string().test(
      'subArnCode',
      'Sub ARN code cannot be the same as ARN code',
      function (value) {
        const { arnCode } = this.parent;
        return value !== arnCode;
      },
    ),
    subBrokerCode: Yup.string().matches(
      /^[a-zA-Z0-9]+$/,
      'Sub broker code must contain only alphabets and numbers',
    ),
    investmentAmount: Yup.string()
      .required('Investment Amount is required')
      .min(1, 'Minimum investment amount should not be 0')
      .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
    frequency: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string().required('SIP frequency is required'),
    }),
    sipDay: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string().required('SIP Day is required'),
    }),
    numberOfInstalments: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string()
        .required('Number of Installment is required')
        .matches(/^[0-9]+$/, 'Number of installments must contain only numbers'),
    }),
    startDate: Yup.string()
      .nullable()
      .test('is-valid-date', 'Start date is required', function (value) {
        const { modeOfTransaction } = this.parent;
        if (modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP') {
          return startDate != null;
        }
        return true;
      }),
    endDate: Yup.string()
      .nullable()
      .test('is-valid-date', 'End date is required', function (value) {
        const { modeOfTransaction } = this.parent;
        if (modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP') {
          return endDate != null;
        }
        return true;
      }),
    lumpsumAmount: Yup.string().when('modeOfTransaction', {
      is: 'Lumpsum + SIP',
      then: Yup.string()
        .required('Lumpsum Amount is required')
        .min(1, 'Minimum investment amount should not be 0')
        .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
    }),
  });

  const handleModeOfTransactionChange = (
    setFieldValue: (field: string, value: any) => void,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    setFieldError('investmentAmount', '');
    setFieldError('frequency', '');
    setFieldError('sipDay', '');
    setFieldError('numberOfInstalments', '');
    setFieldError('startDate', '');
    setFieldError('endDate', '');
    setFieldError('lumpsumAmount', '');

    setFieldValue('investmentAmount', '');
    setFieldValue('frequency', '');
    setFieldValue('sipDay', '');
    setFieldValue('numberOfInstalments', '');
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
    setFieldValue('lumpsumAmount', '');
    setFieldValue('modeOfRegistration', '');
    setFieldValue('perpetual', false);
  };

  const handleSubmit = (values: InvestmentDetails) => {
    contextDispatch(setInvestmentDetails(values));
    contextDispatch(setSipDates({ startDate, endDate }));
    navigate('../new-investor-onboarding');
  };

  const validateMinInvestmentAmount = (values: InvestmentDetails) => {
    const errors: Record<string, any> = {};
    const minAmount = schemaData?.minTransactionAmount;

    const selectedFrequency = schemaData?.frequency?.find(
      (item: any) => item.mode === values.frequency,
    );
    const minInstallments = selectedFrequency?.minInstallments || '';
    const minSipAmount = selectedFrequency?.minTransactionAmount;

    if (Number(values.investmentAmount) < minAmount) {
      errors.investmentAmount = `Minimum Investment Amount should be ${minAmount}`;
    }
    if (Number(values.investmentAmount) < minSipAmount) {
      errors.investmentAmount = `Minimum Investment Amount should be ${minSipAmount}`;
    }
    if (
      values.modeOfTransaction === 'Lumpsum + SIP' &&
      Number(values.lumpsumAmount) < minSipAmount
    ) {
      errors.lumpsumAmount = `Minimum Lumpsum Amount should be ${minSipAmount}`;
    }
    if (Number(values.numberOfInstalments) < minInstallments) {
      errors.numberOfInstalments = `Number of installments should be ${minInstallments}`;
    }
    return errors;
  };

  const handleSipFrequencyChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFieldValue('frequency', e.target.value);
    setSelectedFrequencyAndSip({
      ...selectedFrequencyAndSip,
      selectedFrequency: e.target.value,
      noOfInstallments: 0,
    });
    const sipCycle = schemaData?.frequency?.find(
      (item: any) => item.mode === e.target.value,
    );
    if (sipCycle) {
      const cycleArray = sipCycle.cycle?.split(',') || [];
      let sipDays = [];
      const isNumericSequence = cycleArray.every(
        (day: { trim: () => number }) => !isNaN(day.trim()),
      );
      if (!isNumericSequence) {
        sipDays = Array.from({ length: 28 }, (_, i) => {
          const day = (i + 1).toString();
          return {
            label: day,
            value: day,
          };
        });
      } else {
        sipDays =
          cycleArray?.map((day: string) => {
            const dayWithoutLeadingZero = parseInt(day, 10).toString();
            return {
              label: dayWithoutLeadingZero,
              value: dayWithoutLeadingZero,
            };
          }) || [];
      }
      setSipDays(sipDays);
    }
  };

  const handleModeOfRegistrationChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
    values: InvestmentDetails,
  ) => {
    setFieldValue('modeOfRegistration', e?.target?.value);

    if (values.modeOfRegistration !== '') {
      setFieldValue('frequency', '');
      setFieldValue('sipDay', '');
      setFieldValue('numberOfInstalments', '');
      setFieldValue('startDate', null);
      setFieldValue('endDate', null);
      setFieldValue('perpetual', false);
    }
  };

  return (
    <>
      <PageNavigator
        title='Investment Details'
        subtitle='Please enter the investment details'
      />
      <Investorcard
        sx={(theme: Theme) => ({
          padding: {
            xs: '0px',
            sm: '35px 35px',
            [theme.breakpoints.down('sm')]: {
              boxShadow: 'unset',
              border: 'unset',
            },
          },
        })}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          validate={validateMinInvestmentAmount}
        >
          {({ values, setFieldValue, setFieldError, errors }) => {
            const selectedFrequency = schemaData?.frequency?.find(
              (item: any) => item.mode === values.frequency,
            );
            const minInstallments = selectedFrequency?.minInstallments || '';
            const sipMinAmount = selectedFrequency?.minTransactionAmount || '';
            return (
              <Form>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '16px',
                      xl: '18px',
                    },
                    fontWeight: 500,
                    color: { xs: 'text.valueColor', sm: 'primary.main' },
                    mb: 2,
                  }}
                >
                  Distributor Details
                </Typography>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                  >
                    <FormTextInput
                      name='arnCode'
                      label='ARN Code'
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                  >
                    <FormTextInput
                      name='subArnCode'
                      label='Sub ARN Code'
                      required={false}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={4}
                    md={3}
                    xs={12}
                  >
                    <FormTextInput
                      name='subBrokerCode'
                      label='Sub Broker Code'
                      required={false}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <EuinsFields
                      name='euin'
                      allEUINs={allEUINs}
                      defaultValue={euin}
                    />
                  </Grid>
                </Grid>

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  sx={{
                    border: '1px dashed ',
                    borderColor: 'text.borderColorDark',
                    my: 3,
                  }}
                />

                <Typography
                  sx={{
                    fontSize: {
                      xs: '16px',
                      xl: '18px',
                    },
                    fontWeight: 500,
                    color: { xs: 'text.valueColor', sm: 'primary.main' },
                    mb: 2,
                  }}
                >
                  Mode of Transaction *
                </Typography>
                <FormStyledRadioButton
                  options={[
                    { label: 'Lumpsum', value: 'Lumpsum' },
                    { label: 'SIP', value: 'SIP' },
                    { label: 'Lumpsum + SIP', value: 'Lumpsum + SIP' },
                  ]}
                  name='modeOfTransaction'
                  handleChange={async (e: any) => {
                    try {
                      setDisplayLoader(true);
                      const payload = {
                        fund: schemeDetails?.fund,
                        scheme: schemeDetails?.scheme,
                        plan: schemeDetails?.plan,
                        option: schemeDetails?.option,
                        trType: e.target.value == 'Lumpsum' ? 'NPL' : 'SIP',
                      };
                      const result: any = await getSchemaDetails(payload).unwrap();
                      setSchemaData(result);
                      if (result?.frequency?.length === 0) {
                        showToast('Scheme is not allowed for transaction', 'error');
                      }
                      if (result?.frequency) {
                        const uniqueModes = result.frequency
                          .map((option: { mode: any }) => option.mode)
                          .filter(
                            (mode: any, index: any, self: string | any[]) =>
                              self.indexOf(mode) === index,
                          );
                        const sipFrequency = uniqueModes.map((mode: any) => ({
                          label: mode,
                          value: mode,
                        }));
                        setSipFrequencyOptions(sipFrequency);
                      }
                    } catch (error: any) {
                      showToast(
                        (error as { message: string })?.message ||
                          error?.data?.message ||
                          'Unknown error',
                        'error',
                      );
                    } finally {
                      setDisplayLoader(false);
                    }
                    handleModeOfTransactionChange(setFieldValue, setFieldError);
                  }}
                />

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  sx={{
                    border: '1px dashed ',
                    borderColor: 'text.borderColorDark',
                    my: 3,
                  }}
                />
                <Typography
                  sx={{
                    fontSize: {
                      xs: '16px',
                      xl: '18px',
                    },
                    fontWeight: 500,
                    color: { xs: 'text.valueColor', sm: 'primary.main' },
                    mb: 2,
                  }}
                >
                  Scheme Details
                </Typography>
                <Box
                  sx={{
                    bgcolor: 'text.boxColor',
                    borderRadius: '10px',
                    padding: { xs: '20px 20px', md: '35px 35px' },
                    mb: 2,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      sm={1}
                      lg={0.7}
                    >
                      <Box
                        sx={{
                          width: '42px',
                          height: '42px',
                          borderRadius: '5px',
                          border: '0.5px solid',
                          borderColor: 'text.borderColorLight',
                          boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                          padding: '4px 4px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <BankIcon />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      sm={11}
                      lg={11.3}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: 'text.labelColor',
                            }}
                          >
                            {fundName}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            {`${schemeDesc} - ${planDesc}`}
                          </Typography>
                        </Stack>
                      </Box>
                      <Grid
                        container
                        spacing={2}
                        mt={'26px'}
                        // columnGap={'55px'}
                      >
                        {values?.modeOfTransaction !== '' &&
                          values?.modeOfTransaction !== 'Lumpsum' && (
                            <>
                              <Grid
                                item
                                sm={6}
                                lg={4}
                                xs={12}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                                  }}
                                >
                                  Mode of registration
                                </Typography>
                                <FormSelectInput
                                  name='modeOfRegistration'
                                  label='Mode Of Registration'
                                  defaultValue={''}
                                  required
                                  options={paymentModeOptions.filter(
                                    item =>
                                      item.label !== 'NETBANKING' && item.label !== 'UPI',
                                  )}
                                  onChange={e =>
                                    handleModeOfRegistrationChange(
                                      e,
                                      setFieldValue,
                                      values,
                                    )
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                                  }}
                                >
                                  Frequency
                                </Typography>

                                <FormSelectInput
                                  name='frequency'
                                  label='Frequency'
                                  defaultValue={''}
                                  required
                                  options={sipFrequencyOption}
                                  onChange={(e: any) => {
                                    setFieldValue('sipDay', '');
                                    setFieldValue('numberOfInstalments', '');
                                    setFieldValue('startDate', null);
                                    setFieldValue('endDate', null);
                                    setFieldValue('perpetual', false);
                                    handleSipFrequencyChange(e, setFieldValue);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                                  }}
                                >
                                  No of Installments
                                </Typography>
                                <FormTextInput
                                  name='numberOfInstalments'
                                  label='Number Of Installments'
                                  required
                                  onChange={e => {
                                    setFieldValue('sipDay', '');
                                    setFieldValue('startDate', null);
                                    setFieldValue('endDate', null);
                                    setSelectedFrequencyAndSip({
                                      ...selectedFrequencyAndSip,
                                      noOfInstallments: Number(e.target.value),
                                    });
                                    //TODO: Need to change the logic based on the actual api response for sip dates.
                                    const end_date = getPerpetualSipEndDate(
                                      values.modeOfRegistration,
                                      startDate,
                                    );
                                    const maxInstallments =
                                      maxInstallmentBasedOnFrequency(
                                        values.frequency,
                                        values.modeOfRegistration,
                                      );
                                    if (
                                      values.modeOfRegistration === 'ISIP' &&
                                      Number(e.target.value) > maxInstallments
                                    ) {
                                      showToast(
                                        'Number of installments needs to reduced so that end date is not greater than 12/2099',
                                        'error',
                                      );
                                      setFieldValue('numberOfInstalments', '');
                                    } else if (
                                      values.modeOfRegistration === 'kotm' &&
                                      Number(e.target.value) > maxInstallments
                                    ) {
                                      showToast(
                                        `SIP End date cannot exceed ${dateToDDMMYYY(
                                          end_date,
                                        )}`,
                                        'error',
                                      );
                                      setFieldValue('numberOfInstalments', '');
                                    }
                                  }}
                                  disabled={values?.perpetual}
                                />

                                <Typography>
                                  Minimum Number of installments: {minInstallments}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                                  }}
                                >
                                  SIP Day
                                </Typography>
                                <FormSelectInput
                                  name='sipDay'
                                  label='SIP Day'
                                  defaultValue={''}
                                  required
                                  options={sipDays}
                                  onChange={e => {
                                    setSelectedFrequencyAndSip({
                                      ...selectedFrequencyAndSip,
                                      selectedSipDay: e.target.value,
                                    });
                                    //TODO: Need to change the logic based on the actual api response.
                                    const actualTime = new Date(endDate).getTime();
                                    const end_date = getPerpetualSipEndDate(
                                      values.modeOfRegistration,
                                      startDate,
                                    );
                                    const requiredTime = new Date(end_date).getTime();
                                    if (
                                      values.modeOfRegistration === 'ISIP' &&
                                      actualTime > requiredTime
                                    ) {
                                      showToast(
                                        'Number of installments needs to reduced so that end date is not greater than 12/2099',
                                        'error',
                                      );
                                      setFieldValue('numberOfInstalments', '');
                                    } else if (
                                      values.modeOfRegistration === 'kotm' &&
                                      actualTime > requiredTime
                                    ) {
                                      showToast(
                                        `SIP End date cannot exceed ${dateToDDMMYYY(
                                          end_date,
                                        )}`,
                                        'error',
                                      );
                                      setFieldValue('numberOfInstalments', '');
                                      setFieldValue('sipDay', '');
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                                  }}
                                >
                                  Start Date
                                </Typography>
                                <FormDateInput
                                  name='startDate'
                                  label='Start Date'
                                  required={true}
                                  value={dateToDDMMYYY(startDate)}
                                  disabled
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                                  }}
                                >
                                  End Date
                                </Typography>
                                <FormDateInput
                                  name='endDate'
                                  label='End Date'
                                  value={
                                    values.perpetual
                                      ? dateToDDMMYYYY(values.endDate)
                                      : dateToDDMMYYY(endDate)
                                  }
                                  required={true}
                                  disabled
                                />
                              </Grid>
                              {values.sipDay !== '' && (
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormCheckbox
                                    sx={{
                                      '& .MuiFormControlLabel-root': {
                                        '& .MuiFormControlLabel-label': {
                                          fontSize: '14px',
                                        },
                                      },
                                    }}
                                    name='perpetual'
                                    label='Perpetual SIP'
                                    margin='dense'
                                    checked={values?.perpetual}
                                    onChange={e =>
                                      handlePerpetualSipBoxChnage(
                                        e,
                                        setFieldValue,
                                        values,
                                        startDate,
                                        selectedFrequencyAndSip,
                                      )
                                    }
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                        >
                          <Typography
                            sx={{
                              color: 'text.labelColor',
                              mb: 0.5,
                              fontWeight: 500,
                              fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                            }}
                          >
                            Investment Amount
                          </Typography>
                          <FormTextInput
                            name='investmentAmount'
                            label='Investment Amount'
                            required
                          />
                          <Typography>
                            Minimum Amount:{' '}
                            {values.modeOfTransaction === 'Lumpsum'
                              ? schemaData?.minTransactionAmount
                              : sipMinAmount}
                          </Typography>
                        </Grid>
                        {values.modeOfTransaction === 'Lumpsum + SIP' && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={4}
                          >
                            <Typography
                              sx={{
                                color: 'text.labelColor',
                                mb: 0.5,
                                fontWeight: 500,
                                fontSize: { xs: '12px', lg: '14px', xl: '16px' },
                              }}
                            >
                              Lumpsum Amount
                            </Typography>

                            <FormTextInput
                              name='lumpsumAmount'
                              label='Lumpsum Amount'
                            />
                            <Typography>Minimum Amount:{sipMinAmount}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {displayLoader && <CustomLoader display={displayLoader} />}
                </Box>
                <Stack
                  direction={'row'}
                  columnGap={'20px'}
                  mt={'26px'}
                >
                  <SubmitButton
                    sx={{
                      '&.MuiButtonBase-root': {
                        borderRadius: '5px',
                        minWidth: { xs: 'unset', sm: '200px' },
                        flex: { xs: 1, sm: 'unset' },
                        my: { xs: 'unset' },
                        padding: '8px 8px',
                      },
                    }}
                    label='Continue'
                  >
                    Continue
                  </SubmitButton>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Investorcard>
    </>
  );
};

export default MfsInvestmentDetails;
