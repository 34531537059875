import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as DropdownIcon } from '../../../../assets/dropdown-icon.svg';
import InfoIcon from '../../../../assets/info-icon.svg';
import { CheckBoxHtmlTooltip } from '../../../common/checkbox';
import Layout from '../../Layout';
import DetailsDataTable from '../common/DetailsDataTable';
import { DetailType } from '../common/types';
import { InvestorNominee } from './InvestorNominee';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    'borderRadius': 6,
    'border': '1px solid',
    'borderColor': 'text.borderColorDark',
    'backgroundColor': 'common.white',
    'minWidth': 160,
    'color': theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    'boxShadow': '0px 4px 16px 0px rgba(0, 0, 0, 0.15)',
    '& .MuiMenu-list': {
      padding: '0px',
    },
    '& .MuiMenuItem-root': {
      'padding': '13px 10px',
      'justifyContent': 'center',
      'color': theme.palette.primary.main,
      'fontSize': '14px',
      'fontWeight': 600,
      '&:not(:last-of-type)': {
        borderBottom: '1px solid',
        borderColor: 'text.borderColorDark',
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
export default function UpdateNomineeDetails() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [toggleUpdate, setToggleUdpate] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<any>();
  const [noNominee, setNoNominee] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleUpdateClick(data: any) {
    setUpdateData(data);
    setToggleUdpate(true);
  }
  const navigate = useNavigate();
  const columns = [
    'Folio Number',
    'Fund House',
    'Primary Holder',
    'Mode of Holding',
    'Nominee',
  ];

  function isRowDisabled(currentRow: any): boolean {
    return currentRow.modeOfHolding !== '1';
  }

  function getTooltip(isSelected: boolean, isDisabled: boolean) {
    if (isSelected || !isDisabled) return undefined;
    return ({ children }: { children: JSX.Element }) => {
      return (
        <CheckBoxHtmlTooltip
          title={
            <>
              <Typography color='inherit'>
                Can only select folios with mode of Holding: Single
              </Typography>
            </>
          }
        >
          {children}
        </CheckBoxHtmlTooltip>
      );
    };
  }

  return (
    <Layout
      title='Update Nominee Details'
      onBackPress={
        toggleUpdate ? () => setToggleUdpate(prev => !prev) : () => navigate(-1)
      }
    >
      {!toggleUpdate ? (
        <>
          <DetailsDataTable
            columns={columns}
            onSubmit={handleUpdateClick}
            showEmailPhoneCombination
            detailType={DetailType.Investor}
            isRowDisabled={isRowDisabled}
            checkboxTooltip={getTooltip}
            showNominees
          />
        </>
      ) : (
        <>
          <Box
            sx={{
              borderRadius: '7px',
              backgroundColor: 'text.borderColorDark',
              display: 'flex',
              padding: '16px',
              gap: '10px',
            }}
          >
            <img
              src={InfoIcon}
              alt='info-icon'
            />
            <Typography
              variant='subtitle2'
              sx={{ color: 'text.labelColor' }}
            >
              Investor(s) have the option to choose upto three nominees
            </Typography>
          </Box>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: 'space-between', my: { xs: 1.5, md: 0 } }}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <FormControlLabel
                  sx={{
                    '& .MuiTypography-root': {
                      color: 'text.primary',
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                  }}
                  control={
                    <Checkbox
                      checked={noNominee}
                      onChange={() => setNoNominee(prev => !prev)}
                      sx={{
                        '&.Mui-checked': {
                          color: 'primary.main',
                        },
                      }}
                    />
                  }
                  label='I wish not to Nominate'
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Stack
                direction='row'
                sx={{
                  justifyContent: { xs: 'flex-start', md: 'flex-end' },
                  my: { xs: 0, md: '20px' },
                }}
              >
                <Typography
                  variant='subtitle2'
                  sx={{
                    color: 'text.labelColor',
                    opacity: '0.9',
                  }}
                >
                  Selected Folios:
                </Typography>
                <Typography
                  component='span'
                  sx={{
                    color: 'primary.main',
                    fontWeight: 600,
                    marginLeft: '5px',
                  }}
                >
                  {updateData[0].folio}
                </Typography>
                {updateData.length > 1 ? (
                  <>
                    <Button
                      sx={{
                        padding: '0px',
                        color: 'text.labelColor',
                        fontSize: '14px',
                        fontWeight: 500,
                        marginLeft: '5px',
                        borderBottom: '1px solid',
                        borderColor: 'text.labelColor',
                        borderRadius: '0px',
                      }}
                      variant='text'
                      id='demo-customized-button'
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      disableElevation
                      onClick={handleClick}
                      endIcon={<DropdownIcon />}
                    >
                      +{updateData.length - 1} more
                    </Button>
                    <StyledMenu
                      id='demo-customized-menu'
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      {updateData.slice(1).map((folioData: any) => (
                        <MenuItem
                          key={folioData.folio}
                          onClick={handleClose}
                          disableRipple
                        >
                          {folioData.folio}
                        </MenuItem>
                      ))}
                    </StyledMenu>
                  </>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
          <InvestorNominee
            noNominee={noNominee}
            data={
              updateData.length === 1 ? updateData[0] : { ...updateData[0], nominees: [] }
            }
          />
        </>
      )}
    </Layout>
  );
}
