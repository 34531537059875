import { Box, Grid, Stack, Typography } from '@mui/material';

import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';
import { FromAndToHeader } from '../common/helper';
import { TransactionType } from '../types';

const Cart = ({
  data,
  children,
  cartItemslength,
}: {
  data?: any;
  children?: React.ReactNode;
  cartItemslength?: boolean;
}) => (
  <Box
    sx={{
      'pb': 2,
      'mb': 3,
      '&:nth-of-type(n+2)': {
        borderTop: cartItemslength ? '1px solid ' : 'unset',
        borderColor: cartItemslength ? 'text.borderColorLight' : 'unset',
        pt: cartItemslength ? 3 : 'unset',
      },
    }}
  >
    {children}
    <Typography sx={{ color: 'text.labelColor', mb: 2, fontWeight: 500 }}>
      Axis Mutual Fund
    </Typography>
    <FromAndToHeader
      data={data}
      type={TransactionType.SWITCH}
    />
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: 'space-between',
        my: 1,
      }}
    >
      <Grid
        item
        xs={6}
        sm={4}
      >
        <Stack gap='10px'>
          <CartLabel>Folio</CartLabel>
          <CartValue>{data.folioNo}</CartValue>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        sm={4}
      >
        <Stack gap='10px'>
          <CartLabel>Units</CartLabel>
          <CartValue>{data.cartUnits}</CartValue>
        </Stack>
      </Grid>

      <Grid
        item
        sm={4}
        xs={6}
      >
        <Stack gap='10px'>
          <CartLabel>Exit Load</CartLabel>
          <CartValue>₹ 1</CartValue>
        </Stack>
      </Grid>
    </Grid>
  </Box>
);

export default Cart;
