import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Theme, Typography } from '@mui/material';
import React, { Fragment } from 'react';

interface FilterTabsProps {
  tabValue: string;
  onChange: (newValue: string) => void;
  tabsArr: { label: string; value: string; totalCount?: number | string }[];
  tabContent: React.ReactNode[]; // Content for each TabPanel
  isAddUsersForm?: boolean;
  mobileTabsButton?: boolean;
}
const tabsColour = [
  { bgColor: 'text.primary', color: 'common.white' },
  { bgColor: 'rgba(40, 153, 103, 0.20)', color: 'text.darkGreen' },
  { bgColor: 'rgba(169, 107, 12, 0.20)', color: 'text.darkOrange' },
];
const FilterTabs: React.FC<FilterTabsProps> = ({
  tabValue,
  onChange,
  tabsArr,
  tabContent,
  isAddUsersForm = false,
  mobileTabsButton = false,
}) => {
  return (
    <Fragment>
      <TabContext value={tabValue}>
        <Box
          sx={(theme: Theme) => ({
            'p': isAddUsersForm ? '16px 30px' : 0,
            'mb': 2,
            '& .MuiTabs-root': {
              'borderBottom': '1px solid',
              'borderColor': 'text.borderColorLight',
              'pt': '10px',
              '& .MuiTabs-flexContainer': {
                'pl': '0px',
                'columnGap': '25px',
                'justifyContent': mobileTabsButton ? 'center' : 'flex-start',
                '& .MuiButtonBase-root.MuiTab-root': {
                  'padding': { xs: '10px 8px', sm: '12px 16px' },
                  'borderRadius': mobileTabsButton ? '6px' : 'unset',
                  'minHeight': mobileTabsButton ? '30px' : '',
                  '& .MuiTypography-root': {
                    'fontWeight': 500,
                    'fontSize': { xs: '12px', sm: '14px' },
                    'color': mobileTabsButton ? 'text.labelColor' : '',
                    '&:not(:last-child)': {
                      color: 'text.labelColor',
                    },
                  },
                  '&.Mui-selected': {
                    'backgroundImage': mobileTabsButton
                      ? theme.palette.background.primaryLinearGradient2
                      : 'unset',
                    '& .MuiTypography-root': {
                      'color': mobileTabsButton ? 'common.white' : '',
                      '&:not(:last-child)': {
                        color: 'text.primary',
                      },
                    },
                  },
                },
              },
              '& .MuiTabs-indicator': {
                bgcolor: 'text.primary',
                height: '3px',
                display: mobileTabsButton ? 'none' : 'inline-block',
              },
            },
            '& .MuiTabPanel-root': {
              padding: '0px',
            },
          })}
        >
          <TabList
            onChange={(event, newValue) => onChange(newValue)}
            aria-label='add users tab'
          >
            {tabsArr.map((tab, index) => (
              <Tab
                label={
                  <Stack
                    direction={'row'}
                    columnGap={'8px'}
                    alignItems={'center'}
                  >
                    <Typography sx={{ color: 'text.primary' }}>{tab.label}</Typography>
                    {tab?.totalCount && (
                      <Box
                        sx={{
                          bgcolor: tabsColour[index].bgColor,
                          borderRadius: '2.5px',
                          padding: '3px 3px',
                        }}
                      >
                        <Typography
                          sx={{
                            color: tabsColour[index].color,
                          }}
                          component={'span'}
                        >
                          {tab?.totalCount}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                }
                value={tab.value}
                key={tab.value}
              />
            ))}
          </TabList>
          {tabContent.map((content, index) => (
            <TabPanel
              key={tabsArr[index].value}
              value={tabsArr[index].value}
            >
              {content}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Fragment>
  );
};

export default FilterTabs;
