import { AddCircle } from '@mui/icons-material';

export const sections = {
  profile: {
    name: 'Profile',
    options: [
      {
        label: 'Personal Information',
        icon: <AddCircle />,
        path: '/profile/personal-information',
      },
      {
        label: 'Details of Related Person',
        icon: <AddCircle />,
        path: '/profile/nominee',
      },
      {
        label: 'Contact Details',
        icon: <AddCircle />,
        path: '/profile/contact-details',
      },
      {
        label: 'Additional KYC',
        icon: <AddCircle />,
        path: '/profile/additional-kyc',
      },
      {
        label: 'Nominee Details',
        icon: <AddCircle />,
        path: '/profile/nominee-details',
      },
      {
        label: 'FATCA Declaration',
        icon: <AddCircle />,
        path: '/profile/fatca-declaration',
      },
      {
        label: 'Bank Details',
        icon: <AddCircle />,
        path: '/profile/bank-details',
      },
      // {
      //   label: 'Documents',
      //   icon: <AddCircle />,
      //   path: '/profile/documents',
      // },
      // {
      //   label: 'Update Password',
      //   icon: <AddCircle />,
      //   path: '/profile/update-password',
      // },
    ],
  },
  reports: {
    name: 'Reports',
    options: [
      {
        label: 'Client Statement',
        icon: <AddCircle />,
        path: '/reports/client-statement',
      },
      {
        label: 'Transaction Statement',
        icon: <AddCircle />,
        path: '/reports/transaction-statement',
      },
      {
        label: 'Portfolio Summary',
        icon: <AddCircle />,
        path: '/reports/portfolio-summary',
      },
      {
        label: 'CG Statement',
        icon: <AddCircle />,
        path: '/reports/cg-statement',
      },
    ],
  },
};
