import { Box, Grid, Stack, Typography } from '@mui/material';

import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';
import { FromAndToHeader } from '../common/helper';
import { TransactionType } from '../types';

const Cart = ({ data, children }: { data?: any; children?: React.ReactNode }) => (
  <Box>
    {children}
    <Typography sx={{ color: 'text.labelColor', mb: 2 }}>Axis Mutual Fund</Typography>
    <FromAndToHeader
      data={data}
      type={TransactionType.STP}
    />
    <Grid
      container
      spacing={2}
      sx={theme => ({
        justifyContent: 'space-between',
      })}
    >
      <Grid
        item
        xs={6}
        sm={4}
      >
        <Stack gap='10px'>
          <CartLabel>Folio</CartLabel>
          <CartValue>{data.folio}</CartValue>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        sm={4}
      >
        <Stack gap='10px'>
          <CartLabel>Switch Type</CartLabel>
          {/* TODO: replace with proper type */}
          <CartValue>37969557252</CartValue>
        </Stack>
      </Grid>

      <Grid
        item
        xs={6}
        sm={4}
      >
        <Stack gap='10px'>
          <CartLabel>Units</CartLabel>
          <CartValue>{data.cartUnits}</CartValue>
        </Stack>
      </Grid>
    </Grid>
  </Box>
);

export default Cart;
