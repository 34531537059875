import { Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { format } from 'date-fns';
import * as React from 'react';

import { ReactComponent as BankIcon } from '../../assets/axis-icon.svg';
import { Investorcard } from '../auth/login/styles/styledComponents';
import { PageNavigator } from '../common/BackToHomePage';
import DataTable from '../common/DataTable';
import { DropDownToggle } from '../common/DropdownToggle';
import { CustomSearch } from '../common/search';
import ViewMyEarningsMobile from '../common/UI/ViewMyEarningsMobile';
import { useGetDistributorManagedFundsQuery, useGetFundEarningsMutation } from './slice';
export interface FundDetails {
  fundCode: string;
  fundName: string;
}
export interface BrokerPaymentDetails {
  amount: string;
  bankName: string;
  fromDate: string;
  paymode: string;
  refNo: number;
  toDate: string;
  trxnCount: number;
}
export interface AllData {
  value: number;
  totalAmount: number;
  tableData: BrokerPaymentDetails[];
  selectedFundObj: any; // TODO: change type
}
export const ViewMyEarnings = () => {
  const { data: allDistributorManagedFunds } = useGetDistributorManagedFundsQuery([]);
  const [getEarningsByFund] = useGetFundEarningsMutation();
  const [filteredDistributorManagedFunds, setFilteredDistributorManagedFunds] =
    React.useState([]);
  const [allData, setAllData] = React.useState<AllData>({
    value: 0,
    totalAmount: 0,
    tableData: [],
    selectedFundObj: allDistributorManagedFunds?.fundDetails[0] || {},
  });
  const [query, setQuery] = React.useState<string>('');
  const handleChange = (newValue: number) => {
    const selectedObj = filteredDistributorManagedFunds[newValue];
    setAllData(prevData => ({
      ...prevData,
      value: newValue,
      selectedFundObj: selectedObj,
    }));
  };

  React.useEffect(() => {
    const getFundEarnings = async () => {
      const response = await getEarningsByFund({
        fund: allData.selectedFundObj.fundCode,
      });

      if ('data' in response) {
        const brokPaymentDetails = response.data.brokPaymentDetails;

        setAllData(prevData => ({
          ...prevData,
          totalAmount: brokPaymentDetails.reduce(
            (sum: number, item: BrokerPaymentDetails) => sum + parseFloat(item.amount),
            0,
          ),
          tableData: brokPaymentDetails,
        }));
      }
    };
    if (allData.selectedFundObj.fundCode) {
      getFundEarnings();
    }
  }, [allData.value, allData.selectedFundObj.fundCode, allDistributorManagedFunds]);
  React.useEffect(() => {
    setFilteredDistributorManagedFunds(allDistributorManagedFunds?.fundDetails);
  }, []);
  const queryChangeHandler = (value: string) => {
    const filteredData = allDistributorManagedFunds?.fundDetails.filter((item: any) =>
      item.fundName.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredDistributorManagedFunds(filteredData);
    setQuery(value);
  };
  React.useEffect(() => {
    if (filteredDistributorManagedFunds?.length > 0) {
      handleChange(0); // Call handleChange with the new value (0) after filtering
    }
  }, [filteredDistributorManagedFunds]);
  const getRows = () => {
    return (
      allData.tableData?.map(({ paymode, fromDate, toDate, amount, refNo }) => ({
        'Reference No.': refNo,
        'Mode of Payment': paymode,
        'Time Period': `${format(new Date(fromDate), 'dd MMM yyyy')} to ${format(
          new Date(toDate),
          'dd MMM yyyy',
        )}`,
        'Amount': amount,
      })) || []
    );
  };
  return (
    <>
      <PageNavigator
        title='My Earnings'
        subtitle='Please specify which AMC earnings details to explore'
      />
      {/* Mobile UI */}
      <DropDownToggle />
      {/* Mobile UI */}
      <Investorcard
        sx={{
          padding: '30px 30px 0px 0px',
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            sm={3}
          >
            <Typography
              variant='h5'
              sx={{ ml: 3.5, mb: 3 }}
            >
              AMC Selection
            </Typography>
            <Box sx={{ padding: '0px 20px' }}>
              <CustomSearch
                value={query}
                placeholder='Search'
                onChange={(value: string) => queryChangeHandler(value)}
              />
            </Box>

            {/* <TextField
              fullWidth
              type='serach'
              placeholder='search'
              sx={{
                'p': '0px 20px',
                'mb': 3,
                '& .MuiInputBase-root': {
                  '&.Mui-focused,&:hover': {
                    '& .MuiOutlinedInput-notchedOutline ': {
                      border: '1px solid',
                      borderColor: 'text.borderColorLight',
                    },
                  },
                },
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'text.borderColorLight',
                },
                '& .MuiInputBase-input': {
                  padding: '10px 14px',
                  border: 'none',
                },
              }}
            /> */}
            <Tabs
              sx={{
                'maxHeight': 'calc(100vh - 380px)',
                'overflowY': 'auto',
                '& .MuiTabs-flexContainer': {
                  'alignItems': 'flex-start',
                  'pl': 0,
                  '& .MuiButtonBase-root.MuiTab-root ': {
                    'minWidth': '100%',
                    'alignItems': 'flex-start',
                    'minHeight': '78px',
                    'borderTop': '0.5px solid',
                    'borderBottom': '0.5px solid',
                    'borderColor': 'text.borderColorDark',
                    '&:last-child': {
                      borderBottom: '0px',
                    },
                    '& .MuiTypography-root': {
                      fontSize: '15px',
                      fontWeight: '400',
                      color: 'text.primary',
                      textAlign: 'left',
                    },
                    '&.Mui-selected': {
                      'bgcolor': 'text.boxColorLight',
                      '& .MuiTypography-root': {
                        color: 'primary.main',
                      },
                    },
                  },
                },
                '& .MuiButtonBase-root-MuiTab-root': {
                  minHeight: '78px',
                },
                '& .MuiTabs-indicator': {
                  left: 0,
                  height: '78px',
                  width: '5px',
                },
              }}
              orientation='vertical'
              variant='scrollable'
              value={allData.value}
              onChange={(_, newValue) => handleChange(newValue)}
              aria-label='Vertical tabs example'
            >
              {filteredDistributorManagedFunds?.map(
                (item: FundDetails, index: number) => (
                  <Tab
                    key={index}
                    label={
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <Box
                          sx={{
                            border: '1px solid',
                            borderColor: 'text.borderColorDark',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '5px',
                            mr: 2,
                            width: '28px',
                            height: '29px',
                          }}
                        >
                          <BankIcon />
                        </Box>
                        <Typography>{item.fundName}</Typography>
                      </Stack>
                    }
                    {...a11yProps(index)}
                  />
                ),
              )}
            </Tabs>
          </Grid>
          <Grid
            item
            sm={9}
            sx={{ borderLeft: '1px dashed', borderColor: 'text.borderColorLight' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'text.borderColorDark',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '5px',
                    mr: 2,
                  }}
                >
                  <BankIcon />
                </Box>

                <Typography variant='h4'>{allData?.selectedFundObj?.fundName}</Typography>
              </Box>
              <Box>
                <Typography
                  variant='h5'
                  sx={{ color: 'primary.main', mb: 1 }}
                >
                  ₹ {allData.totalAmount.toFixed(2)}
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{ color: 'text.labelColor', textAlign: 'right' }}
                >
                  Total
                </Typography>
              </Box>
            </Box>
            <Box>
              <DataTable
                rows={getRows()}
                columns={['Reference No.', 'Mode of Payment', 'Time Period', 'Amount']}
                onSubmit={() => {}}
                hideUpdateButton={true}
                hidecheckbox={true}
                showPaper={false}
              />
            </Box>
          </Grid>
        </Grid>
      </Investorcard>
      {/* Mobile UI */}
      <ViewMyEarningsMobile />
      {/* Mobile UI */}
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    'id': `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
