import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';

import { CustomSearch } from '../../../common/search';

const SearchTypo = styled(Typography)(({ theme }) => ({
  color: '#6C737F',
  fontSize: '14px',
  fontWeight: 400,
}));

interface TableFilterProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  isFromEmailOrPhone?: string;
  selectedFilteredValue: string;
  filterOptionChangeHandler: (event: SelectChangeEvent<string>) => void;
  filteredOptions: string[];
  searchText?: string;
  onSearchTextChange?: (value: string) => void;
}

const TableFilter: FunctionComponent<TableFilterProps> = ({
  open,
  setOpen,
  isFromEmailOrPhone,
  selectedFilteredValue,
  filterOptionChangeHandler,
  filteredOptions,
  searchText,
  onSearchTextChange,
}) => {
  return (
    <>
      {!isFromEmailOrPhone ? (
        <>
          <Grid
            container
            spacing={2}
            alignItems={'center'}
          >
            {/* TODO: add below filters */}
            {/* <Grid
              item
              sm={6}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={'30px'}
              >
                <Stack
                  direction='row'
                  alignItems='center'
                  gap='10px'
                >
                  <FilterIcon />
                  <SearchTypo>Filters :</SearchTypo>
                </Stack>
                <Stack
                  direction='row'
                  alignItems='center'
                >
                  <SearchTypo
                    sx={{
                      '&.MuiTypography-root': {
                        color: open ? '#2057A6' : '#6C737F',
                      },
                    }}
                  >
                    Fund House
                  </SearchTypo>
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </IconButton>
                </Stack>
                <Stack
                  direction='row'
                  alignItems='center'
                >
                  <SearchTypo
                    sx={{
                      '&.MuiTypography-root': {
                        color: open ? '#2057A6' : '#6C737F',
                      },
                    }}
                  >
                    Category
                  </SearchTypo>
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </IconButton>
                </Stack>
              </Stack>
            </Grid> */}
            <Grid
              item
              sm={12}
            >
              <CustomSearch
                placeholder='Search by AMC'
                value={searchText}
                onChange={onSearchTextChange}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              {/* <InputLabel>Dropdown</InputLabel> */}
              <Select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                value={selectedFilteredValue}
                onChange={event => {
                  // Handle filtered option change
                  filterOptionChangeHandler(event);
                  // Reset selection on filter change
                  // handleFilterChange();
                }}
                // onChange={filteredOptionHandler}
              >
                {filteredOptions.map((item, index) => (
                  <MenuItem
                    key={`${item}_${index}`}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}

                {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
        </> // Show the dropdown here with filteredOptions
      )}
    </>
  );
};

export default TableFilter;
