import { Box, Grid, Stack } from '@mui/material';

import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';

type Data = {
  cartUnits?: number;
  amount?: number;
  nav?: number;
};
export function calculateValue(
  { cartUnits = 0, amount = 0, nav = 1 }: Data = {},
  type: string,
): string {
  const parsedUnits = parseFloat(`${cartUnits}`.replace(',', ''));
  const parsedAmount = parseFloat(`${amount}`.replace(',', ''));

  const isUnitsValid =
    typeof parsedUnits === 'number' && !isNaN(parsedUnits) && parsedUnits !== 0;
  const isAmountValid =
    typeof parsedAmount === 'number' && !isNaN(parsedAmount) && parsedAmount !== 0;

  const calculatedUnits = isUnitsValid
    ? parsedUnits
    : isAmountValid
    ? parsedAmount / nav
    : 0;
  const calculatedAmount = isAmountValid
    ? parsedAmount
    : isUnitsValid
    ? parsedUnits * nav
    : 0;

  return (() => {
    switch (type) {
      case 'units':
        return isUnitsValid ? parsedUnits.toFixed(2) : calculatedUnits.toFixed(2);
      case 'amount':
        return isAmountValid ? parsedAmount.toFixed(2) : calculatedAmount.toFixed(2);
      default:
        return '0.00';
    }
  })();
}

const Cart = ({ data, children }: { data?: any; children?: React.ReactNode }) => (
  <Box
    sx={{
      pb: 2,
      mb: 3,
    }}
  >
    {children}
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: 'space-between',
        my: 1,
      }}
    >
      <Grid
        item
        xs={12}
        sm={8}
      >
        <Stack gap='10px'>
          <CartLabel>{data?.fundName}</CartLabel>
          <CartValue>{data?.schemeName}</CartValue>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        sm={2}
      >
        <Stack gap='10px'>
          <CartLabel>Amount:</CartLabel>
          <CartValue>₹ {calculateValue(data, 'amount')}</CartValue>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        sm={2}
      >
        <Stack gap='10px'>
          <CartLabel>Units:</CartLabel>
          <CartValue>{calculateValue(data, 'units')}</CartValue>
        </Stack>
      </Grid>
    </Grid>
  </Box>
);

export default Cart;
