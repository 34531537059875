import { Box, Grid, Stack, Typography } from '@mui/material';

import { ReactComponent as BankIconSmall } from '../../../assets/BankIcons/axis-bank-icon.svg';

/* Mobile UI - TODO Static Data*/
export default function ViewMyEarningsMobile() {
  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          mt: 2,
        }}
      >
        <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <BankIconSmall />
          <Typography variant='subtitle1'>Axis Mutual Fund</Typography>
        </Stack>
        <Stack>
          <Typography
            variant='subtitle1'
            sx={{ color: 'primary.main', mt: 2, mb: 1 }}
          >
            ₹ 92,33,568
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{ color: 'text.labelColor', mb: 1, textAlign: 'right' }}
          >
            Total
          </Typography>
        </Stack>
      </Box>
      {[1, 2, 3, 4, 5].map((item, index) => {
        return (
          <Box
            key={item}
            sx={{
              borderBottom: '0.5px solid',
              borderColor: 'text.borderColorDark',
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: 'space-between', padding: '10px 0px' }}
            >
              <Grid
                item
                xs={6}
              >
                <Stack>
                  <Typography
                    variant='subtitle2'
                    sx={{ fontWeight: 400, color: 'text.labelColor', mb: 1 }}
                  >
                    Reference No.
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ color: 'text.valueColor' }}
                  >
                    1320341
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Stack sx={{ textAlign: 'right' }}>
                  <Typography
                    variant='subtitle2'
                    sx={{ fontWeight: 400, color: 'text.labelColor', mb: 1 }}
                  >
                    Mode of Payment
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ color: 'text.valueColor' }}
                  >
                    NEFT
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Stack>
                  <Typography
                    variant='subtitle2'
                    sx={{ fontWeight: 400, color: 'text.labelColor', mb: 1 }}
                  >
                    Time Period
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ color: 'text.valueColor' }}
                  >
                    01 Dec 2020 to 31 Dec 2020
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Stack sx={{ textAlign: 'right' }}>
                  <Typography
                    variant='subtitle2'
                    sx={{ fontWeight: 400, color: 'text.labelColor', mb: 1 }}
                  >
                    Amount
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ color: 'primary.main' }}
                  >
                    ₹ 92,33,568
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
}

/* Mobile UI TODO Static Data*/
