import { Box, Button, Typography } from '@mui/material';

import BreadCrumb_Icon from '../../assets/breadcrumb-icon.svg';
export interface BreadcrumbProps {
  title?: string;
  subtitle?: string;
  otherHeading?: string;
  onClick: () => void | undefined;
}
export function Breadcrumb({ title, subtitle, otherHeading, onClick }: BreadcrumbProps) {
  return (
    <Box
      sx={{
        'alignItems': 'center',
        'gap': '2px',
        'mb': 3,
        'display': { xs: 'none', sm: 'flex' },
        '& .MuiTypography-root': {
          color: 'text.labelColor',
        },
      }}
    >
      <Button
        sx={{
          p: 0,
          display: 'block',
        }}
        disableFocusRipple={true}
        onClick={onClick || undefined}
      >
        <Typography>{title}</Typography>
      </Button>
      <img
        src={BreadCrumb_Icon}
        alt='breadcrumb-icon'
      />
      <Typography>{subtitle}</Typography>
      {otherHeading && (
        <>
          <img
            src={BreadCrumb_Icon}
            alt='breadcrumb-icon'
          />
          <Typography>{otherHeading}</Typography>
        </>
      )}
    </Box>
  );
}
