import { Box, FormControl, MenuItem, Select, Theme } from '@mui/material';
import React from 'react';

import { ReactComponent as AddIcon } from '../../../assets/rounded-add-icon.svg';
import { ReactComponent as ArrowDropDown } from '../../../assets/select-dropdown-icon.svg';
import { StepperAddButton } from '../../auth/login/styles/styledComponents';
import { CustomSearch } from '../../common/search';
import SearchInputBox from '../../common/SearchBox';
import FilterTabs from '../FilterTabs';

interface TeamTableFilterProps {
  tabValue: string;
  tabValueHandler: (newValue: string) => void;
  tabsArr: { label: string; value: string; totalCount?: number | string }[];
  tabContent: React.ReactNode[];
  statusDropDown: { [key: string]: string };
  addButtonHandler: () => void;
  selectedDropdownValue: string;
  dropDownHandler: (selectedOption: string) => void;
  query: string;
  queryChangeHandler: (value: string) => void;
}

export function TeamTableFilter({
  tabValue,
  tabValueHandler,
  tabsArr,
  tabContent,
  statusDropDown,
  addButtonHandler,
  selectedDropdownValue,
  dropDownHandler,
  query,
  queryChangeHandler,
}: TeamTableFilterProps) {
  return (
    <Box sx={{ mb: { xs: 3, sm: 0 } }}>
      <FilterTabs
        tabValue={tabValue}
        onChange={newValue => tabValueHandler(newValue)}
        tabsArr={tabsArr}
        tabContent={tabContent}
      />
      <Box
        sx={{
          padding: '20px 16px',
          display: ['none', 'flex'],
          justifyContent: 'space-between',
          columnGap: '20px',
        }}
      >
        <FormControl sx={{ minWidth: '270px' }}>
          <Select
            sx={{
              '& .MuiSelect-select': {
                borderRadius: '5px',
                border: '1px solid',
                borderColor: 'text.borderColorLight',
                fontSize: '14px',
                fontWeight: 500,
                color: 'text.labelColor3',
                padding: '10.2px 14px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            fullWidth
            IconComponent={ArrowDropDown}
            displayEmpty
            value={selectedDropdownValue}
            onChange={e => dropDownHandler(e.target.value)}
          >
            {Object.keys(statusDropDown || {}).map((key: string, index: number) => (
              <MenuItem
                value={statusDropDown[key]}
                key={index}
              >
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <CustomSearch
          value={query}
          placeholder='Search by Name, SubBroker ID, Employee ID'
          onChange={(value: string) => queryChangeHandler(value)}
          sx={(theme: Theme) => ({
            [theme.breakpoints.up(1600)]: {
              minWidth: '772px',
            },
            [theme.breakpoints.up(1400)]: {
              minWidth: '600px',
            },
          })}
        />

        <StepperAddButton
          onClick={addButtonHandler}
          startIcon={<AddIcon />}
          sx={{ minWidth: '165px', padding: '9.5px 20px' }}
        >
          Add Users
        </StepperAddButton>
      </Box>

      {/* For small screens */}
      <Box sx={{ display: ['block', 'none'] }}>
        <SearchInputBox
          data={statusDropDown}
          filterTitle={'Status'}
          queryChangeHandler={queryChangeHandler}
          placeholder='Search by Name, SubBroker ID, Employee ID'
          query={query}
          dropDownHandler={dropDownHandler}
          selectedDropdownValue={selectedDropdownValue}
        />
        {/* To be removed on sx */}
        {/* <StepperAddButton
          onClick={addButtonHandler}
          startIcon={<AddIcon />}
          sx={{ minWidth: '180px' }}
        >
          Add Users
        </StepperAddButton> */}
      </Box>
      {/* For small screens */}
    </Box>
  );
}
