import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { Outlet } from 'react-router-dom';

interface State {
  pan: string;
  isBackToSummary: boolean;
  refId: string;
  dob: string;
  mobileNumber: string;
  mobileCountryCode: string;
  email: string;
  name: string;
  kycTrack: { DtData: any[] };
  kycDetails: any | null;
  aadhaarDetails: any | null;
  skipKyc: boolean;
  ackNumber: string;
  personalDetails: any | null;
  fatcaDetails: any | null;
  accountType: any | null;
  schemeDetails: any | null;
  nomineeDetails: any | null;
  bankDetails: any | null;
  pennyDropResponse: any | null;
  tier2PennyDrop: any;
  documentDetails: any | null;
  fundDetails: any | null;
  summary: any | null;
  EKYCDetails: any | null;
  popBankDetails: any | null;
  countriesDetails: any | null;
  kycBankDetails: any | null;
  AAAdetails: any | null;
  District: string;
  statesList: any[];
  countriesList: any[];
  currentStep: number;
  ResidenceType: string;
}

interface Action {
  type: string;
  payload: any;
}

type DispatchType = Dispatch<Action>;

interface ContextProps {
  state: State;
  dispatch: DispatchType;
}

export const NewSubscriberContext = createContext<ContextProps | undefined>(undefined);

const initialState: State = {
  pan: '',
  isBackToSummary: false,
  refId: '',
  dob: '',
  mobileNumber: '',
  mobileCountryCode: '',
  email: '',
  name: '',
  kycTrack: { DtData: [] },
  kycDetails: null,
  aadhaarDetails: null,
  skipKyc: false,
  ackNumber: '',
  personalDetails: null,
  fatcaDetails: null,
  accountType: null,
  schemeDetails: null,
  nomineeDetails: null,
  bankDetails: null,
  pennyDropResponse: null,
  tier2PennyDrop: null,
  documentDetails: null,
  fundDetails: null,
  summary: null,
  EKYCDetails: null,
  popBankDetails: null,
  countriesDetails: null,
  kycBankDetails: null,
  AAAdetails: null,
  District: '',
  statesList: [],
  countriesList: [],
  currentStep: 0,
  ResidenceType: '',
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_BASIC':
      return { ...state, ...action.payload };
    case 'SET_KYC_BANK_DETAILS':
      return { ...state, kycBankDetails: action.payload };
    case 'SET_PAN':
      return { ...state, pan: action.payload };
    case 'SET_DOB':
      return { ...state, dob: action.payload };
    case 'SET_MOBILE_NUMBER':
      return { ...state, mobileNumber: action.payload };
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_NAME':
      return { ...state, name: action.payload };
    case 'SET_KYC_DATA':
      return { ...state, kycDetails: action.payload };
    case 'SET_ACK_NUMBER':
      return { ...state, ackNumber: action.payload };
    case 'SET_FATCA_DETAILS':
      return { ...state, fatcaDetails: action.payload };
    case 'SET_ACCOUNT_TYPE':
      return { ...state, accountType: action.payload };
    case 'SET_SCHEME_DETAILS':
      return { ...state, schemeDetails: action.payload };
    case 'SET_NOMINEE_DETAILS':
      return { ...state, nomineeDetails: action.payload };
    case 'SET_BANK_DETAILS':
      return { ...state, bankDetails: action.payload };
    case 'SET_PENNY_DROP_RESPONSE':
      return { ...state, pennyDropResponse: action.payload };
    case 'SET_DOCUMENT_DETAILS':
      return { ...state, documentDetails: action.payload };
    case 'SET_PERSONAL_DETAILS':
      return { ...state, personalDetails: action.payload };
    case 'SET_FUND_DETAILS':
      return { ...state, fundDetails: action.payload };
    case 'SET_KYC_TRACK_DETAILS':
      return { ...state, kycTrack: action.payload };
    case 'SET_SUMMARY_DETAILS':
      return { ...state, summary: action.payload };
    case 'SET_EKYC_DETAILS':
      return { ...state, EKYCDetails: action.payload };
    case 'SET_POP_BANK_DETAILS':
      return { ...state, popBankDetails: action.payload };
    case 'SET_COUNTRIES_DETAILS':
      return { ...state, countriesDetails: action.payload };
    case 'SET_STATUS_BACK_TO_SUMMARY':
      return { ...state, isBackToSummary: action.payload };
    case 'SET_AADHAAR_DETAILS':
      return { ...state, aadhaarDetails: action.payload };
    case 'SET_AAA_DETAILS':
      return { ...state, AAAdetails: action.payload };
    case 'SET_SKIP_KYC':
      return { ...state, skipKyc: action.payload };
    case 'SET_DISTRICT':
      return { ...state, District: action.payload };
    case 'SET_STEP':
      return { ...state, currentStep: action.payload };
    default:
      return state;
  }
};

const NewSubscriberProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <NewSubscriberContext.Provider value={{ state, dispatch }}>
      {children}
    </NewSubscriberContext.Provider>
  );
};

export const NewSubscriberContextLayout = () => {
  return (
    <NewSubscriberProvider>
      <Outlet />
    </NewSubscriberProvider>
  );
};

export const useNewSubscriberContext = (): ContextProps => {
  const context = useContext(NewSubscriberContext);
  if (!context) {
    throw new Error(
      'useNewSubscriberContext must be used within a NewSubscriberProvider',
    );
  }
  return context;
};
