import { Box, Grid, Stack, Theme, Typography } from '@mui/material';
import { differenceInDays, format, parse } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import {
  Detailslabel,
  DetailsValue,
  Investorcard,
} from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { calculateSIPDates, SIPDate } from '../../common/custom-functions';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import SubmitButton from '../../forms/FormSubmitButton';
import Layout from '../Layout';
import {
  useCancelSipMutation,
  useCancelStpMutation,
  useCancelSwpMutation,
  usePauseSipMutation,
} from '../slice';
import CancellationReasonDialog from './CancellationReasonDialog';

type BasePayload = {
  fund: string;
  scheme: string;
  plan: string;
  option: string;
  ihno: number;
  branch: string;
  folio: number;
  mobile: string;
  email: string;
  transactionType: any;
  name: string;
  investorPan: string;
  frequency: string;
  euin: string;
  schemeName: string;
  remarks: string;
};

type PausePayload = BasePayload & {
  sipStartDate: string;
  sipPauseStartDate: string;
  sipEndDate: string;
  noOfInstallments: any;
};

type CancelSipPayload = BasePayload & {
  sipStartDate: string;
  remarks: string;
  sipEndDate: string;
};

type CancelStpPayload = BasePayload & {
  toScheme: string;
  toPlan: string;
  toOption: string;
  stpDay: string;
  stpOption: string;
  toFolio: number;
  stpStartDate: string;
  stpEndDate: string;
  remarks: string;
};

type CancelSwpPayload = BasePayload & {
  swpStartDate: string;
  swpEndDate: string;
  remarks: string;
};
type PauseInitialValues = {
  startDate: string;
  endDate: string;
  pauseInstallments: string;
};
const validationSchema = Yup.object().shape({
  startDate: Yup.date().required('Start Date is required'),
  pauseInstallments: Yup.number().required('Pause Installments is required'),
  endDate: Yup.date().required('End Date is required'),
});
const Confirmation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [cancelSipScheme] = useCancelSipMutation();
  const [cancelStpScheme] = useCancelStpMutation();
  const [cancelSwpScheme] = useCancelSwpMutation();
  const [pauseSipScheme] = usePauseSipMutation();
  const [allDates, setAllDates] = useState<SIPDate[]>([]);
  const [bodyContent, setBodyContent] = useState('');
  const [showCancellationDialogBox, setShowCancellationDialogBox] =
    useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<string>('');
  const subtitle = `Please check the following details`;
  const isCancelType =
    state.trtType === 'SIPC' || state.trtType === 'STPC' || state.trtType === 'SWPC';

  const initialValues: PauseInitialValues = {
    startDate: '',
    endDate: '',
    pauseInstallments: '',
  };
  const { euin } = useSelector((state: { login: LoginResponse }) => state.login);
  const {
    pan: invPan,
    email,
    mobile,
    name,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);
  const { showToast } = useRootContext();
  const payload: BasePayload = {
    fund: state.payload.schemeDetails.fund,
    scheme: state.payload.schemeDetails.scheme,
    plan: state.payload.schemeDetails.plan,
    option: state.payload.schemeDetails.option,
    ihno: state.payload.schemeDetails.ihno,
    branch: state.payload.schemeDetails.branch,
    folio: state.payload.schemeDetails.folio,
    mobile: mobile,
    email: email,
    transactionType: state.trtType,
    name: name,
    investorPan: invPan,
    frequency: state.payload.schemeDetails.frequency,
    euin: euin,
    remarks: state.remarks || '',
    schemeName: state.payload.schemeDetails.amc,
  };
  const cancelPayload: CancelSipPayload = {
    ...payload,
    sipStartDate: format(new Date(state.payload.schemeDetails.startDate), 'yyyy-MM-dd'),
    sipEndDate: format(new Date(state.payload.schemeDetails.endDate), 'yyyy-MM-dd'),
  };
  const submitHandler = async (values: any) => {
    try {
      let res;
      if (state.trtType === 'PAUS') {
        // For PAUS
        const pausePayload: PausePayload = {
          ...payload,
          sipStartDate: format(
            new Date(state.payload.schemeDetails.startDate),
            'yyyy-MM-dd',
          ),
          sipPauseStartDate: format(values.startDate, 'yyyy-MM-dd'), // Input Date
          sipEndDate: format(new Date(state.payload.schemeDetails.endDate), 'yyyy-MM-dd'),
          noOfInstallments: values.pauseInstallments,
        };
        res = await pauseSipScheme(pausePayload).unwrap();
      } else if (state.trtType === 'SIPC') {
        res = await cancelSipScheme(cancelPayload).unwrap();
      } else {
        setShowCancellationDialogBox(true);
      }
      const { message, ihno } = res;
      setBodyContent(`${message} with batch number ${ihno}`);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };

  const calculateEndDate = (startDate: Date, numberOfInstallments: number) => {
    const endDate = new Date(startDate);
    // Add the number of installments
    endDate.setMonth(endDate.getMonth() + numberOfInstallments - 1);

    return endDate;
  };

  useEffect(() => {
    const allDates = calculateSIPDates(
      new Date(state.payload.schemeDetails.startDate),
      new Date(state.payload.schemeDetails.endDate),
    );
    const pauseDates = allDates;
    const today = new Date();
    const validPauseDates = pauseDates.filter(pauseDate => {
      const parsedDateFrom = parse(format(today, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());

      const parsedDateTo = parse(
        format(pauseDate.value, 'yyyy-MM-dd'),
        'yyyy-MM-dd',
        new Date(),
      );
      const diffDays = differenceInDays(parsedDateTo, parsedDateFrom);
      return diffDays > 12 && diffDays < 60;
    });
    setAllDates(validPauseDates.slice(0, 2));
  }, []);

  const backButtonHandler = () => {
    setShowCancellationDialogBox(false);
  };
  const popupContent = (res: { message: string; ihno: number }) => {
    const { message, ihno } = res;
    setBodyContent(`${message} with batch number ${ihno}`);
  };
  const proceedButtonHandler = async () => {
    try {
      let res;

      if (state.trtType === 'STPC') {
        const stpPayload: CancelStpPayload = {
          ...cancelPayload,
          toScheme: state.payload.schemeDetails.toScheme,
          toPlan: state.payload.schemeDetails.toPlan,
          toOption: state.payload.schemeDetails.toOption,
          stpDay: format(new Date(state.payload.schemeDetails.startDate), 'yyyy-MM-dd'),
          stpOption: 'string',
          toFolio: state.payload.schemeDetails.toAcno,
          stpStartDate: format(
            new Date(state.payload.schemeDetails.startDate),
            'yyyy-MM-dd',
          ),
          remarks: selectedReason,
          stpEndDate: format(new Date(state.payload.schemeDetails.endDate), 'yyyy-MM-dd'),
        };
        res = await cancelStpScheme(stpPayload).unwrap();
        popupContent(res);
      } else {
        const swpPayload: CancelSwpPayload = {
          ...cancelPayload,
          remarks: selectedReason,
          swpStartDate: state.payload.schemeDetails.startDate,
          swpEndDate: state.payload.schemeDetails.endDate,
        };
        res = await cancelSwpScheme(swpPayload).unwrap();
        popupContent(res);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };
  return (
    <Layout
      title='Confirmation'
      subtitle={subtitle}
    >
      <Investorcard
        sx={theme => ({
          padding: '30px',
          [theme.breakpoints.down('md')]: {
            border: 'unset',
            boxShadow: 'unset',
            padding: '0px',
          },
        })}
      >
        <Formik
          initialValues={!isCancelType ? initialValues : {}}
          onSubmit={submitHandler}
          validationSchema={!isCancelType ? validationSchema : Yup.object()} // No validation required for cancel
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                {!isCancelType ? (
                  <Grid
                    container
                    sx={{ my: 1 }}
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Typography>Select Pause Start Date</Typography>
                      <FormSelectInput
                        name='startDate'
                        label='SIP Pause Date'
                        options={allDates}
                        onChange={e => {
                          const endDate = calculateEndDate(
                            e.target.value as Date,
                            Number((values as PauseInitialValues).pauseInstallments) || 1,
                          );
                          setFieldValue('endDate', new Date(endDate));
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                    >
                      <Typography>Select Pause Installments</Typography>
                      <FormSelectInput
                        name='pauseInstallments'
                        label='Select Mode'
                        options={Array.from({ length: 6 }, (_, i) => ({
                          label: String(i + 1),
                          value: i + 1,
                        }))}
                        onChange={e => {
                          const endDate = calculateEndDate(
                            new Date((values as PauseInitialValues).startDate),
                            Number(e.target.value),
                          );

                          setFieldValue('endDate', new Date(endDate));
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                    >
                      <Typography>Sip Pause End Date</Typography>

                      <FormDateInput
                        name='endDate'
                        placeholder='Sip Pause End Date'
                        label='Date'
                        disabled
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Typography
                      variant='subtitle1'
                      sx={{ color: 'primary.main', mb: 1.5 }}
                    >
                      Scheme Details
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        sm={12}
                      >
                        <Stack
                          gap='10px'
                          mb='36px'
                        >
                          <Detailslabel sx={{ fontSize: '16px' }}>
                            Scheme Name
                          </Detailslabel>
                          <DetailsValue sx={{ fontSize: '16px' }}>
                            {state.payload.schemeDetails?.amc}
                          </DetailsValue>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack
                          gap='10px'
                          mb='36px'
                        >
                          <Detailslabel sx={{ fontSize: '16px' }}>Folio</Detailslabel>
                          <DetailsValue sx={{ fontSize: '16px' }}>
                            {state.payload.schemeDetails.folio}
                          </DetailsValue>
                        </Stack>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack
                          gap='10px'
                          mb='36px'
                        >
                          <Detailslabel sx={{ fontSize: '16px' }}>
                            Installment Amount
                          </Detailslabel>
                          <DetailsValue sx={{ fontSize: '16px' }}>
                            {state.payload.schemeDetails.installmentAmount}
                          </DetailsValue>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack
                          gap='10px'
                          mb='36px'
                        >
                          <Detailslabel sx={{ fontSize: '16px' }}>Frequency</Detailslabel>
                          <DetailsValue sx={{ fontSize: '16px' }}>
                            {state.payload.schemeDetails.frequency}
                          </DetailsValue>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack
                          gap='10px'
                          mb='36px'
                        >
                          <Detailslabel sx={{ fontSize: '16px' }}>
                            Registration Date
                          </Detailslabel>
                          <DetailsValue sx={{ fontSize: '16px' }}>
                            {format(
                              new Date(state.payload.schemeDetails.registerDate),
                              'dd/MM/yyyy',
                            )}
                          </DetailsValue>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack
                          gap='10px'
                          mb='36px'
                        >
                          <Detailslabel sx={{ fontSize: '16px' }}>From Date</Detailslabel>
                          <DetailsValue sx={{ fontSize: '16px' }}>
                            {format(
                              new Date(state.payload.schemeDetails.startDate),
                              'dd/MM/yyyy',
                            )}
                          </DetailsValue>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                      >
                        <Stack
                          gap='10px'
                          mb='36px'
                        >
                          <Detailslabel sx={{ fontSize: '16px' }}>To Date</Detailslabel>
                          <DetailsValue sx={{ fontSize: '16px' }}>
                            {format(
                              new Date(state.payload.schemeDetails.endDate),
                              'dd/MM/yyyy',
                            )}
                          </DetailsValue>
                        </Stack>
                      </Grid>
                    </Grid>{' '}
                  </>
                )}
                <SubmitButton
                  label={
                    state.trtType === 'STPC' || state.trtType === 'SWPC'
                      ? 'Cancel'
                      : !isCancelType
                      ? 'Pause'
                      : 'Confirm'
                  }
                  sx={(theme: Theme) => ({
                    my: 3,
                    [theme.breakpoints.down(600)]: {
                      maxWidth: '100%',
                      width: '100%',
                    },
                  })}
                  fullWidth
                />
              </Form>
            );
          }}
        </Formik>
      </Investorcard>

      <CustomDialog
        isVisible={!!bodyContent}
        firstButtonHandler={() => navigate('../')}
        firstButtonTitle='OK'
        handleClose={() => {}}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'fontSize': '16px',
              'color': 'text.grayishBlue',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />

          <Typography variant='body1'>{bodyContent}</Typography>
        </Box>
      </CustomDialog>
      <CancellationReasonDialog
        showDialog={showCancellationDialogBox}
        backButtonHandler={backButtonHandler}
        proceedButtonHandler={proceedButtonHandler}
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
      />
    </Layout>
  );
};

export default Confirmation;
