import { createSlice } from '@reduxjs/toolkit';

import { login } from '../login/loginSlice';

export interface AuthResponse {
  token: string;
  kydCompliant: boolean | null;
  mobileExists: boolean | null;
  emailExists: boolean | null;
  mobileDescription: string;
  emailDescription: string;
  isFromCompany: boolean;
  userName: string;
  removed: boolean;
  isAdmin: boolean;
  firstTimeLoginIndividual: boolean;
  firstTimeLoginAdmin: boolean;
  isAdminProfileActive: boolean;
  isIndividualProfileActive: boolean;
  role: string;
  name: string;
  mobile: string;
  email: string;
  subBrokerCode: string;
  loggedInAs: string;
  forgotPassword: boolean;
}

const initialState = {
  token: null,
  kydCompliant: null,
  mobileExists: null,
  emailExists: null,
  mobileDescription: '',
  emailDescription: '',
  isFromCompany: false,
  userName: '',
  removed: false,
  isAdmin: false,
  firstTimeLoginIndividual: false,
  firstTimeLoginAdmin: false,
  isAdminProfileActive: false,
  isIndividualProfileActive: false,
  role: null,
  name: null,
  mobile: null,
  email: null,
  subBrokerCode: null,
  forgotPassword: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    signup(state, action) {
      const {
        token,
        kydCompliant,
        mobileExists,
        emailExists,
        mobileDescription,
        emailDescription,
        isFromCompany = false,
      } = action.payload;
      state.token = token;
      state.kydCompliant = kydCompliant;
      state.mobileExists = mobileExists;
      state.emailExists = emailExists;
      state.mobileDescription = mobileDescription;
      state.emailDescription = emailDescription;
      state.isFromCompany = isFromCompany;
    },

    nonKydSignup(state, action) {
      const { emailDescription, kydCompliant, mobileDescription, email, mobile } =
        action.payload.data;
      state.emailDescription = emailDescription;
      state.kydCompliant = kydCompliant;
      state.mobileDescription = mobileDescription;
      state.email = email; // Saving Mobile and email
      state.mobile = mobile;
    },
    nonIndnonKydSignup(state, action) {
      const { emailDescription, token, mobileDescription, email, mobile } =
        action.payload.data; // Saving Mobile and email here also.
      state.emailDescription = emailDescription;
      state.token = token;
      state.mobileDescription = mobileDescription;
      state.mobile = mobile;
      state.email = email;
    },

    setUserName(state, action) {
      state.userName = action.payload;
    },

    setLoggedInUserName(state, action) {
      state.name = action.payload;
    },

    setAdmin(state, action) {
      state.isAdmin = action.payload;
    },

    setForgotPassword(state, action) {
      state.forgotPassword = action.payload;
    },

    resetAuthState: () => initialState,

    onLogin(state, action) {
      const { token, isFromCompany } = action.payload;
      state.token = token;
      if (isFromCompany !== null) {
        state.isFromCompany = isFromCompany;
      }
    },

    signin(state, action) {
      const {
        isAdmin,
        firstTimeLoginIndividual,
        firstTimeLoginAdmin,
        isAdminProfileActive,
        isIndividualProfileActive,
        role,
      } = action.payload;

      state.isAdmin = isAdmin;
      state.firstTimeLoginAdmin = firstTimeLoginAdmin;
      state.firstTimeLoginIndividual = firstTimeLoginIndividual;
      state.isAdminProfileActive = isAdminProfileActive;
      state.isIndividualProfileActive = isIndividualProfileActive;
      state.role = role;
    },

    onPasswordSetup(state, action) {
      const { name, mobile, email, subbrokerCode } = action.payload;
      state.name = name;
      state.mobile = mobile;
      state.email = email;
      state.subBrokerCode = subbrokerCode;
    },
  },
  extraReducers(builder) {
    builder.addCase(login, state => {
      state.token = null;
      state.kydCompliant = null;
      state.mobileExists = null;
      state.emailExists = null;
      state.mobileDescription = '';
      state.emailDescription = '';
      state.userName = '';
      state.removed = true;
    });
  },
});

export const {
  signup,
  nonKydSignup,
  nonIndnonKydSignup,
  setUserName,
  onLogin,
  signin,
  setAdmin,
  onPasswordSetup,
  setLoggedInUserName,
  setForgotPassword,
  resetAuthState,
} = authSlice.actions;
export default authSlice.reducer;
