import { Box, SxProps, Typography } from '@mui/material';

export interface PanDetailsProps {
  value: any;
  name: string;
  sx?: SxProps;
}
export default function PanDetailsBox({ value, name, sx }: PanDetailsProps) {
  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'space-between',
        alignItems: 'center',
        bgcolor: 'text.boxColorLight',
        padding: '10px 20px',
        position: 'fixed',
        top: '90px',
        zIndex: '3',
        mx: '-20px',
        width: '100%',
        ...sx,
      }}
    >
      <Typography sx={{ fontWeight: 600, color: 'primary.main' }}>{name}</Typography>

      <Typography
        variant='subtitle2'
        sx={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          color: 'text.labelColor',
        }}
      >
        PAN:
        <Typography
          variant='subtitle2'
          sx={{ color: 'text.valueColor' }}
        >
          {value}
        </Typography>
      </Typography>
    </Box>
  );
}
