import { Grid } from '@mui/material';
import { useState } from 'react';

import PaymentModesForm from '../cart/paymentModesForm';
import { FormData } from '../switch/Confirmation';
import { ModeTypeToTrtType } from '../types';
import { Mandate } from './types';

const Confirmation = ({ data, formikRef = null }: { data: any; formikRef?: any }) => {
  const [selectedMandate, setSelectedMandate] = useState<Mandate>({
    label: '',
    value: '',
    mandateLimit: 0,
    startDate: '',
    endDate: '',
    bankAccountNumber: '',
    bankName: '',
  });

  const aplPayload = {
    fund: data?.fund,
    pan: data?.pan,
    folio: data?.folio,
    trtype: ModeTypeToTrtType[data.modeType as keyof typeof ModeTypeToTrtType],
    modeOfRegistration: data?.modeOfRegistration,
    amount: data?.amount, // For lumpsumAmount
    installmentAmount: data?.installmentAmount, // For Sip
    isNriInvestor: data?.isNriInvestor || data?.invCategory,
    isPanBasedAotm: data?.isPanBasedAotm || false,
  };

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Scheme'
            value={data.fundName || data.schemeName}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Mode of Transaction'
            value={data.modeType || data?.transactionType}
          />
        </Grid>

        {data?.installmentAmount > 0 && (
          <Grid
            item
            xs={12}
            sx={{ mb: 1.5 }}
          >
            <FormData
              label='Installment Amount'
              value={`₹ ${data.installmentAmount}`}
            />
          </Grid>
        )}
        {data?.amount > 0 && (
          <Grid
            item
            xs={12}
            sx={{ mb: 1.5 }}
          >
            <FormData
              label='Investment Amount'
              value={`₹ ${data.amount}`}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <PaymentModesForm
            aplPaymentsModePayload={aplPayload}
            selectedMandate={selectedMandate}
            setSelectedMandate={setSelectedMandate}
            formikRef={formikRef}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Confirmation;
