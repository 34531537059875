import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { Investorcard } from '../../auth/login/styles/styledComponents';
import ExpandableRadioTable from '../../common/ExpandableRadioTable';
import SearchComponent from '../../common/SearchComponent';
import { useRootContext } from '../../data/root.context';
import { useSchemeByfundMutation } from '../mfs/newInvestorOnboarding/slice';
// TODO: Select Scheme image to  be added
// import { ReactComponent as FindSchemes } from '../../../assets/schemeTable.svg';
interface DropdownItem {
  label: string;
  value: string;
}
interface SchemeDetail {
  fund: string;
  amcName: string;
  folio: number;
  dob: string;
  investorName: string;
  mobile: string;
  email: string;
  schemeName: string;
  scheme: string;
  schemePlan: string;
  schemeOption: string;
  planDesc: string;
  transactionSource: string;
  isDemat: boolean;
  taxsaverFlag: boolean;
  mcrid: boolean;
  category: string;
  modeOfHolding: string;
  mohDesc: string;
  units: number;
  nav: number;
  redemptionAllow: boolean;
  swdAllow: boolean;
  switchAllow: boolean;
  stpAllow: boolean;
  additionalPurAllow: boolean;
  newPurAllow: boolean;
}

const NewSchemeTable = ({
  onContinueButtonClick,
  showFoliosDropdown = false,
  handleFolioSelection,
  showAmcDropdown = false,
  selectedFolio,
  selectedAmc,
  handleAmcSelection,
  amcDropdown,
  folioDropdown,
  filterByKey,
}: {
  onContinueButtonClick: (row: any, extraData: any) => void;
  showFoliosDropdown?: boolean;
  handleFolioSelection?: (folioNo: string) => void;
  showAmcDropdown?: boolean;
  selectedFolio?: string;
  selectedAmc?: string;
  handleAmcSelection?: any;
  amcDropdown?: DropdownItem[];
  folioDropdown?: string[];
  filterByKey?: string;
}) => {
  const [schemesByFund, setSchemesByFund] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [newSelectedAMC, setNewSelectedAMC] = useState<string>(selectedAmc || '');
  const [getSchemesByFund] = useSchemeByfundMutation();
  const { showToast } = useRootContext();
  const [selectedChip, setSelectedChip] = useState<string>('');
  const [chipsData, setChipsData] = useState<any>([]);
  const firstChipCall = useRef<boolean>(false);
  const [text, setText] = useState<string>('');
  useEffect(() => {
    async function fetchSchemesByFund() {
      try {
        if (newSelectedAMC) {
          const schemeByFundBody = {
            funds: [newSelectedAMC],
            ...(selectedChip !== '' && { category: [selectedChip] }),
            investorPan: '',
            start: 0,
            end: 20000, //TODO: add valid pagination
          };
          const resFund = await getSchemesByFund(schemeByFundBody).unwrap();
          const filteredData = filterByKey
            ? resFund.filter((row: any) => row[filterByKey])
            : resFund;
          setSchemesByFund(filteredData);
          setFilteredData(filteredData);

          // Get unique categories

          // Initializing it with the first category.
          if (!firstChipCall.current) {
            const arrangedObj: { [key: string]: SchemeDetail[] } = {};
            for (const item of resFund) {
              const { fund } = item;
              if (!arrangedObj[fund]) {
                arrangedObj[fund] = [item];
              } else {
                arrangedObj[fund].push(item);
              }
            }
            const uniqueCategories: string[] = [
              ...new Set(arrangedObj[newSelectedAMC]?.map(item => item.category) || []),
            ];
            setChipsData(uniqueCategories);
            setSelectedChip(uniqueCategories[0]);
            firstChipCall.current = true;
          }
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    }
    fetchSchemesByFund();
  }, [selectedChip, newSelectedAMC]);

  const handleAMCSelection = (e: any) => {
    setNewSelectedAMC(e.target.value);
    handleAmcSelection(e);
    // everytime when we are changing selected amc reset chips.
    setSelectedChip('');
    firstChipCall.current = false;
  };

  const handleChipSelection = async (scheme: string) => {
    setSelectedChip(scheme);
  };
  const onChangeSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };
  useEffect(() => {
    const filteredData = schemesByFund.filter(
      (investor: any) =>
        investor.category.toLowerCase().includes(text.toLowerCase()) ||
        investor.schemeDesc.toLowerCase().includes(text.toLowerCase()),
    );
    setFilteredData(filteredData);
  }, [text]);
  return (
    <Investorcard
      sx={(theme: Theme) => ({
        [theme.breakpoints.down('sm')]: {
          boxShadow: 'unset',
          border: 'unset',
        },
      })}
    >
      <ExpandableRadioTable
        showIcon={true}
        showSelectAmcImage={newSelectedAMC === ''}
        rows={filteredData}
        keysToShow={['schemeDesc', 'category', 'subCategory', 'risk']}
        colorMappings={{
          'Low Risk': 'success.main',
          'Moderate Risk': 'warning.main',
          // Add more mappings as needed
        }}
        rowHeader='schemeDesc'
        onContinue={row =>
          onContinueButtonClick(row, {
            categories: chipsData,
            selectedCategory: selectedChip,
          })
        }
        filter={
          <>
            <Box
              sx={{
                padding: { xs: '10px', sm: '25px 25px' },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                columnGap: '20px',
                borderBottom: '1px solid',
                borderColor: 'text.borderColorLight',
              }}
            >
              {showAmcDropdown && (
                <FormControl
                  fullWidth
                  variant='filled'
                  sx={{
                    'mb': 1,
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.labelColor',
                      top: '-2px',
                    },
                    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                      color: 'text.labelColor',
                      fontSize: '14px',
                    },
                    '& .MuiInputBase-root': {
                      'fontSize': '14px',
                      'color': 'text.labelColor',
                      'fontWeight': 500,
                      '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled':
                        {
                          backgroundColor: 'text.borderColorDark',
                          color: 'text.primary',
                          WebkitTextFillColor: 'unset',
                        },
                    },
                    '& .MuiInputBase-root, & .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root':
                      {
                        'border': 'none',
                        'backgroundColor': 'unset !important',
                        '&:before, &:after, &:hover': {
                          borderBottom: 'none !important',
                        },
                        '&.Mui-focused': {
                          backgroundColor: 'unset',
                        },
                      },
                    '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root.Mui-focused':
                      {
                        '&:hover': {
                          '&:before': {
                            border: 'none',
                          },
                        },
                      },
                    '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:hover': {
                      'backgroundColor': 'unset',
                      '&:before': {
                        borderBottom: 'none',
                      },
                    },
                    '& .MuiSelect-select ,&:focus': {
                      borderRadius: '7px',
                      border: '1px solid',
                      borderColor: 'text.borderColorLight',
                      backgroundColor: 'common.white',
                      paddingBottom: '4px',
                      paddingTop: '20px',
                    },
                  }}
                >
                  <InputLabel
                    id='amc-select-label'
                    sx={{
                      'color': 'text.labelColor',
                      'fontSize': '14px',
                      'fontWeight': '500',
                      'top': '2px',
                      'left': '2px',
                      '&.Mui-focused': {
                        color: 'inherit',
                        fontSize: '12px',
                        fontWeight: '500',
                      },
                    }}
                  >
                    Select AMC
                  </InputLabel>
                  <Select
                    labelId='amc-select-label'
                    id='amc-select'
                    value={newSelectedAMC}
                    onChange={handleAMCSelection}
                  >
                    {amcDropdown?.map((item: DropdownItem, index: number) => (
                      <MenuItem
                        key={index}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {showFoliosDropdown && (
                <FormControl
                  fullWidth
                  variant='filled'
                  sx={{
                    'mb': 1,
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.labelColor',
                      top: '-2px',
                    },
                    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                      color: 'text.labelColor',
                      fontSize: '14px',
                    },
                    '& .MuiInputBase-root': {
                      'fontSize': '14px',
                      'color': 'text.labelColor',
                      'fontWeight': 500,
                      '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled':
                        {
                          backgroundColor: 'text.borderColorDark',
                          color: 'text.primary',
                          WebkitTextFillColor: 'unset',
                        },
                    },
                    '& .MuiInputBase-root, & .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root':
                      {
                        'border': 'none',
                        'backgroundColor': 'unset !important',
                        '&:before, &:after, &:hover': {
                          borderBottom: 'none !important',
                        },
                        '&.Mui-focused': {
                          backgroundColor: 'unset',
                        },
                      },
                    '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root.Mui-focused':
                      {
                        '&:hover': {
                          '&:before': {
                            border: 'none',
                          },
                        },
                      },
                    '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:hover': {
                      'backgroundColor': 'unset',
                      '&:before': {
                        borderBottom: 'none',
                      },
                    },
                    '& .MuiSelect-select ,&:focus': {
                      borderRadius: '7px',
                      border: '1px solid',
                      borderColor: 'text.borderColorLight',
                      backgroundColor: 'common.white',
                      paddingBottom: '4px',
                      paddingTop: '20px',
                    },
                  }}
                >
                  <InputLabel
                    id='folio-select-label'
                    sx={{
                      'color': 'text.labelColor',
                      'fontSize': '14px',
                      'fontWeight': '500',
                      'top': '2px',
                      'left': '2px',
                      '&.Mui-focused': {
                        color: 'inherit',
                        fontSize: '12px',
                        fontWeight: '500',
                      },
                    }}
                  >
                    Select Folio
                  </InputLabel>
                  <Select
                    labelId='folio-select-label'
                    id='folio-select-label'
                    value={selectedFolio}
                    onChange={(event: SelectChangeEvent<string>) => {
                      if (handleFolioSelection) {
                        handleFolioSelection(event.target.value);
                      }
                    }}
                  >
                    {folioDropdown?.map((item, index: number) => (
                      <MenuItem
                        key={index}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>

            <Box
              sx={{
                padding: '25px 25px',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid',
                borderColor: 'text.borderColorLight',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              {chipsData?.map((scheme: any, index: number) => (
                <Chip
                  key={index}
                  onClick={async () => handleChipSelection(scheme)}
                  sx={(theme: Theme) => ({
                    'background':
                      selectedChip === scheme
                        ? 'linear-gradient(180deg, #1D6CB0 0%, #10C1D0 100%)'
                        : theme.palette.common.white,
                    'borderRadius': '7px',
                    'padding': '0px 15px',
                    'border': selectedChip === scheme ? 'unset' : '0.5px solid',
                    'borderColor':
                      selectedChip === scheme ? 'unset' : 'text.borderColorLight',
                    '& .MuiChip-label': {
                      fontSize: { xs: '13px', xl: '15px' },
                      fontWeight: '500',
                      p: 0,
                      color: selectedChip === scheme ? 'common.white' : 'text.labelColor',
                    },
                  })}
                  label={scheme}
                />
              ))}
            </Box>
            {schemesByFund?.length > 0 && (
              <Box sx={{ p: '20px 20px' }}>
                <SearchComponent
                  text={text}
                  onChangeSearchHandler={onChangeSearchHandler}
                />
              </Box>
            )}
          </>
        }
      />
    </Investorcard>
  );
};

export default NewSchemeTable;
