import { Grid } from '@mui/material';

import SwitchFromIcon from '../../../assets/switch-from.svg';
import SwitchToIcon from '../../../assets/switch-to.svg';
import { mapFrequency } from '../mfs/newInvestorOnboarding/utils';
import { FormData } from '../switch/Confirmation';

const Confirmation = ({ data }: { data: any }) => {
  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Scheme From'
            value={data?.switchOutSchemeName}
          >
            <img
              src={SwitchFromIcon}
              alt={'switch-from-icon'}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </FormData>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Scheme To'
            value={data?.switchInSchemeName}
          >
            <img
              src={SwitchToIcon}
              alt={'switch-to-icon'}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </FormData>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='STP Type'
            value={data?.stpOption}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='STP Installment Amount'
            value={data?.amount}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Frequency'
            value={mapFrequency(data?.frequency)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='No. Of Installments'
            value={data?.noOfTransfers}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='STP Start Date'
            value={`${data?.stpStartDate}`}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='STP End Date'
            value={`${data?.stpEndDate}`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Confirmation;
