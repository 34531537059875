import { Box, Grid, IconButton, Stack } from '@mui/material';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../../../assets/back-icon-large.svg';
import {
  DetailsHeader,
  Detailslabel,
  DetailsValue,
  FieldSubHeader3,
  Investorcard,
  TransactionHeader,
} from '../../../auth/login/styles/styledComponents';
import InvestorProfile from '../../../common/InvestorProfile';
import { useRootContext } from '../../../data/root.context';
import { TransactionResponse } from '../../../features/transactions/transactionSlice';
import StyledRadioButtonGroup from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { AdditionalPurchaseFormValues, AgentDetails, ServiceCharges } from '../../types';
import {
  useGetAgentDetailsMutation,
  useGetServiceDetailsMutation,
  useSendSubContributionEmailMutation,
} from '../investorOnBoarding/slice';

export default function AdditionalPurchase() {
  const [getAgentDetails] = useGetAgentDetailsMutation();
  const [getServiceDetails] = useGetServiceDetailsMutation();
  const formikRef = useRef<FormikProps<AdditionalPurchaseFormValues>>(null);
  const [agentDetails, setAgentDetails] = useState<AgentDetails | null>(null);
  const [serviceCharges, setServiceCharges] = useState<ServiceCharges | null>(null);
  const [sendSubContributionEmail] = useSendSubContributionEmailMutation();
  const { showToast } = useRootContext();
  const agentRefNumber = 'AABCK5190K00000018';
  const navigate = useNavigate();

  const initialValues: AdditionalPurchaseFormValues = {
    accountType: '',
    fundManager: '',
    lumpsumAmount: '',
  };
  const {
    name,
    pan,
    MFS,
    NPS,
    email,
    mobile,
    pran,
    uniqueAgentIdentifier,
    ackNumber,
    accountType,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);

  const submitHandler = async (
    values: AdditionalPurchaseFormValues,
    helpers: FormikHelpers<AdditionalPurchaseFormValues>,
  ) => {
    try {
      const params = {
        Pran: selectedSubscriber?.PRAN,
        ContributionType: 'S',
        AccounType: Number(selectedSubscriber?.AccountType),
        TotalAmount: Number(values?.lumpsumAmount),
        POP_Code: agentDetails?.POPCode,
        POP_SP_Code: agentDetails?.POP_SPCode,
        AgentReferenceNumber: agentRefNumber,
        EmailID: selectedSubscriber?.EmailID,
        PhoneNumber: selectedSubscriber?.PhoneNumber,
        PAN: selectedSubscriber?.PAN,
        SubscriberName: selectedSubscriber?.SubscriberName,
      };
      const response = await sendSubContributionEmail(params).unwrap();
      showToast((response.data as { message: string }).message, 'success');
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
    helpers.setSubmitting(false);
  };
  const selectedSubscriber = {
    SubscriberName: name,
    PRAN: pran,
    EmailID: email,
    PhoneNumber: mobile,
    PAN: pan,
    AckNumber: ackNumber,
    UniqueAgentIdentifier: uniqueAgentIdentifier,
    AccountType: accountType,
  };
  // Get the service Info as soon as we enter the amount.
  const fetchServiceData = async (amount: number) => {
    const params = {
      AccountType: Number(selectedSubscriber?.AccountType),
      Amount: Number(amount),
      PranNo: selectedSubscriber?.PRAN,
      POP_SPCode: agentDetails?.POP_SPCode,
    };
    try {
      const response = await getServiceDetails(params).unwrap();
      setServiceCharges(response?.data);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };
  const fetchData = async () => {
    try {
      const response: any = await getAgentDetails({
        agentRefNumber,
      }).unwrap();

      setAgentDetails(response.data);

      if (formikRef.current) {
        formikRef.current.setFieldValue('fundManager', response?.data?.TPI_ClientName);
        formikRef.current.setFieldValue('accountType', selectedSubscriber?.AccountType);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleBackButton = () => {
    if (MFS && NPS) {
      navigate('../../asset-selection');
    } else {
      navigate('../../');
    }
  };
  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
      >
        <IconButton sx={{ mb: 2 }}>
          <BackIcon onClick={handleBackButton} />
        </IconButton>
        <TransactionHeader sx={{ mb: 2 }}>Additional Purchase</TransactionHeader>
      </Stack>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          sm={9}
        >
          <Investorcard sx={{ padding: '30px 30px' }}>
            <DetailsHeader sx={{ mb: 1.5 }}>
              Please enter the below details.
            </DetailsHeader>
            <FieldSubHeader3 sx={{ fontSize: '16px', mb: 1.5 }}>
              Select account Type
            </FieldSubHeader3>

            <Formik
              initialValues={initialValues}
              onSubmit={submitHandler}
              innerRef={formikRef}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <StyledRadioButtonGroup
                    options={[
                      { label: 'TIER I', value: '1' },
                      { label: 'TIER II', value: '2' },
                      { label: 'TIER I & II', value: '3' },
                    ]}
                    value={values.accountType}
                    disabled={true}
                    handleChange={() => {}}
                  />

                  <Grid
                    container
                    spacing={2}
                    my={'16px'}
                  >
                    <Grid
                      item
                      xs={6}
                    >
                      <FormTextInput
                        name='fundManager'
                        label='Pension Funds Manager'
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <FormTextInput
                        name='lumpsumAmount'
                        label='Lumpsum Amount'
                        onChange={async e => {
                          const value = +e.target.value;
                          setFieldValue('lumpsumAmount', value == 0 ? '' : '' + value);
                          if (selectedSubscriber?.AccountType == '2') {
                            if (Number(value) >= 250) {
                              await fetchServiceData(value);
                              return;
                            }
                          } else {
                            if (Number(value) >= 500) {
                              await fetchServiceData(value);
                              return;
                            }
                          }
                        }}
                        placeholder={
                          selectedSubscriber?.AccountType == '2'
                            ? `Minimum Amount ₹.250`
                            : `Minimum Amount ₹.500`
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                    >
                      {serviceCharges && (
                        <Box
                          sx={{
                            'display': 'flex',
                            'justifyContent': 'space-between',
                            '& .MuiTypography-root ': {
                              mb: 1,
                            },
                          }}
                        >
                          <Box>
                            <Detailslabel>Service Charge</Detailslabel>
                            <Detailslabel>CGST Amount</Detailslabel>
                            <Detailslabel>IGST Amount</Detailslabel>
                            <Detailslabel>SGST Amount</Detailslabel>
                            <Detailslabel>Total Contribution Amount</Detailslabel>
                            <Detailslabel>POP Charges</Detailslabel>
                            <Detailslabel>Total Amount Payable</Detailslabel>
                          </Box>
                          <Box>
                            <DetailsValue>₹ {serviceCharges?.Servicecharge}</DetailsValue>
                            <DetailsValue>₹ {serviceCharges?.CGSTAmount}</DetailsValue>
                            <DetailsValue>₹ {serviceCharges?.IGSTAmount}</DetailsValue>
                            <DetailsValue>₹ {serviceCharges?.SGSTAmount}</DetailsValue>
                            <DetailsValue>
                              ₹ {serviceCharges?.Totalcontributionamount}
                            </DetailsValue>
                            <DetailsValue>₹ {serviceCharges?.POPCharges}</DetailsValue>
                            <DetailsValue>
                              ₹ {serviceCharges?.TotalAmountPayble}
                            </DetailsValue>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  </Grid>

                  <SubmitButton
                    sx={{
                      '&.MuiButtonBase-root': {
                        borderRadius: '5px',
                        minWidth: '200px',
                        mt: 3,
                      },
                    }}
                    label='Submit'
                  >
                    Submit
                  </SubmitButton>
                </Form>
              )}
            </Formik>
          </Investorcard>
        </Grid>
        <Grid
          item
          sm={3}
        >
          <InvestorProfile />
        </Grid>
      </Grid>
    </>
  );
}
