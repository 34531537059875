import { createSlice } from '@reduxjs/toolkit';

export interface TransactionResponse {
  cartItems: [];
  name: string;
  pan: string;
  MFS: boolean | null;
  NPS: boolean | null;
  email: string;
  mobile: string;
  pran: null | string;
  uniqueAgentIdentifier: null | string;
  ackNumber: null | string;
  accountType: null | string;
  assets: {};
}

const initialState = {
  cartItems: [],
  name: null,
  pan: null,
  MFS: false,
  NPS: false,
  email: null,
  mobile: null,
  pran: null,
  uniqueAgentIdentifier: null,
  ackNumber: null,
  accountType: null,
  assets: {},
};

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.cartItems = action.payload;
    },

    investorDetails: (state, action) => {
      const {
        name,
        pan,
        mfStatus,
        email,
        mobile,
        npsEmpanelmentStatus,
        pran,
        UniqueAgentIdentifier,
        AckNumber,
        AccountType,
      } = action.payload;
      state.name = name;
      state.pan = pan;
      state.MFS = mfStatus;
      state.NPS = npsEmpanelmentStatus;
      state.email = email;
      state.mobile = mobile;
      state.pran = pran;
      state.uniqueAgentIdentifier = UniqueAgentIdentifier;
      state.ackNumber = AckNumber;
      state.accountType = AccountType;
    },
    resetTransactionState: () => initialState,
    assets: (state, action) => {
      state.assets = action.payload;
    },
  },
});

export const { addToCart, investorDetails, resetTransactionState, assets } =
  transactionSlice.actions;
export default transactionSlice.reducer;
