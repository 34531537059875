import { calculateDifference } from '../../utils';

export const handleSameAsApplicantAddressChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any) => void,
  index: number,
  personalDetails: any,
) => {
  setFieldValue(`nominees.${index}.isSameAsApplicant`, e.target.checked);
  if (e.target.checked) {
    setFieldValue(
      `nominees.${index}.address`,
      `${personalDetails?.add1} ${personalDetails?.add2}`,
    );
    setFieldValue(`nominees.${index}.city`, personalDetails?.city);
    setFieldValue(`nominees.${index}.state`, personalDetails?.state);
    setFieldValue(`nominees.${index}.pinCode`, personalDetails?.pinCode);
    setFieldValue(`nominees.${index}.country`, personalDetails?.country);
  } else {
    setFieldValue(`nominees.${index}.address`, '');
    setFieldValue(`nominees.${index}.city`, '');
    setFieldValue(`nominees.${index}.state`, '');
    setFieldValue(`nominees.${index}.pinCode`, '');
    setFieldValue(`nominees.${index}.country`, '');
  }
};

export const getPerpetualSipEndDate = (mode: string, startDate: Date) => {
  if (mode === 'ISIP') {
    return new Date(2099, 11, 31);
  }

  const date = new Date(startDate);

  date.setDate(date.getDate() - 1);
  date.setFullYear(date.getFullYear() + 40);
  return date;
};

export const handlePerpetualSipBoxChnage = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any) => void,
  values: any,
  startDate: Date,
  selectedFrequencyAndSip: any,
) => {
  setFieldValue('perpetual', e.target.checked);

  if (e.target.checked) {
    const end_date = getPerpetualSipEndDate(values.modeOfRegistration, startDate);
    const selectedInstallments = calculateDifference(
      startDate,
      end_date,
      selectedFrequencyAndSip.selectedFrequency,
    );
    setFieldValue('endDate', end_date);
    setFieldValue('numberOfInstalments', Math.round(selectedInstallments as any));
  } else {
    setFieldValue('frequency', '');
    setFieldValue('sipDay', '');
    setFieldValue('numberOfInstalments', '');
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
  }
};

export const getEarliestAndLatestSipDates = (cartItems: any[]) => {
  const startDates = cartItems?.map(sip => {
    const payload = JSON.parse(sip.payload);
    return new Date(payload.sipStartDate).getTime();
  });
  const endDates = cartItems?.map(sip => {
    const payload = JSON.parse(sip.payload);
    return new Date(payload.sipEndDate).getTime();
  });
  const earliestDate = startDates && new Date(Math.min(...startDates));
  const latestDate = endDates && new Date(Math.max(...endDates));

  return { earliestDate, latestDate };
};

export const maxInstallmentBasedOnFrequency = (frequency: any, mode: any) => {
  const today = new Date();
  const isipLimitDate = new Date('2099-12-31');
  const nonIsipLimitDate = new Date();
  nonIsipLimitDate.setFullYear(today.getFullYear() + 40);

  const limitDate = mode === 'ISIP' ? isipLimitDate : nonIsipLimitDate;

  const calculateDaysDifference = (date1: any, date2: any) =>
    Math.round((date2 - date1) / (24 * 60 * 60 * 1000));

  const calculateMonthsDifference = (date1: any, date2: any) =>
    (date2.getFullYear() - date1.getFullYear()) * 12 +
    date2.getMonth() -
    date1.getMonth();

  const calculateYearsDifference = (date1: any, date2: any) =>
    date2.getFullYear() - date1.getFullYear();

  switch (frequency) {
    case 'Daily':
      return calculateDaysDifference(today, limitDate);
    case 'Monthly':
      return calculateMonthsDifference(today, limitDate);
    case 'Annual':
      return calculateYearsDifference(today, limitDate);
    case 'Fortnightly':
      return calculateDaysDifference(today, limitDate) / 14;
    case 'Weekly':
      return calculateDaysDifference(today, limitDate) / 7;
    case 'Quarterly':
      return calculateMonthsDifference(today, limitDate) / 3;
    default:
      return 0;
  }
};
